import { html } from 'lit';
import { z } from 'zod';
import './nodes/index.js';
export const affineInlineSpecsWithoutReference = [
    {
        name: 'bold',
        schema: z.literal(true).optional().nullable().catch(undefined),
        match: (delta)=>{
            return !!delta.attributes?.bold;
        },
        renderer: (delta)=>{
            return html`<affine-text .delta=${delta}></affine-text>`;
        }
    },
    {
        name: 'italic',
        schema: z.literal(true).optional().nullable().catch(undefined),
        match: (delta)=>{
            return !!delta.attributes?.italic;
        },
        renderer: (delta)=>{
            return html`<affine-text .delta=${delta}></affine-text>`;
        }
    },
    {
        name: 'underline',
        schema: z.literal(true).optional().nullable().catch(undefined),
        match: (delta)=>{
            return !!delta.attributes?.underline;
        },
        renderer: (delta)=>{
            return html`<affine-text .delta=${delta}></affine-text>`;
        }
    },
    {
        name: 'strike',
        schema: z.literal(true).optional().nullable().catch(undefined),
        match: (delta)=>{
            return !!delta.attributes?.strike;
        },
        renderer: (delta)=>{
            return html`<affine-text .delta=${delta}></affine-text>`;
        }
    },
    {
        name: 'code',
        schema: z.literal(true).optional().nullable().catch(undefined),
        match: (delta)=>{
            return !!delta.attributes?.code;
        },
        renderer: (delta)=>{
            return html`<affine-text .delta=${delta}></affine-text>`;
        }
    },
    {
        name: 'background',
        schema: z.string().optional().nullable().catch(undefined),
        match: (delta)=>{
            return !!delta.attributes?.background;
        },
        renderer: (delta)=>{
            return html`<affine-text .delta=${delta}></affine-text>`;
        }
    },
    {
        name: 'color',
        schema: z.string().optional().nullable().catch(undefined),
        match: (delta)=>{
            return !!delta.attributes?.color;
        },
        renderer: (delta)=>{
            return html`<affine-text .delta=${delta}></affine-text>`;
        }
    }
];
export function getAffineInlineSpecsWithReference(referenceNodeConfig) {
    return [
        ...affineInlineSpecsWithoutReference,
        {
            name: 'reference',
            schema: z.object({
                type: z.enum([
                    'Subpage',
                    'LinkedPage'
                ]),
                pageId: z.string()
            }).optional().nullable().catch(undefined),
            match: (delta)=>{
                return !!delta.attributes?.reference;
            },
            renderer: (delta, selected)=>{
                return html`<affine-reference
          .delta=${delta}
          .selected=${selected}
          .config=${referenceNodeConfig}
        ></affine-reference>`;
            },
            embed: true
        },
        {
            name: 'link',
            schema: z.string().optional().nullable().catch(undefined),
            match: (delta)=>{
                return !!delta.attributes?.link;
            },
            renderer: (delta)=>{
                return html`<affine-link .delta=${delta}></affine-link>`;
            }
        }
    ];
}
