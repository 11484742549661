import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MenuIcon, MenuItem } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { ExpandCloseIcon, MoveToLeftDuotoneIcon, MoveToRightDuotoneIcon, SoloViewIcon } from '@blocksuite/icons/rc';
import { useSortable } from '@dnd-kit/sortable';
import { useLiveData, useService } from '@toeverything/infra';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { memo, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { WorkbenchService } from '../../services/workbench';
import { SplitViewIndicator } from './indicator';
import * as styles from './split-view.css';
export const SplitViewPanel = memo(function SplitViewPanel({ children, view, setSlots }) {
    const [indicatorPressed, setIndicatorPressed] = useState(false);
    const ref = useRef(null);
    const size = useLiveData(view.size$);
    const workbench = useService(WorkbenchService).workbench;
    const activeView = useLiveData(workbench.activeView$);
    const views = useLiveData(workbench.views$);
    const isLast = views[views.length - 1] === view;
    const { attributes, listeners, transform, transition, isDragging: dndIsDragging, setNodeRef } = useSortable({
        id: view.id,
        attributes: {
            role: 'group'
        }
    });
    const isDragging = dndIsDragging || indicatorPressed;
    const isActive = activeView === view;
    useLayoutEffect(()=>{
        if (ref.current) {
            setSlots?.((slots)=>({
                    ...slots,
                    [view.id]: ref
                }));
        }
    }, [
        setSlots,
        view.id
    ]);
    const style = useMemo(()=>({
            ...assignInlineVars({
                '--size': size.toString()
            })
        }), [
        size
    ]);
    const dragStyle = useMemo(()=>({
            transform: `translate3d(${transform?.x ?? 0}px, 0, 0)`,
            transition
        }), [
        transform,
        transition
    ]);
    return _jsxs("div", {
        style: style,
        className: styles.splitViewPanel,
        "data-is-dragging": isDragging,
        "data-is-active": isActive && views.length > 1,
        "data-is-last": isLast,
        children: [
            _jsxs("div", {
                ref: setNodeRef,
                style: dragStyle,
                className: styles.splitViewPanelDrag,
                ...attributes,
                children: [
                    _jsx("div", {
                        className: styles.splitViewPanelContent,
                        ref: ref
                    }),
                    views.length > 1 ? _jsx(SplitViewIndicator, {
                        listeners: listeners,
                        isDragging: isDragging,
                        isActive: isActive,
                        menuItems: _jsx(SplitViewMenu, {
                            view: view
                        }),
                        setPressed: setIndicatorPressed
                    }) : null
                ]
            }),
            children
        ]
    });
});
const SplitViewMenu = ({ view })=>{
    const t = useI18n();
    const workbench = useService(WorkbenchService).workbench;
    const views = useLiveData(workbench.views$);
    const viewIndex = views.findIndex((v)=>v === view);
    const handleClose = useCallback(()=>workbench.close(view), [
        view,
        workbench
    ]);
    const handleMoveLeft = useCallback(()=>{
        workbench.moveView(viewIndex, viewIndex - 1);
    }, [
        viewIndex,
        workbench
    ]);
    const handleMoveRight = useCallback(()=>{
        workbench.moveView(viewIndex, viewIndex + 1);
    }, [
        viewIndex,
        workbench
    ]);
    const handleCloseOthers = useCallback(()=>{
        workbench.closeOthers(view);
    }, [
        view,
        workbench
    ]);
    const CloseItem = views.length > 1 ? _jsx(MenuItem, {
        preFix: _jsx(MenuIcon, {
            icon: _jsx(ExpandCloseIcon, {})
        }),
        onClick: handleClose,
        children: t['com.affine.workbench.split-view-menu.close']()
    }) : null;
    const MoveLeftItem = viewIndex > 0 && views.length > 1 ? _jsx(MenuItem, {
        onClick: handleMoveLeft,
        preFix: _jsx(MenuIcon, {
            icon: _jsx(MoveToLeftDuotoneIcon, {})
        }),
        children: t['com.affine.workbench.split-view-menu.move-left']()
    }) : null;
    const FullScreenItem = views.length > 1 ? _jsx(MenuItem, {
        onClick: handleCloseOthers,
        preFix: _jsx(MenuIcon, {
            icon: _jsx(SoloViewIcon, {})
        }),
        children: t['com.affine.workbench.split-view-menu.keep-this-one']()
    }) : null;
    const MoveRightItem = viewIndex < views.length - 1 ? _jsx(MenuItem, {
        onClick: handleMoveRight,
        preFix: _jsx(MenuIcon, {
            icon: _jsx(MoveToRightDuotoneIcon, {})
        }),
        children: t['com.affine.workbench.split-view-menu.move-right']()
    }) : null;
    return _jsxs(_Fragment, {
        children: [
            MoveRightItem,
            MoveLeftItem,
            FullScreenItem,
            CloseItem
        ]
    });
};
