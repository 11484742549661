function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _init_canvasSlot, _init_doc, _init_host, _init_portal, _init_refModel, _init_renderModel, _init_renderer, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { literal, html as staticHtml, unsafeStatic } from 'lit/static-html.js';
import './portal/generic-block.js';
import './portal/note.js';
const portalMap = {
    'affine:note': 'surface-ref-note-portal'
};
const getPortalTag = (model)=>{
    const tag = portalMap[model.flavour];
    return tag ?? 'surface-ref-generic-block-portal';
};
let _SurfaceRefPortal;
_dec = customElement('surface-ref-portal'), _dec1 = query('.stacking-canvas'), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = query('.surface-blocks-portal'), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_SurfaceRefPortal), _initClass();
    }
    static{
        class SurfaceRefPortal extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_canvasSlot, _init_doc, _init_host, _init_portal, _init_refModel, _init_renderModel, _init_renderer, _initProto], c: [_SurfaceRefPortal, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "canvasSlot"
                    ],
                    [
                        _dec2,
                        1,
                        "doc"
                    ],
                    [
                        _dec3,
                        1,
                        "host"
                    ],
                    [
                        _dec4,
                        1,
                        "portal"
                    ],
                    [
                        _dec5,
                        1,
                        "refModel"
                    ],
                    [
                        _dec6,
                        1,
                        "renderModel"
                    ],
                    [
                        _dec7,
                        1,
                        "renderer"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    .surface-blocks-portal {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      transform-origin: 0 0;
    }
    .stacking-canvas {
      position: absolute;
      left: 0;
      top: 0;
    }

    .stacking-canvas > canvas {
      transform: var(--stacking-canvas-transform);
      transform-origin: 0 0;
      position: absolute;
      left: 0;
      top: 0;
    }
  `;
            }
            _getBlocksInGroup(model) {
                return Array.from(model.childIds).map((id)=>this.doc.getBlockById(id)).filter((el)=>el);
            }
            render() {
                return html`<div class="surface-blocks-portal">
      <div class="stacking-canvas"></div>
      ${this._renderGfxBlocks()}
    </div>`;
            }
            setStackingCanvas(canvases) {
                if (this.canvasSlot) {
                    this.canvasSlot.replaceChildren(...canvases);
                }
            }
            get surfaceService() {
                return this.host.std.spec.getService('affine:surface');
            }
            #___private_canvasSlot_1;
            get canvasSlot() {
                return this.#___private_canvasSlot_1;
            }
            set canvasSlot(_v) {
                this.#___private_canvasSlot_1 = _v;
            }
            #___private_doc_2;
            get doc() {
                return this.#___private_doc_2;
            }
            set doc(_v) {
                this.#___private_doc_2 = _v;
            }
            #___private_host_3;
            get host() {
                return this.#___private_host_3;
            }
            set host(_v) {
                this.#___private_host_3 = _v;
            }
            #___private_portal_4;
            get portal() {
                return this.#___private_portal_4;
            }
            set portal(_v) {
                this.#___private_portal_4 = _v;
            }
            #___private_refModel_5;
            get refModel() {
                return this.#___private_refModel_5;
            }
            set refModel(_v) {
                this.#___private_refModel_5 = _v;
            }
            #___private_renderModel_6;
            get renderModel() {
                return this.#___private_renderModel_6;
            }
            set renderModel(_v) {
                this.#___private_renderModel_6 = _v;
            }
            #___private_renderer_7;
            get renderer() {
                return this.#___private_renderer_7;
            }
            set renderer(_v) {
                this.#___private_renderer_7 = _v;
            }
            constructor(...args){
                super(...args);
                this._getBlocksInFrame = (model)=>{
                    const bound = model.elementBound;
                    const candidates = this.surfaceService?.layer.blocksGrid.search(bound) ?? [];
                    return candidates;
                };
                this._renderGfxBlocks = ()=>{
                    const refModel = this.refModel;
                    const blocks = 'flavour' in refModel ? this._getBlocksInFrame(refModel) : this._getBlocksInGroup(refModel);
                    const blockLayers = this.surfaceService?.layer.layers.filter((layer)=>layer.type === 'block') ?? [];
                    let currentLayerIdx = 0;
                    let currentIdxOffset = 0;
                    return repeat(blocks, (model)=>model.id, (model, index)=>{
                        const tag = literal`${unsafeStatic(getPortalTag(model))}`;
                        let currentLayer = blockLayers[currentLayerIdx];
                        if (!blockLayers[currentLayerIdx].set.has(model)) {
                            while(!currentLayer.set.has(model)){
                                currentLayer = blockLayers[++currentLayerIdx];
                            }
                            currentIdxOffset = 0;
                        }
                        const zIndex = currentLayer.zIndex + currentIdxOffset++;
                        return staticHtml`<${tag}
          .index=${index}
          .model=${model}
          .doc=${this.doc}
          .host=${this.host}
          .renderer=${this.renderer}
          .renderModel=${this.renderModel}
          style=${styleMap({
                            'z-index': zIndex
                        })}
        ></${tag}>`;
                    });
                };
                this.setViewport = (viewport)=>{
                    this.requestUpdate();
                    this.updateComplete.then(()=>{
                        this.portal?.style.setProperty('transform', `translate(${viewport.translateX}px, ${viewport.translateY}px) scale(${viewport.zoom})`);
                        this.portal?.style.setProperty('transform-origin', '0 0');
                        this.canvasSlot?.style.setProperty('--stacking-canvas-transform', `scale(${1 / viewport.zoom}) translate(${-viewport.translateX}px, ${-viewport.translateY}px)`);
                    }).catch(console.error);
                };
                this.#___private_canvasSlot_1 = (_initProto(this), _init_canvasSlot(this));
                this.#___private_doc_2 = _init_doc(this);
                this.#___private_host_3 = _init_host(this);
                this.#___private_portal_4 = _init_portal(this);
                this.#___private_refModel_5 = _init_refModel(this);
                this.#___private_renderModel_6 = _init_renderModel(this);
                this.#___private_renderer_7 = _init_renderer(this);
            }
        }
    }
}();
export { _SurfaceRefPortal as SurfaceRefPortal };
