import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
import { MemoryMemento } from '../../../storage';
export class GlobalContext extends Entity {
    define(key) {
        this.memento.set(key, null);
        const livedata$ = LiveData.from(this.memento.watch(key), null);
        return {
            get: ()=>this.memento.get(key),
            set: (value)=>this.memento.set(key, value),
            $: livedata$
        };
    }
    constructor(...args){
        super(...args);
        this.memento = new MemoryMemento();
        this.workspaceId = this.define('workspaceId');
        this.isDoc = this.define('isDoc');
        this.isTrashDoc = this.define('isTrashDoc');
        this.docId = this.define('docId');
        this.docMode = this.define('docMode');
        this.isCollection = this.define('isCollection');
        this.collectionId = this.define('collectionId');
        this.isTrash = this.define('isTrash');
        this.isTag = this.define('isTag');
        this.tagId = this.define('tagId');
        this.isFeed = this.define('isFeed');
        this.feedId = this.define('feedId');
        this.isAllDocs = this.define('isAllDocs');
    }
}
