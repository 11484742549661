import { BlockService } from '@blocksuite/block-std';
import { Slot } from '@blocksuite/store';
import { insertLinkByQuickSearchCommand } from './commands/insert-link-by-quick-search.js';
export class EmbedLinkedDocBlockService extends BlockService {
    mounted() {
        super.mounted();
        this.std.command.add('insertLinkByQuickSearch', insertLinkByQuickSearchCommand);
    }
    constructor(...args){
        super(...args);
        this.slots = {
            linkedDocCreated: new Slot()
        };
    }
}
