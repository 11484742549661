import { assertExists } from '@blocksuite/global/utils';
import { INLINE_ROOT_ATTR } from '@blocksuite/inline';
import { FORMAT_BLOCK_SUPPORT_FLAVOURS, FORMAT_NATIVE_SUPPORT_FLAVOURS, FORMAT_TEXT_SUPPORT_FLAVOURS } from '../../_common/configs/text-format/consts.js';
import { BLOCK_ID_ATTR } from '../../_common/consts.js';
function isActive(std, key) {
    const [result] = std.command.chain().isTextStyleActive({
        key
    }).run();
    return result;
}
function handleCommonStyle(std, key) {
    const active = isActive(std, key);
    const payload = {
        styles: {
            [key]: active ? null : true
        }
    };
    return std.command.chain().try((chain)=>[
            chain.getTextSelection().formatText(payload),
            chain.getBlockSelections().formatBlock(payload),
            chain.formatNative(payload)
        ]).run();
}
export function generateTextStyleCommand(key) {
    return (ctx, next)=>{
        const [result] = handleCommonStyle(ctx.std, key);
        if (result) {
            return next();
        }
        return false;
    };
}
function getCombinedFormatFromInlineEditors(inlineEditors) {
    const formatArr = [];
    inlineEditors.forEach(([inlineEditor, inlineRange])=>{
        if (!inlineRange) return;
        const format = inlineEditor.getFormat(inlineRange);
        formatArr.push(format);
    });
    if (formatArr.length === 0) return {};
    return formatArr.reduce((acc, cur)=>{
        const newFormat = {};
        for(const key in acc){
            const typedKey = key;
            if (acc[typedKey] === cur[typedKey]) {
                newFormat[typedKey] = acc[typedKey];
            }
        }
        return newFormat;
    });
}
function getSelectedInlineEditors(blocks, filter) {
    return blocks.flatMap((el)=>{
        const inlineRoot = el.querySelector(`[${INLINE_ROOT_ATTR}]`);
        if (inlineRoot) {
            return filter(inlineRoot);
        }
        return [];
    });
}
function handleCurrentSelection(chain, handler) {
    return chain.try((chain)=>[
            chain.getTextSelection().getSelectedBlocks({
                types: [
                    'text'
                ],
                filter: (el)=>FORMAT_TEXT_SUPPORT_FLAVOURS.includes(el.model.flavour)
            }).inline((ctx, next)=>{
                const { selectedBlocks, currentTextSelection } = ctx;
                assertExists(selectedBlocks);
                assertExists(currentTextSelection);
                if (currentTextSelection.isCollapsed()) return false;
                const selectedInlineEditors = getSelectedInlineEditors(selectedBlocks, (inlineRoot)=>{
                    const inlineRange = inlineRoot.inlineEditor.getInlineRange();
                    if (!inlineRange) return [];
                    return inlineRoot.inlineEditor;
                });
                const result = handler('text', selectedInlineEditors);
                if (!result) return false;
                if (result === true) {
                    return next();
                }
                return next(result);
            }),
            chain.getBlockSelections().getSelectedBlocks({
                types: [
                    'block'
                ],
                filter: (el)=>FORMAT_BLOCK_SUPPORT_FLAVOURS.includes(el.model.flavour)
            }).inline((ctx, next)=>{
                const { selectedBlocks } = ctx;
                assertExists(selectedBlocks);
                const selectedInlineEditors = getSelectedInlineEditors(selectedBlocks, (inlineRoot)=>inlineRoot.inlineEditor.yTextLength > 0 ? inlineRoot.inlineEditor : []);
                const result = handler('block', selectedInlineEditors);
                if (!result) return false;
                if (result === true) {
                    return next();
                }
                return next(result);
            }),
            chain.inline((ctx, next)=>{
                const selectedInlineEditors = Array.from(ctx.std.host.querySelectorAll(`[${INLINE_ROOT_ATTR}]`)).filter((el)=>{
                    const selection = document.getSelection();
                    if (!selection || selection.rangeCount === 0) return false;
                    const range = selection.getRangeAt(0);
                    return range.intersectsNode(el);
                }).filter((el)=>{
                    const block = el.closest(`[${BLOCK_ID_ATTR}]`);
                    if (block) {
                        return FORMAT_NATIVE_SUPPORT_FLAVOURS.includes(block.model.flavour);
                    }
                    return false;
                }).map((el)=>el.inlineEditor);
                const result = handler('native', selectedInlineEditors);
                if (!result) return false;
                if (result === true) {
                    return next();
                }
                return next(result);
            })
        ]);
}
export function getCombinedTextStyle(chain) {
    return handleCurrentSelection(chain, (type, inlineEditors)=>{
        if (type === 'text') {
            return {
                textStyle: getCombinedFormatFromInlineEditors(inlineEditors.map((e)=>[
                        e,
                        e.getInlineRange()
                    ]))
            };
        }
        if (type === 'block') {
            return {
                textStyle: getCombinedFormatFromInlineEditors(inlineEditors.map((e)=>[
                        e,
                        {
                            index: 0,
                            length: e.yTextLength
                        }
                    ]))
            };
        }
        if (type === 'native') {
            return {
                textStyle: getCombinedFormatFromInlineEditors(inlineEditors.map((e)=>[
                        e,
                        e.getInlineRange()
                    ]))
            };
        }
        return false;
    });
}
export function isFormatSupported(chain) {
    return handleCurrentSelection(chain, (_type, inlineEditors)=>inlineEditors.length > 0);
}
