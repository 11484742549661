import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Input } from '../../ui/input';
import * as styles from './share.css';
export const AuthInput = ({ label, error, errorHint, withoutHint = false, onEnter, className, ...inputProps })=>{
    return _jsxs("div", {
        className: clsx(styles.authInputWrapper, {
            'without-hint': withoutHint
        }),
        children: [
            label ? _jsx("label", {
                children: label
            }) : null,
            _jsx(Input, {
                className: clsx(className),
                size: "extraLarge",
                status: error ? 'error' : 'default',
                onKeyDown: (e)=>{
                    if (e.key === 'Enter') {
                        onEnter?.();
                    }
                },
                ...inputProps
            }),
            error && errorHint && !withoutHint ? _jsx("div", {
                className: clsx(styles.formHint, {
                    error: error
                }),
                children: errorHint
            }) : null
        ]
    });
};
