function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _BlockComponent, _dec1, _init_rootElementContainer, _initProto;
import { BlockComponent } from '@blocksuite/block-std';
import { css, html } from 'lit';
import { customElement, query } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { NoteDisplayMode, asyncFocusRichText, buildPath, focusTitle, getDocTitleInlineEditor, matchFlavours } from '../../_common/utils/index.js';
import { getScrollContainer } from '../../_common/utils/scroll-container.js';
import { PageClipboard } from '../clipboard/index.js';
import { PageKeyboardManager } from '../keyboard/keyboard-manager.js';
const DOC_BLOCK_CHILD_PADDING = 24;
const DOC_BOTTOM_PADDING = 32;
function testClickOnBlankArea(state, viewportLeft, viewportWidth, pageWidth, paddingLeft, paddingRight) {
    const blankLeft = viewportLeft + (viewportWidth - pageWidth) / 2 + paddingLeft;
    const blankRight = viewportLeft + (viewportWidth - pageWidth) / 2 + pageWidth - paddingRight;
    if (state.raw.clientX < blankLeft || state.raw.clientX > blankRight) {
        return true;
    }
    return false;
}
let _PageRootBlockComponent;
_dec = customElement('affine-page-root'), _dec1 = query('.affine-page-root-block-container');
new class extends _identity {
    constructor(){
        super(_PageRootBlockComponent), _initClass();
    }
    static{
        class PageRootBlockComponent extends (_BlockComponent = BlockComponent) {
            static{
                ({ e: [_init_rootElementContainer, _initProto], c: [_PageRootBlockComponent, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "rootElementContainer"
                    ]
                ], [
                    _dec
                ], _BlockComponent));
            }
            static{
                this.styles = css`
    editor-host:has(> affine-page-root, * > affine-page-root) {
      display: block;
      height: 100%;
    }

    affine-page-root {
      display: block;
      height: 100%;
    }

    .affine-page-root-block-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      font-family: var(--affine-font-family);
      font-size: var(--affine-font-base);
      line-height: var(--affine-line-height);
      color: var(--affine-text-primary-color);
      font-weight: 400;
      max-width: var(--affine-editor-width);
      margin: 0 auto;
      /* cursor: crosshair; */
      cursor: default;

      /* Leave a place for drag-handle */
      /* Do not use prettier format this style, or it will be broken */
      /* prettier-ignore */
      padding-left: var(--affine-editor-side-padding, ${DOC_BLOCK_CHILD_PADDING}px);
      /* prettier-ignore */
      padding-right: var(--affine-editor-side-padding, ${DOC_BLOCK_CHILD_PADDING}px);
      /* prettier-ignore */
      padding-bottom: var(--affine-editor-bottom-padding, ${DOC_BOTTOM_PADDING}px);
    }

    /* Extra small devices (phones, 640px and down) */
    @container viewport (width <= 640px) {
      .affine-page-root-block-container {
        padding-left: ${DOC_BLOCK_CHILD_PADDING}px;
        padding-right: ${DOC_BLOCK_CHILD_PADDING}px;
      }
    }

    .affine-block-element {
      display: block;
    }

    @media print {
      .selected {
        background-color: transparent !important;
      }
    }
  `;
            }
            _createDefaultNoteBlock() {
                const { doc } = this;
                const noteId = doc.addBlock('affine:note', {}, doc.root?.id);
                return doc.getBlockById(noteId);
            }
            _getDefaultNoteBlock() {
                return this.doc.root?.children.find((child)=>child.flavour === 'affine:note') ?? this._createDefaultNoteBlock();
            }
            _initViewportResizeEffect() {
                const viewport = this.viewport;
                const viewportElement = this.viewportElement;
                if (!viewport || !viewportElement) {
                    return;
                }
                const resizeObserver = new ResizeObserver((entries)=>{
                    for (const { target } of entries){
                        if (target === viewportElement) {
                            this.slots.viewportUpdated.emit(viewport);
                            break;
                        }
                    }
                });
                resizeObserver.observe(viewportElement);
                this.disposables.add(()=>{
                    resizeObserver.unobserve(viewportElement);
                    resizeObserver.disconnect();
                });
            }
            connectedCallback() {
                super.connectedCallback();
                this.clipboardController.hostConnected();
                this.keyboardManager = new PageKeyboardManager(this);
                this.bindHotKey({
                    'Mod-a': ()=>{
                        const blocks = this.model.children.filter((model)=>{
                            if (matchFlavours(model, [
                                'affine:note'
                            ])) {
                                const note = model;
                                if (note.displayMode === NoteDisplayMode.EdgelessOnly) return false;
                                return true;
                            }
                            return false;
                        }).flatMap((model)=>{
                            return model.children.map((child)=>{
                                return this.std.selection.create('block', {
                                    blockId: child.id
                                });
                            });
                        });
                        this.std.selection.setGroup('note', blocks);
                        return true;
                    },
                    ArrowUp: ()=>{
                        const selection = this.host.selection;
                        const sel = selection.value.find((sel)=>sel.is('text') || sel.is('block'));
                        if (!sel) return;
                        let model = null;
                        let path = buildPath(this.doc.getBlockById(sel.blockId));
                        while(path.length > 0 && !model){
                            const m = this.doc.getBlockById(path[path.length - 1]);
                            if (m && m.flavour === 'affine:note') {
                                model = m;
                            }
                            path = path.slice(0, -1);
                        }
                        if (!model) return;
                        const prevNote = this.doc.getPrev(model);
                        if (!prevNote || prevNote.flavour !== 'affine:note') {
                            const isFirstText = sel.is('text') && sel.start.index === 0;
                            const isBlock = sel.is('block');
                            if (isBlock || isFirstText) {
                                focusTitle(this.host);
                            }
                            return;
                        }
                        const notes = this.doc.getBlockByFlavour('affine:note');
                        const index = notes.indexOf(prevNote);
                        if (index !== 0) return;
                        const range = this.std.range.value;
                        requestAnimationFrame(()=>{
                            const currentRange = this.std.range.value;
                            if (!range || !currentRange) return;
                            if (range.startContainer === currentRange.startContainer && range.startOffset === currentRange.startOffset && range.endContainer === currentRange.endContainer && range.endOffset === currentRange.endOffset) {
                                const titleInlineEditor = getDocTitleInlineEditor(this.host);
                                if (titleInlineEditor) {
                                    titleInlineEditor.focusEnd();
                                }
                            }
                        });
                    }
                });
                this.handleEvent('click', (ctx)=>{
                    const event = ctx.get('pointerState');
                    if (event.raw.target !== this && event.raw.target !== this.viewportElement && event.raw.target !== this.rootElementContainer) {
                        return;
                    }
                    const { paddingLeft, paddingRight } = window.getComputedStyle(this.rootElementContainer);
                    if (!this.viewport) return;
                    const isClickOnBlankArea = testClickOnBlankArea(event, this.viewport.left, this.viewport.clientWidth, this.rootElementContainer.clientWidth, parseFloat(paddingLeft), parseFloat(paddingRight));
                    if (isClickOnBlankArea) {
                        this.host.selection.clear([
                            'block'
                        ]);
                        return;
                    }
                    let newTextSelectionId = null;
                    const readonly = this.doc.readonly;
                    const lastNote = this.model.children.slice().reverse().find((child)=>{
                        const isNote = matchFlavours(child, [
                            'affine:note'
                        ]);
                        if (!isNote) return false;
                        const note = child;
                        const displayOnDoc = !!note.displayMode && note.displayMode !== NoteDisplayMode.EdgelessOnly;
                        return displayOnDoc;
                    });
                    if (!lastNote) {
                        if (readonly) return;
                        const noteId = this.doc.addBlock('affine:note', {}, this.model.id);
                        const paragraphId = this.doc.addBlock('affine:paragraph', {}, noteId);
                        newTextSelectionId = paragraphId;
                    } else {
                        const last = lastNote.children.at(-1);
                        if (!last || !last.text || matchFlavours(last, [
                            'affine:code',
                            'affine:divider',
                            'affine:image',
                            'affine:database',
                            'affine:bookmark',
                            'affine:attachment',
                            'affine:surface-ref'
                        ]) || /affine:embed-*/.test(last.flavour)) {
                            if (readonly) return;
                            const paragraphId = this.doc.addBlock('affine:paragraph', {}, lastNote.id);
                            newTextSelectionId = paragraphId;
                        }
                    }
                    this.updateComplete.then(()=>{
                        if (!newTextSelectionId) return;
                        this.host.selection.setGroup('note', [
                            this.host.selection.create('text', {
                                from: {
                                    blockId: newTextSelectionId,
                                    index: 0,
                                    length: 0
                                },
                                to: null
                            })
                        ]);
                    }).catch(console.error);
                });
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this.clipboardController.hostDisconnected();
                this._disposables.dispose();
                this.keyboardManager = null;
            }
            firstUpdated() {
                this._initViewportResizeEffect();
                const noteModels = this.model.children.filter((model)=>matchFlavours(model, [
                        'affine:note'
                    ]));
                noteModels.forEach((note)=>{
                    this.disposables.add(note.propsUpdated.on(({ key })=>{
                        if (key === 'displayMode') {
                            this.requestUpdate();
                        }
                    }));
                });
            }
            renderBlock() {
                const content = html`${repeat(this.model.children.filter((child)=>{
                    const isNote = matchFlavours(child, [
                        'affine:note'
                    ]);
                    const note = child;
                    const displayOnEdgeless = !!note.displayMode && note.displayMode === NoteDisplayMode.EdgelessOnly;
                    return !(isNote && displayOnEdgeless);
                }), (child)=>child.id, (child)=>this.host.renderModel(child))}`;
                const widgets = html`${repeat(Object.entries(this.widgets), ([id])=>id, ([_, widget])=>widget)}`;
                return html`
      <div class="affine-page-root-block-container">${content} ${widgets}</div>
    `;
            }
            get rootScrollContainer() {
                return getScrollContainer(this);
            }
            get slots() {
                return this.service.slots;
            }
            get viewport() {
                if (!this.viewportElement) {
                    return null;
                }
                const { scrollLeft, scrollTop, scrollWidth, scrollHeight, clientWidth, clientHeight } = this.viewportElement;
                const { top, left } = this.viewportElement.getBoundingClientRect();
                return {
                    top,
                    left,
                    scrollLeft,
                    scrollTop,
                    scrollWidth,
                    scrollHeight,
                    clientWidth,
                    clientHeight
                };
            }
            get viewportElement() {
                if (this._viewportElement) return this._viewportElement;
                this._viewportElement = this.host.closest('.affine-page-viewport');
                return this._viewportElement;
            }
            #___private_rootElementContainer_1;
            get rootElementContainer() {
                return this.#___private_rootElementContainer_1;
            }
            set rootElementContainer(_v) {
                this.#___private_rootElementContainer_1 = _v;
            }
            constructor(...args){
                super(...args);
                this._viewportElement = null;
                this.clipboardController = new PageClipboard(this);
                this.focusFirstParagraph = ()=>{
                    const defaultNote = this._getDefaultNoteBlock();
                    const firstText = defaultNote?.children.find((block)=>matchFlavours(block, [
                            'affine:paragraph',
                            'affine:list',
                            'affine:code'
                        ]));
                    if (firstText) {
                        asyncFocusRichText(this.host, firstText.id)?.catch(console.error);
                    } else {
                        const newFirstParagraphId = this.doc.addBlock('affine:paragraph', {}, defaultNote, 0);
                        asyncFocusRichText(this.host, newFirstParagraphId)?.catch(console.error);
                    }
                };
                this.keyboardManager = null;
                this.prependParagraphWithText = (text)=>{
                    const newFirstParagraphId = this.doc.addBlock('affine:paragraph', {
                        text
                    }, this._getDefaultNoteBlock(), 0);
                    asyncFocusRichText(this.host, newFirstParagraphId)?.catch(console.error);
                };
                this.#___private_rootElementContainer_1 = (_initProto(this), _init_rootElementContainer(this));
            }
        }
    }
}();
export { _PageRootBlockComponent as PageRootBlockComponent };
