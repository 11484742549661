function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _SignalWatcher, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _init__displayModeButtonGroup, _init__displayModePanel, _init__showPopper, _init_activeHeadingId, _init_doc, _init_editorMode, _init_enableNotesSorting, _init_index, _init_invisible, _init_note, _init_number, _init_showPreviewIcon, _init_status, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { NoteDisplayMode, ThemeObserver, createButtonPopper, on, once } from '@blocksuite/blocks';
import { SignalWatcher } from '@lit-labs/preact-signals';
import { baseTheme } from '@toeverything/theme';
import { LitElement, css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { HiddenIcon, SmallArrowDownIcon } from '../../_common/icons.js';
import './outline-preview.js';
const styles = css`
  :host {
    display: block;
    position: relative;
  }

  .card-container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  .card-preview {
    position: relative;

    width: 100%;

    border-radius: 4px;

    cursor: default;
    user-select: none;
  }

  .card-preview.edgeless:hover {
    background: var(--affine-hover-color);
  }

  .card-header-container {
    padding: 0 8px;
    width: 100%;
    min-height: 28px;
    display: none;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
  }

  .card-header-container.enable-sorting {
    display: flex;
  }

  .card-header-container .card-number {
    text-align: center;
    font-size: var(--affine-font-sm);
    font-family: ${unsafeCSS(baseTheme.fontSansFamily)};
    color: var(--affine-brand-color, #1e96eb);
    font-weight: 500;
    line-height: 14px;
    line-height: 20px;
  }

  .card-header-container .card-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-header-container .card-divider {
    height: 1px;
    flex: 1;
    border-top: 1px dashed var(--affine-border-color);
    transform: translateY(50%);
  }

  .display-mode-button-group {
    display: none;
    align-items: center;
    gap: 4px;
    padding: 2px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
  }

  .card-preview:hover .display-mode-button-group {
    display: flex;
  }

  .display-mode-button-label {
    color: var(--affine-text-primary-color);
  }

  .display-mode-button {
    display: flex;
    border-radius: 4px;
    background-color: var(--affine-hover-color);
    align-items: center;
  }

  .current-mode-label {
    display: flex;
    padding: 2px 0px 2px 4px;
    align-items: center;
  }

  note-display-mode-panel {
    position: absolute;
    display: none;
    background: var(--affine-background-overlay-panel-color);
    border-radius: 8px;
    box-shadow: var(--affine-shadow-2);
    box-sizing: border-box;
    padding: 8px;
    font-size: var(--affine-font-sm);
    color: var(--affine-text-primary-color);
    line-height: 22px;
    font-weight: 400;
    font-family: ${unsafeCSS(baseTheme.fontSansFamily)};
  }

  note-display-mode-panel[data-show] {
    display: flex;
  }

  .card-content {
    font-family: ${unsafeCSS(baseTheme.fontSansFamily)};
    user-select: none;
    color: var(--affine-text-primary-color);
  }

  .card-preview.edgeless .card-content:hover {
    cursor: pointer;
  }

  .card-preview.edgeless .card-header-container:hover {
    cursor: grab;
  }

  .card-container.placeholder {
    pointer-events: none;
    opacity: 0.5;
  }

  .card-container.selected .card-preview.edgeless {
    background: var(--affine-hover-color);
  }

  .card-container.placeholder .card-preview.edgeless {
    background: var(--affine-hover-color);
    opacity: 0.9;
  }

  .card-container[data-sortable='true'] {
    padding: 2px 0;
  }

  .card-container[data-invisible='true'] .card-header-container .card-number,
  .card-container[data-invisible='true']
    .card-header-container
    .card-header-icon,
  .card-container[data-invisible='true'] .card-preview .card-content {
    color: var(--affine-text-disable-color);
    pointer-events: none;
  }

  .card-preview.page outline-block-preview:hover {
    color: var(--affine-brand-color);
  }
`;
export const AFFINE_OUTLINE_NOTE_CARD = 'affine-outline-note-card';
let _OutlineNoteCard;
_dec = customElement(AFFINE_OUTLINE_NOTE_CARD), _dec1 = query('.display-mode-button-group'), _dec2 = query('note-display-mode-panel'), _dec3 = state(), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    attribute: false
}), _dec11 = property({
    attribute: false
}), _dec12 = property({
    attribute: false
}), _dec13 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_OutlineNoteCard), _initClass();
    }
    static{
        class OutlineNoteCard extends (_SignalWatcher = SignalWatcher(WithDisposable(LitElement))) {
            static{
                ({ e: [_init__displayModeButtonGroup, _init__displayModePanel, _init__showPopper, _init_activeHeadingId, _init_doc, _init_editorMode, _init_enableNotesSorting, _init_index, _init_invisible, _init_note, _init_number, _init_showPreviewIcon, _init_status, _initProto], c: [_OutlineNoteCard, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_displayModeButtonGroup"
                    ],
                    [
                        _dec2,
                        1,
                        "_displayModePanel"
                    ],
                    [
                        _dec3,
                        1,
                        "_showPopper"
                    ],
                    [
                        _dec4,
                        1,
                        "activeHeadingId"
                    ],
                    [
                        _dec5,
                        1,
                        "doc"
                    ],
                    [
                        _dec6,
                        1,
                        "editorMode"
                    ],
                    [
                        _dec7,
                        1,
                        "enableNotesSorting"
                    ],
                    [
                        _dec8,
                        1,
                        "index"
                    ],
                    [
                        _dec9,
                        1,
                        "invisible"
                    ],
                    [
                        _dec10,
                        1,
                        "note"
                    ],
                    [
                        _dec11,
                        1,
                        "number"
                    ],
                    [
                        _dec12,
                        1,
                        "showPreviewIcon"
                    ],
                    [
                        _dec13,
                        1,
                        "status"
                    ]
                ], [
                    _dec
                ], _SignalWatcher));
            }
            static{
                this.styles = styles;
            }
            _dispatchClickBlockEvent(block) {
                const event = new CustomEvent('clickblock', {
                    detail: {
                        blockId: block.id
                    }
                });
                this.dispatchEvent(event);
            }
            _dispatchDisplayModeChangeEvent(note, newMode) {
                const event = new CustomEvent('displaymodechange', {
                    detail: {
                        note,
                        newMode
                    }
                });
                this.dispatchEvent(event);
            }
            _dispatchDragEvent(e) {
                e.preventDefault();
                if (e.button !== 0 || this.editorMode === 'page' || !this.enableNotesSorting) return;
                const { clientX: startX, clientY: startY } = e;
                const disposeDragStart = on(this.ownerDocument, 'mousemove', (e)=>{
                    if (Math.abs(startX - e.clientX) < 5 && Math.abs(startY - e.clientY) < 5) {
                        return;
                    }
                    if (this.status !== 'selected') {
                        this._dispatchSelectEvent(e);
                    }
                    const event = new CustomEvent('drag');
                    this.dispatchEvent(event);
                    disposeDragStart();
                });
                once(this.ownerDocument, 'mouseup', ()=>{
                    disposeDragStart();
                });
            }
            _dispatchFitViewEvent(e) {
                e.stopPropagation();
                const event = new CustomEvent('fitview', {
                    detail: {
                        block: this.note
                    }
                });
                this.dispatchEvent(event);
            }
            _dispatchSelectEvent(e) {
                e.stopPropagation();
                const event = new CustomEvent('select', {
                    detail: {
                        id: this.note.id,
                        selected: this.status !== 'selected',
                        number: this.number,
                        multiselect: e.shiftKey
                    }
                });
                this.dispatchEvent(event);
            }
            _getCurrentModeLabel(mode) {
                switch(mode){
                    case NoteDisplayMode.DocAndEdgeless:
                        return 'Both';
                    case NoteDisplayMode.EdgelessOnly:
                        return 'Edgeless';
                    case NoteDisplayMode.DocOnly:
                        return 'Page';
                    default:
                        return 'Both';
                }
            }
            connectedCallback() {
                super.connectedCallback();
                const observer = new MutationObserver(()=>this.requestUpdate());
                observer.observe(this.ownerDocument.documentElement, {
                    subtree: false,
                    childList: false,
                    attributes: true,
                    attributeFilter: [
                        'data-theme'
                    ]
                });
                this._disposables.add(()=>observer.disconnect());
            }
            firstUpdated() {
                this._displayModePopper = createButtonPopper(this._displayModeButtonGroup, this._displayModePanel, ({ display })=>{
                    this._showPopper = display === 'show';
                }, {
                    mainAxis: 8,
                    crossAxis: -60
                });
                this.disposables.add(this._displayModePopper);
            }
            render() {
                if (this.note.isEmpty.peek()) return nothing;
                const mode = ThemeObserver.mode;
                const { children, displayMode } = this.note;
                const currentMode = this._getCurrentModeLabel(displayMode);
                const cardHeaderClasses = classMap({
                    'card-header-container': true,
                    'enable-sorting': this.enableNotesSorting
                });
                return html`
      <div
        data-invisible="${this.invisible ? 'true' : 'false'}"
        data-sortable="${this.enableNotesSorting ? 'true' : 'false'}"
        class="card-container ${this.status ?? ''} ${mode}"
      >
        <div
          class="card-preview ${this.editorMode}"
          @mousedown=${this._dispatchDragEvent}
          @click=${this._dispatchSelectEvent}
          @dblclick=${this._dispatchFitViewEvent}
        >
        ${html`<div class=${cardHeaderClasses}>
          ${this.invisible ? html`<span class="card-header-icon">${HiddenIcon}</span>` : html`<span class="card-number">${this.number}</span>`}
          <span class="card-divider"></span>
          <div class="display-mode-button-group">
            <span class="display-mode-button-label">Show in</span>
            <edgeless-tool-icon-button
              .tooltip=${this._showPopper ? '' : 'Display Mode'}
              .tipPosition=${'left-start'}
              .iconContainerPadding=${0}
              @click=${(e)=>{
                    e.stopPropagation();
                    this._displayModePopper?.toggle();
                }}
              @dblclick=${(e)=>e.stopPropagation()}
            >
              <div class="display-mode-button">
                <span class="current-mode-label">${currentMode}</span>
                ${SmallArrowDownIcon}
              </div>
            </edgeless-tool-icon-button>
          </div>
          <note-display-mode-panel
            .displayMode=${displayMode}
            .panelWidth=${220}
            .onSelect=${(newMode)=>{
                    this._dispatchDisplayModeChangeEvent(this.note, newMode);
                    this._displayModePopper?.hide();
                }}
          >
          </note-display-mode-panel>
        </div>`}
          <div class="card-content">
            ${children.map((block)=>{
                    return html`<affine-outline-block-preview
                .block=${block}
                .className=${this.activeHeadingId === block.id ? 'active' : ''}
                .showPreviewIcon=${this.showPreviewIcon}
                .disabledIcon=${this.invisible}
                .cardNumber=${this.number}
                .enableNotesSorting=${this.enableNotesSorting}
                @click=${()=>{
                        if (this.editorMode === 'edgeless' || this.invisible) return;
                        this._dispatchClickBlockEvent(block);
                    }}
              ></affine-outline-block-preview>`;
                })}
            </div>
          </div>
        </div>
      </div>
    `;
            }
            #___private__displayModeButtonGroup_1;
            get _displayModeButtonGroup() {
                return this.#___private__displayModeButtonGroup_1;
            }
            set _displayModeButtonGroup(_v) {
                this.#___private__displayModeButtonGroup_1 = _v;
            }
            #___private__displayModePanel_2;
            get _displayModePanel() {
                return this.#___private__displayModePanel_2;
            }
            set _displayModePanel(_v) {
                this.#___private__displayModePanel_2 = _v;
            }
            #___private__showPopper_3;
            get _showPopper() {
                return this.#___private__showPopper_3;
            }
            set _showPopper(_v) {
                this.#___private__showPopper_3 = _v;
            }
            #___private_activeHeadingId_4;
            get activeHeadingId() {
                return this.#___private_activeHeadingId_4;
            }
            set activeHeadingId(_v) {
                this.#___private_activeHeadingId_4 = _v;
            }
            #___private_doc_5;
            get doc() {
                return this.#___private_doc_5;
            }
            set doc(_v) {
                this.#___private_doc_5 = _v;
            }
            #___private_editorMode_6;
            get editorMode() {
                return this.#___private_editorMode_6;
            }
            set editorMode(_v) {
                this.#___private_editorMode_6 = _v;
            }
            #___private_enableNotesSorting_7;
            get enableNotesSorting() {
                return this.#___private_enableNotesSorting_7;
            }
            set enableNotesSorting(_v) {
                this.#___private_enableNotesSorting_7 = _v;
            }
            #___private_index_8;
            get index() {
                return this.#___private_index_8;
            }
            set index(_v) {
                this.#___private_index_8 = _v;
            }
            #___private_invisible_9;
            get invisible() {
                return this.#___private_invisible_9;
            }
            set invisible(_v) {
                this.#___private_invisible_9 = _v;
            }
            #___private_note_10;
            get note() {
                return this.#___private_note_10;
            }
            set note(_v) {
                this.#___private_note_10 = _v;
            }
            #___private_number_11;
            get number() {
                return this.#___private_number_11;
            }
            set number(_v) {
                this.#___private_number_11 = _v;
            }
            #___private_showPreviewIcon_12;
            get showPreviewIcon() {
                return this.#___private_showPreviewIcon_12;
            }
            set showPreviewIcon(_v) {
                this.#___private_showPreviewIcon_12 = _v;
            }
            #___private_status_13;
            get status() {
                return this.#___private_status_13;
            }
            set status(_v) {
                this.#___private_status_13 = _v;
            }
            constructor(...args){
                super(...args);
                this._displayModePopper = null;
                this.#___private__displayModeButtonGroup_1 = (_initProto(this), _init__displayModeButtonGroup(this));
                this.#___private__displayModePanel_2 = _init__displayModePanel(this);
                this.#___private__showPopper_3 = _init__showPopper(this, false);
                this.#___private_activeHeadingId_4 = _init_activeHeadingId(this, null);
                this.#___private_doc_5 = _init_doc(this);
                this.#___private_editorMode_6 = _init_editorMode(this, 'page');
                this.#___private_enableNotesSorting_7 = _init_enableNotesSorting(this);
                this.#___private_index_8 = _init_index(this);
                this.#___private_invisible_9 = _init_invisible(this, false);
                this.#___private_note_10 = _init_note(this);
                this.#___private_number_11 = _init_number(this);
                this.#___private_showPreviewIcon_12 = _init_showPreviewIcon(this);
                this.#___private_status_13 = _init_status(this, undefined);
            }
        }
    }
}();
export { _OutlineNoteCard as OutlineNoteCard };
