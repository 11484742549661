function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _init_left, _init_lines, _init_shadow, _init_top, _init_width, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { startDrag } from '../../../../../utils/drag.js';
import { getResultInRange } from '../../../../../utils/utils.js';
import { DEFAULT_COLUMN_MIN_WIDTH } from '../../consts.js';
let _TableVerticalIndicator;
_dec = customElement('data-view-table-vertical-indicator'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_TableVerticalIndicator), _initClass();
    }
    static{
        class TableVerticalIndicator extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_left, _init_lines, _init_shadow, _init_top, _init_width, _initProto], c: [_TableVerticalIndicator, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "left"
                    ],
                    [
                        _dec2,
                        1,
                        "lines"
                    ],
                    [
                        _dec3,
                        1,
                        "shadow"
                    ],
                    [
                        _dec4,
                        1,
                        "top"
                    ],
                    [
                        _dec5,
                        1,
                        "width"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    data-view-table-vertical-indicator {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1;
      pointer-events: none;
    }

    .vertical-indicator-container {
      position: absolute;
      pointer-events: none;
    }

    .vertical-indicator-group {
      position: absolute;
      z-index: 1;
      width: 100%;
      background-color: var(--affine-hover-color);
      pointer-events: none;
    }
    .vertical-indicator-group::after {
      position: absolute;
      z-index: 1;
      width: 2px;
      height: 100%;
      content: '';
      right: 0;
      background-color: var(--affine-primary-color);
      border-radius: 1px;
    }
    .with-shadow.vertical-indicator-group::after {
      box-shadow: 0px 0px 8px 0px rgba(30, 150, 235, 0.35);
    }
  `;
            }
            render() {
                const containerStyle = styleMap({
                    top: `${this.top}px`,
                    left: `${this.left}px`,
                    width: `${Math.max(this.width, 1)}px`
                });
                return html`
      <div class="vertical-indicator-container" style=${containerStyle}>
        ${repeat(this.lines, ({ top, bottom })=>{
                    const groupStyle = styleMap({
                        top: `${top}px`,
                        height: `${bottom - top}px`
                    });
                    const groupClass = classMap({
                        'with-shadow': this.shadow,
                        'vertical-indicator-group': true
                    });
                    return html`<div class="${groupClass}" style=${groupStyle}></div>`;
                })}
      </div>
    `;
            }
            #___private_left_1;
            get left() {
                return this.#___private_left_1;
            }
            set left(_v) {
                this.#___private_left_1 = _v;
            }
            #___private_lines_2;
            get lines() {
                return this.#___private_lines_2;
            }
            set lines(_v) {
                this.#___private_lines_2 = _v;
            }
            #___private_shadow_3;
            get shadow() {
                return this.#___private_shadow_3;
            }
            set shadow(_v) {
                this.#___private_shadow_3 = _v;
            }
            #___private_top_4;
            get top() {
                return this.#___private_top_4;
            }
            set top(_v) {
                this.#___private_top_4 = _v;
            }
            #___private_width_5;
            get width() {
                return this.#___private_width_5;
            }
            set width(_v) {
                this.#___private_width_5 = _v;
            }
            constructor(...args){
                super(...args);
                this.#___private_left_1 = (_initProto(this), _init_left(this));
                this.#___private_lines_2 = _init_lines(this);
                this.#___private_shadow_3 = _init_shadow(this, false);
                this.#___private_top_4 = _init_top(this);
                this.#___private_width_5 = _init_width(this);
            }
        }
    }
}();
export const getTableGroupRects = (tableContainer)=>{
    const tableRect = tableContainer.getBoundingClientRect();
    const groups = tableContainer.querySelectorAll('affine-data-view-table-group');
    return Array.from(groups).map((group)=>{
        const groupRect = group.getBoundingClientRect();
        const top = group.querySelector('.affine-database-column-header')?.getBoundingClientRect().top ?? groupRect.top;
        const bottom = group.querySelector('.affine-database-block-rows')?.getBoundingClientRect().bottom ?? groupRect.bottom;
        return {
            top: top - tableRect.top,
            bottom: bottom - tableRect.top
        };
    });
};
export const startDragWidthAdjustmentBar = (evt, tableContainer, width, column)=>{
    const scale = width / column.width$.value;
    const tableRect = tableContainer.getBoundingClientRect();
    const left = tableContainer.querySelector(`affine-database-header-column[data-column-id='${column.id}']`)?.getBoundingClientRect().left ?? 0;
    const rectList = getTableGroupRects(tableContainer);
    const preview = getVerticalIndicator();
    preview.display(column.width$.value * scale, tableRect.top, rectList, left);
    tableContainer.style.pointerEvents = 'none';
    startDrag(evt, {
        onDrag: ()=>({
                width: column.width$.value
            }),
        onMove: ({ x })=>{
            const width = Math.round(getResultInRange((x - left) / scale, DEFAULT_COLUMN_MIN_WIDTH, Infinity));
            preview.display(width * scale, tableRect.top, rectList, left);
            return {
                width
            };
        },
        onDrop: ({ width })=>{
            column.updateWidth(width);
        },
        onClear: ()=>{
            tableContainer.style.pointerEvents = 'auto';
            preview.remove();
        }
    });
};
let preview = null;
export const getVerticalIndicator = ()=>{
    if (!preview) {
        const dragBar = new _TableVerticalIndicator();
        preview = {
            display (width, top, lines, left, shadow = false) {
                document.body.append(dragBar);
                dragBar.left = left;
                dragBar.lines = lines;
                dragBar.top = top;
                dragBar.width = width;
                dragBar.shadow = shadow;
            },
            remove () {
                dragBar.remove();
            }
        };
    }
    return preview;
};
export { _TableVerticalIndicator as TableVerticalIndicator };
