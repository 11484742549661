import { PagePropertyType } from '@affine/core/modules/properties/services/schema';
import * as icons from '@blocksuite/icons/rc';
export const iconNames = [
    'ai',
    'email',
    'text',
    'dateTime',
    'keyboard',
    'pen',
    'account',
    'embedWeb',
    'layer',
    'pin',
    'appearance',
    'eraser',
    'layout',
    'presentation',
    'bookmark',
    'exportToHtml',
    'lightMode',
    'progress',
    'bulletedList',
    'exportToMarkdown',
    'link',
    'publish',
    'camera',
    'exportToPdf',
    'linkedEdgeless',
    'quote',
    'checkBoxCheckLinear',
    'exportToPng',
    'linkedPage',
    'save',
    'cloudWorkspace',
    'exportToSvg',
    'localData',
    'shape',
    'code',
    'favorite',
    'localWorkspace',
    'style',
    'codeBlock',
    'file',
    'lock',
    'tag',
    'collaboration',
    'folder',
    'multiSelect',
    'tags',
    'colorPicker',
    'frame',
    'new',
    'today',
    'contactWithUs',
    'grid',
    'now',
    'upgrade',
    'darkMode',
    'grouping',
    'number',
    'userGuide',
    'databaseKanbanView',
    'image',
    'numberedList',
    'view',
    'databaseListView',
    'inbox',
    'other',
    'viewLayers',
    'databaseTableView',
    'info',
    'page',
    'attachment',
    'delete',
    'issue',
    'paste',
    'heartbreak',
    'edgeless',
    'journal',
    'payment'
];
export const getDefaultIconName = (type)=>{
    switch(type){
        case 'text':
            return 'text';
        case 'tags':
            return 'tag';
        case 'date':
            return 'dateTime';
        case 'progress':
            return 'progress';
        case 'checkbox':
            return 'checkBoxCheckLinear';
        case 'number':
            return 'number';
        default:
            return 'text';
    }
};
export const getSafeIconName = (iconName, type)=>{
    return iconNames.includes(iconName) ? iconName : getDefaultIconName(type || PagePropertyType.Text);
};
const nameToComponentName = (iconName)=>{
    const capitalize = (s)=>s.charAt(0).toUpperCase() + s.slice(1);
    return `${capitalize(iconName)}Icon`;
};
export const nameToIcon = (iconName, type)=>{
    const Icon = icons[nameToComponentName(getSafeIconName(iconName, type))];
    if (!Icon) {
        throw new Error(`Icon ${iconName} not found`);
    }
    return Icon;
};
