import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider, Menu, Scrollable, useConfirmModal } from '@affine/component';
import { useAsyncCallback } from '@affine/core/hooks/affine-async-hooks';
import { useNavigateHelper } from '@affine/core/hooks/use-navigate-helper';
import { track } from '@affine/core/mixpanel';
import { TagService } from '@affine/core/modules/tag';
import { isNewTabTrigger } from '@affine/core/utils';
import { useTagI18N } from '@affine/core/modules/tag/entities/internal-tag';
import { useI18n } from '@affine/i18n';
import { ArrowDownSmallIcon, SearchIcon, ViewLayersIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService, WorkspaceService } from '@toeverything/infra';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CollectionService } from '../../../modules/collection';
import { usePageHelper } from '../../blocksuite/block-suite-page-list/utils';
import { createTagFilter } from '../filter/utils';
import { createEmptyCollection } from '../use-collection-manager';
import { useEditCollection, useEditCollectionName } from '../view/use-edit-collection';
import * as styles from './page-list-header.css';
import { PageListNewPageButton } from './page-list-new-page-button';
export const PageListHeader = ()=>{
    const t = useI18n();
    const workspace = useService(WorkspaceService).workspace;
    const { importFile, createEdgeless, createPage } = usePageHelper(workspace.docCollection);
    const title = useMemo(()=>{
        return t['com.affine.all-pages.header']();
    }, [
        t
    ]);
    const onImportFile = useAsyncCallback(async ()=>{
        const options = await importFile();
        if (options.isWorkspaceFile) {
            track.allDocs.header.actions.createWorkspace({
                control: 'import'
            });
        } else {
            track.allDocs.header.actions.createDoc({
                control: 'import'
            });
        }
    }, [
        importFile
    ]);
    return _jsxs("div", {
        className: styles.docListHeader,
        children: [
            _jsx("div", {
                className: styles.docListHeaderTitle,
                children: title
            }),
            _jsx(PageListNewPageButton, {
                size: "small",
                testId: "new-page-button-trigger",
                onCreateEdgeless: (e)=>createEdgeless(isNewTabTrigger(e) ? 'new-tab' : true),
                onCreatePage: (e)=>createPage(isNewTabTrigger(e) ? 'new-tab' : true),
                onImportFile: onImportFile,
                children: _jsx("div", {
                    className: styles.buttonText,
                    children: t['New Page']()
                })
            })
        ]
    });
};
export const CollectionPageListHeader = ({ collection, workspaceId })=>{
    const t = useI18n();
    const { jumpToCollections } = useNavigateHelper();
    const handleJumpToCollections = useCallback(()=>{
        jumpToCollections(workspaceId);
    }, [
        jumpToCollections,
        workspaceId
    ]);
    const collectionService = useService(CollectionService);
    const { node, open } = useEditCollection();
    const handleEdit = useAsyncCallback(async ()=>{
        const ret = await open({
            ...collection
        }, 'page');
        collectionService.updateCollection(collection.id, ()=>ret);
    }, [
        collection,
        collectionService,
        open
    ]);
    const workspace = useService(WorkspaceService).workspace;
    const { createEdgeless, createPage } = usePageHelper(workspace.docCollection);
    const { openConfirmModal } = useConfirmModal();
    const createAndAddDocument = useCallback((createDocumentFn)=>{
        const newDoc = createDocumentFn();
        collectionService.addPageToCollection(collection.id, newDoc.id);
    }, [
        collection.id,
        collectionService
    ]);
    const onConfirmAddDocument = useCallback((createDocumentFn)=>{
        openConfirmModal({
            title: t['com.affine.collection.add-doc.confirm.title'](),
            description: t['com.affine.collection.add-doc.confirm.description'](),
            cancelText: t['Cancel'](),
            confirmText: t['Confirm'](),
            confirmButtonOptions: {
                variant: 'primary'
            },
            onConfirm: ()=>createAndAddDocument(createDocumentFn)
        });
    }, [
        openConfirmModal,
        t,
        createAndAddDocument
    ]);
    const onCreateEdgeless = useCallback(()=>onConfirmAddDocument(createEdgeless), [
        createEdgeless,
        onConfirmAddDocument
    ]);
    const onCreatePage = useCallback(()=>onConfirmAddDocument(createPage), [
        createPage,
        onConfirmAddDocument
    ]);
    return _jsxs(_Fragment, {
        children: [
            node,
            _jsxs("div", {
                className: styles.docListHeader,
                children: [
                    _jsxs("div", {
                        className: styles.docListHeaderTitle,
                        children: [
                            _jsxs("div", {
                                style: {
                                    cursor: 'pointer'
                                },
                                onClick: handleJumpToCollections,
                                children: [
                                    t['com.affine.collections.header'](),
                                    " /"
                                ]
                            }),
                            _jsx("div", {
                                className: styles.titleIcon,
                                children: _jsx(ViewLayersIcon, {})
                            }),
                            _jsx("div", {
                                className: styles.titleCollectionName,
                                children: collection.name
                            })
                        ]
                    }),
                    _jsxs("div", {
                        className: styles.rightButtonGroup,
                        children: [
                            _jsx(Button, {
                                onClick: handleEdit,
                                children: t['Edit']()
                            }),
                            _jsx(PageListNewPageButton, {
                                size: "small",
                                testId: "new-page-button-trigger",
                                onCreateEdgeless: onCreateEdgeless,
                                onCreatePage: onCreatePage,
                                children: _jsx("div", {
                                    className: styles.buttonText,
                                    children: t['New Page']()
                                })
                            })
                        ]
                    })
                ]
            })
        ]
    });
};
export const TagPageListHeader = ({ tag, workspaceId })=>{
    const tagColor = useLiveData(tag.color$);
    const tagTitle = useLiveData(tag.value$);
    const tt = useTagI18N();
    const t = useI18n();
    const { jumpToTags, jumpToCollection } = useNavigateHelper();
    const collectionService = useService(CollectionService);
    const [openMenu, setOpenMenu] = useState(false);
    const { open, node } = useEditCollectionName({
        title: t['com.affine.editCollection.saveCollection'](),
        showTips: true
    });
    const handleJumpToTags = useCallback(()=>{
        jumpToTags(workspaceId);
    }, [
        jumpToTags,
        workspaceId
    ]);
    const saveToCollection = useCallback((collection)=>{
        collectionService.addCollection({
            ...collection,
            filterList: [
                createTagFilter(tag.id)
            ]
        });
        jumpToCollection(workspaceId, collection.id);
    }, [
        collectionService,
        tag.id,
        jumpToCollection,
        workspaceId
    ]);
    const handleClick = useCallback(()=>{
        open('').then((name)=>{
            return saveToCollection(createEmptyCollection(nanoid(), {
                name
            }));
        }).catch((err)=>{
            console.error(err);
        });
    }, [
        open,
        saveToCollection
    ]);
    return _jsxs(_Fragment, {
        children: [
            node,
            _jsxs("div", {
                className: styles.docListHeader,
                children: [
                    _jsxs("div", {
                        className: styles.docListHeaderTitle,
                        children: [
                            _jsxs("div", {
                                style: {
                                    cursor: 'pointer',
                                    lineHeight: '1.4em'
                                },
                                onClick: handleJumpToTags,
                                children: [
                                    t['Tags'](),
                                    " /"
                                ]
                            }),
                            _jsx(Menu, {
                                rootOptions: {
                                    open: openMenu,
                                    onOpenChange: setOpenMenu
                                },
                                contentOptions: {
                                    side: 'bottom',
                                    align: 'start',
                                    sideOffset: 18,
                                    avoidCollisions: false,
                                    className: styles.tagsMenu
                                },
                                items: _jsx(SwitchTag, {
                                    onClick: setOpenMenu
                                }),
                                children: _jsxs("div", {
                                    className: styles.tagSticky,
                                    children: [
                                        _jsx("div", {
                                            className: styles.tagIndicator,
                                            style: {
                                                backgroundColor: tagColor
                                            }
                                        }),
                                        _jsx("div", {
                                            className: styles.tagLabel,
                                            children: tt(tagTitle)
                                        }),
                                        _jsx(ArrowDownSmallIcon, {
                                            className: styles.arrowDownSmallIcon
                                        })
                                    ]
                                })
                            })
                        ]
                    }),
                    _jsx(Button, {
                        onClick: handleClick,
                        children: t['com.affine.editCollection.saveCollection']()
                    })
                ]
            })
        ]
    });
};
export const SwitchTag = ({ onClick })=>{
    const t = useI18n();
    const [inputValue, setInputValue] = useState('');
    const tagList = useService(TagService).tagList;
    const filteredTags = useLiveData(inputValue ? tagList.filterTagsByName$(inputValue) : tagList.tags$);
    const onInputChange = useCallback((e)=>{
        setInputValue(e.target.value);
    }, []);
    const handleClick = useCallback(()=>{
        setInputValue('');
        onClick(false);
    }, [
        onClick
    ]);
    return _jsxs("div", {
        className: styles.tagsEditorRoot,
        children: [
            _jsxs("div", {
                className: styles.tagsEditorSelectedTags,
                children: [
                    _jsx(SearchIcon, {
                        className: styles.searchIcon
                    }),
                    _jsx("input", {
                        value: inputValue,
                        onChange: onInputChange,
                        autoFocus: true,
                        className: styles.searchInput,
                        placeholder: t['com.affine.search-tags.placeholder']()
                    })
                ]
            }),
            _jsx(Divider, {}),
            _jsx("div", {
                className: styles.tagsEditorTagsSelector,
                children: _jsxs(Scrollable.Root, {
                    children: [
                        _jsxs(Scrollable.Viewport, {
                            className: styles.tagSelectorTagsScrollContainer,
                            children: [
                                filteredTags.map((tag)=>{
                                    return _jsx(TagLink, {
                                        tag: tag,
                                        onClick: handleClick
                                    }, tag.id);
                                }),
                                filteredTags.length === 0 ? _jsx("div", {
                                    className: clsx(styles.tagSelectorItem, 'disable'),
                                    children: t['Find 0 result']()
                                }) : null
                            ]
                        }),
                        _jsx(Scrollable.Scrollbar, {
                            style: {
                                transform: 'translateX(6px)'
                            }
                        })
                    ]
                })
            })
        ]
    });
};
const TagLink = ({ tag, onClick })=>{
    const tagColor = useLiveData(tag.color$);
    const tagTitle = useLiveData(tag.value$);
    const tt = useTagI18N();
    return _jsxs(Link, {
        className: styles.tagSelectorItem,
        "data-tag-id": tag.id,
        "data-tag-value": tagTitle,
        to: `/tag/${tag.id}`,
        onClick: onClick,
        children: [
            _jsx("div", {
                className: styles.tagIcon,
                style: {
                    background: tagColor
                }
            }),
            _jsx("div", {
                className: styles.tagSelectorItemText,
                children: tt(tagTitle)
            })
        ]
    }, tag.id);
};
