export * from './adapter/index.js';
export * from './migration/index.js';
export * from './reactive/index.js';
export * from './schema/index.js';
export * from './store/index.js';
export * from './transformer/index.js';
export { createAutoIncrementIdGenerator, createAutoIncrementIdGeneratorByClientId, nanoid, uuidv4 } from './utils/id-generator.js';
export * as Utils from './utils/utils.js';
export * from './yjs/index.js';
export { Slot } from '@blocksuite/global/utils';
import './utils/formatter.js';
const env = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : {};
const importIdentifier = '__ $BLOCKSUITE_STORE$ __';
if (env[importIdentifier] === true) {
    console.error('@blocksuite/store was already imported. This breaks constructor checks and will lead to issues!');
}
env[importIdentifier] = true;
