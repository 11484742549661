function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _init_backlinkList, _init_doc, _init_expanded, _init_showSelect, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { ArrowDownSmallIcon, DocIcon, DualLinkIcon16, LinkedDocIcon, PlusIcon, TagsIcon, popTagSelect } from '@blocksuite/blocks';
import { assertExists } from '@blocksuite/global/utils';
import { baseTheme } from '@toeverything/theme';
import { LitElement, css, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { BacklinkButton } from './backlink-popover.js';
import { DEFAULT_DOC_NAME, DOC_BLOCK_CHILD_PADDING } from './utils.js';
let _DocMetaTags;
_dec = customElement('doc-meta-tags'), _dec1 = state(), _dec2 = property({
    attribute: false
}), _dec3 = state(), _dec4 = state();
new class extends _identity {
    constructor(){
        super(_DocMetaTags), _initClass();
    }
    static{
        class DocMetaTags extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init_backlinkList, _init_doc, _init_expanded, _init_showSelect, _initProto], c: [_DocMetaTags, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "backlinkList"
                    ],
                    [
                        _dec2,
                        1,
                        "doc"
                    ],
                    [
                        _dec3,
                        1,
                        "expanded"
                    ],
                    [
                        _dec4,
                        1,
                        "showSelect"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    .doc-meta-container {
      font-family: ${unsafeCSS(baseTheme.fontSansFamily)};
      font-size: var(--affine-font-base);
      line-height: var(--affine-line-height);
      color: var(--affine-text-primary-color);
      display: block;
      box-sizing: border-box;
      max-width: var(--affine-editor-width);
      margin-left: auto;
      margin-right: auto;
      padding-left: var(
        --affine-editor-side-padding,
        ${DOC_BLOCK_CHILD_PADDING}px
      );
      padding-right: var(
        --affine-editor-side-padding,
        ${DOC_BLOCK_CHILD_PADDING}px
      );
    }

    /* Extra small devices (phones, 640px and down) */
    @container viewport (width <= 640px) {
      .doc-meta-container {
        padding-left: ${DOC_BLOCK_CHILD_PADDING}px;
        padding-right: ${DOC_BLOCK_CHILD_PADDING}px;
      }
    }

    .meta-data {
      border-radius: 8px;
      display: flex;
      align-items: center;
      height: 30px;
      cursor: pointer;
      justify-content: space-between;
      margin: 0 -12px;
    }

    .meta-data-content {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--affine-text-secondary-color);
    }

    .meta-data:hover {
      background-color: var(--affine-hover-color);
    }

    .tags-inline {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
    }

    .tags-inline .tag-list {
      display: flex;
      align-items: center;
    }

    .tag-inline {
      max-width: 100px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .expand {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }

    .expand svg {
      color: var(--affine-icon-color);
    }

    .meta-data-expanded {
      padding: 10px 12px 24px;
      margin: 0 -24px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: var(--affine-hover-color-filled);
      border-radius: 8px;
    }

    .meta-data-expanded-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      font-weight: 600;
      font-size: 14px;
      color: var(--affine-text-secondary-color);
      border-radius: 4px;
      cursor: pointer;
    }

    .meta-data-expanded-title:hover {
      background-color: var(--affine-hover-color);
    }

    .meta-data-expanded-title .close {
      transform: rotate(180deg);
      border-radius: 4px;
      display: flex;
      align-items: center;
    }

    @media print {
      .meta-data-expanded-title .close {
        display: none;
      }

      .expand {
        display: none;
      }
    }

    .meta-data-expanded-title .close:hover {
      background-color: var(--affine-hover-color);
    }

    .meta-data-expanded-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 0 12px;
    }

    .meta-data-expanded-column-item {
      display: flex;
      flex-direction: column;
    }

    .meta-data-expanded-column-item .backlink-title {
      display: flex;
      align-items: center;
      gap: 8px;
      fill: var(--affine-icon-color);
    }

    .meta-data-expanded-column-item .backlinks {
      margin-left: 24px;
    }

    .meta-data-expanded-item {
      display: flex;
      gap: 8px;
    }

    .meta-data-expanded-item .value {
      flex: 1;
    }

    .add-tag {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .add-tag svg {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      fill: var(--affine-icon-color);
    }

    .add-tag:hover svg {
      background-color: var(--affine-hover-color);
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .tag {
      padding: 4px 10px;
      border-radius: 8px;
      color: var(--affine-text-primary-color);
      font-size: 13px;
      line-height: 13px;
      display: flex;
      align-items: center;
      font-weight: 400;
      cursor: pointer;
    }

    .backlinks {
      display: flex;
      gap: 8px;
      flex-direction: column;
    }

    .backlinks .title {
      height: 28px;
      color: var(--affine-text-secondary-color);
      font-size: var(--affine-font-sm);
    }

    .backlinks .link {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 15px;
      cursor: pointer;
      width: max-content;
      border-radius: 4px;
      padding: 0 8px 0 4px;
      margin: 0 -8px 0 -4px;
    }

    .backlinks .link:hover {
      background-color: var(--affine-hover-color);
    }

    .backlinks .link svg {
      fill: var(--affine-icon-color);
    }

    .link-title {
      border-bottom: 0.5px solid var(--affine-divider-color);
    }

    .backlinks .link:hover .link-title {
      border-bottom-color: transparent;
    }
  `;
            }
            connectedCallback() {
                super.connectedCallback();
                this._listenBacklinkList();
                this._disposables.add(this.meta.docMetaUpdated.on(()=>{
                    this.requestUpdate();
                }));
            }
            render() {
                if (!this.expanded) {
                    return html`
        <div class="doc-meta-container caret-ignore">
          <div class="meta-data caret-ignore" @click="${this._toggle}">
            <div class="meta-data-content">
              ${this._renderBacklinkInline()} ${this._renderTagsInline()}
            </div>
            <div class="expand">${ArrowDownSmallIcon}</div>
          </div>
        </div>
      `;
                }
                return html`
      <div class="doc-meta-container caret-ignore">
        <div class="meta-data-expanded caret-ignore">
          <div class="meta-data-expanded-title" @click="${this._toggle}">
            <div>Doc info</div>
            <div class="close">${ArrowDownSmallIcon}</div>
          </div>
          <div class="meta-data-expanded-content">
            ${this._renderBacklinkExpanded()} ${this._renderTagsExpanded()}
          </div>
        </div>
      </div>
    `;
            }
            get meta() {
                return this.doc.collection.meta;
            }
            get options() {
                return this.meta.properties.tags?.options ?? [];
            }
            set options(tags) {
                this.tags = this.tags.filter((v)=>tags.find((x)=>x.id === v));
                this.doc.collection.meta.setProperties({
                    ...this.meta.properties,
                    tags: {
                        ...this.meta.properties.tags,
                        options: tags
                    }
                });
            }
            get pageRoot() {
                const pageViewport = this.closest('.affine-page-viewport');
                assertExists(pageViewport);
                const pageRoot = pageViewport.querySelector('affine-page-root');
                assertExists(pageRoot);
                return pageRoot;
            }
            get tags() {
                return this.doc.meta?.tags ?? [];
            }
            set tags(tags) {
                assertExists(this.doc.meta);
                this.doc.meta.tags = tags;
            }
            #___private_backlinkList_1;
            get backlinkList() {
                return this.#___private_backlinkList_1;
            }
            set backlinkList(_v) {
                this.#___private_backlinkList_1 = _v;
            }
            #___private_doc_2;
            get doc() {
                return this.#___private_doc_2;
            }
            set doc(_v) {
                this.#___private_doc_2 = _v;
            }
            #___private_expanded_3;
            get expanded() {
                return this.#___private_expanded_3;
            }
            set expanded(_v) {
                this.#___private_expanded_3 = _v;
            }
            #___private_showSelect_4;
            get showSelect() {
                return this.#___private_showSelect_4;
            }
            set showSelect(_v) {
                this.#___private_showSelect_4 = _v;
            }
            constructor(...args){
                super(...args);
                this._listenBacklinkList = ()=>{
                    const metaMap = Object.fromEntries(this.doc.collection.meta.docMetas.map((v)=>[
                            v.id,
                            v
                        ]));
                    const toData = (backlink)=>{
                        const docMeta = metaMap[backlink.pageId];
                        if (!docMeta) {
                            console.warn('Unexpected doc meta not found', backlink.pageId);
                        }
                        return {
                            ...backlink,
                            ...docMeta,
                            icon: backlink.type === 'LinkedPage' ? LinkedDocIcon : DocIcon,
                            jump: ()=>{
                                if (backlink.pageId === this.doc.id) return;
                                this.pageRoot.slots.docLinkClicked.emit({
                                    docId: backlink.pageId,
                                    blockId: backlink.blockId
                                });
                            }
                        };
                    };
                    const backlinkIndexer = this.doc.collection.indexer.backlink;
                    if (backlinkIndexer) {
                        const getList = ()=>{
                            return backlinkIndexer.getBacklink(this.doc.id).filter((v)=>v.type === 'LinkedPage').map(toData);
                        };
                        this.backlinkList = getList();
                        this._disposables.add(backlinkIndexer.slots.indexUpdated.on(()=>{
                            this.backlinkList = getList();
                        }));
                    }
                };
                this._renderBacklinkExpanded = ()=>{
                    const backlinkList = this.backlinkList;
                    if (!backlinkList.length) {
                        return null;
                    }
                    const renderLink = (link)=>{
                        return html` <div @click=${link.jump} class="link">
        ${link.icon}
        <div class="link-title">${link.title || DEFAULT_DOC_NAME}</div>
      </div>`;
                    };
                    return html`<div class="meta-data-expanded-column-item">
      <div class="backlink-title">
        ${DualLinkIcon16}
        <span class="title">Linked to this doc</span>
      </div>
      <div class="backlinks">
        ${repeat(backlinkList, (v)=>v.pageId, renderLink)}
      </div>
    </div>`;
                };
                this._renderBacklinkInline = ()=>{
                    const backlinkButton = new BacklinkButton(this.backlinkList);
                    return backlinkButton;
                };
                this._renderTagsExpanded = ()=>{
                    const optionMap = Object.fromEntries(this.options.map((v)=>[
                            v.id,
                            v
                        ]));
                    return html` <div class="meta-data-expanded-item">
      <div class="type">${TagsIcon}</div>
      <div class="value">
        <div class="tags">
          ${repeat(this.tags, (id)=>id, (id)=>{
                        const tag = optionMap[id];
                        if (!tag) {
                            return null;
                        }
                        const style = styleMap({
                            backgroundColor: tag.color
                        });
                        const click = ()=>{
                            this.pageRoot.slots.tagClicked.emit({
                                tagId: tag.id
                            });
                        };
                        return html` <div class="tag" @click=${click} style=${style}>
                ${tag.value}
              </div>`;
                    })}
          ${this.doc.readonly ? nothing : html`<div class="add-tag" @click="${this._selectTags}">
                ${PlusIcon}
              </div>`}
        </div>
      </div>
    </div>`;
                };
                this._renderTagsInline = ()=>{
                    const tags = this.tags;
                    const optionMap = Object.fromEntries(this.options.map((v)=>[
                            v.id,
                            v
                        ]));
                    return html` <div class="tags-inline">
      ${TagsIcon}
      ${tags.length > 0 ? html` <div class="tag-list">
            ${repeat(tags.slice(0, 3), (id)=>id, (id, i)=>{
                        const tag = optionMap[id];
                        if (!tag) {
                            return null;
                        }
                        return html` <div>${i !== 0 ? html`,&nbsp;` : ''}</div>
                  <div class="tag-inline">${tag.value}</div>`;
                    })}
            ${tags.length > 3 ? html`, and ${tags.length - 3} more` : ''}
          </div>` : 'Tags'}
    </div>`;
                };
                this._selectTags = ()=>{
                    this._disposables.add({
                        dispose: popTagSelect(this.shadowRoot?.querySelector('.tags') ?? this, {
                            value: this.tags,
                            onChange: (tags)=>this.tags = tags,
                            options: this.options,
                            onOptionsChange: (options)=>this.options = options
                        })
                    });
                };
                this._toggle = ()=>{
                    this.expanded = !this.expanded;
                };
                this.#___private_backlinkList_1 = (_initProto(this), _init_backlinkList(this));
                this.#___private_doc_2 = _init_doc(this);
                this.#___private_expanded_3 = _init_expanded(this, false);
                this.#___private_showSelect_4 = _init_showSelect(this, false);
            }
        }
    }
}();
export { _DocMetaTags as DocMetaTags };
