import i18next from 'i18next';
import { I18nextProvider, initReactI18next, Trans } from 'react-i18next';
import { LOCALES } from './resources';
export * from './i18n';
export * from './utils';
const STORAGE_KEY = 'i18n_lng';
export { I18nextProvider, LOCALES, Trans };
const resources = LOCALES.reduce((acc, { tag, res })=>{
    return Object.assign(acc, {
        [tag]: {
            translation: res
        }
    });
}, {});
const fallbackLng = 'en';
const standardizeLocale = (language)=>{
    if (language === 'zh-CN' || language === 'zh' || language === 'zh-Hans') {
        language = 'zh-Hans';
    } else if (language.slice(0, 2).toLowerCase() === 'zh') {
        language = 'zh-Hant';
    }
    if (LOCALES.some((locale)=>locale.tag === language)) return language;
    if (LOCALES.some((locale)=>locale.tag === language.slice(0, 2).toLowerCase())) {
        return language.slice(0, 2).toLowerCase();
    }
    return fallbackLng;
};
export const createI18n = ()=>{
    const i18n = i18next.createInstance();
    i18n.use(initReactI18next).init({
        lng: 'en',
        fallbackLng,
        debug: false,
        resources,
        interpolation: {
            escapeValue: false
        }
    }).then(()=>{
        console.info('i18n init success');
    }).catch(()=>{
        console.error('i18n init failed');
    });
    if (globalThis.localStorage) {
        i18n.on('languageChanged', (lng)=>{
            localStorage.setItem(STORAGE_KEY, lng);
        });
    }
    return i18n;
};
export function setUpLanguage(i) {
    let language;
    const localStorageLanguage = localStorage.getItem(STORAGE_KEY);
    if (localStorageLanguage) {
        language = standardizeLocale(localStorageLanguage);
    } else {
        language = standardizeLocale(navigator.language);
    }
    return i.changeLanguage(language);
}
