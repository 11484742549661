import { ThemeObserver } from '../../../_common/theme/theme-observer.js';
import { ShapeToolController } from '../../edgeless/controllers/tools/shape-tool.js';
import { EdgelessRootBlockComponent } from '../../edgeless/edgeless-root-block.js';
export function updateShapeOverlay(rootComponent) {
    const controller = rootComponent.tools.currentController;
    if (controller instanceof ShapeToolController) {
        controller.createOverlay();
    }
}
export function getActiveShapeColor(type) {
    return ({ rootComponent })=>{
        if (rootComponent instanceof EdgelessRootBlockComponent) {
            const props = rootComponent.service.editPropsStore.getLastProps('shape');
            const color = type == 'fill' ? props.fillColor : props.strokeColor;
            return ThemeObserver.getColorValue(color);
        }
        return '';
    };
}
export function getActiveConnectorStrokeColor({ rootComponent }) {
    if (rootComponent instanceof EdgelessRootBlockComponent) {
        const props = rootComponent.service.editPropsStore.getLastProps('connector');
        const color = props.stroke;
        return ThemeObserver.getColorValue(color);
    }
    return '';
}
export function setEdgelessToolAction(tool) {
    return ({ rootComponent })=>{
        rootComponent.service.tool.setEdgelessTool(tool);
    };
}
export function getPosition(angleRad, v) {
    const x = Math.cos(angleRad) * v[0];
    const y = Math.sin(angleRad) * v[1];
    return [
        x,
        y
    ];
}
export function isNodeWithChildren(node) {
    return 'children' in node;
}
export function isRootNode(model) {
    return model.type === 'root';
}
export function isSubmenuNode(model) {
    return model.type === 'submenu';
}
export function isCommandNode(model) {
    return model.type === 'command';
}
export function isColorNode(model) {
    return model.type === 'color';
}
export function isNodeWithAction(node) {
    return 'action' in node && typeof node.action === 'function';
}
export function calcNodeAngles(node, parentAngle) {
    const nodeAngles = [];
    if (node.length == 0) {
        return nodeAngles;
    }
    const fixedAngles = [];
    node.forEach((item, index)=>{
        if (item.angle && item.angle >= 0) {
            fixedAngles.push({
                angle: item.angle,
                index: index
            });
        }
    });
    if (parentAngle != undefined) {
        for(let i = 0; i < fixedAngles.length; i++){
            if (Math.abs(fixedAngles[i].angle - parentAngle) < 0.0001) {
                fixedAngles[i].angle += 0.1;
            }
        }
    }
    for(let i = 0; i < fixedAngles.length; i++){
        fixedAngles[i].angle = fixedAngles[i].angle % 360;
    }
    for(let i = 0; i < fixedAngles.length - 1;){
        if (fixedAngles[i].angle > fixedAngles[i + 1].angle) {
            fixedAngles.splice(i + 1, 1);
        } else {
            ++i;
        }
    }
    if (fixedAngles.length == 0) {
        let firstAngle = 0;
        if (parentAngle != undefined) {
            const wedgeSize = 360 / (node.length + 1);
            let minAngle = 360;
            for(let i = 0; i < node.length; i++){
                minAngle = Math.min(minAngle, (parentAngle + (i + 1) * wedgeSize) % 360);
            }
            firstAngle = minAngle;
        }
        fixedAngles.push({
            angle: firstAngle,
            index: 0
        });
        nodeAngles[0] = firstAngle;
    }
    for(let i = 0; i < fixedAngles.length; i++){
        const wedgeBeginIndex = fixedAngles[i].index;
        const wedgeBeginAngle = fixedAngles[i].angle;
        const wedgeEndIndex = fixedAngles[(i + 1) % fixedAngles.length].index;
        let wedgeEndAngle = fixedAngles[(i + 1) % fixedAngles.length].angle;
        nodeAngles[wedgeBeginIndex] = wedgeBeginAngle;
        if (wedgeEndAngle <= wedgeBeginAngle) {
            wedgeEndAngle += 360;
        }
        let wedgeItemCount = (wedgeEndIndex - wedgeBeginIndex - 1 + node.length) % node.length;
        let parentInWedge = false;
        if (parentAngle != undefined) {
            if (parentAngle < wedgeBeginAngle) {
                parentAngle += 360;
            }
            parentInWedge = parentAngle > wedgeBeginAngle && parentAngle < wedgeEndAngle;
            if (parentInWedge) {
                wedgeItemCount += 1;
            }
        }
        const wedgeItemGap = (wedgeEndAngle - wedgeBeginAngle) / (wedgeItemCount + 1);
        let index = (wedgeBeginIndex + 1) % node.length;
        let count = 1;
        let parentGapRequired = parentInWedge;
        while(index != wedgeEndIndex){
            let itemAngle = wedgeBeginAngle + wedgeItemGap * count;
            if (parentGapRequired && itemAngle + wedgeItemGap / 2 - (parentAngle ?? 0) > 0) {
                count += 1;
                itemAngle = wedgeBeginAngle + wedgeItemGap * count;
                parentGapRequired = false;
            }
            nodeAngles[index] = itemAngle % 360;
            index = (index + 1) % node.length;
            count += 1;
        }
    }
    return nodeAngles;
}
export function calcNodeWedges(nodeAngles, parentAngle) {
    if (nodeAngles.length === 0 && parentAngle === undefined) {
        return [];
    }
    if (nodeAngles.length === 1 && parentAngle === undefined) {
        return [
            {
                start: 0,
                end: 360
            }
        ];
    }
    if (nodeAngles.length === 1 && parentAngle !== undefined) {
        let start = parentAngle;
        let center = nodeAngles[0];
        let end = parentAngle + 360;
        [start, center, end] = normalizeConsecutiveAngles(start, center, end);
        [start, end] = scaleWedge(start, center, end, 0.5);
        return [
            {
                start: start,
                end: end
            }
        ];
    }
    const wedges = [];
    for(let i = 0; i < nodeAngles.length; i++){
        let start = nodeAngles[(i + nodeAngles.length - 1) % nodeAngles.length];
        let center = nodeAngles[i];
        let end = nodeAngles[(i + 1) % nodeAngles.length];
        [start, center, end] = normalizeConsecutiveAngles(start, center, end);
        if (parentAngle !== undefined) {
            [start, end] = cropWedge(start, center, end, parentAngle);
            [start, center, end] = normalizeConsecutiveAngles(start, center, end);
        }
        [start, end] = scaleWedge(start, center, end, 0.5);
        wedges.push({
            start: start,
            end: end
        });
    }
    return wedges;
}
export function isAngleBetween(angle, start, end) {
    return angle > start && angle <= end || angle - 360 > start && angle - 360 <= end || angle + 360 > start && angle + 360 <= end;
}
function normalizeConsecutiveAngles(start, center, end) {
    while(center < start){
        center += 360;
    }
    while(end < center){
        end += 360;
    }
    while(center >= 360){
        start -= 360;
        center -= 360;
        end -= 360;
    }
    return [
        start,
        center,
        end
    ];
}
function cropWedge(start, center, end, cropAngle) {
    if (isAngleBetween(cropAngle, start, center)) {
        start = cropAngle;
    }
    if (isAngleBetween(cropAngle, center, end)) {
        end = cropAngle;
    }
    return [
        start,
        end
    ];
}
function scaleWedge(start, center, end, scale) {
    start = center - (center - start) * scale;
    end = center + (end - center) * scale;
    return [
        start,
        end
    ];
}
