import { html } from 'lit';
export function fontIcon(svg, baseSize = 20) {
    const size = baseSize / 16;
    const fontIconStyle = `
    width: ${size}em;
    height: ${size}em;
    vertical-align: middle;
    font-size: inherit;
    margin-bottom: 0.1em;
  `;
    return html`<svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    style=${fontIconStyle}
  >
    ${svg}
  </svg>`;
}
export function icon(svg, size = 24) {
    return html`<svg
    width="${size}"
    height="${size}"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    ${svg}
  </svg>`;
}
