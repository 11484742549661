function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WidgetComponent, _dec1, _dec2, _dec3, _init__dragHandleContainer, _init__dragHandleGrabber, _init__dragHoverRect, _initProto;
import { PathFinder, WidgetComponent } from '@blocksuite/block-std';
import { Point } from '@blocksuite/global/utils';
import { Bound } from '@blocksuite/global/utils';
import { DisposableGroup, throttle } from '@blocksuite/global/utils';
import { BlockViewType } from '@blocksuite/store';
import { html } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Rect, getBlockComponentsExcludeSubtrees, getCurrentNativeRange, getModelByBlockComponent, isInsideEdgelessEditor, isInsidePageEditor, matchFlavours } from '../../../_common/utils/index.js';
import { getScrollContainer } from '../../../_common/utils/scroll-container.js';
import { getSelectedRect, isTopLevelBlock } from '../../../root-block/edgeless/utils/query.js';
import { PageRootBlockComponent } from '../../../root-block/page/page-root-block.js';
import { autoScroll } from '../../../root-block/text-selection/utils.js';
import { SpecProvider } from '../../../specs/utils/spec-provider.js';
import { DragPreview } from './components/drag-preview.js';
import { DropIndicator } from './components/drop-indicator.js';
import { DRAG_HANDLE_CONTAINER_OFFSET_LEFT_TOP_LEVEL, DRAG_HANDLE_CONTAINER_PADDING, DRAG_HANDLE_CONTAINER_WIDTH, DRAG_HANDLE_CONTAINER_WIDTH_TOP_LEVEL, DRAG_HANDLE_GRABBER_BORDER_RADIUS, DRAG_HANDLE_GRABBER_HEIGHT, DRAG_HANDLE_GRABBER_WIDTH, DRAG_HANDLE_GRABBER_WIDTH_HOVERED, DRAG_HOVER_RECT_PADDING, DragHandleOptionsRunner, HOVER_AREA_RECT_PADDING_TOP_LEVEL } from './config.js';
import { styles } from './styles.js';
import { calcDropTarget, captureEventTarget, containBlock, containChildBlock, getClosestBlockByPoint, getClosestNoteBlock, getDragHandleContainerHeight, getDragHandleLeftPadding, getDuplicateBlocks, getNoteId, includeTextSelection, insideDatabaseTable, isBlockPathEqual, isOutOfNoteBlock, updateDragHandleClassName } from './utils.js';
export const AFFINE_DRAG_HANDLE_WIDGET = 'affine-drag-handle-widget';
let _AffineDragHandleWidget;
_dec = customElement(AFFINE_DRAG_HANDLE_WIDGET), _dec1 = query('.affine-drag-handle-container'), _dec2 = query('.affine-drag-handle-grabber'), _dec3 = state();
new class extends _identity {
    constructor(){
        super(_AffineDragHandleWidget), _initClass();
    }
    static{
        class AffineDragHandleWidget extends (_WidgetComponent = WidgetComponent) {
            static{
                ({ e: [_init__dragHandleContainer, _init__dragHandleGrabber, _init__dragHoverRect, _initProto], c: [_AffineDragHandleWidget, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_dragHandleContainer"
                    ],
                    [
                        _dec2,
                        1,
                        "_dragHandleGrabber"
                    ],
                    [
                        _dec3,
                        1,
                        "_dragHoverRect"
                    ]
                ], [
                    _dec
                ], _WidgetComponent));
            }
            static{
                this.staticOptionRunner = new DragHandleOptionsRunner();
            }
            static{
                this.styles = styles;
            }
            static registerOption(option) {
                return AffineDragHandleWidget.staticOptionRunner.register(option);
            }
            _clearRaf() {
                if (this.rafID) {
                    cancelAnimationFrame(this.rafID);
                    this.rafID = 0;
                }
            }
            _getHoverAreaRectTopLevelBlock(edgelessElement) {
                if (isInsidePageEditor(this.host)) return null;
                const edgelessRoot = this.rootComponent;
                const rect = getSelectedRect([
                    edgelessElement
                ]);
                let [left, top] = edgelessRoot.service.viewport.toViewCoord(rect.left, rect.top);
                const width = rect.width * this.scale;
                const height = rect.height * this.scale;
                let [right, bottom] = [
                    left + width,
                    top + height
                ];
                const offsetLeft = DRAG_HANDLE_CONTAINER_OFFSET_LEFT_TOP_LEVEL * this.scale;
                const padding = HOVER_AREA_RECT_PADDING_TOP_LEVEL * this.scale;
                left -= DRAG_HANDLE_CONTAINER_WIDTH_TOP_LEVEL * this.scale + offsetLeft;
                top -= padding;
                right += padding;
                bottom += padding;
                return new Rect(left, top, right, bottom);
            }
            get _rangeManager() {
                return this.std.range;
            }
            get dragHandleContainerOffsetParent() {
                return this._dragHandleContainer.parentElement;
            }
            get scrollContainer() {
                return getScrollContainer(this.rootComponent);
            }
            connectedCallback() {
                super.connectedCallback();
                this.disposables.addFromEvent(this, 'pointerdown', (e)=>{
                    e.preventDefault();
                });
                this.handleEvent('pointerMove', this._throttledPointerMoveHandler);
                this.handleEvent('click', this._clickHandler);
                this.handleEvent('dragStart', this._dragStartHandler);
                this.handleEvent('dragMove', this._dragMoveHandler);
                this.handleEvent('dragEnd', this._dragEndHandler, {
                    global: true
                });
                this.handleEvent('pointerOut', this._pointerOutHandler);
                this.handleEvent('beforeInput', ()=>this._hide());
                this.handleEvent('keyDown', this._keyboardHandler, {
                    global: true
                });
                this.handleEvent('keyUp', this._keyboardHandler, {
                    global: true
                });
            }
            disconnectedCallback() {
                this._hide(true);
                this._disposables.dispose();
                this._anchorModelDisposables?.dispose();
                super.disconnectedCallback();
            }
            firstUpdated() {
                this._hide(true);
                this._disposables.addFromEvent(this._dragHandleContainer, 'pointerenter', this._onDragHandlePointerEnter);
                this._disposables.addFromEvent(this._dragHandleContainer, 'pointerdown', this._onDragHandlePointerDown);
                this._disposables.addFromEvent(this._dragHandleContainer, 'pointerup', this._onDragHandlePointerUp);
                this._disposables.addFromEvent(this._dragHandleContainer, 'pointerleave', this._onDragHandlePointerLeave);
                this._disposables.addFromEvent(this.host, 'pointerleave', ()=>{
                    this._hide();
                });
                if (isInsidePageEditor(this.host)) {
                    this._disposables.add(this.doc.slots.blockUpdated.on(()=>this._hide()));
                    const pageRoot = this.rootComponent;
                    this._disposables.add(pageRoot.slots.viewportUpdated.on(()=>{
                        this._hide();
                        if (this.dropIndicator) {
                            this.dropIndicator.rect = null;
                        }
                    }));
                    this._disposables.addFromEvent(this.scrollContainer, 'scrollend', this._updateDropIndicatorOnScroll);
                } else if (isInsideEdgelessEditor(this.host)) {
                    const edgelessRoot = this.rootComponent;
                    this._disposables.add(edgelessRoot.slots.edgelessToolUpdated.on(this._handleEdgelessToolUpdated));
                    this._disposables.add(edgelessRoot.service.viewport.viewportUpdated.on(this._handleEdgelessViewPortUpdated));
                    this._disposables.add(edgelessRoot.service.selection.slots.updated.on(()=>{
                        this._checkTopLevelBlockSelection();
                    }));
                    this._disposables.add(edgelessRoot.slots.readonlyUpdated.on(()=>{
                        this._checkTopLevelBlockSelection();
                    }));
                    this._disposables.add(edgelessRoot.slots.draggingAreaUpdated.on(()=>{
                        this._checkTopLevelBlockSelection();
                    }));
                    this._disposables.add(edgelessRoot.slots.elementResizeStart.on(()=>{
                        this._hide();
                    }));
                    this._disposables.add(edgelessRoot.slots.elementResizeEnd.on(()=>{
                        this._checkTopLevelBlockSelection();
                    }));
                }
            }
            render() {
                const hoverRectStyle = styleMap(this._dragHoverRect ? {
                    width: `${this._dragHoverRect.width}px`,
                    height: `${this._dragHoverRect.height}px`,
                    top: `${this._dragHoverRect.top}px`,
                    left: `${this._dragHoverRect.left}px`
                } : {
                    display: 'none'
                });
                return html`
      <div class="affine-drag-handle-widget">
        <div class="affine-drag-handle-container">
          <div class="affine-drag-handle-grabber"></div>
        </div>
        <div class="affine-drag-hover-rect" style=${hoverRectStyle}></div>
      </div>
    `;
            }
            get anchorBlockComponent() {
                if (!this._anchorBlockPath) return null;
                return this._getBlockComponentFromViewStore(this._anchorBlockPath);
            }
            get anchorEdgelessElement() {
                if (isInsidePageEditor(this.host) || !this._anchorBlockId) return null;
                const { service } = this.rootComponent;
                const edgelessElement = service.getElementById(this._anchorBlockId);
                return isTopLevelBlock(edgelessElement) ? edgelessElement : null;
            }
            get optionRunner() {
                return AffineDragHandleWidget.staticOptionRunner;
            }
            get rootComponent() {
                return this.block;
            }
            get selectedBlocks() {
                return this.host.selection.find('text') ? this.host.selection.filter('text') : this.host.selection.filter('block');
            }
            #___private__dragHandleContainer_1;
            get _dragHandleContainer() {
                return this.#___private__dragHandleContainer_1;
            }
            set _dragHandleContainer(_v) {
                this.#___private__dragHandleContainer_1 = _v;
            }
            #___private__dragHandleGrabber_2;
            get _dragHandleGrabber() {
                return this.#___private__dragHandleGrabber_2;
            }
            set _dragHandleGrabber(_v) {
                this.#___private__dragHandleGrabber_2 = _v;
            }
            #___private__dragHoverRect_3;
            get _dragHoverRect() {
                return this.#___private__dragHoverRect_3;
            }
            set _dragHoverRect(_v) {
                this.#___private__dragHoverRect_3 = _v;
            }
            constructor(...args){
                super(...args);
                this._anchorBlockId = '';
                this._anchorBlockPath = null;
                this._anchorModelDisposables = null;
                this._calculatePreviewOffset = (blocks, state)=>{
                    const { top, left } = blocks[0].getBoundingClientRect();
                    const previewOffset = new Point(state.raw.x - left, state.raw.y - top);
                    return previewOffset;
                };
                this._calculateQuery = (selectedIds)=>{
                    const ids = selectedIds.map((id)=>({
                            id,
                            viewType: BlockViewType.Display
                        }));
                    selectedIds.map((block)=>{
                        let parent = block;
                        do {
                            if (!selectedIds.includes(parent)) {
                                ids.push({
                                    viewType: BlockViewType.Bypass,
                                    id: parent
                                });
                            }
                            parent = this.doc.blockCollection.crud.getParent(parent);
                        }while (parent && !ids.map(({ id })=>id).includes(parent));
                    });
                    const addChildren = (id)=>{
                        const children = this.doc.getBlock(id)?.model.children ?? [];
                        children.forEach((child)=>{
                            ids.push({
                                viewType: BlockViewType.Display,
                                id: child.id
                            });
                            addChildren(child.id);
                        });
                    };
                    selectedIds.forEach(addChildren);
                    return {
                        match: ids,
                        mode: 'strict'
                    };
                };
                this._canEditing = (noteBlock)=>{
                    if (noteBlock.doc.id !== this.doc.id) return false;
                    if (isInsidePageEditor(this.host)) return true;
                    const edgelessRoot = this.rootComponent;
                    const noteBlockId = noteBlock.path[noteBlock.path.length - 1];
                    return edgelessRoot.service.selection.editing && edgelessRoot.service.selection.selectedIds[0] === noteBlockId;
                };
                this._changeCursorToGrabbing = ()=>{
                    document.documentElement.classList.add('affine-drag-preview-grabbing');
                };
                this._checkTopLevelBlockSelection = ()=>{
                    if (!this.isConnected) {
                        return;
                    }
                    if (this.doc.readonly || isInsidePageEditor(this.host)) {
                        this._hide();
                        return;
                    }
                    const edgelessRoot = this.rootComponent;
                    const editing = edgelessRoot.service.selection.editing;
                    const selectedElements = edgelessRoot.service.selection.selectedElements;
                    if (editing || selectedElements.length !== 1) {
                        this._hide();
                        return;
                    }
                    const selectedElement = selectedElements[0];
                    if (!isTopLevelBlock(selectedElement)) {
                        this._hide();
                        return;
                    }
                    const flavour = selectedElement.flavour;
                    const dragHandleOptions = this.optionRunner.getOption(flavour);
                    if (!dragHandleOptions || !dragHandleOptions.edgeless) {
                        this._hide();
                        return;
                    }
                    const selections = edgelessRoot.service.selection.surfaceSelections;
                    this._anchorBlockId = selectedElement.id;
                    this._anchorBlockPath = selections[0].blockId;
                    this._showDragHandleOnTopLevelBlocks().catch(console.error);
                };
                this._clickHandler = (ctx)=>{
                    if (!this._isHoverDragHandleVisible) return;
                    const state = ctx.get('pointerState');
                    const { target } = state.raw;
                    const element = captureEventTarget(target);
                    const insideDragHandle = !!element?.closest(AFFINE_DRAG_HANDLE_WIDGET);
                    if (!insideDragHandle) return;
                    if (!this._anchorBlockId || !this._anchorBlockPath) return;
                    const { selection } = this.host;
                    const selectedBlocks = this.selectedBlocks;
                    if (selectedBlocks.length > 0 && !includeTextSelection(selectedBlocks) && selectedBlocks[0].blockId === this._anchorBlockId) {
                        selection.clear([
                            'block'
                        ]);
                        this._dragHoverRect = null;
                        this._showDragHandleOnHoverBlock(this._anchorBlockPath);
                        return;
                    }
                    const blocks = this.anchorBlockComponent;
                    if (!blocks) return;
                    if (selectedBlocks.length > 1) {
                        this._showDragHandleOnHoverBlock(this._anchorBlockPath);
                    }
                    this._setSelectedBlocks([
                        blocks
                    ]);
                };
                this._createDragPreview = (blocks, state, dragPreviewEl, dragPreviewOffset)=>{
                    let dragPreview;
                    if (dragPreviewEl) {
                        dragPreview = new DragPreview(dragPreviewOffset);
                        dragPreview.append(dragPreviewEl);
                    } else {
                        let width = 0;
                        blocks.forEach((element)=>{
                            width = Math.max(width, element.getBoundingClientRect().width);
                        });
                        const selectedIds = blocks.map((block)=>block.model.id);
                        const query = this._calculateQuery(selectedIds);
                        const doc = this.doc.blockCollection.getDoc({
                            query
                        });
                        const previewSpec = SpecProvider.getInstance().getSpec('page:preview');
                        const previewTemplate = this.host.renderSpecPortal(doc, previewSpec.value);
                        const offset = this._calculatePreviewOffset(blocks, state);
                        const posX = state.raw.x - offset.x;
                        const posY = state.raw.y - offset.y;
                        const altKey = state.raw.altKey;
                        dragPreview = new DragPreview(offset);
                        dragPreview.template = previewTemplate;
                        dragPreview.onRemove = ()=>{
                            this.doc.blockCollection.clearQuery(query);
                        };
                        dragPreview.style.width = `${width / this.scale / this.noteScale / this.cumulativeParentScale}px`;
                        dragPreview.style.transform = `translate(${posX}px, ${posY}px) scale(${this.scale * this.noteScale})`;
                        dragPreview.style.opacity = altKey ? '1' : '0.5';
                    }
                    this.rootComponent.append(dragPreview);
                    return dragPreview;
                };
                this._createDropIndicator = ()=>{
                    if (!this.dropIndicator) {
                        this.dropIndicator = new DropIndicator();
                        this.rootComponent.append(this.dropIndicator);
                    }
                };
                this._dragEndHandler = (ctx)=>{
                    this._clearRaf();
                    if (!this.dragging || !this.dragPreview) return false;
                    if (this.draggingElements.length === 0 || this.doc.readonly) {
                        this._hide(true);
                        return false;
                    }
                    const state = ctx.get('pointerState');
                    const { target } = state.raw;
                    if (!this.host.contains(target)) {
                        this._hide(true);
                        return true;
                    }
                    for (const option of this.optionRunner.options){
                        if (option.onDragEnd?.({
                            state,
                            draggingElements: this.draggingElements,
                            dropBlockId: this.dropBlockId,
                            dropType: this.dropType,
                            dragPreview: this.dragPreview,
                            noteScale: this.noteScale,
                            editorHost: this.host
                        })) {
                            this._hide(true);
                            if (isInsideEdgelessEditor(this.host)) {
                                this._checkTopLevelBlockSelection();
                            }
                            return true;
                        }
                    }
                    this._onDragEnd(state);
                    if (isInsideEdgelessEditor(this.host)) {
                        this._checkTopLevelBlockSelection();
                    }
                    return true;
                };
                this._dragMoveHandler = (ctx)=>{
                    if (this._isHoverDragHandleVisible || this._isTopLevelDragHandleVisible) {
                        this._hide();
                    }
                    if (!this.dragging || this.draggingElements.length === 0) {
                        return false;
                    }
                    ctx.get('defaultState').event.preventDefault();
                    const state = ctx.get('pointerState');
                    for (const option of this.optionRunner.options){
                        if (option.onDragMove?.(state, this.draggingElements)) {
                            return true;
                        }
                    }
                    return this._onDragMove(state);
                };
                this._dragStartHandler = (ctx)=>{
                    const state = ctx.get('pointerState');
                    const { button } = state.raw;
                    if (button !== 0) {
                        return false;
                    }
                    for (const option of this.optionRunner.options){
                        if (option.onDragStart?.({
                            state,
                            startDragging: this._startDragging,
                            anchorBlockId: this._anchorBlockId,
                            anchorBlockPath: this._anchorBlockPath,
                            editorHost: this.host
                        })) {
                            return true;
                        }
                    }
                    return this._onDragStart(state);
                };
                this._getBlockComponentFromViewStore = (path)=>{
                    return this.host.view.getBlock(path);
                };
                this._getDraggingAreaRect = (block)=>{
                    let { left, top, right, bottom } = block.getBoundingClientRect();
                    const blocks = this._getHoveredBlocks();
                    blocks.forEach((block)=>{
                        left = Math.min(left, block.getBoundingClientRect().left);
                        top = Math.min(top, block.getBoundingClientRect().top);
                        right = Math.max(right, block.getBoundingClientRect().right);
                        bottom = Math.max(bottom, block.getBoundingClientRect().bottom);
                    });
                    const offsetLeft = getDragHandleLeftPadding(blocks);
                    const offsetParentRect = this.dragHandleContainerOffsetParent.getBoundingClientRect();
                    if (!offsetParentRect) return new Rect(0, 0, 0, 0);
                    left -= offsetParentRect.left;
                    right -= offsetParentRect.left;
                    top -= offsetParentRect.top;
                    bottom -= offsetParentRect.top;
                    left /= this.cumulativeParentScale;
                    right /= this.cumulativeParentScale;
                    top /= this.cumulativeParentScale;
                    bottom /= this.cumulativeParentScale;
                    left -= (DRAG_HANDLE_CONTAINER_WIDTH + offsetLeft) * this.scale * this.noteScale;
                    top -= DRAG_HOVER_RECT_PADDING * this.scale;
                    right += DRAG_HOVER_RECT_PADDING * this.scale;
                    bottom += DRAG_HOVER_RECT_PADDING * this.scale;
                    return new Rect(left, top, right, bottom);
                };
                this._getDropResult = (state)=>{
                    const point = new Point(state.raw.x, state.raw.y);
                    const closestBlock = getClosestBlockByPoint(this.host, this.rootComponent, point);
                    if (!closestBlock) return null;
                    const blockId = closestBlock.model.id;
                    const blockPath = closestBlock.path;
                    const model = closestBlock.model;
                    const isDatabase = matchFlavours(model, [
                        'affine:database'
                    ]);
                    if (isDatabase) return null;
                    const isDraggedElementNote = this.draggingElements.length === 1 && matchFlavours(this.draggingElements[0].model, [
                        'affine:note'
                    ]);
                    if (isDraggedElementNote) {
                        const parent = this.std.doc.getParent(closestBlock.model);
                        if (!parent) return null;
                        const parentElement = this._getBlockComponentFromViewStore(parent.id);
                        if (!parentElement) return null;
                        if (!matchFlavours(parentElement.model, [
                            'affine:note'
                        ])) return null;
                    }
                    if (containBlock(this.draggingElements.map((block)=>block.model.id), blockId) || containChildBlock(this.draggingElements, blockPath)) {
                        return null;
                    }
                    let rect = null;
                    let dropType = 'before';
                    const result = calcDropTarget(point, model, closestBlock, this.draggingElements, this.scale * this.cumulativeParentScale, isDraggedElementNote === false);
                    if (result) {
                        rect = result.rect;
                        dropType = result.dropType;
                    }
                    if (isDraggedElementNote && dropType === 'in') return null;
                    const dropIndicator = {
                        rect,
                        dropBlockId: blockId,
                        dropType
                    };
                    return dropIndicator;
                };
                this._getHoveredBlocks = ()=>{
                    if (!this._isHoverDragHandleVisible || !this._anchorBlockPath) return [];
                    const hoverBlock = this.anchorBlockComponent;
                    if (!hoverBlock) return [];
                    const selections = this.selectedBlocks;
                    let blocks = [];
                    if (selections.length > 0 && includeTextSelection(selections)) {
                        const range = getCurrentNativeRange();
                        if (!range) return [];
                        if (!this._rangeManager) return [];
                        blocks = this._rangeManager.getSelectedBlockComponentsByRange(range, {
                            match: (el)=>el.model.role === 'content',
                            mode: 'highest'
                        });
                    } else {
                        blocks = this.selectedBlocks.map((block)=>this._getBlockComponentFromViewStore(block.blockId)).filter((block)=>!!block);
                    }
                    if (containBlock(blocks.map((block)=>PathFinder.id(block.path)), this._anchorBlockPath)) {
                        return blocks;
                    }
                    return [
                        hoverBlock
                    ];
                };
                this._getTopWithBlockComponent = (block)=>{
                    const computedStyle = getComputedStyle(block);
                    const { top } = block.getBoundingClientRect();
                    const paddingTop = parseInt(computedStyle.paddingTop) * this.scale;
                    return (top + paddingTop - this.dragHandleContainerOffsetParent.getBoundingClientRect().top) / this.cumulativeParentScale;
                };
                this._handleAnchorModelDisposables = (blockModel)=>{
                    if (this._anchorModelDisposables) {
                        this._anchorModelDisposables.dispose();
                        this._anchorModelDisposables = null;
                    }
                    this._anchorModelDisposables = new DisposableGroup();
                    this._anchorModelDisposables.add(blockModel.propsUpdated.on(()=>this._hide()));
                    this._anchorModelDisposables.add(blockModel.deleted.on(()=>this._hide()));
                };
                this._handleEdgelessToolUpdated = (newTool)=>{
                    if (newTool.type === 'default') {
                        this._checkTopLevelBlockSelection();
                    } else {
                        this._hide();
                    }
                };
                this._handleEdgelessViewPortUpdated = ({ zoom, center })=>{
                    if (this.scale !== zoom) {
                        this.scale = zoom;
                        this._updateDragPreviewOnViewportUpdate();
                    }
                    if (this.center[0] !== center[0] && this.center[1] !== center[1]) {
                        this.center = [
                            ...center
                        ];
                        this._updateDropIndicatorOnScroll();
                    }
                    if (this._isTopLevelDragHandleVisible) {
                        this._showDragHandleOnTopLevelBlocks().catch(console.error);
                        this._updateDragHoverRectTopLevelBlock();
                    } else {
                        this._hide();
                    }
                };
                this._hide = (force = false)=>{
                    updateDragHandleClassName();
                    this._isHoverDragHandleVisible = false;
                    this._isTopLevelDragHandleVisible = false;
                    this._isDragHandleHovered = false;
                    this._anchorBlockId = '';
                    this._anchorBlockPath = null;
                    if (this._dragHandleContainer) {
                        this._dragHandleContainer.style.display = 'none';
                    }
                    if (force) {
                        this._reset();
                    }
                };
                this._isBlockSelected = (block)=>{
                    if (!block) return false;
                    return this.selectedBlocks.some((selection)=>selection.blockId === block.model.id);
                };
                this._isDragHandleHovered = false;
                this._isHoverDragHandleVisible = false;
                this._isTopLevelDragHandleVisible = false;
                this._keyboardHandler = (ctx)=>{
                    if (!this.dragging || !this.dragPreview) {
                        return;
                    }
                    const state = ctx.get('defaultState');
                    const event = state.event;
                    event.preventDefault();
                    event.stopPropagation();
                    const altKey = event.key === 'Alt' && event.altKey;
                    this.dragPreview.style.opacity = altKey ? '1' : '0.5';
                };
                this._lastHoveredBlockPath = null;
                this._lastShowedBlock = null;
                this._onDragEnd = (state)=>{
                    const targetBlockId = this.dropBlockId;
                    const dropType = this.dropType;
                    const draggingElements = this.draggingElements;
                    this._hide(true);
                    if (!targetBlockId) {
                        const target = captureEventTarget(state.raw.target);
                        if (!target) return false;
                        const isTargetEdgelessContainer = target.classList.contains('edgeless-container');
                        if (!isTargetEdgelessContainer) return false;
                        const selectedBlocks = getBlockComponentsExcludeSubtrees(draggingElements).map((element)=>getModelByBlockComponent(element)).filter((x)=>!!x);
                        if (selectedBlocks.length === 0) return false;
                        const isSurfaceComponent = selectedBlocks.some((block)=>{
                            const parent = this.doc.getParent(block.id);
                            return matchFlavours(parent, [
                                'affine:surface'
                            ]);
                        });
                        if (isSurfaceComponent) return true;
                        const edgelessRoot = this.rootComponent;
                        const { left: viewportLeft, top: viewportTop } = edgelessRoot.viewport;
                        const newNoteId = edgelessRoot.addNoteWithPoint(new Point(state.raw.x - viewportLeft, state.raw.y - viewportTop), {
                            scale: this.noteScale
                        });
                        const newNoteBlock = this.doc.getBlockById(newNoteId);
                        if (!newNoteBlock) return;
                        const bound = Bound.deserialize(newNoteBlock.xywh);
                        bound.h *= this.noteScale;
                        bound.w *= this.noteScale;
                        this.doc.updateBlock(newNoteBlock, {
                            xywh: bound.serialize(),
                            edgeless: {
                                ...newNoteBlock.edgeless,
                                scale: this.noteScale
                            }
                        });
                        const altKey = state.raw.altKey;
                        if (altKey) {
                            const duplicateBlocks = getDuplicateBlocks(selectedBlocks);
                            this.doc.addBlocks(duplicateBlocks, newNoteBlock);
                        } else {
                            this.doc.moveBlocks(selectedBlocks, newNoteBlock);
                        }
                        edgelessRoot.service.selection.set({
                            elements: [
                                newNoteBlock.id
                            ],
                            editing: true
                        });
                        return true;
                    }
                    if (containBlock(this.selectedBlocks.map((selection)=>selection.blockId), targetBlockId)) {
                        return false;
                    }
                    const selectedBlocks = getBlockComponentsExcludeSubtrees(draggingElements).map((element)=>getModelByBlockComponent(element)).filter((x)=>!!x);
                    if (!selectedBlocks.length) {
                        return false;
                    }
                    const targetBlock = this.doc.getBlockById(targetBlockId);
                    if (!targetBlock) return;
                    const shouldInsertIn = dropType === 'in';
                    const parent = shouldInsertIn ? targetBlock : this.doc.getParent(targetBlockId);
                    if (!parent) return;
                    const altKey = state.raw.altKey;
                    if (shouldInsertIn) {
                        if (altKey) {
                            const duplicateBlocks = getDuplicateBlocks(selectedBlocks);
                            this.doc.addBlocks(duplicateBlocks, targetBlock);
                        } else {
                            this.doc.moveBlocks(selectedBlocks, targetBlock);
                        }
                    } else {
                        if (altKey) {
                            const duplicateBlocks = getDuplicateBlocks(selectedBlocks);
                            const parentIndex = parent.children.indexOf(targetBlock) + (dropType === 'after' ? 1 : 0);
                            this.doc.addBlocks(duplicateBlocks, parent, parentIndex);
                        } else {
                            this.doc.moveBlocks(selectedBlocks, parent, targetBlock, dropType === 'before');
                        }
                    }
                    setTimeout(()=>{
                        if (!parent) return;
                        const parentElement = this._getBlockComponentFromViewStore(parent.id);
                        if (parentElement) {
                            const newSelectedBlocks = selectedBlocks.map((block)=>{
                                return this.std.view.getBlock(block.id);
                            });
                            if (!newSelectedBlocks) return;
                            const noteId = getNoteId(parentElement);
                            this._setSelectedBlocks(newSelectedBlocks, noteId);
                        }
                    }, 0);
                    return true;
                };
                this._onDragHandlePointerDown = ()=>{
                    if (!this._isHoverDragHandleVisible || !this._anchorBlockPath) return;
                    const block = this.anchorBlockComponent;
                    if (!block) return;
                    this._dragHoverRect = this._getDraggingAreaRect(block) ?? null;
                };
                this._onDragHandlePointerEnter = ()=>{
                    const container = this._dragHandleContainer;
                    const grabber = this._dragHandleGrabber;
                    if (!container || !grabber) return;
                    if (this._isHoverDragHandleVisible && this._anchorBlockPath) {
                        const block = this.anchorBlockComponent;
                        if (!block) return;
                        const padding = DRAG_HANDLE_CONTAINER_PADDING * this.scale;
                        container.style.paddingTop = `${padding}px`;
                        container.style.paddingBottom = `${padding}px`;
                        container.style.transition = `padding 0.25s ease`;
                        grabber.style.width = `${DRAG_HANDLE_GRABBER_WIDTH_HOVERED * this.scale * this.noteScale}px`;
                        grabber.style.borderRadius = `${DRAG_HANDLE_GRABBER_BORDER_RADIUS * this.scale * this.noteScale}px`;
                        this._isDragHandleHovered = true;
                    } else if (this._isTopLevelDragHandleVisible) {
                        const edgelessElement = this.anchorEdgelessElement;
                        if (!edgelessElement) return;
                        this._dragHoverRect = this._getHoverAreaRectTopLevelBlock(edgelessElement);
                        this._isDragHandleHovered = true;
                    }
                };
                this._onDragHandlePointerLeave = ()=>{
                    this._isDragHandleHovered = false;
                    this._dragHoverRect = null;
                    if (this._isTopLevelDragHandleVisible) return;
                    if (this.dragging) return;
                    if (!this._anchorBlockPath) return;
                    this._showDragHandleOnHoverBlock(this._anchorBlockPath);
                };
                this._onDragHandlePointerUp = ()=>{
                    if (!this._isHoverDragHandleVisible) return;
                    this._dragHoverRect = null;
                };
                this._onDragMove = (state)=>{
                    this._clearRaf();
                    this.rafID = requestAnimationFrame(()=>{
                        this._updateDragPreviewPosition(state);
                        this._updateDropIndicator(state, true);
                    });
                    return true;
                };
                this._onDragStart = (state)=>{
                    const event = state.raw;
                    const { target } = event;
                    const element = captureEventTarget(target);
                    const insideDragHandle = !!element?.closest(AFFINE_DRAG_HANDLE_WIDGET);
                    if (!insideDragHandle) {
                        this._hide();
                        return false;
                    }
                    if (!this._isHoverDragHandleVisible || !this._anchorBlockId || !this._anchorBlockPath) return;
                    const hoverBlock = this.anchorBlockComponent;
                    if (!hoverBlock) return false;
                    let selections = this.selectedBlocks;
                    if (selections.length > 0 && includeTextSelection(selections)) {
                        const nativeSelection = document.getSelection();
                        if (nativeSelection && nativeSelection.rangeCount > 0 && this._rangeManager) {
                            const range = nativeSelection.getRangeAt(0);
                            const blocks = this._rangeManager.getSelectedBlockComponentsByRange(range, {
                                match: (el)=>el.model.role === 'content',
                                mode: 'highest'
                            });
                            this._setSelectedBlocks(blocks);
                            selections = this.selectedBlocks;
                        }
                    }
                    if (selections.length === 0 || !containBlock(selections.map((selection)=>selection.blockId), this._anchorBlockId)) {
                        const block = this.anchorBlockComponent;
                        if (block) {
                            this._setSelectedBlocks([
                                block
                            ]);
                        }
                    }
                    const blocks = this.selectedBlocks.map((selection)=>{
                        return this._getBlockComponentFromViewStore(selection.blockId);
                    }).filter((element)=>!!element);
                    const blocksExcludingChildren = getBlockComponentsExcludeSubtrees(blocks);
                    if (blocksExcludingChildren.length === 0) return false;
                    this._startDragging(blocksExcludingChildren, state);
                    this._hide();
                    return true;
                };
                this._pointerMoveOnBlock = (state)=>{
                    if (this._isTopLevelDragHandleVisible) return;
                    const point = new Point(state.raw.x, state.raw.y);
                    const closestBlock = getClosestBlockByPoint(this.host, this.rootComponent, point);
                    if (!closestBlock) {
                        this._anchorBlockId = '';
                        this._anchorBlockPath = null;
                        return;
                    }
                    const blockId = closestBlock.getAttribute(this.host.blockIdAttr);
                    if (!blockId) return;
                    this._anchorBlockId = blockId;
                    this._anchorBlockPath = closestBlock.blockId;
                    if (insideDatabaseTable(closestBlock) || this.doc.readonly) {
                        this._hide();
                        return;
                    }
                    if ((!this._lastHoveredBlockPath || !isBlockPathEqual(this._anchorBlockPath, this._lastHoveredBlockPath) || !this._isHoverDragHandleVisible) && !this._isDragHandleHovered) {
                        this._showDragHandleOnHoverBlock(this._anchorBlockPath);
                        this._lastHoveredBlockPath = this._anchorBlockPath;
                    }
                };
                this._pointerOutHandler = (ctx)=>{
                    const state = ctx.get('pointerState');
                    state.raw.preventDefault();
                    const { target } = state.raw;
                    const element = captureEventTarget(target);
                    if (!element) return;
                    const { relatedTarget } = state.raw;
                    const relatedElement = captureEventTarget(relatedTarget);
                    const outOfPageViewPort = element.classList.contains('affine-page-viewport');
                    const inPage = !!relatedElement?.closest('.affine-page-viewport');
                    const inDragHandle = !!relatedElement?.closest(AFFINE_DRAG_HANDLE_WIDGET);
                    if (outOfPageViewPort && !inDragHandle && !inPage) {
                        this._hide();
                    }
                };
                this._removeDragPreview = ()=>{
                    if (this.dragPreview) {
                        this.dragPreview.remove();
                        this.dragPreview = null;
                    }
                };
                this._removeDropIndicator = ()=>{
                    if (this.dropIndicator) {
                        this.dropIndicator.remove();
                        this.dropIndicator = null;
                    }
                };
                this._reset = ()=>{
                    this.draggingElements = [];
                    this.dropBlockId = '';
                    this.dropType = null;
                    this.lastDragPointerState = null;
                    this.rafID = 0;
                    this.dragging = false;
                    this._dragHoverRect = null;
                    this._lastHoveredBlockPath = null;
                    this._lastShowedBlock = null;
                    this._anchorBlockId = '';
                    this._anchorBlockPath = null;
                    this._isHoverDragHandleVisible = false;
                    this._isDragHandleHovered = false;
                    this._isTopLevelDragHandleVisible = false;
                    this._removeDragPreview();
                    this._removeDropIndicator();
                    this._resetCursor();
                };
                this._resetCursor = ()=>{
                    document.documentElement.classList.remove('affine-drag-preview-grabbing');
                };
                this._resetDropResult = ()=>{
                    this.dropBlockId = '';
                    this.dropType = null;
                    if (this.dropIndicator) this.dropIndicator.rect = null;
                };
                this._setSelectedBlocks = (blocks, noteId)=>{
                    const { selection } = this.host;
                    const selections = blocks.map((block)=>selection.create('block', {
                            blockId: block.blockId
                        }));
                    if (isInsideEdgelessEditor(this.host)) {
                        const surfaceElementId = noteId ? noteId : getNoteId(blocks[0]);
                        const surfaceSelection = selection.create('surface', blocks[0].blockId, [
                            surfaceElementId
                        ], true);
                        selections.push(surfaceSelection);
                    }
                    selection.set(selections);
                };
                this._showDragHandleOnHoverBlock = (blockPath)=>{
                    const block = this._getBlockComponentFromViewStore(blockPath);
                    if (!block) return;
                    const container = this._dragHandleContainer;
                    const grabber = this._dragHandleGrabber;
                    if (!container || !grabber) return;
                    this._isHoverDragHandleVisible = true;
                    const draggingAreaRect = this._getDraggingAreaRect(block);
                    const containerHeight = getDragHandleContainerHeight(block.model);
                    updateDragHandleClassName([
                        block
                    ]);
                    const posTop = this._getTopWithBlockComponent(block);
                    const rowPaddingY = (containerHeight - DRAG_HANDLE_GRABBER_HEIGHT) / 2 * this.scale * this.noteScale;
                    const paddingTop = rowPaddingY + posTop - draggingAreaRect.top;
                    const paddingBottom = draggingAreaRect.height - paddingTop - DRAG_HANDLE_GRABBER_HEIGHT * this.scale * this.noteScale;
                    const applyStyle = (transition)=>{
                        container.style.transition = transition ? 'padding 0.25s ease' : 'none';
                        container.style.paddingTop = `${paddingTop}px`;
                        container.style.paddingBottom = `${paddingBottom}px`;
                        container.style.width = `${DRAG_HANDLE_CONTAINER_WIDTH * this.scale * this.noteScale}px`;
                        container.style.left = `${draggingAreaRect.left}px`;
                        container.style.top = `${draggingAreaRect.top}px`;
                        container.style.display = 'flex';
                        container.style.height = `${draggingAreaRect.height}px`;
                    };
                    if (isBlockPathEqual(block.blockId, this._lastShowedBlock?.path)) {
                        applyStyle(true);
                    } else if (this.selectedBlocks.length) {
                        if (this._isBlockSelected(block)) applyStyle(this._isDragHandleHovered && this._isBlockSelected(this._lastShowedBlock?.el));
                        else applyStyle(false);
                    } else {
                        applyStyle(false);
                    }
                    grabber.style.width = `${DRAG_HANDLE_GRABBER_WIDTH * this.scale * this.noteScale}px`;
                    grabber.style.borderRadius = `${DRAG_HANDLE_GRABBER_BORDER_RADIUS * this.scale * this.noteScale}px`;
                    this._handleAnchorModelDisposables(block.model);
                    if (!isBlockPathEqual(block.blockId, this._lastShowedBlock?.path)) {
                        this._lastShowedBlock = {
                            path: block.blockId,
                            el: block
                        };
                    }
                };
                this._showDragHandleOnTopLevelBlocks = async ()=>{
                    if (isInsidePageEditor(this.host)) return;
                    const edgelessRoot = this.rootComponent;
                    await edgelessRoot.surface.updateComplete;
                    if (!this._anchorBlockPath) return;
                    const block = this.anchorBlockComponent;
                    if (!block) return;
                    const edgelessElement = edgelessRoot.service.getElementById(block.model.id);
                    if (!edgelessElement) return;
                    const container = this._dragHandleContainer;
                    const grabber = this._dragHandleGrabber;
                    if (!container || !grabber) return;
                    const rect = getSelectedRect([
                        edgelessElement
                    ]);
                    const [left, top] = edgelessRoot.service.viewport.toViewCoord(rect.left, rect.top);
                    const height = rect.height * this.scale;
                    const posLeft = left - (DRAG_HANDLE_CONTAINER_WIDTH_TOP_LEVEL + DRAG_HANDLE_CONTAINER_OFFSET_LEFT_TOP_LEVEL) * this.scale;
                    const posTop = top;
                    container.style.transition = 'none';
                    container.style.paddingTop = `0px`;
                    container.style.paddingBottom = `0px`;
                    container.style.width = `${DRAG_HANDLE_CONTAINER_WIDTH_TOP_LEVEL * this.scale}px`;
                    container.style.left = `${posLeft}px`;
                    container.style.top = `${posTop}px`;
                    container.style.display = 'flex';
                    container.style.height = `${height}px`;
                    grabber.style.width = `${DRAG_HANDLE_GRABBER_WIDTH_HOVERED * this.scale}px`;
                    grabber.style.borderRadius = `${DRAG_HANDLE_GRABBER_BORDER_RADIUS * this.scale}px`;
                    this._handleAnchorModelDisposables(block.model);
                    this._isTopLevelDragHandleVisible = true;
                };
                this._startDragging = (blocks, state, dragPreviewEl, dragPreviewOffset)=>{
                    if (!blocks.length) {
                        return;
                    }
                    this.draggingElements = blocks;
                    if (this.dragPreview) {
                        this._removeDragPreview();
                    }
                    this.dragPreview = this._createDragPreview(blocks, state, dragPreviewEl, dragPreviewOffset);
                    this.dragging = true;
                    this._changeCursorToGrabbing();
                    this._createDropIndicator();
                    this._hide();
                };
                this._throttledPointerMoveHandler = throttle((ctx)=>{
                    if (this.doc.readonly || this.dragging || !this.isConnected) {
                        this._hide();
                        return;
                    }
                    if (this._isTopLevelDragHandleVisible) return;
                    const state = ctx.get('pointerState');
                    const { target } = state.raw;
                    const element = captureEventTarget(target);
                    if (!element) return;
                    if (element.closest('.affine-drag-handle-container')) return;
                    const point = new Point(state.raw.x, state.raw.y);
                    const closestNoteBlock = getClosestNoteBlock(this.host, this.rootComponent, point);
                    this.noteScale = isInsidePageEditor(this.host) ? 1 : closestNoteBlock?.model.edgeless.scale ?? 1;
                    if (closestNoteBlock && this._canEditing(closestNoteBlock) && !isOutOfNoteBlock(this.host, closestNoteBlock, point, this.scale * this.noteScale)) {
                        this._pointerMoveOnBlock(state);
                        return true;
                    }
                    this._hide();
                    return false;
                }, 1000 / 60);
                this._updateDragHoverRectTopLevelBlock = ()=>{
                    if (!this._dragHoverRect) return;
                    const edgelessElement = this.anchorEdgelessElement;
                    if (edgelessElement) {
                        this._dragHoverRect = this._getHoverAreaRectTopLevelBlock(edgelessElement);
                    }
                };
                this._updateDragPreviewOnViewportUpdate = ()=>{
                    if (this.dragPreview && this.lastDragPointerState) {
                        this._updateDragPreviewPosition(this.lastDragPointerState);
                    }
                };
                this._updateDragPreviewPosition = (state)=>{
                    if (!this.dragPreview) return;
                    const offsetParentRect = this.dragHandleContainerOffsetParent.getBoundingClientRect();
                    const dragPreviewOffset = this.dragPreview.offset;
                    let posX = state.raw.x - dragPreviewOffset.x - offsetParentRect.left;
                    posX /= this.cumulativeParentScale;
                    let posY = state.raw.y - dragPreviewOffset.y - offsetParentRect.top;
                    posY /= this.cumulativeParentScale;
                    this.dragPreview.style.transform = `translate(${posX}px, ${posY}px) scale(${this.scale * this.noteScale})`;
                    const altKey = state.raw.altKey;
                    this.dragPreview.style.opacity = altKey ? '1' : '0.5';
                };
                this._updateDropIndicator = (state, shouldAutoScroll = false)=>{
                    const point = new Point(state.raw.x, state.raw.y);
                    const closestNoteBlock = getClosestNoteBlock(this.host, this.rootComponent, point);
                    if (!closestNoteBlock || isOutOfNoteBlock(this.host, closestNoteBlock, point, this.scale)) {
                        this._resetDropResult();
                    } else {
                        const dropResult = this._getDropResult(state);
                        this._updateDropResult(dropResult);
                    }
                    this.lastDragPointerState = state;
                    if (this.rootComponent instanceof PageRootBlockComponent) {
                        if (!shouldAutoScroll) return;
                        const result = autoScroll(this.scrollContainer, state.raw.y);
                        if (!result) {
                            this._clearRaf();
                            return;
                        }
                        this.rafID = requestAnimationFrame(()=>this._updateDropIndicator(state, true));
                    } else {
                        this._clearRaf();
                    }
                };
                this._updateDropIndicatorOnScroll = ()=>{
                    if (!this.dragging || this.draggingElements.length === 0 || !this.lastDragPointerState) return;
                    const state = this.lastDragPointerState;
                    this.rafID = requestAnimationFrame(()=>this._updateDropIndicator(state, false));
                };
                this._updateDropResult = (dropResult)=>{
                    if (!this.dropIndicator) return;
                    this.dropBlockId = dropResult?.dropBlockId ?? '';
                    this.dropType = dropResult?.dropType ?? null;
                    if (dropResult?.rect) {
                        const offsetParentRect = this.dragHandleContainerOffsetParent.getBoundingClientRect();
                        let { left, top } = dropResult.rect;
                        left -= offsetParentRect.left;
                        top -= offsetParentRect.top;
                        left /= this.cumulativeParentScale;
                        top /= this.cumulativeParentScale;
                        let { width, height } = dropResult.rect;
                        width /= this.cumulativeParentScale;
                        height /= this.cumulativeParentScale;
                        const rect = Rect.fromLWTH(left, width, top, height);
                        this.dropIndicator.rect = rect;
                    } else {
                        this.dropIndicator.rect = dropResult?.rect ?? null;
                    }
                };
                this.center = [
                    0,
                    0
                ];
                this.cumulativeParentScale = 1;
                this.dragPreview = null;
                this.dragging = false;
                this.draggingElements = [];
                this.dropBlockId = '';
                this.dropIndicator = null;
                this.dropType = null;
                this.lastDragPointerState = null;
                this.noteScale = 1;
                this.rafID = 0;
                this.scale = 1;
                this.#___private__dragHandleContainer_1 = (_initProto(this), _init__dragHandleContainer(this));
                this.#___private__dragHandleGrabber_2 = _init__dragHandleGrabber(this);
                this.#___private__dragHoverRect_3 = _init__dragHoverRect(this, null);
            }
        }
    }
}();
export { _AffineDragHandleWidget as AffineDragHandleWidget };
