import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { notify, Wrapper } from '@affine/component';
import { AuthInput, BackButton, ModalHeader } from '@affine/component/auth-components';
import { Button } from '@affine/component/ui/button';
import { useAsyncCallback } from '@affine/core/hooks/affine-async-hooks';
import { AuthService } from '@affine/core/modules/cloud';
import { useI18n } from '@affine/i18n';
import { useService } from '@toeverything/infra';
import { useCallback, useState } from 'react';
import * as styles from './style.css';
import { useCaptcha } from './use-captcha';
export const SignInWithPassword = ({ setAuthState, setEmailType, email, onSignedIn })=>{
    const t = useI18n();
    const authService = useService(AuthService);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [verifyToken, challenge] = useCaptcha();
    const [isLoading, setIsLoading] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const onSignIn = useAsyncCallback(async ()=>{
        if (isLoading) return;
        setIsLoading(true);
        try {
            await authService.signInPassword({
                email,
                password
            });
            onSignedIn?.();
        } catch (err) {
            console.error(err);
            setPasswordError(true);
        } finally{
            setIsLoading(false);
        }
    }, [
        isLoading,
        authService,
        email,
        password,
        onSignedIn
    ]);
    const sendMagicLink = useAsyncCallback(async ()=>{
        if (sendingEmail) return;
        setSendingEmail(true);
        try {
            if (verifyToken) {
                await authService.sendEmailMagicLink(email, verifyToken, challenge);
                setAuthState('afterSignInSendEmail');
            }
        } catch (err) {
            console.error(err);
            notify.error({
                title: 'Failed to send email, please try again.'
            });
        }
        setSendingEmail(false);
    }, [
        sendingEmail,
        verifyToken,
        authService,
        email,
        challenge,
        setAuthState
    ]);
    const sendChangePasswordEmail = useCallback(()=>{
        setEmailType('changePassword');
        setAuthState('sendEmail');
    }, [
        setAuthState,
        setEmailType
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(ModalHeader, {
                title: t['com.affine.auth.sign.in'](),
                subTitle: t['com.affine.brand.affineCloud']()
            }),
            _jsxs(Wrapper, {
                marginTop: 30,
                style: {
                    position: 'relative'
                },
                children: [
                    _jsx(AuthInput, {
                        label: t['com.affine.settings.email'](),
                        disabled: true,
                        value: email
                    }),
                    _jsx(AuthInput, {
                        autoFocus: true,
                        "data-testid": "password-input",
                        label: t['com.affine.auth.password'](),
                        value: password,
                        type: "password",
                        onChange: useCallback((value)=>{
                            setPassword(value);
                        }, []),
                        error: passwordError,
                        errorHint: t['com.affine.auth.password.error'](),
                        onEnter: onSignIn
                    }),
                    _jsx("div", {
                        className: styles.forgetPasswordButtonRow,
                        style: {
                            display: 'none'
                        },
                        children: _jsx("a", {
                            className: styles.linkButton,
                            onClick: sendChangePasswordEmail,
                            style: {
                                color: 'var(--affine-text-secondary-color)',
                                fontSize: 'var(--affine-font-sm)'
                            },
                            children: t['com.affine.auth.forget']()
                        })
                    }),
                    _jsx("div", {
                        className: styles.sendMagicLinkButtonRow,
                        children: _jsx("a", {
                            "data-testid": "send-magic-link-button",
                            className: styles.linkButton,
                            onClick: sendMagicLink,
                            children: t['com.affine.auth.sign.auth.code.send-email.sign-in']()
                        })
                    }),
                    _jsx(Button, {
                        "data-testid": "sign-in-button",
                        variant: "primary",
                        size: "extraLarge",
                        style: {
                            width: '100%'
                        },
                        disabled: isLoading,
                        onClick: onSignIn,
                        children: t['com.affine.auth.sign.in']()
                    })
                ]
            }),
            _jsx(BackButton, {
                onClick: useCallback(()=>{
                    setAuthState('signIn');
                }, [
                    setAuthState
                ])
            })
        ]
    });
};
