import { Text } from '@blocksuite/store';
import { isInsideBlockByFlavour, matchFlavours } from '../../../_common/utils/model.js';
import { getBlockComponentByModel, getDocTitleByEditorHost, getInlineEditorByModel, getNextBlock, getPreviousBlock } from '../../../_common/utils/query.js';
import { asyncFocusRichText, asyncSetInlineRange, focusBlockByModel, focusTitle } from '../../../_common/utils/selection.js';
import { EMBED_BLOCK_FLAVOUR_LIST } from '../../consts.js';
function supportsChildren(model) {
    if (matchFlavours(model, [
        'affine:image',
        'affine:divider',
        'affine:code'
    ])) {
        return false;
    }
    if (matchFlavours(model, [
        'affine:paragraph'
    ]) && [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'quote'
    ].includes(model.type ?? '')) {
        return false;
    }
    return true;
}
export function handleBlockEndEnter(editorHost, model) {
    const doc = model.doc;
    const parent = doc.getParent(model);
    const nextSibling = doc.getNext(model);
    if (!parent) {
        return;
    }
    const getProps = ()=>{
        const shouldInheritFlavour = matchFlavours(model, [
            'affine:list'
        ]);
        if (shouldInheritFlavour) {
            return [
                model.flavour,
                {
                    type: model.type
                }
            ];
        }
        return [
            'affine:paragraph',
            {
                type: 'text'
            }
        ];
    };
    const [flavour, blockProps] = getProps();
    if (isInsideBlockByFlavour(doc, model, 'affine:database')) {
        doc.captureSync();
        const index = parent.children.findIndex((child)=>child.id === model.id);
        let newParent = parent;
        let newBlockIndex = index + 1;
        const childrenLength = parent.children.length;
        if (index === childrenLength - 1 && model.text?.yText.length === 0) {
            if (childrenLength !== 1) {
                doc.deleteBlock(model);
            }
            const nextModel = doc.getNext(newParent);
            if (nextModel && matchFlavours(nextModel, [
                'affine:paragraph'
            ])) {
                asyncFocusRichText(editorHost, nextModel.id, nextModel.text.length)?.catch(console.error);
                return;
            }
            const prevParent = doc.getParent(parent);
            if (!prevParent) return;
            const prevIndex = prevParent.children.findIndex((child)=>child.id === parent.id);
            newParent = prevParent;
            newBlockIndex = prevIndex + 1;
        }
        const id = doc.addBlock(flavour, blockProps, newParent, newBlockIndex);
        asyncFocusRichText(editorHost, id)?.catch(console.error);
        return;
    }
    const index = parent.children.indexOf(model);
    if (index === -1) {
        return;
    }
    doc.captureSync();
    let id;
    if (model.children.length > 0) {
        id = doc.addBlock(flavour, blockProps, model, 0);
    } else {
        id = doc.addBlock(flavour, blockProps, parent, index + 1);
    }
    if (matchFlavours(model, [
        'affine:list'
    ]) && model.type === 'numbered') {
        let next = nextSibling;
        while(next && matchFlavours(next, [
            'affine:list'
        ]) && model.type === 'numbered'){
            doc.updateBlock(next, {});
            next = doc.getNext(next);
        }
    }
    asyncFocusRichText(editorHost, id)?.catch(console.error);
}
export function handleBlockSplit(editorHost, model, splitIndex, splitLength) {
    if (!(model.text instanceof Text)) return;
    if (model.text.yText.length < splitIndex + splitLength) return;
    const doc = model.doc;
    const parent = doc.getParent(model);
    if (!parent) return;
    const modelIndex = parent.children.indexOf(model);
    if (modelIndex === -1) return;
    doc.captureSync();
    const right = model.text.split(splitIndex, splitLength);
    if (model.children.length > 0 && splitIndex > 0) {
        const id = doc.addBlock(model.flavour, {
            text: right,
            type: model.type
        }, model, 0);
        return asyncFocusRichText(editorHost, id);
    } else {
        const id = doc.addBlock(model.flavour, {
            text: right,
            type: model.type
        }, parent, modelIndex + 1);
        const newModel = doc.getBlock(id).model;
        doc.moveBlocks(model.children, newModel);
        return asyncFocusRichText(editorHost, id);
    }
}
export function handleIndent(editorHost, model, offset = 0) {
    const doc = model.doc;
    const previousSibling = doc.getPrev(model);
    if (doc.readonly || !previousSibling || !supportsChildren(previousSibling)) {
        return;
    }
    const nextSiblings = doc.getNexts(model);
    doc.captureSync();
    doc.moveBlocks([
        model
    ], previousSibling);
    if (matchFlavours(model, [
        'affine:list'
    ]) && model.type === 'numbered') {
        doc.updateBlock(model, {});
    }
    nextSiblings.filter((sibling)=>matchFlavours(sibling, [
            'affine:list'
        ]) && sibling.type === 'numbered').forEach((sibling)=>{
        doc.updateBlock(sibling, {});
    });
    if (matchFlavours(previousSibling, [
        'affine:list'
    ]) && previousSibling.collapsed) {
        doc.updateBlock(previousSibling, {
            collapsed: false
        });
    }
    asyncSetInlineRange(editorHost, model, {
        index: offset,
        length: 0
    }).catch(console.error);
}
export function handleMultiBlockIndent(editorHost, models) {
    if (!models.length) return;
    const doc = models[0].doc;
    let firstIndentIndex = -1;
    let previousSibling = null;
    for(let i = 0; i < models.length; i++){
        previousSibling = doc.getPrev(models[i]);
        if (previousSibling && supportsChildren(previousSibling)) {
            firstIndentIndex = i;
            break;
        }
    }
    if (firstIndentIndex === -1) return;
    doc.captureSync();
    const indentModels = models.slice(firstIndentIndex);
    indentModels.forEach((model)=>{
        const parent = doc.getParent(model);
        if (!parent) return;
        if (!indentModels.includes(parent)) {
            handleIndent(editorHost, model);
        }
    });
}
export function handleUnindent(editorHost, model, offset = 0) {
    const doc = model.doc;
    const parent = doc.getParent(model);
    if (doc.readonly || !parent || parent.role !== 'content') {
        return;
    }
    const grandParent = doc.getParent(parent);
    if (!grandParent) return;
    doc.captureSync();
    const nextSiblings = doc.getNexts(model);
    const parentNextSiblings = doc.getNexts(parent);
    doc.moveBlocks(nextSiblings, model);
    doc.moveBlocks([
        model
    ], grandParent, parent, false);
    if (matchFlavours(model, [
        'affine:list'
    ]) && model.type === 'numbered') {
        doc.updateBlock(model, {});
    }
    model.children.forEach((child)=>{
        if (matchFlavours(child, [
            'affine:list'
        ]) && child.type === 'numbered') {
            doc.updateBlock(child, {});
        }
    });
    parentNextSiblings.filter((sibling)=>matchFlavours(sibling, [
            'affine:list'
        ]) && sibling.type === 'numbered').forEach((sibling)=>{
        doc.updateBlock(sibling, {});
    });
    asyncSetInlineRange(editorHost, model, {
        index: offset,
        length: 0
    }).catch(console.error);
}
export function handleMultiBlockOutdent(editorHost, models) {
    if (!models.length) return;
    const doc = models[0].doc;
    let firstOutdentIndex = -1;
    let firstParent;
    for(let i = 0; i < models.length; i++){
        firstParent = doc.getParent(models[i]);
        if (firstParent && !matchFlavours(firstParent, [
            'affine:note'
        ])) {
            firstOutdentIndex = i;
            break;
        }
    }
    const outdentModels = models.slice(firstOutdentIndex);
    for(let i = outdentModels.length - 1; i >= 0; i--){
        const model = outdentModels[i];
        const parent = doc.getParent(model);
        if (parent && !outdentModels.includes(parent)) {
            handleUnindent(editorHost, model);
        }
    }
}
export function handleRemoveAllIndent(editorHost, model, offset = 0) {
    const doc = model.doc;
    let parent = doc.getParent(model);
    while(parent && !matchFlavours(parent, [
        'affine:note'
    ])){
        handleUnindent(editorHost, model, offset);
        parent = doc.getParent(model);
    }
}
export function handleRemoveAllIndentForMultiBlocks(editorHost, models) {
    if (!models.length) return;
    const doc = models[0].doc;
    for(let i = models.length - 1; i >= 0; i--){
        const model = models[i];
        const parent = doc.getParent(model);
        if (parent && !matchFlavours(parent, [
            'affine:note'
        ])) {
            handleRemoveAllIndent(editorHost, model);
        }
    }
}
function handleCodeBlockForwardDelete(model) {
    if (!matchFlavours(model, [
        'affine:code'
    ])) return false;
    return true;
}
function handleDatabaseBlockForwardDelete(model) {
    const doc = model.doc;
    if (!isInsideBlockByFlavour(doc, model, 'affine:database')) return false;
    return true;
}
function handleListBlockBackspace(editorHost, model) {
    const doc = model.doc;
    if (!matchFlavours(model, [
        'affine:list'
    ])) return false;
    const parent = doc.getParent(model);
    if (!parent) return false;
    const nextSiblings = doc.getNexts(model);
    const index = parent.children.indexOf(model);
    const blockProps = {
        type: 'text',
        text: model.text?.clone(),
        children: model.children
    };
    doc.captureSync();
    doc.deleteBlock(model, {
        deleteChildren: false
    });
    nextSiblings.filter((sibling)=>matchFlavours(sibling, [
            'affine:list'
        ]) && sibling.type === 'numbered').forEach((sibling)=>doc.updateBlock(sibling, {}));
    const id = doc.addBlock('affine:paragraph', blockProps, parent, index);
    asyncFocusRichText(editorHost, id)?.catch(console.error);
    return true;
}
function handleListBlockForwardDelete(editorHost, model) {
    if (!matchFlavours(model, [
        'affine:list'
    ])) return false;
    const doc = model.doc;
    const firstChild = model.firstChild();
    if (firstChild) {
        model.text?.join(firstChild.text);
        const grandChildren = firstChild.children;
        if (grandChildren) {
            doc.moveBlocks(grandChildren, model);
            doc.deleteBlock(firstChild);
            return true;
        } else {
            doc.deleteBlock(firstChild);
            return true;
        }
    } else {
        const nextSibling = doc.getNext(model);
        if (nextSibling) {
            model.text?.join(nextSibling.text);
            if (nextSibling.children) {
                const parent = doc.getParent(nextSibling);
                if (!parent) return false;
                doc.moveBlocks(nextSibling.children, parent, model, false);
                doc.deleteBlock(nextSibling);
                return true;
            } else {
                doc.deleteBlock(nextSibling);
                return true;
            }
        } else {
            const nextBlock = getNextBlock(editorHost, model);
            if (!nextBlock) {
                return true;
            }
            model.text?.join(nextBlock.text);
            if (nextBlock.children) {
                const parent = doc.getParent(nextBlock);
                if (!parent) return false;
                doc.moveBlocks(nextBlock.children, parent, doc.getParent(model), false);
                doc.deleteBlock(nextBlock);
                return true;
            } else {
                doc.deleteBlock(nextBlock);
                return true;
            }
        }
    }
}
function handleParagraphOrListSibling(editorHost, model, previousSibling, parent) {
    const doc = model.doc;
    if (!matchFlavours(previousSibling, [
        'affine:paragraph',
        'affine:list'
    ])) return false;
    doc.captureSync();
    const preTextLength = previousSibling.text?.length || 0;
    model.text?.length && previousSibling.text?.join(model.text);
    doc.deleteBlock(model, {
        bringChildrenTo: parent
    });
    const inlineEditor = getInlineEditorByModel(editorHost, previousSibling);
    inlineEditor?.setInlineRange({
        index: preTextLength,
        length: 0
    });
    return true;
}
function handleEmbedDividerCodeSibling(editorHost, model, previousSibling, parent) {
    const doc = model.doc;
    if (matchFlavours(previousSibling, [
        'affine:divider'
    ])) {
        doc.deleteBlock(previousSibling);
        return true;
    }
    if (!matchFlavours(previousSibling, [
        'affine:image',
        'affine:code',
        'affine:bookmark',
        'affine:attachment',
        'affine:surface-ref'
    ])) return false;
    focusBlockByModel(editorHost, previousSibling);
    if (!model.text?.length) {
        doc.captureSync();
        doc.deleteBlock(model, {
            bringChildrenTo: parent
        });
    }
    return true;
}
function handleNoPreviousSibling(editorHost, model) {
    const doc = model.doc;
    const text = model.text;
    const parent = doc.getParent(model);
    if (!parent) return false;
    const titleElement = getDocTitleByEditorHost(editorHost);
    if (!titleElement) {
        if (matchFlavours(parent, [
            'affine:edgeless-text'
        ]) || model.children.length > 0) {
            doc.deleteBlock(model, {
                bringChildrenTo: parent
            });
            return true;
        }
        return false;
    }
    const rootModel = model.doc.root;
    const title = rootModel.title;
    doc.captureSync();
    let textLength = 0;
    if (text) {
        textLength = text.length;
        title.join(text);
    }
    if (doc.getNext(model) || model.children.length > 0) {
        const parent = doc.getParent(model);
        if (!parent) return false;
        doc.deleteBlock(model, {
            bringChildrenTo: parent
        });
    } else {
        text?.clear();
    }
    focusTitle(editorHost, title.length - textLength);
    return true;
}
function handleParagraphDeleteActions(editorHost, model) {
    const doc = model.doc;
    const parent = doc.getParent(model);
    if (!parent) return false;
    const previousSibling = getPreviousBlock(editorHost, model);
    if (!previousSibling) {
        return handleNoPreviousSibling(editorHost, model);
    } else if (matchFlavours(previousSibling, [
        'affine:paragraph',
        'affine:list'
    ])) {
        const modelIndex = parent.children.indexOf(model);
        if ((modelIndex === -1 || modelIndex === parent.children.length - 1) && parent.role === 'content') return false;
        const lengthBeforeJoin = previousSibling.text?.length ?? 0;
        previousSibling.text?.join(model.text);
        doc.deleteBlock(model, {
            bringChildrenTo: parent
        });
        asyncSetInlineRange(editorHost, previousSibling, {
            index: lengthBeforeJoin,
            length: 0
        }).catch(console.error);
        return true;
    } else if (matchFlavours(previousSibling, [
        'affine:attachment',
        'affine:bookmark',
        'affine:code',
        'affine:image',
        'affine:divider',
        ...EMBED_BLOCK_FLAVOUR_LIST
    ])) {
        const previousSiblingElement = getBlockComponentByModel(editorHost, previousSibling);
        if (!previousSiblingElement) return false;
        const selection = editorHost.selection.create('block', {
            blockId: previousSiblingElement.blockId
        });
        editorHost.selection.setGroup('note', [
            selection
        ]);
        if (model.text?.length === 0) {
            doc.deleteBlock(model, {
                bringChildrenTo: parent
            });
        }
        return true;
    } else if (matchFlavours(previousSibling, [
        'affine:edgeless-text'
    ])) {
        return true;
    }
    if (matchFlavours(parent, [
        'affine:database'
    ])) {
        doc.deleteBlock(model);
        focusBlockByModel(editorHost, previousSibling);
        return true;
    } else if (matchFlavours(parent, [
        'affine:note'
    ])) {
        return handleParagraphOrListSibling(editorHost, model, previousSibling, parent) || handleEmbedDividerCodeSibling(editorHost, model, previousSibling, parent);
    }
    return false;
}
function handleParagraphBlockBackspace(editorHost, model) {
    const doc = model.doc;
    if (!matchFlavours(model, [
        'affine:paragraph'
    ])) return false;
    if (model.type !== 'text') {
        doc.captureSync();
        doc.updateBlock(model, {
            type: 'text'
        });
        return true;
    }
    const isHandled = handleParagraphDeleteActions(editorHost, model);
    if (isHandled) return true;
    handleUnindent(editorHost, model);
    return true;
}
function handleParagraphBlockForwardDelete(editorHost, model) {
    const doc = model.doc;
    function handleParagraphOrList(doc, model, nextSibling, firstChild) {
        function handleParagraphOrListSibling(doc, model, nextSibling) {
            if (nextSibling && matchFlavours(nextSibling, [
                'affine:paragraph',
                'affine:list'
            ])) {
                model.text?.join(nextSibling.text);
                if (nextSibling.children) {
                    const parent = doc.getParent(nextSibling);
                    if (!parent) return false;
                    doc.moveBlocks(nextSibling.children, parent, model, false);
                    doc.deleteBlock(nextSibling);
                    return true;
                } else {
                    doc.deleteBlock(nextSibling);
                    return true;
                }
            } else {
                const nextBlock = getNextBlock(editorHost, model);
                if (!nextBlock || !matchFlavours(nextBlock, [
                    'affine:paragraph',
                    'affine:list'
                ])) return false;
                model.text?.join(nextBlock.text);
                if (nextBlock.children) {
                    const parent = doc.getParent(nextBlock);
                    if (!parent) return false;
                    doc.moveBlocks(nextBlock.children, parent, doc.getParent(model), false);
                    doc.deleteBlock(nextBlock);
                    return true;
                } else {
                    doc.deleteBlock(nextBlock);
                    return true;
                }
            }
        }
        function handleParagraphOrListChild(doc, model, firstChild) {
            if (!firstChild || !matchFlavours(firstChild, [
                'affine:paragraph',
                'affine:list'
            ])) {
                return false;
            }
            const grandChildren = firstChild.children;
            model.text?.join(firstChild.text);
            if (grandChildren) {
                doc.moveBlocks(grandChildren, model);
            }
            doc.deleteBlock(firstChild);
            return true;
        }
        const nextBlock = getNextBlock(editorHost, model);
        if (!firstChild && !nextBlock) return true;
        return handleParagraphOrListChild(doc, model, firstChild) || handleParagraphOrListSibling(doc, model, nextSibling);
    }
    function handleEmbedDividerCode(nextSibling, firstChild) {
        function handleEmbedDividerCodeChild(firstChild) {
            if (!firstChild || !matchFlavours(firstChild, [
                'affine:image',
                'affine:divider',
                'affine:code'
            ])) return false;
            focusBlockByModel(editorHost, firstChild);
            return true;
        }
        function handleEmbedDividerCodeSibling(nextSibling) {
            if (matchFlavours(nextSibling, [
                'affine:divider'
            ])) {
                const nextSiblingComponent = getBlockComponentByModel(editorHost, nextSibling);
                if (!nextSiblingComponent) return false;
                editorHost.selection.setGroup('note', [
                    editorHost.selection.create('block', {
                        blockId: nextSiblingComponent.blockId
                    })
                ]);
                return true;
            }
            if (!nextSibling || !matchFlavours(nextSibling, [
                'affine:image',
                'affine:code'
            ])) return false;
            focusBlockByModel(editorHost, nextSibling);
            return true;
        }
        return handleEmbedDividerCodeChild(firstChild) || handleEmbedDividerCodeSibling(nextSibling);
    }
    if (!matchFlavours(model, [
        'affine:paragraph'
    ])) return false;
    const parent = doc.getParent(model);
    if (!parent) return false;
    const nextSibling = doc.getNext(model);
    const firstChild = model.firstChild();
    if (matchFlavours(parent, [
        'affine:database'
    ])) {
        return false;
    } else {
        const ignoreForwardDeleteFlavourList = [
            'affine:database',
            'affine:image',
            'affine:code',
            'affine:attachment',
            'affine:bookmark',
            ...EMBED_BLOCK_FLAVOUR_LIST
        ];
        if (nextSibling && matchFlavours(nextSibling, ignoreForwardDeleteFlavourList)) {
            return true;
        }
        return handleParagraphOrList(doc, model, nextSibling, firstChild) || handleEmbedDividerCode(nextSibling, firstChild);
    }
}
export function handleLineStartBackspace(editorHost, model) {
    if (handleListBlockBackspace(editorHost, model) || handleParagraphBlockBackspace(editorHost, model)) {
        return;
    }
}
export function handleLineEndForwardDelete(editorHost, model) {
    if (handleCodeBlockForwardDelete(model) || handleListBlockForwardDelete(editorHost, model) || handleParagraphBlockForwardDelete(editorHost, model)) {
        handleDatabaseBlockForwardDelete(model);
        return;
    }
}
