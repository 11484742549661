import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton, MenuIcon, MenuItem, MenuSeparator, toast } from '@affine/component';
import { useAppSettingHelper } from '@affine/core/hooks/affine/use-app-setting-helper';
import { track } from '@affine/core/mixpanel';
import { FavoriteService } from '@affine/core/modules/favorite';
import { TagService } from '@affine/core/modules/tag';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { useI18n } from '@affine/i18n';
import { DeleteIcon, FavoritedIcon, FavoriteIcon, OpenInNewIcon, PlusIcon, SplitViewIcon } from '@blocksuite/icons/rc';
import { DocsService, useLiveData, useServices } from '@toeverything/infra';
import { useCallback, useMemo } from 'react';
export const useExplorerTagNodeOperations = (tagId, { openNodeCollapsed })=>{
    const t = useI18n();
    const { appSettings } = useAppSettingHelper();
    const { docsService, workbenchService, tagService, favoriteService } = useServices({
        WorkbenchService,
        TagService,
        DocsService,
        FavoriteService
    });
    const favorite = useLiveData(favoriteService.favoriteList.favorite$('tag', tagId));
    const tagRecord = useLiveData(tagService.tagList.tagByTagId$(tagId));
    const handleNewDoc = useCallback(()=>{
        if (tagRecord) {
            const newDoc = docsService.createDoc();
            tagRecord?.tag(newDoc.id);
            track.$.navigationPanel.tags.createDoc();
            workbenchService.workbench.openDoc(newDoc.id);
            openNodeCollapsed();
        }
    }, [
        docsService,
        openNodeCollapsed,
        tagRecord,
        workbenchService.workbench
    ]);
    const handleMoveToTrash = useCallback(()=>{
        tagService.tagList.deleteTag(tagId);
        track.$.navigationPanel.organize.deleteOrganizeItem({
            type: 'tag'
        });
        toast(t['com.affine.tags.delete-tags.toast']());
    }, [
        t,
        tagId,
        tagService.tagList
    ]);
    const handleOpenInSplitView = useCallback(()=>{
        workbenchService.workbench.openTag(tagId, {
            at: 'beside'
        });
        track.$.navigationPanel.organize.openInSplitView({
            type: 'tag'
        });
    }, [
        tagId,
        workbenchService
    ]);
    const handleToggleFavoriteTag = useCallback(()=>{
        favoriteService.favoriteList.toggle('tag', tagId);
        track.$.navigationPanel.organize.toggleFavorite({
            type: 'tag'
        });
    }, [
        favoriteService,
        tagId
    ]);
    const handleOpenInNewTab = useCallback(()=>{
        workbenchService.workbench.openTag(tagId, {
            at: 'new-tab'
        });
        track.$.navigationPanel.organize.openInNewTab({
            type: 'tag'
        });
    }, [
        tagId,
        workbenchService
    ]);
    return useMemo(()=>[
            {
                index: 0,
                inline: true,
                view: _jsx(IconButton, {
                    size: "16",
                    onClick: handleNewDoc,
                    tooltip: t['com.affine.rootAppSidebar.explorer.tag-add-tooltip'](),
                    children: _jsx(PlusIcon, {})
                })
            },
            {
                index: 50,
                view: _jsx(MenuItem, {
                    preFix: _jsx(MenuIcon, {
                        children: _jsx(OpenInNewIcon, {})
                    }),
                    onClick: handleOpenInNewTab,
                    children: t['com.affine.workbench.tab.page-menu-open']()
                })
            },
            ...appSettings.enableMultiView ? [
                {
                    index: 100,
                    view: _jsx(MenuItem, {
                        preFix: _jsx(MenuIcon, {
                            children: _jsx(SplitViewIcon, {})
                        }),
                        onClick: handleOpenInSplitView,
                        children: t['com.affine.workbench.split-view.page-menu-open']()
                    })
                }
            ] : [],
            {
                index: 199,
                view: _jsx(MenuItem, {
                    preFix: _jsx(MenuIcon, {
                        children: favorite ? _jsx(FavoritedIcon, {
                            style: {
                                color: 'var(--affine-primary-color)'
                            }
                        }) : _jsx(FavoriteIcon, {})
                    }),
                    onClick: handleToggleFavoriteTag,
                    children: favorite ? t['com.affine.favoritePageOperation.remove']() : t['com.affine.favoritePageOperation.add']()
                })
            },
            {
                index: 9999,
                view: _jsx(MenuSeparator, {}, "menu-separator")
            },
            {
                index: 10000,
                view: _jsx(MenuItem, {
                    type: 'danger',
                    preFix: _jsx(MenuIcon, {
                        children: _jsx(DeleteIcon, {})
                    }),
                    onClick: handleMoveToTrash,
                    children: t['Delete']()
                })
            }
        ], [
        appSettings.enableMultiView,
        favorite,
        handleMoveToTrash,
        handleNewDoc,
        handleOpenInNewTab,
        handleOpenInSplitView,
        handleToggleFavoriteTag,
        t
    ]);
};
