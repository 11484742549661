import { DisposableGroup } from '@blocksuite/global/utils';
export function WithDisposable(SuperClass) {
    class DerivedClass extends SuperClass {
        connectedCallback() {
            super.connectedCallback();
            if (this._disposables.disposed) {
                this._disposables = new DisposableGroup();
            }
        }
        disconnectedCallback() {
            super.disconnectedCallback();
            this._disposables.dispose();
        }
        get disposables() {
            return this._disposables;
        }
        constructor(...args){
            super(...args);
            this._disposables = new DisposableGroup();
        }
    }
    return DerivedClass;
}
