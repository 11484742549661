import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CategoryDivider } from '@affine/core/components/app-sidebar';
import * as Collapsible from '@radix-ui/react-collapsible';
import { useLiveData, useService } from '@toeverything/infra';
import clsx from 'clsx';
import { useCallback } from 'react';
import { ExplorerService } from '../../services/explorer';
import { content, header, root } from './collapsible-section.css';
export const CollapsibleSection = ({ name, title, actions, children, className, testId, headerRef, headerTestId, headerClassName, contentClassName })=>{
    const section = useService(ExplorerService).sections[name];
    const collapsed = useLiveData(section.collapsed$);
    const setCollapsed = useCallback((v)=>{
        section.setCollapsed(v);
    }, [
        section
    ]);
    return _jsxs(Collapsible.Root, {
        "data-collapsed": collapsed,
        className: clsx(root, className),
        open: !collapsed,
        "data-testid": testId,
        children: [
            _jsx(CategoryDivider, {
                "data-testid": headerTestId,
                label: title,
                setCollapsed: setCollapsed,
                collapsed: collapsed,
                ref: headerRef,
                className: clsx(header, headerClassName),
                children: actions
            }),
            _jsx(Collapsible.Content, {
                className: clsx(content, contentClassName),
                children: children
            })
        ]
    });
};
