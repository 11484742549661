import { WorkspaceFlavour } from '@affine/env/workspace';
import { Service } from '@toeverything/infra';
import { ShareDocsList } from '../entities/share-docs-list';
export class ShareDocsService extends Service {
    constructor(workspaceService){
        super();
        this.workspaceService = workspaceService;
        this.shareDocs = this.workspaceService.workspace.flavour === WorkspaceFlavour.AFFINE_CLOUD ? this.framework.createEntity(ShareDocsList) : null;
    }
}
