import { map } from 'rxjs';
import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
import { DocRecord } from './record';
export class DocRecordList extends Entity {
    constructor(store){
        super();
        this.store = store;
        this.pool = new Map();
        this.docs$ = LiveData.from(this.store.watchDocIds().pipe(map((ids)=>ids.map((id)=>{
                const exists = this.pool.get(id);
                if (exists) {
                    return exists;
                }
                const record = this.framework.createEntity(DocRecord, {
                    id
                });
                this.pool.set(id, record);
                return record;
            }))), []);
        this.trashDocs$ = LiveData.from(this.store.watchTrashDocIds().pipe(map((ids)=>ids.map((id)=>{
                const exists = this.pool.get(id);
                if (exists) {
                    return exists;
                }
                const record = this.framework.createEntity(DocRecord, {
                    id
                });
                this.pool.set(id, record);
                return record;
            }))), []);
        this.isReady$ = LiveData.from(this.store.watchDocListReady(), false);
    }
    doc$(id) {
        return this.docs$.map((record)=>record.find((record)=>record.id === id));
    }
    setMode(id, mode) {
        return this.store.setDocModeSetting(id, mode);
    }
    getMode(id) {
        return this.store.getDocModeSetting(id);
    }
    toggleMode(id) {
        const mode = this.getMode(id) === 'edgeless' ? 'page' : 'edgeless';
        this.setMode(id, mode);
        return this.getMode(id);
    }
    observeMode(id) {
        return this.store.watchDocModeSetting(id);
    }
}
