import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import { DisposableGroup, Slot } from '@blocksuite/global/utils';
import { PathFinder } from '../utils/index.js';
import { UIEventState, UIEventStateContext } from './base.js';
import { ClipboardControl } from './control/clipboard.js';
import { KeyboardControl } from './control/keyboard.js';
import { PointerControl } from './control/pointer.js';
import { RangeControl } from './control/range.js';
import { EventScopeSourceType, EventSourceState } from './state/source.js';
import { toLowerCase } from './utils.js';
const bypassEventNames = [
    'beforeInput',
    'blur',
    'focus',
    'drop',
    'contextMenu',
    'wheel'
];
const eventNames = [
    'click',
    'doubleClick',
    'tripleClick',
    'pointerDown',
    'pointerMove',
    'pointerUp',
    'pointerOut',
    'dragStart',
    'dragMove',
    'dragEnd',
    'pinch',
    'pan',
    'keyDown',
    'keyUp',
    'selectionChange',
    'compositionStart',
    'compositionUpdate',
    'compositionEnd',
    'cut',
    'copy',
    'paste',
    ...bypassEventNames
];
export class UIEventDispatcher {
    constructor(std){
        this.std = std;
        this._active = false;
        this._calculatePath = (model)=>{
            const path = [];
            let current = model;
            while(current){
                path.push(current.id);
                current = this.std.doc.getParent(current);
            }
            return path.reverse();
        };
        this._handlersMap = Object.fromEntries(eventNames.map((name)=>[
                name,
                []
            ]));
        this.bindHotkey = (...args)=>this._keyboardControl.bindHotkey(...args);
        this.disposables = new DisposableGroup();
        this.slots = {
            parentScaleChanged: new Slot(),
            editorHostPanned: new Slot()
        };
        this._pointerControl = new PointerControl(this);
        this._keyboardControl = new KeyboardControl(this);
        this._rangeControl = new RangeControl(this);
        this._clipboardControl = new ClipboardControl(this);
    }
    _bindEvents() {
        bypassEventNames.forEach((eventName)=>{
            this.disposables.addFromEvent(this.host, toLowerCase(eventName), (event)=>{
                this.run(eventName, UIEventStateContext.from(new UIEventState(event), new EventSourceState({
                    event,
                    sourceType: EventScopeSourceType.Selection
                })));
            }, eventName === 'wheel' ? {
                passive: false
            } : undefined);
        });
        this._pointerControl.listen();
        this._keyboardControl.listen();
        this._rangeControl.listen();
        this._clipboardControl.listen();
        let _dragging = false;
        this.disposables.addFromEvent(this.host, 'pointerdown', ()=>{
            _dragging = true;
            this._active = true;
        });
        this.disposables.addFromEvent(this.host, 'pointerup', ()=>{
            _dragging = false;
        });
        this.disposables.addFromEvent(this.host, 'click', ()=>{
            this._active = true;
        });
        this.disposables.addFromEvent(this.host, 'focusin', ()=>{
            this._active = true;
        });
        this.disposables.addFromEvent(this.host, 'focusout', (e)=>{
            if (e.relatedTarget && !this.host.contains(e.relatedTarget)) {
                this._active = false;
            }
        });
        this.disposables.addFromEvent(this.host, 'pointerenter', ()=>{
            this._active = true;
        });
        this.disposables.addFromEvent(this.host, 'pointerleave', ()=>{
            if ((!document.activeElement || !this.host.contains(document.activeElement)) && !_dragging) {
                this._active = false;
            }
        });
    }
    _buildEventScopeBySelection(name) {
        const handlers = this._handlersMap[name];
        if (!handlers) return;
        const selections = this._currentSelections;
        const seen = {};
        const paths = selections.map((selection)=>selection.blockId).map((id)=>this.std.doc.getBlockById(id)).filter((block)=>!!block).map((block)=>this._calculatePath(block));
        const flavours = paths.flatMap((path)=>path.map((blockId)=>this.std.doc.getBlockById(blockId)?.flavour)).filter((flavour)=>{
            if (!flavour) return false;
            if (seen[flavour]) return false;
            seen[flavour] = true;
            return true;
        }).reverse();
        return this.buildEventScope(name, flavours, paths);
    }
    _buildEventScopeByTarget(name, target) {
        const handlers = this._handlersMap[name];
        if (!handlers) return;
        const el = target instanceof Element ? target : target.parentElement;
        const block = el?.closest('[data-block-id]');
        const path = block?.path;
        if (!path) {
            return this._buildEventScopeBySelection(name);
        }
        const flavours = path.map((blockId)=>{
            return this.std.doc.getBlockById(blockId)?.flavour;
        }).filter((flavour)=>{
            return !!flavour;
        }).reverse();
        return this.buildEventScope(name, flavours, [
            path
        ]);
    }
    get _currentSelections() {
        return this.std.selection.value;
    }
    _getEventScope(name, state) {
        const handlers = this._handlersMap[name];
        if (!handlers) return;
        let output;
        switch(state.sourceType){
            case EventScopeSourceType.Selection:
                {
                    output = this._buildEventScopeBySelection(name);
                    break;
                }
            case EventScopeSourceType.Target:
                {
                    output = this._buildEventScopeByTarget(name, state.event.target);
                    break;
                }
            default:
                {
                    throw new BlockSuiteError(ErrorCode.EventDispatcherError, `Unknown event scope source: ${state.sourceType}`);
                }
        }
        return output;
    }
    add(name, handler, options) {
        const runner = {
            fn: handler,
            flavour: options?.flavour,
            path: options?.path
        };
        this._handlersMap[name].unshift(runner);
        return ()=>{
            if (this._handlersMap[name].includes(runner)) {
                this._handlersMap[name] = this._handlersMap[name].filter((x)=>x !== runner);
            }
        };
    }
    buildEventScope(name, flavours, paths) {
        const handlers = this._handlersMap[name];
        if (!handlers) return;
        const globalEvents = handlers.filter((handler)=>handler.flavour === undefined && handler.path === undefined);
        const pathEvents = handlers.filter((handler)=>{
            const _path = handler.path;
            if (_path === undefined) return false;
            return paths.some((path)=>PathFinder.includes(path, _path));
        });
        const flavourEvents = handlers.filter((handler)=>handler.flavour && flavours.includes(handler.flavour));
        return {
            runners: pathEvents.concat(flavourEvents).concat(globalEvents),
            flavours,
            paths
        };
    }
    mount() {
        if (this.disposables.disposed) {
            this.disposables = new DisposableGroup();
        }
        this._bindEvents();
    }
    run(name, context, scope) {
        if (!this.active) return;
        const sourceState = context.get('sourceState');
        if (!scope) {
            scope = this._getEventScope(name, sourceState);
            if (!scope) {
                return;
            }
        }
        for (const runner of scope.runners){
            const { fn } = runner;
            const result = fn(context);
            if (result) {
                context.get('defaultState').event.stopPropagation();
                return;
            }
        }
    }
    unmount() {
        this.disposables.dispose();
    }
    get active() {
        return this._active;
    }
    get host() {
        return this.std.host;
    }
}
