import { jsx as _jsx } from "react/jsx-runtime";
import { MenuIcon, MenuItem } from '@affine/component';
import { CompatibleFavoriteItemsAdapter } from '@affine/core/modules/properties';
import { useI18n } from '@affine/i18n';
import { FavoritedIcon, FavoriteIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import { useMemo } from 'react';
export const FavoriteFolderOperation = ({ id })=>{
    const t = useI18n();
    const compatibleFavoriteItemsAdapter = useService(CompatibleFavoriteItemsAdapter);
    const favorite = useLiveData(useMemo(()=>{
        return compatibleFavoriteItemsAdapter.isFavorite$(id, 'folder');
    }, [
        compatibleFavoriteItemsAdapter,
        id
    ]));
    return _jsx(MenuItem, {
        preFix: _jsx(MenuIcon, {
            children: favorite ? _jsx(FavoritedIcon, {
                style: {
                    color: cssVar('primaryColor')
                }
            }) : _jsx(FavoriteIcon, {})
        }),
        onClick: ()=>compatibleFavoriteItemsAdapter.toggle(id, 'folder'),
        children: favorite ? t['com.affine.rootAppSidebar.organize.folder-rm-favorite']() : t['com.affine.rootAppSidebar.organize.folder-add-favorite']()
    });
};
