import { Text } from '@blocksuite/store';
import { clamp } from '../../../../../_common/utils/math.js';
import { tNumber } from '../../../logical/data-type.js';
import { columnType } from '../../column-config.js';
export const numberColumnType = columnType('number');
export const numberColumnModelConfig = numberColumnType.modelConfig({
    name: 'Number',
    type: ()=>tNumber.create(),
    defaultData: ()=>({
            decimal: 0,
            format: 'number'
        }),
    cellToString: (data)=>data?.toString() ?? '',
    cellFromString: (data)=>{
        const num = data ? Number(data) : NaN;
        return {
            value: isNaN(num) ? null : num
        };
    },
    cellToJson: (data)=>data ?? null,
    isEmpty: (data)=>data == null
});
numberColumnModelConfig.addConvert('rich-text', (_column, cells)=>({
        column: {},
        cells: cells.map((v)=>new Text(v?.toString()).yText)
    }));
numberColumnModelConfig.addConvert('progress', (_column, cells)=>({
        column: {},
        cells: cells.map((v)=>clamp(v ?? 0, 0, 100))
    }));
