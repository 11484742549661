import { getUserQuery, removeAvatarMutation, updateUserProfileMutation, uploadAvatarMutation } from '@affine/graphql';
import { Store } from '@toeverything/infra';
export class AuthStore extends Store {
    constructor(fetchService, gqlService, globalStateService){
        super();
        this.fetchService = fetchService;
        this.gqlService = gqlService;
        this.globalStateService = globalStateService;
    }
    watchCachedAuthSession() {
        return this.globalStateService.globalState.watch('affine-cloud-auth');
    }
    setCachedAuthSession(session) {
        this.globalStateService.globalState.set('affine-cloud-auth', session);
    }
    async fetchSession() {
        const url = `/api/auth/session`;
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const res = await this.fetchService.fetch(url, options);
        const data = await res.json();
        if (!res.ok) throw new Error('Get session fetch error: ' + JSON.stringify(data));
        return data;
    }
    async uploadAvatar(file) {
        await this.gqlService.gql({
            query: uploadAvatarMutation,
            variables: {
                avatar: file
            }
        });
    }
    async removeAvatar() {
        await this.gqlService.gql({
            query: removeAvatarMutation
        });
    }
    async updateLabel(label) {
        await this.gqlService.gql({
            query: updateUserProfileMutation,
            variables: {
                input: {
                    name: label
                }
            }
        });
    }
    async checkUserByEmail(email) {
        const data = await this.gqlService.gql({
            query: getUserQuery,
            variables: {
                email
            }
        });
        return {
            isExist: !!data.user,
            hasPassword: !!data.user?.hasPassword
        };
    }
}
