import { checkboxColumnConfig } from './checkbox/cell-renderer.js';
import { dateColumnConfig } from './date/cell-renderer.js';
import { imageColumnConfig } from './image/cell-renderer.js';
import { linkColumnConfig } from './link/cell-renderer.js';
import { multiSelectColumnConfig } from './multi-select/cell-renderer.js';
import { numberColumnConfig } from './number/cell-renderer.js';
import { progressColumnConfig } from './progress/cell-renderer.js';
import { selectColumnConfig } from './select/cell-renderer.js';
import { textColumnConfig } from './text/cell-renderer.js';
export const columnPresets = {
    checkboxColumnConfig,
    dateColumnConfig,
    imageColumnConfig,
    linkColumnConfig,
    multiSelectColumnConfig,
    numberColumnConfig,
    progressColumnConfig,
    selectColumnConfig,
    textColumnConfig
};
