import { WorkspaceFlavour } from '@affine/env/workspace';
import { assertEquals } from '@blocksuite/global/utils';
import { applyUpdate, encodeStateAsUpdate } from 'yjs';
import { Service } from '../../../framework';
import { transformWorkspaceDBLocalToCloud } from '../../db';
export class WorkspaceTransformService extends Service {
    constructor(factory, destroy){
        super();
        this.factory = factory;
        this.destroy = destroy;
        this.transformLocalToCloud = async (local, accountId)=>{
            assertEquals(local.flavour, WorkspaceFlavour.LOCAL);
            await local.engine.waitForDocSynced();
            const newMetadata = await this.factory.create(WorkspaceFlavour.AFFINE_CLOUD, async (docCollection, blobStorage, docStorage)=>{
                applyUpdate(docCollection.doc, encodeStateAsUpdate(local.docCollection.doc));
                for (const subdoc of local.docCollection.doc.getSubdocs()){
                    for (const newSubdoc of docCollection.doc.getSubdocs()){
                        if (newSubdoc.guid === subdoc.guid) {
                            applyUpdate(newSubdoc, encodeStateAsUpdate(subdoc));
                        }
                    }
                }
                await transformWorkspaceDBLocalToCloud(local.id, docCollection.id, local.engine.doc.storage.behavior, docStorage, accountId);
                const blobList = await local.engine.blob.list();
                for (const blobKey of blobList){
                    const blob = await local.engine.blob.get(blobKey);
                    if (blob) {
                        await blobStorage.set(blobKey, blob);
                    }
                }
            });
            await this.destroy.deleteWorkspace(local.meta);
            return newMetadata;
        };
    }
}
