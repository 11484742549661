function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _CaptionedBlockComponent, _dec1, _dec2, _init__isCollapsedWhenReadOnly, _init__richTextElement, _initProto;
import { getInlineRangeProvider } from '@blocksuite/block-std';
import { assertExists } from '@blocksuite/global/utils';
import { effect } from '@lit-labs/preact-signals';
import { html, nothing } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { CaptionedBlockComponent } from '../_common/components/captioned-block-component.js';
import { bindContainerHotkey } from '../_common/components/rich-text/keymap/index.js';
import '../_common/components/rich-text/rich-text.js';
import { BLOCK_CHILDREN_CONTAINER_PADDING_LEFT } from '../_common/consts.js';
import { NOTE_SELECTOR } from '../_common/edgeless/note/consts.js';
import { getViewportElement } from '../_common/utils/query.js';
import { EdgelessRootBlockComponent } from '../root-block/edgeless/edgeless-root-block.js';
import { correctNumberedListsOrderToPrev } from './commands/utils.js';
import { listBlockStyles } from './styles.js';
import { getListIcon } from './utils/get-list-icon.js';
import { playCheckAnimation, toggleDown, toggleRight } from './utils/icons.js';
let _ListBlockComponent;
_dec = customElement('affine-list'), _dec1 = state(), _dec2 = query('rich-text');
new class extends _identity {
    constructor(){
        super(_ListBlockComponent), _initClass();
    }
    static{
        class ListBlockComponent extends (_CaptionedBlockComponent = CaptionedBlockComponent) {
            static{
                ({ e: [_init__isCollapsedWhenReadOnly, _init__richTextElement, _initProto], c: [_ListBlockComponent, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_isCollapsedWhenReadOnly"
                    ],
                    [
                        _dec2,
                        1,
                        "_richTextElement"
                    ]
                ], [
                    _dec
                ], _CaptionedBlockComponent));
            }
            static{
                this.styles = listBlockStyles;
            }
            _select() {
                const selection = this.host.selection;
                selection.update((selList)=>{
                    return selList.filter((sel)=>!sel.is('text') && !sel.is('block')).concat(selection.create('block', {
                        blockId: this.blockId
                    }));
                });
            }
            _toggleChildren() {
                if (this.doc.readonly) {
                    this._isCollapsedWhenReadOnly = !this._isCollapsedWhenReadOnly;
                    return;
                }
                const newCollapsedState = !this.model.collapsed;
                this._isCollapsedWhenReadOnly = newCollapsedState;
                this.doc.captureSync();
                this.doc.updateBlock(this.model, {
                    collapsed: newCollapsedState
                });
            }
            _toggleTemplate(isCollapsed) {
                const noChildren = this.model.children.length === 0;
                if (noChildren) return nothing;
                const toggleDownTemplate = html`<div
      contenteditable="false"
      class="toggle-icon"
      @click=${this._toggleChildren}
    >
      ${toggleDown}
    </div>`;
                const toggleRightTemplate = html`<div
      contenteditable="false"
      class="toggle-icon toggle-icon__collapsed"
      @click=${this._toggleChildren}
    >
      ${toggleRight}
    </div>`;
                return isCollapsed ? toggleRightTemplate : toggleDownTemplate;
            }
            connectedCallback() {
                super.connectedCallback();
                bindContainerHotkey(this);
                this._inlineRangeProvider = getInlineRangeProvider(this);
                this.disposables.add(effect(()=>{
                    const type = this.model.type$.value;
                    const order = this.model.order$.value;
                    if (type === 'numbered' && !Number.isInteger(order)) {
                        correctNumberedListsOrderToPrev(this.doc, this.model, false);
                    }
                    if (type !== 'numbered' && order !== null) {
                        this.model.order = null;
                    }
                }));
            }
            async getUpdateComplete() {
                const result = await super.getUpdateComplete();
                await this._richTextElement?.updateComplete;
                return result;
            }
            renderBlock() {
                const { model, _onClickIcon } = this;
                const collapsed = this.doc.readonly ? this._isCollapsedWhenReadOnly : !!model.collapsed;
                const listIcon = getListIcon(model, !collapsed, _onClickIcon);
                const checked = this.model.type === 'todo' && this.model.checked ? 'affine-list--checked' : '';
                const children = html`<div
      class="affine-block-children-container ${collapsed ? 'affine-list__collapsed' : ''}"
      style="padding-left: ${BLOCK_CHILDREN_CONTAINER_PADDING_LEFT}px;"
    >
      ${this.renderChildren(this.model)}
    </div>`;
                return html`
      <div class=${'affine-list-block-container'}>
        <div class=${`affine-list-rich-text-wrapper ${checked}`}>
          ${this._toggleTemplate(collapsed)} ${listIcon}
          <rich-text
            .yText=${this.model.text.yText}
            .inlineEventSource=${this.topContenteditableElement ?? nothing}
            .undoManager=${this.doc.history}
            .attributeRenderer=${this.attributeRenderer}
            .attributesSchema=${this.attributesSchema}
            .markdownShortcutHandler=${this.markdownShortcutHandler}
            .embedChecker=${this.embedChecker}
            .readonly=${this.doc.readonly}
            .inlineRangeProvider=${this._inlineRangeProvider}
            .enableClipboard=${false}
            .enableUndoRedo=${false}
            .verticalScrollContainerGetter=${()=>getViewportElement(this.host)}
          ></rich-text>
        </div>

        ${children}
      </div>
    `;
            }
            get attributeRenderer() {
                return this.inlineManager.getRenderer();
            }
            get attributesSchema() {
                return this.inlineManager.getSchema();
            }
            get embedChecker() {
                return this.inlineManager.embedChecker;
            }
            get inlineManager() {
                const inlineManager = this.service?.inlineManager;
                assertExists(inlineManager);
                return inlineManager;
            }
            get markdownShortcutHandler() {
                return this.inlineManager.markdownShortcutHandler;
            }
            get topContenteditableElement() {
                if (this.rootComponent instanceof EdgelessRootBlockComponent) {
                    const el = this.closest(NOTE_SELECTOR);
                    return el;
                }
                return this.rootComponent;
            }
            #___private__isCollapsedWhenReadOnly_1;
            get _isCollapsedWhenReadOnly() {
                return this.#___private__isCollapsedWhenReadOnly_1;
            }
            set _isCollapsedWhenReadOnly(_v) {
                this.#___private__isCollapsedWhenReadOnly_1 = _v;
            }
            #___private__richTextElement_2;
            get _richTextElement() {
                return this.#___private__richTextElement_2;
            }
            set _richTextElement(_v) {
                this.#___private__richTextElement_2 = _v;
            }
            #___private_blockContainerStyles_3;
            get blockContainerStyles() {
                return this.#___private_blockContainerStyles_3;
            }
            set blockContainerStyles(_v) {
                this.#___private_blockContainerStyles_3 = _v;
            }
            constructor(...args){
                super(...args);
                this._inlineRangeProvider = null;
                this._onClickIcon = (e)=>{
                    e.stopPropagation();
                    if (this.model.type === 'toggle') {
                        this._toggleChildren();
                        return;
                    } else if (this.model.type === 'todo') {
                        if (this.doc.readonly) return;
                        this.doc.captureSync();
                        const checkedPropObj = {
                            checked: !this.model.checked
                        };
                        this.doc.updateBlock(this.model, checkedPropObj);
                        if (this.model.checked) {
                            const checkEl = this.querySelector('.affine-list-block__todo-prefix');
                            assertExists(checkEl);
                            playCheckAnimation(checkEl).catch(console.error);
                        }
                        return;
                    }
                    this._select();
                };
                this.#___private__isCollapsedWhenReadOnly_1 = (_initProto(this), _init__isCollapsedWhenReadOnly(this, false));
                this.#___private__richTextElement_2 = _init__richTextElement(this, null);
                this.#___private_blockContainerStyles_3 = {
                    margin: '10px 0'
                };
            }
        }
    }
}();
export { _ListBlockComponent as ListBlockComponent };
