function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _SignalWatcher, _dec1, _dec2, _dec3, _init_column, _init_grabStatus, _init_tableViewManager, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { assertExists } from '@blocksuite/global/utils';
import { SignalWatcher } from '@lit-labs/preact-signals';
import { css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { styleMap } from 'lit/directives/style-map.js';
import { html } from 'lit/static-html.js';
import { popMenu } from '../../../../../../../_common/components/index.js';
import { numberFormats } from '../../../../../column/presets/number/utils/formats.js';
import { inputConfig, typeConfig } from '../../../../../common/column-menu.js';
import { DatabaseDuplicate, DatabaseInsertLeft, DatabaseInsertRight, DatabaseMoveLeft, DatabaseMoveRight, DeleteIcon } from '../../../../../common/icons/index.js';
import { startDrag } from '../../../../../utils/drag.js';
import { autoScrollOnBoundary } from '../../../../../utils/frame-loop.js';
import { insertPositionToIndex } from '../../../../../utils/insert.js';
import { renderUniLit } from '../../../../../utils/uni-component/index.js';
import { getResultInRange } from '../../../../../utils/utils.js';
import { DEFAULT_COLUMN_TITLE_HEIGHT } from '../../consts.js';
import { getTableContainer } from '../../types.js';
import { DataViewColumnPreview } from './column-renderer.js';
import './number-format-bar.js';
import { getTableGroupRects, getVerticalIndicator, startDragWidthAdjustmentBar } from './vertical-indicator.js';
let _DatabaseHeaderColumn;
_dec = customElement('affine-database-header-column'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_DatabaseHeaderColumn), _initClass();
    }
    static{
        class DatabaseHeaderColumn extends (_SignalWatcher = SignalWatcher(WithDisposable(ShadowlessElement))) {
            static{
                ({ e: [_init_column, _init_grabStatus, _init_tableViewManager, _initProto], c: [_DatabaseHeaderColumn, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "column"
                    ],
                    [
                        _dec2,
                        1,
                        "grabStatus"
                    ],
                    [
                        _dec3,
                        1,
                        "tableViewManager"
                    ]
                ], [
                    _dec
                ], _SignalWatcher));
            }
            static{
                this.styles = css`
    affine-database-header-column {
      display: flex;
    }

    .affine-database-header-column-grabbing * {
      cursor: grabbing;
    }
  `;
            }
            popMenu(ele) {
                const enableNumberFormatting = this.tableViewManager.featureFlags$.value.enable_number_formatting;
                popMenu(ele ?? this, {
                    options: {
                        input: inputConfig(this.column),
                        items: [
                            {
                                type: 'group',
                                name: 'Column Prop Group ',
                                children: ()=>[
                                        typeConfig(this.column),
                                        ...enableNumberFormatting ? [
                                            {
                                                type: 'sub-menu',
                                                name: 'Number Format',
                                                hide: ()=>!this.column.updateData || this.column.type !== 'number',
                                                options: {
                                                    input: {
                                                        search: true
                                                    },
                                                    items: [
                                                        numberFormatConfig(this.column),
                                                        ...numberFormats.map((format)=>{
                                                            const data = this.column.data$.value;
                                                            return {
                                                                type: 'action',
                                                                isSelected: data.format === format.type,
                                                                icon: html`<span
                                style="font-size: var(--affine-font-base); scale: 1.2;"
                                >${format.symbol}</span
                              >`,
                                                                name: format.label,
                                                                select: ()=>{
                                                                    if (data.format === format.type) return;
                                                                    this.column.updateData(()=>({
                                                                            format: format.type
                                                                        }));
                                                                }
                                                            };
                                                        })
                                                    ]
                                                }
                                            }
                                        ] : []
                                    ]
                            },
                            {
                                type: 'action',
                                name: 'Duplicate Column',
                                icon: DatabaseDuplicate,
                                hide: ()=>!this.column.duplicate || this.column.type === 'title',
                                select: ()=>{
                                    this.column.duplicate?.();
                                }
                            },
                            {
                                type: 'action',
                                name: 'Insert Left Column',
                                icon: DatabaseInsertLeft,
                                select: ()=>{
                                    this.tableViewManager.columnAdd({
                                        id: this.column.id,
                                        before: true
                                    });
                                    Promise.resolve().then(()=>{
                                        const pre = this.previousElementSibling;
                                        if (pre instanceof DatabaseHeaderColumn) {
                                            pre.editTitle();
                                            pre.scrollIntoView({
                                                inline: 'nearest',
                                                block: 'nearest'
                                            });
                                        }
                                    }).catch(console.error);
                                }
                            },
                            {
                                type: 'action',
                                name: 'Insert Right Column',
                                icon: DatabaseInsertRight,
                                select: ()=>{
                                    this.tableViewManager.columnAdd({
                                        id: this.column.id,
                                        before: false
                                    });
                                    Promise.resolve().then(()=>{
                                        const next = this.nextElementSibling;
                                        if (next instanceof DatabaseHeaderColumn) {
                                            next.editTitle();
                                            next.scrollIntoView({
                                                inline: 'nearest',
                                                block: 'nearest'
                                            });
                                        }
                                    }).catch(console.error);
                                }
                            },
                            {
                                type: 'action',
                                name: 'Move Left',
                                icon: DatabaseMoveLeft,
                                hide: ()=>this.column.isFirst,
                                select: ()=>{
                                    const preId = this.tableViewManager.columnGetPreColumn(this.column.id)?.id;
                                    if (!preId) {
                                        return;
                                    }
                                    this.tableViewManager.columnMove(this.column.id, {
                                        id: preId,
                                        before: true
                                    });
                                }
                            },
                            {
                                type: 'action',
                                name: 'Move Right',
                                icon: DatabaseMoveRight,
                                hide: ()=>this.column.isLast,
                                select: ()=>{
                                    const nextId = this.tableViewManager.columnGetNextColumn(this.column.id)?.id;
                                    if (!nextId) {
                                        return;
                                    }
                                    this.tableViewManager.columnMove(this.column.id, {
                                        id: nextId,
                                        before: false
                                    });
                                }
                            },
                            {
                                type: 'group',
                                name: 'operation',
                                children: ()=>[
                                        {
                                            type: 'action',
                                            name: 'Delete Column',
                                            icon: DeleteIcon,
                                            hide: ()=>!this.column.delete || this.column.type === 'title',
                                            select: ()=>{
                                                this.column.delete?.();
                                            },
                                            class: 'delete-item'
                                        }
                                    ]
                            }
                        ]
                    }
                });
            }
            get readonly() {
                return this.tableViewManager.readonly$.value;
            }
            widthDragStart(event) {
                startDragWidthAdjustmentBar(event, getTableContainer(this), this.getBoundingClientRect().width, this.column);
            }
            connectedCallback() {
                super.connectedCallback();
                const table = this.closest('affine-database-table');
                if (table) {
                    this.disposables.add(table.handleEvent('dragStart', (context)=>{
                        if (this.tableViewManager.readonly$.value) {
                            return;
                        }
                        const event = context.get('pointerState').raw;
                        const target = event.target;
                        if (target instanceof Element) {
                            if (this.widthDragBar.value?.contains(target)) {
                                event.preventDefault();
                                this.widthDragStart(event);
                                return true;
                            }
                            if (this.contains(target)) {
                                event.preventDefault();
                                this.moveColumn(event);
                                return true;
                            }
                        }
                        return false;
                    }));
                }
            }
            render() {
                const column = this.column;
                const style = styleMap({
                    height: DEFAULT_COLUMN_TITLE_HEIGHT + 'px'
                });
                const classes = classMap({
                    'affine-database-column-move': true,
                    [this.grabStatus]: true
                });
                return html`
      <div
        style=${style}
        class="affine-database-column-content"
        @click="${this._clickColumn}"
        @contextmenu="${this._contextMenu}"
      >
        ${this.readonly ? null : html` <button class="${classes}">
              <div class="hover-trigger"></div>
              <div class="control-h"></div>
              <div class="control-l"></div>
              <div class="control-r"></div>
            </button>`}
        <div class="affine-database-column-text ${column.type}">
          <div
            class="affine-database-column-type-icon dv-hover"
            @click="${this._clickTypeIcon}"
          >
            <uni-lit .uni="${column.icon}"></uni-lit>
          </div>
          <div class="affine-database-column-text-content">
            <div class="affine-database-column-text-input">${column.name}</div>
          </div>
        </div>
      </div>
      <div
        ${ref(this.widthDragBar)}
        @mouseenter="${this._enterWidthDragBar}"
        @mouseleave="${this._leaveWidthDragBar}"
        style="width: 0;position: relative;height: 100%;z-index: 1;cursor: col-resize"
      >
        <div style="width: 8px;height: 100%;margin-left: -4px;"></div>
      </div>
    `;
            }
            #___private_column_1;
            get column() {
                return this.#___private_column_1;
            }
            set column(_v) {
                this.#___private_column_1 = _v;
            }
            #___private_grabStatus_2;
            get grabStatus() {
                return this.#___private_grabStatus_2;
            }
            set grabStatus(_v) {
                this.#___private_grabStatus_2 = _v;
            }
            #___private_tableViewManager_3;
            get tableViewManager() {
                return this.#___private_tableViewManager_3;
            }
            set tableViewManager(_v) {
                this.#___private_tableViewManager_3 = _v;
            }
            constructor(...args){
                super(...args);
                this._clickColumn = ()=>{
                    if (this.tableViewManager.readonly$.value) {
                        return;
                    }
                    this.popMenu();
                };
                this._clickTypeIcon = (event)=>{
                    if (this.tableViewManager.readonly$.value) {
                        return;
                    }
                    if (this.column.type === 'title') {
                        return;
                    }
                    event.stopPropagation();
                    popMenu(this, {
                        options: {
                            input: {
                                search: true,
                                placeholder: 'Search'
                            },
                            items: this.tableViewManager.allColumnConfig.map((config)=>{
                                return {
                                    type: 'action',
                                    name: config.name,
                                    isSelected: config.type === this.column.type,
                                    icon: renderUniLit(this.tableViewManager.getIcon(config.type)),
                                    select: ()=>{
                                        this.column.updateType?.(config.type);
                                    }
                                };
                            })
                        }
                    });
                };
                this._columnsOffset = (header, _scale)=>{
                    const columns = header.querySelectorAll('affine-database-header-column');
                    const left = [];
                    const right = [];
                    let curr = left;
                    const offsetArr = [];
                    const columnsArr = Array.from(columns);
                    for(let i = 0; i < columnsArr.length; i++){
                        const v = columnsArr[i];
                        if (v === this) {
                            curr = right;
                            offsetArr.push(-1);
                            continue;
                        }
                        curr.push({
                            x: v.offsetLeft + v.offsetWidth / 2,
                            ele: v
                        });
                        offsetArr.push(v.getBoundingClientRect().left - header.getBoundingClientRect().left);
                        if (i === columnsArr.length - 1) {
                            offsetArr.push(v.getBoundingClientRect().right - header.getBoundingClientRect().left);
                        }
                    }
                    left.reverse();
                    const getInsertPosition = (offset, width)=>{
                        let result = undefined;
                        for(let i = 0; i < left.length; i++){
                            const { x, ele } = left[i];
                            if (x < offset) {
                                if (result) {
                                    return result;
                                }
                                break;
                            } else {
                                result = {
                                    before: true,
                                    id: ele.column.id
                                };
                            }
                        }
                        const offsetRight = offset + width;
                        for (const { x, ele } of right){
                            if (x > offsetRight) {
                                if (result) {
                                    return result;
                                }
                                break;
                            } else {
                                result = {
                                    before: false,
                                    id: ele.column.id
                                };
                            }
                        }
                        return result;
                    };
                    const fixedColumns = columnsArr.map((v)=>({
                            id: v.column.id
                        }));
                    const getInsertOffset = (insertPosition)=>{
                        return offsetArr[insertPositionToIndex(insertPosition, fixedColumns)];
                    };
                    return {
                        computeInsertInfo: (offset, width)=>{
                            const insertPosition = getInsertPosition(offset, width);
                            return {
                                insertPosition: insertPosition,
                                insertOffset: insertPosition ? getInsertOffset(insertPosition) : undefined
                            };
                        }
                    };
                };
                this._contextMenu = (e)=>{
                    if (this.tableViewManager.readonly$.value) {
                        return;
                    }
                    e.preventDefault();
                    this.popMenu(e.target);
                };
                this._enterWidthDragBar = ()=>{
                    if (this.tableViewManager.readonly$.value) {
                        return;
                    }
                    if (this.drawWidthDragBarTask) {
                        cancelAnimationFrame(this.drawWidthDragBarTask);
                        this.drawWidthDragBarTask = 0;
                    }
                    this.drawWidthDragBar();
                };
                this._leaveWidthDragBar = ()=>{
                    cancelAnimationFrame(this.drawWidthDragBarTask);
                    this.drawWidthDragBarTask = 0;
                    getVerticalIndicator().remove();
                };
                this.drawWidthDragBar = ()=>{
                    const tableContainer = getTableContainer(this);
                    const tableRect = tableContainer.getBoundingClientRect();
                    const rectList = getTableGroupRects(tableContainer);
                    getVerticalIndicator().display(0, tableRect.top, rectList, this.getBoundingClientRect().right);
                    this.drawWidthDragBarTask = requestAnimationFrame(this.drawWidthDragBar);
                };
                this.drawWidthDragBarTask = 0;
                this.moveColumn = (evt)=>{
                    const tableContainer = getTableContainer(this);
                    const headerContainer = this.closest('affine-database-column-header');
                    const scrollContainer = tableContainer?.parentElement;
                    assertExists(headerContainer);
                    assertExists(tableContainer);
                    assertExists(scrollContainer);
                    const columnHeaderRect = this.getBoundingClientRect();
                    const scale = columnHeaderRect.width / this.column.width$.value;
                    const headerContainerRect = tableContainer.getBoundingClientRect();
                    const rectOffsetLeft = evt.x - columnHeaderRect.left;
                    const offsetRight = columnHeaderRect.right - evt.x;
                    const startOffset = (columnHeaderRect.left - headerContainerRect.left) / scale;
                    const max = (headerContainerRect.width - columnHeaderRect.width) / scale;
                    const { computeInsertInfo } = this._columnsOffset(headerContainer, scale);
                    const column = new DataViewColumnPreview();
                    column.tableViewManager = this.tableViewManager;
                    column.column = this.column;
                    column.table = tableContainer;
                    const dragPreview = createDragPreview(tableContainer, columnHeaderRect.width / scale, headerContainerRect.height / scale, startOffset, column);
                    const rectList = getTableGroupRects(tableContainer);
                    const dropPreview = getVerticalIndicator();
                    const cancelScroll = autoScrollOnBoundary(scrollContainer, {
                        boundary: {
                            left: rectOffsetLeft,
                            right: offsetRight
                        },
                        onScroll: ()=>{
                            drag.move({
                                x: drag.last.x
                            });
                        }
                    });
                    const html = document.querySelector('html');
                    html?.classList.toggle('affine-database-header-column-grabbing', true);
                    const drag = startDrag(evt, {
                        onDrag: ()=>{
                            this.grabStatus = 'grabbing';
                            return {};
                        },
                        onMove: ({ x })=>{
                            this.grabStatus = 'grabbing';
                            const currentOffset = getResultInRange((x - tableContainer.getBoundingClientRect().left - rectOffsetLeft) / scale, 0, max);
                            const insertInfo = computeInsertInfo(currentOffset, columnHeaderRect.width / scale);
                            if (insertInfo.insertOffset != null) {
                                dropPreview.display(0, headerContainerRect.top, rectList, tableContainer.getBoundingClientRect().left + insertInfo.insertOffset, true);
                            } else {
                                dropPreview.remove();
                            }
                            dragPreview.display(currentOffset);
                            return {
                                insertPosition: insertInfo.insertPosition
                            };
                        },
                        onDrop: ({ insertPosition })=>{
                            this.grabStatus = 'grabEnd';
                            if (insertPosition) {
                                this.tableViewManager.columnMove(this.column.id, insertPosition);
                            }
                        },
                        onClear: ()=>{
                            cancelScroll();
                            html?.classList.toggle('affine-database-header-column-grabbing', false);
                            dropPreview.remove();
                            dragPreview.remove();
                        }
                    });
                };
                this.widthDragBar = createRef();
                this.editTitle = ()=>{
                    this._clickColumn();
                };
                this.#___private_column_1 = (_initProto(this), _init_column(this));
                this.#___private_grabStatus_2 = _init_grabStatus(this, 'grabEnd');
                this.#___private_tableViewManager_3 = _init_tableViewManager(this);
            }
        }
    }
}();
const createDragPreview = (container, width, height, startLeft, content)=>{
    const div = document.createElement('div');
    div.append(content);
    div.style.opacity = '0.8';
    div.style.position = 'absolute';
    div.style.width = `${width}px`;
    div.style.height = `${height}px`;
    div.style.left = `${startLeft}px`;
    div.style.top = `0px`;
    div.style.zIndex = '9';
    container.append(div);
    return {
        display (offset) {
            div.style.left = `${Math.round(offset)}px`;
        },
        remove () {
            div.remove();
        }
    };
};
function numberFormatConfig(column) {
    return {
        type: 'custom',
        render: ()=>html` <affine-database-number-format-bar
        .column="${column}"
      ></affine-database-number-format-bar>`
    };
}
export { _DatabaseHeaderColumn as DatabaseHeaderColumn };
