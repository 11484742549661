export const DARK_THEME = 'dark-plus';
export const LIGHT_THEME = 'light-plus';
export const FALLBACK_LANG = 'Plain Text';
export const PLAIN_TEXT_LANG_INFO = {
    id: FALLBACK_LANG,
    name: FALLBACK_LANG,
    aliases: [
        'plaintext',
        'txt',
        'text'
    ],
    import: ()=>Promise.resolve({
            default: []
        })
};
