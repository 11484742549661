import { Entity } from '../../../framework';
export class Table extends Entity {
    constructor(workspaceService){
        super();
        this.workspaceService = workspaceService;
        this.table = this.props.table;
        this.isSyncing$ = this.workspaceService.workspace.engine.doc.docState$(this.props.storageDocId).map((docState)=>docState.syncing);
        this.isLoading$ = this.workspaceService.workspace.engine.doc.docState$(this.props.storageDocId).map((docState)=>docState.loading);
        this.create = this.table.create.bind(this.table);
        this.update = this.table.update.bind(this.table);
        this.get = this.table.get.bind(this.table);
        this.get$ = this.table.get$.bind(this.table);
        this.find = this.table.find.bind(this.table);
        this.find$ = this.table.find$.bind(this.table);
        this.keys = this.table.keys.bind(this.table);
        this.delete = this.table.delete.bind(this.table);
    }
}
