import { jsx as _jsx } from "react/jsx-runtime";
import { OutlineViewer } from '@blocksuite/presets';
import { useCallback, useRef } from 'react';
import * as styles from './outline-viewer.css';
export const EditorOutlineViewer = ({ editor, show, openOutlinePanel })=>{
    const outlineViewerRef = useRef(null);
    const onRefChange = useCallback((container)=>{
        if (container) {
            if (outlineViewerRef.current === null) {
                console.error('outline viewer should be initialized');
                return;
            }
            container.append(outlineViewerRef.current);
        }
    }, []);
    if (!editor || !show) return;
    if (!outlineViewerRef.current) {
        outlineViewerRef.current = new OutlineViewer();
    }
    if (outlineViewerRef.current.editor !== editor) {
        outlineViewerRef.current.editor = editor;
    }
    if (outlineViewerRef.current.toggleOutlinePanel !== openOutlinePanel) {
        outlineViewerRef.current.toggleOutlinePanel = openOutlinePanel;
    }
    return _jsx("div", {
        className: styles.root,
        ref: onRefChange
    });
};
