import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef, useCallback, useEffect } from 'react';
import { useAutoFocus, useAutoSelect } from '../../hooks';
import { input, inputWrapper } from './style.css';
export const Input = forwardRef(function Input({ disabled, onChange: propsOnChange, noBorder = false, className, status = 'default', style = {}, inputStyle = {}, size = 'default', preFix, endFix, onEnter, onKeyDown, onBlur, autoFocus, autoSelect, ...otherProps }, upstreamRef) {
    const focusRef = useAutoFocus(autoFocus);
    const selectRef = useAutoSelect(autoSelect);
    const inputRef = (el)=>{
        focusRef.current = el;
        selectRef.current = el;
        if (upstreamRef) {
            if (typeof upstreamRef === 'function') {
                upstreamRef(el);
            } else {
                upstreamRef.current = el;
            }
        }
    };
    useEffect(()=>{
        if (!onBlur) return;
        selectRef.current?.addEventListener('blur', onBlur);
        return ()=>{
            selectRef.current?.removeEventListener('blur', onBlur);
        };
    }, [
        onBlur,
        selectRef
    ]);
    return _jsxs("div", {
        className: clsx(inputWrapper, className, {
            disabled: disabled,
            'no-border': noBorder,
            error: status === 'error',
            success: status === 'success',
            warning: status === 'warning',
            default: status === 'default',
            large: size === 'large',
            'extra-large': size === 'extraLarge'
        }),
        style: {
            ...style
        },
        children: [
            preFix,
            _jsx("input", {
                className: clsx(input, {
                    large: size === 'large',
                    'extra-large': size === 'extraLarge'
                }),
                ref: inputRef,
                disabled: disabled,
                style: inputStyle,
                onChange: useCallback((e)=>{
                    propsOnChange?.(e.target.value);
                }, [
                    propsOnChange
                ]),
                onKeyDown: useCallback((e)=>{
                    if (e.key === 'Enter') {
                        onEnter?.();
                    }
                    onKeyDown?.(e);
                }, [
                    onKeyDown,
                    onEnter
                ]),
                ...otherProps
            }),
            endFix
        ]
    });
});
