import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDocMetaHelper } from '@affine/core/hooks/use-block-suite-page-meta';
import { useJournalHelper } from '@affine/core/hooks/use-journal';
import { PeekViewService, useInsidePeekView } from '@affine/core/modules/peek-view';
import { WorkbenchLink } from '@affine/core/modules/workbench';
import { useI18n } from '@affine/i18n';
import { DeleteIcon, LinkedEdgelessIcon, LinkedPageIcon, TodayIcon } from '@blocksuite/icons/rc';
import { DocsService, LiveData, useLiveData, useService } from '@toeverything/infra';
import { useCallback, useRef } from 'react';
import * as styles from './styles.css';
export function pageReferenceRenderer({ docMode, pageId, pageMetaHelper, journalHelper, t }) {
    const { isPageJournal, getLocalizedJournalDateString } = journalHelper;
    const referencedPage = pageMetaHelper.getDocMeta(pageId);
    let title = referencedPage?.title ?? t['com.affine.editor.reference-not-found']();
    let icon = !referencedPage ? _jsx(DeleteIcon, {
        className: styles.pageReferenceIcon
    }) : docMode === 'page' || docMode === null ? _jsx(LinkedPageIcon, {
        className: styles.pageReferenceIcon
    }) : _jsx(LinkedEdgelessIcon, {
        className: styles.pageReferenceIcon
    });
    const isJournal = isPageJournal(pageId);
    const localizedJournalDate = getLocalizedJournalDateString(pageId);
    if (isJournal && localizedJournalDate) {
        title = localizedJournalDate;
        icon = _jsx(TodayIcon, {
            className: styles.pageReferenceIcon
        });
    }
    return _jsxs(_Fragment, {
        children: [
            icon,
            _jsx("span", {
                className: "affine-reference-title",
                children: title ? title : t['Untitled']()
            })
        ]
    });
}
export function AffinePageReference({ pageId, docCollection, wrapper: Wrapper }) {
    const pageMetaHelper = useDocMetaHelper(docCollection);
    const journalHelper = useJournalHelper(docCollection);
    const t = useI18n();
    const docsService = useService(DocsService);
    const mode$ = LiveData.from(docsService.list.observeMode(pageId), undefined);
    const docMode = useLiveData(mode$) ?? null;
    const el = pageReferenceRenderer({
        docMode,
        pageId,
        pageMetaHelper,
        journalHelper,
        docCollection,
        t
    });
    const ref = useRef(null);
    const peekView = useService(PeekViewService).peekView;
    const isInPeekView = useInsidePeekView();
    const onClick = useCallback((e)=>{
        if (e.shiftKey && ref.current) {
            e.preventDefault();
            e.stopPropagation();
            peekView.open(ref.current).catch(console.error);
        }
        if (isInPeekView) {
            peekView.close();
        }
        return;
    }, [
        isInPeekView,
        peekView
    ]);
    return _jsx(WorkbenchLink, {
        ref: ref,
        to: `/${pageId}`,
        onClick: onClick,
        className: styles.pageReferenceLink,
        children: Wrapper ? _jsx(Wrapper, {
            children: el
        }) : el
    });
}
