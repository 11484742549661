import { isEqual } from 'lodash-es';
import { distinctUntilChanged, Observable } from 'rxjs';
import { Store } from '../../../framework';
export class DocsStore extends Store {
    constructor(workspaceService, localState){
        super();
        this.workspaceService = workspaceService;
        this.localState = localState;
    }
    getBlockSuiteDoc(id) {
        return this.workspaceService.workspace.docCollection.getDoc(id);
    }
    createBlockSuiteDoc() {
        return this.workspaceService.workspace.docCollection.createDoc();
    }
    watchDocIds() {
        return new Observable((subscriber)=>{
            const emit = ()=>{
                subscriber.next(this.workspaceService.workspace.docCollection.meta.docMetas.map((v)=>v.id));
            };
            emit();
            const dispose = this.workspaceService.workspace.docCollection.meta.docMetaUpdated.on(emit).dispose;
            return ()=>{
                dispose();
            };
        });
    }
    watchTrashDocIds() {
        return new Observable((subscriber)=>{
            const emit = ()=>{
                subscriber.next(this.workspaceService.workspace.docCollection.meta.docMetas.map((v)=>v.trash ? v.id : null).filter(Boolean));
            };
            emit();
            const dispose = this.workspaceService.workspace.docCollection.meta.docMetaUpdated.on(emit).dispose;
            return ()=>{
                dispose();
            };
        });
    }
    watchDocMeta(id) {
        let meta = null;
        return new Observable((subscriber)=>{
            const emit = ()=>{
                if (meta === null) {
                    meta = this.workspaceService.workspace.docCollection.meta.getDocMeta(id) || null;
                }
                subscriber.next({
                    ...meta
                });
            };
            emit();
            const dispose = this.workspaceService.workspace.docCollection.meta.docMetaUpdated.on(emit).dispose;
            return ()=>{
                dispose();
            };
        }).pipe(distinctUntilChanged((p, c)=>isEqual(p, c)));
    }
    watchDocListReady() {
        return this.workspaceService.workspace.engine.rootDocState$.map((state)=>!state.syncing).asObservable();
    }
    setDocMeta(id, meta) {
        this.workspaceService.workspace.docCollection.setDocMeta(id, meta);
    }
    setDocModeSetting(id, mode) {
        return this.localState.set(`page:${id}:mode`, mode);
    }
    getDocModeSetting(id) {
        return this.localState.get(`page:${id}:mode`);
    }
    watchDocModeSetting(id) {
        return this.localState.watch(`page:${id}:mode`);
    }
    waitForDocLoadReady(id) {
        return this.workspaceService.workspace.engine.doc.waitForReady(id);
    }
    setPriorityLoad(id, priority) {
        return this.workspaceService.workspace.engine.doc.setPriority(id, priority);
    }
    markDocSyncStateAsReady(id) {
        this.workspaceService.workspace.engine.doc.markAsReady(id);
    }
}
