function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _init_position, _init_rootComponent, _init_schema, _init_widgetComponent, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { Vec } from '@blocksuite/global/utils';
import { Slot, assertEquals, assertExists } from '@blocksuite/global/utils';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { toDegree, toRadian } from '../../../surface-block/index.js';
import { PieNode } from './node.js';
import { PieManager } from './pie-manager.js';
import { pieMenuStyles } from './styles.js';
import { getPosition, isColorNode, isCommandNode, isNodeWithAction, isNodeWithChildren, isRootNode, isSubmenuNode } from './utils.js';
let _PieMenu;
_dec = customElement('affine-pie-menu'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_PieMenu), _initClass();
    }
    static{
        class PieMenu extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init_position, _init_rootComponent, _init_schema, _init_widgetComponent, _initProto], c: [_PieMenu, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "position"
                    ],
                    [
                        _dec2,
                        1,
                        "rootComponent"
                    ],
                    [
                        _dec3,
                        1,
                        "schema"
                    ],
                    [
                        _dec4,
                        1,
                        "widgetComponent"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = pieMenuStyles;
            }
            _createNodeTree(nodeSchema) {
                const node = new PieNode();
                const { angle, startAngle, endAngle, label } = nodeSchema;
                node.id = label;
                node.model = nodeSchema;
                node.angle = angle ?? 0;
                node.startAngle = startAngle ?? 0;
                node.endAngle = endAngle ?? 0;
                node.menu = this;
                if (!isRootNode(nodeSchema)) {
                    node.slot = 'children-slot';
                    const { PIE_RADIUS } = PieManager.settings;
                    const isColorNode = nodeSchema.type === 'color';
                    const radius = isColorNode ? PIE_RADIUS * 0.6 : PIE_RADIUS;
                    node.position = getPosition(toRadian(node.angle), [
                        radius,
                        radius
                    ]);
                } else {
                    node.position = [
                        0,
                        0
                    ];
                }
                if (isNodeWithChildren(nodeSchema)) {
                    nodeSchema.children.forEach((childSchema, i)=>{
                        const childNode = this._createNodeTree(childSchema);
                        childNode.containerNode = node;
                        childNode.index = i + 1;
                        childNode.setAttribute('index', childNode.index.toString());
                        node.append(childNode);
                    });
                }
                return node;
            }
            _setupEvents() {
                this._disposables.addFromEvent(this.widgetComponent, 'pointermove', this._handlePointerMove);
                this._disposables.addFromEvent(document, 'keydown', this._handleKeyDown);
            }
            close() {
                this.abortController.abort();
            }
            connectedCallback() {
                super.connectedCallback();
                this._setupEvents();
                const root = this._createNodeTree(this.schema.root);
                this.selectionChain.push(root);
            }
            getActiveNodeRelPos() {
                const position = [
                    ...this.position
                ];
                for (const node of this.selectionChain){
                    position[0] += node.position[0];
                    position[1] += node.position[1];
                }
                return position;
            }
            getActiveNodeToMouseLenSq(mouse) {
                const [x, y] = mouse;
                const [nodeX, nodeY] = this.getActiveNodeRelPos();
                const dx = x - nodeX;
                const dy = y - nodeY;
                return Vec.len2([
                    dx,
                    dy
                ]);
            }
            getNodeRelPos(node) {
                const position = [
                    ...this.position
                ];
                let cur = node;
                while(cur !== null){
                    position[0] += cur.position[0];
                    position[1] += cur.position[1];
                    cur = cur.containerNode;
                }
                return position;
            }
            isActiveNode(node) {
                return this.activeNode === node;
            }
            isChildOfActiveNode(node) {
                return node.containerNode === this.activeNode;
            }
            openSubmenu(submenu) {
                assertEquals(submenu.model.type, 'submenu', 'Need node of type submenu');
                if (isNodeWithAction(submenu.model)) submenu.select();
                this.selectionChain.push(submenu);
                this.setHovered(null);
                this.slots.requestNodeUpdate.emit();
            }
            popSelectionChainTo(node) {
                assertEquals(isNodeWithChildren(node.model), true, 'Required a root node or a submenu node');
                while(this.selectionChain.length > 1 && this.activeNode !== node){
                    this.selectionChain.pop();
                }
                this.requestUpdate();
                this.slots.requestNodeUpdate.emit();
            }
            render() {
                const [x, y] = this.position;
                const menuStyles = {
                    transform: `translate(${x}px, ${y}px) translate(-50%, -50%)`
                };
                return html` <div class="pie-menu-container">
      <div class="overlay" @click="${()=>this.abortController.abort()}"></div>

      <div style="${styleMap(menuStyles)}" class="pie-menu">
        ${this.rootNode ?? nothing}
      </div>
    </div>`;
            }
            selectHovered() {
                const { hoveredNode } = this;
                if (hoveredNode) {
                    hoveredNode.select();
                }
            }
            setHovered(node) {
                clearTimeout(this._openSubmenuTimeout);
                this._hoveredNode = node;
                if (!node) return;
                if (isSubmenuNode(node.model)) {
                    const { openOnHover, timeoutOverride } = node.model;
                    const { SUBMENU_OPEN_TIMEOUT } = PieManager.settings;
                    if (openOnHover !== undefined && !openOnHover) return;
                    this._openSubmenuTimeout = setTimeout(()=>{
                        this.openSubmenu(node);
                    }, timeoutOverride ?? SUBMENU_OPEN_TIMEOUT);
                }
            }
            get activeNode() {
                const node = this.selectionChain[this.selectionChain.length - 1];
                assertExists(node, 'Required atLeast 1 node active');
                return node;
            }
            get hoveredNode() {
                return this._hoveredNode;
            }
            get rootNode() {
                const node = this.selectionChain[0];
                assertExists(node, 'No root node');
                return node;
            }
            #___private_position_1;
            get position() {
                return this.#___private_position_1;
            }
            set position(_v) {
                this.#___private_position_1 = _v;
            }
            #___private_rootComponent_2;
            get rootComponent() {
                return this.#___private_rootComponent_2;
            }
            set rootComponent(_v) {
                this.#___private_rootComponent_2 = _v;
            }
            #___private_schema_3;
            get schema() {
                return this.#___private_schema_3;
            }
            set schema(_v) {
                this.#___private_schema_3 = _v;
            }
            #___private_widgetComponent_4;
            get widgetComponent() {
                return this.#___private_widgetComponent_4;
            }
            set widgetComponent(_v) {
                this.#___private_widgetComponent_4 = _v;
            }
            constructor(...args){
                super(...args);
                this._handleKeyDown = (ev)=>{
                    const { key } = ev;
                    if (key === 'Escape') {
                        return this.abortController.abort();
                    }
                    if (ev.code === 'Backspace') {
                        if (this.selectionChain.length <= 1) return;
                        const { containerNode } = this.activeNode;
                        if (containerNode) this.popSelectionChainTo(containerNode);
                    }
                    if (key.match(/\d+/)) {
                        this.selectChildWithIndex(parseInt(key));
                    }
                };
                this._handlePointerMove = (ev)=>{
                    const { clientX, clientY } = ev;
                    const { ACTIVATE_THRESHOLD_MIN } = PieManager.settings;
                    const lenSq = this.getActiveNodeToMouseLenSq([
                        clientX,
                        clientY
                    ]);
                    if (lenSq > ACTIVATE_THRESHOLD_MIN ** 2) {
                        const [nodeX, nodeY] = this.getActiveNodeRelPos();
                        const dx = clientX - nodeX;
                        const dy = clientY - nodeY;
                        const TAU = Math.PI * 2;
                        const angle = toDegree((Math.atan2(dy, dx) + TAU) % TAU);
                        this.slots.pointerAngleUpdated.emit(angle);
                    } else {
                        this.slots.pointerAngleUpdated.emit(null);
                    }
                };
                this._hoveredNode = null;
                this.selectChildWithIndex = (index)=>{
                    const activeNode = this.activeNode;
                    if (!activeNode || isNaN(index)) return;
                    const node = activeNode.querySelector(`& > affine-pie-node[index='${index}']`);
                    if (node instanceof PieNode && !isColorNode(node.model)) {
                        if (isSubmenuNode(node.model)) this.openSubmenu(node);
                        else node.select();
                        if (isCommandNode(node.model)) this.close();
                    }
                };
                this.abortController = new AbortController();
                this.selectionChain = [];
                this.slots = {
                    pointerAngleUpdated: new Slot(),
                    requestNodeUpdate: new Slot()
                };
                this.#___private_position_1 = (_initProto(this), _init_position(this));
                this.#___private_rootComponent_2 = _init_rootComponent(this);
                this.#___private_schema_3 = _init_schema(this);
                this.#___private_widgetComponent_4 = _init_widgetComponent(this);
            }
        }
    }
}();
export { _PieMenu as PieMenu };
