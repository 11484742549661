import { markdownToSnapshot } from '../_common/markdown-utils';
import { getSurfaceElementFromEditor } from '../_common/selection-utils';
import { basicTheme } from './template';
export const PPTBuilder = (host)=>{
    const service = host.spec.getService('affine:page');
    const docs = [];
    const contents = [];
    const allImages = [];
    const addDoc = async (block)=>{
        const sections = block.children.map((v)=>{
            const title = getText(v);
            const keywords = getText(v.children[0]);
            const content = getText(v.children[1]);
            return {
                title,
                keywords,
                content
            };
        });
        const doc = {
            isCover: docs.length === 0,
            title: getText(block),
            sections
        };
        docs.push(doc);
        if (doc.isCover) return;
        const job = service.createTemplateJob('template');
        const { images, content } = await basicTheme(doc);
        contents.push(content);
        allImages.push(images);
        if (images.length) {
            await Promise.all(images.map(({ id, url })=>fetch(url).then((res)=>res.blob()).then((blob)=>job.job.assets.set(id, blob))));
        }
        await job.insertTemplate(content);
        getSurfaceElementFromEditor(host).refresh();
    };
    return {
        process: async (text)=>{
            try {
                const snapshot = await markdownToSnapshot(text, host);
                const block = snapshot.snapshot.content[0];
                for (const child of block.children){
                    await addDoc(child);
                    const { centerX, centerY, zoom } = service.getFitToScreenData();
                    service.viewport.setViewport(zoom, [
                        centerX,
                        centerY
                    ]);
                }
            } catch (e) {
                console.error(e);
            }
            return {
                contents,
                images: allImages
            };
        },
        done: async (text)=>{
            const snapshot = await markdownToSnapshot(text, host);
            const block = snapshot.snapshot.content[0];
            await addDoc(block.children[block.children.length - 1]);
        }
    };
};
const getText = (block)=>{
    return block.props.text?.delta?.[0]?.insert ?? '';
};
