import { Slot } from '@blocksuite/global/utils';
import { SharedPriorityTarget } from '../utils/async-queue.js';
import { MANUALLY_STOP, throwIfAborted } from '../utils/throw-if-aborted.js';
import { DocEngineStep, DocPeerStep } from './consts.js';
import { SyncPeer } from './peer.js';
export class DocEngine {
    constructor(rootDoc, main, shadows, logger){
        this.rootDoc = rootDoc;
        this.main = main;
        this.shadows = shadows;
        this.logger = logger;
        this._abort = new AbortController();
        this.onStatusChange = new Slot();
        this.priorityTarget = new SharedPriorityTarget();
        this._status = {
            step: DocEngineStep.Stopped,
            main: null,
            shadows: shadows.map(()=>null),
            retrying: false
        };
        this.logger.debug(`syne-engine:${this.rootDocId} status init`, this.status);
    }
    setStatus(s) {
        this.logger.debug(`syne-engine:${this.rootDocId} status change`, s);
        this._status = s;
        this.onStatusChange.emit(s);
    }
    canGracefulStop() {
        return !!this.status.main && this.status.main.pendingPushUpdates === 0;
    }
    forceStop() {
        this._abort.abort(MANUALLY_STOP);
        this.setStatus({
            step: DocEngineStep.Stopped,
            main: null,
            shadows: this.shadows.map(()=>null),
            retrying: false
        });
    }
    setPriorityRule(target) {
        this.priorityTarget.priorityRule = target;
    }
    start() {
        if (this.status.step !== DocEngineStep.Stopped) {
            this.forceStop();
        }
        this._abort = new AbortController();
        this.sync(this._abort.signal).catch((err)=>{
            this.logger.error(`syne-engine:${this.rootDocId}`, err);
        });
    }
    async sync(signal) {
        const state = {
            mainPeer: null,
            shadowPeers: this.shadows.map(()=>null)
        };
        const cleanUp = [];
        try {
            state.mainPeer = new SyncPeer(this.rootDoc, this.main, this.priorityTarget, this.logger);
            cleanUp.push(state.mainPeer.onStatusChange.on(()=>{
                if (!signal.aborted) this.updateSyncingState(state.mainPeer, state.shadowPeers);
            }).dispose);
            this.updateSyncingState(state.mainPeer, state.shadowPeers);
            await state.mainPeer.waitForLoaded(signal);
            state.shadowPeers = this.shadows.map((shadow)=>{
                const peer = new SyncPeer(this.rootDoc, shadow, this.priorityTarget, this.logger);
                cleanUp.push(peer.onStatusChange.on(()=>{
                    if (!signal.aborted) this.updateSyncingState(state.mainPeer, state.shadowPeers);
                }).dispose);
                return peer;
            });
            this.updateSyncingState(state.mainPeer, state.shadowPeers);
            await new Promise((_, reject)=>{
                if (signal.aborted) {
                    reject(signal.reason);
                }
                signal.addEventListener('abort', ()=>{
                    reject(signal.reason);
                });
            });
        } catch (error) {
            if (error === MANUALLY_STOP || signal.aborted) {
                return;
            }
            throw error;
        } finally{
            state.mainPeer?.stop();
            for (const shadowPeer of state.shadowPeers){
                shadowPeer?.stop();
            }
            for (const clean of cleanUp){
                clean();
            }
        }
    }
    updateSyncingState(local, shadows) {
        let step = DocEngineStep.Synced;
        const allPeer = [
            local,
            ...shadows
        ];
        for (const peer of allPeer){
            if (!peer || peer.status.step !== DocPeerStep.Synced) {
                step = DocEngineStep.Syncing;
                break;
            }
        }
        this.setStatus({
            step,
            main: local?.status ?? null,
            shadows: shadows.map((peer)=>peer?.status ?? null),
            retrying: allPeer.some((peer)=>peer?.status.step === DocPeerStep.Retrying)
        });
    }
    async waitForGracefulStop(abort) {
        await Promise.race([
            new Promise((_, reject)=>{
                if (abort?.aborted) {
                    reject(abort?.reason);
                }
                abort?.addEventListener('abort', ()=>{
                    reject(abort.reason);
                });
            }),
            new Promise((resolve)=>{
                this.onStatusChange.on(()=>{
                    if (this.canGracefulStop()) {
                        resolve();
                    }
                });
            })
        ]);
        throwIfAborted(abort);
        this.forceStop();
    }
    async waitForLoadedRootDoc(abort) {
        function isLoadedRootDoc(status) {
            return ![
                status.main,
                ...status.shadows
            ].some((peer)=>!peer || peer.step <= DocPeerStep.LoadingRootDoc);
        }
        if (isLoadedRootDoc(this.status)) {
            return;
        } else {
            return Promise.race([
                new Promise((resolve)=>{
                    this.onStatusChange.on((status)=>{
                        if (isLoadedRootDoc(status)) {
                            resolve();
                        }
                    });
                }),
                new Promise((_, reject)=>{
                    if (abort?.aborted) {
                        reject(abort?.reason);
                    }
                    abort?.addEventListener('abort', ()=>{
                        reject(abort.reason);
                    });
                })
            ]);
        }
    }
    async waitForSynced(abort) {
        if (this.status.step === DocEngineStep.Synced) {
            return;
        } else {
            return Promise.race([
                new Promise((resolve)=>{
                    this.onStatusChange.on((status)=>{
                        if (status.step === DocEngineStep.Synced) {
                            resolve();
                        }
                    });
                }),
                new Promise((_, reject)=>{
                    if (abort?.aborted) {
                        reject(abort?.reason);
                    }
                    abort?.addEventListener('abort', ()=>{
                        reject(abort.reason);
                    });
                })
            ]);
        }
    }
    get rootDocId() {
        return this.rootDoc.guid;
    }
    get status() {
        return this._status;
    }
}
