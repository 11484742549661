function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _init__embedScale, _init_edgeless, _init_model, _init_quickConnectButton, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { Bound } from '@blocksuite/global/utils';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { join } from 'lit/directives/join.js';
import { repeat } from 'lit/directives/repeat.js';
import { toggleEmbedCardEditModal } from '../../../_common/components/embed-card/modal/embed-card-edit-modal.js';
import { isPeekable, peek } from '../../../_common/components/index.js';
import { toast } from '../../../_common/components/toast.js';
import '../../../_common/components/toolbar/icon-button.js';
import '../../../_common/components/toolbar/menu-button.js';
import '../../../_common/components/toolbar/separator.js';
import { renderToolbarSeparator } from '../../../_common/components/toolbar/separator.js';
import { EMBED_CARD_HEIGHT, EMBED_CARD_WIDTH } from '../../../_common/consts.js';
import { CaptionIcon, CenterPeekIcon, CopyIcon, EditIcon, ExpandFullSmallIcon, OpenIcon, PaletteIcon, SmallArrowDownIcon } from '../../../_common/icons/index.js';
import { getEmbedCardIcons, getHostName } from '../../../_common/utils/url.js';
import { BookmarkStyles } from '../../../bookmark-block/bookmark-model.js';
import '../../edgeless/components/panel/card-style-panel.js';
import { isBookmarkBlock, isEmbedGithubBlock, isEmbedHtmlBlock, isEmbedLinkedDocBlock, isEmbedSyncedDocBlock } from '../../edgeless/utils/query.js';
let _EdgelessChangeEmbedCardButton;
_dec = customElement('edgeless-change-embed-card-button'), _dec1 = state(), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EdgelessChangeEmbedCardButton), _initClass();
    }
    static{
        class EdgelessChangeEmbedCardButton extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init__embedScale, _init_edgeless, _init_model, _init_quickConnectButton, _initProto], c: [_EdgelessChangeEmbedCardButton, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_embedScale"
                    ],
                    [
                        _dec2,
                        1,
                        "edgeless"
                    ],
                    [
                        _dec3,
                        1,
                        "model"
                    ],
                    [
                        _dec4,
                        1,
                        "quickConnectButton"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    .affine-link-preview {
      display: flex;
      justify-content: flex-start;
      width: 140px;
      padding: var(--1, 0px);
      border-radius: var(--1, 0px);
      opacity: var(--add, 1);
      user-select: none;
      cursor: pointer;

      color: var(--affine-link-color);
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: var(--affine-font-family);
      font-size: var(--affine-font-sm);
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      text-wrap: nowrap;
    }

    .affine-link-preview > span {
      display: inline-block;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      text-overflow: ellipsis;
      overflow: hidden;
      opacity: var(--add, 1);
    }
  `;
            }
            get _blockComponent() {
                const blockSelection = this.edgeless.service.selection.surfaceSelections.filter((sel)=>sel.elements.includes(this.model.id));
                if (blockSelection.length !== 1) {
                    return;
                }
                const blockComponent = this.std.view.getBlock(blockSelection[0].blockId);
                if (!blockComponent) return;
                return blockComponent;
            }
            get _canConvertToEmbedView() {
                const block = this._blockComponent;
                if (!!block && isEmbedLinkedDocBlock(block.model)) {
                    const isSyncedDocEnabled = block.doc.awarenessStore.getFlag('enable_synced_doc_block');
                    if (!isSyncedDocEnabled) {
                        return false;
                    }
                }
                return block && 'convertToEmbed' in block || this._embedOptions?.viewType === 'embed';
            }
            get _canShowCardStylePanel() {
                return isBookmarkBlock(this.model) || isEmbedGithubBlock(this.model) || isEmbedLinkedDocBlock(this.model);
            }
            get _canShowFullScreenButton() {
                return isEmbedHtmlBlock(this.model);
            }
            get _canShowUrlOptions() {
                return 'url' in this.model && (isBookmarkBlock(this.model) || isEmbedGithubBlock(this.model) || isEmbedLinkedDocBlock(this.model));
            }
            get _doc() {
                return this.model.doc;
            }
            get _embedViewButtonDisabled() {
                if (this._doc.readonly) {
                    return true;
                }
                return isEmbedLinkedDocBlock(this.model) && (!!this._blockComponent?.closest('affine-embed-synced-doc-block') || this.model.pageId === this._doc.id);
            }
            get _getCardStyleOptions() {
                const { EmbedCardHorizontalIcon, EmbedCardListIcon, EmbedCardVerticalIcon, EmbedCardCubeIcon } = getEmbedCardIcons();
                return [
                    {
                        style: 'horizontal',
                        Icon: EmbedCardHorizontalIcon,
                        tooltip: 'Large horizontal style'
                    },
                    {
                        style: 'list',
                        Icon: EmbedCardListIcon,
                        tooltip: 'Small horizontal style'
                    },
                    {
                        style: 'vertical',
                        Icon: EmbedCardVerticalIcon,
                        tooltip: 'Large vertical style'
                    },
                    {
                        style: 'cube',
                        Icon: EmbedCardCubeIcon,
                        tooltip: 'Small vertical style'
                    }
                ];
            }
            get _isCardView() {
                if (isBookmarkBlock(this.model) || isEmbedLinkedDocBlock(this.model)) {
                    return true;
                }
                return this._embedOptions?.viewType === 'card';
            }
            get _isEmbedView() {
                return !isBookmarkBlock(this.model) && (isEmbedSyncedDocBlock(this.model) || this._embedOptions?.viewType === 'embed');
            }
            get _openButtonDisabled() {
                return isEmbedLinkedDocBlock(this.model) && this.model.pageId === this._doc.id;
            }
            _openMenuButton() {
                const buttons = [];
                if (isEmbedLinkedDocBlock(this.model) || isEmbedSyncedDocBlock(this.model)) {
                    buttons.push({
                        name: 'Open this doc',
                        icon: ExpandFullSmallIcon,
                        handler: this._open,
                        disabled: this._openButtonDisabled
                    });
                } else if (this._canShowFullScreenButton) {
                    buttons.push({
                        name: 'Open this doc',
                        icon: ExpandFullSmallIcon,
                        handler: this._open
                    });
                }
                if (this._blockComponent && isPeekable(this._blockComponent)) {
                    buttons.push({
                        name: 'Open in center peek',
                        icon: CenterPeekIcon,
                        handler: ()=>this._peek()
                    });
                }
                if (buttons.length === 0) {
                    return nothing;
                }
                return html`
      <editor-menu-button
        .contentPadding=${'8px'}
        .button=${html`
          <editor-icon-button
            aria-label="Open"
            .justify=${'space-between'}
            .labelHeight=${'20px'}
          >
            ${OpenIcon}${SmallArrowDownIcon}
          </editor-icon-button>
        `}
      >
        <div data-size="small" data-orientation="vertical">
          ${repeat(buttons, (button)=>button.name, ({ name, icon, handler, disabled })=>html`
              <editor-menu-action
                aria-label=${name}
                ?disabled=${disabled}
                @click=${handler}
              >
                ${icon}<span class="label">${name}</span>
              </editor-menu-action>
            `)}
        </div>
      </editor-menu-button>
    `;
            }
            get _rootService() {
                return this.std.spec.getService('affine:page');
            }
            _setCardStyle(style) {
                const bounds = Bound.deserialize(this.model.xywh);
                bounds.w = EMBED_CARD_WIDTH[style];
                bounds.h = EMBED_CARD_HEIGHT[style];
                const xywh = bounds.serialize();
                this.model.doc.updateBlock(this.model, {
                    style,
                    xywh
                });
            }
            _setEmbedScale(scale) {
                if (isEmbedHtmlBlock(this.model)) return;
                const bound = Bound.deserialize(this.model.xywh);
                if ('scale' in this.model) {
                    const oldScale = this.model.scale ?? 1;
                    const ratio = scale / oldScale;
                    bound.w *= ratio;
                    bound.h *= ratio;
                    const xywh = bound.serialize();
                    this.model.doc.updateBlock(this.model, {
                        scale,
                        xywh
                    });
                } else {
                    bound.h = EMBED_CARD_HEIGHT[this.model.style] * scale;
                    bound.w = EMBED_CARD_WIDTH[this.model.style] * scale;
                    const xywh = bound.serialize();
                    this.model.doc.updateBlock(this.model, {
                        xywh
                    });
                }
                this._embedScale = scale;
            }
            _showCaption() {
                this._blockComponent?.captionEditor?.show();
            }
            _viewMenuButton() {
                if (this._canConvertToEmbedView || this._isEmbedView) {
                    const buttons = [
                        {
                            type: 'card',
                            name: 'Card view',
                            handler: ()=>this._convertToCardView(),
                            disabled: this.model.doc.readonly
                        },
                        {
                            type: 'embed',
                            name: 'Embed view',
                            handler: ()=>this._convertToEmbedView(),
                            disabled: this.model.doc.readonly && this._embedViewButtonDisabled
                        }
                    ];
                    return html`
        <editor-menu-button
          .contentPadding=${'8px'}
          .button=${html`
            <editor-icon-button
              aria-label="Switch view"
              .justify=${'space-between'}
              .labelHeight=${'20px'}
              .iconContainerWidth=${'110px'}
            >
              <div class="label">
                <span style="text-transform: capitalize"
                  >${this._viewType}</span
                >
                view
              </div>
              ${SmallArrowDownIcon}
            </editor-icon-button>
          `}
        >
          <div data-size="small" data-orientation="vertical">
            ${repeat(buttons, (button)=>button.type, ({ type, name, handler, disabled })=>html`
                <editor-menu-action
                  aria-label=${name}
                  data-testid=${`link-to-${type}`}
                  ?data-selected=${this._viewType === type}
                  ?disabled=${disabled}
                  @click=${handler}
                >
                  ${name}
                </editor-menu-action>
              `)}
          </div>
        </editor-menu-button>
      `;
                }
                return nothing;
            }
            get _viewType() {
                if (this._isCardView) {
                    return 'card';
                }
                if (this._isEmbedView) {
                    return 'embed';
                }
                return 'inline';
            }
            get std() {
                return this.edgeless.std;
            }
            connectedCallback() {
                super.connectedCallback();
                this._embedScale = this._getScale();
            }
            render() {
                const model = this.model;
                if ('url' in this.model) {
                    this._embedOptions = this._rootService.getEmbedBlockOptions(this.model.url);
                }
                const buttons = [
                    this._canShowUrlOptions && 'url' in model ? html`
            <a
              class="affine-link-preview"
              href=${model.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span>${getHostName(model.url)}</span>
            </a>

            <editor-icon-button
              aria-label="Click to copy link"
              .tooltip=${'Click to copy link'}
              class="change-embed-card-button copy"
              ?disabled=${this._doc.readonly}
              @click=${this._copyUrl}
            >
              ${CopyIcon}
            </editor-icon-button>

            <editor-icon-button
              aria-label="Edit"
              .tooltip=${'Edit'}
              class="change-embed-card-button edit"
              ?disabled=${this._doc.readonly}
              @click=${()=>toggleEmbedCardEditModal(this.std.host, model)}
            >
              ${EditIcon}
            </editor-icon-button>
          ` : nothing,
                    this._openMenuButton(),
                    this._viewMenuButton(),
                    'style' in model && this._canShowCardStylePanel ? html`
            <editor-menu-button
              .contentPadding=${'8px'}
              .button=${html`
                <editor-icon-button
                  aria-label="Card style"
                  .tooltip=${'Card style'}
                >
                  ${PaletteIcon}
                </editor-icon-button>
              `}
            >
              <card-style-panel
                .value=${model.style}
                .options=${this._getCardStyleOptions}
                .onSelect=${(value)=>this._setCardStyle(value)}
              >
              </card-style-panel>
            </editor-menu-button>
          ` : nothing,
                    'caption' in model ? html`
            <editor-icon-button
              aria-label="Add caption"
              .tooltip=${'Add caption'}
              class="change-embed-card-button caption"
              ?disabled=${this._doc.readonly}
              @click=${this._showCaption}
            >
              ${CaptionIcon}
            </editor-icon-button>
          ` : nothing,
                    this.quickConnectButton,
                    isEmbedHtmlBlock(model) ? nothing : html`
            <editor-menu-button
              .contentPadding=${'8px'}
              .button=${html`
                <editor-icon-button
                  aria-label="Scale"
                  .tooltip=${'Scale'}
                  .justify=${'space-between'}
                  .iconContainerWidth=${'65px'}
                  .labelHeight=${'20px'}
                >
                  <span class="label">
                    ${Math.round(this._embedScale * 100) + '%'}
                  </span>
                  ${SmallArrowDownIcon}
                </editor-icon-button>
              `}
            >
              <edgeless-scale-panel
                class="embed-scale-popper"
                .scale=${Math.round(this._embedScale * 100)}
                .onSelect=${(scale)=>this._setEmbedScale(scale)}
              ></edgeless-scale-panel>
            </editor-menu-button>
          `
                ];
                return join(buttons.filter((button)=>button !== nothing), renderToolbarSeparator);
            }
            #___private__embedScale_1;
            get _embedScale() {
                return this.#___private__embedScale_1;
            }
            set _embedScale(_v) {
                this.#___private__embedScale_1 = _v;
            }
            #___private_edgeless_2;
            get edgeless() {
                return this.#___private_edgeless_2;
            }
            set edgeless(_v) {
                this.#___private_edgeless_2 = _v;
            }
            #___private_model_3;
            get model() {
                return this.#___private_model_3;
            }
            set model(_v) {
                this.#___private_model_3 = _v;
            }
            #___private_quickConnectButton_4;
            get quickConnectButton() {
                return this.#___private_quickConnectButton_4;
            }
            set quickConnectButton(_v) {
                this.#___private_quickConnectButton_4 = _v;
            }
            constructor(...args){
                super(...args);
                this._convertToCardView = ()=>{
                    if (this._isCardView) {
                        return;
                    }
                    const block = this._blockComponent;
                    if (block && 'convertToCard' in block) {
                        block.convertToCard();
                        return;
                    }
                    if (!('url' in this.model)) {
                        return;
                    }
                    const { id, url, xywh, style, caption } = this.model;
                    let targetFlavour = 'affine:bookmark', targetStyle = style;
                    if (this._embedOptions && this._embedOptions.viewType === 'card') {
                        const { flavour, styles } = this._embedOptions;
                        targetFlavour = flavour;
                        targetStyle = styles.includes(style) ? style : styles[0];
                    } else {
                        targetStyle = BookmarkStyles.includes(style) ? style : BookmarkStyles[0];
                    }
                    const bound = Bound.deserialize(xywh);
                    bound.w = EMBED_CARD_WIDTH[targetStyle];
                    bound.h = EMBED_CARD_HEIGHT[targetStyle];
                    const newId = this.edgeless.service.addBlock(targetFlavour, {
                        url,
                        xywh: bound.serialize(),
                        style: targetStyle,
                        caption
                    }, this.edgeless.surface.model);
                    this.std.command.exec('reassociateConnectors', {
                        oldId: id,
                        newId
                    });
                    this.edgeless.service.selection.set({
                        editing: false,
                        elements: [
                            newId
                        ]
                    });
                    this._doc.deleteBlock(this.model);
                };
                this._convertToEmbedView = ()=>{
                    if (this._isEmbedView) {
                        return;
                    }
                    const block = this._blockComponent;
                    if (block && 'convertToEmbed' in block) {
                        block.convertToEmbed();
                        return;
                    }
                    if (!('url' in this.model)) {
                        return;
                    }
                    if (!this._embedOptions) return;
                    const { flavour, styles } = this._embedOptions;
                    const { id, url, xywh, style } = this.model;
                    const targetStyle = styles.includes(style) ? style : styles[0];
                    const bound = Bound.deserialize(xywh);
                    bound.w = EMBED_CARD_WIDTH[targetStyle];
                    bound.h = EMBED_CARD_HEIGHT[targetStyle];
                    const newId = this.edgeless.service.addBlock(flavour, {
                        url,
                        xywh: bound.serialize(),
                        style: targetStyle
                    }, this.edgeless.surface.model);
                    this.std.command.exec('reassociateConnectors', {
                        oldId: id,
                        newId
                    });
                    this.edgeless.service.selection.set({
                        editing: false,
                        elements: [
                            newId
                        ]
                    });
                    this._doc.deleteBlock(this.model);
                };
                this._copyUrl = ()=>{
                    if (!('url' in this.model)) {
                        return;
                    }
                    navigator.clipboard.writeText(this.model.url).catch(console.error);
                    toast(this.std.host, 'Copied link to clipboard');
                    this.edgeless.service.selection.clear();
                };
                this._embedOptions = null;
                this._getScale = ()=>{
                    if ('scale' in this.model) {
                        return this.model.scale ?? 1;
                    } else if (isEmbedHtmlBlock(this.model)) {
                        return 1;
                    }
                    const bound = Bound.deserialize(this.model.xywh);
                    return bound.h / EMBED_CARD_HEIGHT[this.model.style];
                };
                this._open = ()=>{
                    this._blockComponent?.open();
                };
                this._peek = ()=>{
                    if (!this._blockComponent) return;
                    peek(this._blockComponent);
                };
                this.#___private__embedScale_1 = (_initProto(this), _init__embedScale(this, 1));
                this.#___private_edgeless_2 = _init_edgeless(this);
                this.#___private_model_3 = _init_model(this);
                this.#___private_quickConnectButton_4 = _init_quickConnectButton(this);
            }
        }
    }
}();
export function renderEmbedButton(edgeless, models, quickConnectButton) {
    if (models?.length !== 1) return nothing;
    return html`
    <edgeless-change-embed-card-button
      .model=${models[0]}
      .edgeless=${edgeless}
      .quickConnectButton=${quickConnectButton?.pop() ?? nothing}
    ></edgeless-change-embed-card-button>
  `;
}
export { _EdgelessChangeEmbedCardButton as EdgelessChangeEmbedCardButton };
