import { DarkLoadingIcon, EmbedCardDarkBannerIcon, EmbedCardDarkCubeIcon, EmbedCardDarkHorizontalIcon, EmbedCardDarkListIcon, EmbedCardDarkVerticalIcon, EmbedCardLightBannerIcon, EmbedCardLightCubeIcon, EmbedCardLightHorizontalIcon, EmbedCardLightListIcon, EmbedCardLightVerticalIcon, LightLoadingIcon } from '../icons/text.js';
import { ThemeObserver } from '../theme/theme-observer.js';
export const ALLOWED_SCHEMES = [
    'http',
    'https',
    'ftp',
    'sftp',
    'mailto',
    'tel'
];
const MAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const URL_REGEX = new RegExp('^' + '(?:(?:(?:https?|ftp):)?\\/\\/)' + '(?:\\S+(?::\\S*)?@)?' + '(?:' + '(?!(?:10|127)(?:\\.\\d{1,3}){3})' + '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' + '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' + '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' + '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' + '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' + '|' + '(?:' + '(?:' + '[a-z0-9\\u00a1-\\uffff]' + '[a-z0-9\\u00a1-\\uffff_-]{0,62}' + ')?' + '[a-z0-9\\u00a1-\\uffff]\\.' + ')+' + '(?:[a-z\\u00a1-\\uffff]{2,6}\\.?)' + ')' + '(?::\\d{2,5})?' + '(?:[/?#]\\S*)?' + '$', 'i');
export function normalizeUrl(url) {
    const includeScheme = ALLOWED_SCHEMES.find((scheme)=>url.startsWith(scheme + ':'));
    if (includeScheme) {
        return url;
    }
    const isEmail = MAIL_REGEX.test(url);
    if (isEmail) {
        return 'mailto:' + url;
    }
    return 'http://' + url;
}
export function isValidUrl(str) {
    if (!str) {
        return false;
    }
    const url = normalizeUrl(str);
    if (url === str) {
        try {
            new URL(url);
        } catch  {
            return false;
        }
        return true;
    }
    return URL_REGEX.test(url);
}
const COMMON_TLDS = [
    'com',
    'org',
    'net',
    'edu',
    'gov',
    'co',
    'io',
    'me',
    'moe',
    'mil',
    'top',
    'dev',
    'xyz',
    'info',
    'cat',
    'ru',
    'de',
    'jp',
    'uk',
    'pro'
];
function isCommonTLD(url) {
    const tld = url.hostname.split('.').pop();
    if (!tld) {
        return false;
    }
    return COMMON_TLDS.includes(tld);
}
export function isStrictUrl(str) {
    if (!isValidUrl(str)) {
        return false;
    }
    const url = new URL(normalizeUrl(str));
    if (isCommonTLD(url)) {
        return true;
    }
    return false;
}
export function isUrlInClipboard(clipboardData) {
    const url = clipboardData.getData('text/plain');
    return isValidUrl(url);
}
export function getEmbedCardIcons() {
    const theme = ThemeObserver.mode;
    if (theme === 'light') {
        return {
            LoadingIcon: LightLoadingIcon,
            EmbedCardBannerIcon: EmbedCardLightBannerIcon,
            EmbedCardHorizontalIcon: EmbedCardLightHorizontalIcon,
            EmbedCardListIcon: EmbedCardLightListIcon,
            EmbedCardVerticalIcon: EmbedCardLightVerticalIcon,
            EmbedCardCubeIcon: EmbedCardLightCubeIcon
        };
    } else {
        return {
            LoadingIcon: DarkLoadingIcon,
            EmbedCardBannerIcon: EmbedCardDarkBannerIcon,
            EmbedCardHorizontalIcon: EmbedCardDarkHorizontalIcon,
            EmbedCardListIcon: EmbedCardDarkListIcon,
            EmbedCardVerticalIcon: EmbedCardDarkVerticalIcon,
            EmbedCardCubeIcon: EmbedCardDarkCubeIcon
        };
    }
}
export function getHostName(url) {
    try {
        return new URL(url).hostname;
    } catch  {
        return url;
    }
}
