import { LiveData, Service } from '@toeverything/infra';
import { Observable } from 'rxjs';
import { Array as YArray } from 'yjs';
const SETTING_KEY = 'setting';
const FEEDS_KEY = 'feeds';
const SUBSCRIPTIONS_AFTER_CURSOR_KEY = 'subscriptionAfterCursor';
export class FeedsService extends Service {
    constructor(workspaceService){
        super();
        this.workspaceService = workspaceService;
        this.afterCursor$ = LiveData.from(new Observable((subscriber)=>{
            subscriber.next(this.afterCursor || '0');
            const fn = ()=>{
                subscriber.next(this.afterCursor || '0');
            };
            this.setting.observeDeep(fn);
            return ()=>{
                this.setting.unobserveDeep(fn);
            };
        }), '0');
        this.feeds$ = LiveData.from(new Observable((subscriber)=>{
            subscriber.next(this.feedsYArray?.toArray() ?? []);
            const fn = ()=>{
                subscriber.next(this.feedsYArray?.toArray() ?? []);
            };
            this.setting.observeDeep(fn);
            return ()=>{
                this.setting.unobserveDeep(fn);
            };
        }), []);
    }
    get doc() {
        return this.workspaceService.workspace.docCollection.doc;
    }
    get setting() {
        return this.workspaceService.workspace.docCollection.doc.getMap(SETTING_KEY);
    }
    get feedsYArray() {
        return this.setting.get(FEEDS_KEY);
    }
    get afterCursor() {
        return this.setting.get(SUBSCRIPTIONS_AFTER_CURSOR_KEY);
    }
    updateAfterCursor(cursor) {
        this.setting.set(SUBSCRIPTIONS_AFTER_CURSOR_KEY, cursor);
    }
    feedById$(feedId) {
        if (!feedId) {
            return;
        }
        return this.feeds$.map((feed)=>feed.find((item)=>item.id === feedId));
    }
    subscribe(...collections) {
        if (!this.setting.has(FEEDS_KEY)) {
            this.setting.set(FEEDS_KEY, new YArray());
        }
        this.doc.transact(()=>{
            this.feedsYArray?.insert(0, collections);
        });
    }
    updateFeed(id, updater) {
        if (this.feedsYArray) {
            updateFirstOfYArray(this.feedsYArray, (v)=>v.id === id, (v)=>{
                return updater(v);
            });
        }
    }
    hasSubscribe(id) {
        return this.feedsYArray?.toArray().some((v)=>v.id === id) ?? false;
    }
    unsubscribe(...ids) {
        const subscriptionsYArray = this.feedsYArray;
        if (!subscriptionsYArray) {
            return;
        }
        const set = new Set(ids);
        this.workspaceService.workspace.docCollection.doc.transact(()=>{
            const indexList = [];
            subscriptionsYArray.forEach((feed, i)=>{
                if (set.has(feed.id)) {
                    set.delete(feed.id);
                    indexList.unshift(i);
                }
            });
            indexList.forEach((i)=>{
                subscriptionsYArray.delete(i);
            });
        });
    }
}
const updateFirstOfYArray = (array, p, update)=>{
    array.doc?.transact(()=>{
        for(let i = 0; i < array.length; i++){
            const ele = array.get(i);
            if (p(ele)) {
                array.delete(i);
                array.insert(i, [
                    update(ele)
                ]);
                return;
            }
        }
    });
};
