import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { join } from 'lit/directives/join.js';
import { repeat } from 'lit/directives/repeat.js';
import './icon-button.js';
import './menu-button.js';
import './separator.js';
export function renderActions(fatActions, handler, selectedName) {
    return join(fatActions.filter((g)=>g.length).map((g)=>g.filter((a)=>a !== nothing)).filter((g)=>g.length).map((actions)=>repeat(actions, (action)=>action.name, (action)=>html`
            <editor-menu-action
              aria-label=${action.name}
              class=${classMap({
                delete: action.type === 'delete'
            })}
              ?data-selected=${selectedName === action.name}
              ?disabled=${action.disabled}
              @click=${action.handler ? action.handler : ()=>handler?.(action)}
            >
              ${action.icon}<span class="label">${action.name}</span>
            </editor-menu-action>
          `)), ()=>html`
      <editor-toolbar-separator
        data-orientation="horizontal"
      ></editor-toolbar-separator>
    `);
}
