function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _init_edgeless, _init_element, _init_mountEditor, _init_richText, _initProto;
import { RangeManager, ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { Bound, Vec } from '@blocksuite/global/utils';
import { assertExists } from '@blocksuite/global/utils';
import { DocCollection } from '@blocksuite/store';
import { html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { getNearestTranslation, isElementOutsideViewport } from '../../../../_common/edgeless/mindmap/index.js';
import { ThemeObserver } from '../../../../_common/theme/theme-observer.js';
import { TextResizing } from '../../../../surface-block/consts.js';
import { MindmapElementModel } from '../../../../surface-block/element-model/index.js';
import { toRadian } from '../../../../surface-block/index.js';
import { wrapFontFamily } from '../../../../surface-block/utils/font.js';
import { getSelectedRect } from '../../utils/query.js';
let _EdgelessShapeTextEditor;
_dec = customElement('edgeless-shape-text-editor'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = query('rich-text');
class EdgelessShapeTextEditor extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
    static{
        ({ e: [_init_edgeless, _init_element, _init_mountEditor, _init_richText, _initProto], c: [_EdgelessShapeTextEditor, _initClass] } = _apply_decs_2203_r(this, [
            [
                _dec1,
                1,
                "edgeless"
            ],
            [
                _dec2,
                1,
                "element"
            ],
            [
                _dec3,
                1,
                "mountEditor"
            ],
            [
                _dec4,
                1,
                "richText"
            ]
        ], [
            _dec
        ], _WithDisposable));
    }
    _initMindmapKeyBindings() {
        if (!this.element.surface.isInMindmap(this.element.id)) {
            return;
        }
        const service = this.edgeless.service;
        this._disposables.addFromEvent(this, 'keydown', (evt)=>{
            switch(evt.key){
                case 'Enter':
                    {
                        if (evt.shiftKey || evt.isComposing) return;
                        evt.preventDefault();
                        const edgeless = this.edgeless;
                        const element = this.element;
                        const mindmap = this.element.group;
                        const parent = mindmap.getParentNode(element.id) ?? element;
                        const id = mindmap.addNode(parent.id);
                        requestAnimationFrame(()=>{
                            this.element = edgeless.service.getElementById(id);
                            const element = this.element;
                            this.mountEditor?.(element, edgeless);
                            if (isElementOutsideViewport(service.viewport, element, [
                                90,
                                20
                            ])) {
                                const [dx, dy] = getNearestTranslation(edgeless.service.viewport, element, [
                                    100,
                                    20
                                ]);
                                edgeless.service.viewport.smoothTranslate(service.viewport.centerX - dx, service.viewport.centerY + dy);
                            }
                        });
                        this.ownerDocument.activeElement.blur();
                        break;
                    }
                case 'Tab':
                    {
                        evt.preventDefault();
                        const edgeless = this.edgeless;
                        const element = this.element;
                        const mindmap = this.element.group;
                        const id = mindmap.addNode(element.id);
                        requestAnimationFrame(()=>{
                            this.element = edgeless.service.getElementById(id);
                            const element = this.element;
                            this.mountEditor?.(element, edgeless);
                            if (isElementOutsideViewport(service.viewport, element, [
                                90,
                                20
                            ])) {
                                const [dx, dy] = getNearestTranslation(edgeless.service.viewport, element, [
                                    100,
                                    20
                                ]);
                                edgeless.service.viewport.smoothTranslate(service.viewport.centerX - dx, service.viewport.centerY + dy);
                            }
                        });
                        this.ownerDocument.activeElement.blur();
                        break;
                    }
            }
        });
    }
    _unmount() {
        this._resizeObserver?.disconnect();
        this._resizeObserver = null;
        if (this.element.text) {
            const text = this.element.text.toString();
            const trimed = text.trim();
            const len = trimed.length;
            if (len === 0) {
                this.element.text = undefined;
            } else if (len < text.length) {
                this.element.text = new DocCollection.Y.Text(trimed);
            }
        }
        this.element.textDisplay = true;
        this.element.group instanceof MindmapElementModel && this.element.group.layout();
        this.remove();
        this.edgeless.service.selection.set({
            elements: [],
            editing: false
        });
    }
    _updateElementWH() {
        const bcr = this.richText.getBoundingClientRect();
        const containerHeight = this.richText.offsetHeight;
        const containerWidth = this.richText.offsetWidth;
        const textResizing = this.element.textResizing;
        if (this._lastXYWH !== this.element.xywh) {
            this.requestUpdate();
        }
        if (containerHeight !== this.element.h && textResizing === TextResizing.AUTO_HEIGHT || textResizing === TextResizing.AUTO_WIDTH && containerWidth !== this.element.w) {
            const [leftTopX, leftTopY] = Vec.rotWith([
                this.richText.offsetLeft,
                this.richText.offsetTop
            ], [
                bcr.left + bcr.width / 2,
                bcr.top + bcr.height / 2
            ], toRadian(-this.element.rotate));
            const [modelLeftTopX, modelLeftTopY] = this.edgeless.service.viewport.toModelCoord(leftTopX, leftTopY);
            this.edgeless.service.updateElement(this.element.id, {
                xywh: new Bound(modelLeftTopX, modelLeftTopY, textResizing === TextResizing.AUTO_WIDTH ? containerWidth : this.element.w, containerHeight).serialize()
            });
            this.element.group instanceof MindmapElementModel && this.element.group.layout();
            this.richText.style.minHeight = `${containerHeight}px`;
        }
        this.edgeless.service.selection.set({
            elements: [
                this.element.id
            ],
            editing: true
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAttribute(RangeManager.rangeSyncExcludeAttr, 'true');
    }
    firstUpdated() {
        const dispatcher = this.edgeless.dispatcher;
        assertExists(dispatcher);
        this.element.textDisplay = false;
        this.disposables.add(this.edgeless.service.viewport.viewportUpdated.on(()=>{
            this.requestUpdate();
            this.updateComplete.then(()=>{
                this._updateElementWH();
            }).catch(console.error);
        }));
        this.disposables.add(dispatcher.add('click', ()=>{
            return true;
        }));
        this.disposables.add(dispatcher.add('doubleClick', ()=>{
            return true;
        }));
        this.updateComplete.then(()=>{
            if (this.element.group instanceof MindmapElementModel) {
                this.inlineEditor.selectAll();
            } else {
                this.inlineEditor.focusEnd();
            }
            this.disposables.add(this.inlineEditor.slots.renderComplete.on(()=>{
                this._updateElementWH();
            }));
            this.disposables.addFromEvent(this.inlineEditorContainer, 'blur', ()=>{
                if (this._keeping) return;
                this._unmount();
            });
        }).catch(console.error);
        this.disposables.addFromEvent(this, 'keydown', (evt)=>{
            if (evt.key === 'Escape') {
                requestAnimationFrame(()=>{
                    this.edgeless.service.selection.set({
                        elements: [
                            this.element.id
                        ],
                        editing: false
                    });
                });
                this.ownerDocument.activeElement.blur();
            }
        });
        this._initMindmapKeyBindings();
    }
    async getUpdateComplete() {
        const result = await super.getUpdateComplete();
        await this.richText?.updateComplete;
        return result;
    }
    render() {
        if (!this.element.text) {
            console.error('Failed to mount shape editor because of no text.');
            return nothing;
        }
        const [verticalPadding, horiPadding] = this.element.padding;
        const textResizing = this.element.textResizing;
        const viewport = this.edgeless.service.viewport;
        const zoom = viewport.zoom;
        const rect = getSelectedRect([
            this.element
        ]);
        const rotate = this.element.rotate;
        const [leftTopX, leftTopY] = Vec.rotWith([
            rect.left,
            rect.top
        ], [
            rect.left + rect.width / 2,
            rect.top + rect.height / 2
        ], toRadian(rotate));
        const [x, y] = this.edgeless.service.viewport.toViewCoord(leftTopX, leftTopY);
        const autoWidth = textResizing === TextResizing.AUTO_WIDTH;
        const color = ThemeObserver.generateColorProperty(this.element.color, '#000000');
        const inlineEditorStyle = styleMap({
            position: 'absolute',
            left: x + 'px',
            top: y + 'px',
            width: textResizing > TextResizing.AUTO_WIDTH ? rect.width + 'px' : 'fit-content',
            height: 'initial',
            minHeight: textResizing === TextResizing.AUTO_WIDTH ? '1em' : `${rect.height}px`,
            maxWidth: textResizing === TextResizing.AUTO_WIDTH ? this.element.maxWidth ? `${this.element.maxWidth}px` : undefined : undefined,
            boxSizing: 'border-box',
            fontSize: this.element.fontSize + 'px',
            fontFamily: wrapFontFamily(this.element.fontFamily),
            fontWeight: this.element.fontWeight,
            lineHeight: 'normal',
            outline: 'none',
            transform: `scale(${zoom}, ${zoom}) rotate(${rotate}deg)`,
            transformOrigin: 'top left',
            color,
            padding: `${verticalPadding}px ${horiPadding}px`,
            textAlign: this.element.textAlign,
            display: 'grid',
            gridTemplateColumns: '100%',
            alignItems: this.element.textVerticalAlign === 'center' ? 'center' : this.element.textVerticalAlign === 'bottom' ? 'end' : 'start',
            alignContent: 'center',
            gap: '0',
            zIndex: '1'
        });
        this._lastXYWH = this.element.xywh;
        return html` <style>
        edgeless-shape-text-editor v-text [data-v-text] {
          overflow-wrap: ${autoWidth ? 'normal' : 'anywhere'};
          word-break: ${autoWidth ? 'normal' : 'break-word'} !important;
          white-space: ${autoWidth ? 'pre' : 'pre-wrap'} !important;
        }

        edgeless-shape-text-editor .inline-editor {
          min-width: 1px;
        }
      </style>
      <rich-text
        .yText=${this.element.text}
        .enableFormat=${false}
        .enableAutoScrollHorizontally=${false}
        style=${inlineEditorStyle}
      ></rich-text>`;
    }
    setKeeping(keeping) {
        this._keeping = keeping;
    }
    get inlineEditor() {
        assertExists(this.richText.inlineEditor);
        return this.richText.inlineEditor;
    }
    get inlineEditorContainer() {
        return this.inlineEditor.rootElement;
    }
    #___private_edgeless_1;
    get edgeless() {
        return this.#___private_edgeless_1;
    }
    set edgeless(_v) {
        this.#___private_edgeless_1 = _v;
    }
    #___private_element_2;
    get element() {
        return this.#___private_element_2;
    }
    set element(_v) {
        this.#___private_element_2 = _v;
    }
    #___private_mountEditor_3;
    get mountEditor() {
        return this.#___private_mountEditor_3;
    }
    set mountEditor(_v) {
        this.#___private_mountEditor_3 = _v;
    }
    #___private_richText_4;
    get richText() {
        return this.#___private_richText_4;
    }
    set richText(_v) {
        this.#___private_richText_4 = _v;
    }
    static{
        _initClass();
    }
    constructor(...args){
        super(...args);
        this._keeping = false;
        this._lastXYWH = '';
        this._resizeObserver = null;
        this.#___private_edgeless_1 = (_initProto(this), _init_edgeless(this));
        this.#___private_element_2 = _init_element(this);
        this.#___private_mountEditor_3 = _init_mountEditor(this, undefined);
        this.#___private_richText_4 = _init_richText(this);
    }
}
export { _EdgelessShapeTextEditor as EdgelessShapeTextEditor };
