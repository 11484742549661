function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _init_edgeless, _init_elementType, _init_elements, _init_textColorButton, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { Bound } from '@blocksuite/global/utils';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { join } from 'lit/directives/join.js';
import { when } from 'lit/directives/when.js';
import '../../../_common/components/toolbar/icon-button.js';
import '../../../_common/components/toolbar/menu-button.js';
import '../../../_common/components/toolbar/separator.js';
import { renderToolbarSeparator } from '../../../_common/components/toolbar/separator.js';
import { SmallArrowDownIcon, TextAlignCenterIcon, TextAlignLeftIcon, TextAlignRightIcon } from '../../../_common/icons/index.js';
import { countBy, maxBy } from '../../../_common/utils/iterable.js';
import { EdgelessTextBlockModel } from '../../../edgeless-text/edgeless-text-model.js';
import { isFontStyleSupported, isFontWeightSupported } from '../../../surface-block/canvas-renderer/element-renderer/text/utils.js';
import { normalizeTextBound } from '../../../surface-block/canvas-renderer/element-renderer/text/utils.js';
import { FontFamily, FontStyle, FontWeight, TextAlign } from '../../../surface-block/consts.js';
import { isConnectorWithLabel } from '../../../surface-block/element-model/connector.js';
import { TextElementModel } from '../../../surface-block/element-model/text.js';
import { ConnectorElementModel, ShapeElementModel } from '../../../surface-block/index.js';
import { normalizeShapeBound } from '../../../surface-block/index.js';
import { getFontFacesByFontFamily, wrapFontFamily } from '../../../surface-block/utils/font.js';
import '../../edgeless/components/color-picker/index.js';
import { packColor, packColorsWithColorScheme } from '../../edgeless/components/color-picker/utils.js';
import '../../edgeless/components/panel/align-panel.js';
import { GET_DEFAULT_LINE_COLOR, LINE_COLORS } from '../../edgeless/components/panel/color-panel.js';
import '../../edgeless/components/panel/font-family-panel.js';
import '../../edgeless/components/panel/font-weight-and-style-panel.js';
import '../../edgeless/components/panel/size-panel.js';
const FONT_SIZE_LIST = [
    {
        value: 16
    },
    {
        value: 24
    },
    {
        value: 32
    },
    {
        value: 40
    },
    {
        value: 64
    },
    {
        value: 128
    }
];
const FONT_WEIGHT_CHOOSE = [
    [
        FontWeight.Light,
        ()=>'Light'
    ],
    [
        FontWeight.Regular,
        ()=>'Regular'
    ],
    [
        FontWeight.SemiBold,
        ()=>'Semibold'
    ]
];
const FONT_STYLE_CHOOSE = [
    [
        FontStyle.Normal,
        ()=>nothing
    ],
    [
        FontStyle.Italic,
        ()=>'Italic'
    ]
];
const TEXT_ALIGN_CHOOSE = [
    [
        TextAlign.Left,
        ()=>TextAlignLeftIcon
    ],
    [
        TextAlign.Center,
        ()=>TextAlignCenterIcon
    ],
    [
        TextAlign.Right,
        ()=>TextAlignRightIcon
    ]
];
function countByField(elements, field) {
    return countBy(elements, (element)=>extractField(element, field));
}
function extractField(element, field) {
    if (element instanceof EdgelessTextBlockModel) {
        return field === 'fontSize' ? null : element[field];
    }
    return element instanceof ConnectorElementModel ? element.labelStyle[field] : element[field];
}
function getMostCommonValue(elements, field) {
    const values = countByField(elements, field);
    return maxBy(Object.entries(values), ([_k, count])=>count);
}
function getMostCommonAlign(elements) {
    const max = getMostCommonValue(elements, 'textAlign');
    return max ? max[0] : TextAlign.Left;
}
function getMostCommonColor(elements, colorScheme) {
    const colors = countBy(elements, (ele)=>{
        const color = ele instanceof ConnectorElementModel ? ele.labelStyle.color : ele.color;
        return typeof color === 'object' ? color[colorScheme] ?? color.normal ?? null : color;
    });
    const max = maxBy(Object.entries(colors), ([_k, count])=>count);
    return max ? max[0] : GET_DEFAULT_LINE_COLOR();
}
function getMostCommonFontFamily(elements) {
    const max = getMostCommonValue(elements, 'fontFamily');
    return max ? max[0] : FontFamily.Inter;
}
function getMostCommonFontSize(elements) {
    const max = getMostCommonValue(elements, 'fontSize');
    return max ? Number(max[0]) : FONT_SIZE_LIST[0].value;
}
function getMostCommonFontStyle(elements) {
    const max = getMostCommonValue(elements, 'fontStyle');
    return max ? max[0] : FontStyle.Normal;
}
function getMostCommonFontWeight(elements) {
    const max = getMostCommonValue(elements, 'fontWeight');
    return max ? max[0] : FontWeight.Regular;
}
function buildProps(element, props) {
    if (element instanceof ConnectorElementModel) {
        return {
            labelStyle: {
                ...element.labelStyle,
                ...props
            }
        };
    }
    return {
        ...props
    };
}
let _EdgelessChangeTextMenu;
_dec = customElement('edgeless-change-text-menu'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = query('edgeless-color-picker-button.text-color');
new class extends _identity {
    constructor(){
        super(_EdgelessChangeTextMenu), _initClass();
    }
    static{
        class EdgelessChangeTextMenu extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init_edgeless, _init_elementType, _init_elements, _init_textColorButton, _initProto], c: [_EdgelessChangeTextMenu, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "edgeless"
                    ],
                    [
                        _dec2,
                        1,
                        "elementType"
                    ],
                    [
                        _dec3,
                        1,
                        "elements"
                    ],
                    [
                        _dec4,
                        1,
                        "textColorButton"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    :host {
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
      gap: inherit;
      height: 100%;
    }
  `;
            }
            render() {
                const colorScheme = this.edgeless.surface.renderer.getColorScheme();
                const elements = this.elements;
                const selectedAlign = getMostCommonAlign(elements);
                const selectedColor = getMostCommonColor(elements, colorScheme);
                const selectedFontFamily = getMostCommonFontFamily(elements);
                const selectedFontSize = Math.trunc(getMostCommonFontSize(elements));
                const selectedFontStyle = getMostCommonFontStyle(elements);
                const selectedFontWeight = getMostCommonFontWeight(elements);
                const matchFontFaces = getFontFacesByFontFamily(selectedFontFamily);
                const fontStyleBtnDisabled = matchFontFaces.length === 1 && matchFontFaces[0].style === selectedFontStyle && matchFontFaces[0].weight === selectedFontWeight;
                return join([
                    html`
          <editor-menu-button
            .contentPadding=${'8px'}
            .button=${html`
              <editor-icon-button
                aria-label="Font"
                .tooltip=${'Font'}
                .justify=${'space-between'}
                .labelHeight=${'20px'}
                .iconContainerWidth=${'40px'}
              >
                <span
                  class="label padding0"
                  style=${`font-family: ${wrapFontFamily(selectedFontFamily)}`}
                  >Aa</span
                >${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <edgeless-font-family-panel
              .value=${selectedFontFamily}
              .onSelect=${this._setFontFamily}
            ></edgeless-font-family-panel>
          </editor-menu-button>
        `,
                    when(this.edgeless.doc.awarenessStore.getFlag('enable_color_picker'), ()=>{
                        const { type, colors } = packColorsWithColorScheme(colorScheme, selectedColor, elements[0] instanceof ConnectorElementModel ? elements[0].labelStyle.color : elements[0].color);
                        return html`
              <edgeless-color-picker-button
                class="text-color"
                .label=${'Text color'}
                .pick=${this.pickColor}
                .isText=${true}
                .color=${selectedColor}
                .colors=${colors}
                .colorType=${type}
                .palettes=${LINE_COLORS}
              >
              </edgeless-color-picker-button>
            `;
                    }, ()=>html`
            <editor-menu-button
              .contentPadding=${'8px'}
              .button=${html`
                <editor-icon-button
                  aria-label="Text color"
                  .tooltip=${'Text color'}
                >
                  <edgeless-text-color-icon
                    .color=${selectedColor}
                  ></edgeless-text-color-icon>
                </editor-icon-button>
              `}
            >
              <edgeless-color-panel
                .value=${selectedColor}
                @select=${this._setTextColor}
              ></edgeless-color-panel>
            </editor-menu-button>
          `),
                    html`
          <editor-menu-button
            .contentPadding=${'8px'}
            .button=${html`
              <editor-icon-button
                aria-label="Font style"
                .tooltip=${'Font style'}
                .justify=${'space-between'}
                .labelHeight=${'20px'}
                .iconContainerWidth=${'90px'}
                .disabled=${fontStyleBtnDisabled}
              >
                <span class="label ellipsis">
                  ${choose(selectedFontWeight, FONT_WEIGHT_CHOOSE)}
                  ${choose(selectedFontStyle, FONT_STYLE_CHOOSE)}
                </span>
                ${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <edgeless-font-weight-and-style-panel
              .fontFamily=${selectedFontFamily}
              .fontWeight=${selectedFontWeight}
              .fontStyle=${selectedFontStyle}
              .onSelect=${this._setFontWeightAndStyle}
            ></edgeless-font-weight-and-style-panel>
          </editor-menu-button>
        `,
                    this.elementType === 'edgeless-text' ? nothing : html`
              <editor-menu-button
                .contentPadding=${'8px'}
                .button=${html`
                  <editor-icon-button
                    aria-label="Font size"
                    .tooltip=${'Font size'}
                    .justify=${'space-between'}
                    .labelHeight=${'20px'}
                    .iconContainerWidth=${'60px'}
                  >
                    <span class="label">${selectedFontSize}</span>
                    ${SmallArrowDownIcon}
                  </editor-icon-button>
                `}
              >
                <edgeless-size-panel
                  data-type="check"
                  .size=${selectedFontSize}
                  .sizeList=${FONT_SIZE_LIST}
                  .onSelect=${this._setFontSize}
                ></edgeless-size-panel>
              </editor-menu-button>
            `,
                    html`
          <editor-menu-button
            .button=${html`
              <editor-icon-button
                aria-label="Alignment"
                .tooltip=${'Alignment'}
              >
                ${choose(selectedAlign, TEXT_ALIGN_CHOOSE)}${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <edgeless-align-panel
              .value=${selectedAlign}
              .onSelect=${this._setTextAlign}
            ></edgeless-align-panel>
          </editor-menu-button>
        `
                ].filter((b)=>b !== nothing), renderToolbarSeparator);
            }
            get service() {
                return this.edgeless.service;
            }
            #___private_edgeless_1;
            get edgeless() {
                return this.#___private_edgeless_1;
            }
            set edgeless(_v) {
                this.#___private_edgeless_1 = _v;
            }
            #___private_elementType_2;
            get elementType() {
                return this.#___private_elementType_2;
            }
            set elementType(_v) {
                this.#___private_elementType_2 = _v;
            }
            #___private_elements_3;
            get elements() {
                return this.#___private_elements_3;
            }
            set elements(_v) {
                this.#___private_elements_3 = _v;
            }
            #___private_textColorButton_4;
            get textColorButton() {
                return this.#___private_textColorButton_4;
            }
            set textColorButton(_v) {
                this.#___private_textColorButton_4 = _v;
            }
            constructor(...args){
                super(...args);
                this._setFontFamily = (fontFamily)=>{
                    const currentFontWeight = getMostCommonFontWeight(this.elements);
                    const fontWeight = isFontWeightSupported(fontFamily, currentFontWeight) ? currentFontWeight : FontWeight.Regular;
                    const currentFontStyle = getMostCommonFontStyle(this.elements);
                    const fontStyle = isFontStyleSupported(fontFamily, currentFontStyle) ? currentFontStyle : FontStyle.Normal;
                    const props = {
                        fontFamily,
                        fontWeight,
                        fontStyle
                    };
                    this.elements.forEach((element)=>{
                        this.service.updateElement(element.id, buildProps(element, props));
                        this._updateElementBound(element);
                    });
                };
                this._setFontSize = (fontSize)=>{
                    const props = {
                        fontSize
                    };
                    this.elements.forEach((element)=>{
                        this.service.updateElement(element.id, buildProps(element, props));
                        this._updateElementBound(element);
                    });
                };
                this._setFontWeightAndStyle = (fontWeight, fontStyle)=>{
                    const props = {
                        fontWeight,
                        fontStyle
                    };
                    this.elements.forEach((element)=>{
                        this.service.updateElement(element.id, buildProps(element, props));
                        this._updateElementBound(element);
                    });
                };
                this._setTextAlign = (textAlign)=>{
                    const props = {
                        textAlign
                    };
                    this.elements.forEach((element)=>{
                        this.service.updateElement(element.id, buildProps(element, props));
                    });
                };
                this._setTextColor = ({ detail: color })=>{
                    const props = {
                        color
                    };
                    this.elements.forEach((element)=>{
                        this.service.updateElement(element.id, buildProps(element, props));
                    });
                };
                this._updateElementBound = (element)=>{
                    const elementType = this.elementType;
                    if (elementType === 'text' && element instanceof TextElementModel) {
                        const { text: yText, fontFamily, fontStyle, fontSize, fontWeight, hasMaxWidth } = element;
                        const newBound = normalizeTextBound({
                            yText,
                            fontFamily,
                            fontStyle,
                            fontSize,
                            fontWeight,
                            hasMaxWidth
                        }, Bound.fromXYWH(element.deserializedXYWH));
                        this.service.updateElement(element.id, {
                            xywh: newBound.serialize()
                        });
                    } else if (elementType === 'connector' && isConnectorWithLabel(element)) {
                        const { text, labelXYWH, labelStyle: { fontFamily, fontStyle, fontSize, fontWeight }, labelConstraints: { hasMaxWidth, maxWidth } } = element;
                        const prevBounds = Bound.fromXYWH(labelXYWH || [
                            0,
                            0,
                            16,
                            16
                        ]);
                        const center = prevBounds.center;
                        const bounds = normalizeTextBound({
                            yText: text,
                            fontFamily,
                            fontStyle,
                            fontSize,
                            fontWeight,
                            hasMaxWidth,
                            maxWidth
                        }, prevBounds);
                        bounds.center = center;
                        this.service.updateElement(element.id, {
                            labelXYWH: bounds.toXYWH()
                        });
                    } else if (elementType === 'shape' && element instanceof ShapeElementModel) {
                        const newBound = normalizeShapeBound(element, Bound.fromXYWH(element.deserializedXYWH));
                        this.service.updateElement(element.id, {
                            xywh: newBound.serialize()
                        });
                    }
                };
                this.pickColor = (event)=>{
                    if (event.type === 'pick') {
                        this.elements.forEach((ele)=>{
                            if (ele instanceof ConnectorElementModel) {
                                this.service.updateElement(ele.id, {
                                    labelStyle: {
                                        ...ele.labelStyle,
                                        ...packColor('color', {
                                            ...event.detail
                                        })
                                    }
                                });
                                this._updateElementBound(ele);
                                return;
                            }
                            this.service.updateElement(ele.id, packColor('color', {
                                ...event.detail
                            }));
                            this._updateElementBound(ele);
                        });
                        return;
                    }
                    const key = this.elementType === 'connector' ? 'labelStyle' : 'color';
                    this.elements.forEach((ele)=>{
                        ele[event.type === 'start' ? 'stash' : 'pop'](key);
                    });
                };
                this.#___private_edgeless_1 = (_initProto(this), _init_edgeless(this));
                this.#___private_elementType_2 = _init_elementType(this);
                this.#___private_elements_3 = _init_elements(this);
                this.#___private_textColorButton_4 = _init_textColorButton(this);
            }
        }
    }
}();
export { _EdgelessChangeTextMenu as EdgelessChangeTextMenu };
