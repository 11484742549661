import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
export class SpecBuilder {
    constructor(spec){
        this._value = [
            ...spec
        ];
    }
    setup(flavour, setup) {
        const specIndex = this._value.findIndex((s)=>s.schema.model.flavour === flavour);
        if (specIndex === -1) {
            throw new BlockSuiteError(ErrorCode.ValueNotExists, `BlockSpec not found for ${flavour}`);
        }
        this._value[specIndex] = {
            ...this._value[specIndex]
        };
        const spec = this._value[specIndex];
        const oldSetup = spec.setup;
        spec.setup = (slots, disposableGroup)=>{
            oldSetup?.(slots, disposableGroup);
            setup(slots, disposableGroup);
        };
    }
    get value() {
        return this._value;
    }
}
