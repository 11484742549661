import { isBrowser } from '@affine/env/constant';
import createCache from '@emotion/cache';
export default function createEmotionCache() {
    let insertionPoint;
    if (isBrowser) {
        const emotionInsertionPoint = document.querySelector('meta[name="emotion-insertion-point"]');
        insertionPoint = emotionInsertionPoint ?? undefined;
    }
    return createCache({
        key: 'affine',
        insertionPoint
    });
}
