import { UIEventState } from '../base.js';
export var EventScopeSourceType;
(function(EventScopeSourceType) {
    EventScopeSourceType["Selection"] = "selection";
    EventScopeSourceType["Target"] = "target";
})(EventScopeSourceType || (EventScopeSourceType = {}));
export class EventSourceState extends UIEventState {
    constructor({ event, sourceType }){
        super(event);
        this.type = 'sourceState';
        this.sourceType = sourceType;
    }
}
