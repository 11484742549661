import { assertExists } from '@blocksuite/global/utils';
import { asyncGetRichText, buildPath } from '../../_common/utils/query.js';
export async function onModelTextUpdated(editorHost, model, callback) {
    const richText = await asyncGetRichText(editorHost, model.id);
    assertExists(richText, 'RichText is not ready yet.');
    await richText.updateComplete;
    const inlineEditor = richText.inlineEditor;
    assertExists(inlineEditor, 'Inline editor is not ready yet.');
    inlineEditor.slots.renderComplete.once(()=>{
        if (callback) {
            callback(richText);
        }
    });
}
export async function onModelElementUpdated(editorHost, model, callback) {
    const page = model.doc;
    assertExists(page.root);
    const rootComponent = editorHost.view.viewFromPath('block', [
        page.root.id
    ]);
    if (!rootComponent) return;
    await rootComponent.updateComplete;
    const element = editorHost.view.viewFromPath('block', buildPath(model));
    if (element) callback(element);
}
