function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _CaptionedBlockComponent, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _init__showOverlay, _init_allowEmbed, _init_blobUrl, _init_downloading, _init_error, _init_loading, _initProto;
import { Bound } from '@blocksuite/global/utils';
import { Slice } from '@blocksuite/store';
import { flip, offset } from '@floating-ui/dom';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ref } from 'lit/directives/ref.js';
import { styleMap } from 'lit/directives/style-map.js';
import { CaptionedBlockComponent, HoverController, toast } from '../_common/components/index.js';
import { bindContainerHotkey } from '../_common/components/rich-text/keymap/container.js';
import { EMBED_CARD_HEIGHT, EMBED_CARD_WIDTH } from '../_common/consts.js';
import { AttachmentIcon16, getAttachmentFileIcons } from '../_common/icons/index.js';
import { ThemeObserver } from '../_common/theme/theme-observer.js';
import { humanFileSize } from '../_common/utils/math.js';
import { getEmbedCardIcons } from '../_common/utils/url.js';
import { AttachmentBlockStyles } from './attachment-model.js';
import { AttachmentOptionsTemplate } from './components/options.js';
import { renderEmbedView } from './embed.js';
import { styles } from './styles.js';
import { checkAttachmentBlob, downloadAttachmentBlob } from './utils.js';
let _AttachmentBlockComponent;
_dec = customElement('affine-attachment'), _dec1 = state(), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_AttachmentBlockComponent), _initClass();
    }
    static{
        class AttachmentBlockComponent extends (_CaptionedBlockComponent = CaptionedBlockComponent) {
            static{
                ({ e: [_init__showOverlay, _init_allowEmbed, _init_blobUrl, _init_downloading, _init_error, _init_loading, _initProto], c: [_AttachmentBlockComponent, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_showOverlay"
                    ],
                    [
                        _dec2,
                        1,
                        "allowEmbed"
                    ],
                    [
                        _dec3,
                        1,
                        "blobUrl"
                    ],
                    [
                        _dec4,
                        1,
                        "downloading"
                    ],
                    [
                        _dec5,
                        1,
                        "error"
                    ],
                    [
                        _dec6,
                        1,
                        "loading"
                    ]
                ], [
                    _dec
                ], _CaptionedBlockComponent));
            }
            static{
                this.styles = styles;
            }
            get _embedView() {
                if (this.isInSurface || !this.model.embed || !this.blobUrl) return;
                return renderEmbedView(this.model, this.blobUrl, this.service.maxFileSize);
            }
            _handleClick(event) {
                event.stopPropagation();
                if (!this.isInSurface) {
                    this._selectBlock();
                }
            }
            _handleDoubleClick(event) {
                event.stopPropagation();
                if (this.allowEmbed) {
                    this.open();
                } else {
                    this.download();
                }
            }
            _selectBlock() {
                const selectionManager = this.host.selection;
                const blockSelection = selectionManager.create('block', {
                    blockId: this.blockId
                });
                selectionManager.setGroup('note', [
                    blockSelection
                ]);
            }
            connectedCallback() {
                super.connectedCallback();
                bindContainerHotkey(this);
                this.refreshData();
                this.contentEditable = 'false';
                const parent = this.host.doc.getParent(this.model);
                this._isInSurface = parent?.flavour === 'affine:surface';
                if (!this.model.style) {
                    this.doc.withoutTransact(()=>{
                        this.doc.updateBlock(this.model, {
                            style: AttachmentBlockStyles[1]
                        });
                    });
                }
                this.model.propsUpdated.on(({ key })=>{
                    if (key === 'sourceId') {
                        if (this.blobUrl) {
                            URL.revokeObjectURL(this.blobUrl);
                            this.blobUrl = undefined;
                        }
                        this.refreshData();
                    }
                });
                this.disposables.add(ThemeObserver.subscribe(()=>this.requestUpdate()));
                this.disposables.add(this.std.selection.slots.changed.on(()=>{
                    this._isSelected = !!this.selected?.is('block') || !!this.selected?.is('surface');
                    this._showOverlay = this._isResizing || this._isDragging || !this._isSelected;
                }));
                this.handleEvent('dragStart', ()=>{
                    this._isDragging = true;
                    this._showOverlay = this._isResizing || this._isDragging || !this._isSelected;
                });
                this.handleEvent('dragEnd', ()=>{
                    this._isDragging = false;
                    this._showOverlay = this._isResizing || this._isDragging || !this._isSelected;
                });
                if (this.isInSurface) {
                    if (this.rootService) {
                        this._disposables.add(this.rootService?.slots.elementResizeStart.on(()=>{
                            this._isResizing = true;
                            this._showOverlay = true;
                        }));
                        this._disposables.add(this.rootService.slots.elementResizeEnd.on(()=>{
                            this._isResizing = false;
                            this._showOverlay = this._isResizing || this._isDragging || !this._isSelected;
                        }));
                    }
                    this.style.position = 'absolute';
                }
            }
            disconnectedCallback() {
                if (this.blobUrl) {
                    URL.revokeObjectURL(this.blobUrl);
                }
                super.disconnectedCallback();
            }
            renderBlock() {
                const { name, size, style } = this.model;
                const cardStyle = style ?? AttachmentBlockStyles[1];
                const { LoadingIcon } = getEmbedCardIcons();
                const titleIcon = this.loading ? LoadingIcon : AttachmentIcon16;
                const titleText = this.loading ? 'Loading...' : name;
                const infoText = this.error ? 'File loading failed.' : humanFileSize(size);
                const fileType = name.split('.').pop() ?? '';
                const FileTypeIcon = getAttachmentFileIcons(fileType);
                let containerStyleMap = styleMap({
                    position: 'relative',
                    width: '100%',
                    margin: '18px 0px'
                });
                if (this.isInSurface) {
                    const width = EMBED_CARD_WIDTH[cardStyle];
                    const height = EMBED_CARD_HEIGHT[cardStyle];
                    const bound = Bound.deserialize((this.rootService?.getElementById(this.model.id) ?? this.model).xywh);
                    const scaleX = bound.w / width;
                    const scaleY = bound.h / height;
                    containerStyleMap = styleMap({
                        width: `${width}px`,
                        height: `${height}px`,
                        transform: `scale(${scaleX}, ${scaleY})`,
                        transformOrigin: '0 0'
                    });
                    this.style.width = `${bound.w}px`;
                    this.style.height = `${bound.h}px`;
                    this.style.left = `${bound.x}px`;
                    this.style.top = `${bound.y}px`;
                    this.style.zIndex = `${this.toZIndex()}`;
                }
                const embedView = this._embedView;
                return html`
      <div
        ${this.isInSurface ? nothing : ref(this._whenHover.setReference)}
        class="affine-attachment-container"
        style=${containerStyleMap}
      >
        ${embedView ? html`<div
              class="affine-attachment-embed-container"
              @click=${this._handleClick}
              @dblclick=${this._handleDoubleClick}
            >
              ${embedView}

              <div
                class=${classMap({
                    'affine-attachment-iframe-overlay': true,
                    hide: !this._showOverlay
                })}
              ></div>
            </div>` : html`<div
              class=${classMap({
                    'affine-attachment-card': true,
                    [cardStyle]: true,
                    loading: this.loading,
                    error: this.error,
                    unsynced: false
                })}
              @click=${this._handleClick}
              @dblclick=${this._handleDoubleClick}
            >
              <div class="affine-attachment-content">
                <div class="affine-attachment-content-title">
                  <div class="affine-attachment-content-title-icon">
                    ${titleIcon}
                  </div>

                  <div class="affine-attachment-content-title-text">
                    ${titleText}
                  </div>
                </div>

                <div class="affine-attachment-content-info">${infoText}</div>
              </div>

              <div class="affine-attachment-banner">${FileTypeIcon}</div>
            </div>`}
      </div>
    `;
            }
            toZIndex() {
                return this.rootService?.layer.getZIndex(this.model) ?? 1;
            }
            updateZIndex() {
                this.style.zIndex = `${this.toZIndex()}`;
            }
            get isInSurface() {
                return this._isInSurface;
            }
            get rootService() {
                const service = this.host.spec.getService('affine:page');
                if (!service.surface) {
                    return null;
                }
                return service;
            }
            #___private__showOverlay_1;
            get _showOverlay() {
                return this.#___private__showOverlay_1;
            }
            set _showOverlay(_v) {
                this.#___private__showOverlay_1 = _v;
            }
            #___private_allowEmbed_2;
            get allowEmbed() {
                return this.#___private_allowEmbed_2;
            }
            set allowEmbed(_v) {
                this.#___private_allowEmbed_2 = _v;
            }
            #___private_blobUrl_3;
            get blobUrl() {
                return this.#___private_blobUrl_3;
            }
            set blobUrl(_v) {
                this.#___private_blobUrl_3 = _v;
            }
            #___private_downloading_4;
            get downloading() {
                return this.#___private_downloading_4;
            }
            set downloading(_v) {
                this.#___private_downloading_4 = _v;
            }
            #___private_error_5;
            get error() {
                return this.#___private_error_5;
            }
            set error(_v) {
                this.#___private_error_5 = _v;
            }
            #___private_loading_6;
            get loading() {
                return this.#___private_loading_6;
            }
            set loading(_v) {
                this.#___private_loading_6 = _v;
            }
            #___private_useCaptionEditor_7;
            get useCaptionEditor() {
                return this.#___private_useCaptionEditor_7;
            }
            set useCaptionEditor(_v) {
                this.#___private_useCaptionEditor_7 = _v;
            }
            constructor(...args){
                super(...args);
                this._isDragging = false;
                this._isInSurface = false;
                this._isResizing = false;
                this._isSelected = false;
                this._whenHover = new HoverController(this, ({ abortController })=>{
                    const selection = this.host.selection;
                    const textSelection = selection.find('text');
                    if (!!textSelection && (!!textSelection.to || !!textSelection.from.length)) {
                        return null;
                    }
                    const blockSelections = selection.filter('block');
                    if (blockSelections.length > 1 || blockSelections.length === 1 && blockSelections[0].blockId !== this.blockId) {
                        return null;
                    }
                    return {
                        template: AttachmentOptionsTemplate({
                            anchor: this,
                            model: this.model,
                            showCaption: ()=>this.captionEditor?.show(),
                            copy: this.copy,
                            download: this.download,
                            refresh: this.refreshData,
                            abortController
                        }),
                        computePosition: {
                            referenceElement: this,
                            placement: 'top-start',
                            middleware: [
                                flip(),
                                offset(4)
                            ],
                            autoUpdate: true
                        }
                    };
                });
                this.copy = ()=>{
                    const slice = Slice.fromModels(this.doc, [
                        this.model
                    ]);
                    this.std.clipboard.copySlice(slice).catch(console.error);
                    toast(this.host, 'Copied to clipboard');
                };
                this.download = ()=>{
                    downloadAttachmentBlob(this);
                };
                this.open = ()=>{
                    if (!this.blobUrl) {
                        return;
                    }
                    window.open(this.blobUrl, '_blank');
                };
                this.refreshData = ()=>{
                    checkAttachmentBlob(this).catch(console.error);
                };
                this.#___private__showOverlay_1 = (_initProto(this), _init__showOverlay(this, true));
                this.#___private_allowEmbed_2 = _init_allowEmbed(this, false);
                this.#___private_blobUrl_3 = _init_blobUrl(this, undefined);
                this.#___private_downloading_4 = _init_downloading(this, false);
                this.#___private_error_5 = _init_error(this, false);
                this.#___private_loading_6 = _init_loading(this, false);
                this.#___private_useCaptionEditor_7 = true;
            }
        }
    }
}();
export { _AttachmentBlockComponent as AttachmentBlockComponent };
