import { DebugLogger } from '@affine/debug';
import { isEqual } from 'lodash-es';
import { catchError, EMPTY, exhaustMap, mergeMap } from 'rxjs';
import { Entity } from '../../../framework';
import { effect, fromPromise, LiveData, onComplete, onStart } from '../../../livedata';
const logger = new DebugLogger('affine:workspace-profile');
export class WorkspaceProfile extends Entity {
    get id() {
        return this.props.metadata.id;
    }
    constructor(cache, providers){
        super();
        this.cache = cache;
        this.profile$ = LiveData.from(this.cache.watchProfileCache(this.props.metadata.id), null);
        this.avatar$ = this.profile$.map((v)=>v?.avatar);
        this.name$ = this.profile$.map((v)=>v?.name);
        this.isLoading$ = new LiveData(false);
        this.revalidate = effect(exhaustMap(()=>{
            const provider = this.provider;
            if (!provider) {
                return EMPTY;
            }
            return fromPromise((signal)=>provider.getWorkspaceProfile(this.props.metadata.id, signal)).pipe(mergeMap((info)=>{
                if (info) {
                    this.setProfile({
                        ...this.profile$.value,
                        ...info
                    });
                }
                return EMPTY;
            }), catchError((err)=>{
                logger.error(err);
                return EMPTY;
            }), onStart(()=>this.isLoading$.next(true)), onComplete(()=>this.isLoading$.next(false)));
        }));
        this.provider = providers.find((p)=>p.flavour === this.props.metadata.flavour) ?? null;
    }
    setProfile(info) {
        if (isEqual(this.profile$.value, info)) {
            return;
        }
        this.cache.setProfileCache(this.props.metadata.id, info);
    }
    syncWithWorkspace(workspace) {
        workspace.name$.subscribe((name)=>{
            const old = this.profile$.value;
            this.setProfile({
                ...old,
                name: name ?? old?.name
            });
        });
        workspace.avatar$.subscribe((avatar)=>{
            const old = this.profile$.value;
            this.setProfile({
                ...old,
                avatar: avatar ?? old?.avatar
            });
        });
    }
}
