import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import { nothing } from 'lit';
import { BlockComponent } from './block-component.js';
export const GfxElementSymbol = Symbol('GfxElement');
export class GfxBlockComponent extends BlockComponent {
    connectedCallback() {
        super.connectedCallback();
        this.style.position = 'absolute';
    }
    getRenderingRect() {
        const { xywh$ } = this.model;
        if (!xywh$) {
            throw new BlockSuiteError(ErrorCode.GfxBlockElementError, 'Gfx block element should have `xywh` property.');
        }
        const [x, y, w, h] = JSON.parse(xywh$.value);
        return {
            x,
            y,
            w,
            h,
            zIndex: this.toZIndex()
        };
    }
    renderBlock() {
        const { x, y, w, h, zIndex } = this.getRenderingRect();
        this.style.left = `${x}px`;
        this.style.top = `${y}px`;
        this.style.width = `${w}px`;
        this.style.height = `${h}px`;
        this.style.zIndex = zIndex;
        return this.renderGfxBlock();
    }
    renderGfxBlock() {
        return nothing;
    }
    renderPageContent() {
        return nothing;
    }
    async scheduleUpdate() {
        const parent = this.parentElement;
        if (this.hasUpdated || !parent || !('scheduleUpdateChildren' in parent)) {
            super.scheduleUpdate();
        } else {
            await parent.scheduleUpdateChildren(this.model.id);
            super.scheduleUpdate();
        }
    }
    toZIndex() {
        return `${1}`;
    }
    updateZIndex() {
        this.style.zIndex = this.toZIndex();
    }
    get rootService() {
        return this.host.spec.getService(this.rootServiceFlavour);
    }
    constructor(...args){
        super(...args);
        this[GfxElementSymbol] = true;
    }
}
export function toGfxBlockComponent(CustomBlock) {
    return class extends CustomBlock {
        connectedCallback() {
            super.connectedCallback();
            this.style.position = 'absolute';
        }
        getRenderingRect() {
            const { xywh$ } = this.model;
            if (!xywh$) {
                throw new BlockSuiteError(ErrorCode.GfxBlockElementError, 'Gfx block element should have `xywh` property.');
            }
            const [x, y, w, h] = JSON.parse(xywh$.value);
            return {
                x,
                y,
                w,
                h,
                zIndex: this.toZIndex()
            };
        }
        renderBlock() {
            const { xywh, index } = this.model;
            if (!xywh || !index) {
                throw new BlockSuiteError(ErrorCode.GfxBlockElementError, 'Gfx block element should have `xywh` and `index` props.');
            }
            const { x, y, w, h, zIndex } = this.getRenderingRect();
            this.style.left = `${x}px`;
            this.style.top = `${y}px`;
            this.style.width = typeof w === 'number' ? `${w}px` : w;
            this.style.height = typeof h === 'number' ? `${h}px` : h;
            this.style.zIndex = zIndex;
            return this.renderGfxBlock();
        }
        renderGfxBlock() {
            return this.renderPageContent();
        }
        renderPageContent() {
            return super.renderBlock();
        }
        async scheduleUpdate() {
            const parent = this.parentElement;
            if (this.hasUpdated || !parent || !('scheduleUpdateChildren' in parent)) {
                super.scheduleUpdate();
            } else {
                await parent.scheduleUpdateChildren(this.model.id);
                super.scheduleUpdate();
            }
        }
        toZIndex() {
            return `${1}`;
        }
        updateZIndex() {
            this.style.zIndex = this.toZIndex();
        }
        get rootService() {
            return this.host.spec.getService(this.rootServiceFlavour);
        }
        constructor(...args){
            super(...args);
            this[GfxElementSymbol] = true;
        }
    };
}
