import { GraphQLError as BaseGraphQLError } from 'graphql';
import { ErrorNames } from './schema';
export class UserFriendlyError {
    static fromAnyError(response) {
        if (response instanceof GraphQLError) {
            return new UserFriendlyError(response.extensions);
        }
        if (response && typeof response === 'object' && response.type && response.name) {
            return new UserFriendlyError(response);
        }
        return new UserFriendlyError({
            status: 500,
            code: 'INTERNAL_SERVER_ERROR',
            type: 'INTERNAL_SERVER_ERROR',
            name: ErrorNames.INTERNAL_SERVER_ERROR,
            message: 'Internal server error'
        });
    }
    constructor(response){
        this.response = response;
        this.status = this.response.status;
        this.code = this.response.code;
        this.type = this.response.type;
        this.name = this.response.name;
        this.message = this.response.message;
        this.args = this.response.args;
        this.stacktrace = this.response.stacktrace;
    }
}
export class GraphQLError extends BaseGraphQLError {
}
