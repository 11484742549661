import { Slot } from '@blocksuite/store';
import { RootService } from '../root-service.js';
export class PageRootService extends RootService {
    constructor(...args){
        super(...args);
        this.slots = {
            docLinkClicked: new Slot(),
            tagClicked: new Slot(),
            viewportUpdated: new Slot()
        };
    }
}
