import { DebugLogger } from '@affine/debug';
import { DocCollection } from '@blocksuite/store';
export { DocCollection };
export var WorkspaceSubPath;
(function(WorkspaceSubPath) {
    WorkspaceSubPath["ALL"] = "all";
    WorkspaceSubPath["TRASH"] = "trash";
    WorkspaceSubPath["SHARED"] = "shared";
    WorkspaceSubPath["Later"] = "later";
    WorkspaceSubPath["Archive"] = "archive";
    WorkspaceSubPath["Home"] = "home";
})(WorkspaceSubPath || (WorkspaceSubPath = {}));
export const WorkspaceSubPathName = {
    ["all"]: 'All Pages',
    ["trash"]: 'Trash',
    ["shared"]: 'Shared',
    ["later"]: 'Later',
    ["archive"]: 'Archive',
    ["home"]: 'Home'
};
export const pathGenerator = {
    all: (workspaceId)=>`/workspace/${workspaceId}/all`,
    trash: (workspaceId)=>`/workspace/${workspaceId}/trash`,
    shared: (workspaceId)=>`/workspace/${workspaceId}/shared`,
    later: (workspaceId)=>`/workspace/${workspaceId}/later`,
    archive: (workspaceId)=>`/workspace/${workspaceId}/archive`,
    home: (workspaceId)=>`/workspace/${workspaceId}/home`
};
export const performanceLogger = new DebugLogger('performance');
export const performanceRenderLogger = performanceLogger.namespace('render');
