import { i18nTime } from '@affine/i18n';
import { initEmptyPage, useService } from '@toeverything/infra';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { WorkbenchService } from '../modules/workbench';
import { useCurrentWorkspacePropertiesAdapter } from './use-affine-adapter';
import { useDocCollectionHelper } from './use-block-suite-workspace-helper';
export const JOURNAL_DATE_FORMAT = 'YYYY-MM-DD';
function isJournalString(j) {
    return j ? !!j?.match(/^\d{4}-\d{2}-\d{2}$/) : false;
}
function toDayjs(j) {
    if (!j || !isJournalString(j)) return null;
    const day = dayjs(j);
    if (!day.isValid()) return null;
    return day;
}
export const useJournalHelper = (docCollection)=>{
    const bsWorkspaceHelper = useDocCollectionHelper(docCollection);
    const adapter = useCurrentWorkspacePropertiesAdapter();
    const _createJournal = useCallback((maybeDate)=>{
        const day = dayjs(maybeDate);
        const title = day.format(JOURNAL_DATE_FORMAT);
        const page = bsWorkspaceHelper.createDoc();
        page.collection.setDocMeta(page.id, {
            createDate: dayjs().set('year', day.year()).set('month', day.month()).set('date', day.date()).toDate().getTime()
        });
        initEmptyPage(page, title);
        adapter.setJournalPageDateString(page.id, title);
        return page;
    }, [
        adapter,
        bsWorkspaceHelper
    ]);
    const isPageJournal = useCallback((pageId)=>{
        return !!adapter.getJournalPageDateString(pageId);
    }, [
        adapter
    ]);
    const getJournalsByDate = useCallback((maybeDate)=>{
        const day = dayjs(maybeDate);
        return Array.from(docCollection.docs.values()).map((blockCollection)=>blockCollection.getDoc()).filter((page)=>{
            const pageId = page.id;
            if (!isPageJournal(pageId)) return false;
            if (page.meta?.trash) return false;
            const journalDate = adapter.getJournalPageDateString(page.id);
            if (!journalDate) return false;
            return day.isSame(journalDate, 'day');
        });
    }, [
        adapter,
        isPageJournal,
        docCollection.docs
    ]);
    const getJournalByDate = useCallback((maybeDate)=>{
        const pages = getJournalsByDate(maybeDate);
        if (pages.length) return pages[0];
        return _createJournal(maybeDate);
    }, [
        _createJournal,
        getJournalsByDate
    ]);
    const isPageTodayJournal = useCallback((pageId)=>{
        const date = dayjs().format(JOURNAL_DATE_FORMAT);
        const d = adapter.getJournalPageDateString(pageId);
        return isPageJournal(pageId) && d === date;
    }, [
        adapter,
        isPageJournal
    ]);
    const getJournalDateString = useCallback((pageId)=>{
        return adapter.getJournalPageDateString(pageId);
    }, [
        adapter
    ]);
    const getLocalizedJournalDateString = useCallback((pageId)=>{
        const journalDateString = getJournalDateString(pageId);
        if (!journalDateString) return null;
        return i18nTime(journalDateString, {
            absolute: {
                accuracy: 'day'
            }
        });
    }, [
        getJournalDateString
    ]);
    const appendContentToToday = useCallback(async (content)=>{
        if (!content) return;
        const page = getJournalByDate(dayjs().format(JOURNAL_DATE_FORMAT));
        if (!page) return;
        const blockId = page.addBlock('affine:paragraph', {
            text: new page.Text(content)
        }, page.getBlockByFlavour('affine:note')[0].id);
        return {
            page,
            blockId
        };
    }, [
        getJournalByDate
    ]);
    return useMemo(()=>({
            getJournalsByDate,
            getJournalByDate,
            getJournalDateString,
            getLocalizedJournalDateString,
            isPageJournal,
            isPageTodayJournal,
            appendContentToToday
        }), [
        getJournalByDate,
        getJournalDateString,
        getJournalsByDate,
        getLocalizedJournalDateString,
        isPageJournal,
        isPageTodayJournal,
        appendContentToToday
    ]);
};
export const useJournalRouteHelper = (docCollection)=>{
    const { getJournalByDate } = useJournalHelper(docCollection);
    const workbench = useService(WorkbenchService).workbench;
    const openJournal = useCallback((maybeDate, newTab)=>{
        const page = getJournalByDate(maybeDate);
        workbench.openDoc(page.id, {
            at: newTab ? 'new-tab' : 'active'
        });
        return page.id;
    }, [
        getJournalByDate,
        workbench
    ]);
    const openToday = useCallback((newTab)=>{
        const date = dayjs().format(JOURNAL_DATE_FORMAT);
        return openJournal(date, newTab);
    }, [
        openJournal
    ]);
    return useMemo(()=>({
            openJournal,
            openToday
        }), [
        openJournal,
        openToday
    ]);
};
export const useJournalInfoHelper = (docCollection, pageId)=>{
    const { isPageJournal, getJournalDateString, getLocalizedJournalDateString, isPageTodayJournal } = useJournalHelper(docCollection);
    return useMemo(()=>({
            isJournal: pageId ? isPageJournal(pageId) : false,
            journalDate: pageId ? toDayjs(getJournalDateString(pageId)) : null,
            localizedJournalDate: pageId ? getLocalizedJournalDateString(pageId) : null,
            isTodayJournal: pageId ? isPageTodayJournal(pageId) : false
        }), [
        getJournalDateString,
        getLocalizedJournalDateString,
        isPageJournal,
        isPageTodayJournal,
        pageId
    ]);
};
