import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseIcon } from '@blocksuite/icons/rc';
import * as Dialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import clsx from 'clsx';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { IconButton } from '../button';
import * as styles from './styles.css';
const getVar = (style = '', defaultValue = '')=>{
    return style ? typeof style === 'number' ? `${style}px` : style : defaultValue;
};
class ModalTransitionContainer extends HTMLElement {
    removeChild(child) {
        if (typeof document.startViewTransition === 'function') {
            this.pendingTransitionNodes.push(child);
            this.requestTransition();
            return child;
        } else {
            return super.removeChild(child);
        }
    }
    requestTransition() {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }
        this.animationFrame = requestAnimationFrame(()=>{
            if (typeof document.startViewTransition === 'function') {
                const nodes = this.pendingTransitionNodes;
                document.startViewTransition(()=>{
                    nodes.forEach((child)=>{
                        super.removeChild(child);
                    });
                });
                this.pendingTransitionNodes = [];
            }
        });
    }
    constructor(...args){
        super(...args);
        this.pendingTransitionNodes = [];
        this.animationFrame = null;
    }
}
let defined = false;
function createContainer() {
    if (!defined) {
        customElements.define('modal-transition-container', ModalTransitionContainer);
        defined = true;
    }
    const container = new ModalTransitionContainer();
    document.body.append(container);
    return container;
}
export const ModalInner = forwardRef((props, ref)=>{
    const { modal, portalOptions, open, onOpenChange, width, height, minHeight = 194, title, description, withoutCloseButton = false, persistent, contentOptions: { style: contentStyle, className: contentClassName, onPointerDownOutside, onEscapeKeyDown, ...otherContentOptions } = {}, overlayOptions: { className: overlayClassName, style: overlayStyle, ...otherOverlayOptions } = {}, closeButtonOptions, children, ...otherProps } = props;
    const { className: closeButtonClassName, ...otherCloseButtonProps } = closeButtonOptions || {};
    const [container, setContainer] = useState(null);
    useEffect(()=>{
        const container = createContainer();
        setContainer(container);
        return ()=>{
            setTimeout(()=>{
                container.remove();
            }, 1000);
        };
    }, []);
    const handlePointerDownOutSide = useCallback((e)=>{
        onPointerDownOutside?.(e);
        persistent && e.preventDefault();
    }, [
        onPointerDownOutside,
        persistent
    ]);
    const handleEscapeKeyDown = useCallback((e)=>{
        onEscapeKeyDown?.(e);
        persistent && e.preventDefault();
    }, [
        onEscapeKeyDown,
        persistent
    ]);
    if (!container) {
        return;
    }
    return _jsx(Dialog.Root, {
        modal: modal,
        open: open,
        onOpenChange: onOpenChange,
        ...otherProps,
        children: _jsxs(Dialog.Portal, {
            container: container,
            ...portalOptions,
            children: [
                _jsx(Dialog.Overlay, {
                    className: clsx(styles.modalOverlay, overlayClassName),
                    style: {
                        ...overlayStyle
                    },
                    ...otherOverlayOptions
                }),
                _jsx("div", {
                    "data-modal": modal,
                    className: clsx(styles.modalContentWrapper),
                    children: _jsxs(Dialog.Content, {
                        onPointerDownOutside: handlePointerDownOutSide,
                        onEscapeKeyDown: handleEscapeKeyDown,
                        className: clsx(styles.modalContent, contentClassName),
                        style: {
                            ...assignInlineVars({
                                [styles.widthVar]: getVar(width, '50vw'),
                                [styles.heightVar]: getVar(height, 'unset'),
                                [styles.minHeightVar]: getVar(minHeight, '26px')
                            }),
                            ...contentStyle
                        },
                        ...description ? {} : {
                            'aria-describedby': undefined
                        },
                        ...otherContentOptions,
                        ref: ref,
                        children: [
                            withoutCloseButton ? null : _jsx(Dialog.Close, {
                                asChild: true,
                                children: _jsx(IconButton, {
                                    size: "20",
                                    className: clsx(styles.closeButton, closeButtonClassName),
                                    "aria-label": "Close",
                                    "data-testid": "modal-close-button",
                                    ...otherCloseButtonProps,
                                    children: _jsx(CloseIcon, {})
                                })
                            }),
                            title ? _jsx(Dialog.Title, {
                                className: styles.modalHeader,
                                children: title
                            }) : _jsx(VisuallyHidden.Root, {
                                asChild: true,
                                children: _jsx(Dialog.Title, {})
                            }),
                            description ? _jsx(Dialog.Description, {
                                className: styles.modalDescription,
                                children: description
                            }) : null,
                            children
                        ]
                    })
                })
            ]
        })
    });
});
ModalInner.displayName = 'ModalInner';
export const Modal = forwardRef((props, ref)=>{
    if (!props.open) {
        return;
    }
    return _jsx(ModalInner, {
        ...props,
        ref: ref
    });
});
Modal.displayName = 'Modal';
