function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _init__isHeightLimit, _init__isResizing, _init__isWidthLimit, _init__mode, _init__scaleDirection, _init__scalePercent, _init__selectedRect, _init__shiftKey, _init_autoCompleteOff, _init_edgeless, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { deserializeXYWH } from '@blocksuite/global/utils';
import { Bound } from '@blocksuite/global/utils';
import { Slot, assertType } from '@blocksuite/global/utils';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { EMBED_CARD_HEIGHT } from '../../../../_common/consts.js';
import { isMindmapNode } from '../../../../_common/edgeless/mindmap/index.js';
import { requestThrottledConnectedFrame, stopPropagation } from '../../../../_common/utils/event.js';
import { pickValues } from '../../../../_common/utils/iterable.js';
import { clamp } from '../../../../_common/utils/math.js';
import { EDGELESS_TEXT_BLOCK_MIN_WIDTH } from '../../../../edgeless-text/edgeless-text-block.js';
import { EMBED_HTML_MIN_HEIGHT, EMBED_HTML_MIN_WIDTH } from '../../../../embed-html-block/styles.js';
import { SYNCED_MIN_HEIGHT, SYNCED_MIN_WIDTH } from '../../../../embed-synced-doc-block/styles.js';
import { NoteBlockModel } from '../../../../note-block/note-model.js';
import { normalizeTextBound } from '../../../../surface-block/canvas-renderer/element-renderer/text/utils.js';
import { TextElementModel } from '../../../../surface-block/element-model/text.js';
import { CanvasElementType, GroupElementModel, ShapeElementModel } from '../../../../surface-block/index.js';
import { ConnectorElementModel, normalizeDegAngle, normalizeShapeBound } from '../../../../surface-block/index.js';
import { AI_CHAT_BLOCK_MAX_HEIGHT, AI_CHAT_BLOCK_MAX_WIDTH, AI_CHAT_BLOCK_MIN_HEIGHT, AI_CHAT_BLOCK_MIN_WIDTH, NOTE_MIN_HEIGHT, NOTE_MIN_WIDTH } from '../../utils/consts.js';
import { getElementsWithoutGroup } from '../../utils/group.js';
import { getSelectableBounds, getSelectedRect, isAIChatBlock, isAttachmentBlock, isBookmarkBlock, isCanvasElement, isEdgelessTextBlock, isEmbedFigmaBlock, isEmbedGithubBlock, isEmbedHtmlBlock, isEmbedLinkedDocBlock, isEmbedLoomBlock, isEmbedSyncedDocBlock, isEmbedYoutubeBlock, isEmbeddedBlock, isFrameBlock, isImageBlock, isNoteBlock } from '../../utils/query.js';
import '../auto-complete/edgeless-auto-complete.js';
import '../connector/connector-handle.js';
import { HandleDirection } from '../resize/resize-handles.js';
import { ResizeHandles } from '../resize/resize-handles.js';
import { HandleResizeManager } from '../resize/resize-manager.js';
import { calcAngle, calcAngleEdgeWithRotation, calcAngleWithRotation, generateCursorUrl, getResizeLabel, rotateResizeCursor } from '../utils.js';
let _EdgelessSelectedRect;
_dec = customElement('edgeless-selected-rect'), _dec1 = state(), _dec2 = state(), _dec3 = state(), _dec4 = state(), _dec5 = state(), _dec6 = state(), _dec7 = state(), _dec8 = state(), _dec9 = state(), _dec10 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EdgelessSelectedRect), _initClass();
    }
    static{
        class EdgelessSelectedRect extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init__isHeightLimit, _init__isResizing, _init__isWidthLimit, _init__mode, _init__scaleDirection, _init__scalePercent, _init__selectedRect, _init__shiftKey, _init_autoCompleteOff, _init_edgeless, _initProto], c: [_EdgelessSelectedRect, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_isHeightLimit"
                    ],
                    [
                        _dec2,
                        1,
                        "_isResizing"
                    ],
                    [
                        _dec3,
                        1,
                        "_isWidthLimit"
                    ],
                    [
                        _dec4,
                        1,
                        "_mode"
                    ],
                    [
                        _dec5,
                        1,
                        "_scaleDirection"
                    ],
                    [
                        _dec6,
                        1,
                        "_scalePercent"
                    ],
                    [
                        _dec7,
                        1,
                        "_selectedRect"
                    ],
                    [
                        _dec8,
                        1,
                        "_shiftKey"
                    ],
                    [
                        _dec9,
                        1,
                        "autoCompleteOff"
                    ],
                    [
                        _dec10,
                        1,
                        "edgeless"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.enabledWarnings = [];
            }
            static{
                this.styles = css`
    :host {
      display: block;
      user-select: none;
      contain: size layout;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .affine-edgeless-selected-rect {
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: center center;
      border-radius: 0;
      pointer-events: none;
      box-sizing: border-box;
      z-index: 1;
      border-color: var(--affine-blue);
      border-width: var(--affine-border-width);
      border-style: solid;
      transform: translate(0, 0) rotate(0);
    }

    .affine-edgeless-selected-rect .handle {
      position: absolute;
      user-select: none;
      outline: none;
      pointer-events: auto;

      /**
       * Fix: pointerEvent stops firing after a short time.
       * When a gesture is started, the browser intersects the touch-action values of the touched element and its ancestors,
       * up to the one that implements the gesture (in other words, the first containing scrolling element)
       * https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action
       */
      touch-action: none;
    }

    .affine-edgeless-selected-rect .handle[aria-label^='top-'],
    .affine-edgeless-selected-rect .handle[aria-label^='bottom-'] {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      z-index: 10;
    }

    .affine-edgeless-selected-rect .handle[aria-label^='top-'] .resize,
    .affine-edgeless-selected-rect .handle[aria-label^='bottom-'] .resize {
      position: absolute;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 2px var(--affine-blue) solid;
      background: white;
    }

    .affine-edgeless-selected-rect .handle[aria-label^='top-'] .rotate,
    .affine-edgeless-selected-rect .handle[aria-label^='bottom-'] .rotate {
      position: absolute;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      background: transparent;
    }

    /* -18 + 6.5 */
    .affine-edgeless-selected-rect .handle[aria-label='top-left'] {
      left: -12px;
      top: -12px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='top-left'] .resize {
      right: 0;
      bottom: 0;
    }
    .affine-edgeless-selected-rect .handle[aria-label='top-left'] .rotate {
      right: 6px;
      bottom: 6px;
    }

    .affine-edgeless-selected-rect .handle[aria-label='top-right'] {
      top: -12px;
      right: -12px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='top-right'] .resize {
      left: 0;
      bottom: 0;
    }
    .affine-edgeless-selected-rect .handle[aria-label='top-right'] .rotate {
      left: 6px;
      bottom: 6px;
    }

    .affine-edgeless-selected-rect .handle[aria-label='bottom-right'] {
      right: -12px;
      bottom: -12px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='bottom-right'] .resize {
      left: 0;
      top: 0;
    }
    .affine-edgeless-selected-rect .handle[aria-label='bottom-right'] .rotate {
      left: 6px;
      top: 6px;
    }

    .affine-edgeless-selected-rect .handle[aria-label='bottom-left'] {
      bottom: -12px;
      left: -12px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='bottom-left'] .resize {
      right: 0;
      top: 0;
    }
    .affine-edgeless-selected-rect .handle[aria-label='bottom-left'] .rotate {
      right: 6px;
      top: 6px;
    }

    .affine-edgeless-selected-rect .handle[aria-label='top'],
    .affine-edgeless-selected-rect .handle[aria-label='bottom'],
    .affine-edgeless-selected-rect .handle[aria-label='left'],
    .affine-edgeless-selected-rect .handle[aria-label='right'] {
      border: 0;
      background: transparent;
      border-color: var('--affine-blue');
    }

    .affine-edgeless-selected-rect .handle[aria-label='left'],
    .affine-edgeless-selected-rect .handle[aria-label='right'] {
      top: 0;
      bottom: 0;
      height: 100%;
      width: 6px;
    }

    .affine-edgeless-selected-rect .handle[aria-label='top'],
    .affine-edgeless-selected-rect .handle[aria-label='bottom'] {
      left: 0;
      right: 0;
      width: 100%;
      height: 6px;
    }

    /* calc(-1px - (6px - 1px) / 2) = -3.5px */
    .affine-edgeless-selected-rect .handle[aria-label='left'] {
      left: -3.5px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='right'] {
      right: -3.5px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='top'] {
      top: -3.5px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='bottom'] {
      bottom: -3.5px;
    }

    .affine-edgeless-selected-rect .handle[aria-label='top'] .resize,
    .affine-edgeless-selected-rect .handle[aria-label='bottom'] .resize,
    .affine-edgeless-selected-rect .handle[aria-label='left'] .resize,
    .affine-edgeless-selected-rect .handle[aria-label='right'] .resize {
      width: 100%;
      height: 100%;
    }

    .affine-edgeless-selected-rect .handle[aria-label='top'] .resize:after,
    .affine-edgeless-selected-rect .handle[aria-label='bottom'] .resize:after,
    .affine-edgeless-selected-rect .handle[aria-label='left'] .resize:after,
    .affine-edgeless-selected-rect .handle[aria-label='right'] .resize:after {
      position: absolute;
      width: 7px;
      height: 7px;
      box-sizing: border-box;
      border-radius: 6px;
      z-index: 10;
      content: '';
      background: white;
    }

    .affine-edgeless-selected-rect
      .handle[aria-label='top']
      .transparent-handle:after,
    .affine-edgeless-selected-rect
      .handle[aria-label='bottom']
      .transparent-handle:after,
    .affine-edgeless-selected-rect
      .handle[aria-label='left']
      .transparent-handle:after,
    .affine-edgeless-selected-rect
      .handle[aria-label='right']
      .transparent-handle:after {
      opacity: 0;
    }

    .affine-edgeless-selected-rect .handle[aria-label='left'] .resize:after,
    .affine-edgeless-selected-rect .handle[aria-label='right'] .resize:after {
      top: calc(50% - 6px);
    }

    .affine-edgeless-selected-rect .handle[aria-label='top'] .resize:after,
    .affine-edgeless-selected-rect .handle[aria-label='bottom'] .resize:after {
      left: calc(50% - 6px);
    }

    .affine-edgeless-selected-rect .handle[aria-label='left'] .resize:after {
      left: -0.5px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='right'] .resize:after {
      right: -0.5px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='top'] .resize:after {
      top: -0.5px;
    }
    .affine-edgeless-selected-rect .handle[aria-label='bottom'] .resize:after {
      bottom: -0.5px;
    }

    .affine-edgeless-selected-rect .handle .resize::before {
      content: '';
      display: none;
      position: absolute;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23 3H19C10.1634 3 3 10.1634 3 19V23' stroke='black' stroke-opacity='0.3' stroke-width='5' stroke-linecap='round'/%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
    }
    .affine-edgeless-selected-rect[data-mode='scale']
      .handle[aria-label='top-left']
      .resize:hover::before,
    .affine-edgeless-selected-rect[data-scale-direction='top-left'][data-scale-percent]
      .handle[aria-label='top-left']
      .resize::before {
      display: block;
      top: 0px;
      left: 0px;
      transform: translate(-100%, -100%);
    }
    .affine-edgeless-selected-rect[data-mode='scale']
      .handle[aria-label='top-right']
      .resize:hover::before,
    .affine-edgeless-selected-rect[data-scale-direction='top-right'][data-scale-percent]
      .handle[aria-label='top-right']
      .resize::before {
      display: block;
      top: 0px;
      right: 0px;
      transform: translate(100%, -100%) rotate(90deg);
    }
    .affine-edgeless-selected-rect[data-mode='scale']
      .handle[aria-label='bottom-right']
      .resize:hover::before,
    .affine-edgeless-selected-rect[data-scale-direction='bottom-right'][data-scale-percent]
      .handle[aria-label='bottom-right']
      .resize::before {
      display: block;
      bottom: 0px;
      right: 0px;
      transform: translate(100%, 100%) rotate(180deg);
    }
    .affine-edgeless-selected-rect[data-mode='scale']
      .handle[aria-label='bottom-left']
      .resize:hover::before,
    .affine-edgeless-selected-rect[data-scale-direction='bottom-left'][data-scale-percent]
      .handle[aria-label='bottom-left']
      .resize::before {
      display: block;
      bottom: 0px;
      left: 0px;
      transform: translate(-100%, 100%) rotate(-90deg);
    }

    .affine-edgeless-selected-rect::after {
      content: attr(data-scale-percent);
      display: none;
      position: absolute;
      color: var(--affine-icon-color);
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: var(--affine-font-family);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .affine-edgeless-selected-rect[data-scale-direction='top-left']::after {
      display: block;
      top: -20px;
      left: -20px;
      transform: translate(-100%, -100%);
    }
    .affine-edgeless-selected-rect[data-scale-direction='top-right']::after {
      display: block;
      top: -20px;
      right: -20px;
      transform: translate(100%, -100%);
    }
    .affine-edgeless-selected-rect[data-scale-direction='bottom-right']::after {
      display: block;
      bottom: -20px;
      right: -20px;
      transform: translate(100%, 100%);
    }
    .affine-edgeless-selected-rect[data-scale-direction='bottom-left']::after {
      display: block;
      bottom: -20px;
      left: -20px;
      transform: translate(-100%, 100%);
    }
  `;
            }
            constructor(){
                super();
                this._cursorRotate = 0;
                this._dragEndCallback = [];
                this._initSelectedSlot = ()=>{
                    this._propDisposables.forEach((disposable)=>disposable.dispose());
                    this._propDisposables = [];
                    this.selection.selectedElements.forEach((element)=>{
                        if ('flavour' in element) {
                            this._propDisposables.push(element.propsUpdated.on(()=>{
                                this._updateOnElementChange(element.id);
                            }));
                        }
                    });
                };
                this._onDragEnd = ()=>{
                    this.slots.dragEnd.emit();
                    this.doc.transact(()=>{
                        this._dragEndCallback.forEach((cb)=>cb());
                    });
                    this._dragEndCallback = [];
                    this._isWidthLimit = false;
                    this._isHeightLimit = false;
                    this._updateCursor(false);
                    this._scalePercent = undefined;
                    this._scaleDirection = undefined;
                    this._updateMode();
                    this.edgeless.slots.elementResizeEnd.emit();
                };
                this._onDragMove = (newBounds, direction)=>{
                    this.slots.dragMove.emit();
                    const { edgeless } = this;
                    newBounds.forEach(({ bound, matrix, path }, id)=>{
                        const element = edgeless.service.getElementById(id);
                        if (!element) return;
                        if (isNoteBlock(element)) {
                            this.#adjustNote(element, bound, direction);
                            return;
                        }
                        if (isEdgelessTextBlock(element)) {
                            this.#adjustEdgelessText(element, bound, direction);
                            return;
                        }
                        if (isEmbedSyncedDocBlock(element)) {
                            this.#adjustEmbedSyncedDoc(element, bound, direction);
                            return;
                        }
                        if (isEmbedHtmlBlock(element)) {
                            this.#adjustEmbedHtml(element, bound, direction);
                            return;
                        }
                        if (isAIChatBlock(element)) {
                            this.#adjustAIChat(element, bound, direction);
                            return;
                        }
                        if (this._isProportionalElement(element)) {
                            this.#adjustProportional(element, bound, direction);
                            return;
                        }
                        if (element instanceof TextElementModel) {
                            this.#adjustText(element, bound, direction);
                            return;
                        }
                        if (element instanceof ShapeElementModel) {
                            this.#adjustShape(element, bound, direction);
                            return;
                        }
                        if (element instanceof ConnectorElementModel && matrix && path) {
                            this.#adjustConnector(element, bound, matrix, path);
                            return;
                        }
                        this.#adjustUseFallback(element, bound, direction);
                    });
                };
                this._onDragRotate = (center, delta)=>{
                    this.slots.dragRotate.emit();
                    const { selection } = this;
                    const m = new DOMMatrix().translateSelf(center.x, center.y).rotateSelf(delta).translateSelf(-center.x, -center.y);
                    const elements = selection.selectedElements.filter((element)=>isImageBlock(element) || isEdgelessTextBlock(element) || isCanvasElement(element));
                    getElementsWithoutGroup(elements).forEach((element)=>{
                        const { id, rotate } = element;
                        const bounds = Bound.deserialize(element.xywh);
                        const originalCenter = bounds.center;
                        const point = new DOMPoint(...originalCenter).matrixTransform(m);
                        bounds.center = [
                            point.x,
                            point.y
                        ];
                        if (isCanvasElement(element) && element instanceof ConnectorElementModel) {
                            this.#adjustConnector(element, bounds, m, element.absolutePath.map((p)=>p.clone()));
                        } else {
                            this.edgeless.service.updateElement(id, {
                                xywh: bounds.serialize(),
                                rotate: normalizeDegAngle(rotate + delta)
                            });
                        }
                    });
                    this._updateCursor(true, {
                        type: 'rotate',
                        angle: delta
                    });
                    this._updateMode();
                };
                this._onDragStart = ()=>{
                    this.slots.dragStart.emit();
                    const rotation = this._resizeManager.rotation;
                    this._dragEndCallback = [];
                    this.edgeless.slots.elementResizeStart.emit();
                    this.selection.selectedElements.forEach((el)=>{
                        el.stash('xywh');
                        if (el instanceof NoteBlockModel) {
                            el.stash('edgeless');
                        }
                        if (rotation) {
                            el.stash('rotate');
                        }
                        if (el instanceof TextElementModel && !rotation) {
                            el.stash('fontSize');
                            el.stash('hasMaxWidth');
                        }
                        this._dragEndCallback.push(()=>{
                            el.pop('xywh');
                            if (el instanceof NoteBlockModel) {
                                el.pop('edgeless');
                            }
                            if (rotation) {
                                el.pop('rotate');
                            }
                            if (el instanceof TextElementModel && !rotation) {
                                el.pop('fontSize');
                                el.pop('hasMaxWidth');
                            }
                        });
                    });
                    this._updateResizeManagerState(true);
                };
                this._propDisposables = [];
                this._updateCursor = (dragging, options)=>{
                    let cursor = 'default';
                    if (dragging && options) {
                        const { type, target, point } = options;
                        let { angle } = options;
                        if (type === 'rotate') {
                            if (target && point) {
                                angle = calcAngle(target, point, 45);
                            }
                            this._cursorRotate += angle || 0;
                            cursor = generateCursorUrl(this._cursorRotate).toString();
                        } else {
                            if (this.resizeMode === 'edge') {
                                cursor = 'ew';
                            } else if (target && point) {
                                const label = getResizeLabel(target);
                                const { width, height, left, top } = this._selectedRect;
                                if (label === 'top' || label === 'bottom' || label === 'left' || label === 'right') {
                                    angle = calcAngleEdgeWithRotation(target, this._selectedRect.rotate);
                                } else {
                                    angle = calcAngleWithRotation(target, point, new DOMRect(left + this.edgeless.viewport.left, top + this.edgeless.viewport.top, width, height), this._selectedRect.rotate);
                                }
                                cursor = rotateResizeCursor(angle * Math.PI / 180);
                            }
                            cursor += '-resize';
                        }
                    } else {
                        this._cursorRotate = 0;
                    }
                    this.edgelessSlots.cursorUpdated.emit(cursor);
                };
                this._updateMode = ()=>{
                    if (this._cursorRotate) {
                        this._mode = 'rotate';
                        return;
                    }
                    const { selection } = this;
                    const elements = selection.selectedElements;
                    if (elements.length !== 1) this._mode = 'scale';
                    const element = elements[0];
                    if (isNoteBlock(element) || isEmbedSyncedDocBlock(element)) {
                        this._mode = this._shiftKey ? 'scale' : 'resize';
                    } else if (this._isProportionalElement(element)) {
                        this._mode = 'scale';
                    } else {
                        this._mode = 'resize';
                    }
                    if (this._mode !== 'scale') {
                        this._scalePercent = undefined;
                        this._scaleDirection = undefined;
                    }
                };
                this._updateOnElementChange = (element, fromRemote = false)=>{
                    if (fromRemote && this._resizeManager.dragging || !this.isConnected) {
                        return;
                    }
                    const id = typeof element === 'string' ? element : element.id;
                    if (this._resizeManager.bounds.has(id) || this.selection.has(id)) {
                        this._updateSelectedRect();
                        this._updateMode();
                    }
                };
                this._updateOnSelectionChange = ()=>{
                    this._initSelectedSlot();
                    this._updateSelectedRect();
                    this._updateResizeManagerState(true);
                    this._updateCursor(false);
                    this._updateMode();
                };
                this._updateOnViewportChange = ()=>{
                    if (this.selection.empty) {
                        return;
                    }
                    this._updateSelectedRect();
                    this._updateMode();
                };
                this._updateResizeManagerState = (refresh)=>{
                    const { _resizeManager, _selectedRect, resizeMode, zoom, selection: { selectedElements } } = this;
                    const rect = getSelectedRect(selectedElements);
                    const proportion = selectedElements.some((element)=>this._isProportionalElement(element));
                    if (selectedElements.length > 1) refresh = true;
                    _resizeManager.updateState(resizeMode, _selectedRect.rotate, zoom, refresh ? undefined : rect, refresh ? rect : undefined, proportion);
                    _resizeManager.updateBounds(getSelectableBounds(selectedElements));
                };
                this._updateSelectedRect = requestThrottledConnectedFrame(()=>{
                    const { zoom, selection, edgeless } = this;
                    const elements = selection.selectedElements;
                    const rect = getSelectedRect(elements);
                    const [left, top] = edgeless.service.viewport.toViewCoord(rect.left, rect.top);
                    const [width, height] = [
                        rect.width * zoom,
                        rect.height * zoom
                    ];
                    let rotate = 0;
                    if (elements.length === 1 && elements[0].rotate) {
                        rotate = elements[0].rotate;
                    }
                    this._selectedRect = {
                        width,
                        height,
                        left,
                        top,
                        rotate,
                        borderStyle: 'solid',
                        borderWidth: selection.editing ? 2 : 1
                    };
                }, this);
                this.slots = {
                    dragStart: new Slot(),
                    dragMove: new Slot(),
                    dragRotate: new Slot(),
                    dragEnd: new Slot()
                };
                this.#___private__isHeightLimit_1 = (_initProto(this), _init__isHeightLimit(this, false));
                this.#___private__isResizing_2 = _init__isResizing(this, false);
                this.#___private__isWidthLimit_3 = _init__isWidthLimit(this, false);
                this.#___private__mode_4 = _init__mode(this, 'resize');
                this.#___private__scaleDirection_5 = _init__scaleDirection(this, undefined);
                this.#___private__scalePercent_6 = _init__scalePercent(this, undefined);
                this.#___private__selectedRect_7 = _init__selectedRect(this, {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0,
                    rotate: 0,
                    borderWidth: 0,
                    borderStyle: 'solid'
                });
                this.#___private__shiftKey_8 = _init__shiftKey(this, false);
                this.#___private_autoCompleteOff_9 = _init_autoCompleteOff(this, false);
                this.#___private_edgeless_10 = _init_edgeless(this);
                this._resizeManager = new HandleResizeManager(this._onDragStart, this._onDragMove, this._onDragRotate, this._onDragEnd);
                this.addEventListener('pointerdown', stopPropagation);
            }
            #adjustAIChat(element, bound, direction) {
                const curBound = Bound.deserialize(element.xywh);
                let scale = 1;
                if ('scale' in element) {
                    scale = element.scale;
                }
                let width = curBound.w / scale;
                let height = curBound.h / scale;
                if (this._shiftKey) {
                    scale = bound.w / width;
                    this._scalePercent = `${Math.round(scale * 100)}%`;
                    this._scaleDirection = direction;
                }
                width = bound.w / scale;
                width = clamp(width, AI_CHAT_BLOCK_MIN_WIDTH, AI_CHAT_BLOCK_MAX_WIDTH);
                bound.w = width * scale;
                height = bound.h / scale;
                height = clamp(height, AI_CHAT_BLOCK_MIN_HEIGHT, AI_CHAT_BLOCK_MAX_HEIGHT);
                bound.h = height * scale;
                this._isWidthLimit = width === AI_CHAT_BLOCK_MIN_WIDTH || width === AI_CHAT_BLOCK_MAX_WIDTH;
                this._isHeightLimit = height === AI_CHAT_BLOCK_MIN_HEIGHT || height === AI_CHAT_BLOCK_MAX_HEIGHT;
                this.edgeless.service.updateElement(element.id, {
                    scale,
                    xywh: bound.serialize()
                });
            }
            #adjustConnector(element, bounds, matrix, originalPath) {
                const props = element.resize(bounds, originalPath, matrix);
                this.edgeless.service.updateElement(element.id, props);
            }
            #adjustEdgelessText(element, bound, direction) {
                const oldXYWH = Bound.deserialize(element.xywh);
                if (direction === HandleDirection.TopLeft || direction === HandleDirection.TopRight || direction === HandleDirection.BottomRight || direction === HandleDirection.BottomLeft) {
                    const newScale = element.scale * (bound.w / oldXYWH.w);
                    this._scalePercent = `${Math.round(newScale * 100)}%`;
                    this._scaleDirection = direction;
                    bound.h = bound.w * (oldXYWH.h / oldXYWH.w);
                    this.edgeless.service.updateElement(element.id, {
                        scale: newScale,
                        xywh: bound.serialize()
                    });
                } else if (direction === HandleDirection.Left || direction === HandleDirection.Right) {
                    const textPortal = this.edgeless.host.view.getBlock(element.id);
                    if (!textPortal) return;
                    if (!textPortal.checkWidthOverflow(bound.w)) return;
                    const newRealWidth = clamp(bound.w / element.scale, EDGELESS_TEXT_BLOCK_MIN_WIDTH, Infinity);
                    bound.w = newRealWidth * element.scale;
                    this.edgeless.service.updateElement(element.id, {
                        xywh: Bound.serialize({
                            ...bound,
                            h: oldXYWH.h
                        }),
                        hasMaxWidth: true
                    });
                }
            }
            #adjustEmbedHtml(element, bound, _direction) {
                bound.w = clamp(bound.w, EMBED_HTML_MIN_WIDTH, Infinity);
                bound.h = clamp(bound.h, EMBED_HTML_MIN_HEIGHT, Infinity);
                this._isWidthLimit = bound.w === EMBED_HTML_MIN_WIDTH;
                this._isHeightLimit = bound.h === EMBED_HTML_MIN_HEIGHT;
                this.edgeless.service.updateElement(element.id, {
                    xywh: bound.serialize()
                });
            }
            #adjustEmbedSyncedDoc(element, bound, direction) {
                const curBound = Bound.deserialize(element.xywh);
                let scale = element.scale ?? 1;
                let width = curBound.w / scale;
                let height = curBound.h / scale;
                if (this._shiftKey) {
                    scale = bound.w / width;
                    this._scalePercent = `${Math.round(scale * 100)}%`;
                    this._scaleDirection = direction;
                }
                width = bound.w / scale;
                width = clamp(width, SYNCED_MIN_WIDTH, Infinity);
                bound.w = width * scale;
                height = bound.h / scale;
                height = clamp(height, SYNCED_MIN_HEIGHT, Infinity);
                bound.h = height * scale;
                this._isWidthLimit = width === SYNCED_MIN_WIDTH;
                this._isHeightLimit = height === SYNCED_MIN_HEIGHT;
                this.edgeless.service.updateElement(element.id, {
                    scale,
                    xywh: bound.serialize()
                });
            }
            #adjustNote(element, bound, direction) {
                const curBound = Bound.deserialize(element.xywh);
                let scale = element.edgeless.scale ?? 1;
                let width = curBound.w / scale;
                let height = curBound.h / scale;
                if (this._shiftKey) {
                    scale = bound.w / width;
                    this._scalePercent = `${Math.round(scale * 100)}%`;
                    this._scaleDirection = direction;
                } else if (curBound.h !== bound.h) {
                    this.edgeless.doc.updateBlock(element, ()=>{
                        element.edgeless.collapse = true;
                        element.edgeless.collapsedHeight = bound.h / scale;
                    });
                }
                width = bound.w / scale;
                width = clamp(width, NOTE_MIN_WIDTH, Infinity);
                bound.w = width * scale;
                height = bound.h / scale;
                height = clamp(height, NOTE_MIN_HEIGHT, Infinity);
                bound.h = height * scale;
                this._isWidthLimit = width === NOTE_MIN_WIDTH;
                this._isHeightLimit = height === NOTE_MIN_HEIGHT;
                this.edgeless.service.updateElement(element.id, {
                    edgeless: {
                        ...element.edgeless,
                        scale
                    },
                    xywh: bound.serialize()
                });
            }
            #adjustProportional(element, bound, direction) {
                const curBound = Bound.deserialize(element.xywh);
                if (isImageBlock(element)) {
                    const { height } = element;
                    if (height) {
                        this._scalePercent = `${Math.round(bound.h / height * 100)}%`;
                        this._scaleDirection = direction;
                    }
                } else {
                    const cardStyle = element.style;
                    const height = EMBED_CARD_HEIGHT[cardStyle];
                    this._scalePercent = `${Math.round(bound.h / height * 100)}%`;
                    this._scaleDirection = direction;
                }
                if (direction === HandleDirection.Left || direction === HandleDirection.Right) {
                    bound.h = curBound.h / curBound.w * bound.w;
                } else if (direction === HandleDirection.Top || direction === HandleDirection.Bottom) {
                    bound.w = curBound.w / curBound.h * bound.h;
                }
                this.edgeless.service.updateElement(element.id, {
                    xywh: bound.serialize()
                });
            }
            #adjustShape(element, bound, _direction) {
                bound = normalizeShapeBound(element, bound);
                this.edgeless.service.updateElement(element.id, {
                    xywh: bound.serialize()
                });
            }
            #adjustText(element, bound, direction) {
                let p = 1;
                if (direction === HandleDirection.Left || direction === HandleDirection.Right) {
                    const { text: yText, fontFamily, fontSize, fontStyle, fontWeight, hasMaxWidth } = element;
                    bound = normalizeTextBound({
                        yText,
                        fontFamily,
                        fontSize,
                        fontStyle,
                        fontWeight,
                        hasMaxWidth
                    }, bound, true);
                    this.edgeless.service.updateElement(element.id, {
                        xywh: bound.serialize(),
                        fontSize: element.fontSize * p,
                        hasMaxWidth: true
                    });
                } else {
                    p = bound.h / element.h;
                    this.edgeless.service.updateElement(element.id, {
                        xywh: bound.serialize(),
                        fontSize: element.fontSize * p
                    });
                }
            }
            #adjustUseFallback(element, bound, _direction) {
                this.edgeless.service.updateElement(element.id, {
                    xywh: bound.serialize()
                });
            }
            _canAutoComplete() {
                return !this.autoCompleteOff && !this._isResizing && this.selection.selectedElements.length === 1 && (this.selection.selectedElements[0] instanceof ShapeElementModel || isNoteBlock(this.selection.selectedElements[0]));
            }
            _canRotate() {
                return !this.selection.selectedElements.every((ele)=>isNoteBlock(ele) || isFrameBlock(ele) || isBookmarkBlock(ele) || isAttachmentBlock(ele) || isEmbeddedBlock(ele));
            }
            _isProportionalElement(element) {
                return isAttachmentBlock(element) || isImageBlock(element) || isBookmarkBlock(element) || isEmbedFigmaBlock(element) || isEmbedGithubBlock(element) || isEmbedYoutubeBlock(element) || isEmbedLoomBlock(element) || isEmbedLinkedDocBlock(element);
            }
            _shouldRenderSelection(elements) {
                elements = elements ?? this.selection.selectedElements;
                return elements.length > 0 && !this.selection.editing;
            }
            firstUpdated() {
                const { _disposables, edgelessSlots, selection, edgeless } = this;
                _disposables.add(edgeless.service.viewport.viewportUpdated.on(this._updateOnViewportChange));
                pickValues(edgeless.service.surface, [
                    'elementAdded',
                    'elementRemoved',
                    'elementUpdated'
                ]).forEach((slot)=>{
                    _disposables.add(slot.on(this._updateOnElementChange));
                });
                _disposables.add(this.doc.slots.blockUpdated.on(this._updateOnElementChange));
                _disposables.add(edgelessSlots.pressShiftKeyUpdated.on((pressed)=>{
                    this._shiftKey = pressed;
                    this._resizeManager.onPressShiftKey(pressed);
                    this._updateSelectedRect();
                    this._updateMode();
                }));
                _disposables.add(selection.slots.updated.on(this._updateOnSelectionChange));
                _disposables.add(edgeless.slots.readonlyUpdated.on(()=>this.requestUpdate()));
                _disposables.add(edgeless.slots.elementResizeStart.on(()=>this._isResizing = true));
                _disposables.add(edgeless.slots.elementResizeEnd.on(()=>this._isResizing = false));
                _disposables.add(()=>{
                    this._propDisposables.forEach((disposable)=>disposable.dispose());
                });
            }
            render() {
                if (!this.isConnected) return nothing;
                const { selection } = this;
                const elements = selection.selectedElements;
                if (!this._shouldRenderSelection(elements)) return nothing;
                const { edgeless, doc, resizeMode, _resizeManager, _selectedRect, _updateCursor } = this;
                const hasResizeHandles = !selection.editing && !doc.readonly;
                const inoperable = selection.inoperable;
                const handlers = [];
                if (!inoperable) {
                    const resizeHandles = hasResizeHandles ? ResizeHandles(resizeMode, (e, direction)=>{
                        const target = e.target;
                        if (target.classList.contains('rotate') && !this._canRotate()) {
                            return;
                        }
                        const proportional = elements.some((el)=>el instanceof TextElementModel);
                        _resizeManager.onPointerDown(e, direction, proportional);
                    }, (dragging, options)=>{
                        if (!this._canRotate() && options?.type === 'rotate') return;
                        _updateCursor(dragging, options);
                    }) : nothing;
                    const connectorHandle = elements.length === 1 && elements[0] instanceof ConnectorElementModel ? html`
              <edgeless-connector-handle
                .connector=${elements[0]}
                .edgeless=${edgeless}
              ></edgeless-connector-handle>
            ` : nothing;
                    const elementHandle = elements.length > 1 && !elements.reduce((p, e)=>p && e instanceof ConnectorElementModel, true) ? elements.map((element)=>{
                        const [modelX, modelY, w, h] = deserializeXYWH(element.xywh);
                        const [x, y] = edgeless.service.viewport.toViewCoord(modelX, modelY);
                        const { left, top, borderWidth } = this._selectedRect;
                        const style = {
                            position: 'absolute',
                            boxSizing: 'border-box',
                            left: `${x - left - borderWidth}px`,
                            top: `${y - top - borderWidth}px`,
                            width: `${w * this.zoom}px`,
                            height: `${h * this.zoom}px`,
                            transform: `rotate(${element.rotate}deg)`,
                            border: `1px solid var(--affine-primary-color)`
                        };
                        return html`<div
                class="element-handle"
                style=${styleMap(style)}
              ></div>`;
                    }) : nothing;
                    handlers.push(resizeHandles, connectorHandle, elementHandle);
                }
                const isSingleGroup = elements.length === 1 && elements[0] instanceof GroupElementModel;
                if (elements.length === 1 && elements[0] instanceof ConnectorElementModel) {
                    _selectedRect.width = 0;
                    _selectedRect.height = 0;
                    _selectedRect.borderWidth = 0;
                }
                _selectedRect.borderStyle = isSingleGroup ? 'dashed' : 'solid';
                return html`
      <style>
        .affine-edgeless-selected-rect .handle[aria-label='right']::after {
          content: '';
          display: ${this._isWidthLimit ? 'initial' : 'none'};
          position: absolute;
          top: 0;
          left: 1.5px;
          width: 2px;
          height: 100%;
          background: var(--affine-error-color);
          filter: drop-shadow(-6px 0px 12px rgba(235, 67, 53, 0.35));
        }

        .affine-edgeless-selected-rect .handle[aria-label='bottom']::after {
          content: '';
          display: ${this._isHeightLimit ? 'initial' : 'none'};
          position: absolute;
          top: 1.5px;
          left: 0px;
          width: 100%;
          height: 2px;
          background: var(--affine-error-color);
          filter: drop-shadow(-6px 0px 12px rgba(235, 67, 53, 0.35));
        }
      </style>

      ${!doc.readonly && !inoperable && this._canAutoComplete() ? html`<edgeless-auto-complete
            .current=${this.selection.selectedElements[0]}
            .edgeless=${edgeless}
            .selectedRect=${_selectedRect}
          >
          </edgeless-auto-complete>` : nothing}

      <div
        class="affine-edgeless-selected-rect"
        style=${styleMap({
                    width: `${_selectedRect.width}px`,
                    height: `${_selectedRect.height}px`,
                    borderWidth: `${_selectedRect.borderWidth}px`,
                    borderStyle: _selectedRect.borderStyle,
                    transform: `translate(${_selectedRect.left}px, ${_selectedRect.top}px) rotate(${_selectedRect.rotate}deg)`
                })}
        disabled="true"
        data-mode=${this._mode}
        data-scale-percent=${ifDefined(this._scalePercent)}
        data-scale-direction=${ifDefined(this._scaleDirection)}
      >
        ${handlers}
      </div>
    `;
            }
            get doc() {
                return this.edgeless.doc;
            }
            get dragDirection() {
                return this._resizeManager.dragDirection;
            }
            get dragging() {
                return this._resizeManager.dragging || this.edgeless.tools.dragging;
            }
            get edgelessSlots() {
                return this.edgeless.slots;
            }
            get resizeMode() {
                const elements = this.selection.selectedElements;
                let areAllConnectors = true;
                let areAllIndependentConnectors = elements.length > 1;
                let areAllShapes = true;
                let areAllTexts = true;
                let hasMindmapNode = false;
                for (const element of elements){
                    if (isNoteBlock(element) || isEmbedSyncedDocBlock(element)) {
                        areAllConnectors = false;
                        if (this._shiftKey) {
                            areAllShapes = false;
                            areAllTexts = false;
                        }
                    } else if (isEmbedHtmlBlock(element)) {
                        areAllConnectors = false;
                    } else if (isFrameBlock(element)) {
                        areAllConnectors = false;
                    } else if (this._isProportionalElement(element)) {
                        areAllConnectors = false;
                        areAllShapes = false;
                        areAllTexts = false;
                    } else if (isEdgelessTextBlock(element)) {
                        areAllConnectors = false;
                        areAllShapes = false;
                    } else {
                        assertType(element);
                        if (element.type === CanvasElementType.CONNECTOR) {
                            const connector = element;
                            areAllIndependentConnectors &&= !(connector.source.id || connector.target.id);
                        } else {
                            areAllConnectors = false;
                        }
                        if (element.type !== CanvasElementType.SHAPE && element.type !== CanvasElementType.GROUP) areAllShapes = false;
                        if (element.type !== CanvasElementType.TEXT) areAllTexts = false;
                        if (isMindmapNode(element)) {
                            hasMindmapNode = true;
                        }
                    }
                }
                if (areAllConnectors) {
                    if (areAllIndependentConnectors) {
                        return 'all';
                    } else {
                        return 'none';
                    }
                }
                if (hasMindmapNode) return 'none';
                if (areAllShapes) return 'all';
                if (areAllTexts) return 'edgeAndCorner';
                return 'corner';
            }
            get selection() {
                return this.edgeless.service.selection;
            }
            get surface() {
                return this.edgeless.surface;
            }
            get zoom() {
                return this.edgeless.service.viewport.zoom;
            }
            #___private__isHeightLimit_1;
            get _isHeightLimit() {
                return this.#___private__isHeightLimit_1;
            }
            set _isHeightLimit(_v) {
                this.#___private__isHeightLimit_1 = _v;
            }
            #___private__isResizing_2;
            get _isResizing() {
                return this.#___private__isResizing_2;
            }
            set _isResizing(_v) {
                this.#___private__isResizing_2 = _v;
            }
            #___private__isWidthLimit_3;
            get _isWidthLimit() {
                return this.#___private__isWidthLimit_3;
            }
            set _isWidthLimit(_v) {
                this.#___private__isWidthLimit_3 = _v;
            }
            #___private__mode_4;
            get _mode() {
                return this.#___private__mode_4;
            }
            set _mode(_v) {
                this.#___private__mode_4 = _v;
            }
            #___private__scaleDirection_5;
            get _scaleDirection() {
                return this.#___private__scaleDirection_5;
            }
            set _scaleDirection(_v) {
                this.#___private__scaleDirection_5 = _v;
            }
            #___private__scalePercent_6;
            get _scalePercent() {
                return this.#___private__scalePercent_6;
            }
            set _scalePercent(_v) {
                this.#___private__scalePercent_6 = _v;
            }
            #___private__selectedRect_7;
            get _selectedRect() {
                return this.#___private__selectedRect_7;
            }
            set _selectedRect(_v) {
                this.#___private__selectedRect_7 = _v;
            }
            #___private__shiftKey_8;
            get _shiftKey() {
                return this.#___private__shiftKey_8;
            }
            set _shiftKey(_v) {
                this.#___private__shiftKey_8 = _v;
            }
            #___private_autoCompleteOff_9;
            get autoCompleteOff() {
                return this.#___private_autoCompleteOff_9;
            }
            set autoCompleteOff(_v) {
                this.#___private_autoCompleteOff_9 = _v;
            }
            #___private_edgeless_10;
            get edgeless() {
                return this.#___private_edgeless_10;
            }
            set edgeless(_v) {
                this.#___private_edgeless_10 = _v;
            }
        }
    }
}();
export { _EdgelessSelectedRect as EdgelessSelectedRect };
