import { matchFlavours } from '../../_common/utils/model.js';
import { getNextContinuousNumberedLists } from './utils.js';
export const convertToNumberedListCommand = (ctx, next)=>{
    const { std, id, order, stopCapturing = true } = ctx;
    const host = std.host;
    const doc = host.doc;
    if (stopCapturing) host.doc.captureSync();
    const model = doc.getBlock(id).model;
    if (!model.text) return;
    const parent = doc.getParent(model);
    if (!parent) return;
    const index = parent.children.indexOf(model);
    const prevSibling = doc.getPrev(model);
    let realOrder = order;
    if (prevSibling && matchFlavours(prevSibling, [
        'affine:list'
    ]) && prevSibling.type === 'numbered') {
        doc.transact(()=>{
            if (!prevSibling.order) prevSibling.order = 1;
            realOrder = prevSibling.order + 1;
        });
    }
    const newListId = doc.addBlock('affine:list', {
        type: 'numbered',
        text: model.text.clone(),
        order: realOrder
    }, parent, index);
    const newList = doc.getBlock(newListId).model;
    doc.deleteBlock(model, {
        deleteChildren: false,
        bringChildrenTo: newList
    });
    const nextContinuousNumberedLists = getNextContinuousNumberedLists(doc, newList);
    let base = realOrder + 1;
    nextContinuousNumberedLists.forEach((list)=>{
        doc.transact(()=>{
            list.order = base;
        });
        base += 1;
    });
    next({
        list: newList
    });
};
