function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _initClass, _Service;
import { fromPromise, OnEvent, Service, WorkspaceEngineBeforeStart } from '@toeverything/infra';
import { switchMap } from 'rxjs';
import { DocsIndexer } from '../entities/docs-indexer';
let _DocsSearchService;
_dec = OnEvent(WorkspaceEngineBeforeStart, (s)=>s.handleWorkspaceEngineBeforeStart);
class DocsSearchService extends (_Service = Service) {
    static{
        ({ c: [_DocsSearchService, _initClass] } = _apply_decs_2203_r(this, [], [
            _dec
        ], _Service));
    }
    handleWorkspaceEngineBeforeStart() {
        this.indexer.setupListener();
        this.indexer.startCrawling();
    }
    async search(query) {
        const { buckets } = await this.indexer.blockIndex.aggregate({
            type: 'boolean',
            occur: 'must',
            queries: [
                {
                    type: 'match',
                    field: 'content',
                    match: query
                },
                {
                    type: 'boolean',
                    occur: 'should',
                    queries: [
                        {
                            type: 'all'
                        },
                        {
                            type: 'boost',
                            boost: 1.5,
                            query: {
                                type: 'match',
                                field: 'flavour',
                                match: 'affine:page'
                            }
                        }
                    ]
                }
            ]
        }, 'docId', {
            pagination: {
                limit: 50,
                skip: 0
            },
            hits: {
                pagination: {
                    limit: 2,
                    skip: 0
                },
                fields: [
                    'blockId',
                    'flavour'
                ],
                highlights: [
                    {
                        field: 'content',
                        before: '<b>',
                        end: '</b>'
                    }
                ]
            }
        });
        const docData = await this.indexer.docIndex.getAll(buckets.map((bucket)=>bucket.key));
        const result = [];
        for (const bucket of buckets){
            const firstMatchFlavour = bucket.hits.nodes[0]?.fields.flavour;
            if (firstMatchFlavour === 'affine:page') {
                const blockContent = bucket.hits.nodes[1]?.highlights.content[0];
                result.push({
                    docId: bucket.key,
                    title: bucket.hits.nodes[0].highlights.content[0],
                    score: bucket.score,
                    blockContent
                });
            } else {
                const title = docData.find((doc)=>doc.id === bucket.key)?.get('title') ?? '';
                const matchedBlockId = bucket.hits.nodes[0]?.fields.blockId;
                result.push({
                    docId: bucket.key,
                    title: typeof title === 'string' ? title : title[0],
                    blockId: typeof matchedBlockId === 'string' ? matchedBlockId : matchedBlockId[0],
                    score: bucket.score,
                    blockContent: bucket.hits.nodes[0]?.highlights.content[0]
                });
            }
        }
        return result;
    }
    search$(query) {
        return this.indexer.blockIndex.aggregate$({
            type: 'boolean',
            occur: 'must',
            queries: [
                {
                    type: 'match',
                    field: 'content',
                    match: query
                },
                {
                    type: 'boolean',
                    occur: 'should',
                    queries: [
                        {
                            type: 'all'
                        },
                        {
                            type: 'boost',
                            boost: 1.5,
                            query: {
                                type: 'match',
                                field: 'flavour',
                                match: 'affine:page'
                            }
                        }
                    ]
                }
            ]
        }, 'docId', {
            pagination: {
                limit: 50,
                skip: 0
            },
            hits: {
                pagination: {
                    limit: 2,
                    skip: 0
                },
                fields: [
                    'blockId',
                    'flavour'
                ],
                highlights: [
                    {
                        field: 'content',
                        before: '<b>',
                        end: '</b>'
                    }
                ]
            }
        }).pipe(switchMap(({ buckets })=>{
            return fromPromise(async ()=>{
                const docData = await this.indexer.docIndex.getAll(buckets.map((bucket)=>bucket.key));
                const result = [];
                for (const bucket of buckets){
                    const firstMatchFlavour = bucket.hits.nodes[0]?.fields.flavour;
                    if (firstMatchFlavour === 'affine:page') {
                        const blockContent = bucket.hits.nodes[1]?.highlights.content[0];
                        result.push({
                            docId: bucket.key,
                            title: bucket.hits.nodes[0].highlights.content[0],
                            score: bucket.score,
                            blockContent
                        });
                    } else {
                        const title = docData.find((doc)=>doc.id === bucket.key)?.get('title') ?? '';
                        const matchedBlockId = bucket.hits.nodes[0]?.fields.blockId;
                        result.push({
                            docId: bucket.key,
                            title: typeof title === 'string' ? title : title[0],
                            blockId: typeof matchedBlockId === 'string' ? matchedBlockId : matchedBlockId[0],
                            score: bucket.score,
                            blockContent: bucket.hits.nodes[0]?.highlights.content[0]
                        });
                    }
                }
                return result;
            });
        }));
    }
    async searchRefsFrom(docId) {
        const { nodes } = await this.indexer.blockIndex.search({
            type: 'boolean',
            occur: 'must',
            queries: [
                {
                    type: 'match',
                    field: 'docId',
                    match: docId
                },
                {
                    type: 'exists',
                    field: 'ref'
                }
            ]
        }, {
            fields: [
                'ref'
            ],
            pagination: {
                limit: 100
            }
        });
        const docIds = new Set(nodes.flatMap((node)=>{
            const refs = node.fields.ref;
            return typeof refs === 'string' ? [
                refs
            ] : refs;
        }));
        const docData = await this.indexer.docIndex.getAll(Array.from(docIds));
        return docData.map((doc)=>{
            const title = doc.get('title');
            return {
                docId: doc.id,
                title: title ? typeof title === 'string' ? title : title[0] : ''
            };
        });
    }
    watchRefsFrom(docId) {
        return this.indexer.blockIndex.search$({
            type: 'boolean',
            occur: 'must',
            queries: [
                {
                    type: 'match',
                    field: 'docId',
                    match: docId
                },
                {
                    type: 'exists',
                    field: 'ref'
                }
            ]
        }, {
            fields: [
                'ref'
            ],
            pagination: {
                limit: 100
            }
        }).pipe(switchMap(({ nodes })=>{
            return fromPromise(async ()=>{
                const docIds = new Set(nodes.flatMap((node)=>{
                    const refs = node.fields.ref;
                    return typeof refs === 'string' ? [
                        refs
                    ] : refs;
                }));
                const docData = await this.indexer.docIndex.getAll(Array.from(docIds));
                return docData.map((doc)=>{
                    const title = doc.get('title');
                    return {
                        docId: doc.id,
                        title: title ? typeof title === 'string' ? title : title[0] : ''
                    };
                });
            });
        }));
    }
    async searchRefsTo(docId) {
        const { buckets } = await this.indexer.blockIndex.aggregate({
            type: 'match',
            field: 'ref',
            match: docId
        }, 'docId', {
            hits: {
                fields: [
                    'docId',
                    'blockId'
                ],
                pagination: {
                    limit: 1
                }
            },
            pagination: {
                limit: 100
            }
        });
        const docData = await this.indexer.docIndex.getAll(buckets.map((bucket)=>bucket.key));
        return buckets.map((bucket)=>{
            const title = docData.find((doc)=>doc.id === bucket.key)?.get('title') ?? '';
            const blockId = bucket.hits.nodes[0]?.fields.blockId ?? '';
            return {
                docId: bucket.key,
                blockId: typeof blockId === 'string' ? blockId : blockId[0],
                title: typeof title === 'string' ? title : title[0]
            };
        });
    }
    watchRefsTo(docId) {
        return this.indexer.blockIndex.aggregate$({
            type: 'match',
            field: 'ref',
            match: docId
        }, 'docId', {
            hits: {
                fields: [
                    'docId',
                    'blockId'
                ],
                pagination: {
                    limit: 1
                }
            },
            pagination: {
                limit: 100
            }
        }).pipe(switchMap(({ buckets })=>{
            return fromPromise(async ()=>{
                const docData = await this.indexer.docIndex.getAll(buckets.map((bucket)=>bucket.key));
                return buckets.map((bucket)=>{
                    const title = docData.find((doc)=>doc.id === bucket.key)?.get('title') ?? '';
                    const blockId = bucket.hits.nodes[0]?.fields.blockId ?? '';
                    return {
                        docId: bucket.key,
                        blockId: typeof blockId === 'string' ? blockId : blockId[0],
                        title: typeof title === 'string' ? title : title[0]
                    };
                });
            });
        }));
    }
    async getDocTitle(docId) {
        const doc = await this.indexer.docIndex.get(docId);
        const title = doc?.get('title');
        return typeof title === 'string' ? title : title?.[0];
    }
    dispose() {
        this.indexer.dispose();
    }
    static{
        _initClass();
    }
    constructor(...args){
        super(...args);
        this.indexer = this.framework.createEntity(DocsIndexer);
    }
}
export { _DocsSearchService as DocsSearchService };
