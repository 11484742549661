export { Navigator } from './entities/navigator';
export { resolveLinkToDoc, resolveRouteLinkMeta } from './utils';
export { NavigationButtons } from './view/navigation-buttons';
import { WorkspaceScope } from '@toeverything/infra';
import { WorkbenchService } from '../workbench';
import { Navigator } from './entities/navigator';
import { NavigatorService } from './services/navigator';
export function configureNavigationModule(framework) {
    framework.scope(WorkspaceScope).service(NavigatorService).entity(Navigator, [
        WorkbenchService
    ]);
}
