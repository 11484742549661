import { cancelSubscriptionMutation, createCheckoutSessionMutation, pricesQuery, resumeSubscriptionMutation, SubscriptionPlan, subscriptionQuery, updateSubscriptionMutation } from '@affine/graphql';
import { Store } from '@toeverything/infra';
import { getAffineCloudBaseUrl } from '../services/fetch';
const SUBSCRIPTION_CACHE_KEY = 'subscription:';
const getDefaultSubscriptionSuccessCallbackLink = (plan)=>{
    const path = plan === SubscriptionPlan.AI ? '/ai-upgrade-success' : '/upgrade-success';
    const urlString = getAffineCloudBaseUrl() + path;
    const url = new URL(urlString);
    if (environment.isDesktop) {
        url.searchParams.set('schema', window.appInfo.schema);
    }
    return url.toString();
};
export class SubscriptionStore extends Store {
    constructor(gqlService, globalCacheService){
        super();
        this.gqlService = gqlService;
        this.globalCacheService = globalCacheService;
    }
    async fetchSubscriptions(abortSignal) {
        const data = await this.gqlService.gql({
            query: subscriptionQuery,
            context: {
                signal: abortSignal
            }
        });
        if (!data.currentUser) {
            throw new Error('No logged in');
        }
        return {
            userId: data.currentUser?.id,
            subscriptions: data.currentUser?.subscriptions
        };
    }
    async mutateResumeSubscription(idempotencyKey, plan, abortSignal) {
        const data = await this.gqlService.gql({
            query: resumeSubscriptionMutation,
            variables: {
                idempotencyKey,
                plan
            },
            context: {
                signal: abortSignal
            }
        });
        return data.resumeSubscription;
    }
    async mutateCancelSubscription(idempotencyKey, plan, abortSignal) {
        const data = await this.gqlService.gql({
            query: cancelSubscriptionMutation,
            variables: {
                idempotencyKey,
                plan
            },
            context: {
                signal: abortSignal
            }
        });
        return data.cancelSubscription;
    }
    getCachedSubscriptions(userId) {
        return this.globalCacheService.globalCache.get(SUBSCRIPTION_CACHE_KEY + userId);
    }
    setCachedSubscriptions(userId, subscriptions) {
        return this.globalCacheService.globalCache.set(SUBSCRIPTION_CACHE_KEY + userId, subscriptions);
    }
    setSubscriptionRecurring(idempotencyKey, recurring, plan) {
        return this.gqlService.gql({
            query: updateSubscriptionMutation,
            variables: {
                idempotencyKey,
                plan,
                recurring
            }
        });
    }
    async createCheckoutSession(input) {
        const data = await this.gqlService.gql({
            query: createCheckoutSessionMutation,
            variables: {
                input: {
                    ...input,
                    successCallbackLink: input.successCallbackLink || getDefaultSubscriptionSuccessCallbackLink(input.plan)
                }
            }
        });
        return data.createCheckoutSession;
    }
    async fetchSubscriptionPrices(abortSignal) {
        const data = await this.gqlService.gql({
            query: pricesQuery,
            context: {
                signal: abortSignal
            }
        });
        return data.prices;
    }
}
