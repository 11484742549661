import { showAILoginRequiredAtom } from '@affine/core/components/affine/auth/ai-login-required';
import { cleanupCopilotSessionMutation, createCopilotMessageMutation, createCopilotSessionMutation, fetcher as defaultFetcher, forkCopilotSessionMutation, getBaseUrl, getCopilotHistoriesQuery, getCopilotHistoryIdsQuery, getCopilotSessionsQuery, GraphQLError, UserFriendlyError } from '@affine/graphql';
import { GeneralNetworkError, PaymentRequiredError, UnauthorizedError } from '@blocksuite/blocks';
import { getCurrentStore } from '@toeverything/infra';
function codeToError(error) {
    switch(error.status){
        case 401:
            return new UnauthorizedError();
        case 402:
            return new PaymentRequiredError();
        default:
            return new GeneralNetworkError(error.code ? `${error.code}: ${error.message}\nIdentify: ${error.name}` : undefined);
    }
}
export function resolveError(err) {
    const standardError = err instanceof GraphQLError ? new UserFriendlyError(err.extensions) : UserFriendlyError.fromAnyError(err);
    return codeToError(standardError);
}
export function handleError(src) {
    const err = resolveError(src);
    if (err instanceof UnauthorizedError) {
        getCurrentStore().set(showAILoginRequiredAtom, true);
    }
    return err;
}
const fetcher = async (options)=>{
    try {
        return await defaultFetcher(options);
    } catch (err) {
        throw handleError(err);
    }
};
export class CopilotClient {
    async createSession(options) {
        const res = await fetcher({
            query: createCopilotSessionMutation,
            variables: {
                options
            }
        });
        return res.createCopilotSession;
    }
    async forkSession(options) {
        const res = await fetcher({
            query: forkCopilotSessionMutation,
            variables: {
                options
            }
        });
        return res.forkCopilotSession;
    }
    async createMessage(options) {
        const res = await fetcher({
            query: createCopilotMessageMutation,
            variables: {
                options
            }
        });
        return res.createCopilotMessage;
    }
    async getSessions(workspaceId) {
        const res = await fetcher({
            query: getCopilotSessionsQuery,
            variables: {
                workspaceId
            }
        });
        return res.currentUser?.copilot;
    }
    async getHistories(workspaceId, docId, options) {
        const res = await fetcher({
            query: getCopilotHistoriesQuery,
            variables: {
                workspaceId,
                docId,
                options
            }
        });
        return res.currentUser?.copilot?.histories;
    }
    async getHistoryIds(workspaceId, docId, options) {
        const res = await fetcher({
            query: getCopilotHistoryIdsQuery,
            variables: {
                workspaceId,
                docId,
                options
            }
        });
        return res.currentUser?.copilot?.histories;
    }
    async cleanupSessions(input) {
        const res = await fetcher({
            query: cleanupCopilotSessionMutation,
            variables: {
                input
            }
        });
        return res.cleanupCopilotSession;
    }
    async chatText({ sessionId, messageId, signal }) {
        const url = new URL(`${this.backendUrl}/api/copilot/chat/${sessionId}`);
        if (messageId) {
            url.searchParams.set('messageId', messageId);
        }
        const response = await fetch(url.toString(), {
            signal
        });
        return response.text();
    }
    chatTextStream({ sessionId, messageId }, endpoint = 'stream') {
        const url = new URL(`${this.backendUrl}/api/copilot/chat/${sessionId}/${endpoint}`);
        if (messageId) url.searchParams.set('messageId', messageId);
        return new EventSource(url.toString());
    }
    imagesStream(sessionId, messageId, seed, endpoint = 'images') {
        const url = new URL(`${this.backendUrl}/api/copilot/chat/${sessionId}/${endpoint}`);
        if (messageId) {
            url.searchParams.set('messageId', messageId);
        }
        if (seed) {
            url.searchParams.set('seed', seed);
        }
        return new EventSource(url);
    }
    constructor(){
        this.backendUrl = getBaseUrl();
    }
}
