function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _LitElement, _dec1, _dec2, _dec3, _dec4, _init_fontFamily, _init_fontStyle, _init_fontWeight, _init_onSelect, _initProto;
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { join } from 'lit/directives/join.js';
import { repeat } from 'lit/directives/repeat.js';
import { CheckIcon } from '../../../../_common/icons/edgeless.js';
import { FontFamily, FontFamilyMap, FontStyle, FontWeight } from '../../../../surface-block/consts.js';
import { getFontFaces, getFontFacesByFontFamily, isSameFontFamily } from '../../../../surface-block/utils/font.js';
const FONT_WEIGHT_CHOOSE = [
    [
        FontWeight.Light,
        ()=>'Light'
    ],
    [
        FontWeight.Regular,
        ()=>'Regular'
    ],
    [
        FontWeight.SemiBold,
        ()=>'Semibold'
    ]
];
let _EdgelessFontWeightAndStylePanel;
_dec = customElement('edgeless-font-weight-and-style-panel'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EdgelessFontWeightAndStylePanel), _initClass();
    }
    static{
        class EdgelessFontWeightAndStylePanel extends (_LitElement = LitElement) {
            static{
                ({ e: [_init_fontFamily, _init_fontStyle, _init_fontWeight, _init_onSelect, _initProto], c: [_EdgelessFontWeightAndStylePanel, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "fontFamily"
                    ],
                    [
                        _dec2,
                        1,
                        "fontStyle"
                    ],
                    [
                        _dec3,
                        1,
                        "fontWeight"
                    ],
                    [
                        _dec4,
                        1,
                        "onSelect"
                    ]
                ], [
                    _dec
                ], _LitElement));
            }
            static{
                this.styles = css`
    :host {
      display: flex;
      align-items: start;
      flex-direction: column;
      min-width: 124px;
    }

    edgeless-tool-icon-button {
      width: 100%;
    }
  `;
            }
            _isActive(fontWeight, fontStyle = FontStyle.Normal) {
                return this.fontWeight === fontWeight && this.fontStyle === fontStyle;
            }
            _isDisabled(fontWeight, fontStyle = FontStyle.Normal) {
                if (!(this.fontFamily in FontFamilyMap)) return false;
                const fontFace = getFontFaces().filter(isSameFontFamily(this.fontFamily)).find((fontFace)=>fontFace.weight === fontWeight && fontFace.style === fontStyle);
                return !fontFace;
            }
            _onSelect(fontWeight, fontStyle = FontStyle.Normal) {
                this.fontWeight = fontWeight;
                this.fontStyle = fontStyle;
                if (this.onSelect) {
                    this.onSelect(fontWeight, fontStyle);
                }
            }
            render() {
                let fontFaces = getFontFacesByFontFamily(this.fontFamily);
                if (fontFaces.length === 0) {
                    fontFaces = getFontFacesByFontFamily(FontFamily.Inter);
                }
                const fontFacesWithNormal = fontFaces.filter((fontFace)=>fontFace.style === FontStyle.Normal);
                const fontFacesWithItalic = fontFaces.filter((fontFace)=>fontFace.style === FontStyle.Italic);
                return join([
                    fontFacesWithNormal.length > 0 ? repeat(fontFacesWithNormal, (fontFace)=>fontFace.weight, (fontFace)=>{
                        const active = this._isActive(fontFace.weight);
                        return html`
                  <edgeless-tool-icon-button
                    data-weight="${fontFace.weight}"
                    .iconContainerPadding=${[
                            4,
                            8
                        ]}
                    .justify=${'space-between'}
                    .disabled=${this._isDisabled(fontFace.weight)}
                    .active=${active}
                    @click=${()=>this._onSelect(fontFace.weight)}
                  >
                    ${choose(fontFace.weight, FONT_WEIGHT_CHOOSE)}
                    ${active ? CheckIcon : nothing}
                  </edgeless-tool-icon-button>
                `;
                    }) : nothing,
                    fontFacesWithItalic.length > 0 ? repeat(fontFacesWithItalic, (fontFace)=>fontFace.weight, (fontFace)=>{
                        const active = this._isActive(fontFace.weight, FontStyle.Italic);
                        return html`
                  <edgeless-tool-icon-button
                    data-weight="${fontFace.weight} italic"
                    .iconContainerPadding=${[
                            4,
                            8
                        ]}
                    .justify=${'space-between'}
                    .disabled=${this._isDisabled(fontFace.weight, FontStyle.Italic)}
                    .active=${active}
                    @click=${()=>this._onSelect(fontFace.weight, FontStyle.Italic)}
                  >
                    ${choose(fontFace.weight, FONT_WEIGHT_CHOOSE)} Italic
                    ${active ? CheckIcon : nothing}
                  </edgeless-tool-icon-button>
                `;
                    }) : nothing
                ].filter((item)=>item !== nothing), ()=>html`
        <edgeless-menu-divider
          data-orientation="horizontal"
        ></edgeless-menu-divider>
      `);
            }
            #___private_fontFamily_1;
            get fontFamily() {
                return this.#___private_fontFamily_1;
            }
            set fontFamily(_v) {
                this.#___private_fontFamily_1 = _v;
            }
            #___private_fontStyle_2;
            get fontStyle() {
                return this.#___private_fontStyle_2;
            }
            set fontStyle(_v) {
                this.#___private_fontStyle_2 = _v;
            }
            #___private_fontWeight_3;
            get fontWeight() {
                return this.#___private_fontWeight_3;
            }
            set fontWeight(_v) {
                this.#___private_fontWeight_3 = _v;
            }
            #___private_onSelect_4;
            get onSelect() {
                return this.#___private_onSelect_4;
            }
            set onSelect(_v) {
                this.#___private_onSelect_4 = _v;
            }
            constructor(...args){
                super(...args);
                this.#___private_fontFamily_1 = (_initProto(this), _init_fontFamily(this, FontFamily.Inter));
                this.#___private_fontStyle_2 = _init_fontStyle(this, FontStyle.Normal);
                this.#___private_fontWeight_3 = _init_fontWeight(this, FontWeight.Regular);
                this.#___private_onSelect_4 = _init_onSelect(this);
            }
        }
    }
}();
export { _EdgelessFontWeightAndStylePanel as EdgelessFontWeightAndStylePanel };
