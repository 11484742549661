import { assertExists } from '@blocksuite/global/utils';
import { KEYBOARD_ALLOW_DEFAULT, KEYBOARD_PREVENT_DEFAULT } from '@blocksuite/inline';
import { matchFlavours } from '../../../../_common/utils/model.js';
import { handleBlockEndEnter, handleBlockSplit, handleLineEndForwardDelete, handleLineStartBackspace, handleUnindent } from '../rich-text-operations.js';
function isCollapsedAtBlockStart(inlineEditor) {
    const inlineRange = inlineEditor.getInlineRange();
    return inlineRange?.index === 0 && inlineRange?.length === 0;
}
function isCollapsedAtBlockEnd(inlineEditor) {
    const inlineRange = inlineEditor.getInlineRange();
    return inlineRange?.index === inlineEditor.yText.length && inlineRange?.length === 0;
}
export function onSoftEnter(inlineRange, inlineEditor) {
    inlineEditor.insertText(inlineRange, '\n');
    inlineEditor.setInlineRange({
        index: inlineRange.index + 1,
        length: 0
    });
    return KEYBOARD_PREVENT_DEFAULT;
}
export function hardEnter(editorHost, model, range, inlineEditor, e, shortKey = false) {
    const doc = model.doc;
    e.stopPropagation();
    const parent = doc.getParent(model);
    const isLastChild = parent?.lastChild() === model;
    const isEmptyList = matchFlavours(model, [
        'affine:list'
    ]) && model.text.length === 0;
    assertExists(model.text, 'Failed to hardEnter! model.text not exists!');
    if (isEmptyList && parent && matchFlavours(parent, [
        'affine:note',
        'affine:database'
    ]) && model.children.length === 0) {
        handleLineStartBackspace(editorHost, model);
        return KEYBOARD_PREVENT_DEFAULT;
    }
    if (isEmptyList && isLastChild) {
        handleUnindent(editorHost, model, range.index);
        return KEYBOARD_PREVENT_DEFAULT;
    }
    const isEnd = model.text.length === range.index;
    const softEnterable = isSoftEnterable(model);
    if (isEnd && softEnterable) {
        if (matchFlavours(model, [
            'affine:code'
        ])) {
            if (shortKey) {
                handleBlockEndEnter(editorHost, model);
                return KEYBOARD_PREVENT_DEFAULT;
            }
            onSoftEnter(range, inlineEditor);
            return KEYBOARD_PREVENT_DEFAULT;
        }
        const textStr = model.text.toString();
        const endWithTwoBlankLines = textStr === '\n' || textStr.endsWith('\n');
        const shouldSoftEnter = softEnterable && !endWithTwoBlankLines;
        if (shouldSoftEnter || shortKey) {
            onSoftEnter(range, inlineEditor);
            return KEYBOARD_PREVENT_DEFAULT;
        }
        model.text.delete(range.index - 1, 1);
        handleBlockEndEnter(editorHost, model);
        return KEYBOARD_PREVENT_DEFAULT;
    }
    if (isEnd || shortKey) {
        handleBlockEndEnter(editorHost, model);
        return KEYBOARD_PREVENT_DEFAULT;
    }
    const isSoftEnterBlock = isSoftEnterable(model);
    if (isSoftEnterBlock) {
        onSoftEnter(range, inlineEditor);
        return KEYBOARD_PREVENT_DEFAULT;
    }
    handleBlockSplit(editorHost, model, range.index, range.length)?.catch(console.error);
    return KEYBOARD_PREVENT_DEFAULT;
}
function isSoftEnterable(model) {
    if (matchFlavours(model, [
        'affine:code'
    ])) return true;
    if (matchFlavours(model, [
        'affine:paragraph'
    ])) {
        return model.type === 'quote';
    }
    return false;
}
export function onBackspace(editorHost, model, e, inlineEditor) {
    if (isCollapsedAtBlockStart(inlineEditor)) {
        if (model.flavour === 'affine:code') {
            return KEYBOARD_ALLOW_DEFAULT;
        }
        e.stopPropagation();
        handleLineStartBackspace(editorHost, model);
        return KEYBOARD_PREVENT_DEFAULT;
    }
    e.stopPropagation();
    return KEYBOARD_ALLOW_DEFAULT;
}
export function onForwardDelete(editorHost, model, e, inlineEditor) {
    e.stopPropagation();
    if (isCollapsedAtBlockEnd(inlineEditor)) {
        handleLineEndForwardDelete(editorHost, model);
        return KEYBOARD_PREVENT_DEFAULT;
    }
    return KEYBOARD_ALLOW_DEFAULT;
}
