import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DropIndicator, IconButton, Menu, MenuIcon, MenuItem, useDraggable, useDropTarget } from '@affine/component';
import { RenameModal } from '@affine/component/rename-modal';
import { appSidebarWidthAtom } from '@affine/core/components/app-sidebar/index.jotai';
import { WorkbenchLink } from '@affine/core/modules/workbench';
import { useI18n } from '@affine/i18n';
import { ArrowDownSmallIcon, EditIcon, MoreHorizontalIcon } from '@blocksuite/icons/rc';
import * as Collapsible from '@radix-ui/react-collapsible';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import { Fragment, useCallback, useContext, useEffect, useId, useMemo, useRef, useState } from 'react';
import { ExplorerTreeContext } from './context';
import { DropEffect } from './drop-effect';
import * as styles from './node.css';
export const ExplorerTreeNode = ({ children, icon: Icon, name, onClick, to, active, defaultRenaming, renameable, onRename, disabled, collapsed, setCollapsed, canDrop, reorderable = true, operations = [], postfix, childrenOperations = [], childrenPlaceholder, linkComponent: LinkComponent = WorkbenchLink, dndData, onDrop, dropEffect, ...otherProps })=>{
    const t = useI18n();
    const cid = useId();
    const context = useContext(ExplorerTreeContext);
    const level = context?.level ?? 0;
    const clickForCollapse = !onClick && !to && !disabled;
    const [childCount, setChildCount] = useState(0);
    const sidebarWidth = useAtomValue(appSidebarWidthAtom);
    const [renaming, setRenaming] = useState(defaultRenaming);
    const [lastInGroup, setLastInGroup] = useState(false);
    const rootRef = useRef(null);
    const { dragRef, dragging, CustomDragPreview } = useDraggable(()=>({
            data: {
                ...dndData?.draggable,
                __cid: cid
            },
            dragPreviewPosition: 'pointer-outside'
        }), [
        cid,
        dndData
    ]);
    const handleCanDrop = useMemo(()=>(args)=>{
            if (!reorderable && args.treeInstruction?.type !== 'make-child') {
                return false;
            }
            return (typeof canDrop === 'function' ? canDrop(args) : canDrop) ?? true;
        }, [
        canDrop,
        reorderable
    ]);
    const { dropTargetRef, treeInstruction, draggedOverDraggable, draggedOver, draggedOverPosition } = useDropTarget(()=>({
            data: dndData?.dropTarget,
            treeInstruction: {
                currentLevel: level,
                indentPerLevel: 20,
                mode: !collapsed ? 'expanded' : lastInGroup ? 'last-in-group' : 'standard',
                block: reorderable === false ? [
                    'reorder-above',
                    'reorder-below',
                    'reparent'
                ] : []
            },
            onDrop: (data)=>{
                if (data.source.data.__cid === cid && data.treeInstruction?.type !== 'reparent') {
                    return;
                }
                onDrop?.(data);
                if (data.treeInstruction?.type === 'make-child') {
                    setCollapsed(false);
                }
            },
            canDrop: handleCanDrop
        }), [
        dndData?.dropTarget,
        level,
        collapsed,
        lastInGroup,
        reorderable,
        handleCanDrop,
        cid,
        onDrop,
        setCollapsed
    ]);
    const isSelfDraggedOver = draggedOverDraggable?.data.__cid === cid;
    useEffect(()=>{
        if (draggedOver && treeInstruction?.type === 'make-child' && !isSelfDraggedOver) {
            const timeout = setTimeout(()=>{
                setCollapsed(false);
            }, 1000);
            return ()=>clearTimeout(timeout);
        }
        return;
    }, [
        draggedOver,
        isSelfDraggedOver,
        setCollapsed,
        treeInstruction?.type
    ]);
    useEffect(()=>{
        if (rootRef.current) {
            const parent = rootRef.current.parentElement;
            if (parent) {
                const updateLastInGroup = ()=>{
                    setLastInGroup(parent?.lastElementChild === rootRef.current);
                };
                updateLastInGroup();
                const observer = new MutationObserver(updateLastInGroup);
                observer.observe(parent, {
                    childList: true
                });
                return ()=>observer.disconnect();
            }
        }
        return;
    }, []);
    const presetOperations = useMemo(()=>[
            renameable ? {
                index: 0,
                view: _jsx(MenuItem, {
                    type: 'default',
                    preFix: _jsx(MenuIcon, {
                        children: _jsx(EditIcon, {})
                    }),
                    onClick: ()=>setRenaming(true),
                    children: t['com.affine.menu.rename']()
                }, 'explorer-tree-rename')
            } : null
        ].filter((t)=>t !== null), [
        renameable,
        t
    ]);
    const { menuOperations, inlineOperations } = useMemo(()=>{
        const sorted = [
            ...presetOperations,
            ...operations
        ].sort((a, b)=>a.index - b.index);
        return {
            menuOperations: sorted.filter(({ inline })=>!inline),
            inlineOperations: sorted.filter(({ inline })=>!!inline)
        };
    }, [
        presetOperations,
        operations
    ]);
    const contextValue = useMemo(()=>{
        return {
            operations: childrenOperations,
            level: (context?.level ?? 0) + 1,
            registerChild: ()=>{
                setChildCount((c)=>c + 1);
                return ()=>setChildCount((c)=>c - 1);
            }
        };
    }, [
        childrenOperations,
        context?.level
    ]);
    const handleCollapsedChange = useCallback((e)=>{
        e.stopPropagation();
        e.preventDefault();
        setCollapsed(!collapsed);
    }, [
        collapsed,
        setCollapsed
    ]);
    const handleRename = useCallback((newName)=>{
        onRename?.(newName);
    }, [
        onRename
    ]);
    const handleClick = useCallback(()=>{
        if (!clickForCollapse) {
            onClick?.();
        } else {
            setCollapsed(!collapsed);
        }
    }, [
        clickForCollapse,
        collapsed,
        onClick,
        setCollapsed
    ]);
    const content = _jsxs("div", {
        onClick: handleClick,
        className: styles.itemRoot,
        "data-active": active,
        "data-disabled": disabled,
        children: [
            Icon && _jsxs("div", {
                className: styles.iconsContainer,
                children: [
                    _jsx("div", {
                        "data-disabled": disabled,
                        onClick: handleCollapsedChange,
                        "data-testid": "explorer-collapsed-button",
                        className: styles.collapsedIconContainer,
                        children: _jsx(ArrowDownSmallIcon, {
                            className: styles.collapsedIcon,
                            "data-collapsed": collapsed !== false
                        })
                    }),
                    _jsx(Icon, {
                        className: styles.icon,
                        draggedOver: draggedOver && !isSelfDraggedOver,
                        treeInstruction: treeInstruction,
                        collapsed: collapsed
                    })
                ]
            }),
            renameable && renaming && _jsx(RenameModal, {
                open: true,
                width: sidebarWidth - 32,
                onOpenChange: setRenaming,
                onRename: handleRename,
                currentName: name ?? '',
                children: _jsx("div", {
                    className: styles.itemRenameAnchor
                })
            }),
            _jsx("div", {
                className: styles.itemContent,
                children: name
            }),
            postfix,
            _jsxs("div", {
                className: styles.postfix,
                onClick: (e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                },
                children: [
                    inlineOperations.map(({ view }, index)=>_jsx(Fragment, {
                            children: view
                        }, index)),
                    menuOperations.length > 0 && _jsx(Menu, {
                        items: menuOperations.map(({ view }, index)=>_jsx(Fragment, {
                                children: view
                            }, index)),
                        children: _jsx(IconButton, {
                            size: "16",
                            "data-testid": "explorer-tree-node-operation-button",
                            style: {
                                marginLeft: 4
                            },
                            children: _jsx(MoreHorizontalIcon, {})
                        })
                    })
                ]
            })
        ]
    });
    return _jsxs(Collapsible.Root, {
        open: !collapsed,
        onOpenChange: setCollapsed,
        style: assignInlineVars({
            [styles.levelIndent]: `${level * 20}px`
        }),
        ref: rootRef,
        ...otherProps,
        children: [
            _jsxs("div", {
                className: clsx(styles.contentContainer, styles.draggedOverEffect),
                "data-open": !collapsed,
                "data-self-dragged-over": isSelfDraggedOver,
                ref: dropTargetRef,
                children: [
                    to ? _jsx(LinkComponent, {
                        to: to,
                        className: styles.linkItemRoot,
                        ref: dragRef,
                        children: content
                    }) : _jsx("div", {
                        ref: dragRef,
                        children: content
                    }),
                    _jsx(CustomDragPreview, {
                        children: _jsx("div", {
                            className: styles.draggingContainer,
                            children: content
                        })
                    }),
                    treeInstruction && !(treeInstruction.type !== 'reparent' && isSelfDraggedOver) && treeInstruction.type !== 'instruction-blocked' && _jsx(DropIndicator, {
                        instruction: treeInstruction
                    }),
                    draggedOver && dropEffect && draggedOverPosition && !isSelfDraggedOver && draggedOverDraggable && _jsx(DropEffect, {
                        dropEffect: dropEffect({
                            source: draggedOverDraggable,
                            treeInstruction: treeInstruction
                        }),
                        position: draggedOverPosition
                    })
                ]
            }),
            _jsxs(Collapsible.Content, {
                style: {
                    display: dragging ? 'none' : undefined
                },
                children: [
                    _jsx("div", {
                        className: styles.collapseContentPlaceholder,
                        children: childCount === 0 && !collapsed && childrenPlaceholder
                    }),
                    _jsx(ExplorerTreeContext.Provider, {
                        value: contextValue,
                        children: collapsed ? null : children
                    })
                ]
            })
        ]
    });
};
