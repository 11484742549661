import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { useCallback } from 'react';
import * as styles from './selector-layout.css';
export const SelectorLayout = ({ children, searchPlaceholder, selectedCount, onSearch, onClear, onCancel, onConfirm, actions })=>{
    const t = useI18n();
    const onSearchChange = useCallback((e)=>{
        onSearch?.(e.target.value);
    }, [
        onSearch
    ]);
    return _jsxs("div", {
        className: styles.root,
        children: [
            _jsx("header", {
                className: styles.header,
                children: _jsx("input", {
                    className: styles.search,
                    placeholder: searchPlaceholder,
                    onChange: onSearchChange
                })
            }),
            _jsx("main", {
                className: styles.content,
                children: children
            }),
            _jsxs("footer", {
                className: styles.footer,
                children: [
                    _jsxs("div", {
                        className: styles.footerInfo,
                        children: [
                            _jsxs("div", {
                                className: styles.selectedCount,
                                children: [
                                    _jsx("span", {
                                        children: t['com.affine.selectPage.selected']()
                                    }),
                                    _jsx("span", {
                                        className: styles.selectedNum,
                                        children: selectedCount ?? 0
                                    })
                                ]
                            }),
                            _jsx(Button, {
                                variant: "plain",
                                className: styles.clearButton,
                                onClick: onClear,
                                children: t['com.affine.editCollection.pages.clear']()
                            })
                        ]
                    }),
                    _jsx("div", {
                        className: styles.footerAction,
                        children: actions ?? _jsxs(_Fragment, {
                            children: [
                                _jsx(Button, {
                                    onClick: onCancel,
                                    className: styles.actionButton,
                                    children: t['Cancel']()
                                }),
                                _jsx(Button, {
                                    onClick: onConfirm,
                                    className: styles.actionButton,
                                    variant: "primary",
                                    children: t['Confirm']()
                                })
                            ]
                        })
                    })
                ]
            })
        ]
    });
};
