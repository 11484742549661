import { createZodUnion } from '../../../_common/utils/index.js';
export var ShapeType;
(function(ShapeType) {
    ShapeType["Diamond"] = "diamond";
    ShapeType["Ellipse"] = "ellipse";
    ShapeType["Rect"] = "rect";
    ShapeType["Triangle"] = "triangle";
})(ShapeType || (ShapeType = {}));
export var SHAPE_TEXT_FONT_SIZE;
(function(SHAPE_TEXT_FONT_SIZE) {
    SHAPE_TEXT_FONT_SIZE[SHAPE_TEXT_FONT_SIZE["LARGE"] = 28] = "LARGE";
    SHAPE_TEXT_FONT_SIZE[SHAPE_TEXT_FONT_SIZE["MEDIUM"] = 20] = "MEDIUM";
    SHAPE_TEXT_FONT_SIZE[SHAPE_TEXT_FONT_SIZE["SMALL"] = 12] = "SMALL";
    SHAPE_TEXT_FONT_SIZE[SHAPE_TEXT_FONT_SIZE["XLARGE"] = 36] = "XLARGE";
})(SHAPE_TEXT_FONT_SIZE || (SHAPE_TEXT_FONT_SIZE = {}));
export const FILL_COLORS = [
    '--affine-palette-shape-yellow',
    '--affine-palette-shape-orange',
    '--affine-palette-shape-red',
    '--affine-palette-shape-magenta',
    '--affine-palette-shape-purple',
    '--affine-palette-shape-blue',
    '--affine-palette-shape-teal',
    '--affine-palette-shape-green',
    '--affine-palette-shape-black',
    '--affine-palette-shape-grey',
    '--affine-palette-shape-white'
];
export const DEFAULT_SHAPE_FILL_COLOR = FILL_COLORS[0];
export const FillColorsSchema = createZodUnion(FILL_COLORS);
export const STROKE_COLORS = [
    '--affine-palette-line-yellow',
    '--affine-palette-line-orange',
    '--affine-palette-line-red',
    '--affine-palette-line-magenta',
    '--affine-palette-line-purple',
    '--affine-palette-line-blue',
    '--affine-palette-line-teal',
    '--affine-palette-line-green',
    '--affine-palette-line-black',
    '--affine-palette-line-grey',
    '--affine-palette-line-white'
];
export const DEFAULT_SHAPE_STROKE_COLOR = STROKE_COLORS[0];
export const DEFAULT_SHAPE_TEXT_COLOR = STROKE_COLORS[8];
export const StrokeColorsSchema = createZodUnion(STROKE_COLORS);
