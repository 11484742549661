import { jsx as _jsx } from "react/jsx-runtime";
import { EditFeedModal } from '@affine/core/components/page-list/feed/edit-feed';
import { useCallback, useState } from 'react';
export const useEditSubscriptionModal = ({ title, showTips })=>{
    const [data, setData] = useState();
    const close = useCallback(()=>setData(undefined), []);
    return {
        node: data ? _jsx(EditFeedModal, {
            showTips: showTips,
            title: title,
            init: data.name,
            open: !!data,
            onOpenChange: close,
            onConfirm: data.onConfirm
        }) : null,
        open: (name)=>new Promise((res)=>{
                setData({
                    name,
                    onConfirm: (feed)=>{
                        res(feed);
                    }
                });
            })
    };
};
