import { assertEquals } from '@blocksuite/global/utils';
export class ColumnDataStats {
    constructor(column){
        this.column = column;
        this.dataViewManager = column.view;
    }
    _assertColumnType(type) {
        assertEquals(this.column.type, type, `This function should only be called in a column of type ${type}`);
    }
    _getAllValuesAsString(group) {
        const colType = this.column.type;
        const colValues = [];
        for (const rId of group?.rows ?? this.dataViewManager.rows$.value){
            switch(colType){
                case 'multi-select':
                    {
                        const options = this.column.data$.value.options ?? [];
                        const values = this.column.getValue(rId) ?? [];
                        const map = new Map(options?.map((v)=>[
                                v.id,
                                v
                            ]));
                        for (const id of values){
                            const opt = map.get(id);
                            if (opt) colValues.push(opt.value);
                        }
                        break;
                    }
                default:
                    {
                        const value = this.column.getStringValue(rId);
                        if (value.trim() !== '') colValues.push(value);
                    }
            }
        }
        return colValues;
    }
    _getCheckBoxColValues(group) {
        this._assertColumnType('checkbox');
        const val = (group?.rows ?? this.dataViewManager.rows$.value).map((rId)=>{
            return this.column.getValue(rId);
        });
        return val;
    }
    _getColValuesAsNumber(group) {
        this._assertColumnType('number');
        const values = [];
        for (const rId of group?.rows ?? this.dataViewManager.rows$.value){
            const value = this.column.getValue(rId);
            if (value !== undefined) values.push(value);
        }
        return values;
    }
    _getColValuesAsString(group, noEmpty = false) {
        const val = (group?.rows ?? this.dataViewManager.rows$.value).map((rId)=>{
            return this.column.getStringValue(rId);
        });
        return noEmpty ? val.filter((v)=>v.trim() !== '') : val;
    }
    _getColumnValueCounts(group) {
        return this._getAllValuesAsString(group).length;
    }
    _getEmptyCellCount(group) {
        let empty = 0;
        const rows = group?.rows ?? this.dataViewManager.rows$.value;
        for (const rId of rows){
            const colVal = this.column.getStringValue(rId).trim();
            if (colVal === '') empty++;
        }
        return empty;
    }
    _getNonEmptyCellCount(group) {
        let notEmpty = 0;
        const rows = group?.rows ?? this.dataViewManager.rows$.value;
        for (const rId of rows){
            const colVal = this.column.getStringValue(rId).trim();
            if (colVal !== '') notEmpty++;
        }
        return notEmpty;
    }
    checked(group) {
        let checked = 0;
        const values = this._getCheckBoxColValues(group);
        for (const value of values){
            if (value) checked++;
        }
        return checked;
    }
    countAll(group) {
        return group?.rows.length ?? this.dataViewManager.rows$.value.length;
    }
    countEmpty(group) {
        return this._getEmptyCellCount(group);
    }
    countNonEmpty(group) {
        return this._getNonEmptyCellCount(group);
    }
    countUniqueValues(group) {
        return [
            ...new Set(this._getAllValuesAsString(group))
        ].length;
    }
    countValues(group) {
        return this._getColumnValueCounts(group);
    }
    max(group) {
        const values = this._getColValuesAsNumber(group);
        return Math.max(...values);
    }
    mean(group) {
        const values = this._getColValuesAsNumber(group);
        let sum = 0;
        for (const val of values)sum += val;
        return sum / values.length;
    }
    median(group) {
        const values = this._getColValuesAsNumber(group).sort((a, b)=>a - b);
        const n = values.length;
        const mid = Math.floor(n / 2);
        if (n % 2 === 0) {
            return (values[mid - 1] + values[mid]) / 2;
        } else {
            return values[mid];
        }
    }
    min(group) {
        const values = this._getColValuesAsNumber(group);
        return Math.min(...values);
    }
    mode(group) {
        const values = this._getColValuesAsNumber(group);
        const frequencyMap = new Map();
        values.forEach((value)=>{
            const cur = frequencyMap.get(value);
            cur === undefined ? frequencyMap.set(value, 1) : frequencyMap.set(value, cur + 1);
        });
        let mode = 0;
        let maxFrequency = 0;
        frequencyMap.forEach((frequency, element)=>{
            if (frequency > maxFrequency) {
                mode = element;
                maxFrequency = frequency;
            }
        });
        return mode;
    }
    notChecked(group) {
        let notChecked = 0;
        const values = this._getCheckBoxColValues(group);
        for (const value of values){
            if (!value) notChecked++;
        }
        return notChecked;
    }
    percentChecked(group) {
        this._assertColumnType('checkbox');
        return this.checked(group) / this.countAll(group);
    }
    percentEmpty(group) {
        return this._getEmptyCellCount(group) / this.countAll(group);
    }
    percentNonEmpty(group) {
        return 1.0 - this.percentEmpty(group);
    }
    percentNotChecked(group) {
        this._assertColumnType('checkbox');
        return 1.0 - this.percentChecked(group);
    }
    range(group) {
        return this.max(group) - this.min(group);
    }
    sum(group) {
        const values = this._getColValuesAsNumber(group);
        let sum = 0;
        for (const val of values)sum += val;
        return sum;
    }
}
