import ar from './ar.json';
import ca from './ca.json';
import da from './da.json';
import de from './de.json';
import en from './en.json';
import en_US from './en-US.json';
import en_Wemem from './en-wemem.json';
import es from './es.json';
import es_AR from './es-AR.json';
import es_CL from './es-CL.json';
import fr from './fr.json';
import hi from './hi.json';
import it from './it.json';
import ja from './ja.json';
import ko from './ko.json';
import pt_BR from './pt-BR.json';
import ru from './ru.json';
import sv_SE from './sv-SE.json';
import ur from './ur.json';
import zh_Hans from './zh-Hans.json';
import zh_Hans_Wemem from './zh-Hans-wemem.json';
import zh_Hant from './zh-Hant.json';
export const LOCALES = [
    {
        id: 1000040010,
        name: 'Korean (South Korea)',
        tag: 'ko',
        originalName: '한국어(대한민국)',
        flagEmoji: '🇰🇷',
        base: false,
        completeRate: 0.726,
        res: ko
    },
    {
        id: 1000040021,
        name: 'Portuguese (Brazil)',
        tag: 'pt-BR',
        originalName: 'português (Brasil)',
        flagEmoji: '🇧🇷',
        base: false,
        completeRate: 0.317,
        res: pt_BR
    },
    {
        id: 1000040001,
        name: 'English',
        tag: 'en',
        originalName: 'English',
        flagEmoji: '🇬🇧',
        base: true,
        completeRate: 1,
        res: Object.assign({}, en, en_Wemem)
    },
    {
        id: 1000040003,
        name: 'Traditional Chinese',
        tag: 'zh-Hant',
        originalName: '繁體中文',
        flagEmoji: '🇭🇰',
        base: false,
        completeRate: 0.34,
        res: zh_Hant
    },
    {
        id: 1000040004,
        name: 'Simplified Chinese',
        tag: 'zh-Hans',
        originalName: '简体中文',
        flagEmoji: '🇨🇳',
        base: false,
        res: Object.assign({}, zh_Hans, zh_Hans_Wemem),
        completeRate: 0.974
    },
    {
        id: 1000040006,
        name: 'French',
        tag: 'fr',
        originalName: 'français',
        flagEmoji: '🇫🇷',
        base: false,
        completeRate: 0.877,
        res: fr
    },
    {
        id: 1000040008,
        name: 'Spanish',
        tag: 'es',
        originalName: 'español',
        flagEmoji: '🇪🇸',
        base: false,
        completeRate: 0.241,
        res: es
    },
    {
        id: 1000040009,
        name: 'German',
        tag: 'de',
        originalName: 'Deutsch',
        flagEmoji: '🇩🇪',
        base: false,
        completeRate: 0.277,
        res: de
    },
    {
        id: 1000040011,
        name: 'Russian',
        tag: 'ru',
        originalName: 'русский',
        flagEmoji: '🇷🇺',
        base: false,
        completeRate: 0.913,
        res: ru
    },
    {
        id: 1000040014,
        name: 'Japanese',
        tag: 'ja',
        originalName: '日本語',
        flagEmoji: '🇯🇵',
        base: false,
        completeRate: 0.414,
        res: ja
    },
    {
        id: 1000040023,
        name: 'Italian',
        tag: 'it',
        originalName: 'italiano',
        flagEmoji: '🇮🇹',
        base: false,
        completeRate: 0.001,
        res: it
    },
    {
        id: 1000070001,
        name: 'Catalan',
        tag: 'ca',
        originalName: 'català',
        flagEmoji: '🇦🇩',
        base: false,
        completeRate: 0.063,
        res: ca
    },
    {
        id: 1000074001,
        name: 'Danish',
        tag: 'da',
        originalName: 'dansk',
        flagEmoji: '🇩🇰',
        base: false,
        completeRate: 0.094,
        res: da
    },
    {
        id: 1000074002,
        name: 'English (United States)',
        tag: 'en-US',
        originalName: 'English (United States)',
        flagEmoji: '🇺🇸',
        base: false,
        completeRate: 0.009,
        res: en_US
    },
    {
        id: 1000074003,
        name: 'Spanish (Chile)',
        tag: 'es-CL',
        originalName: 'español (Chile)',
        flagEmoji: '🇨🇱',
        base: false,
        completeRate: 0.026,
        res: es_CL
    },
    {
        id: 1000074004,
        name: 'Hindi',
        tag: 'hi',
        originalName: 'हिन्दी',
        flagEmoji: '🇮🇳',
        base: false,
        completeRate: 0.016,
        res: hi
    },
    {
        id: 1000134010,
        name: 'Swedish (Sweden)',
        tag: 'sv-SE',
        originalName: 'svenska (Sverige)',
        flagEmoji: '🇸🇪',
        base: false,
        completeRate: 0.059,
        res: sv_SE
    },
    {
        id: 1000134011,
        name: 'Spanish (Argentina)',
        tag: 'es-AR',
        originalName: 'español (Argentina)',
        flagEmoji: '🇦🇷',
        base: false,
        completeRate: 0.094,
        res: es_AR
    },
    {
        id: 1000134012,
        name: 'Urdu',
        tag: 'ur',
        originalName: 'اردو',
        flagEmoji: '🇵🇰',
        base: false,
        completeRate: 0.021,
        res: ur
    },
    {
        id: 1000134005,
        name: 'Arabic',
        tag: 'ar',
        originalName: 'العربية',
        flagEmoji: '🇸🇦',
        base: false,
        completeRate: 0.974,
        res: ar
    }
];
