import { html, render } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { deltaInsertsToChunks, renderElement, transformDeltasToEmbedDeltas } from '../utils/index.js';
export class DeltaService {
    constructor(editor){
        this.editor = editor;
        this.getDeltaByRangeIndex = (rangeIndex)=>{
            const deltas = this.deltas;
            let index = 0;
            for (const delta of deltas){
                if (index + delta.insert.length >= rangeIndex) {
                    return delta;
                }
                index += delta.insert.length;
            }
            return null;
        };
        this.getDeltasByInlineRange = (inlineRange)=>{
            return this.mapDeltasInInlineRange(inlineRange, (delta, index)=>[
                    delta,
                    {
                        index,
                        length: delta.insert.length
                    }
                ]);
        };
        this.mapDeltasInInlineRange = (inlineRange, callback, normalize = false)=>{
            const deltas = normalize ? transformDeltasToEmbedDeltas(this.editor, this.deltas) : this.deltas;
            const result = [];
            deltas.reduce((rangeIndex, delta, deltaIndex)=>{
                const length = delta.insert.length;
                const from = inlineRange.index - length;
                const to = inlineRange.index + inlineRange.length;
                const deltaInRange = rangeIndex >= from && (rangeIndex < to || inlineRange.length === 0 && rangeIndex === inlineRange.index);
                if (deltaInRange) {
                    const value = callback(delta, rangeIndex, deltaIndex);
                    result.push(value);
                }
                return rangeIndex + length;
            }, 0);
            return result;
        };
        this.render = async (syncInlineRange = true)=>{
            if (!this.editor.mounted) return;
            this.editor.slots.render.emit();
            const rootElement = this.editor.rootElement;
            const normalizedDeltas = transformDeltasToEmbedDeltas(this.editor, this.deltas);
            const chunks = deltaInsertsToChunks(normalizedDeltas);
            let normalizedDeltaIndex = 0;
            const lines = chunks.map((chunk, index)=>{
                if (chunk.length > 0) {
                    const lineDeltas = [];
                    chunk.forEach((delta)=>{
                        lineDeltas.push([
                            delta,
                            normalizedDeltaIndex
                        ]);
                        normalizedDeltaIndex++;
                    });
                    const elements = lineDeltas.map(([delta, normalizedDeltaIndex])=>{
                        let selected = false;
                        const inlineRange = this.editor.getInlineRange();
                        if (inlineRange) {
                            selected = this.isNormalizedDeltaSelected(normalizedDeltaIndex, inlineRange);
                        }
                        return [
                            renderElement(delta, this.editor.attributeService.normalizeAttributes, selected),
                            delta
                        ];
                    });
                    return html`<v-line .elements=${elements} .index=${index}></v-line>`;
                } else {
                    return html`<v-line .elements=${[]} .index=${index}></v-line>`;
                }
            });
            try {
                render(repeat(lines.map((line, i)=>({
                        line,
                        index: i
                    })), (entry)=>entry.index, (entry)=>entry.line), rootElement);
            } catch (_) {
                this.editor.rerenderWholeEditor();
                await this.editor.waitForUpdate();
            }
            await this.editor.waitForUpdate();
            if (syncInlineRange) {
                this.editor.rangeService.syncInlineRange();
            }
            this.editor.slots.renderComplete.emit();
        };
    }
    isNormalizedDeltaSelected(normalizedDeltaIndex, inlineRange) {
        let result = false;
        if (inlineRange.length >= 1) {
            this.editor.mapDeltasInInlineRange(inlineRange, (_, rangeIndex, deltaIndex)=>{
                if (deltaIndex === normalizedDeltaIndex && rangeIndex >= inlineRange.index) {
                    result = true;
                }
            }, true);
        }
        return result;
    }
    get deltas() {
        return this.editor.yText.toDelta();
    }
}
