function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _init__askAIButton, _init_buttonText, _init_host, _init_actionGroups, _init_toggleType, _init_options, _initProto;
import './ask-ai-panel';
import { WithDisposable } from '@blocksuite/block-std';
import { AIStarIcon, EdgelessRootService } from '@blocksuite/blocks';
import { createLitPortal, HoverController } from '@blocksuite/blocks';
import { assertExists } from '@blocksuite/global/utils';
import { flip, offset } from '@floating-ui/dom';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { ref } from 'lit/directives/ref.js';
import { styleMap } from 'lit/directives/style-map.js';
import { getRootService } from '../../utils/selection-utils';
const buttonWidthMap = {
    small: '72px',
    middle: '76px',
    large: '82px'
};
const buttonHeightMap = {
    small: '24px',
    middle: '32px',
    large: '32px'
};
let _AskAIButton;
_dec = customElement('ask-ai-button'), _dec1 = query('.ask-ai-button'), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_AskAIButton), _initClass();
    }
    static{
        class AskAIButton extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init__askAIButton, _init_buttonText, _init_host, _init_actionGroups, _init_toggleType, _init_options, _initProto], c: [_AskAIButton, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_askAIButton"
                    ],
                    [
                        _dec2,
                        1,
                        "buttonText"
                    ],
                    [
                        _dec3,
                        1,
                        "host"
                    ],
                    [
                        _dec4,
                        1,
                        "actionGroups"
                    ],
                    [
                        _dec5,
                        1,
                        "toggleType"
                    ],
                    [
                        _dec6,
                        1,
                        "options"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            get _edgeless() {
                const rootService = getRootService(this.host);
                if (rootService instanceof EdgelessRootService) {
                    return rootService;
                }
                return null;
            }
            static{
                this.styles = css`
    .ask-ai-button {
      border-radius: 4px;
      position: relative;
    }

    .ask-ai-icon-button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--affine-brand-color);
      font-size: var(--affine-font-sm);
      font-weight: 500;
    }

    .ask-ai-icon-button.small {
      font-size: var(--affine-font-xs);
      svg {
        scale: 0.8;
        margin-right: 2px;
      }
    }

    .ask-ai-icon-button.large {
      font-size: var(--affine-font-md);
      svg {
        scale: 1.2;
      }
    }

    .ask-ai-icon-button span {
      line-height: 22px;
    }

    .ask-ai-icon-button svg {
      margin-right: 4px;
      color: var(--affine-brand-color);
    }
  `;
            }
            #___private__askAIButton_1;
            get _askAIButton() {
                return this.#___private__askAIButton_1;
            }
            set _askAIButton(_v) {
                this.#___private__askAIButton_1 = _v;
            }
            #___private_buttonText_2;
            get buttonText() {
                return this.#___private_buttonText_2;
            }
            set buttonText(_v) {
                this.#___private_buttonText_2 = _v;
            }
            #___private_host_3;
            get host() {
                return this.#___private_host_3;
            }
            set host(_v) {
                this.#___private_host_3 = _v;
            }
            #___private_actionGroups_4;
            get actionGroups() {
                return this.#___private_actionGroups_4;
            }
            set actionGroups(_v) {
                this.#___private_actionGroups_4 = _v;
            }
            #___private_toggleType_5;
            get toggleType() {
                return this.#___private_toggleType_5;
            }
            set toggleType(_v) {
                this.#___private_toggleType_5 = _v;
            }
            #___private_options_6;
            get options() {
                return this.#___private_options_6;
            }
            set options(_v) {
                this.#___private_options_6 = _v;
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this._clearAbortController();
            }
            render() {
                const { size = 'small', backgroundColor, boxShadow } = this.options;
                const { toggleType } = this;
                const buttonStyles = styleMap({
                    backgroundColor: backgroundColor || 'transparent',
                    boxShadow: boxShadow || 'none'
                });
                return html`<div
      class="ask-ai-button"
      style=${buttonStyles}
      ${toggleType === 'hover' ? ref(this._whenHover.setReference) : nothing}
      @click=${this._toggleAIPanel}
    >
      <icon-button
        class="ask-ai-icon-button ${size}"
        width=${buttonWidthMap[size]}
        height=${buttonHeightMap[size]}
      >
        ${AIStarIcon} <span>${this.buttonText || 'Ask AI'}</span></icon-button
      >
    </div>`;
            }
            constructor(...args){
                super(...args);
                this.#___private__askAIButton_1 = (_initProto(this), _init__askAIButton(this));
                this._abortController = null;
                this._whenHover = new HoverController(this, ({ abortController })=>{
                    return {
                        template: html`<ask-ai-panel
          .host=${this.host}
          .actionGroups=${this.actionGroups}
          .abortController=${abortController}
        ></ask-ai-panel>`,
                        computePosition: {
                            referenceElement: this,
                            placement: 'bottom-start',
                            middleware: [
                                flip(),
                                offset({
                                    mainAxis: 8,
                                    crossAxis: -6
                                })
                            ],
                            autoUpdate: true
                        }
                    };
                }, {
                    allowMultiple: true
                });
                this.#___private_buttonText_2 = _init_buttonText(this, '');
                this.#___private_host_3 = _init_host(this);
                this.#___private_actionGroups_4 = _init_actionGroups(this);
                this.#___private_toggleType_5 = _init_toggleType(this, 'hover');
                this.#___private_options_6 = _init_options(this, {
                    size: 'middle',
                    backgroundColor: undefined,
                    boxShadow: undefined,
                    panelWidth: 330
                });
                this._clearAbortController = ()=>{
                    if (this._abortController) {
                        this._abortController.abort();
                        this._abortController = null;
                    }
                };
                this._toggleAIPanel = ()=>{
                    if (this.toggleType !== 'click') {
                        return;
                    }
                    if (this._abortController) {
                        this._clearAbortController();
                        return;
                    }
                    this._abortController = new AbortController();
                    assertExists(this._askAIButton);
                    const panelMinWidth = this.options.panelWidth || 330;
                    createLitPortal({
                        template: html`<ask-ai-panel
        .host=${this.host}
        .actionGroups=${this.actionGroups}
        .minWidth=${panelMinWidth}
      ></ask-ai-panel>`,
                        container: this._askAIButton,
                        computePosition: {
                            referenceElement: this._askAIButton,
                            placement: 'bottom-start',
                            middleware: [
                                flip(),
                                offset({
                                    mainAxis: 8,
                                    crossAxis: -6
                                })
                            ],
                            autoUpdate: true
                        },
                        abortController: this._abortController,
                        closeOnClickAway: true
                    });
                };
            }
        }
    }
}();
export { _AskAIButton as AskAIButton };
