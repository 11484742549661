function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _init_children, _init_layoutType, _init_style, _initProto;
import { GfxGroupLikeElementModel, convert, observe, watch, yfield } from '@blocksuite/block-std/gfx';
import { assertType, deserializeXYWH } from '@blocksuite/global/utils';
import { DocCollection } from '@blocksuite/store';
import { generateKeyBetween } from 'fractional-indexing';
import { z } from 'zod';
import { keys, last, pick } from '../../_common/utils/iterable.js';
import { TextResizing } from '../consts.js';
import { ConnectorPathGenerator } from '../managers/connector-manager.js';
import { LocalConnectorElementModel } from './connector.js';
import { LayoutType, layout } from './utils/mindmap/layout.js';
import { MindmapStyle, applyNodeStyle, mindmapStyleGetters } from './utils/mindmap/style.js';
export { LayoutType } from './utils/mindmap/layout.js';
export { MindmapStyle } from './utils/mindmap/style.js';
const baseNodeSchema = z.object({
    text: z.string(),
    xywh: z.optional(z.string())
});
const nodeSchema = baseNodeSchema.extend({
    children: z.lazy(()=>nodeSchema.array()).optional()
});
function isNodeType(node) {
    return typeof node.text === 'string' && Array.isArray(node.children);
}
_dec = convert((initialValue, instance)=>{
    if (!(initialValue instanceof DocCollection.Y.Map)) {
        nodeSchema.parse(initialValue);
        assertType(initialValue);
        const map = new DocCollection.Y.Map();
        const surface = instance.surface;
        const doc = surface.doc;
        const recursive = (node, parent = null, index = 'a0')=>{
            const id = surface.addElement({
                type: 'shape',
                text: node.text,
                xywh: node.xywh ? node.xywh : `[0, 0, 100, 30]`
            });
            map.set(id, {
                index,
                parent: parent ?? undefined
            });
            let curIdx = 'a0';
            node.children?.forEach((childNode)=>{
                recursive(childNode, id, curIdx);
                curIdx = generateKeyBetween(curIdx, null);
            });
        };
        doc.transact(()=>{
            recursive(initialValue);
        });
        instance.requestBuildTree();
        instance.requestLayout();
        return map;
    } else {
        instance.requestBuildTree();
        instance.requestLayout();
        return initialValue;
    }
}), _dec1 = observe((_, instance, transaction)=>{
    instance.setChildIds(Array.from(instance.children.keys()), transaction?.local ?? true);
    instance.buildTree();
    instance.connectors.clear();
}), _dec2 = yfield(), _dec3 = watch((_, instance, local)=>{
    if (!local) {
        return;
    }
    instance.surface.doc.transact(()=>{
        instance['_tree']?.children.forEach((child)=>{
            if (!instance.children.has(child.id)) {
                return;
            }
            instance.children.set(child.id, {
                index: child.detail.index,
                parent: child.detail.parent
            });
        });
    });
    instance.buildTree();
}), _dec4 = yfield(), _dec5 = watch((_, instance, local)=>{
    if (local) {
        instance.layout();
    }
}), _dec6 = yfield();
export class MindmapElementModel extends GfxGroupLikeElementModel {
    static{
        ({ e: [_init_children, _init_layoutType, _init_style, _initProto] } = _apply_decs_2203_r(this, [
            [
                [
                    _dec,
                    _dec1,
                    _dec2
                ],
                1,
                "children"
            ],
            [
                [
                    _dec3,
                    _dec4
                ],
                1,
                "layoutType"
            ],
            [
                [
                    _dec5,
                    _dec6
                ],
                1,
                "style"
            ]
        ], []));
    }
    static createFromTree(tree, style, layoutType, surface) {
        const children = new DocCollection.Y.Map();
        const traverse = (subtree, parent)=>{
            const value = {
                ...subtree.detail,
                parent
            };
            if (!parent) {
                delete value.parent;
            }
            children.set(subtree.id, value);
            subtree.children.forEach((child)=>traverse(child, subtree.id));
        };
        traverse(tree);
        const mindmapId = surface.addElement({
            type: 'mindmap',
            children,
            layoutType,
            style
        });
        const mindmap = surface.getElementById(mindmapId);
        mindmap.layout();
        return mindmap;
    }
    static propsToY(props) {
        if (props.children && !isNodeType(props.children) && !(props.children instanceof DocCollection.Y.Map)) {
            const children = new DocCollection.Y.Map();
            keys(props.children).forEach((key)=>{
                const detail = pick(props.children[key], [
                    'index',
                    'parent',
                    'preferredDir'
                ]);
                children.set(key, detail);
            });
            props.children = children;
        }
        return props;
    }
    _cfgBalanceLayoutDir() {
        if (this.layoutType !== LayoutType.BALANCE) {
            return;
        }
        const tree = this._tree;
        const splitPoint = tree.children.findIndex((child, index)=>{
            if (child.detail.preferredDir === LayoutType.LEFT || child.detail.preferredDir === LayoutType.RIGHT && child.children[index + 1]?.detail.preferredDir !== LayoutType.RIGHT) {
                return true;
            }
            return false;
        });
        if (splitPoint === -1) {
            const mid = Math.ceil(tree.children.length / 2);
            tree.right.push(...tree.children.slice(0, mid));
            tree.left.push(...tree.children.slice(mid));
        } else {
            tree.right.push(...tree.children.slice(0, splitPoint + 1));
            tree.left.push(...tree.children.slice(splitPoint + 1));
        }
        tree.left.reverse();
    }
    _isConnectorOutdated(options, updateKey = true) {
        const { connector, from, to, layout } = options;
        const cacheKey = `${from.element.xywh}-${to.element.xywh}-${layout}-${this.style}`;
        if (connector['MINDMAP_CONNECTOR'] === cacheKey) {
            return {
                outdated: false,
                cacheKey
            };
        } else if (updateKey) {
            connector['MINDMAP_CONNECTOR'] = cacheKey;
        }
        return {
            outdated: true,
            cacheKey
        };
    }
    addConnector(from, to, layout, connectorStyle, extra = false) {
        const id = `#${from.id}-${to.id}`;
        if (extra) {
            this.extraConnectors.set(id, new LocalConnectorElementModel());
        } else if (this.connectors.has(id)) {
            const connector = this.connectors.get(id);
            const { outdated } = this._isConnectorOutdated({
                connector,
                from,
                to,
                layout
            });
            if (!outdated) {
                return connector;
            }
        } else {
            const connector = new LocalConnectorElementModel();
            this._isConnectorOutdated({
                connector,
                from,
                to,
                layout
            });
            this.connectors.set(id, connector);
        }
        const connector = extra ? this.extraConnectors.get(id) : this.connectors.get(id);
        connector.id = id;
        connector.source = {
            id: from.id,
            position: layout === LayoutType.RIGHT ? [
                1,
                0.5
            ] : [
                0,
                0.5
            ]
        };
        connector.target = {
            id: to.id,
            position: layout === LayoutType.RIGHT ? [
                0,
                0.5
            ] : [
                1,
                0.5
            ]
        };
        Object.entries(connectorStyle).forEach(([key, value])=>{
            connector[key] = value;
        });
        this.pathGenerator.updatePath(connector);
        return connector;
    }
    addNode(parent, sibling, position = 'after', props = {}, direction) {
        if (parent && typeof parent !== 'string') {
            parent = parent.id;
        }
        assertType(parent);
        if (parent && !this._nodeMap.has(parent)) {
            throw new Error(`Parent node ${parent} not found`);
        }
        props['text'] = new DocCollection.Y.Text(props['text'] ?? 'New node');
        const type = props.type ?? 'shape';
        let id;
        this.surface.doc.transact(()=>{
            const parentNode = parent ? this._nodeMap.get(parent) : null;
            if (parentNode) {
                const isBalance = this.layoutType === LayoutType.BALANCE && this._tree.id === parentNode.id;
                let index = last(parentNode.children) ? generateKeyBetween(last(parentNode.children).detail.index, null) : 'a0';
                sibling = sibling ?? last(parentNode.children)?.id;
                const siblingNode = typeof sibling === 'number' ? parentNode.children[sibling] : sibling ? this._nodeMap.get(sibling) : undefined;
                const path = siblingNode ? this.getPath(siblingNode) : this.getPath(parentNode).concat([
                    0
                ]);
                const style = this.styleGetter.getNodeStyle(siblingNode ?? parentNode, path);
                id = this.surface.addElement({
                    type,
                    xywh: '[0,0,100,30]',
                    textResizing: TextResizing.AUTO_WIDTH,
                    maxWidth: false,
                    ...props,
                    ...style.node
                });
                if (siblingNode) {
                    const siblingIndex = parentNode.children.findIndex((val)=>val.id === sibling);
                    index = position === 'after' ? generateKeyBetween(siblingNode.detail.index, parentNode.children[siblingIndex + 1]?.detail.index ?? null) : generateKeyBetween(parentNode.children[siblingIndex - 1]?.detail.index ?? null, siblingNode.detail.index);
                } else if (isBalance && direction !== undefined) {
                    const lastNode = direction === LayoutType.LEFT ? this._tree.left[0] : last(this._tree.right);
                    if (lastNode) {
                        index = generateKeyBetween(lastNode.detail.index, null);
                    }
                }
                const nodeDetail = {
                    index,
                    parent: parent
                };
                if (direction !== undefined && isBalance) {
                    nodeDetail.preferredDir = direction;
                }
                this.children.set(id, nodeDetail);
            } else {
                const rootStyle = this.styleGetter.root;
                id = this.surface.addElement({
                    type,
                    xywh: '[0,0,113,41]',
                    textResizing: TextResizing.AUTO_WIDTH,
                    maxWidth: 400,
                    ...props,
                    ...rootStyle
                });
                this.children.clear();
                this.children.set(id, {
                    index: 'a0'
                });
            }
        });
        this.layout();
        return id;
    }
    addTree(parent, tree, sibling, layout) {
        parent = typeof parent === 'string' ? parent : parent.id;
        if (!this._nodeMap.has(parent) || !parent) {
            return null;
        }
        assertType(parent);
        if (layout === LayoutType.BALANCE || this._nodeMap.get(parent) !== this._tree) {
            layout = undefined;
        }
        const traverse = (node, parent, sibling, layout)=>{
            let nodeId;
            if ('text' in node) {
                nodeId = this.addNode(parent, sibling, 'before', {
                    text: node.text
                }, layout);
            } else {
                this.children.set(node.id, {
                    ...node.detail,
                    parent
                });
                nodeId = node.id;
            }
            node.children?.forEach((child)=>{
                traverse(child, nodeId);
            });
            return nodeId;
        };
        if (!('text' in tree)) {
            this.surface.doc.transact(()=>{
                traverse(tree, parent, sibling, layout);
            });
            this.applyStyle();
            this.layout();
            return this._nodeMap.get(tree.id);
        } else {
            const nodeId = traverse(tree, parent, sibling, layout);
            this.layout();
            return this._nodeMap.get(nodeId);
        }
    }
    applyStyle(fitContent = false) {
        this.surface.doc.transact(()=>{
            const style = this.styleGetter;
            if (!style) return;
            applyNodeStyle(this._tree, style.root, fitContent);
            const walk = (node, path)=>{
                node.children.forEach((child, idx)=>{
                    const currentPath = [
                        ...path,
                        idx
                    ];
                    const nodeStyle = style.getNodeStyle(child, currentPath);
                    applyNodeStyle(child, nodeStyle.node, fitContent);
                    walk(child, currentPath);
                });
            };
            walk(this._tree, [
                0
            ]);
        });
    }
    buildTree() {
        const mindmapNodeMap = new Map();
        const nodesMap = this.children;
        if (!nodesMap) {
            this._nodeMap = mindmapNodeMap;
            this._tree = null;
            return;
        }
        let rootNode;
        nodesMap.forEach((val, id)=>{
            const node = mindmapNodeMap.has(id) ? mindmapNodeMap.get(id) : {
                id,
                parent: val.parent,
                index: val.index,
                detail: val,
                element: this.surface.getElementById(id),
                children: []
            };
            if (!node.detail) {
                node.detail = val;
            }
            if (!mindmapNodeMap.has(id)) {
                mindmapNodeMap.set(id, node);
            }
            if (!val.parent) {
                rootNode = node;
                rootNode.left = [];
                rootNode.right = [];
            } else if (mindmapNodeMap.has(val.parent)) {
                const parentNode = mindmapNodeMap.get(val.parent);
                parentNode.children = parentNode.children ?? [];
                parentNode.children.push(node);
            } else {
                mindmapNodeMap.set(val.parent, {
                    id: val.parent,
                    children: [
                        node
                    ],
                    element: this.surface.getElementById(val.parent)
                });
            }
        });
        mindmapNodeMap.forEach((node)=>{
            node.children.sort((a, b)=>a.detail.index === b.detail.index ? 0 : a.detail.index > b.detail.index ? 1 : -1);
        });
        if (!rootNode) {
            return;
        }
        this._nodeMap = mindmapNodeMap;
        this._tree = rootNode;
        if (this.layoutType === LayoutType.BALANCE) {
            this._cfgBalanceLayoutDir();
        } else {
            this._tree[this.layoutType === LayoutType.RIGHT ? 'right' : 'left'] = this._tree.children;
        }
    }
    detach(subtree) {
        subtree = typeof subtree === 'string' ? this._nodeMap.get(subtree) : subtree;
        assertType(subtree);
        if (!subtree) {
            return;
        }
        const traverse = (subtree)=>{
            this.children.delete(subtree.id);
            subtree.detail = {
                ...subtree.detail
            };
            subtree.children.forEach((child)=>traverse(child));
        };
        this.surface.doc.transact(()=>{
            traverse(subtree);
        });
        this.layout();
        delete subtree.detail.parent;
        return subtree;
    }
    getChildNodes(id, subtree) {
        const node = this._nodeMap.get(id);
        if (!node) {
            return [];
        }
        if (subtree && id === this._tree.id) {
            return this._tree[subtree];
        }
        return node.children;
    }
    getConnector(from, to) {
        if (!this._nodeMap.has(from.id) || !this._nodeMap.has(to.id)) {
            return null;
        }
        return this.addConnector(from, to, this.getLayoutDir(to), this.styleGetter.getNodeStyle(to, this.getPath(to)).connector);
    }
    getLayoutDir(node) {
        node = typeof node === 'string' ? this._nodeMap.get(node) : node;
        assertType(node);
        let current = node;
        const root = this._tree;
        while(current){
            if (current.overriddenDir !== undefined) {
                return current.overriddenDir;
            }
            const parent = current.detail.parent ? this._nodeMap.get(current.detail.parent) ?? null : null;
            if (parent === root) {
                return root.left.includes(current) ? LayoutType.LEFT : root.right.includes(current) ? LayoutType.RIGHT : this.layoutType;
            }
            current = parent;
        }
        return this.layoutType;
    }
    getNode(id) {
        return this._nodeMap.get(id) ?? null;
    }
    getParentNode(id) {
        const node = this.children.get(id);
        return node?.parent ? this._nodeMap.get(node.parent) ?? null : null;
    }
    getPath(element) {
        let node = this._nodeMap.get(typeof element === 'string' ? element : element.id);
        if (!node) {
            throw new Error('Node not found');
        }
        const path = [];
        while(node && node !== this._tree){
            const parent = this._nodeMap.get(node.detail.parent);
            path.unshift(parent.children.indexOf(node));
            node = parent;
        }
        path.unshift(0);
        return path;
    }
    getSiblingNode(id, direction = 'next', subtree) {
        const node = this._nodeMap.get(id);
        if (!node) {
            return null;
        }
        const parent = this._nodeMap.get(node.detail.parent);
        if (!parent) {
            return null;
        }
        const childrenTree = subtree && parent.id === this._tree.id ? this._tree[subtree] : parent.children;
        const idx = childrenTree.indexOf(node);
        if (idx === -1) {
            return null;
        }
        const siblingIndex = direction === 'next' ? idx + 1 : idx - 1;
        const sibling = childrenTree[siblingIndex] ?? null;
        return sibling;
    }
    layout(tree = this.tree, applyStyle = true, layoutType) {
        if (!tree || !tree.element) return;
        if (applyStyle) {
            this.applyStyle(true);
        }
        this.surface.doc.transact(()=>{
            const path = this.getPath(tree.id);
            layout(tree, this, layoutType ?? this.getLayoutDir(tree.id), path);
        });
    }
    moveTo(targetXYWH) {
        const { x, y } = this;
        const targetPos = typeof targetXYWH === 'string' ? deserializeXYWH(targetXYWH) : targetXYWH;
        const offsetX = targetPos[0] - x;
        const offsetY = targetPos[1] - y + targetPos[3];
        this.surface.doc.transact(()=>{
            this.childElements.forEach((el)=>{
                const deserializedXYWH = deserializeXYWH(el.xywh);
                el.xywh = `[${deserializedXYWH[0] + offsetX},${deserializedXYWH[1] + offsetY},${deserializedXYWH[2]},${deserializedXYWH[3]}]`;
            });
        });
    }
    moveTree(tree, parent, siblingIndex, layout) {
        parent = this._nodeMap.get(typeof parent === 'string' ? parent : parent.id);
        if (!parent || !this._nodeMap.has(tree.id)) {
            return;
        }
        assertType(parent);
        if (layout === LayoutType.BALANCE || parent !== this._tree) {
            layout = undefined;
        }
        const sibling = parent.children[siblingIndex];
        const preSibling = parent.children[siblingIndex - 1];
        const index = sibling || preSibling ? generateKeyBetween(preSibling?.detail.index ?? null, sibling?.detail.index ?? null) : tree.detail.index ?? undefined;
        this.surface.doc.transact(()=>{
            const val = layout !== undefined ? {
                ...tree.detail,
                index,
                parent: parent.id,
                preferredDir: layout
            } : {
                ...tree.detail,
                index,
                parent: parent.id
            };
            this.children.set(tree.id, val);
        });
        this.layout();
        return this._nodeMap.get(tree.id);
    }
    onCreated() {
        this.requestBuildTree();
    }
    removeChild(id) {
        if (!this._nodeMap.has(id)) {
            return;
        }
        const surface = this.surface;
        const removedDescendants = [];
        const remove = (element)=>{
            element.children?.forEach((child)=>{
                remove(child);
            });
            this.children?.delete(element.id);
            removedDescendants.push(element.id);
        };
        surface.doc.transact(()=>{
            remove(this._nodeMap.get(id));
        });
        queueMicrotask(()=>{
            removedDescendants.forEach((id)=>surface.removeElement(id));
        });
        this.buildTree();
        this.requestLayout();
    }
    requestBuildTree() {
        if (this._queueBuildTree) {
            return;
        }
        this._queueBuildTree = true;
        queueMicrotask(()=>{
            this.buildTree();
            this._queueBuildTree = false;
        });
    }
    requestLayout() {
        if (!this._queued) {
            this._queued = true;
            queueMicrotask(()=>{
                this.layout();
                this._queued = false;
            });
        }
    }
    serialize() {
        const result = super.serialize();
        return result;
    }
    stashTree(node) {
        const mindNode = typeof node === 'string' ? this.getNode(node) : node;
        if (!mindNode) {
            return;
        }
        const stashed = new Set();
        const traverse = (node, parent)=>{
            node.element.stash('xywh');
            node.element.opacity = 0.3;
            stashed.add(node.element);
            if (parent) {
                const connectorId = `#${parent.element.id}-${node.element.id}`;
                const connector = this.connectors.get(connectorId);
                if (connector) {
                    connector.opacity = 0.3;
                    stashed.add(connector);
                }
            }
            if (node.children.length) {
                node.children.forEach((child)=>traverse(child, node));
            }
        };
        const parent = this.getParentNode(mindNode.element.id);
        const parentNode = parent ? this.getNode(parent.id) : null;
        traverse(mindNode, parentNode);
        return ()=>{
            stashed.forEach((el)=>{
                if ('pop' in el) {
                    el.pop('xywh');
                }
                el.opacity = 1;
            });
        };
    }
    traverse(callback) {
        const traverse = (node, parent)=>{
            callback(node, parent);
            node?.children.forEach((child)=>{
                traverse(child, node);
            });
        };
        if (this._tree) {
            traverse(this._tree, null);
        }
    }
    get nodeMap() {
        return this._nodeMap;
    }
    get rotate() {
        return 0;
    }
    set rotate(_) {}
    get styleGetter() {
        return mindmapStyleGetters[this.style];
    }
    get tree() {
        return this._tree;
    }
    get type() {
        return 'mindmap';
    }
    #___private_children_1;
    get children() {
        return this.#___private_children_1;
    }
    set children(_v) {
        this.#___private_children_1 = _v;
    }
    #___private_layoutType_2;
    get layoutType() {
        return this.#___private_layoutType_2;
    }
    set layoutType(_v) {
        this.#___private_layoutType_2 = _v;
    }
    #___private_style_3;
    get style() {
        return this.#___private_style_3;
    }
    set style(_v) {
        this.#___private_style_3 = _v;
    }
    constructor(...args){
        super(...args);
        this._nodeMap = new Map();
        this._queueBuildTree = false;
        this._queued = false;
        this.connectors = new Map();
        this.extraConnectors = new Map();
        this.pathGenerator = new ConnectorPathGenerator({
            getElementById: (id)=>this.surface.getElementById(id) ?? this.surface.doc.getBlockById(id)
        });
        this.#___private_children_1 = (_initProto(this), _init_children(this, new DocCollection.Y.Map()));
        this.#___private_layoutType_2 = _init_layoutType(this, LayoutType.RIGHT);
        this.#___private_style_3 = _init_style(this, MindmapStyle.ONE);
    }
}
