import { getWorkspacePublicPageByIdQuery, publishPageMutation, revokePublicPageMutation } from '@affine/graphql';
import { Store } from '@toeverything/infra';
export class ShareStore extends Store {
    constructor(gqlService){
        super();
        this.gqlService = gqlService;
    }
    async getShareInfoByDocId(workspaceId, docId, signal) {
        const data = await this.gqlService.gql({
            query: getWorkspacePublicPageByIdQuery,
            variables: {
                pageId: docId,
                workspaceId
            },
            context: {
                signal
            }
        });
        return data.workspace.publicPage ?? undefined;
    }
    async enableSharePage(workspaceId, pageId, docMode, signal) {
        await this.gqlService.gql({
            query: publishPageMutation,
            variables: {
                pageId,
                workspaceId,
                mode: docMode
            },
            context: {
                signal
            }
        });
    }
    async disableSharePage(workspaceId, pageId, signal) {
        await this.gqlService.gql({
            query: revokePublicPageMutation,
            variables: {
                pageId,
                workspaceId
            },
            context: {
                signal
            }
        });
    }
}
