import { AICodeBlockSpec, AIImageBlockSpec, AIParagraphBlockSpec } from '@affine/core/blocksuite/presets/ai';
import { BookmarkBlockSpec, DatabaseBlockSpec, DataViewBlockSpec, DividerBlockSpec, EmbedFigmaBlockSpec, EmbedGithubBlockSpec, EmbedHtmlBlockSpec, EmbedLinkedDocBlockSpec, EmbedLoomBlockSpec, EmbedSyncedDocBlockSpec, EmbedYoutubeBlockSpec, ListBlockSpec, NoteBlockSpec } from '@blocksuite/blocks';
import { AIChatBlockSpec, EdgelessAIChatBlockSpec } from '@blocksuite/presets';
import { CustomAttachmentBlockSpec } from './custom/attachment-block';
export const CommonBlockSpecs = [
    ListBlockSpec,
    NoteBlockSpec,
    DatabaseBlockSpec,
    DataViewBlockSpec,
    DividerBlockSpec,
    BookmarkBlockSpec,
    EmbedFigmaBlockSpec,
    EmbedGithubBlockSpec,
    EmbedYoutubeBlockSpec,
    EmbedLoomBlockSpec,
    EmbedHtmlBlockSpec,
    EmbedSyncedDocBlockSpec,
    EmbedLinkedDocBlockSpec,
    CustomAttachmentBlockSpec,
    AICodeBlockSpec,
    AIImageBlockSpec,
    AIParagraphBlockSpec,
    AIChatBlockSpec,
    EdgelessAIChatBlockSpec
];
