import { tagColors } from '@affine/core/components/affine/page-properties/common';
import { useI18n } from '@affine/i18n';
import { useCallback } from 'react';
export const InternalTagPrefix = 'ai.wemem.internal-tags.';
export const RefPageTagPrefix = `${InternalTagPrefix}ref-page.`;
export const FeedTag = {
    id: 'Feed',
    value: `${InternalTagPrefix}feed`,
    color: tagColors[5][0],
    ghost: true,
    createDate: Date.now(),
    updateDate: Date.now()
};
export const WeChatTag = {
    id: 'WeChat',
    value: `${InternalTagPrefix}wechat`,
    color: tagColors[4][0],
    createDate: Date.now(),
    updateDate: Date.now()
};
export const RSSTag = {
    id: 'RSS',
    value: `${InternalTagPrefix}rss`,
    color: tagColors[4][0],
    createDate: Date.now(),
    updateDate: Date.now()
};
export const SeenTag = {
    id: 'Seen',
    value: `${InternalTagPrefix}seen`,
    color: tagColors[4][0],
    ghost: true,
    createDate: Date.now(),
    updateDate: Date.now()
};
export const UnseenTag = {
    id: 'Unseen',
    value: `${InternalTagPrefix}unseen`,
    color: tagColors[5][0],
    ghost: true,
    createDate: Date.now(),
    updateDate: Date.now()
};
export const isInternalTag = (tagName)=>tagName.startsWith(InternalTagPrefix);
export const useTagI18N = ()=>{
    const t = useI18n();
    return useCallback((tagName)=>{
        if (!tagName) {
            return t['Untitled']();
        }
        if (isInternalTag(tagName)) {
            return t[tagName] ? t[tagName]() : tagName;
        }
        return tagName;
    }, [
        t
    ]);
};
export const InternalTags = [
    FeedTag,
    SeenTag,
    UnseenTag
];
export const isGhostTag = (tagId)=>{
    return InternalTags.some((tag)=>tag.id === tagId && tag.ghost) || tagId.includes(RefPageTagPrefix) || tagId.includes('http');
};
export const getRefPageId = (tags)=>{
    const refPageTag = tags?.findLast((tag)=>tag.startsWith(RefPageTagPrefix));
    if (refPageTag) {
        return refPageTag.slice(RefPageTagPrefix.length);
    }
    return;
};
