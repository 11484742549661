import { workspaceQuotaQuery } from '@affine/graphql';
import { Store } from '@toeverything/infra';
export class WorkspaceQuotaStore extends Store {
    constructor(graphqlService){
        super();
        this.graphqlService = graphqlService;
    }
    async fetchWorkspaceQuota(workspaceId, signal) {
        const data = await this.graphqlService.gql({
            query: workspaceQuotaQuery,
            variables: {
                id: workspaceId
            },
            context: {
                signal
            }
        });
        return data.workspace.quota;
    }
}
