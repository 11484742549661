import { assertExists } from '@blocksuite/global/utils';
export class PeekableController {
    constructor(target, enable){
        this.target = target;
        this.enable = enable;
        this.getRootService = ()=>{
            return this.target.std.spec.getService('affine:page');
        };
        this.peek = (template)=>{
            return Promise.resolve(this.getRootService().peekViewService?.peek(this.target, template));
        };
    }
    get peekable() {
        return !!this.getRootService().peekViewService && (this.enable ? this.enable(this.target) : true);
    }
}
const symbol = Symbol('peekable');
export const isPeekable = (e)=>{
    return Reflect.has(e, symbol) && e[symbol]?.peekable;
};
export const peek = (e, template)=>{
    isPeekable(e) && e[symbol]?.peek(template);
};
export const Peekable = (options = {
    action: [
        'double-click',
        'shift-click'
    ]
})=>(Class, context)=>{
        assertExists(context.kind === 'class');
        if (options.action === undefined) options.action = [
            'double-click',
            'shift-click'
        ];
        const actions = Array.isArray(options.action) ? options.action : options.action ? [
            options.action
        ] : [];
        const derivedClass = class extends Class {
            connectedCallback() {
                super.connectedCallback();
                const target = (options.selector ? this.querySelector(options.selector) : this) || this;
                if (actions.includes('double-click')) {
                    this.disposables.addFromEvent(target, 'dblclick', (e)=>{
                        if (this[symbol].peekable) {
                            e.stopPropagation();
                            this[symbol].peek().catch(console.error);
                        }
                    });
                }
                if (actions.includes('shift-click')) {
                    this.disposables.addFromEvent(target, 'click', (e)=>{
                        if (e.shiftKey && this[symbol].peekable) {
                            e.stopPropagation();
                            e.stopImmediatePropagation();
                            this[symbol].peek().catch(console.error);
                        }
                    });
                }
            }
            constructor(...args){
                super(...args);
                this[symbol] = new PeekableController(this, options.enableOn);
            }
        };
        return derivedClass;
    };
const getSelectedPeekableBlocks = (cmd)=>{
    const [result, ctx] = cmd.std.command.chain().tryAll((chain)=>[
            chain.getTextSelection(),
            chain.getBlockSelections()
        ]).getSelectedBlocks({
        types: [
            'text',
            'block'
        ]
    }).run();
    return ((result ? ctx.selectedBlocks : []) || []).filter(isPeekable);
};
export const getSelectedPeekableBlocksCommand = (ctx, next)=>{
    const selectedPeekableBlocks = getSelectedPeekableBlocks(ctx);
    if (selectedPeekableBlocks.length > 0) {
        next({
            selectedPeekableBlocks
        });
    }
};
export const peekSelectedBlockCommand = (ctx, next)=>{
    const peekableBlocks = getSelectedPeekableBlocks(ctx);
    const block = peekableBlocks.at(0);
    if (block) {
        peek(block);
        next();
    }
};
