export const FORMAT_TEXT_SUPPORT_FLAVOURS = [
    'affine:paragraph',
    'affine:list'
];
export const FORMAT_BLOCK_SUPPORT_FLAVOURS = [
    'affine:paragraph',
    'affine:list'
];
export const FORMAT_NATIVE_SUPPORT_FLAVOURS = [
    'affine:database'
];
