import { jsx as _jsx } from "react/jsx-runtime";
import { FrameworkScope, useLiveData } from '@toeverything/infra';
import { lazy as reactLazy, useLayoutEffect, useMemo } from 'react';
import { createMemoryRouter, RouterProvider, UNSAFE_LocationContext, UNSAFE_RouteContext } from 'react-router-dom';
import { viewRoutes } from '../../../router';
import { RouteContainer } from './route-container';
const warpedRoutes = viewRoutes.map(({ path, lazy })=>{
    const Component = reactLazy(()=>lazy().then((m)=>({
                default: m.Component
            })));
    const route = {
        Component
    };
    return {
        path,
        Component: ()=>{
            return _jsx(RouteContainer, {
                route: route
            });
        }
    };
});
export const ViewRoot = ({ view })=>{
    const viewRouter = useMemo(()=>createMemoryRouter(warpedRoutes), []);
    const location = useLiveData(view.location$);
    useLayoutEffect(()=>{
        viewRouter.navigate(location).catch((err)=>{
            console.error('navigate error', err);
        });
    }, [
        location,
        view,
        viewRouter
    ]);
    return _jsx(FrameworkScope, {
        scope: view.scope,
        children: _jsx(UNSAFE_LocationContext.Provider, {
            value: null,
            children: _jsx(UNSAFE_RouteContext.Provider, {
                value: {
                    outlet: null,
                    matches: [],
                    isDataRoute: false
                },
                children: _jsx(RouterProvider, {
                    router: viewRouter
                })
            })
        })
    });
};
