function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _init_connector, _init_edgeless, _init_richText, _initProto;
import { RangeManager, ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { Bound, Vec } from '@blocksuite/global/utils';
import { assertExists } from '@blocksuite/global/utils';
import { DocCollection } from '@blocksuite/store';
import { css, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import '../../../../_common/components/rich-text/rich-text.js';
import { ThemeObserver } from '../../../../_common/theme/theme-observer.js';
import { almostEqual } from '../../../../_common/utils/math.js';
import { getLineHeight } from '../../../../surface-block/canvas-renderer/element-renderer/text/utils.js';
const HORIZONTAL_PADDING = 2;
const VERTICAL_PADDING = 2;
const BORDER_WIDTH = 1;
let _EdgelessConnectorLabelEditor;
_dec = customElement('edgeless-connector-label-editor'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = query('rich-text');
new class extends _identity {
    constructor(){
        super(_EdgelessConnectorLabelEditor), _initClass();
    }
    static{
        class EdgelessConnectorLabelEditor extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_connector, _init_edgeless, _init_richText, _initProto], c: [_EdgelessConnectorLabelEditor, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "connector"
                    ],
                    [
                        _dec2,
                        1,
                        "edgeless"
                    ],
                    [
                        _dec3,
                        1,
                        "richText"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    .edgeless-connector-label-editor {
      position: absolute;
      left: 0;
      top: 0;
      transform-origin: center;
      z-index: 10;
      padding: ${VERTICAL_PADDING}px ${HORIZONTAL_PADDING}px;
      border: ${BORDER_WIDTH}px solid var(--affine-primary-color, #1e96eb);
      background: var(--affine-background-primary-color, #fff);
      border-radius: 2px;
      box-shadow: 0px 0px 0px 2px rgba(30, 150, 235, 0.3);
      box-sizing: border-box;
      overflow: visible;

      .inline-editor {
        white-space: pre-wrap !important;
        outline: none;
      }

      .inline-editor span {
        word-break: normal !important;
        overflow-wrap: anywhere !important;
      }

      .edgeless-connector-label-editor-placeholder {
        pointer-events: none;
        color: var(--affine-text-disable-color);
        white-space: nowrap;
      }
    }
  `;
            }
            connectedCallback() {
                super.connectedCallback();
                this.setAttribute(RangeManager.rangeSyncExcludeAttr, 'true');
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this._resizeObserver?.disconnect();
                this._resizeObserver = null;
            }
            firstUpdated() {
                const { edgeless, connector } = this;
                const { dispatcher } = edgeless;
                assertExists(dispatcher);
                this._resizeObserver = new ResizeObserver(()=>{
                    this._updateLabelRect();
                    this.requestUpdate();
                });
                this._resizeObserver.observe(this.richText);
                this.updateComplete.then(()=>{
                    this.inlineEditor.selectAll();
                    this.inlineEditor.slots.renderComplete.on(()=>{
                        this.requestUpdate();
                    });
                    this.disposables.add(dispatcher.add('keyDown', (ctx)=>{
                        const state = ctx.get('keyboardState');
                        const { key, ctrlKey, metaKey, altKey, shiftKey, isComposing } = state.raw;
                        const onlyCmd = (ctrlKey || metaKey) && !altKey && !shiftKey;
                        const isModEnter = onlyCmd && key === 'Enter';
                        const isEscape = key === 'Escape';
                        if (!isComposing && (isModEnter || isEscape)) {
                            this.inlineEditorContainer.blur();
                            edgeless.service.selection.set({
                                elements: [
                                    connector.id
                                ],
                                editing: false
                            });
                            return true;
                        }
                        return false;
                    }));
                    this.disposables.add(edgeless.service.surface.elementUpdated.on(({ id })=>{
                        if (id === connector.id) this.requestUpdate();
                    }));
                    this.disposables.add(edgeless.service.viewport.viewportUpdated.on(()=>{
                        this.requestUpdate();
                    }));
                    this.disposables.add(dispatcher.add('click', ()=>true));
                    this.disposables.add(dispatcher.add('doubleClick', ()=>true));
                    this.disposables.add(()=>{
                        if (connector.text) {
                            const text = connector.text.toString();
                            const trimed = text.trim();
                            const len = trimed.length;
                            if (len === 0) {
                                edgeless.service.updateElement(connector.id, {
                                    text: undefined,
                                    labelXYWH: undefined,
                                    labelStyle: undefined,
                                    labelOffset: undefined
                                });
                            } else if (len < text.length) {
                                edgeless.service.updateElement(connector.id, {
                                    text: new DocCollection.Y.Text(trimed)
                                });
                            }
                        }
                        connector.lableEditing = false;
                        edgeless.service.selection.set({
                            elements: [],
                            editing: false
                        });
                    });
                    this.disposables.addFromEvent(this.inlineEditorContainer, 'blur', ()=>{
                        if (this._keeping) return;
                        this.remove();
                    });
                    this.disposables.addFromEvent(this.inlineEditorContainer, 'compositionstart', ()=>{
                        this._isComposition = true;
                        this.requestUpdate();
                    });
                    this.disposables.addFromEvent(this.inlineEditorContainer, 'compositionend', ()=>{
                        this._isComposition = false;
                        this.requestUpdate();
                    });
                    connector.lableEditing = true;
                }).catch(console.error);
            }
            async getUpdateComplete() {
                const result = await super.getUpdateComplete();
                await this.richText?.updateComplete;
                return result;
            }
            render() {
                const { connector } = this;
                const { labelOffset: { distance }, labelStyle: { fontFamily, fontSize, fontStyle, fontWeight, textAlign, color: labelColor }, labelConstraints: { hasMaxWidth, maxWidth } } = connector;
                const lineHeight = getLineHeight(fontFamily, fontSize, fontWeight);
                const { translateX, translateY, zoom } = this.edgeless.service.viewport;
                const [x, y] = Vec.mul(connector.getPointByOffsetDistance(distance), zoom);
                const transformOperation = [
                    'translate(-50%, -50%)',
                    `translate(${translateX}px, ${translateY}px)`,
                    `translate(${x}px, ${y}px)`,
                    `scale(${zoom})`
                ];
                const isEmpty = !connector.text.length && !this._isComposition;
                const color = ThemeObserver.generateColorProperty(labelColor, '#000000');
                return html`
      <div
        class="edgeless-connector-label-editor"
        style=${styleMap({
                    fontFamily: `"${fontFamily}"`,
                    fontSize: `${fontSize}px`,
                    fontStyle,
                    fontWeight,
                    textAlign,
                    lineHeight: `${lineHeight}px`,
                    maxWidth: hasMaxWidth ? `${maxWidth + BORDER_WIDTH * 2 + HORIZONTAL_PADDING * 2}px` : 'initial',
                    color,
                    transform: transformOperation.join(' ')
                })}
      >
        <rich-text
          .yText=${connector.text}
          .enableFormat=${false}
          style=${isEmpty ? styleMap({
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    padding: `${VERTICAL_PADDING}px ${HORIZONTAL_PADDING}px`
                }) : nothing}
        ></rich-text>
        ${isEmpty ? html`
              <span class="edgeless-connector-label-editor-placeholder">
                Add text
              </span>
            ` : nothing}
      </div>
    `;
            }
            setKeeping(keeping) {
                this._keeping = keeping;
            }
            get inlineEditor() {
                assertExists(this.richText.inlineEditor);
                return this.richText.inlineEditor;
            }
            get inlineEditorContainer() {
                return this.inlineEditor.rootElement;
            }
            #___private_connector_1;
            get connector() {
                return this.#___private_connector_1;
            }
            set connector(_v) {
                this.#___private_connector_1 = _v;
            }
            #___private_edgeless_2;
            get edgeless() {
                return this.#___private_edgeless_2;
            }
            set edgeless(_v) {
                this.#___private_edgeless_2 = _v;
            }
            #___private_richText_3;
            get richText() {
                return this.#___private_richText_3;
            }
            set richText(_v) {
                this.#___private_richText_3 = _v;
            }
            constructor(...args){
                super(...args);
                this._isComposition = false;
                this._keeping = false;
                this._resizeObserver = null;
                this._updateLabelRect = ()=>{
                    const { connector, edgeless } = this;
                    if (!connector || !edgeless) return;
                    const newWidth = this.inlineEditorContainer.scrollWidth;
                    const newHeight = this.inlineEditorContainer.scrollHeight;
                    const center = connector.getPointByOffsetDistance(connector.labelOffset.distance);
                    const bounds = Bound.fromCenter(center, newWidth, newHeight);
                    const labelXYWH = bounds.toXYWH();
                    if (!connector.labelXYWH || labelXYWH.some((p, i)=>!almostEqual(p, connector.labelXYWH[i]))) {
                        edgeless.service.updateElement(connector.id, {
                            labelXYWH
                        });
                    }
                };
                this.#___private_connector_1 = (_initProto(this), _init_connector(this));
                this.#___private_edgeless_2 = _init_edgeless(this);
                this.#___private_richText_3 = _init_richText(this);
            }
        }
    }
}();
export { _EdgelessConnectorLabelEditor as EdgelessConnectorLabelEditor };
