import { matchFlavours } from '../../_common/utils/model.js';
import { correctNumberedListsOrderToPrev, getNextContinuousNumberedLists } from './utils.js';
export const splitListCommand = (ctx, next)=>{
    const { blockId, inlineIndex, std } = ctx;
    const host = std.host;
    const doc = host.doc;
    const model = doc.getBlock(blockId).model;
    if (!matchFlavours(model, [
        'affine:list'
    ])) {
        console.error(`block ${blockId} is not a list block`);
        return;
    }
    const parent = doc.getParent(model);
    if (!parent) {
        console.error(`block ${blockId} has no parent`);
        return;
    }
    const modelIndex = parent.children.indexOf(model);
    if (modelIndex === -1) {
        console.error(`block ${blockId} is not a child of its parent`);
        return;
    }
    doc.captureSync();
    if (model.text.length === 0) {
        if (parent.role === 'hub') {
            const id = doc.addBlock('affine:paragraph', {}, parent, modelIndex);
            const paragraph = doc.getBlock(id);
            if (!paragraph) return;
            doc.deleteBlock(model, {
                bringChildrenTo: paragraph.model
            });
            const nextContinuousNumberedLists = getNextContinuousNumberedLists(doc, paragraph.model);
            let base = 1;
            nextContinuousNumberedLists.forEach((list)=>{
                doc.transact(()=>{
                    list.order = base;
                });
                base += 1;
            });
            host.updateComplete.then(()=>{
                host.command.exec('focusBlockStart', {
                    focusBlock: host.view.getBlock(id)
                });
            }).catch(console.error);
            next();
            return;
        }
        if (parent.role === 'content') {
            host.command.exec('unindentList', {
                blockId,
                inlineIndex: 0
            });
            next();
            return;
        }
        return;
    }
    let newListId = null;
    if (model.children.length > 0 && !model.collapsed) {
        const afterText = model.text.split(inlineIndex);
        newListId = doc.addBlock('affine:list', {
            type: model.type,
            text: afterText,
            order: model.type === 'numbered' ? 1 : null
        }, model, 0);
        if (model.type === 'numbered') {
            const nextContinuousNumberedLists = getNextContinuousNumberedLists(doc, newListId);
            let base = 2;
            nextContinuousNumberedLists.forEach((list)=>{
                doc.transact(()=>{
                    list.order = base;
                });
                base += 1;
            });
        }
    } else {
        const afterText = model.text.split(inlineIndex);
        newListId = doc.addBlock('affine:list', {
            type: model.type,
            text: afterText,
            order: null
        }, parent, modelIndex + 1);
        correctNumberedListsOrderToPrev(doc, newListId);
    }
    if (newListId) {
        host.updateComplete.then(()=>{
            host.command.exec('focusBlockStart', {
                focusBlock: host.view.getBlock(newListId)
            });
        }).catch(console.error);
        next();
        return;
    }
};
