import { html } from 'lit';
export const DatabaseSearchIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.75 9.5C3.75 6.32436 6.32436 3.75 9.5 3.75C12.6756 3.75 15.25 6.32436 15.25 9.5C15.25 12.6756 12.6756 15.25 9.5 15.25C6.32436 15.25 3.75 12.6756 3.75 9.5ZM9.5 2.25C5.49594 2.25 2.25 5.49594 2.25 9.5C2.25 13.5041 5.49594 16.75 9.5 16.75C11.2319 16.75 12.8219 16.1427 14.0689 15.1295L19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L15.1295 14.0689C16.1427 12.8219 16.75 11.2319 16.75 9.5C16.75 5.49594 13.5041 2.25 9.5 2.25Z"
    fill="currentColor"
  />
</svg> `;
export const DatabaseDuplicate = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6 3.25C4.48122 3.25 3.25 4.48122 3.25 6V14C3.25 15.5188 4.48122 16.75 6 16.75H7.25V18C7.25 19.5188 8.48122 20.75 10 20.75H18C19.5188 20.75 20.75 19.5188 20.75 18V10C20.75 8.48122 19.5188 7.25 18 7.25H16.75V6C16.75 4.48122 15.5188 3.25 14 3.25H6ZM10 7.25H15.25V6C15.25 5.30964 14.6904 4.75 14 4.75H6C5.30964 4.75 4.75 5.30964 4.75 6V14C4.75 14.6904 5.30964 15.25 6 15.25H8C8.41421 15.25 8.75 15.5858 8.75 16V18C8.75 18.6904 9.30964 19.25 10 19.25H18C18.6904 19.25 19.25 18.6904 19.25 18V10C19.25 9.30964 18.6904 8.75 18 8.75H16H10C9.46561 8.75 9.00774 9.08568 8.82933 9.56024C8.68357 9.94796 8.2511 10.1441 7.86338 9.99835C7.47566 9.85259 7.27951 9.42012 7.42527 9.0324C7.81633 7.99216 8.82056 7.25 10 7.25ZM12.8871 10.489C12.6049 10.1858 12.1303 10.1688 11.8271 10.451C11.524 10.7333 11.507 11.2078 11.7892 11.511L12.4771 12.25H7.79999C7.38577 12.25 7.04999 12.5858 7.04999 13C7.04999 13.4142 7.38577 13.75 7.79999 13.75H12.4771L11.7892 14.489C11.507 14.7922 11.524 15.2667 11.8271 15.549C12.1303 15.8312 12.6049 15.8142 12.8871 15.511L14.7489 13.511C15.017 13.2231 15.017 12.7769 14.7489 12.489L12.8871 10.489Z"
    fill="currentColor"
  />
</svg> `;
export const DatabaseInsertLeft = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14 3.75C13.0335 3.75 12.25 4.5335 12.25 5.5V18.5C12.25 19.4665 13.0335 20.25 14 20.25H18C18.9665 20.25 19.75 19.4665 19.75 18.5V5.5C19.75 4.5335 18.9665 3.75 18 3.75H14ZM13.75 5.5C13.75 5.36193 13.8619 5.25 14 5.25H18C18.1381 5.25 18.25 5.36193 18.25 5.5V18.5C18.25 18.6381 18.1381 18.75 18 18.75H14C13.8619 18.75 13.75 18.6381 13.75 18.5V5.5ZM8.25 9.5C8.25 9.08579 7.91421 8.75 7.5 8.75C7.08579 8.75 6.75 9.08579 6.75 9.5V11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H6.75V14.5C6.75 14.9142 7.08579 15.25 7.5 15.25C7.91421 15.25 8.25 14.9142 8.25 14.5V12.75H10C10.4142 12.75 10.75 12.4142 10.75 12C10.75 11.5858 10.4142 11.25 10 11.25H8.25V9.5Z"
    fill="currentColor"
  />
</svg> `;
export const DatabaseInsertRight = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6 3.75C5.0335 3.75 4.25 4.5335 4.25 5.5V18.5C4.25 19.4665 5.0335 20.25 6 20.25H10C10.9665 20.25 11.75 19.4665 11.75 18.5V5.5C11.75 4.5335 10.9665 3.75 10 3.75H6ZM5.75 5.5C5.75 5.36193 5.86193 5.25 6 5.25H10C10.1381 5.25 10.25 5.36193 10.25 5.5V18.5C10.25 18.6381 10.1381 18.75 10 18.75H6C5.86193 18.75 5.75 18.6381 5.75 18.5V5.5ZM17.25 9.5C17.25 9.08579 16.9142 8.75 16.5 8.75C16.0858 8.75 15.75 9.08579 15.75 9.5V11.25H14C13.5858 11.25 13.25 11.5858 13.25 12C13.25 12.4142 13.5858 12.75 14 12.75H15.75V14.5C15.75 14.9142 16.0858 15.25 16.5 15.25C16.9142 15.25 17.25 14.9142 17.25 14.5V12.75H19C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H17.25V9.5Z"
    fill="currentColor"
  />
</svg> `;
export const DatabaseMoveLeft = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4 4V20M8 12H20M8 12L12 8M8 12L12 16"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>`;
export const DatabaseMoveRight = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20 4V20M4 12H16M16 12L12 8M16 12L12 16"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>`;
export const DatabaseMultiSelect = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4 5L6.42929 7.42929C6.46834 7.46834 6.53166 7.46834 6.57071 7.42929L9 5"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
  <path
    d="M4 11L6.42929 13.4293C6.46834 13.4683 6.53166 13.4683 6.57071 13.4293L9 11"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
  <path
    d="M4 17L6.42929 19.4293C6.46834 19.4683 6.53166 19.4683 6.57071 19.4293L9 17"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
  <path
    d="M12 6L20 6"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
  <path
    d="M12 12L20 12"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
  <path
    d="M12 18L20 18"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
</svg>`;
export const DatabaseNumber = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M18.5 4L12.5 20"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
  <path
    d="M12 4L6 20"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
  <path
    d="M20 8.5H5.5"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
  <path
    d="M19 15L4 15"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
  />
</svg>`;
export const DatabaseSelect = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
  />
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.46967 10.4697C7.76256 10.1768 8.23744 10.1768 8.53033 10.4697L12 13.9393L15.4697 10.4697C15.7626 10.1768 16.2374 10.1768 16.5303 10.4697C16.8232 10.7626 16.8232 11.2374 16.5303 11.5303L12.601 15.4596C12.2691 15.7916 11.7309 15.7916 11.399 15.4596L7.46967 11.5303C7.17678 11.2374 7.17678 10.7626 7.46967 10.4697Z"
  />
</svg>`;
export const DatabaseProgress = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15 4.25C15.4142 4.25 15.75 4.58579 15.75 5V6.25H18C20.0711 6.25 21.75 7.92893 21.75 10V14C21.75 16.0711 20.0711 17.75 18 17.75H15.75V19C15.75 19.4142 15.4142 19.75 15 19.75C14.5858 19.75 14.25 19.4142 14.25 19V17.75H6C3.92893 17.75 2.25 16.0711 2.25 14V10C2.25 7.92893 3.92893 6.25 6 6.25H14.25V5C14.25 4.58579 14.5858 4.25 15 4.25ZM14.25 7.75H6C4.75736 7.75 3.75 8.75736 3.75 10V14C3.75 15.2426 4.75736 16.25 6 16.25H14.25V7.75ZM15.75 16.25H18C19.2426 16.25 20.25 15.2426 20.25 14V10C20.25 8.75736 19.2426 7.75 18 7.75H15.75V16.25ZM6 9.25C6.41421 9.25 6.75 9.58579 6.75 10V14C6.75 14.4142 6.41421 14.75 6 14.75C5.58579 14.75 5.25 14.4142 5.25 14L5.25 10C5.25 9.58579 5.58579 9.25 6 9.25ZM9 9.25C9.41421 9.25 9.75 9.58579 9.75 10L9.75 14C9.75 14.4142 9.41421 14.75 9 14.75C8.58579 14.75 8.25 14.4142 8.25 14L8.25 10C8.25 9.58579 8.58579 9.25 9 9.25ZM12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
  />
</svg>`;
export const DatabaseSearchClose = html`<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.22872 4.22878C4.42398 4.03352 4.74057 4.03352 4.93583 4.22878L7.99996 7.29291L11.0641 4.22878C11.2593 4.03352 11.5759 4.03352 11.7712 4.22878C11.9665 4.42405 11.9665 4.74063 11.7712 4.93589L8.70707 8.00002L11.7712 11.0641C11.9665 11.2594 11.9665 11.576 11.7712 11.7713C11.5759 11.9665 11.2593 11.9665 11.0641 11.7713L7.99996 8.70713L4.93583 11.7713C4.74057 11.9665 4.42398 11.9665 4.22872 11.7713C4.03346 11.576 4.03346 11.2594 4.22872 11.0641L7.29285 8.00002L4.22872 4.93589C4.03346 4.74063 4.03346 4.42405 4.22872 4.22878Z"
  />
</svg>`;
export const DatabaseAddColumn = html`<svg
  viewBox="0 0 16 16"
  style="
  width: 12px;
  height: 12px;
  fill: var(--affine-text-primary-color);"
>
  <path
    d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"
  ></path>
</svg>`;
export const DatabaseDragIcon = html`<svg
  width="10"
  height="14"
  viewBox="0 0 10 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="10" height="14" rx="2" />
  <rect width="10" height="14" rx="2" fill-opacity="0.08" />
  <g clip-path="url(#clip0_179_9218)">
    <circle cx="3" cy="3" r="1" fill="var(--affine-icon-color)" />
    <circle cx="3" cy="6.79163" r="1" fill="var(--affine-icon-color)" />
    <circle cx="3" cy="10.5833" r="1" fill="var(--affine-icon-color)" />
    <circle cx="7" cy="3" r="1" fill="var(--affine-icon-color)" />
    <circle cx="7" cy="6.79163" r="1" fill="var(--affine-icon-color)" />
    <circle cx="7" cy="10.5833" r="1" fill="var(--affine-icon-color)" />
  </g>
  <defs>
    <clipPath id="clip0_179_9218">
      <rect width="6" height="10" transform="translate(2 2)" />
    </clipPath>
  </defs>
</svg>`;
export const DatabaseExpand = html`
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4697 3.46967C11.7626 3.17678 12.2374 3.17678 12.5303 3.46967L17.5303 8.46967C17.8232 8.76256 17.8232 9.23744 17.5303 9.53033C17.2374 9.82322 16.7626 9.82322 16.4697 9.53033L12 5.06066L7.53033 9.53033C7.23744 9.82322 6.76256 9.82322 6.46967 9.53033C6.17678 9.23744 6.17678 8.76256 6.46967 8.46967L11.4697 3.46967ZM6.46967 14.4697C6.76256 14.1768 7.23744 14.1768 7.53033 14.4697L12 18.9393L16.4697 14.4697C16.7626 14.1768 17.2374 14.1768 17.5303 14.4697C17.8232 14.7626 17.8232 15.2374 17.5303 15.5303L12.5303 20.5303C12.2374 20.8232 11.7626 20.8232 11.4697 20.5303L6.46967 15.5303C6.17678 15.2374 6.17678 14.7626 6.46967 14.4697Z"
    />
  </svg>
`;
export const DatabaseTableViewClose = html`
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.34314 6.34321C6.63604 6.05031 7.11091 6.05031 7.4038 6.34321L12 10.9394L16.5962 6.34321C16.8891 6.05031 17.364 6.05031 17.6569 6.34321C17.9497 6.6361 17.9497 7.11097 17.6569 7.40387L13.0607 12.0001L17.6569 16.5963C17.9497 16.8891 17.9497 17.364 17.6569 17.6569C17.364 17.9498 16.8891 17.9498 16.5962 17.6569L12 13.0607L7.4038 17.6569C7.11091 17.9498 6.63604 17.9498 6.34314 17.6569C6.05025 17.364 6.05025 16.8891 6.34314 16.5963L10.9393 12.0001L6.34314 7.40387C6.05025 7.11097 6.05025 6.6361 6.34314 6.34321Z"
    />
  </svg>
`;
export const CrossIcon = html` <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="currentColor"
    d="M6.34315 6.34314C6.63604 6.05025 7.11091 6.05025 7.40381 6.34314L12 10.9393L16.5962 6.34314C16.8891 6.05025 17.364 6.05025 17.6569 6.34314C17.9497 6.63604 17.9497 7.11091 17.6569 7.4038L13.0607 12L17.6569 16.5962C17.9497 16.8891 17.9497 17.364 17.6569 17.6569C17.364 17.9497 16.8891 17.9497 16.5962 17.6569L12 13.0607L7.40381 17.6569C7.11091 17.9497 6.63604 17.9497 6.34315 17.6569C6.05025 17.364 6.05025 16.8891 6.34315 16.5962L10.9393 12L6.34315 7.4038C6.05025 7.11091 6.05025 6.63604 6.34315 6.34314Z"
  />
</svg>`;
export const PlusIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="currentColor"
    d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H12.75V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V12.75H6C5.58579 12.75 5.25 12.4142 5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H11.25V6C11.25 5.58579 11.5858 5.25 12 5.25Z"
  />
</svg>`;
export const DeleteIcon = html`<svg
  width="20"
  height="20"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="currentColor"
    d="M11 2.25C9.48122 2.25 8.25 3.48122 8.25 5V6.1499H4C3.53056 6.1499 3.15 6.53046 3.15 6.9999C3.15 7.46934 3.53056 7.8499 4 7.8499H4.3088L5.11923 19.1959C5.22202 20.635 6.41948 21.75 7.86224 21.75H16.1378C17.5805 21.75 18.778 20.635 18.8808 19.1959L19.6912 7.8499H20C20.4694 7.8499 20.85 7.46934 20.85 6.9999C20.85 6.53046 20.4694 6.1499 20 6.1499H15.75V5C15.75 3.48122 14.5188 2.25 13 2.25H11ZM14.25 6.1499V5C14.25 4.30964 13.6904 3.75 13 3.75H11C10.3096 3.75 9.75 4.30964 9.75 5V6.1499H14.25ZM18.1874 7.8499H5.81262L6.61542 19.0891C6.66214 19.7432 7.20644 20.25 7.86224 20.25H16.1378C16.7936 20.25 17.3379 19.7432 17.3846 19.0891L18.1874 7.8499ZM10.75 11C10.75 10.5858 10.4142 10.25 10 10.25C9.58579 10.25 9.25 10.5858 9.25 11V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V11ZM14 10.25C14.4142 10.25 14.75 10.5858 14.75 11V17C14.75 17.4142 14.4142 17.75 14 17.75C13.5858 17.75 13.25 17.4142 13.25 17V11C13.25 10.5858 13.5858 10.25 14 10.25Z"
  />
</svg>`;
export const MoreHorizontalIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.5 12.0001C4.5 12.9559 5.27489 13.7308 6.23077 13.7308C7.18665 13.7308 7.96154 12.9559 7.96154 12.0001C7.96154 11.0442 7.18665 10.2693 6.23077 10.2693C5.27489 10.2693 4.5 11.0442 4.5 12.0001ZM12 13.7308C11.0441 13.7308 10.2692 12.9559 10.2692 12.0001C10.2692 11.0442 11.0441 10.2693 12 10.2693C12.9559 10.2693 13.7308 11.0442 13.7308 12.0001C13.7308 12.9559 12.9559 13.7308 12 13.7308ZM17.7692 13.7308C16.8134 13.7308 16.0385 12.9559 16.0385 12.0001C16.0385 11.0442 16.8134 10.2693 17.7692 10.2693C18.7251 10.2693 19.5 11.0442 19.5 12.0001C19.5 12.9559 18.7251 13.7308 17.7692 13.7308Z"
    fill="currentColor"
  />
</svg> `;
export const ArrowLeftBigIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.9968 6.4927C11.277 6.79778 11.2568 7.27223 10.9517 7.5524L6.92547 11.25L19 11.25C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75L6.92547 12.75L10.9517 16.4476C11.2568 16.7278 11.277 17.2022 10.9968 17.5073C10.7167 17.8124 10.2422 17.8326 9.93714 17.5524L4.4927 12.5524C4.33803 12.4104 4.25 12.21 4.25 12C4.25 11.79 4.33803 11.5896 4.4927 11.4476L9.93714 6.44761C10.2422 6.16743 10.7167 6.18762 10.9968 6.4927Z"
    fill="currentColor"
  />
</svg> `;
export const TextIcon = html`<svg
  width="20"
  height="20"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.25 4C3.25 3.58579 3.58579 3.25 4 3.25H20C20.4142 3.25 20.75 3.58579 20.75 4V6.66667C20.75 7.08088 20.4142 7.41667 20 7.41667C19.5858 7.41667 19.25 7.08088 19.25 6.66667V4.75H12.75V19.25H16C16.4142 19.25 16.75 19.5858 16.75 20C16.75 20.4142 16.4142 20.75 16 20.75H8C7.58579 20.75 7.25 20.4142 7.25 20C7.25 19.5858 7.58579 19.25 8 19.25H11.25V4.75H4.75V6.66667C4.75 7.08088 4.41421 7.41667 4 7.41667C3.58579 7.41667 3.25 7.08088 3.25 6.66667V4Z"
    fill="currentColor"
  />
</svg>`;
export const CheckBoxIcon = html`<svg
  width="20"
  height="20"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="currentColor"
    d="M6 3.25C4.48122 3.25 3.25 4.48122 3.25 6V18C3.25 19.5188 4.48122 20.75 6 20.75H18C19.5188 20.75 20.75 19.5188 20.75 18V6C20.75 4.48122 19.5188 3.25 18 3.25H6ZM4.75 6C4.75 5.30964 5.30964 4.75 6 4.75H18C18.6904 4.75 19.25 5.30964 19.25 6V18C19.25 18.6904 18.6904 19.25 18 19.25H6C5.30964 19.25 4.75 18.6904 4.75 18V6ZM16.5303 9.53033C16.8232 9.23744 16.8232 8.76256 16.5303 8.46967C16.2374 8.17678 15.7626 8.17678 15.4697 8.46967L10.5 13.4393L9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L9.96967 15.0303C10.2626 15.3232 10.7374 15.3232 11.0303 15.0303L16.5303 9.53033Z"
  />
</svg>`;
export const DateTime = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8 2.75C8.41421 2.75 8.75 3.08579 8.75 3.5V4.25H15.25V3.5C15.25 3.08579 15.5858 2.75 16 2.75C16.4142 2.75 16.75 3.08579 16.75 3.5V4.25H19C20.5188 4.25 21.75 5.48122 21.75 7V9.5V19C21.75 20.5188 20.5188 21.75 19 21.75H5C3.48122 21.75 2.25 20.5188 2.25 19V9.5V7C2.25 5.48122 3.48122 4.25 5 4.25H7.25V3.5C7.25 3.08579 7.58579 2.75 8 2.75ZM15.25 5.75V6.5C15.25 6.91421 15.5858 7.25 16 7.25C16.4142 7.25 16.75 6.91421 16.75 6.5V5.75H19C19.6904 5.75 20.25 6.30964 20.25 7V8.75H3.75V7C3.75 6.30964 4.30964 5.75 5 5.75H7.25V6.5C7.25 6.91421 7.58579 7.25 8 7.25C8.41421 7.25 8.75 6.91421 8.75 6.5V5.75H15.25ZM3.75 10.25H20.25V19C20.25 19.6904 19.6904 20.25 19 20.25H5C4.30964 20.25 3.75 19.6904 3.75 19V10.25Z"
    fill="currentColor"
  />
</svg> `;
export const ImageIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="currentColor"
    d="M6 3.25C4.48122 3.25 3.25 4.48122 3.25 6V16V18C3.25 19.5188 4.48122 20.75 6 20.75H18C19.5188 20.75 20.75 19.5188 20.75 18V14V6C20.75 4.48122 19.5188 3.25 18 3.25H6ZM4.75 18V16.3107L9.11612 11.9445C9.60427 11.4564 10.3957 11.4564 10.8839 11.9445L13.4697 14.5303L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L15.0607 14L16.1161 12.9445C16.6043 12.4564 17.3957 12.4564 17.8839 12.9445L19.25 14.3107V18C19.25 18.6904 18.6904 19.25 18 19.25H6C5.30964 19.25 4.75 18.6904 4.75 18ZM11.9445 10.8839L14 12.9393L15.0555 11.8839C16.1294 10.8099 17.8706 10.8099 18.9445 11.8839L19.25 12.1893V6C19.25 5.30964 18.6904 4.75 18 4.75H6C5.30964 4.75 4.75 5.30964 4.75 6V14.1893L8.05546 10.8839C9.1294 9.80994 10.8706 9.80994 11.9445 10.8839ZM14 9C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9Z"
  />
</svg>`;
export const TitleIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.84088 5.25C2.42667 5.25 2.09088 5.58579 2.09088 6V8C2.09088 8.41421 2.42667 8.75 2.84088 8.75C3.25509 8.75 3.59088 8.41421 3.59088 8V6.75H6.99997V17.25H5.0227C4.60849 17.25 4.2727 17.5858 4.2727 18C4.2727 18.4142 4.60849 18.75 5.0227 18.75H7.74997H10.4772C10.8915 18.75 11.2272 18.4142 11.2272 18C11.2272 17.5858 10.8915 17.25 10.4772 17.25H8.49997V6.75H11.9091V8C11.9091 8.41421 12.2449 8.75 12.6591 8.75C13.0733 8.75 13.4091 8.41421 13.4091 8V6C13.4091 5.58579 13.0733 5.25 12.6591 5.25H7.74997H2.84088ZM15.6045 5.25C15.1903 5.25 14.8545 5.58579 14.8545 6C14.8545 6.41421 15.1903 6.75 15.6045 6.75H21.059C21.4733 6.75 21.809 6.41421 21.809 6C21.809 5.58579 21.4733 5.25 21.059 5.25H15.6045ZM14.8545 12C14.8545 11.5858 15.1903 11.25 15.6045 11.25H21.059C21.4733 11.25 21.809 11.5858 21.809 12C21.809 12.4142 21.4733 12.75 21.059 12.75H15.6045C15.1903 12.75 14.8545 12.4142 14.8545 12ZM15.6045 17.25C15.1903 17.25 14.8545 17.5858 14.8545 18C14.8545 18.4142 15.1903 18.75 15.6045 18.75H21.059C21.4733 18.75 21.809 18.4142 21.809 18C21.809 17.5858 21.4733 17.25 21.059 17.25H15.6045Z"
    fill="currentColor"
  />
</svg> `;
export const LinkIcon = html`<svg
  width="20"
  height="20"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M18.4283 5.57173C17.3326 4.47609 15.5563 4.47609 14.4606 5.57173L13.4832 6.54916C13.1903 6.84205 12.7154 6.84205 12.4225 6.54916C12.1296 6.25627 12.1296 5.78139 12.4225 5.4885L13.4 4.51107C15.0814 2.82964 17.8075 2.82964 19.4889 4.51107C21.1704 6.19249 21.1704 8.91862 19.4889 10.6L15.9334 14.1556C14.252 15.837 11.5258 15.837 9.8444 14.1556C9.55151 13.8627 9.55151 13.3878 9.8444 13.0949C10.1373 12.802 10.6122 12.802 10.9051 13.0949C12.0007 14.1906 13.7771 14.1906 14.8727 13.0949L18.4283 9.53938C19.5239 8.44375 19.5239 6.66737 18.4283 5.57173ZM13.0949 10.9051C11.9993 9.80942 10.2229 9.80942 9.12728 10.9051L5.57173 14.4606C4.47609 15.5563 4.47609 17.3326 5.57173 18.4283C6.66737 19.5239 8.44375 19.5239 9.53938 18.4283L10.5185 17.4491C10.8114 17.1562 11.2863 17.1562 11.5792 17.4491C11.8721 17.742 11.8721 18.2169 11.5792 18.5098L10.6 19.4889C8.91862 21.1704 6.19249 21.1704 4.51107 19.4889C2.82964 17.8075 2.82964 15.0814 4.51107 13.4L8.06662 9.8444C9.74805 8.16298 12.4742 8.16298 14.1556 9.8444C14.4485 10.1373 14.4485 10.6122 14.1556 10.9051C13.8627 11.198 13.3878 11.198 13.0949 10.9051Z"
  />
</svg>`;
export const DatabaseKanbanViewIcon = html`<svg
  width="20"
  height="20"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.5 3.75C4.5335 3.75 3.75 4.5335 3.75 5.5V18C3.75 18.9665 4.5335 19.75 5.5 19.75H9.5C10.4665 19.75 11.25 18.9665 11.25 18V5.5C11.25 4.5335 10.4665 3.75 9.5 3.75H5.5ZM5.25 5.5C5.25 5.36193 5.36193 5.25 5.5 5.25H9.5C9.63807 5.25 9.75 5.36193 9.75 5.5V18C9.75 18.1381 9.63807 18.25 9.5 18.25H5.5C5.36193 18.25 5.25 18.1381 5.25 18V5.5ZM14.5 3.75C13.5335 3.75 12.75 4.5335 12.75 5.5V12.5C12.75 13.4665 13.5335 14.25 14.5 14.25H18.5C19.4665 14.25 20.25 13.4665 20.25 12.5V5.5C20.25 4.5335 19.4665 3.75 18.5 3.75H14.5ZM14.25 5.5C14.25 5.36193 14.3619 5.25 14.5 5.25H18.5C18.6381 5.25 18.75 5.36193 18.75 5.5V12.5C18.75 12.6381 18.6381 12.75 18.5 12.75H14.5C14.3619 12.75 14.25 12.6381 14.25 12.5V5.5Z"
  />
</svg>`;
export const DatabaseTableViewIcon = html`<svg
  width="20"
  height="20"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.25 6C3.25 4.48122 4.48122 3.25 6 3.25H18C19.5188 3.25 20.75 4.48122 20.75 6V18C20.75 19.5188 19.5188 20.75 18 20.75H6C4.48122 20.75 3.25 19.5188 3.25 18V6ZM6 4.75C5.30964 4.75 4.75 5.30964 4.75 6V8.25H19.25V6C19.25 5.30964 18.6904 4.75 18 4.75H6ZM4.75 18V9.75H7.75V19.25H6C5.30964 19.25 4.75 18.6904 4.75 18ZM9.25 19.25H18C18.6904 19.25 19.25 18.6904 19.25 18V9.75H9.25V19.25Z"
  />
</svg>`;
export const ConvertIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.19232 4.25C7.7781 4.25 7.44232 4.58579 7.44232 5C7.44232 5.41421 7.7781 5.75 8.19232 5.75H16.7308C17.4211 5.75 17.9808 6.30965 17.9808 7V14.7278L16.3765 13.1235C16.0836 12.8306 15.6087 12.8306 15.3158 13.1235C15.0229 13.4164 15.0229 13.8913 15.3158 14.1842L18.2005 17.0688C18.3411 17.2094 18.5319 17.2885 18.7308 17.2885C18.9297 17.2885 19.1205 17.2094 19.2611 17.0688L22.1457 14.1842C22.4386 13.8913 22.4386 13.4164 22.1457 13.1235C21.8528 12.8306 21.378 12.8306 21.0851 13.1235L19.4808 14.7278V7C19.4808 5.48122 18.2496 4.25 16.7308 4.25H8.19232ZM15.4231 19.75C15.8373 19.75 16.1731 19.4142 16.1731 19C16.1731 18.5858 15.8373 18.25 15.4231 18.25H6.88462C6.19426 18.25 5.63461 17.6904 5.63461 17V9.2722L7.2389 10.8765C7.53179 11.1694 8.00667 11.1694 8.29956 10.8765C8.59245 10.5836 8.59245 10.1087 8.29956 9.81582L5.41494 6.93121C5.27429 6.79056 5.08353 6.71154 4.88461 6.71154C4.6857 6.71154 4.49494 6.79056 4.35429 6.93121L1.46967 9.81582C1.17678 10.1087 1.17678 10.5836 1.46967 10.8765C1.76256 11.1694 2.23744 11.1694 2.53033 10.8765L4.13461 9.2722V17C4.13461 18.5188 5.36583 19.75 6.88462 19.75H15.4231Z"
    fill="currentColor"
  />
</svg> `;
export const FilterIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12ZM12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM8 8.75C7.58579 8.75 7.25 9.08579 7.25 9.5C7.25 9.91421 7.58579 10.25 8 10.25H16C16.4142 10.25 16.75 9.91421 16.75 9.5C16.75 9.08579 16.4142 8.75 16 8.75H8ZM8.25 12.5C8.25 12.0858 8.58579 11.75 9 11.75H15C15.4142 11.75 15.75 12.0858 15.75 12.5C15.75 12.9142 15.4142 13.25 15 13.25H9C8.58579 13.25 8.25 12.9142 8.25 12.5ZM11 14.75C10.5858 14.75 10.25 15.0858 10.25 15.5C10.25 15.9142 10.5858 16.25 11 16.25H13C13.4142 16.25 13.75 15.9142 13.75 15.5C13.75 15.0858 13.4142 14.75 13 14.75H11Z"
    fill="currentColor"
  />
</svg> `;
export const GroupingIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.75 6C2.75 4.48122 3.98122 3.25 5.5 3.25H9H15H18.5C20.0188 3.25 21.25 4.48122 21.25 6V18C21.25 19.5188 20.0188 20.75 18.5 20.75H5.5C3.98122 20.75 2.75 19.5188 2.75 18V6ZM14.25 4.75H9.75V19.25H14.25V4.75ZM15.75 19.25V4.75H18.5C19.1904 4.75 19.75 5.30964 19.75 6V18C19.75 18.6904 19.1904 19.25 18.5 19.25H15.75ZM5.5 4.75H8.25V19.25H5.5C4.80964 19.25 4.25 18.6904 4.25 18V6C4.25 5.30964 4.80964 4.75 5.5 4.75Z"
    fill="currentColor"
  />
</svg> `;
export const InfoIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M20.75 6C20.75 6.41421 20.4142 6.75 20 6.75L10.6465 6.75C10.32 7.90425 9.25878 8.75 8 8.75C6.74122 8.75 5.67998 7.90425 5.35352 6.75L4 6.75C3.58579 6.75 3.25 6.41421 3.25 6C3.25 5.58579 3.58579 5.25 4 5.25L5.35352 5.25C5.67998 4.09575 6.74122 3.25 8 3.25C9.25878 3.25 10.32 4.09575 10.6465 5.25L20 5.25C20.4142 5.25 20.75 5.58579 20.75 6ZM20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L18.6465 12.75C18.32 13.9043 17.2588 14.75 16 14.75C14.7412 14.75 13.68 13.9043 13.3535 12.75L4 12.75C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25L13.3535 11.25C13.68 10.0957 14.7412 9.25 16 9.25C17.2588 9.25 18.32 10.0957 18.6465 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12ZM20.75 18C20.75 18.4142 20.4142 18.75 20 18.75L10.6465 18.75C10.32 19.9043 9.25878 20.75 8 20.75C6.74122 20.75 5.67998 19.9043 5.35352 18.75L4 18.75C3.58579 18.75 3.25 18.4142 3.25 18C3.25 17.5858 3.58579 17.25 4 17.25L5.35352 17.25C5.67998 16.0957 6.74122 15.25 8 15.25C9.25878 15.25 10.32 16.0957 10.6465 17.25L20 17.25C20.4142 17.25 20.75 17.5858 20.75 18ZM17.25 12C17.25 11.3096 16.6904 10.75 16 10.75C15.3096 10.75 14.75 11.3096 14.75 12C14.75 12.6904 15.3096 13.25 16 13.25C16.6904 13.25 17.25 12.6904 17.25 12ZM9.25 6C9.25 5.30964 8.69036 4.75 8 4.75C7.30964 4.75 6.75 5.30964 6.75 6C6.75 6.69036 7.30964 7.25 8 7.25C8.69036 7.25 9.25 6.69036 9.25 6ZM9.25 18C9.25 17.3096 8.69036 16.75 8 16.75C7.30964 16.75 6.75 17.3096 6.75 18C6.75 18.6904 7.30964 19.25 8 19.25C8.69036 19.25 9.25 18.6904 9.25 18Z"
    fill="currentColor"
  />
</svg> `;
export const DoneIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19.5303 6.46967C19.8232 6.76256 19.8232 7.23744 19.5303 7.53033L9.53033 17.5303C9.23744 17.8232 8.76256 17.8232 8.46967 17.5303L4.46967 13.5303C4.17678 13.2374 4.17678 12.7626 4.46967 12.4697C4.76256 12.1768 5.23744 12.1768 5.53033 12.4697L9 15.9393L18.4697 6.46967C18.7626 6.17678 19.2374 6.17678 19.5303 6.46967Z"
    fill="currentColor"
  />
</svg> `;
export const IncreaseDecimalPlacesIcon = html`
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10.5H2.66176V13.5H0V10.5ZM13.3333 18H20V21H13.3333V18ZM22.6259 17.9356L24 19.5282L22.6312 21.0585L20 24V15L22.6259 17.9356ZM3.99019 4.4953C3.99019 2.01262 5.78279 0 7.98405 0C10.1898 0 11.9779 2.02109 11.9779 4.4953V9.0047C11.9779 11.4874 10.1853 13.5 7.98405 13.5C5.7783 13.5 3.99019 11.4789 3.99019 9.0047V4.4953ZM6 4.49786V9.00214C6 10.2525 6.89543 11.25 8 11.25C9.11227 11.25 10 10.2436 10 9.00214V4.49786C10 3.24754 9.10457 2.25 8 2.25C6.88773 2.25 6 3.2564 6 4.49786ZM13.3235 4.4953C13.3235 2.01262 15.1161 0 17.3174 0C19.5231 0 21.3113 2.02109 21.3113 4.4953V9.0047C21.3113 11.4874 19.5187 13.5 17.3174 13.5C15.1116 13.5 13.3235 11.4789 13.3235 9.0047V4.4953ZM15.3333 4.49786V9.00214C15.3333 10.2525 16.2288 11.25 17.3333 11.25C18.4456 11.25 19.3333 10.2436 19.3333 9.00214V4.49786C19.3333 3.24754 18.4379 2.25 17.3333 2.25C16.2211 2.25 15.3333 3.2564 15.3333 4.49786Z"
      fill="currentColor"
    />
  </svg>
`;
export const DecreaseDecimalPlacesIcon = html`
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10.5H3V13.5H0V10.5ZM18.09 22.41L16.305 20.625H24V18.375H16.305L18.09 16.59L16.5 15L12 19.5L16.5 24L18.09 22.41ZM13.5 9.375V4.125C13.5 1.845 11.655 0 9.375 0C7.095 0 5.25 1.845 5.25 4.125V9.375C5.25 11.655 7.095 13.5 9.375 13.5C11.655 13.5 13.5 11.655 13.5 9.375ZM11.25 9.375C11.25 10.41 10.41 11.25 9.375 11.25C8.34 11.25 7.5 10.41 7.5 9.375V4.125C7.5 3.09 8.34 2.25 9.375 2.25C10.41 2.25 11.25 3.09 11.25 4.125V9.375Z"
      fill="currentColor"
    />
  </svg>
`;
export const CenterPeekIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5 4.75C4.86193 4.75 4.75 4.86193 4.75 5V7C4.75 7.41421 4.41421 7.75 4 7.75C3.58579 7.75 3.25 7.41421 3.25 7V5C3.25 4.0335 4.0335 3.25 5 3.25H7C7.41421 3.25 7.75 3.58579 7.75 4C7.75 4.41421 7.41421 4.75 7 4.75H5ZM16.25 4C16.25 3.58579 16.5858 3.25 17 3.25H19C19.9665 3.25 20.75 4.0335 20.75 5V7C20.75 7.41421 20.4142 7.75 20 7.75C19.5858 7.75 19.25 7.41421 19.25 7V5C19.25 4.86193 19.1381 4.75 19 4.75H17C16.5858 4.75 16.25 4.41421 16.25 4ZM7.25 9.1C7.25 8.63056 7.63056 8.25 8.1 8.25H15.9C16.3694 8.25 16.75 8.63055 16.75 9.1V14.9C16.75 15.3694 16.3694 15.75 15.9 15.75H8.1C7.63055 15.75 7.25 15.3694 7.25 14.9V9.1ZM8.75 9.75V14.25H15.25V9.75H8.75ZM4 16.25C4.41421 16.25 4.75 16.5858 4.75 17V19C4.75 19.1381 4.86193 19.25 5 19.25H7C7.41421 19.25 7.75 19.5858 7.75 20C7.75 20.4142 7.41421 20.75 7 20.75H5C4.0335 20.75 3.25 19.9665 3.25 19V17C3.25 16.5858 3.58579 16.25 4 16.25ZM20 16.25C20.4142 16.25 20.75 16.5858 20.75 17V19C20.75 19.9665 19.9665 20.75 19 20.75H17C16.5858 20.75 16.25 20.4142 16.25 20C16.25 19.5858 16.5858 19.25 17 19.25H19C19.1381 19.25 19.25 19.1381 19.25 19V17C19.25 16.5858 19.5858 16.25 20 16.25Z"
    fill="#77757D"
  />
</svg> `;
