function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WidgetBase, _dec1, _initClass1, _WithDisposable, _dec2, _init_database, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { Slot, assertExists } from '@blocksuite/global/utils';
import { computed } from '@lit-labs/preact-signals';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { createModal } from '../../../_common/components/index.js';
import { CrossIcon, ExpandWideIcon } from '../../../_common/icons/index.js';
import { DatabaseSelection } from '../../data-view/common/selection.js';
import { renderTemplate } from '../../data-view/utils/uni-component/render-template.js';
import { WidgetBase } from '../../data-view/widget/widget-base.js';
export function showDatabasePreviewModal(database) {
    const viewComponent = new _DatabaseBlockModalPreview();
    viewComponent.database = database;
    const editorHost = document.querySelector('editor-host');
    assertExists(editorHost);
    const modal = createModal(editorHost);
    const close = ()=>{
        modal.remove();
    };
    const container = renderTemplate(()=>{
        return html`
      <style>
        .database-block-preview-container {
          position: absolute;
          display: flex;
          padding: 24px 53px;
          border-radius: 8px;
          left: 0;
          right: 0;
          margin: 20px auto auto;
          width: calc(100% - 300px);
          max-height: calc(100% - 40px);
          box-shadow: var(--affine-shadow-1);
          background-color: var(--affine-background-primary-color);
        }
        .database-block-preview-close {
          position: absolute;
          right: -48px;
          top: 0;
          padding: 10px;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          background-color: var(--affine-background-primary-color);
        }
        .database-block-preview-close svg {
          color: var(--affine-icon-color);
          width: 16px;
          height: 16px;
        }
        .database-block-preview-close:hover {
          background-color: var(--affine-hover-color-filled);
        }
      </style>
      <div class="database-block-preview-container">
        <database-block-modal-preview
          .database="${database}"
        ></database-block-modal-preview>
        <div @click="${close}" class="database-block-preview-close">
          ${CrossIcon}
        </div>
      </div>
    `;
    });
    container.onclick = (e)=>{
        e.stopPropagation();
    };
    modal.onclick = close;
    modal.style.backgroundColor = 'var(--affine-black-60)';
    modal.append(container);
}
let _ExpandDatabaseBlockModal;
_dec = customElement('expand-database-block-modal');
class ExpandDatabaseBlockModal extends (_WidgetBase = WidgetBase) {
    static{
        ({ c: [_ExpandDatabaseBlockModal, _initClass] } = _apply_decs_2203_r(this, [], [
            _dec
        ], _WidgetBase));
    }
    render() {
        if (this.closest('database-block-modal-preview') || !this.database?.doc.awarenessStore.getFlag('enable_expand_database_block')) {
            return;
        }
        return html` <div
      @click="${this.expandDatabase}"
      class="dv-icon-20 dv-pd-2 dv-hover dv-round-4"
      style="display:flex;"
    >
      ${ExpandWideIcon}
    </div>`;
    }
    get database() {
        return this.closest('affine-database');
    }
    static{
        _initClass();
    }
    constructor(...args){
        super(...args);
        this.expandDatabase = ()=>{
            const database = this.closest('affine-database');
            if (database) {
                showDatabasePreviewModal(database);
            }
        };
    }
}
let _DatabaseBlockModalPreview;
_dec1 = customElement('database-block-modal-preview'), _dec2 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_DatabaseBlockModalPreview), _initClass1();
    }
    static{
        class DatabaseBlockModalPreview extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_database, _initProto], c: [_DatabaseBlockModalPreview, _initClass1] } = _apply_decs_2203_r(this, [
                    [
                        _dec2,
                        1,
                        "database"
                    ]
                ], [
                    _dec1
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    database-block-modal-preview {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
    }
  `;
            }
            firstUpdated(_changedProperties) {
                super.firstUpdated(_changedProperties);
                requestAnimationFrame(()=>{
                    this.querySelector('affine-data-view-renderer')?.focusFirstCell();
                });
            }
            render() {
                const config = {
                    bindHotkey: this.bindHotkey,
                    handleEvent: this.handleEvent,
                    selection$: this.viewSelection$,
                    setSelection: this.setSelection,
                    dataSource: this.database.dataSource,
                    headerWidget: this.database.headerWidget,
                    std: this.database.std
                };
                return html`
      <affine-data-view-renderer
        .config="${config}"
      ></affine-data-view-renderer>
    `;
            }
            #___private_database_1;
            get database() {
                return this.#___private_database_1;
            }
            set database(_v) {
                this.#___private_database_1 = _v;
            }
            constructor(...args){
                super(...args);
                this.bindHotkey = (hotkeys)=>{
                    return {
                        dispose: this.database.host.event.bindHotkey(hotkeys, {
                            path: []
                        })
                    };
                };
                this.blockId = 'database-modal-preview';
                this.handleEvent = (name, handler)=>{
                    return {
                        dispose: this.database.host.event.add(name, handler, {
                            path: []
                        })
                    };
                };
                this.selectionUpdated = new Slot();
                this.setSelection = (selection)=>{
                    this.database.host.selection.set(selection ? [
                        new DatabaseSelection({
                            blockId: this.blockId,
                            viewSelection: selection
                        })
                    ] : []);
                };
                this.viewSelection$ = computed(()=>{
                    const databaseSelection = this.database.selection.value.find((selection)=>{
                        if (selection.blockId !== this.blockId) {
                            return false;
                        }
                        return selection instanceof DatabaseSelection;
                    });
                    return databaseSelection?.viewSelection;
                });
                this.#___private_database_1 = (_initProto(this), _init_database(this));
            }
        }
    }
}();
export { _ExpandDatabaseBlockModal as ExpandDatabaseBlockModal, _DatabaseBlockModalPreview as DatabaseBlockModalPreview };
