export var LineWidth;
(function(LineWidth) {
    LineWidth[LineWidth["Eight"] = 8] = "Eight";
    LineWidth[LineWidth["Four"] = 4] = "Four";
    LineWidth[LineWidth["Six"] = 6] = "Six";
    LineWidth[LineWidth["Ten"] = 10] = "Ten";
    LineWidth[LineWidth["Twelve"] = 12] = "Twelve";
    LineWidth[LineWidth["Two"] = 2] = "Two";
})(LineWidth || (LineWidth = {}));
export var LassoMode;
(function(LassoMode) {
    LassoMode[LassoMode["FreeHand"] = 0] = "FreeHand";
    LassoMode[LassoMode["Polygonal"] = 1] = "Polygonal";
})(LassoMode || (LassoMode = {}));
export var NoteDisplayMode;
(function(NoteDisplayMode) {
    NoteDisplayMode["DocAndEdgeless"] = "both";
    NoteDisplayMode["DocOnly"] = "doc";
    NoteDisplayMode["EdgelessOnly"] = "edgeless";
})(NoteDisplayMode || (NoteDisplayMode = {}));
