function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WidgetBase;
import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { popFilterableSimpleMenu, popMenu } from '../../../../_common/components/index.js';
import { AddCursorIcon, DuplicateIcon, MoreHorizontalIcon, MoveLeftIcon, MoveRightIcon } from '../../../../_common/icons/index.js';
import '../../common/component/overflow/overflow.js';
import { DeleteIcon } from '../../common/icons/index.js';
import { renderUniLit } from '../../utils/uni-component/index.js';
import { WidgetBase } from '../widget-base.js';
let _DataViewHeaderViews;
_dec = customElement('data-view-header-views');
new class extends _identity {
    constructor(){
        super(_DataViewHeaderViews), _initClass();
    }
    static{
        class DataViewHeaderViews extends (_WidgetBase = WidgetBase) {
            static{
                ({ c: [_DataViewHeaderViews, _initClass] } = _apply_decs_2203_r(this, [], [
                    _dec
                ], _WidgetBase));
            }
            static{
                this.styles = css`
    data-view-header-views {
      height: 32px;
      display: flex;
      user-select: none;
      gap: 4px;
    }
    data-view-header-views::-webkit-scrollbar-thumb {
      width: 1px;
    }

    .database-view-button {
      height: 100%;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: var(--affine-text-secondary-color);
      white-space: nowrap;
    }

    .database-view-button .name {
      align-items: center;
      height: 22px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .database-view-button .icon {
      margin-right: 6px;
      display: block;
    }

    .database-view-button .icon svg {
      width: 16px;
      height: 16px;
    }

    .database-view-button.active {
      color: var(--affine-text-primary-color);
      background-color: var(--affine-hover-color-filled);
    }
  `;
            }
            _clickView(event, id) {
                if (this.viewManager.currentViewId$.value !== id) {
                    this.viewManager.setCurrentView(id);
                    return;
                }
                this.openViewOption(event.target, id);
            }
            getRenderer(viewId) {
                return this.dataSource.viewMetaGetById(viewId).renderer;
            }
            render() {
                return html`
      <component-overflow
        .renderItem="${this.renderViews()}"
        .renderMore="${this.renderMore}"
      ></component-overflow>
    `;
            }
            get readonly() {
                return this.viewManager.readonly$.value;
            }
            constructor(...args){
                super(...args);
                this._addViewMenu = (event)=>{
                    popFilterableSimpleMenu(event.target, this.dataSource.viewMetas.map((v)=>{
                        return {
                            type: 'action',
                            name: v.model.defaultName,
                            icon: html`<uni-lit .uni=${v.renderer.icon}></uni-lit>`,
                            select: ()=>{
                                const id = this.viewManager.viewAdd(v.type);
                                this.viewManager.setCurrentView(id);
                            }
                        };
                    }));
                };
                this._showMore = (event)=>{
                    const views = this.viewManager.views$.value;
                    popFilterableSimpleMenu(event.target, [
                        ...views.map((id)=>{
                            const openViewOption = (event)=>{
                                event.stopPropagation();
                                this.openViewOption(event.target, id);
                            };
                            const view = this.viewManager.viewGet(id);
                            return {
                                type: 'action',
                                icon: html`<uni-lit .uni=${this.getRenderer(id).icon}></uni-lit>`,
                                name: view.viewData$.value?.name ?? '',
                                label: ()=>html`${view.viewData$.value?.name}`,
                                isSelected: this.viewManager.currentViewId$.value === id,
                                select: ()=>{
                                    this.viewManager.setCurrentView(id);
                                },
                                postfix: html`<div
            class="dv-hover dv-round-4"
            @click="${openViewOption}"
            style="display:flex;align-items:center;"
          >
            ${MoreHorizontalIcon}
          </div>`
                            };
                        }),
                        {
                            type: 'group',
                            name: '',
                            hide: ()=>this.readonly,
                            children: ()=>this.dataSource.viewMetas.map((v)=>{
                                    return {
                                        type: 'action',
                                        name: `Create ${v.model.defaultName}`,
                                        icon: html`<uni-lit .uni=${v.renderer.icon}></uni-lit>`,
                                        select: ()=>{
                                            const id = this.viewManager.viewAdd(v.type);
                                            this.viewManager.setCurrentView(id);
                                        }
                                    };
                                })
                        }
                    ]);
                };
                this.openViewOption = (target, id)=>{
                    if (this.readonly) {
                        return;
                    }
                    const views = this.viewManager.views$.value;
                    const index = views.findIndex((v)=>v === id);
                    const view = this.viewManager.viewGet(views[index]);
                    if (!view) {
                        return;
                    }
                    popMenu(target, {
                        options: {
                            input: {
                                initValue: view.viewData$.value?.name,
                                onComplete: (text)=>{
                                    view.viewDataUpdate((_data)=>({
                                            name: text
                                        }));
                                }
                            },
                            items: [
                                {
                                    type: 'action',
                                    name: 'Edit View',
                                    icon: renderUniLit(this.getRenderer(id).icon, {}),
                                    select: ()=>{
                                        this.closest('affine-data-view-renderer')?.querySelector('data-view-header-tools-view-options')?.openMoreAction(target);
                                    }
                                },
                                {
                                    type: 'action',
                                    name: 'Move Left',
                                    hide: ()=>index === 0,
                                    icon: MoveLeftIcon,
                                    select: ()=>{
                                        const targetId = views[index - 1];
                                        this.viewManager.moveTo(id, targetId ? {
                                            before: true,
                                            id: targetId
                                        } : 'start');
                                    }
                                },
                                {
                                    type: 'action',
                                    name: 'Move Right',
                                    icon: MoveRightIcon,
                                    hide: ()=>index === views.length - 1,
                                    select: ()=>{
                                        const targetId = views[index + 1];
                                        this.viewManager.moveTo(id, targetId ? {
                                            before: false,
                                            id: targetId
                                        } : 'end');
                                    }
                                },
                                {
                                    type: 'action',
                                    name: 'Duplicate',
                                    icon: DuplicateIcon,
                                    select: ()=>{
                                        this.viewManager.viewDuplicate(id);
                                    }
                                },
                                {
                                    type: 'group',
                                    name: '',
                                    children: ()=>[
                                            {
                                                type: 'action',
                                                name: 'Delete View',
                                                icon: DeleteIcon,
                                                select: ()=>{
                                                    view.delete();
                                                },
                                                class: 'delete-item'
                                            }
                                        ]
                                }
                            ]
                        }
                    });
                };
                this.renderMore = (count)=>{
                    const views = this.viewManager.views$.value;
                    if (count === views.length) {
                        if (this.readonly) {
                            return;
                        }
                        return html`<div
        class="database-view-button dv-icon-16 dv-hover"
        data-testid="database-add-view-button"
        @click="${this._addViewMenu}"
      >
        ${AddCursorIcon}
      </div>`;
                    }
                    return html`
      <div class="database-view-button dv-hover" @click="${this._showMore}">
        ${views.length - count} More
      </div>
    `;
                };
                this.renderViews = ()=>{
                    const views = this.viewManager.views$.value;
                    return views.map((id)=>()=>{
                            const classList = classMap({
                                'database-view-button': true,
                                'dv-hover': true,
                                active: this.viewManager.currentViewId$.value === id
                            });
                            const view = this.viewManager.viewDataGet(id);
                            return html`
        <div
          class="${classList}"
          style="margin-right: 4px;"
          @click="${(event)=>this._clickView(event, id)}"
        >
          <uni-lit class="icon" .uni="${this.getRenderer(id).icon}"></uni-lit>
          <div class="name">${view?.name}</div>
        </div>
      `;
                        });
                };
            }
        }
    }
}();
export { _DataViewHeaderViews as DataViewHeaderViews };
