function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _init__selectionValue, _init_showDownIndicator, _init_avatarUrl, _init_host, _init_isLoading, _init_chatContextValue, _init_updateContext, _init_messagesContainer, _init_showChatCards, _initProto;
import '../messages/slides-renderer';
import './ai-loading';
import '../messages/text';
import './actions/text';
import './actions/action-wrapper';
import './actions/make-real';
import './actions/slides';
import './actions/mindmap';
import './actions/chat-text';
import './actions/image-to-text';
import './actions/image';
import './chat-cards';
import '../_common/components/chat-action-list';
import '../_common/components/copy-more';
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { isInsidePageEditor, PaymentRequiredError, UnauthorizedError } from '@blocksuite/blocks';
import { css, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { EdgelessEditorActions, PageEditorActions } from '../_common/chat-actions-handle';
import { AffineAvatarIcon, AffineIcon, DownArrowIcon } from '../_common/icons';
import { AIChatErrorRenderer } from '../messages/error';
import { AIProvider } from '../provider';
import { HISTORY_IMAGE_ACTIONS } from './const';
import { AIPreloadConfig } from './preload-config';
let _ChatPanelMessages;
_dec = customElement('chat-panel-messages'), _dec1 = state(), _dec2 = state(), _dec3 = state(), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = query('.chat-panel-messages'), _dec9 = state();
new class extends _identity {
    constructor(){
        super(_ChatPanelMessages), _initClass();
    }
    static{
        class ChatPanelMessages extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init__selectionValue, _init_showDownIndicator, _init_avatarUrl, _init_host, _init_isLoading, _init_chatContextValue, _init_updateContext, _init_messagesContainer, _init_showChatCards, _initProto], c: [_ChatPanelMessages, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_selectionValue"
                    ],
                    [
                        _dec2,
                        1,
                        "showDownIndicator"
                    ],
                    [
                        _dec3,
                        1,
                        "avatarUrl"
                    ],
                    [
                        _dec4,
                        1,
                        "host"
                    ],
                    [
                        _dec5,
                        1,
                        "isLoading"
                    ],
                    [
                        _dec6,
                        1,
                        "chatContextValue"
                    ],
                    [
                        _dec7,
                        1,
                        "updateContext"
                    ],
                    [
                        _dec8,
                        1,
                        "messagesContainer"
                    ],
                    [
                        _dec9,
                        1,
                        "showChatCards"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    chat-panel-messages {
      position: relative;
    }

    .chat-panel-messages {
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 100%;
      position: relative;
      overflow-y: auto;

      chat-cards {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    .chat-panel-messages-placeholder {
      width: 100%;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }

    .item-wrapper {
      margin-left: 32px;
    }

    .user-info {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 4px;
      color: var(--affine-text-primary-color);
      font-size: 14px;
      font-weight: 500;
      user-select: none;
    }

    .avatar-container {
      width: 24px;
      height: 24px;
    }

    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--affine-primary-color);
    }

    .avatar-container img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .down-indicator {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 24px;
      z-index: 1;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      border: 0.5px solid var(--affine-border-color);
      background-color: var(--affine-background-primary-color);
      box-shadow: var(--affine-shadow-2);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  `;
            }
            #___private__selectionValue_1;
            get _selectionValue() {
                return this.#___private__selectionValue_1;
            }
            set _selectionValue(_v) {
                this.#___private__selectionValue_1 = _v;
            }
            #___private_showDownIndicator_2;
            get showDownIndicator() {
                return this.#___private_showDownIndicator_2;
            }
            set showDownIndicator(_v) {
                this.#___private_showDownIndicator_2 = _v;
            }
            #___private_avatarUrl_3;
            get avatarUrl() {
                return this.#___private_avatarUrl_3;
            }
            set avatarUrl(_v) {
                this.#___private_avatarUrl_3 = _v;
            }
            #___private_host_4;
            get host() {
                return this.#___private_host_4;
            }
            set host(_v) {
                this.#___private_host_4 = _v;
            }
            #___private_isLoading_5;
            get isLoading() {
                return this.#___private_isLoading_5;
            }
            set isLoading(_v) {
                this.#___private_isLoading_5 = _v;
            }
            #___private_chatContextValue_6;
            get chatContextValue() {
                return this.#___private_chatContextValue_6;
            }
            set chatContextValue(_v) {
                this.#___private_chatContextValue_6 = _v;
            }
            #___private_updateContext_7;
            get updateContext() {
                return this.#___private_updateContext_7;
            }
            set updateContext(_v) {
                this.#___private_updateContext_7 = _v;
            }
            #___private_messagesContainer_8;
            get messagesContainer() {
                return this.#___private_messagesContainer_8;
            }
            set messagesContainer(_v) {
                this.#___private_messagesContainer_8 = _v;
            }
            #___private_showChatCards_9;
            get showChatCards() {
                return this.#___private_showChatCards_9;
            }
            set showChatCards(_v) {
                this.#___private_showChatCards_9 = _v;
            }
            updated(changedProperties) {
                if (changedProperties.has('host')) {
                    const { disposables } = this;
                    disposables.add(this.host.selection.slots.changed.on(()=>{
                        this._selectionValue = this.host.selection.value;
                    }));
                    const { docModeService } = this.host.spec.getService('affine:page');
                    disposables.add(docModeService.onModeChange(()=>this.requestUpdate()));
                }
            }
            _renderAIOnboarding() {
                return this.isLoading || !this.host?.doc.awarenessStore.getFlag('enable_ai_onboarding') ? nothing : html`<div
          style=${styleMap({
                    display: 'flex',
                    gap: '8px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '16px',
                    width: '100%'
                })}
        >
          ${repeat(AIPreloadConfig, (config)=>config.text, (config)=>{
                    return html`<div
                @click=${()=>config.handler()}
                style=${styleMap({
                        display: 'flex',
                        height: '28px',
                        gap: '8px',
                        width: '88%',
                        alignItems: 'center',
                        justifyContent: 'start',
                        cursor: 'pointer'
                    })}
              >
                ${config.icon}
                <div
                  style=${styleMap({
                        fontSize: '12px',
                        fontWeight: '400',
                        color: 'var(--affine-text-primary-color)'
                    })}
                >
                  ${config.text}
                </div>
              </div>`;
                })}
        </div>`;
            }
            render() {
                const { items } = this.chatContextValue;
                const { isLoading } = this;
                const filteredItems = items.filter((item)=>{
                    return 'role' in item || item.messages?.length === 3 || HISTORY_IMAGE_ACTIONS.includes(item.action) && item.messages?.length === 2;
                });
                return html`<style>
        .chat-panel-messages-placeholder div {
          color: ${isLoading ? 'var(--affine-text-secondary-color)' : 'var(--affine-text-primary-color)'};
          font-size: ${isLoading ? 'var(--affine-font-sm)' : '18px'};
          font-weight: 600;
        }
      </style>

      <div
        class="chat-panel-messages"
        @scroll=${(evt)=>{
                    const element = evt.target;
                    this.showDownIndicator = element.scrollHeight - element.scrollTop - element.clientHeight > 200;
                }}
      >
        ${items.length === 0 ? html`<div class="chat-panel-messages-placeholder">
              ${AffineIcon(isLoading ? 'var(--affine-icon-secondary)' : 'var(--affine-primary-color)')}
              <div>
                ${this.isLoading ? 'Wemem AI is loading history...' : 'What can I help you with?'}
              </div>
              ${this._renderAIOnboarding()}
            </div> ` : repeat(filteredItems, (item, index)=>{
                    const isLast = index === filteredItems.length - 1;
                    return html`<div class="message">
                ${this.renderAvatar(item)}
                <div class="item-wrapper">${this.renderItem(item, isLast)}</div>
              </div>`;
                })}
        <chat-cards
          .updateContext=${this.updateContext}
          .host=${this.host}
          .isEmpty=${items.length === 0}
          ?data-show=${this.showChatCards}
        ></chat-cards>
      </div>
      ${this.showDownIndicator ? html`<div class="down-indicator" @click=${()=>this.scrollToDown()}>
            ${DownArrowIcon}
          </div>` : nothing} `;
            }
            async connectedCallback() {
                super.connectedCallback();
                const res = await AIProvider.userInfo;
                this.avatarUrl = res?.avatarUrl ?? '';
                this.disposables.add(AIProvider.slots.userInfo.on((userInfo)=>{
                    const { status, error } = this.chatContextValue;
                    this.avatarUrl = userInfo?.avatarUrl ?? '';
                    if (status === 'error' && error instanceof UnauthorizedError && userInfo) {
                        this.updateContext({
                            status: 'idle',
                            error: null
                        });
                    }
                }));
                this.disposables.add(AIProvider.slots.toggleChatCards.on(({ visible })=>{
                    this.showChatCards = visible;
                }));
            }
            renderItem(item, isLast) {
                const { status, error } = this.chatContextValue;
                const { host } = this;
                if (isLast && status === 'loading') {
                    return this.renderLoading();
                }
                if (isLast && status === 'error' && (error instanceof PaymentRequiredError || error instanceof UnauthorizedError)) {
                    return AIChatErrorRenderer(host, error);
                }
                if ('role' in item) {
                    const state = isLast ? status !== 'loading' && status !== 'transmitting' ? 'finished' : 'generating' : 'finished';
                    const shouldRenderError = isLast && status === 'error' && !!error;
                    return html`<chat-text
          .host=${host}
          .attachments=${item.attachments}
          .text=${item.content}
          .state=${state}
        ></chat-text>
        ${shouldRenderError ? AIChatErrorRenderer(host, error) : nothing}
        ${this.renderEditorActions(item, isLast)}`;
                } else {
                    switch(item.action){
                        case 'Create a presentation':
                            return html`<action-slides
            .host=${host}
            .item=${item}
          ></action-slides>`;
                        case 'Make it real':
                            return html`<action-make-real
            .host=${host}
            .item=${item}
          ></action-make-real>`;
                        case 'Brainstorm mindmap':
                            return html`<action-mindmap
            .host=${host}
            .item=${item}
          ></action-mindmap>`;
                        case 'Explain this image':
                        case 'Generate a caption':
                            return html`<action-image-to-text
            .host=${host}
            .item=${item}
          ></action-image-to-text>`;
                        default:
                            if (HISTORY_IMAGE_ACTIONS.includes(item.action)) {
                                return html`<action-image
              .host=${host}
              .item=${item}
            ></action-image>`;
                            }
                            return html`<action-text
            .item=${item}
            .host=${host}
            .isCode=${item.action === 'Explain this code' || item.action === 'Check code error'}
          ></action-text>`;
                    }
                }
            }
            renderAvatar(item) {
                const isUser = 'role' in item && item.role === 'user';
                return html`<div class="user-info">
      ${isUser ? html`<div class="avatar-container">
            ${this.avatarUrl ? html`<img .src=${this.avatarUrl} />` : html`<div class="avatar"></div>`}
          </div>` : AffineAvatarIcon}
      ${isUser ? 'You' : 'Wemem AI'}
    </div>`;
            }
            renderLoading() {
                return html` <ai-loading></ai-loading>`;
            }
            scrollToDown() {
                this.messagesContainer.scrollTo(0, this.messagesContainer.scrollHeight);
            }
            renderEditorActions(item, isLast) {
                const { status, chatSessionId } = this.chatContextValue;
                if (item.role !== 'assistant') return nothing;
                if (isLast && status !== 'success' && status !== 'idle' && status !== 'error') return nothing;
                const { host } = this;
                const { content, id: messageId } = item;
                const actions = isInsidePageEditor(host) ? PageEditorActions : EdgelessEditorActions;
                return html`
      <chat-copy-more
        .host=${host}
        .actions=${actions}
        .content=${content}
        .isLast=${isLast}
        .chatSessionId=${chatSessionId ?? undefined}
        .messageId=${messageId}
        .withMargin=${true}
        .retry=${()=>this.retry()}
      ></chat-copy-more>
      ${isLast && !!content ? html`<chat-action-list
            .actions=${actions}
            .host=${host}
            .content=${content}
            .chatSessionId=${chatSessionId ?? undefined}
            .messageId=${messageId ?? undefined}
            .withMargin=${true}
          ></chat-action-list>` : nothing}
    `;
            }
            constructor(...args){
                super(...args);
                this.#___private__selectionValue_1 = (_initProto(this), _init__selectionValue(this, []));
                this.#___private_showDownIndicator_2 = _init_showDownIndicator(this, false);
                this.#___private_avatarUrl_3 = _init_avatarUrl(this, '');
                this.#___private_host_4 = _init_host(this);
                this.#___private_isLoading_5 = _init_isLoading(this);
                this.#___private_chatContextValue_6 = _init_chatContextValue(this);
                this.#___private_updateContext_7 = _init_updateContext(this);
                this.#___private_messagesContainer_8 = _init_messagesContainer(this);
                this.#___private_showChatCards_9 = _init_showChatCards(this, true);
                this.retry = async ()=>{
                    const { doc } = this.host;
                    try {
                        const { chatSessionId } = this.chatContextValue;
                        if (!chatSessionId) return;
                        const abortController = new AbortController();
                        const items = [
                            ...this.chatContextValue.items
                        ];
                        const last = items[items.length - 1];
                        if ('content' in last) {
                            last.content = '';
                            last.createdAt = new Date().toISOString();
                        }
                        this.updateContext({
                            items,
                            status: 'loading',
                            error: null
                        });
                        const stream = AIProvider.actions.chat?.({
                            sessionId: chatSessionId,
                            retry: true,
                            docId: doc.id,
                            workspaceId: doc.collection.id,
                            host: this.host,
                            stream: true,
                            signal: abortController.signal,
                            where: 'chat-panel',
                            control: 'chat-send',
                            isRootSession: true
                        });
                        if (stream) {
                            this.updateContext({
                                abortController
                            });
                            for await (const text of stream){
                                const items = [
                                    ...this.chatContextValue.items
                                ];
                                const last = items[items.length - 1];
                                last.content += text;
                                this.updateContext({
                                    items,
                                    status: 'transmitting'
                                });
                            }
                            this.updateContext({
                                status: 'success'
                            });
                        }
                    } catch (error) {
                        this.updateContext({
                            status: 'error',
                            error: error
                        });
                    } finally{
                        this.updateContext({
                            abortController: null
                        });
                    }
                };
            }
        }
    }
}();
export { _ChatPanelMessages as ChatPanelMessages };
