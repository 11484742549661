import { assertEquals } from '@blocksuite/global/utils';
import { z } from 'zod';
import { isDesktop, isServer } from './constant.js';
import { UaHelper } from './ua-helper.js';
export const runtimeFlagsSchema = z.object({
    serverUrlPrefix: z.string(),
    appVersion: z.string(),
    editorVersion: z.string(),
    appBuildType: z.union([
        z.literal('stable'),
        z.literal('beta'),
        z.literal('internal'),
        z.literal('canary')
    ]),
    isSelfHosted: z.boolean().optional(),
    githubUrl: z.string(),
    changelogUrl: z.string(),
    downloadUrl: z.string(),
    imageProxyUrl: z.string(),
    linkPreviewUrl: z.string(),
    allowLocalWorkspace: z.boolean(),
    enablePreloading: z.boolean(),
    enableNewSettingUnstableApi: z.boolean(),
    enableCaptcha: z.boolean(),
    enableEnhanceShareMode: z.boolean(),
    enableExperimentalFeature: z.boolean(),
    enableInfoModal: z.boolean(),
    enableOrganize: z.boolean()
});
export function setupGlobal() {
    if (globalThis.$AFFINE_SETUP) {
        return;
    }
    runtimeFlagsSchema.parse(runtimeConfig);
    let environment;
    const isDebug = process.env.NODE_ENV === 'development';
    if (isServer) {
        environment = {
            isDesktop: false,
            isBrowser: false,
            isServer: true,
            isDebug
        };
    } else {
        const uaHelper = new UaHelper(navigator);
        environment = {
            origin: window.location.origin,
            isDesktop,
            isBrowser: true,
            isServer: false,
            isDebug,
            isLinux: uaHelper.isLinux,
            isMacOs: uaHelper.isMacOs,
            isSafari: uaHelper.isSafari,
            isWindows: uaHelper.isWindows,
            isFireFox: uaHelper.isFireFox,
            isMobile: uaHelper.isMobile,
            isChrome: uaHelper.isChrome,
            isIOS: uaHelper.isIOS
        };
        if (environment.isChrome && !environment.isIOS) {
            assertEquals(environment.isSafari, false);
            assertEquals(environment.isFireFox, false);
            environment = {
                ...environment,
                isSafari: false,
                isFireFox: false,
                isChrome: true,
                chromeVersion: uaHelper.getChromeVersion()
            };
        }
    }
    globalThis.environment = environment;
    globalThis.$AFFINE_SETUP = true;
}
