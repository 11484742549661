import { html, nothing } from 'lit';
import './change-text-menu.js';
export function renderChangeEdgelessTextButton(edgeless, elements) {
    if (!elements?.length) return nothing;
    return html`
    <edgeless-change-text-menu
      .elementType=${'edgeless-text'}
      .elements=${elements}
      .edgeless=${edgeless}
    ></edgeless-change-text-menu>
  `;
}
