function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _init_display, _init_externalXYWH, _init_index, _init_opacity, _init_seed, _initProto;
import { Bound, DisposableGroup, PointLocation, deserializeXYWH, getBoundsWithRotation, getPointsFromBoundsWithRotation, linePolygonIntersects, polygonGetPointTangent, polygonNearestPoint, randomSeed, rotatePoints } from '@blocksuite/global/utils';
import { DocCollection } from '@blocksuite/store';
import { createMutex } from 'lib0/mutex';
import { convertProps, getDerivedProps, getYFieldPropsSet, local, updateDerivedProps, watch, yfield } from './decorators/index.js';
_dec = local(), _dec1 = watch((_, instance)=>{
    instance['_local'].delete('externalBound');
}), _dec2 = local(), _dec3 = yfield(), _dec4 = local(), _dec5 = yfield();
export class GfxPrimitiveElementModel {
    static{
        ({ e: [_init_display, _init_externalXYWH, _init_index, _init_opacity, _init_seed, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "display"
            ],
            [
                [
                    _dec1,
                    _dec2
                ],
                1,
                "externalXYWH"
            ],
            [
                _dec3,
                1,
                "index"
            ],
            [
                _dec4,
                1,
                "opacity"
            ],
            [
                _dec5,
                1,
                "seed"
            ]
        ], []));
    }
    constructor(options){
        this._disposable = new DisposableGroup();
        this._local = new Map();
        this._preserved = new Map();
        this.#___private_display_1 = (_initProto(this), _init_display(this, true));
        this.#___private_externalXYWH_2 = _init_externalXYWH(this, undefined);
        this.#___private_index_3 = _init_index(this);
        this.#___private_opacity_4 = _init_opacity(this, 1);
        this.#___private_seed_5 = _init_seed(this);
        const { id, yMap, model, stashedStore, onChange } = options;
        this._id = id;
        this.yMap = yMap;
        this.surface = model;
        this._stashed = stashedStore;
        this._onChange = onChange;
        this.index = 'a0';
        this.seed = randomSeed();
    }
    static propsToY(props) {
        return props;
    }
    containsBound(bounds) {
        return getPointsFromBoundsWithRotation(this).some((point)=>bounds.containsPoint(point));
    }
    getLineIntersections(start, end) {
        const points = getPointsFromBoundsWithRotation(this);
        return linePolygonIntersects(start, end, points);
    }
    getNearestPoint(point) {
        const points = getPointsFromBoundsWithRotation(this);
        return polygonNearestPoint(points, point);
    }
    getRelativePointLocation(relativePoint) {
        const bound = Bound.deserialize(this.xywh);
        const point = bound.getRelativePoint(relativePoint);
        const rotatePoint = rotatePoints([
            point
        ], bound.center, this.rotate)[0];
        const points = rotatePoints(bound.points, bound.center, this.rotate);
        const tangent = polygonGetPointTangent(points, rotatePoint);
        return new PointLocation(rotatePoint, tangent);
    }
    includesPoint(x, y, _, __) {
        return this.elementBound.isPointInBound([
            x,
            y
        ]);
    }
    intersectsBound(bound) {
        return this.containsBound(bound) || bound.points.some((point, i, points)=>this.getLineIntersections(point, points[(i + 1) % points.length]));
    }
    onCreated() {}
    pop(prop) {
        if (!this._stashed.has(prop)) {
            return;
        }
        const value = this._stashed.get(prop);
        this._stashed.delete(prop);
        delete this[prop];
        if (getYFieldPropsSet(this).has(prop)) {
            this.surface.doc.transact(()=>{
                this.yMap.set(prop, value);
            });
        } else {
            console.warn('pop a prop that is not yfield or local:', prop);
        }
    }
    serialize() {
        return this.yMap.toJSON();
    }
    stash(prop) {
        if (this._stashed.has(prop)) {
            return;
        }
        if (!getYFieldPropsSet(this).has(prop)) {
            return;
        }
        const curVal = this[prop];
        this._stashed.set(prop, curVal);
        Object.defineProperty(this, prop, {
            configurable: true,
            enumerable: true,
            get: ()=>this._stashed.get(prop),
            set: (original)=>{
                const value = convertProps(prop, original, this);
                const oldValue = this._stashed.get(prop);
                const derivedProps = getDerivedProps(prop, original, this);
                this._stashed.set(prop, value);
                this._onChange({
                    props: {
                        [prop]: value
                    },
                    oldValues: {
                        [prop]: oldValue
                    },
                    local: true
                });
                this.surface['hooks'].update.emit({
                    id: this.id,
                    props: {
                        [prop]: value
                    },
                    oldValues: {
                        [prop]: oldValue
                    }
                });
                updateDerivedProps(derivedProps, this);
            }
        });
    }
    get connectable() {
        return true;
    }
    get deserializedXYWH() {
        if (!this._lastXYWH || this.xywh !== this._lastXYWH) {
            const xywh = this.xywh;
            this._local.set('deserializedXYWH', deserializeXYWH(xywh));
            this._lastXYWH = xywh;
        }
        return this._local.get('deserializedXYWH') ?? [
            0,
            0,
            0,
            0
        ];
    }
    get elementBound() {
        if (this.rotate) {
            return Bound.from(getBoundsWithRotation(this));
        }
        return Bound.deserialize(this.xywh);
    }
    get externalBound() {
        if (!this._local.has('externalBound')) {
            const bound = this.externalXYWH ? Bound.deserialize(this.externalXYWH) : null;
            this._local.set('externalBound', bound);
        }
        return this._local.get('externalBound');
    }
    get group() {
        return this.surface.getGroup(this.id);
    }
    get groups() {
        return this.surface.getGroups(this.id);
    }
    get h() {
        return this.deserializedXYWH[3];
    }
    get id() {
        return this._id;
    }
    get isConnected() {
        return this.surface.hasElementById(this.id);
    }
    get w() {
        return this.deserializedXYWH[2];
    }
    get x() {
        return this.deserializedXYWH[0];
    }
    get y() {
        return this.deserializedXYWH[1];
    }
    #___private_display_1;
    get display() {
        return this.#___private_display_1;
    }
    set display(_v) {
        this.#___private_display_1 = _v;
    }
    #___private_externalXYWH_2;
    get externalXYWH() {
        return this.#___private_externalXYWH_2;
    }
    set externalXYWH(_v) {
        this.#___private_externalXYWH_2 = _v;
    }
    #___private_index_3;
    get index() {
        return this.#___private_index_3;
    }
    set index(_v) {
        this.#___private_index_3 = _v;
    }
    #___private_opacity_4;
    get opacity() {
        return this.#___private_opacity_4;
    }
    set opacity(_v) {
        this.#___private_opacity_4 = _v;
    }
    #___private_seed_5;
    get seed() {
        return this.#___private_seed_5;
    }
    set seed(_v) {
        this.#___private_seed_5 = _v;
    }
}
export class GfxGroupLikeElementModel extends GfxPrimitiveElementModel {
    _updateXYWH() {
        let bound;
        let cacheKey = '';
        const oldValue = this._local.get('xywh') ?? '[0,0,0,0]';
        this.childElements.forEach((child)=>{
            cacheKey += child.xywh ?? '';
            bound = bound ? bound.unite(child.elementBound) : child.elementBound;
        });
        if (bound) {
            this._local.set('xywh', bound.serialize());
            this._childBoundCacheKey = cacheKey;
        } else {
            this._local.delete('xywh');
            this._childBoundCacheKey = '';
        }
        this._onChange({
            props: {
                xywh: bound?.serialize()
            },
            oldValues: {
                xywh: oldValue
            },
            local: true
        });
    }
    descendants(withoutGroup = true) {
        return this.childElements.reduce((prev, child)=>{
            if (child instanceof GfxGroupLikeElementModel) {
                prev = prev.concat(child.descendants());
                !withoutGroup && prev.push(child);
            } else {
                prev.push(child);
            }
            return prev;
        }, []);
    }
    hasChild(element) {
        return (typeof element === 'string' ? this.children?.has(element) : this.children?.has(element.id)) ?? false;
    }
    hasDescendant(element) {
        const groups = this.surface.getGroups(typeof element === 'string' ? element : element.id);
        return groups.some((group)=>group.id === this.id);
    }
    setChildIds(value, fromLocal) {
        const oldChildIds = this.childIds;
        this._childIds = value;
        this._onChange({
            props: {
                childIds: value
            },
            oldValues: {
                childIds: oldChildIds
            },
            local: fromLocal
        });
        this.surface['hooks'].update.emit({
            id: this.id,
            props: {
                childIds: value
            },
            oldValues: {
                childIds: oldChildIds
            }
        });
    }
    get childElements() {
        const elements = [];
        for (const key of this.childIds){
            const element = this.surface.getElementById(key) || this.surface.doc.getBlockById(key);
            element && elements.push(element);
        }
        return elements;
    }
    get childIds() {
        return this._childIds;
    }
    get xywh() {
        if (!this._local.has('xywh') || this.childElements.reduce((pre, model)=>pre + (model.xywh ?? ''), '') !== this._childBoundCacheKey) {
            this._mutex(()=>{
                this._updateXYWH();
            });
        }
        return this._local.get('xywh') ?? '[0,0,0,0]';
    }
    set xywh(_) {}
    constructor(...args){
        super(...args);
        this._childBoundCacheKey = '';
        this._childIds = [];
        this._mutex = createMutex();
    }
}
export class GfxLocalElementModel {
    get deserializedXYWH() {
        if (this.xywh !== this._lastXYWH) {
            const xywh = this.xywh;
            this._local.set('deserializedXYWH', deserializeXYWH(xywh));
            this._lastXYWH = xywh;
        }
        return this._local.get('deserializedXYWH');
    }
    get h() {
        return this.deserializedXYWH[3];
    }
    get w() {
        return this.deserializedXYWH[2];
    }
    get x() {
        return this.deserializedXYWH[0];
    }
    get y() {
        return this.deserializedXYWH[1];
    }
    constructor(){
        this._lastXYWH = '[0,0,-1,-1]';
        this._local = new Map();
        this.opacity = 1;
    }
}
export function syncElementFromY(model, callback) {
    const disposables = {};
    const observer = (event, transaction)=>{
        const props = {};
        const oldValues = {};
        event.keysChanged.forEach((key)=>{
            const type = event.changes.keys.get(key);
            const oldValue = event.changes.keys.get(key)?.oldValue;
            if (!type) {
                return;
            }
            if (type.action === 'update' || type.action === 'add') {
                const value = model.yMap.get(key);
                if (value instanceof DocCollection.Y.Text) {
                    disposables[key]?.();
                    disposables[key] = watchText(key, value, callback);
                }
                model['_preserved'].set(key, value);
                props[key] = value;
                oldValues[key] = oldValue;
            }
        });
        callback({
            props,
            oldValues,
            local: transaction.local
        });
    };
    Array.from(model.yMap.entries()).forEach(([key, value])=>{
        if (value instanceof DocCollection.Y.Text) {
            disposables[key] = watchText(key, value, callback);
        }
        model['_preserved'].set(key, value);
    });
    model.yMap.observe(observer);
    disposables['ymap'] = ()=>{
        model.yMap.unobserve(observer);
    };
    return ()=>{
        Object.values(disposables).forEach((fn)=>fn());
    };
}
function watchText(key, value, callback) {
    const fn = (_, transaction)=>{
        callback({
            props: {
                [key]: value
            },
            oldValues: {},
            local: transaction.local
        });
    };
    value.observe(fn);
    return ()=>{
        value.unobserve(fn);
    };
}
