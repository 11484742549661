function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _initClass, _WithDisposable, _dec1, _init_edgeless, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { Bound } from '@blocksuite/global/utils';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import '../../../_common/components/toolbar/icon-button.js';
import '../../../_common/components/toolbar/menu-button.js';
import '../../../_common/components/toolbar/separator.js';
import { AlignBottomIcon, AlignDistributeHorizontallyIcon, AlignDistributeVerticallyIcon, AlignHorizontallyIcon, AlignLeftIcon, AlignRightIcon, AlignTopIcon, AlignVerticallyIcon, SmallArrowDownIcon } from '../../../_common/icons/index.js';
import { ConnectorElementModel, GroupElementModel, MindmapElementModel } from '../../../surface-block/index.js';
var Alignment;
const ALIGNMENT_LIST = [
    {
        name: "Align left",
        content: AlignLeftIcon
    },
    {
        name: "Align horizontally",
        content: AlignHorizontallyIcon
    },
    {
        name: "Align right",
        content: AlignRightIcon
    },
    {
        name: "Distribute horizontally",
        content: AlignDistributeHorizontallyIcon
    },
    {
        name: 'separator',
        content: html`<editor-toolbar-separator></editor-toolbar-separator>`
    },
    {
        name: "Align top",
        content: AlignTopIcon
    },
    {
        name: "Align vertically",
        content: AlignVerticallyIcon
    },
    {
        name: "Align bottom",
        content: AlignBottomIcon
    },
    {
        name: "Distribute vertically",
        content: AlignDistributeVerticallyIcon
    }
];
let _EdgelessAlignButton;
_dec = customElement('edgeless-align-button'), _dec1 = property({
    attribute: false
});
class EdgelessAlignButton extends (_WithDisposable = WithDisposable(LitElement)) {
    static{
        ({ e: [_init_edgeless, _initProto], c: [_EdgelessAlignButton, _initClass] } = _apply_decs_2203_r(this, [
            [
                _dec1,
                1,
                "edgeless"
            ]
        ], [
            _dec
        ], _WithDisposable));
    }
    _align(type) {
        switch(type){
            case "Align left":
                this._alignLeft();
                break;
            case "Align horizontally":
                this._alignHorizontally();
                break;
            case "Align right":
                this._alignRight();
                break;
            case "Distribute horizontally":
                this._alignDistributeHorizontally();
                break;
            case "Align top":
                this._alignTop();
                break;
            case "Align vertically":
                this._alignVertically();
                break;
            case "Align bottom":
                this._alignBottom();
                break;
            case "Distribute vertically":
                this._alignDistributeVertically();
                break;
        }
    }
    _alignBottom() {
        const { elements } = this;
        const bounds = elements.map((a)=>a.elementBound);
        const bottom = Math.max(...bounds.map((b)=>b.maxY));
        elements.forEach((ele, index)=>{
            const elementBound = bounds[index];
            const bound = Bound.deserialize(ele.xywh);
            const offset = bound.maxY - elementBound.maxY;
            bound.y = bottom - bound.h + offset;
            this._updateXYWH(ele, bound);
        });
    }
    _alignDistributeHorizontally() {
        const { elements } = this;
        elements.sort((a, b)=>a.elementBound.minX - b.elementBound.minX);
        const bounds = elements.map((a)=>a.elementBound);
        const left = bounds[0].minX;
        const right = bounds[bounds.length - 1].maxX;
        const totalWidth = right - left;
        const totalGap = totalWidth - elements.reduce((prev, ele)=>prev + ele.elementBound.w, 0);
        const gap = totalGap / (elements.length - 1);
        let next = bounds[0].maxX + gap;
        for(let i = 1; i < elements.length - 1; i++){
            const bound = Bound.deserialize(elements[i].xywh);
            bound.x = next + bounds[i].w / 2 - bound.w / 2;
            next += gap + bounds[i].w;
            this._updateXYWH(elements[i], bound);
        }
    }
    _alignDistributeVertically() {
        const { elements } = this;
        elements.sort((a, b)=>a.elementBound.minY - b.elementBound.minY);
        const bounds = elements.map((a)=>a.elementBound);
        const top = bounds[0].minY;
        const bottom = bounds[bounds.length - 1].maxY;
        const totalHeight = bottom - top;
        const totalGap = totalHeight - elements.reduce((prev, ele)=>prev + ele.elementBound.h, 0);
        const gap = totalGap / (elements.length - 1);
        let next = bounds[0].maxY + gap;
        for(let i = 1; i < elements.length - 1; i++){
            const bound = Bound.deserialize(elements[i].xywh);
            bound.y = next + bounds[i].h / 2 - bound.h / 2;
            next += gap + bounds[i].h;
            this._updateXYWH(elements[i], bound);
        }
    }
    _alignHorizontally() {
        const { elements } = this;
        const bounds = elements.map((a)=>a.elementBound);
        const left = Math.min(...bounds.map((b)=>b.minX));
        const right = Math.max(...bounds.map((b)=>b.maxX));
        const centerX = (left + right) / 2;
        elements.forEach((ele)=>{
            const bound = Bound.deserialize(ele.xywh);
            bound.x = centerX - bound.w / 2;
            this._updateXYWH(ele, bound);
        });
    }
    _alignLeft() {
        const { elements } = this;
        const bounds = elements.map((a)=>a.elementBound);
        const left = Math.min(...bounds.map((b)=>b.minX));
        elements.forEach((ele, index)=>{
            const elementBound = bounds[index];
            const bound = Bound.deserialize(ele.xywh);
            const offset = bound.minX - elementBound.minX;
            bound.x = left + offset;
            this._updateXYWH(ele, bound);
        });
    }
    _alignRight() {
        const { elements } = this;
        const bounds = elements.map((a)=>a.elementBound);
        const right = Math.max(...bounds.map((b)=>b.maxX));
        elements.forEach((ele, index)=>{
            const elementBound = bounds[index];
            const bound = Bound.deserialize(ele.xywh);
            const offset = bound.maxX - elementBound.maxX;
            bound.x = right - bound.w + offset;
            this._updateXYWH(ele, bound);
        });
    }
    _alignTop() {
        const { elements } = this;
        const bounds = elements.map((a)=>a.elementBound);
        const top = Math.min(...bounds.map((b)=>b.minY));
        elements.forEach((ele, index)=>{
            const elementBound = bounds[index];
            const bound = Bound.deserialize(ele.xywh);
            const offset = bound.minY - elementBound.minY;
            bound.y = top + offset;
            this._updateXYWH(ele, bound);
        });
    }
    _alignVertically() {
        const { elements } = this;
        const bounds = elements.map((a)=>a.elementBound);
        const top = Math.min(...bounds.map((b)=>b.minY));
        const bottom = Math.max(...bounds.map((b)=>b.maxY));
        const centerY = (top + bottom) / 2;
        elements.forEach((ele)=>{
            const bound = Bound.deserialize(ele.xywh);
            bound.y = centerY - bound.h / 2;
            this._updateXYWH(ele, bound);
        });
    }
    _updateXYWH(ele, bound) {
        if (ele instanceof ConnectorElementModel) {
            ele.moveTo(bound);
        } else if (ele instanceof GroupElementModel) {
            const groupBound = Bound.deserialize(ele.xywh);
            ele.childElements.forEach((child)=>{
                const newBound = Bound.deserialize(child.xywh);
                newBound.x += bound.x - groupBound.x;
                newBound.y += bound.y - groupBound.y;
                this._updateXYWH(child, newBound);
            });
        } else {
            this.edgeless.service.updateElement(ele.id, {
                xywh: bound.serialize()
            });
        }
    }
    get elements() {
        return this.edgeless.service.selection.selectedElements;
    }
    firstUpdated() {
        this._disposables.add(this.edgeless.service.selection.slots.updated.on(()=>this.requestUpdate()));
    }
    render() {
        return html`
      <editor-menu-button
        .button=${html`
          <editor-icon-button
            aria-label="Align objects"
            .tooltip=${'Align objects'}
          >
            ${AlignLeftIcon}${SmallArrowDownIcon}
          </editor-icon-button>
        `}
      >
        <div>
          ${repeat(ALIGNMENT_LIST, (item, index)=>item.name + index, ({ name, content })=>{
            if (name === 'separator') return content;
            return html`
                <editor-icon-button
                  aria-label=${name}
                  .tooltip=${name}
                  @click=${()=>this._align(name)}
                >
                  ${content}
                </editor-icon-button>
              `;
        })}
        </div>
      </editor-menu-button>
    `;
    }
    #___private_edgeless_1;
    get edgeless() {
        return this.#___private_edgeless_1;
    }
    set edgeless(_v) {
        this.#___private_edgeless_1 = _v;
    }
    static{
        _initClass();
    }
    constructor(...args){
        super(...args);
        this.#___private_edgeless_1 = (_initProto(this), _init_edgeless(this));
    }
}
export function renderAlignButton(edgeless, elements) {
    if (elements.length < 2) return nothing;
    if (elements.some((e)=>e.group instanceof MindmapElementModel)) return nothing;
    return html`
    <edgeless-align-button .edgeless=${edgeless}></edgeless-align-button>
  `;
}
export { _EdgelessAlignButton as EdgelessAlignButton };
