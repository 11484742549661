import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import * as styles from './styles.css';
export const Menu = ({ children, items, portalOptions, rootOptions, noPortal, contentOptions: { className = '', style: contentStyle = {}, ...otherContentOptions } = {} })=>{
    return _jsxs(DropdownMenu.Root, {
        ...rootOptions,
        children: [
            _jsx(DropdownMenu.Trigger, {
                asChild: true,
                children: children
            }),
            noPortal ? _jsx(DropdownMenu.Content, {
                className: clsx(styles.menuContent, className),
                sideOffset: 5,
                align: "start",
                style: {
                    zIndex: 'var(--affine-z-index-popover)',
                    ...contentStyle
                },
                ...otherContentOptions,
                children: items
            }) : _jsx(DropdownMenu.Portal, {
                ...portalOptions,
                children: _jsx(DropdownMenu.Content, {
                    className: clsx(styles.menuContent, className),
                    sideOffset: 5,
                    align: "start",
                    style: {
                        zIndex: 'var(--affine-z-index-popover)',
                        ...contentStyle
                    },
                    ...otherContentOptions,
                    children: items
                })
            })
        ]
    });
};
