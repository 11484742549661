import '../../_common/components/ask-ai-button';
import { html } from 'lit';
const AICodeItemGroups = buildAICodeItemGroups();
const buttonOptions = {
    size: 'small',
    panelWidth: 240
};
import { buildAICodeItemGroups } from '../../_common/config';
import { AIStarIcon } from '../../_common/icons';
export function setupCodeToolbarEntry(codeToolbar) {
    const onAskAIClick = ()=>{
        const { host } = codeToolbar;
        const { selection } = host;
        const codeBlock = codeToolbar.block;
        selection.setGroup('note', [
            selection.create('block', {
                blockId: codeBlock.blockId
            })
        ]);
    };
    codeToolbar.setupDefaultConfig();
    codeToolbar.addItems([
        {
            type: 'custom',
            name: 'Ask AI',
            tooltip: 'Ask AI',
            icon: AIStarIcon,
            showWhen: (CodeBlockComponent)=>!CodeBlockComponent.doc.readonly,
            render (codeBlock, onClick) {
                return html`<ask-ai-button
            class="code-toolbar-button ask-ai"
            .host=${codeBlock.host}
            .actionGroups=${AICodeItemGroups}
            .toggleType=${'click'}
            .options=${buttonOptions}
            @click=${(e)=>{
                    e.stopPropagation();
                    onAskAIClick();
                    onClick?.();
                }}
          ></ask-ai-button>`;
            }
        }
    ], 0);
}
