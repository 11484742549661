function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _init_edgeless, _init_elements, _init_vertical, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { Bound } from '@blocksuite/global/utils';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { isPeekable, peek } from '../../../_common/components/peekable.js';
import '../../../_common/components/toolbar/icon-button.js';
import '../../../_common/components/toolbar/menu-button.js';
import { renderActions } from '../../../_common/components/toolbar/utils.js';
import { BringForwardIcon, BringToFrontIcon, CenterPeekIcon, FrameIcon, GroupIcon, LinkedDocIcon, MoreCopyIcon, MoreDeleteIcon, MoreDuplicateIcon, MoreHorizontalIcon, MoreVerticalIcon, OpenIcon, RefreshIcon, SendBackwardIcon, SendToBackIcon } from '../../../_common/icons/index.js';
import { createLinkedDocFromEdgelessElements, createLinkedDocFromNote, notifyDocCreated, promptDocTitle } from '../../../_common/utils/render-linked-doc.js';
import '../../edgeless/components/toolbar/shape/shape-menu.js';
import { edgelessElementsBound } from '../../edgeless/utils/bound-utils.js';
import { duplicate } from '../../edgeless/utils/clipboard-utils.js';
import { getCloneElements } from '../../edgeless/utils/clone-utils.js';
import { moveConnectors } from '../../edgeless/utils/connector.js';
import { deleteElements } from '../../edgeless/utils/crud.js';
import { isAttachmentBlock, isBookmarkBlock, isEmbedLinkedDocBlock, isEmbedSyncedDocBlock, isEmbeddedLinkBlock, isFrameBlock, isImageBlock, isNoteBlock } from '../../edgeless/utils/query.js';
const OPEN_ACTION = {
    icon: OpenIcon,
    name: 'Open this doc',
    type: 'open'
};
const CENTER_PEEK_ACTION = {
    icon: CenterPeekIcon,
    name: 'Open in center peek',
    type: 'center-peek'
};
const REORDER_ACTIONS = [
    {
        icon: BringToFrontIcon,
        name: 'Bring to Front',
        type: 'front'
    },
    {
        icon: BringForwardIcon,
        name: 'Bring Forward',
        type: 'forward'
    },
    {
        icon: SendBackwardIcon,
        name: 'Send Backward',
        type: 'backward'
    },
    {
        icon: SendToBackIcon,
        name: 'Send to Back',
        type: 'back'
    }
];
const COPY_ACTIONS = [
    {
        icon: MoreCopyIcon,
        name: 'Copy',
        type: 'copy'
    },
    {
        icon: MoreDuplicateIcon,
        name: 'Duplicate',
        type: 'duplicate'
    }
];
const DELETE_ACTION = {
    icon: MoreDeleteIcon,
    name: 'Delete',
    type: 'delete'
};
const FRAME_ACTION = {
    icon: FrameIcon,
    name: 'Frame section',
    type: 'create-frame'
};
const GROUP_ACTION = {
    icon: GroupIcon,
    name: 'Group section',
    type: 'create-group'
};
const RELOAD_ACTION = {
    icon: RefreshIcon,
    name: 'Reload',
    type: 'reload'
};
const TURN_INTO_LINKED_DOC_ACTION = {
    icon: LinkedDocIcon,
    name: 'Turn into linked doc',
    type: 'turn-into-linked-doc'
};
const CREATE_LINKED_DOC_ACTION = {
    icon: LinkedDocIcon,
    name: 'Create linked doc',
    type: 'create-linked-doc'
};
let _EdgelessMoreButton;
_dec = customElement('edgeless-more-button'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
});
class EdgelessMoreButton extends (_WithDisposable = WithDisposable(LitElement)) {
    static{
        ({ e: [_init_edgeless, _init_elements, _init_vertical, _initProto], c: [_EdgelessMoreButton, _initClass] } = _apply_decs_2203_r(this, [
            [
                _dec1,
                1,
                "edgeless"
            ],
            [
                _dec2,
                1,
                "elements"
            ],
            [
                _dec3,
                1,
                "vertical"
            ]
        ], [
            _dec
        ], _WithDisposable));
    }
    get _Actions() {
        return [
            [
                FRAME_ACTION,
                GROUP_ACTION
            ],
            REORDER_ACTIONS,
            this.getOpenActions(),
            [
                ...COPY_ACTIONS,
                this.getRefreshAction()
            ],
            [
                this.getLinkedDocAction()
            ],
            [
                DELETE_ACTION
            ]
        ];
    }
    get _FrameActions() {
        return [
            [
                FRAME_ACTION
            ],
            COPY_ACTIONS,
            [
                this.getLinkedDocAction()
            ],
            [
                DELETE_ACTION
            ]
        ];
    }
    get _block() {
        const blockSelection = this.edgeless.service.selection.surfaceSelections;
        if (blockSelection.length !== 1 || blockSelection[0].elements.length !== 1) {
            return;
        }
        const block = this.view.getBlock(blockSelection[0].blockId);
        if (!block) return;
        return block;
    }
    _refreshable(ele) {
        return isImageBlock(ele) || isBookmarkBlock(ele) || isAttachmentBlock(ele) || isEmbeddedLinkBlock(ele);
    }
    getLinkedDocAction() {
        const isSingleSelect = this.selection.selectedElements.length === 1;
        const { firstElement } = this.selection;
        if (isSingleSelect && (isEmbedLinkedDocBlock(firstElement) || isEmbedSyncedDocBlock(firstElement))) {
            return nothing;
        }
        if (isSingleSelect && isNoteBlock(firstElement)) {
            return TURN_INTO_LINKED_DOC_ACTION;
        }
        return CREATE_LINKED_DOC_ACTION;
    }
    getOpenActions() {
        const isSingleSelect = this.selection.selectedElements.length === 1;
        const { firstElement } = this.selection;
        const result = [];
        if (isSingleSelect && (isEmbedLinkedDocBlock(firstElement) || isEmbedSyncedDocBlock(firstElement))) {
            const disabled = firstElement.pageId === this.doc.id;
            result.push({
                ...OPEN_ACTION,
                disabled
            });
        }
        if (isSingleSelect && this._block && isPeekable(this._block)) {
            result.push(CENTER_PEEK_ACTION);
        }
        return result;
    }
    getRefreshAction() {
        const refreshable = this.selection.selectedElements.every((ele)=>this._refreshable(ele));
        return refreshable ? RELOAD_ACTION : nothing;
    }
    render() {
        const selection = this.edgeless.service.selection;
        const actions = renderActions(selection.selectedElements.some(isFrameBlock) ? this._FrameActions : this._Actions, this._runAction);
        return html`
      <editor-menu-button
        .contentPadding=${'8px'}
        .button=${html`
          <editor-icon-button aria-label="More" .tooltip=${'More'}>
            ${this.vertical ? MoreVerticalIcon : MoreHorizontalIcon}
          </editor-icon-button>
        `}
      >
        <div
          class="more-actions-container"
          data-size="large"
          data-orientation="vertical"
        >
          ${actions}
        </div>
      </editor-menu-button>
    `;
    }
    get doc() {
        return this.edgeless.doc;
    }
    get selection() {
        return this.edgeless.service.selection;
    }
    get slots() {
        return this.edgeless.slots;
    }
    get surface() {
        return this.edgeless.surface;
    }
    get view() {
        return this.edgeless.host.view;
    }
    #___private_edgeless_1;
    get edgeless() {
        return this.#___private_edgeless_1;
    }
    set edgeless(_v) {
        this.#___private_edgeless_1 = _v;
    }
    #___private_elements_2;
    get elements() {
        return this.#___private_elements_2;
    }
    set elements(_v) {
        this.#___private_elements_2 = _v;
    }
    #___private_vertical_3;
    get vertical() {
        return this.#___private_vertical_3;
    }
    set vertical(_v) {
        this.#___private_vertical_3 = _v;
    }
    static{
        _initClass();
    }
    constructor(...args){
        super(...args);
        this._createLinkedDoc = (title)=>{
            const selection = this.edgeless.service.selection;
            const elements = getCloneElements(selection.selectedElements, this.edgeless.surface.edgeless.service.frame);
            const linkedDoc = createLinkedDocFromEdgelessElements(this.edgeless.host, elements, title);
            const width = 364;
            const height = 390;
            const bound = edgelessElementsBound(elements);
            const cardId = this.edgeless.service.addBlock('affine:embed-linked-doc', {
                xywh: `[${bound.center[0] - width / 2}, ${bound.center[1] - height / 2}, ${width}, ${height}]`,
                style: 'vertical',
                pageId: linkedDoc.id
            }, this.surface.model.id);
            this.edgeless.service.telemetryService?.track('CanvasElementAdded', {
                control: 'context-menu',
                page: 'whiteboard editor',
                module: 'toolbar',
                segment: 'toolbar',
                type: 'embed-linked-doc'
            });
            this.edgeless.service.telemetryService?.track('DocCreated', {
                control: 'create linked doc',
                page: 'whiteboard editor',
                module: 'format toolbar',
                type: 'embed-linked-doc'
            });
            this.edgeless.service.telemetryService?.track('LinkedDocCreated', {
                control: 'create linked doc',
                page: 'whiteboard editor',
                module: 'format toolbar',
                type: 'embed-linked-doc',
                other: 'new doc'
            });
            this.doc.transact(()=>{
                deleteElements(this.surface, elements);
            });
            this.edgeless.service.selection.set({
                elements: [
                    cardId
                ],
                editing: false
            });
        };
        this._delete = ()=>{
            this.doc.captureSync();
            deleteElements(this.surface, this.selection.selectedElements);
            this.selection.set({
                elements: [],
                editing: false
            });
        };
        this._reload = (selections)=>{
            selections.forEach((sel)=>{
                const block = this.view.getBlock(sel.blockId);
                if (!!block && this._refreshable(block.model)) {
                    block.refreshData();
                }
            });
        };
        this._runAction = async ({ type })=>{
            const selection = this.edgeless.service.selection;
            switch(type){
                case 'copy':
                    {
                        this.edgeless.clipboardController.copy();
                        break;
                    }
                case 'duplicate':
                    {
                        await duplicate(this.edgeless, selection.selectedElements);
                        break;
                    }
                case 'delete':
                    {
                        this._delete();
                        break;
                    }
                case 'turn-into-linked-doc':
                    {
                        const title = await promptDocTitle(this.edgeless.host);
                        if (title === null) return;
                        this._turnIntoLinkedDoc(title);
                        notifyDocCreated(this.edgeless.host, this.edgeless.doc);
                        break;
                    }
                case 'create-linked-doc':
                    {
                        const title = await promptDocTitle(this.edgeless.host);
                        if (title === null) return;
                        this._createLinkedDoc(title);
                        notifyDocCreated(this.edgeless.host, this.edgeless.doc);
                        break;
                    }
                case 'create-frame':
                    {
                        const { service } = this.edgeless;
                        const frame = service.frame.createFrameOnSelected();
                        if (!frame) break;
                        this.edgeless.service.telemetryService?.track('CanvasElementAdded', {
                            control: 'context-menu',
                            page: 'whiteboard editor',
                            module: 'toolbar',
                            segment: 'toolbar',
                            type: 'frame'
                        });
                        this.edgeless.surface.fitToViewport(Bound.deserialize(frame.xywh));
                        break;
                    }
                case 'create-group':
                    {
                        this.edgeless.service.createGroupFromSelected();
                        break;
                    }
                case 'front':
                case 'forward':
                case 'backward':
                case 'back':
                    {
                        this.selection.selectedElements.forEach((el)=>{
                            this.edgeless.service.reorderElement(el, type);
                        });
                        break;
                    }
                case 'reload':
                    this._reload(this.selection.surfaceSelections);
                    break;
                case 'open':
                    if (this._block && 'open' in this._block && typeof this._block.open === 'function') {
                        this._block.open();
                    }
                    break;
                case 'center-peek':
                    if (this._block && isPeekable(this._block)) {
                        peek(this._block);
                    }
                    break;
            }
        };
        this._turnIntoLinkedDoc = (title)=>{
            const isSingleSelect = this.selection.selectedElements.length === 1;
            const { firstElement: element } = this.selection;
            if (isSingleSelect && isNoteBlock(element)) {
                const linkedDoc = createLinkedDocFromNote(this.edgeless.host.doc, element, title);
                const cardId = this.edgeless.service.addBlock('affine:embed-synced-doc', {
                    xywh: element.xywh,
                    style: 'syncedDoc',
                    pageId: linkedDoc.id,
                    index: element.index
                }, this.surface.model.id);
                this.edgeless.service.telemetryService?.track('CanvasElementAdded', {
                    control: 'context-menu',
                    page: 'whiteboard editor',
                    module: 'toolbar',
                    segment: 'toolbar',
                    type: 'embed-synced-doc'
                });
                this.edgeless.service.telemetryService?.track('DocCreated', {
                    control: 'turn into linked doc',
                    page: 'whiteboard editor',
                    module: 'format toolbar',
                    type: 'embed-linked-doc'
                });
                this.edgeless.service.telemetryService?.track('LinkedDocCreated', {
                    control: 'turn into linked doc',
                    page: 'whiteboard editor',
                    module: 'format toolbar',
                    type: 'embed-linked-doc',
                    other: 'new doc'
                });
                moveConnectors(element.id, cardId, this.edgeless.service);
                this.doc.transact(()=>{
                    this.surface.doc.deleteBlock(element);
                });
                this.edgeless.service.selection.set({
                    elements: [
                        cardId
                    ],
                    editing: false
                });
            } else {
                this._createLinkedDoc(title);
            }
        };
        this.#___private_edgeless_1 = (_initProto(this), _init_edgeless(this));
        this.#___private_elements_2 = _init_elements(this, []);
        this.#___private_vertical_3 = _init_vertical(this, false);
    }
}
export { _EdgelessMoreButton as EdgelessMoreButton };
