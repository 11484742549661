const PANNING_DISTANCE = 30;
export function calPanDelta(viewport, e, edgeDistance = 20) {
    const { left, top, cumulativeParentScale } = viewport;
    let { width, height } = viewport;
    width /= cumulativeParentScale;
    height /= cumulativeParentScale;
    let { x, y } = e;
    const { containerOffset } = e;
    x += containerOffset.x;
    y += containerOffset.y;
    const nearLeft = x < left + edgeDistance;
    const nearRight = x > left + width - edgeDistance;
    const nearTop = y < top + edgeDistance;
    const nearBottom = y > top + height - edgeDistance;
    if (!(nearLeft || nearRight || nearTop || nearBottom)) return null;
    let deltaX = 0;
    let deltaY = 0;
    if (nearLeft) {
        deltaX = Math.max(-PANNING_DISTANCE, x - (left + edgeDistance));
    } else if (nearRight) {
        deltaX = Math.min(PANNING_DISTANCE, x - (left + width - edgeDistance));
    }
    if (nearTop) {
        deltaY = Math.max(-PANNING_DISTANCE, y - (top + edgeDistance));
    } else if (nearBottom) {
        deltaY = Math.min(PANNING_DISTANCE, y - (top + height - edgeDistance));
    }
    return [
        deltaX,
        deltaY
    ];
}
