import { Service } from '../../../framework';
export class GlobalStateService extends Service {
    constructor(globalState){
        super();
        this.globalState = globalState;
    }
}
export class GlobalCacheService extends Service {
    constructor(globalCache){
        super();
        this.globalCache = globalCache;
    }
}
