import { Bound } from '@blocksuite/global/utils';
import { CHAT_BLOCK_HEIGHT, CHAT_BLOCK_WIDTH } from '@blocksuite/presets';
export function calcChildBound(parentModel, service) {
    const parentXYWH = Bound.deserialize(parentModel.xywh);
    const { x: parentX, y: parentY, w: parentWidth } = parentXYWH;
    const connectors = service.getConnectors(parentModel.id);
    const gapX = CHAT_BLOCK_WIDTH;
    const gapY = 60;
    const defaultX = parentX + parentWidth + gapX;
    const defaultY = parentY;
    if (!connectors.length) {
        return new Bound(defaultX, defaultY, CHAT_BLOCK_WIDTH, CHAT_BLOCK_HEIGHT);
    } else {
        const childConnectors = connectors.filter((connector)=>connector.source.id === parentModel.id);
        const targetBlocks = childConnectors.map((connector)=>connector.target.id).filter((id)=>id !== undefined).map((id)=>service.getElementById(id)).filter((block)=>!!block);
        if (targetBlocks.length) {
            targetBlocks.sort((a, b)=>Bound.deserialize(a.xywh).y - Bound.deserialize(b.xywh).y);
            let x, y;
            const middleIndex = Math.floor((targetBlocks.length - 1) / 2);
            const middleBlock = targetBlocks[middleIndex];
            const { y: middleY, h: middleHeight } = Bound.deserialize(middleBlock.xywh);
            const lastBlock = targetBlocks[targetBlocks.length - 1];
            const { x: lastX, y: lastY, h: lastHeight } = Bound.deserialize(lastBlock.xywh);
            if (targetBlocks.length % 2 === 0) {
                x = lastX;
                const gap = lastY - (middleY + middleHeight);
                y = middleY - gap - CHAT_BLOCK_HEIGHT;
            } else {
                x = lastX;
                y = lastY + lastHeight + gapY;
            }
            return new Bound(x, y, CHAT_BLOCK_WIDTH, CHAT_BLOCK_HEIGHT);
        } else {
            return new Bound(defaultX, defaultY, CHAT_BLOCK_WIDTH, CHAT_BLOCK_HEIGHT);
        }
    }
}
