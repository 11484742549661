import { jsx as _jsx } from "react/jsx-runtime";
import { toReactNode } from '@affine/component';
import { AIChatBlockPeekViewTemplate } from '@affine/core/blocksuite/presets/ai';
import { BlockComponent } from '@blocksuite/block-std';
import { useLiveData, useService } from '@toeverything/infra';
import { useEffect, useMemo } from 'react';
import { PeekViewService } from '../services/peek-view';
import { DocPeekPreview } from './doc-preview';
import { ImagePreviewPeekView } from './image-preview';
import { PeekViewModalContainer } from './modal-container';
import { DefaultPeekViewControls, DocPeekViewControls } from './peek-view-controls';
function renderPeekView({ info }) {
    if (info.type === 'template') {
        return toReactNode(info.template);
    }
    if (info.type === 'doc') {
        return _jsx(DocPeekPreview, {
            mode: info.mode,
            xywh: info.xywh,
            docId: info.docId,
            blockId: info.blockId
        });
    }
    if (info.type === 'image') {
        return _jsx(ImagePreviewPeekView, {
            docId: info.docId,
            blockId: info.blockId
        });
    }
    if (info.type === 'ai-chat-block') {
        const template = AIChatBlockPeekViewTemplate(info.model, info.host);
        return toReactNode(template);
    }
    return null;
}
const renderControls = ({ info })=>{
    if (info.type === 'doc') {
        return _jsx(DocPeekViewControls, {
            mode: info.mode,
            docId: info.docId,
            blockId: info.docId
        });
    }
    if (info.type === 'image') {
        return null;
    }
    return _jsx(DefaultPeekViewControls, {});
};
const getRendererProps = (activePeekView)=>{
    if (!activePeekView) {
        return;
    }
    const preview = renderPeekView(activePeekView);
    const controls = renderControls(activePeekView);
    return {
        children: preview,
        controls,
        target: activePeekView?.target instanceof HTMLElement ? activePeekView.target : undefined,
        padding: activePeekView.info.type !== 'image',
        dialogFrame: activePeekView.info.type !== 'image'
    };
};
export const PeekViewManagerModal = ()=>{
    const peekViewEntity = useService(PeekViewService).peekView;
    const activePeekView = useLiveData(peekViewEntity.active$);
    const show = useLiveData(peekViewEntity.show$);
    const renderProps = useMemo(()=>{
        if (!activePeekView) {
            return;
        }
        return getRendererProps(activePeekView);
    }, [
        activePeekView
    ]);
    useEffect(()=>{
        const subscription = peekViewEntity.show$.subscribe(()=>{
            if (activePeekView?.target instanceof BlockComponent) {
                activePeekView.target.requestUpdate();
            }
        });
        return ()=>{
            subscription.unsubscribe();
        };
    }, [
        activePeekView,
        peekViewEntity
    ]);
    return _jsx(PeekViewModalContainer, {
        ...renderProps,
        open: !!show?.value && !!renderProps,
        animation: show?.animation || 'none',
        onOpenChange: (open)=>{
            if (!open) {
                peekViewEntity.close();
            }
        },
        children: renderProps?.children
    });
};
