function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _init_containerWidth, _init_edgelessTool, _init_presentFrameMenuShow, _init_presentSettingMenuShow, _init_scrollSeniorToolIndex, _init_toolbarContainer, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { debounce } from '@blocksuite/global/utils';
import { Slot } from '@blocksuite/store';
import { offset } from '@floating-ui/dom';
import { ContextProvider } from '@lit/context';
import { baseTheme, cssVar } from '@toeverything/theme';
import { LitElement, css, html, nothing, unsafeCSS } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { cache } from 'lit/directives/cache.js';
import { popMenu } from '../../../../_common/components/index.js';
import '../../../../_common/components/smooth-corner.js';
import { ArrowLeftSmallIcon, ArrowRightSmallIcon, MoreHorizontalIcon } from '../../../../_common/icons/index.js';
import { ColorScheme, ThemeObserver } from '../../../../_common/theme/theme-observer.js';
import { stopPropagation } from '../../../../_common/utils/event.js';
import '../buttons/tool-icon-button.js';
import '../buttons/toolbar-button.js';
import { edgelessToolbarContext, edgelessToolbarSlotsContext, edgelessToolbarThemeContext } from './context.js';
import './present/frame-order-button.js';
import './presentation-toolbar.js';
import { getQuickTools, getSeniorTools } from './tools.js';
const TOOLBAR_PADDING_X = 12;
const TOOLBAR_HEIGHT = 64;
const QUICK_TOOLS_GAP = 10;
const QUICK_TOOL_SIZE = 36;
const QUICK_TOOL_MORE_SIZE = 20;
const SENIOR_TOOLS_GAP = 0;
const SENIOR_TOOL_WIDTH = 96;
const SENIOR_TOOL_NAV_SIZE = 20;
const DIVIDER_WIDTH = 8;
const DIVIDER_SPACE = 8;
const SAFE_AREA_WIDTH = 64;
let _EdgelessToolbar;
_dec = customElement('edgeless-toolbar'), _dec1 = state(), _dec2 = state(), _dec3 = state(), _dec4 = state(), _dec5 = state(), _dec6 = query('.edgeless-toolbar-container');
new class extends _identity {
    constructor(){
        super(_EdgelessToolbar), _initClass();
    }
    static{
        class EdgelessToolbar extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init_containerWidth, _init_edgelessTool, _init_presentFrameMenuShow, _init_presentSettingMenuShow, _init_scrollSeniorToolIndex, _init_toolbarContainer, _initProto], c: [_EdgelessToolbar, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "containerWidth"
                    ],
                    [
                        _dec2,
                        1,
                        "edgelessTool"
                    ],
                    [
                        _dec3,
                        1,
                        "presentFrameMenuShow"
                    ],
                    [
                        _dec4,
                        1,
                        "presentSettingMenuShow"
                    ],
                    [
                        _dec5,
                        1,
                        "scrollSeniorToolIndex"
                    ],
                    [
                        _dec6,
                        1,
                        "toolbarContainer"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    :host {
      font-family: ${unsafeCSS(baseTheme.fontSansFamily)};
      position: absolute;
      z-index: 1;
      left: calc(50%);
      transform: translateX(-50%);
      bottom: 0;
      -webkit-user-select: none;
      user-select: none;
      width: 100%;
      pointer-events: none;
    }
    .edgeless-toolbar-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .edgeless-toolbar-toggle-control {
      pointer-events: auto;
      padding-bottom: 16px;
      width: fit-content;
      max-width: calc(100% - ${unsafeCSS(SAFE_AREA_WIDTH)}px * 2);
      min-width: 264px;
    }
    .edgeless-toolbar-toggle-control[data-enable='true'] {
      transition: 0.23s ease;
      padding-top: 100px;
      transform: translateY(100px);
    }
    .edgeless-toolbar-toggle-control[data-enable='true']:hover {
      padding-top: 0;
      transform: translateY(0);
    }

    .edgeless-toolbar-smooth-corner {
      display: block;
      width: fit-content;
      max-width: 100%;
    }
    .edgeless-toolbar-container {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 ${unsafeCSS(TOOLBAR_PADDING_X)}px;
      height: ${unsafeCSS(TOOLBAR_HEIGHT)}px;
    }
    :host([disabled]) .edgeless-toolbar-container {
      pointer-events: none;
    }
    .edgeless-toolbar-container[level='second'] {
      position: absolute;
      bottom: 8px;
      transform: translateY(-100%);
    }
    .edgeless-toolbar-container[hidden] {
      display: none;
    }
    .quick-tools {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${unsafeCSS(QUICK_TOOLS_GAP)}px;
    }
    .full-divider {
      width: ${unsafeCSS(DIVIDER_WIDTH)}px;
      height: 100%;
      margin: 0 ${unsafeCSS(DIVIDER_SPACE)}px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .full-divider::after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: var(--affine-border-color);
    }
    .brush-and-eraser {
      display: flex;
      height: 100%;
      gap: 4px;
      justify-content: center;
    }
    .senior-tools {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ${unsafeCSS(SENIOR_TOOLS_GAP)}px;
      height: 100%;
      min-width: ${unsafeCSS(SENIOR_TOOL_WIDTH)}px;
    }
    .quick-tool-item {
      width: ${unsafeCSS(QUICK_TOOL_SIZE)}px;
      height: ${unsafeCSS(QUICK_TOOL_SIZE)}px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
    .quick-tool-more {
      width: 0;
      height: ${unsafeCSS(QUICK_TOOL_SIZE)}px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.23s ease;
      overflow: hidden;
    }
    [data-dense-quick='true'] .quick-tool-more {
      width: ${unsafeCSS(QUICK_TOOL_MORE_SIZE)}px;
      margin-left: ${unsafeCSS(DIVIDER_SPACE)}px;
    }
    .quick-tool-more-button {
      padding: 0;
    }

    .senior-tool-item {
      width: ${unsafeCSS(SENIOR_TOOL_WIDTH)}px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
    .senior-nav-button-wrapper {
      flex-shrink: 0;
      width: 0px;
      height: ${unsafeCSS(SENIOR_TOOL_NAV_SIZE)}px;
      transition: width 0.23s ease;
      overflow: hidden;
    }
    .senior-nav-button {
      padding: 0;
    }
    .senior-nav-button svg {
      width: 20px;
      height: 20px;
    }
    [data-dense-senior='true'] .senior-nav-button-wrapper {
      width: ${unsafeCSS(SENIOR_TOOL_NAV_SIZE)}px;
    }
    [data-dense-senior='true'] .senior-nav-button-wrapper.prev {
      margin-right: ${unsafeCSS(DIVIDER_SPACE)}px;
    }
    [data-dense-senior='true'] .senior-nav-button-wrapper.next {
      margin-left: ${unsafeCSS(DIVIDER_SPACE)}px;
    }
    .transform-button svg {
      transition: 0.3s ease-in-out;
    }
    .transform-button:hover svg {
      transform: scale(1.15);
    }
  `;
            }
            constructor(edgeless){
                super();
                this._moreQuickToolsMenu = null;
                this._moreQuickToolsMenuRef = null;
                this._onContainerResize = debounce(({ w })=>{
                    this.slots.resize.emit({
                        w,
                        h: TOOLBAR_HEIGHT
                    });
                    this.containerWidth = w;
                    if (this._denseSeniorTools) {
                        this.scrollSeniorToolIndex = Math.min(this._seniorTools.length - this.scrollSeniorToolSize, this.scrollSeniorToolIndex);
                    } else {
                        this.scrollSeniorToolIndex = 0;
                    }
                    if (this._denseQuickTools && this._moreQuickToolsMenu && this._moreQuickToolsMenuRef) {
                        this._moreQuickToolsMenu.close();
                        this._openMoreQuickToolsMenu({
                            currentTarget: this._moreQuickToolsMenuRef
                        });
                    }
                    if (!this._denseQuickTools && this._moreQuickToolsMenu) {
                        this._moreQuickToolsMenu.close();
                        this._moreQuickToolsMenu = null;
                    }
                }, 300);
                this._resizeObserver = null;
                this._slotsProvider = new ContextProvider(this, {
                    context: edgelessToolbarSlotsContext,
                    initialValue: {
                        resize: new Slot()
                    }
                });
                this._themeProvider = new ContextProvider(this, {
                    context: edgelessToolbarThemeContext,
                    initialValue: ColorScheme.Light
                });
                this._toolbarProvider = new ContextProvider(this, {
                    context: edgelessToolbarContext,
                    initialValue: this
                });
                this.activePopper = null;
                this.setEdgelessTool = (edgelessTool)=>{
                    this.edgeless.tools.setEdgelessTool(edgelessTool);
                };
                this.#___private_containerWidth_1 = (_initProto(this), _init_containerWidth(this, 1920));
                this.#___private_edgelessTool_2 = _init_edgelessTool(this, {
                    type: localStorage.defaultTool ?? 'default'
                });
                this.#___private_presentFrameMenuShow_3 = _init_presentFrameMenuShow(this, false);
                this.#___private_presentSettingMenuShow_4 = _init_presentSettingMenuShow(this, false);
                this.#___private_scrollSeniorToolIndex_5 = _init_scrollSeniorToolIndex(this, 0);
                this.#___private_toolbarContainer_6 = _init_toolbarContainer(this);
                this.edgeless = edgeless;
            }
            get _availableWidth() {
                return this.containerWidth - 2 * SAFE_AREA_WIDTH;
            }
            get _cachedPresentHideToolbar() {
                return !!this.edgeless.service.editPropsStore.getStorage('presentHideToolbar');
            }
            get _denseQuickTools() {
                return this._availableWidth - this._seniorToolNavWidth - 1 * SENIOR_TOOL_WIDTH - 2 * TOOLBAR_PADDING_X < this._quickToolsWidthTotal;
            }
            get _denseSeniorTools() {
                return this._availableWidth - this._quickToolsWidthTotal - this._spaceWidthTotal < this._seniorToolsWidthTotal;
            }
            get _enableAutoHide() {
                return this.isPresentMode && this._cachedPresentHideToolbar && !this.presentSettingMenuShow && !this.presentFrameMenuShow;
            }
            get _hiddenQuickTools() {
                return this._quickTools.slice(this._visibleQuickToolSize).filter((tool)=>!!tool.menu);
            }
            _onSeniorNavNext() {
                if (this._seniorScrollNextDisabled) return;
                this.scrollSeniorToolIndex = Math.min(this._seniorTools.length - this.scrollSeniorToolSize, this.scrollSeniorToolIndex + this.scrollSeniorToolSize);
            }
            _onSeniorNavPrev() {
                if (this._seniorScrollPrevDisabled) return;
                this.scrollSeniorToolIndex = Math.max(0, this.scrollSeniorToolIndex - this.scrollSeniorToolSize);
            }
            _openMoreQuickToolsMenu(e) {
                if (!this._hiddenQuickTools.length) return;
                this._moreQuickToolsMenuRef = e.currentTarget;
                this._moreQuickToolsMenu = popMenu(e.currentTarget, {
                    placement: 'top',
                    middleware: [
                        offset({
                            mainAxis: (TOOLBAR_HEIGHT - QUICK_TOOL_MORE_SIZE) / 2 + 8
                        })
                    ],
                    options: {
                        onClose: ()=>{
                            this._moreQuickToolsMenu = null;
                            this._moreQuickToolsMenuRef = null;
                        },
                        items: this._hiddenQuickTools.map((tool)=>tool.menu)
                    }
                });
            }
            get _quickTools() {
                return getQuickTools({
                    edgeless: this.edgeless
                });
            }
            get _quickToolsWidthTotal() {
                return this._quickTools.length * (QUICK_TOOL_SIZE + QUICK_TOOLS_GAP) - QUICK_TOOLS_GAP;
            }
            _renderContent() {
                return html`
      <div class="quick-tools">
        ${this._quickTools.slice(0, this._visibleQuickToolSize).map((tool)=>html`<div class="quick-tool-item">${tool.content}</div>`)}
      </div>
      <div class="quick-tool-more">
        <icon-button
          ?disabled=${!this._denseQuickTools}
          .size=${20}
          class="quick-tool-more-button"
          @click=${this._openMoreQuickToolsMenu}
          ?active=${this._quickTools.slice(this._visibleQuickToolSize).some((tool)=>tool.type === this.edgelessTool?.type)}
        >
          ${MoreHorizontalIcon}
          <affine-tooltip tip-position="top" .offset=${25}>
            More Tools
          </affine-tooltip>
        </icon-button>
      </div>
      <div class="full-divider"></div>
      <div class="senior-nav-button-wrapper prev">
        <icon-button
          .size=${20}
          class="senior-nav-button"
          ?disabled=${this._seniorScrollPrevDisabled}
          @click=${this._onSeniorNavPrev}
        >
          ${ArrowLeftSmallIcon}
          ${cache(this._seniorPrevTooltip ? html` <affine-tooltip tip-position="top" .offset=${4}>
                  ${this._seniorPrevTooltip}
                </affine-tooltip>` : nothing)}
        </icon-button>
      </div>
      <div class="senior-tools">
        ${this._seniorTools.slice(this.scrollSeniorToolIndex, this.scrollSeniorToolIndex + this.scrollSeniorToolSize).map((tool)=>html`<div class="senior-tool-item">${tool.content}</div>`)}
      </div>
      <div class="senior-nav-button-wrapper next">
        <icon-button
          .size=${20}
          class="senior-nav-button"
          ?disabled=${this._seniorScrollNextDisabled}
          @click=${this._onSeniorNavNext}
        >
          ${ArrowRightSmallIcon}
          ${cache(this._seniorNextTooltip ? html` <affine-tooltip tip-position="top" .offset=${4}>
                  ${this._seniorNextTooltip}
                </affine-tooltip>` : nothing)}
        </icon-button>
      </div>
    `;
            }
            get _seniorNextTooltip() {
                if (this._seniorScrollNextDisabled) return '';
                const nextTool = this._seniorTools[this.scrollSeniorToolIndex + this.scrollSeniorToolSize];
                return nextTool?.name ?? '';
            }
            get _seniorPrevTooltip() {
                if (this._seniorScrollPrevDisabled) return '';
                const prevTool = this._seniorTools[this.scrollSeniorToolIndex - 1];
                return prevTool?.name ?? '';
            }
            get _seniorScrollNextDisabled() {
                return this.scrollSeniorToolIndex + this.scrollSeniorToolSize >= this._seniorTools.length;
            }
            get _seniorScrollPrevDisabled() {
                return this.scrollSeniorToolIndex === 0;
            }
            get _seniorToolNavWidth() {
                return this._denseSeniorTools ? (SENIOR_TOOL_NAV_SIZE + DIVIDER_SPACE) * 2 : 0;
            }
            get _seniorTools() {
                return getSeniorTools({
                    edgeless: this.edgeless,
                    toolbarContainer: this.toolbarContainer
                });
            }
            get _seniorToolsWidthTotal() {
                return this._seniorTools.length * (SENIOR_TOOL_WIDTH + SENIOR_TOOLS_GAP) - SENIOR_TOOLS_GAP;
            }
            get _spaceWidthTotal() {
                return DIVIDER_WIDTH + DIVIDER_SPACE * 2 + TOOLBAR_PADDING_X * 2;
            }
            get _visibleQuickToolSize() {
                if (!this._denseQuickTools) return this._quickTools.length;
                const availableWidth = this._availableWidth - this._seniorToolNavWidth - this._spaceWidthTotal - SENIOR_TOOL_WIDTH;
                return Math.max(1, Math.floor((availableWidth - QUICK_TOOL_MORE_SIZE - DIVIDER_SPACE) / (QUICK_TOOL_SIZE + QUICK_TOOLS_GAP)));
            }
            connectedCallback() {
                super.connectedCallback();
                this._toolbarProvider.setValue(this);
                this._resizeObserver = new ResizeObserver((entries)=>{
                    for (const entry of entries){
                        const { width } = entry.contentRect;
                        this._onContainerResize({
                            w: width
                        });
                    }
                });
                this._resizeObserver.observe(this);
                this._disposables.add(ThemeObserver.subscribe((mode)=>this._themeProvider.setValue(mode)));
                this._disposables.add(this.edgeless.slots.edgelessToolUpdated.on((tool)=>{
                    this.edgelessTool = tool;
                }));
                this._disposables.add(this.edgeless.bindHotKey({
                    Escape: ()=>{
                        if (this.edgeless.service.selection.editing) return;
                        if (this.edgelessTool.type === 'frameNavigator') return;
                        if (this.edgelessTool.type === 'default') {
                            if (this.activePopper) {
                                this.activePopper.dispose();
                                this.activePopper = null;
                            }
                            return;
                        }
                        this.setEdgelessTool({
                            type: 'default'
                        });
                    }
                }, {
                    global: true
                }));
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                if (this._resizeObserver) {
                    this._resizeObserver.disconnect();
                }
            }
            firstUpdated() {
                const { _disposables, edgeless } = this;
                _disposables.add(edgeless.service.viewport.viewportUpdated.on(()=>this.requestUpdate()));
                _disposables.add(edgeless.slots.readonlyUpdated.on(()=>{
                    this.requestUpdate();
                }));
                _disposables.add(edgeless.slots.toolbarLocked.on((disabled)=>{
                    this.toggleAttribute('disabled', disabled);
                }));
                _disposables.add(this.edgeless.service.editPropsStore.slots.storageUpdated.on(({ key })=>{
                    if (key === 'presentHideToolbar') {
                        this.requestUpdate();
                    }
                }));
            }
            render() {
                const { type } = this.edgelessTool || {};
                if (this.edgeless.doc.readonly && type !== 'frameNavigator') {
                    return nothing;
                }
                return html`
      <div class="edgeless-toolbar-wrapper">
        <div
          class="edgeless-toolbar-toggle-control"
          data-enable=${this._enableAutoHide}
        >
          <smooth-corner
            class="edgeless-toolbar-smooth-corner"
            .borderRadius=${16}
            .smooth=${0.7}
            .borderWidth=${1}
            .bgColor=${'var(--affine-background-overlay-panel-color)'}
            .borderColor=${'var(--affine-border-color)'}
            style="filter: drop-shadow(${cssVar('toolbarShadow')})"
          >
            <div
              class="edgeless-toolbar-container"
              data-dense-quick=${this._denseQuickTools && this._hiddenQuickTools.length > 0}
              data-dense-senior=${this._denseSeniorTools}
              @dblclick=${stopPropagation}
              @mousedown=${stopPropagation}
              @pointerdown=${stopPropagation}
            >
              <presentation-toolbar
                .visible=${this.isPresentMode}
                .edgeless=${this.edgeless}
                .settingMenuShow=${this.presentSettingMenuShow}
                .frameMenuShow=${this.presentFrameMenuShow}
                .setSettingMenuShow=${(show)=>this.presentSettingMenuShow = show}
                .setFrameMenuShow=${(show)=>this.presentFrameMenuShow = show}
                .containerWidth=${this.containerWidth}
              ></presentation-toolbar>
              ${this.isPresentMode ? nothing : this._renderContent()}
            </div>
          </smooth-corner>
        </div>
      </div>
    `;
            }
            get host() {
                return this.edgeless.host;
            }
            get isPresentMode() {
                return this.edgelessTool.type === 'frameNavigator';
            }
            get scrollSeniorToolSize() {
                if (this._denseQuickTools) return 1;
                const seniorAvailableWidth = this._availableWidth - this._quickToolsWidthTotal - this._spaceWidthTotal;
                if (seniorAvailableWidth >= this._seniorToolsWidthTotal) return this._seniorTools.length;
                return Math.floor((seniorAvailableWidth - (SENIOR_TOOL_NAV_SIZE + DIVIDER_SPACE) * 2) / SENIOR_TOOL_WIDTH) || 1;
            }
            get slots() {
                return this._slotsProvider.value;
            }
            #___private_containerWidth_1;
            get containerWidth() {
                return this.#___private_containerWidth_1;
            }
            set containerWidth(_v) {
                this.#___private_containerWidth_1 = _v;
            }
            #___private_edgelessTool_2;
            get edgelessTool() {
                return this.#___private_edgelessTool_2;
            }
            set edgelessTool(_v) {
                this.#___private_edgelessTool_2 = _v;
            }
            #___private_presentFrameMenuShow_3;
            get presentFrameMenuShow() {
                return this.#___private_presentFrameMenuShow_3;
            }
            set presentFrameMenuShow(_v) {
                this.#___private_presentFrameMenuShow_3 = _v;
            }
            #___private_presentSettingMenuShow_4;
            get presentSettingMenuShow() {
                return this.#___private_presentSettingMenuShow_4;
            }
            set presentSettingMenuShow(_v) {
                this.#___private_presentSettingMenuShow_4 = _v;
            }
            #___private_scrollSeniorToolIndex_5;
            get scrollSeniorToolIndex() {
                return this.#___private_scrollSeniorToolIndex_5;
            }
            set scrollSeniorToolIndex(_v) {
                this.#___private_scrollSeniorToolIndex_5 = _v;
            }
            #___private_toolbarContainer_6;
            get toolbarContainer() {
                return this.#___private_toolbarContainer_6;
            }
            set toolbarContainer(_v) {
                this.#___private_toolbarContainer_6 = _v;
            }
        }
    }
}();
export { _EdgelessToolbar as EdgelessToolbar };
