import "../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css\",\"source\":\"Ll81bnhjZngwIHsKICBtaW4td2lkdGg6IDIyMHB4Owp9Ci5fNW54Y2Z4MSB7CiAgLXdlYmtpdC1hcHAtcmVnaW9uOiBuby1kcmFnOwp9Ci5fNW54Y2Z4MiB7CiAgcGFkZGluZy1yaWdodDogOHB4Owp9Ci5fNW54Y2Z4MyB7CiAgZm9udC1zaXplOiB2YXIoLS1hZmZpbmUtZm9udC14cyk7CiAgZmxleC13cmFwOiBub3dyYXA7Cn0KLl81bnhjZngzW2RhdGEtYWN0aXZlPSJ0cnVlIl0gewogIGNvbG9yOiB2YXIoLS1hZmZpbmUtcHJpbWFyeS1jb2xvcik7Cn0KLl81bnhjZng0IHsKICBkaXNwbGF5OiBmbGV4OwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuOwogIGdhcDogOHB4OwogIGZvbnQtd2VpZ2h0OiA1MDA7CiAgZm9udC1zaXplOiB2YXIoLS1hZmZpbmUtZm9udC14cyk7Cn0KLl81bnhjZng1IHsKICBmb250LXdlaWdodDogNDAwOwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdGV4dC1zZWNvbmRhcnktY29sb3IpOwp9Ci5fNW54Y2Z4NiB7CiAgcGFkZGluZzogNHB4IDEycHg7CiAgaGVpZ2h0OiAyOHB4OwogIGZvbnQtc2l6ZTogdmFyKC0tYWZmaW5lLWZvbnQteHMpOwogIGZvbnQtd2VpZ2h0OiA1MDA7CiAgY29sb3I6IHZhcigtLWFmZmluZS10ZXh0LXNlY29uZGFyeS1jb2xvcik7CiAgbWFyZ2luLWJvdHRvbTogNHB4Owp9Ci5fNW54Y2Z4NyB7CiAgcGFkZGluZzogNHB4IDEycHg7CiAgaGVpZ2h0OiAyOHB4OwogIGZvbnQtc2l6ZTogdmFyKC0tYWZmaW5lLWZvbnQteHMpOwp9Ci5fNW54Y2Z4OCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LXdyYXA6IHdyYXA7CiAgbWF4LXdpZHRoOiAyMDBweDsKICBnYXA6IDhweDsKICBwYWRkaW5nOiA0cHggMTJweDsKfQouXzVueGNmeDkgewogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdGV4dC1kaXNhYmxlLWNvbG9yKTsKfQouXzVueGNmeDlbZGF0YS1hY3RpdmU9InRydWUiXSB7CiAgY29sb3I6IHZhcigtLWFmZmluZS10ZXh0LXByaW1hcnktY29sb3IpOwp9\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var currentGroupType = '_5nxcfx5';
export var headerDisplayButton = '_5nxcfx1';
export var listOption = '_5nxcfx6';
export var menu = '_5nxcfx0';
export var properties = '_5nxcfx7';
export var propertiesWrapper = '_5nxcfx8';
export var propertyButton = '_5nxcfx9';
export var subMenuItem = '_5nxcfx3';
export var subMenuTrigger = '_5nxcfx2';
export var subMenuTriggerContent = '_5nxcfx4';