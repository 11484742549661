export class ReferenceNodeConfig {
    setCustomContent(content) {
        this._customContent = content;
    }
    setCustomIcon(icon) {
        this._customIcon = icon;
    }
    setCustomTitle(title) {
        this._customTitle = title;
    }
    setDoc(doc) {
        this._Doc = doc;
    }
    setInteractable(interactable) {
        this._interactable = interactable;
    }
    get customContent() {
        return this._customContent;
    }
    get customIcon() {
        return this._customIcon;
    }
    get customTitle() {
        return this._customTitle;
    }
    get doc() {
        return this._Doc;
    }
    get interactable() {
        return this._interactable;
    }
    constructor(){
        this._Doc = null;
        this._customContent = null;
        this._customIcon = null;
        this._customTitle = null;
        this._interactable = true;
    }
}
