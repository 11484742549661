import { Entity } from '../../../framework';
export class Doc extends Entity {
    constructor(scope, store){
        super();
        this.scope = scope;
        this.store = store;
        this.blockSuiteDoc = this.scope.props.blockSuiteDoc;
        this.record = this.scope.props.record;
        this.meta$ = this.record.meta$;
        this.mode$ = this.record.mode$;
        this.title$ = this.record.title$;
        this.trash$ = this.record.trash$;
    }
    get id() {
        return this.scope.props.docId;
    }
    setMode(mode) {
        return this.record.setMode(mode);
    }
    getMode() {
        return this.record.getMode();
    }
    toggleMode() {
        return this.record.toggleMode();
    }
    observeMode() {
        return this.record.observeMode();
    }
    moveToTrash() {
        return this.record.moveToTrash();
    }
    restoreFromTrash() {
        return this.record.restoreFromTrash();
    }
    waitForSyncReady() {
        return this.store.waitForDocLoadReady(this.id);
    }
    setPriorityLoad(priority) {
        return this.store.setPriorityLoad(this.id, priority);
    }
}
