import { toast } from '../_common/components/toast.js';
import { humanFileSize } from '../_common/utils/math.js';
import { defaultAttachmentProps } from './attachment-model.js';
import { allowEmbed } from './embed.js';
export function cloneAttachmentProperties(model) {
    const clonedProps = {};
    for(const cur in defaultAttachmentProps){
        const key = cur;
        clonedProps[key] = model[key];
    }
    return clonedProps;
}
const attachmentUploads = new Set();
export function setAttachmentUploading(blockId) {
    attachmentUploads.add(blockId);
}
export function setAttachmentUploaded(blockId) {
    attachmentUploads.delete(blockId);
}
function isAttachmentUploading(blockId) {
    return attachmentUploads.has(blockId);
}
async function uploadAttachmentBlob(editorHost, blockId, blob) {
    if (isAttachmentUploading(blockId)) {
        return;
    }
    const doc = editorHost.doc;
    let sourceId;
    try {
        setAttachmentUploading(blockId);
        sourceId = await doc.blobSync.set(blob);
    } catch (error) {
        console.error(error);
        if (error instanceof Error) {
            toast(editorHost, `Failed to upload attachment! ${error.message || error.toString()}`);
        }
    } finally{
        setAttachmentUploaded(blockId);
        const attachmentModel = doc.getBlockById(blockId);
        doc.withoutTransact(()=>{
            if (!attachmentModel) {
                return;
            }
            doc.updateBlock(attachmentModel, {
                sourceId
            });
        });
    }
}
async function getAttachmentBlob(model) {
    const sourceId = model.sourceId;
    if (!sourceId) {
        return null;
    }
    const doc = model.doc;
    let blob = await doc.blobSync.get(sourceId);
    if (blob) {
        blob = new Blob([
            blob
        ], {
            type: model.type
        });
    }
    return blob;
}
export async function checkAttachmentBlob(block) {
    const model = block.model;
    const { id, sourceId } = model;
    if (isAttachmentUploading(id)) {
        block.loading = true;
        block.error = false;
        block.allowEmbed = false;
        if (block.blobUrl) {
            URL.revokeObjectURL(block.blobUrl);
            block.blobUrl = undefined;
        }
        return;
    }
    try {
        if (!sourceId) {
            return;
        }
        const blob = await getAttachmentBlob(model);
        if (!blob) {
            return;
        }
        block.loading = false;
        block.error = false;
        block.allowEmbed = allowEmbed(model, block.service.maxFileSize);
        if (block.blobUrl) {
            URL.revokeObjectURL(block.blobUrl);
        }
        block.blobUrl = URL.createObjectURL(blob);
    } catch (error) {
        console.warn(error, model, sourceId);
        block.loading = false;
        block.error = true;
        block.allowEmbed = false;
        if (block.blobUrl) {
            URL.revokeObjectURL(block.blobUrl);
            block.blobUrl = undefined;
        }
    }
}
export function downloadAttachmentBlob(block) {
    const { host, model, loading, error, downloading, blobUrl } = block;
    if (downloading) {
        toast(host, 'Download in progress...');
        return;
    }
    if (loading) {
        toast(host, 'Please wait, file is loading...');
        return;
    }
    const name = model.name;
    const shortName = name.length < 20 ? name : name.slice(0, 20) + '...';
    if (error || !blobUrl) {
        toast(host, `Failed to download ${shortName}!`);
        return;
    }
    block.downloading = true;
    toast(host, `Downloading ${shortName}`);
    const tmpLink = document.createElement('a');
    const event = new MouseEvent('click');
    tmpLink.download = name;
    tmpLink.href = blobUrl;
    tmpLink.dispatchEvent(event);
    tmpLink.remove();
    block.downloading = false;
}
export async function getFileType(file) {
    if (file.type) {
        return file.type;
    }
    const buffer = await file.arrayBuffer();
    const FileType = await import('file-type');
    const fileType = await FileType.fileTypeFromBuffer(buffer);
    return fileType ? fileType.mime : '';
}
export async function addSiblingAttachmentBlocks(editorHost, files, maxFileSize, targetModel, place = 'after') {
    if (!files.length) {
        return;
    }
    const isSizeExceeded = files.some((file)=>file.size > maxFileSize);
    if (isSizeExceeded) {
        toast(editorHost, `You can only upload files less than ${humanFileSize(maxFileSize, true, 0)}`);
        return;
    }
    const doc = targetModel.doc;
    const types = await Promise.all(files.map((file)=>getFileType(file)));
    const attachmentBlockProps = files.map((file, index)=>({
            flavour: 'affine:attachment',
            name: file.name,
            size: file.size,
            type: types[index]
        }));
    const blockIds = doc.addSiblingBlocks(targetModel, attachmentBlockProps, place);
    blockIds.map((blockId, index)=>void uploadAttachmentBlob(editorHost, blockId, files[index]));
    return blockIds;
}
