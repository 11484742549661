import { Service } from '../../../framework';
export class WorkspacesService extends Service {
    get list() {
        return this.listService.list;
    }
    constructor(providers, listService, profileRepo, transform, workspaceRepo, workspaceFactory, destroy){
        super();
        this.providers = providers;
        this.listService = listService;
        this.profileRepo = profileRepo;
        this.transform = transform;
        this.workspaceRepo = workspaceRepo;
        this.workspaceFactory = workspaceFactory;
        this.destroy = destroy;
    }
    get deleteWorkspace() {
        return this.destroy.deleteWorkspace;
    }
    get getProfile() {
        return this.profileRepo.getProfile;
    }
    get transformLocalToCloud() {
        return this.transform.transformLocalToCloud;
    }
    get open() {
        return this.workspaceRepo.open;
    }
    get create() {
        return this.workspaceFactory.create;
    }
    async getWorkspaceBlob(meta, blob) {
        return await this.providers.find((x)=>x.flavour === meta.flavour)?.getWorkspaceBlob(meta.id, blob);
    }
}
