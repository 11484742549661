import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { notify, Skeleton } from '@affine/component';
import { Button } from '@affine/component/ui/button';
import { useAsyncCallback } from '@affine/core/hooks/affine-async-hooks';
import { track } from '@affine/core/mixpanel';
import { OAuthProviderType } from '@affine/graphql';
import { GithubIcon, GoogleDuotoneIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { useState } from 'react';
import { AuthService, ServerConfigService } from '../../../modules/cloud';
const OAuthProviderMap = {
    [OAuthProviderType.Google]: {
        icon: _jsx(GoogleDuotoneIcon, {})
    },
    [OAuthProviderType.GitHub]: {
        icon: _jsx(GithubIcon, {})
    },
    [OAuthProviderType.OIDC]: {
        icon: _jsx(GoogleDuotoneIcon, {})
    }
};
export function OAuth({ redirectUri }) {
    const serverConfig = useService(ServerConfigService).serverConfig;
    const oauth = useLiveData(serverConfig.features$.map((r)=>r?.oauth));
    const oauthProviders = useLiveData(serverConfig.config$.map((r)=>r?.oauthProviders));
    if (!oauth) {
        return _jsxs(_Fragment, {
            children: [
                _jsx("br", {}),
                _jsx(Skeleton, {
                    height: 50
                })
            ]
        });
    }
    return oauthProviders?.map((provider)=>_jsx(OAuthProvider, {
            provider: provider,
            redirectUri: redirectUri
        }, provider));
}
function OAuthProvider({ provider, redirectUri }) {
    const { icon } = OAuthProviderMap[provider];
    const authService = useService(AuthService);
    const [isConnecting, setIsConnecting] = useState(false);
    const onClick = useAsyncCallback(async ()=>{
        try {
            setIsConnecting(true);
            await authService.signInOauth(provider, redirectUri);
        } catch (err) {
            console.error(err);
            notify.error({
                title: 'Failed to sign in, please try again.'
            });
        } finally{
            setIsConnecting(false);
            track.$.$.auth.oauth({
                provider
            });
        }
    }, [
        authService,
        provider,
        redirectUri
    ]);
    return _jsxs(Button, {
        variant: "primary",
        block: true,
        size: "extraLarge",
        style: {
            marginTop: 30,
            width: '100%'
        },
        prefix: icon,
        onClick: onClick,
        children: [
            "Continue with ",
            provider,
            isConnecting && '...'
        ]
    }, provider);
}
