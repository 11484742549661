function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _LitElement, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _init__moreButton, _init__moreMenuOpen, _init_abortController, _init_blockComponent, _init_config, _init_moreMenuConfig, _init_onActiveStatusChange, _initProto;
import { assertExists, noop } from '@blocksuite/global/utils';
import { flip, offset } from '@floating-ui/dom';
import { LitElement, html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { createLitPortal } from '../../../../_common/components/portal.js';
import '../../../../_common/components/toolbar/icon-button.js';
import '../../../../_common/components/toolbar/menu-button.js';
import '../../../../_common/components/toolbar/toolbar.js';
import { MoreVerticalIcon } from '../../../../_common/icons/edgeless.js';
import { styles } from '../styles.js';
import { ConfigRenderer, MoreMenuRenderer } from '../utils.js';
let _AffineImageToolbar;
_dec = customElement('affine-image-toolbar'), _dec1 = query('editor-icon-button.more'), _dec2 = state(), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_AffineImageToolbar), _initClass();
    }
    static{
        class AffineImageToolbar extends (_LitElement = LitElement) {
            static{
                ({ e: [_init__moreButton, _init__moreMenuOpen, _init_abortController, _init_blockComponent, _init_config, _init_moreMenuConfig, _init_onActiveStatusChange, _initProto], c: [_AffineImageToolbar, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_moreButton"
                    ],
                    [
                        _dec2,
                        1,
                        "_moreMenuOpen"
                    ],
                    [
                        _dec3,
                        1,
                        "abortController"
                    ],
                    [
                        _dec4,
                        1,
                        "blockComponent"
                    ],
                    [
                        _dec5,
                        1,
                        "config"
                    ],
                    [
                        _dec6,
                        1,
                        "moreMenuConfig"
                    ],
                    [
                        _dec7,
                        1,
                        "onActiveStatusChange"
                    ]
                ], [
                    _dec
                ], _LitElement));
            }
            static{
                this.styles = styles;
            }
            _clearPopMenu() {
                if (this._popMenuAbortController) {
                    this._popMenuAbortController.abort();
                    this._popMenuAbortController = null;
                }
            }
            get _items() {
                return ConfigRenderer(this.blockComponent, this.abortController, this.config, this.closeCurrentMenu);
            }
            _toggleMoreMenu() {
                if (this._currentOpenMenu && !this._currentOpenMenu.signal.aborted && this._currentOpenMenu === this._popMenuAbortController) {
                    this.closeCurrentMenu();
                    this._moreMenuOpen = false;
                    return;
                }
                this.closeCurrentMenu();
                this._popMenuAbortController = new AbortController();
                this._popMenuAbortController.signal.addEventListener('abort', ()=>{
                    this._moreMenuOpen = false;
                    this.onActiveStatusChange(false);
                });
                this.onActiveStatusChange(true);
                this._currentOpenMenu = this._popMenuAbortController;
                assertExists(this._moreButton);
                createLitPortal({
                    template: html`
        <editor-menu-content
          data-show
          class="image-more-popup-menu"
          style=${styleMap({
                        '--content-padding': '8px',
                        '--packed-height': '4px'
                    })}
        >
          <div data-size="large" data-orientation="vertical">
            ${MoreMenuRenderer(this.blockComponent, this._popMenuAbortController, this.moreMenuConfig)}
          </div>
        </editor-menu-content>
      `,
                    container: this.blockComponent.host,
                    portalStyles: {
                        zIndex: 'var(--affine-z-index-popover)'
                    },
                    computePosition: {
                        referenceElement: this._moreButton,
                        placement: 'bottom-start',
                        middleware: [
                            flip(),
                            offset(4)
                        ],
                        autoUpdate: {
                            animationFrame: true
                        }
                    },
                    abortController: this._popMenuAbortController,
                    closeOnClickAway: true
                });
                this._moreMenuOpen = true;
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this.closeCurrentMenu();
                this._clearPopMenu();
            }
            render() {
                return html`
      <editor-toolbar class="affine-image-toolbar-container" data-without-bg>
        ${this._items}
        <editor-icon-button
          class="image-toolbar-button more"
          aria-label="More"
          .tooltip=${'More'}
          .tooltipOffset=${4}
          .showTooltip=${!this._moreMenuOpen}
          @click=${()=>this._toggleMoreMenu()}
        >
          ${MoreVerticalIcon}
        </editor-icon-button>
      </editor-toolbar>
    `;
            }
            #___private__moreButton_1;
            get _moreButton() {
                return this.#___private__moreButton_1;
            }
            set _moreButton(_v) {
                this.#___private__moreButton_1 = _v;
            }
            #___private__moreMenuOpen_2;
            get _moreMenuOpen() {
                return this.#___private__moreMenuOpen_2;
            }
            set _moreMenuOpen(_v) {
                this.#___private__moreMenuOpen_2 = _v;
            }
            #___private_abortController_3;
            get abortController() {
                return this.#___private_abortController_3;
            }
            set abortController(_v) {
                this.#___private_abortController_3 = _v;
            }
            #___private_blockComponent_4;
            get blockComponent() {
                return this.#___private_blockComponent_4;
            }
            set blockComponent(_v) {
                this.#___private_blockComponent_4 = _v;
            }
            #___private_config_5;
            get config() {
                return this.#___private_config_5;
            }
            set config(_v) {
                this.#___private_config_5 = _v;
            }
            #___private_moreMenuConfig_6;
            get moreMenuConfig() {
                return this.#___private_moreMenuConfig_6;
            }
            set moreMenuConfig(_v) {
                this.#___private_moreMenuConfig_6 = _v;
            }
            #___private_onActiveStatusChange_7;
            get onActiveStatusChange() {
                return this.#___private_onActiveStatusChange_7;
            }
            set onActiveStatusChange(_v) {
                this.#___private_onActiveStatusChange_7 = _v;
            }
            constructor(...args){
                super(...args);
                this._currentOpenMenu = null;
                this._popMenuAbortController = null;
                this.closeCurrentMenu = ()=>{
                    if (this._currentOpenMenu && !this._currentOpenMenu.signal.aborted) {
                        this._currentOpenMenu.abort();
                        this._currentOpenMenu = null;
                    }
                };
                this.#___private__moreButton_1 = (_initProto(this), _init__moreButton(this));
                this.#___private__moreMenuOpen_2 = _init__moreMenuOpen(this, false);
                this.#___private_abortController_3 = _init_abortController(this);
                this.#___private_blockComponent_4 = _init_blockComponent(this);
                this.#___private_config_5 = _init_config(this);
                this.#___private_moreMenuConfig_6 = _init_moreMenuConfig(this);
                this.#___private_onActiveStatusChange_7 = _init_onActiveStatusChange(this, noop);
            }
        }
    }
}();
export { _AffineImageToolbar as AffineImageToolbar };
