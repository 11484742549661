function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _init_abortController, _init_confirmButton, _init_inlineEditor, _init_linkInput, _init_mockSelectionContainer, _init_popupContainer, _init_targetInlineRange, _init_textInput, _init_type, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { assertExists } from '@blocksuite/global/utils';
import { computePosition, inline, offset, shift } from '@floating-ui/dom';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { join } from 'lit/directives/join.js';
import { repeat } from 'lit/directives/repeat.js';
import { toast } from '../../../../../components/toast.js';
import '../../../../../components/toolbar/icon-button.js';
import '../../../../../components/toolbar/menu-button.js';
import '../../../../../components/toolbar/separator.js';
import { renderToolbarSeparator } from '../../../../../components/toolbar/separator.js';
import '../../../../../components/toolbar/toolbar.js';
import { renderActions } from '../../../../../components/toolbar/utils.js';
import '../../../../../components/tooltip/tooltip.js';
import { BLOCK_ID_ATTR } from '../../../../../consts.js';
import { ConfirmIcon, CopyIcon, DeleteIcon, EditIcon, MoreVerticalIcon, OpenIcon, SmallArrowDownIcon, UnlinkIcon } from '../../../../../icons/index.js';
import { getHostName, isValidUrl, normalizeUrl } from '../../../../../utils/url.js';
import { linkPopupStyle } from './styles.js';
let _LinkPopup;
_dec = customElement('link-popup'), _dec1 = property({
    attribute: false
}), _dec2 = query('.affine-confirm-button'), _dec3 = property({
    attribute: false
}), _dec4 = query('#link-input'), _dec5 = query('.mock-selection-container'), _dec6 = query('.affine-link-popover-container'), _dec7 = property({
    attribute: false
}), _dec8 = query('#text-input'), _dec9 = property();
new class extends _identity {
    constructor(){
        super(_LinkPopup), _initClass();
    }
    static{
        class LinkPopup extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init_abortController, _init_confirmButton, _init_inlineEditor, _init_linkInput, _init_mockSelectionContainer, _init_popupContainer, _init_targetInlineRange, _init_textInput, _init_type, _initProto], c: [_LinkPopup, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "abortController"
                    ],
                    [
                        _dec2,
                        1,
                        "confirmButton"
                    ],
                    [
                        _dec3,
                        1,
                        "inlineEditor"
                    ],
                    [
                        _dec4,
                        1,
                        "linkInput"
                    ],
                    [
                        _dec5,
                        1,
                        "mockSelectionContainer"
                    ],
                    [
                        _dec6,
                        1,
                        "popupContainer"
                    ],
                    [
                        _dec7,
                        1,
                        "targetInlineRange"
                    ],
                    [
                        _dec8,
                        1,
                        "textInput"
                    ],
                    [
                        _dec9,
                        1,
                        "type"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = linkPopupStyle;
            }
            get _canConvertToEmbedView() {
                return this._embedOptions?.viewType === 'embed';
            }
            _confirmBtnTemplate() {
                return html`
      <editor-icon-button
        class="affine-confirm-button"
        .iconSize=${'24px'}
        .disabled=${true}
        @click=${this._onConfirm}
      >
        ${ConfirmIcon}
      </editor-icon-button>
    `;
            }
            _convertToCardView() {
                if (!this.inlineEditor.isValidInlineRange(this.targetInlineRange)) {
                    return;
                }
                let targetFlavour = 'affine:bookmark';
                if (this._embedOptions && this._embedOptions.viewType === 'card') {
                    targetFlavour = this._embedOptions.flavour;
                }
                const block = this.block;
                if (!block) return;
                const url = this.currentLink;
                const title = this.currentText;
                const props = {
                    url,
                    title: title === url ? '' : title
                };
                const doc = block.doc;
                const parent = doc.getParent(block.model);
                assertExists(parent);
                const index = parent.children.indexOf(block.model);
                doc.addBlock(targetFlavour, props, parent, index + 1);
                const totalTextLength = this.inlineEditor.yTextLength;
                const inlineTextLength = this.targetInlineRange.length;
                if (totalTextLength === inlineTextLength) {
                    doc.deleteBlock(block.model);
                } else {
                    this.inlineEditor.formatText(this.targetInlineRange, {
                        link: null
                    });
                }
                this.abortController.abort();
            }
            _convertToEmbedView() {
                if (!this._embedOptions || this._embedOptions.viewType !== 'embed') {
                    return;
                }
                const { flavour } = this._embedOptions;
                const url = this.currentLink;
                const block = this.block;
                if (!block) return;
                const doc = block.doc;
                const parent = doc.getParent(block.model);
                assertExists(parent);
                const index = parent.children.indexOf(block.model);
                doc.addBlock(flavour, {
                    url
                }, parent, index + 1);
                const totalTextLength = this.inlineEditor.yTextLength;
                const inlineTextLength = this.targetInlineRange.length;
                if (totalTextLength === inlineTextLength) {
                    doc.deleteBlock(block.model);
                } else {
                    this.inlineEditor.formatText(this.targetInlineRange, {
                        link: null
                    });
                }
                this.abortController.abort();
            }
            _copyUrl() {
                navigator.clipboard.writeText(this.currentLink).catch(console.error);
                if (!this.host) return;
                toast(this.host, 'Copied link to clipboard');
                this.abortController.abort();
            }
            get _isBookmarkAllowed() {
                const block = this.block;
                if (!block) return false;
                const schema = block.doc.schema;
                const parent = block.doc.getParent(block.model);
                if (!parent) return false;
                const bookmarkSchema = schema.flavourSchemaMap.get('affine:bookmark');
                if (!bookmarkSchema) return false;
                const parentSchema = schema.flavourSchemaMap.get(parent.flavour);
                if (!parentSchema) return false;
                try {
                    schema.validateSchema(bookmarkSchema, parentSchema);
                } catch  {
                    return false;
                }
                return true;
            }
            _moreActions() {
                return renderActions([
                    [
                        {
                            name: 'Open',
                            icon: OpenIcon,
                            handler: this._openLink
                        },
                        {
                            name: 'Copy',
                            icon: CopyIcon,
                            handler: this._copyUrl
                        },
                        {
                            name: 'Remove link',
                            icon: UnlinkIcon,
                            handler: this._removeLink
                        }
                    ],
                    [
                        {
                            type: 'delete',
                            name: 'Delete',
                            icon: DeleteIcon,
                            handler: this._delete
                        }
                    ]
                ]);
            }
            _onConfirm() {
                if (!this.inlineEditor.isValidInlineRange(this.targetInlineRange)) return;
                if (!this.linkInput) return;
                const linkInputValue = this.linkInput.value;
                if (!linkInputValue || !isValidUrl(linkInputValue)) return;
                const link = normalizeUrl(linkInputValue);
                if (this.type === 'create') {
                    this.inlineEditor.formatText(this.targetInlineRange, {
                        link: link,
                        reference: null
                    });
                    this.inlineEditor.setInlineRange(this.targetInlineRange);
                    const textSelection = this.host?.selection.find('text');
                    if (!textSelection) return;
                    this.std?.range.syncTextSelectionToRange(textSelection);
                } else if (this.type === 'edit') {
                    const text = this.textInput?.value ?? link;
                    this.inlineEditor.insertText(this.targetInlineRange, text, {
                        link: link,
                        reference: null
                    });
                    this.inlineEditor.setInlineRange({
                        index: this.targetInlineRange.index,
                        length: text.length
                    });
                    const textSelection = this.host?.selection.find('text');
                    if (!textSelection) return;
                    this.std?.range.syncTextSelectionToRange(textSelection);
                }
                this.abortController.abort();
            }
            _onKeydown(e) {
                e.stopPropagation();
                if (e.key === 'Enter' && !e.isComposing) {
                    e.preventDefault();
                    this._onConfirm();
                }
            }
            get _rootService() {
                return this.std?.spec.getService('affine:page');
            }
            _updateConfirmBtn() {
                assertExists(this.confirmButton);
                const link = this.linkInput?.value.trim();
                this.confirmButton.disabled = !(link && isValidUrl(link));
                this.confirmButton.requestUpdate();
            }
            _viewMenuButton() {
                if (!this._isBookmarkAllowed) return nothing;
                const buttons = [];
                buttons.push({
                    type: 'inline',
                    name: 'Inline view'
                });
                buttons.push({
                    type: 'card',
                    name: 'Card view',
                    handler: ()=>this._convertToCardView()
                });
                if (this._canConvertToEmbedView) {
                    buttons.push({
                        type: 'embed',
                        name: 'Embed view',
                        handler: ()=>this._convertToEmbedView()
                    });
                }
                return html`
      <editor-menu-button
        .contentPadding=${'8px'}
        .button=${html`
          <editor-icon-button
            aria-label="Switch view"
            .justify=${'space-between'}
            .labelHeight=${'20px'}
            .iconContainerWidth=${'110px'}
          >
            <div class="label">Inline view</div>
            ${SmallArrowDownIcon}
          </editor-icon-button>
        `}
      >
        <div data-size="small" data-orientation="vertical">
          ${repeat(buttons, (button)=>button.type, ({ type, name, handler })=>html`
              <editor-menu-action
                data-testid=${`link-to-${type}`}
                ?data-selected=${type === 'inline'}
                @click=${handler}
              >
                ${name}
              </editor-menu-action>
            `)}
        </div>
      </editor-menu-button>
    `;
            }
            connectedCallback() {
                super.connectedCallback();
                if (this.targetInlineRange.length === 0) {
                    return;
                }
                if (this.type === 'edit' || this.type === 'create') {
                    this._bodyOverflowStyle = document.body.style.overflow;
                    document.body.style.overflow = 'hidden';
                    this.disposables.add({
                        dispose: ()=>{
                            document.body.style.overflow = this._bodyOverflowStyle;
                        }
                    });
                }
            }
            firstUpdated() {
                if (!this.linkInput) return;
                this._disposables.addFromEvent(this.linkInput, 'copy', (e)=>{
                    e.stopPropagation();
                });
                this._disposables.addFromEvent(this.linkInput, 'cut', (e)=>{
                    e.stopPropagation();
                });
                this._disposables.addFromEvent(this.linkInput, 'paste', (e)=>{
                    e.stopPropagation();
                });
            }
            render() {
                return html`
      <div class="overlay-root">
        ${this.type === 'view' ? nothing : html`
              <div
                class="affine-link-popover-overlay-mask"
                @click=${()=>{
                    this.abortController.abort();
                    this.host?.selection.clear();
                }}
              ></div>
            `}
        <div class="affine-link-popover-container" @keydown=${this._onKeydown}>
          ${choose(this.type, [
                    [
                        'create',
                        this._createTemplate
                    ],
                    [
                        'edit',
                        this._editTemplate
                    ],
                    [
                        'view',
                        this._viewTemplate
                    ]
                ])}
        </div>
        <div class="mock-selection-container"></div>
      </div>
    `;
            }
            updated() {
                assertExists(this.popupContainer);
                const range = this.inlineEditor.toDomRange(this.targetInlineRange);
                assertExists(range);
                if (this.type !== 'view') {
                    const domRects = range.getClientRects();
                    Object.values(domRects).forEach((domRect)=>{
                        const mockSelection = document.createElement('div');
                        mockSelection.classList.add('mock-selection');
                        mockSelection.style.left = `${domRect.left}px`;
                        mockSelection.style.top = `${domRect.top}px`;
                        mockSelection.style.width = `${domRect.width}px`;
                        mockSelection.style.height = `${domRect.height}px`;
                        assertExists(this.mockSelectionContainer);
                        this.mockSelectionContainer.append(mockSelection);
                    });
                }
                const visualElement = {
                    getBoundingClientRect: ()=>range.getBoundingClientRect(),
                    getClientRects: ()=>range.getClientRects()
                };
                computePosition(visualElement, this.popupContainer, {
                    middleware: [
                        offset(10),
                        inline(),
                        shift({
                            padding: 6
                        })
                    ]
                }).then(({ x, y })=>{
                    const popupContainer = this.popupContainer;
                    if (!popupContainer) return;
                    popupContainer.style.left = `${x}px`;
                    popupContainer.style.top = `${y}px`;
                }).catch(console.error);
            }
            get block() {
                const block = this.inlineEditor.rootElement.closest(`[${BLOCK_ID_ATTR}]`);
                if (!block) return null;
                return block;
            }
            get currentLink() {
                const link = this.inlineEditor.getFormat(this.targetInlineRange).link;
                assertExists(link);
                return link;
            }
            get currentText() {
                return this.inlineEditor.yTextString.slice(this.targetInlineRange.index, this.targetInlineRange.index + this.targetInlineRange.length);
            }
            get host() {
                return this.block?.host;
            }
            get std() {
                return this.block?.std;
            }
            #___private_abortController_1;
            get abortController() {
                return this.#___private_abortController_1;
            }
            set abortController(_v) {
                this.#___private_abortController_1 = _v;
            }
            #___private_confirmButton_2;
            get confirmButton() {
                return this.#___private_confirmButton_2;
            }
            set confirmButton(_v) {
                this.#___private_confirmButton_2 = _v;
            }
            #___private_inlineEditor_3;
            get inlineEditor() {
                return this.#___private_inlineEditor_3;
            }
            set inlineEditor(_v) {
                this.#___private_inlineEditor_3 = _v;
            }
            #___private_linkInput_4;
            get linkInput() {
                return this.#___private_linkInput_4;
            }
            set linkInput(_v) {
                this.#___private_linkInput_4 = _v;
            }
            #___private_mockSelectionContainer_5;
            get mockSelectionContainer() {
                return this.#___private_mockSelectionContainer_5;
            }
            set mockSelectionContainer(_v) {
                this.#___private_mockSelectionContainer_5 = _v;
            }
            #___private_popupContainer_6;
            get popupContainer() {
                return this.#___private_popupContainer_6;
            }
            set popupContainer(_v) {
                this.#___private_popupContainer_6 = _v;
            }
            #___private_targetInlineRange_7;
            get targetInlineRange() {
                return this.#___private_targetInlineRange_7;
            }
            set targetInlineRange(_v) {
                this.#___private_targetInlineRange_7 = _v;
            }
            #___private_textInput_8;
            get textInput() {
                return this.#___private_textInput_8;
            }
            set textInput(_v) {
                this.#___private_textInput_8 = _v;
            }
            #___private_type_9;
            get type() {
                return this.#___private_type_9;
            }
            set type(_v) {
                this.#___private_type_9 = _v;
            }
            constructor(...args){
                super(...args);
                this._bodyOverflowStyle = '';
                this._createTemplate = ()=>{
                    this.updateComplete.then(()=>{
                        this.linkInput?.focus();
                        this._updateConfirmBtn();
                    }).catch(console.error);
                    return html`
      <div class="affine-link-popover create">
        <input
          id="link-input"
          class="affine-link-popover-input"
          type="text"
          spellcheck="false"
          placeholder="Paste or type a link"
          @input=${this._updateConfirmBtn}
        />
        ${this._confirmBtnTemplate()}
      </div>
    `;
                };
                this._delete = ()=>{
                    if (this.inlineEditor.isValidInlineRange(this.targetInlineRange)) {
                        this.inlineEditor.deleteText(this.targetInlineRange);
                    }
                    this.abortController.abort();
                };
                this._edit = ()=>{
                    this.type = 'edit';
                };
                this._editTemplate = ()=>{
                    this.updateComplete.then(()=>{
                        assertExists(this.textInput);
                        this.textInput.value = this.currentText;
                        assertExists(this.linkInput);
                        this.linkInput.value = this.currentLink;
                        this.textInput.select();
                        this._updateConfirmBtn();
                    }).catch(console.error);
                    return html`
      <div class="affine-link-edit-popover">
        <div class="affine-edit-area text">
          <input
            class="affine-edit-input"
            id="text-input"
            type="text"
            placeholder="Enter text"
            @input=${this._updateConfirmBtn}
          />
          <label class="affine-edit-label" for="text-input">Text</label>
        </div>
        <div class="affine-edit-area link">
          <input
            id="link-input"
            class="affine-edit-input"
            type="text"
            spellcheck="false"
            placeholder="Paste or type a link"
            @input=${this._updateConfirmBtn}
          />
          <label class="affine-edit-label" for="link-input">Link</label>
        </div>
        ${this._confirmBtnTemplate()}
      </div>
    `;
                };
                this._embedOptions = null;
                this._openLink = ()=>{
                    let link = this.currentLink;
                    if (!link.match(/^[a-zA-Z]+:\/\//)) {
                        link = 'https://' + link;
                    }
                    window.open(link, '_blank');
                    this.abortController.abort();
                };
                this._removeLink = ()=>{
                    if (this.inlineEditor.isValidInlineRange(this.targetInlineRange)) {
                        this.inlineEditor.formatText(this.targetInlineRange, {
                            link: null
                        });
                    }
                    this.abortController.abort();
                };
                this._viewTemplate = ()=>{
                    if (!this._rootService) {
                        return nothing;
                    }
                    this._embedOptions = this._rootService.getEmbedBlockOptions(this.currentLink);
                    const buttons = [
                        html`
        <a
          class="affine-link-preview"
          href=${this.currentLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span>${getHostName(this.currentLink)}</span>
        </a>

        <editor-icon-button
          aria-label="Copy"
          data-testid="copy-link"
          .tooltip=${'Click to copy link'}
          @click=${this._copyUrl}
        >
          ${CopyIcon}
        </editor-icon-button>

        <editor-icon-button
          aria-label="Edit"
          data-testid="edit"
          .tooltip=${'Edit'}
          @click=${this._edit}
        >
          ${EditIcon}
        </editor-icon-button>
      `,
                        this._viewMenuButton(),
                        html`
        <editor-menu-button
          .contentPadding=${'8px'}
          .button=${html`
            <editor-icon-button aria-label="More" .tooltip=${'More'}>
              ${MoreVerticalIcon}
            </editor-icon-button>
          `}
        >
          <div data-size="large" data-orientation="vertical">
            ${this._moreActions()}
          </div>
        </editor-menu-button>
      `
                    ];
                    return html`
      <editor-toolbar class="affine-link-popover view">
        ${join(buttons.filter((button)=>button !== nothing), renderToolbarSeparator)}
      </editor-toolbar>
    `;
                };
                this.#___private_abortController_1 = (_initProto(this), _init_abortController(this));
                this.#___private_confirmButton_2 = _init_confirmButton(this, null);
                this.#___private_inlineEditor_3 = _init_inlineEditor(this);
                this.#___private_linkInput_4 = _init_linkInput(this, null);
                this.#___private_mockSelectionContainer_5 = _init_mockSelectionContainer(this);
                this.#___private_popupContainer_6 = _init_popupContainer(this);
                this.#___private_targetInlineRange_7 = _init_targetInlineRange(this);
                this.#___private_textInput_8 = _init_textInput(this, null);
                this.#___private_type_9 = _init_type(this, 'create');
            }
        }
    }
}();
export { _LinkPopup as LinkPopup };
