import { Bound, Vec } from '@blocksuite/global/utils';
import { assertExists, noop } from '@blocksuite/global/utils';
import { DocCollection } from '@blocksuite/store';
import { EdgelessToolController } from './edgeless-tool.js';
export class FrameToolController extends EdgelessToolController {
    _toModelCoord(p) {
        return this._service.viewport.toModelCoord(p.x, p.y);
    }
    afterModeSwitch() {
        noop();
    }
    beforeModeSwitch() {
        noop();
    }
    onContainerClick() {
        noop();
    }
    onContainerContextMenu() {
        noop();
    }
    onContainerDblClick() {
        noop();
    }
    onContainerDragEnd() {
        if (this._frame) {
            const frame = this._frame;
            this._doc.transact(()=>{
                frame.pop('xywh');
            });
            this._edgeless.tools.setEdgelessTool({
                type: 'default'
            });
            this._edgeless.service.selection.set({
                elements: [
                    this._frame.id
                ],
                editing: false
            });
            this._doc.captureSync();
        }
        this._frame = null;
        this._startPoint = null;
    }
    onContainerDragMove(e) {
        const currentPoint = this._toModelCoord(e.point);
        assertExists(this._startPoint);
        if (Vec.dist(this._startPoint, currentPoint) < 8 && !this._frame) return;
        if (!this._frame) {
            const frames = this._service.frames;
            const id = this._service.addBlock('affine:frame', {
                title: new DocCollection.Y.Text(`Frame ${frames.length + 1}`),
                xywh: Bound.fromPoints([
                    this._startPoint,
                    currentPoint
                ]).serialize()
            }, this._service.surface);
            this._service.telemetryService?.track('CanvasElementAdded', {
                control: 'canvas:draw',
                page: 'whiteboard editor',
                module: 'toolbar',
                segment: 'toolbar',
                type: 'frame'
            });
            this._frame = this._service.getElementById(id);
            this._frame.stash('xywh');
            return;
        }
        assertExists(this._frame);
        this._service.updateElement(this._frame.id, {
            xywh: Bound.fromPoints([
                this._startPoint,
                currentPoint
            ]).serialize()
        });
    }
    onContainerDragStart(e) {
        this._doc.captureSync();
        const { point } = e;
        this._startPoint = this._toModelCoord(point);
    }
    onContainerMouseMove() {
        noop();
    }
    onContainerMouseOut() {
        noop();
    }
    onContainerPointerDown() {
        noop();
    }
    onContainerTripleClick() {
        noop();
    }
    onPressShiftKey() {
        noop();
    }
    onPressSpaceBar(_pressed) {
        noop();
    }
    constructor(...args){
        super(...args);
        this._frame = null;
        this._startPoint = null;
        this.tool = {
            type: 'frame'
        };
    }
}
