import { assertExists } from '@blocksuite/global/utils';
import { DocCollection, fromJSON } from '@blocksuite/store';
import { matchFlavours } from '../../../_common/utils/index.js';
function findLastMatchingNode(root, fn) {
    let lastMatchingNode = null;
    function traverse(node) {
        if (fn(node)) {
            lastMatchingNode = node;
        }
        if (node.children) {
            for (const child of node.children){
                traverse(child);
            }
        }
    }
    root.forEach(traverse);
    return lastMatchingNode;
}
const findLast = (snapshot)=>{
    return findLastMatchingNode(snapshot.content, (node)=>!!node.props.text);
};
class PointState {
    constructor(std, point){
        this.std = std;
        this.point = point;
        this._blockFromPath = (path)=>{
            const block = this.std.view.getBlock(path);
            assertExists(block);
            return block;
        };
        this.block = this._blockFromPath(point.blockId);
        this.model = this.block.model;
        const text = this.model.text;
        assertExists(text);
        this.text = text;
    }
}
class PasteTr {
    constructor(std, text, snapshot){
        this.std = std;
        this.text = text;
        this.snapshot = snapshot;
        this._getDeltas = ()=>{
            const firstTextSnapshot = this._textFromSnapshot(this.firstSnapshot);
            const lastTextSnapshot = this._textFromSnapshot(this.lastSnapshot);
            const fromDelta = this.fromPointState.text.sliceToDelta(0, this.fromPointState.point.index);
            const toDelta = this.endPointState.text.sliceToDelta(this.endPointState.point.index + this.endPointState.point.length, this.endPointState.text.length);
            const firstDelta = firstTextSnapshot.delta;
            const lastDelta = lastTextSnapshot.delta;
            return {
                firstTextSnapshot,
                lastTextSnapshot,
                fromDelta,
                toDelta,
                firstDelta,
                lastDelta
            };
        };
        this._mergeCode = ()=>{
            const { toDelta } = this._getDeltas();
            const deltas = [
                {
                    retain: this.fromPointState.point.index
                },
                this.fromPointState.text.length - this.fromPointState.point.index ? {
                    delete: this.fromPointState.text.length - this.fromPointState.point.index
                } : {}
            ];
            let i = 0;
            for (const blockSnapshot of this.snapshot.content){
                if (blockSnapshot.props.text) {
                    const text = this._textFromSnapshot(blockSnapshot);
                    if (i > 0) {
                        deltas.push({
                            insert: '\n'
                        });
                    }
                    deltas.push(...text.delta);
                    i++;
                } else {
                    break;
                }
            }
            this.fromPointState.text.applyDelta(deltas.concat(toDelta));
            this.snapshot.content = [];
        };
        this._mergeMultiple = ()=>{
            this.firstSnapshot.flavour = this.fromPointState.model.flavour;
            if (this.firstSnapshot.props.type && (this.fromPointState.text.length > 0 || this.firstSnapshotIsPlainText)) {
                this.firstSnapshot.props.type = this.fromPointState.model.type;
            }
            if (this.lastSnapshot.props.type && this.to) {
                this.lastSnapshot.flavour = this.endPointState.model.flavour;
                this.lastSnapshot.props.type = this.endPointState.model.type;
            }
            const { lastTextSnapshot, toDelta, firstDelta, lastDelta } = this._getDeltas();
            this.fromPointState.text.applyDelta([
                {
                    retain: this.fromPointState.point.index
                },
                this.fromPointState.text.length - this.fromPointState.point.index ? {
                    delete: this.fromPointState.text.length - this.fromPointState.point.index
                } : {},
                ...firstDelta
            ]);
            const removedFirstSnapshot = this.snapshot.content.shift();
            removedFirstSnapshot?.children.map((block)=>{
                this.snapshot.content.unshift(block);
            });
            this.pasteStartModelChildrenCount = removedFirstSnapshot?.children.length ?? 0;
            this._updateSnapshot();
            lastTextSnapshot.delta = [
                ...lastDelta,
                ...toDelta
            ];
        };
        this._mergeSingle = ()=>{
            const { firstDelta } = this._getDeltas();
            this.fromPointState.text.applyDelta([
                {
                    retain: this.fromPointState.point.index
                },
                this.fromPointState.point.length ? {
                    delete: this.fromPointState.point.length
                } : {},
                ...firstDelta
            ]);
            this.snapshot.content.splice(0, 1);
            this._updateSnapshot();
        };
        this._textFromSnapshot = (snapshot)=>{
            return snapshot.props.text ?? {
                delta: []
            };
        };
        this._updateSnapshot = ()=>{
            if (this.snapshot.content.length === 0) {
                this.firstSnapshot = this.lastSnapshot = undefined;
                return;
            }
            this.firstSnapshot = this.snapshot.content[0];
            this.lastSnapshot = findLast(this.snapshot) ?? this.firstSnapshot;
        };
        this.pasteStartModel = null;
        this.pasteStartModelChildrenCount = 0;
        this.canMerge = ()=>{
            if (this.snapshot.content.length === 0) {
                return false;
            }
            if (!this.firstSnapshot.props.text) {
                return false;
            }
            const firstTextSnapshot = this._textFromSnapshot(this.firstSnapshot);
            const lastTextSnapshot = this._textFromSnapshot(this.lastSnapshot);
            return firstTextSnapshot && lastTextSnapshot && (this.fromPointState.text.length > 0 && this.endPointState.text.length > 0 || this.firstSnapshotIsPlainText);
        };
        this.convertToLinkedDoc = async ()=>{
            const quickSearchService = this.std.spec.getService('affine:page').quickSearchService;
            if (!quickSearchService) {
                return;
            }
            const linkToDocId = new Map();
            for (const blockSnapshot of this.snapshot.content){
                if (blockSnapshot.props.text) {
                    const [delta, transformed] = await this._transformLinkDelta(this._textFromSnapshot(blockSnapshot).delta, linkToDocId, quickSearchService);
                    const model = this.std.doc.getBlockById(blockSnapshot.id);
                    if (transformed && model) {
                        this.std.doc.captureSync();
                        this.std.doc.transact(()=>{
                            const text = model.text;
                            text.clear();
                            text.applyDelta(delta);
                        });
                    }
                }
            }
            const fromPointStateText = this.fromPointState.model.text;
            if (!fromPointStateText) {
                return;
            }
            const [delta, transformed] = await this._transformLinkDelta(fromPointStateText.toDelta(), linkToDocId, quickSearchService);
            if (!transformed) {
                return;
            }
            this.std.doc.captureSync();
            this.std.doc.transact(()=>{
                fromPointStateText.clear();
                fromPointStateText.applyDelta(delta);
            });
        };
        this.focusPasted = ()=>{
            const host = this.std.host;
            const cursorBlock = this.fromPointState.model.flavour === 'affine:code' || !this.lastSnapshot ? this.std.doc.getBlock(this.fromPointState.model.id) : this.std.doc.getBlock(this.lastSnapshot.id);
            assertExists(cursorBlock);
            const { model: cursorModel } = cursorBlock;
            host.updateComplete.then(()=>{
                const target = this.std.host.querySelector(`[${host.blockIdAttr}="${cursorModel.id}"]`);
                if (!target) {
                    return;
                }
                if (!cursorModel.text) {
                    if (matchFlavours(cursorModel, [
                        'affine:image'
                    ])) {
                        const selection = this.std.selection.create('image', {
                            blockId: target.blockId
                        });
                        this.std.selection.setGroup('note', [
                            selection
                        ]);
                        return;
                    }
                    const selection = this.std.selection.create('block', {
                        blockId: target.blockId
                    });
                    this.std.selection.setGroup('note', [
                        selection
                    ]);
                    return;
                }
                const selection = this.std.selection.create('text', {
                    from: {
                        blockId: target.blockId,
                        index: cursorModel.text ? this.lastIndex : 0,
                        length: 0
                    },
                    to: null
                });
                this.std.selection.setGroup('note', [
                    selection
                ]);
            }).catch(console.error);
        };
        this.pasted = ()=>{
            const needCleanup = this.canMerge() || this.endPointState.text.length === 0;
            if (!needCleanup) {
                return;
            }
            if (this.to) {
                const context = this.std.command.exec('getSelectedModels', {
                    types: [
                        'text'
                    ]
                });
                for (const model of context.selectedModels ?? []){
                    if ([
                        this.endPointState.model.id,
                        this.fromPointState.model.id
                    ].includes(model.id) || this.snapshot.content.map((block)=>block.id).includes(model.id)) {
                        continue;
                    }
                    this.std.doc.deleteBlock(model);
                }
                this.std.doc.deleteBlock(this.endPointState.model, this.pasteStartModel ? {
                    bringChildrenTo: this.pasteStartModel
                } : undefined);
            }
            if (this.lastSnapshot) {
                const lastBlock = this.std.doc.getBlock(this.lastSnapshot.id);
                assertExists(lastBlock);
                const { model: lastModel } = lastBlock;
                this.std.doc.moveBlocks(this.fromPointState.model.children, lastModel);
            }
            this.std.doc.moveBlocks(this.std.doc.getNexts(this.fromPointState.model.id).slice(0, this.pasteStartModelChildrenCount), this.fromPointState.model);
            if (!this.firstSnapshotIsPlainText && this.fromPointState.text.length == 0) {
                this.std.doc.deleteBlock(this.fromPointState.model);
            }
        };
        const { from, to } = text;
        const end = to ?? from;
        this.to = to;
        this.fromPointState = new PointState(std, from);
        this.endPointState = new PointState(std, end);
        this.firstSnapshot = snapshot.content[0];
        this.lastSnapshot = findLast(snapshot) ?? this.firstSnapshot;
        if (this.firstSnapshot !== this.lastSnapshot && this.lastSnapshot.props.text) {
            const text = fromJSON(this.lastSnapshot.props.text);
            const doc = new DocCollection.Y.Doc();
            const temp = doc.getMap('temp');
            temp.set('text', text.yText);
            this.lastIndex = text.length;
        } else {
            this.lastIndex = this.fromPointState.point.index + this.snapshot.content.map((snapshot)=>this._textFromSnapshot(snapshot).delta.map((op)=>{
                    if (op.insert) {
                        return op.insert.length;
                    } else if (op.delete) {
                        return -op.delete;
                    } else {
                        return 0;
                    }
                }).reduce((a, b)=>a + b, 0)).reduce((a, b)=>a + b + 1, -1);
        }
        this.firstSnapshotIsPlainText = this.firstSnapshot.flavour === 'affine:paragraph' && this.firstSnapshot.props.type === 'text';
    }
    async _transformLinkDelta(delta, linkToDocId, quickSearchService) {
        let transformed = false;
        const needToConvert = new Map();
        for (const op of delta){
            if (op.attributes?.link) {
                let docId = linkToDocId.get(op.attributes.link);
                if (docId === undefined) {
                    const searchResult = await quickSearchService.searchDoc({
                        userInput: op.attributes.link,
                        skipSelection: true,
                        action: 'insert'
                    });
                    if (searchResult && 'docId' in searchResult) {
                        const doc = this.std.collection.getDoc(searchResult.docId);
                        if (doc) {
                            docId = doc.id;
                            linkToDocId.set(op.attributes.link, doc.id);
                        }
                    }
                }
                if (docId) {
                    needToConvert.set(op, docId);
                }
            }
        }
        const newDelta = delta.map((op)=>{
            if (needToConvert.has(op)) {
                this.std.spec.getService('affine:page').telemetryService?.track('LinkedDocCreated', {
                    page: 'doc editor',
                    category: 'pasted link',
                    type: 'doc',
                    other: 'existing doc'
                });
                transformed = true;
                return {
                    ...op,
                    attributes: {
                        reference: {
                            pageId: needToConvert.get(op),
                            type: 'LinkedPage'
                        }
                    },
                    insert: ' '
                };
            }
            return {
                ...op
            };
        });
        return [
            newDelta,
            transformed
        ];
    }
    merge() {
        if (this.fromPointState.model.flavour === 'affine:code' && !this.to) {
            this._mergeCode();
            return;
        }
        if (this.firstSnapshot === this.lastSnapshot) {
            this._mergeSingle();
            return;
        }
        this._mergeMultiple();
    }
}
function flatNote(snapshot) {
    if (snapshot.content[0]?.flavour === 'affine:note') {
        snapshot.content = snapshot.content[0].children;
    }
}
export const pasteMiddleware = (std)=>{
    return ({ slots })=>{
        let tr;
        slots.beforeImport.on((payload)=>{
            if (payload.type === 'slice') {
                const { snapshot } = payload;
                flatNote(snapshot);
                const text = std.selection.find('text');
                if (!text) {
                    return;
                }
                tr = new PasteTr(std, text, payload.snapshot);
                if (tr.canMerge()) {
                    tr.merge();
                }
            }
        });
        slots.afterImport.on((payload)=>{
            if (tr && payload.type === 'slice') {
                const context = std.command.exec('getSelectedModels', {
                    types: [
                        'block'
                    ]
                });
                for (const model of context.selectedModels ?? []){
                    std.doc.deleteBlock(model);
                }
                tr.pasted();
                tr.focusPasted();
                tr.convertToLinkedDoc().catch(console.error);
            }
        });
    };
};
