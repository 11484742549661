import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NoPermissionOrNotFound, NotFoundPage } from '@affine/component/not-found-page';
import { useAsyncCallback } from '@affine/core/hooks/affine-async-hooks';
import { apis } from '@affine/electron-api';
import { useLiveData, useService } from '@toeverything/infra';
import { useCallback, useEffect, useState } from 'react';
import { SignOutModal } from '../components/affine/sign-out-modal';
import { RouteLogic, useNavigateHelper } from '../hooks/use-navigate-helper';
import { AuthService } from '../modules/cloud';
import { SignIn } from './sign-in';
export const PageNotFound = ({ noPermission })=>{
    const authService = useService(AuthService);
    const account = useLiveData(authService.session.account$);
    const { jumpToIndex } = useNavigateHelper();
    const [open, setOpen] = useState(false);
    const handleBackButtonClick = useCallback(()=>jumpToIndex(RouteLogic.REPLACE), [
        jumpToIndex
    ]);
    const handleOpenSignOutModal = useCallback(()=>{
        setOpen(true);
    }, [
        setOpen
    ]);
    const onConfirmSignOut = useAsyncCallback(async ()=>{
        setOpen(false);
        await authService.signOut();
    }, [
        authService
    ]);
    useEffect(()=>{
        apis?.ui.pingAppLayoutReady().catch(console.error);
    }, []);
    return _jsxs(_Fragment, {
        children: [
            noPermission ? _jsx(NoPermissionOrNotFound, {
                user: account,
                onBack: handleBackButtonClick,
                onSignOut: handleOpenSignOutModal,
                signInComponent: _jsx(SignIn, {})
            }) : _jsx(NotFoundPage, {
                user: account,
                onBack: handleBackButtonClick,
                onSignOut: handleOpenSignOutModal
            }),
            _jsx(SignOutModal, {
                open: open,
                onOpenChange: setOpen,
                onConfirm: onConfirmSignOut
            })
        ]
    });
};
export const Component = PageNotFound;
