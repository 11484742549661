import { oauthProvidersQuery, serverConfigQuery, ServerFeature } from '@affine/graphql';
import { Store } from '@toeverything/infra';
export class ServerConfigStore extends Store {
    constructor(gqlService){
        super();
        this.gqlService = gqlService;
    }
    async fetchServerConfig(abortSignal) {
        const serverConfigData = await this.gqlService.gql({
            query: serverConfigQuery,
            context: {
                signal: abortSignal
            }
        });
        if (serverConfigData.serverConfig.features.includes(ServerFeature.OAuth)) {
            const oauthProvidersData = await this.gqlService.gql({
                query: oauthProvidersQuery,
                context: {
                    signal: abortSignal
                }
            });
            return {
                ...serverConfigData.serverConfig,
                ...oauthProvidersData.serverConfig
            };
        }
        return {
            ...serverConfigData.serverConfig,
            oauthProviders: []
        };
    }
}
