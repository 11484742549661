import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@affine/component/ui/button';
import { useAsyncCallback } from '@affine/core/hooks/affine-async-hooks';
import { Trans, useI18n } from '@affine/i18n';
import { useTheme } from 'next-themes';
import { useState } from 'react';
import imageUrlFor404 from '../error-assets/404-status.assets.svg';
import imageUrlForDark500 from '../error-assets/dark-500-status.assets.svg';
import imageUrlForLight500 from '../error-assets/light-500-status.assets.svg';
import * as styles from './error-detail.css';
export var ErrorStatus;
(function(ErrorStatus) {
    ErrorStatus[ErrorStatus["NotFound"] = 404] = "NotFound";
    ErrorStatus[ErrorStatus["Unexpected"] = 500] = "Unexpected";
})(ErrorStatus || (ErrorStatus = {}));
const imageMap = new Map([
    [
        404,
        {
            light: imageUrlFor404,
            dark: imageUrlFor404
        }
    ],
    [
        500,
        {
            light: imageUrlForLight500,
            dark: imageUrlForDark500
        }
    ]
]);
export const ErrorDetail = (props)=>{
    const { status = 500, direction = 'row', description, onButtonClick, resetError, withoutImage } = props;
    const descriptions = Array.isArray(description) ? description : [
        description
    ];
    const [isBtnLoading, setBtnLoading] = useState(false);
    const t = useI18n();
    const { resolvedTheme } = useTheme();
    const onBtnClick = useAsyncCallback(async ()=>{
        try {
            setBtnLoading(true);
            await onButtonClick?.();
            resetError?.();
        } finally{
            setBtnLoading(false);
        }
    }, [
        onButtonClick,
        resetError
    ]);
    return _jsxs("div", {
        className: styles.errorLayout,
        style: {
            flexDirection: direction
        },
        children: [
            _jsxs("div", {
                className: styles.errorDetailStyle,
                children: [
                    _jsx("h1", {
                        className: styles.errorTitle,
                        children: props.title
                    }),
                    descriptions.map((item, i)=>_jsx("p", {
                            className: styles.errorDescription,
                            children: item
                        }, i)),
                    _jsx("div", {
                        className: styles.errorFooter,
                        children: _jsx(Button, {
                            variant: "primary",
                            onClick: onBtnClick,
                            loading: isBtnLoading,
                            size: "extraLarge",
                            children: props.buttonText ?? t['com.affine.error.retry']()
                        })
                    })
                ]
            }),
            withoutImage ? null : _jsx("div", {
                className: styles.errorImage,
                style: {
                    backgroundImage: `url(${imageMap.get(status)?.[resolvedTheme]})`
                }
            })
        ]
    });
};
export function ContactUS() {
    return _jsxs(Trans, {
        children: [
            "If you are still experiencing this issue, please",
            ' ',
            _jsx("a", {
                style: {
                    color: 'var(--affine-primary-color)'
                },
                href: "https://community.wemem.ai",
                target: "__blank",
                children: "contact us through the community."
            })
        ]
    });
}
