import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { i18nTime, useI18n } from '@affine/i18n';
import { DateTimeIcon, HistoryIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService, WorkspaceService } from '@toeverything/infra';
import { useDebouncedValue } from 'foxact/use-debounced-value';
import { useContext, useMemo } from 'react';
import { managerContext } from '../common';
import * as styles from './time-row.css';
const RowComponent = ({ name, icon, time })=>{
    return _jsxs("div", {
        className: styles.rowCell,
        children: [
            _jsxs("div", {
                className: styles.rowNameContainer,
                children: [
                    _jsx("div", {
                        className: styles.icon,
                        children: icon
                    }),
                    _jsx("span", {
                        className: styles.rowName,
                        children: name
                    })
                ]
            }),
            _jsx("div", {
                className: styles.time,
                children: time ? time : 'unknown'
            })
        ]
    });
};
export const TimeRow = ({ docId })=>{
    const t = useI18n();
    const manager = useContext(managerContext);
    const workspaceService = useService(WorkspaceService);
    const { syncing, retrying, serverClock } = useLiveData(workspaceService.workspace.engine.doc.docState$(docId));
    const timestampElement = useMemo(()=>{
        const formatI18nTime = (time)=>i18nTime(time, {
                relative: {
                    max: [
                        1,
                        'day'
                    ],
                    accuracy: 'minute'
                },
                absolute: {
                    accuracy: 'day'
                }
            });
        const localizedCreateTime = manager.createDate ? formatI18nTime(manager.createDate) : null;
        return _jsxs(_Fragment, {
            children: [
                _jsx(RowComponent, {
                    icon: _jsx(DateTimeIcon, {}),
                    name: t['Created'](),
                    time: manager.createDate ? formatI18nTime(manager.createDate) : localizedCreateTime
                }),
                serverClock ? _jsx(RowComponent, {
                    icon: _jsx(HistoryIcon, {}),
                    name: t[!syncing && !retrying ? 'Updated' : 'com.affine.syncing'](),
                    time: !syncing && !retrying ? formatI18nTime(serverClock) : null
                }) : manager.updatedDate ? _jsx(RowComponent, {
                    icon: _jsx(HistoryIcon, {}),
                    name: t['Updated'](),
                    time: formatI18nTime(manager.updatedDate)
                }) : null
            ]
        });
    }, [
        manager.createDate,
        manager.updatedDate,
        retrying,
        serverClock,
        syncing,
        t
    ]);
    const dTimestampElement = useDebouncedValue(timestampElement, 500);
    return _jsx("div", {
        className: styles.container,
        children: dTimestampElement
    });
};
