import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem } from '@affine/component/ui/menu';
import { useI18n } from '@affine/i18n';
import { ImportIcon, PlusIcon } from '@blocksuite/icons/rc';
import * as styles from './index.css';
export const AddWorkspace = ({ onAddWorkspace, onNewWorkspace })=>{
    const t = useI18n();
    return _jsxs("div", {
        children: [
            environment.isDesktop ? _jsx(MenuItem, {
                block: true,
                preFix: _jsx(ImportIcon, {}),
                onClick: onAddWorkspace,
                "data-testid": "add-workspace",
                className: styles.ItemContainer,
                children: _jsx("div", {
                    className: styles.ItemText,
                    children: t['com.affine.workspace.local.import']()
                })
            }) : null,
            _jsx(MenuItem, {
                block: true,
                preFix: _jsx(PlusIcon, {}),
                onClick: onNewWorkspace,
                "data-testid": "new-workspace",
                className: styles.ItemContainer,
                children: _jsx("div", {
                    className: styles.ItemText,
                    children: runtimeConfig.allowLocalWorkspace ? t['com.affine.workspaceList.addWorkspace.create']() : t['com.affine.workspaceList.addWorkspace.create-cloud']()
                })
            })
        ]
    });
};
