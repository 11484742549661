function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _EdgelessToolbarToolMixin, _dec1, _dec2, _dec3, _dec4, _dec5, _init_color, _init_onShapeClick, _init_readyToDrop, _init_shapeContainer, _init_stroke, _initProto;
import { assertExists } from '@blocksuite/global/utils';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ellipseSvg, roundedSvg, triangleSvg } from '../../../../../_common/icons/edgeless.js';
import { CanvasElementType, ShapeType } from '../../../../../surface-block/index.js';
import { ShapeToolController } from '../../../controllers/tools/shape-tool.js';
import { EdgelessDraggableElementController } from '../common/draggable/draggable-element.controller.js';
import { EdgelessToolbarToolMixin } from '../mixins/tool.mixin.js';
import { buildVariablesObject } from './utils.js';
const shapes = [];
const oy = -2;
const ox = 0;
shapes.push({
    name: 'roundedRect',
    svg: roundedSvg,
    style: {
        default: {
            x: -9,
            y: 6
        },
        hover: {
            y: -5,
            z: 1
        },
        next: {
            y: 60
        }
    }
});
shapes.push({
    name: ShapeType.Ellipse,
    svg: ellipseSvg,
    style: {
        default: {
            x: -20,
            y: 31
        },
        hover: {
            y: 15,
            z: 1
        },
        next: {
            y: 64
        }
    }
});
shapes.push({
    name: ShapeType.Triangle,
    svg: triangleSvg,
    style: {
        default: {
            x: 18,
            y: 25
        },
        hover: {
            y: 7,
            z: 1
        },
        next: {
            y: 64
        }
    }
});
shapes.forEach((s)=>{
    Object.values(s.style).forEach((style)=>{
        if (style.y) style.y += oy;
        if (style.x) style.x += ox;
    });
});
let _EdgelessToolbarShapeDraggable;
_dec = customElement('edgeless-toolbar-shape-draggable'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = state(), _dec4 = query('.edgeless-shape-draggable'), _dec5 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EdgelessToolbarShapeDraggable), _initClass();
    }
    static{
        class EdgelessToolbarShapeDraggable extends (_EdgelessToolbarToolMixin = EdgelessToolbarToolMixin(LitElement)) {
            static{
                ({ e: [_init_color, _init_onShapeClick, _init_readyToDrop, _init_shapeContainer, _init_stroke, _initProto], c: [_EdgelessToolbarShapeDraggable, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "color"
                    ],
                    [
                        _dec2,
                        1,
                        "onShapeClick"
                    ],
                    [
                        _dec3,
                        1,
                        "readyToDrop"
                    ],
                    [
                        _dec4,
                        1,
                        "shapeContainer"
                    ],
                    [
                        _dec5,
                        1,
                        "stroke"
                    ]
                ], [
                    _dec
                ], _EdgelessToolbarToolMixin));
            }
            static{
                this.styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .edgeless-shape-draggable {
      /* avoid shadow clipping */
      --shadow-safe-area: 10px;
      box-sizing: border-box;
      flex-shrink: 0;
      width: calc(100% + 2 * var(--shadow-safe-area));
      height: calc(100% + var(--shadow-safe-area));
      padding-top: var(--shadow-safe-area);
      padding-left: var(--shadow-safe-area);
      padding-right: var(--shadow-safe-area);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      pointer-events: none;
    }

    .shape {
      width: fit-content;
      height: fit-content;
      position: absolute;
      transition:
        transform 0.3s,
        z-index 0.1s;
      transform: translateX(var(--default-x, 0)) translateY(var(--default-y, 0))
        scale(var(--default-s, 1));
      z-index: var(--default-z, 0);
      pointer-events: none;
    }
    .shape svg {
      display: block;
    }
    .shape svg path,
    .shape svg circle,
    .shape svg rect {
      pointer-events: auto;
      cursor: grab;
    }
    .shape:hover,
    .shape.cancel {
      transform: translateX(var(--hover-x, 0)) translateY(var(--hover-y, 0))
        scale(var(--hover-s, 1));
      z-index: var(--hover-z, 0);
    }
    .shape.next {
      transition: all 0.5s cubic-bezier(0.39, 0.28, 0.09, 0.95);
      pointer-events: none;
      transform: translateX(var(--next-x, 0)) translateY(var(--next-y, 0))
        scale(var(--next-s, 1));
    }
    .shape.next.coming {
      transform: translateX(var(--default-x, 0)) translateY(var(--default-y, 0))
        scale(var(--default-s, 1));
    }
  `;
            }
            _setShapeOverlayLock(lock) {
                const controller = this.edgeless.tools.currentController;
                if (controller instanceof ShapeToolController) {
                    controller.setDisableOverlay(lock);
                }
            }
            initDragController() {
                if (!this.edgeless || !this.toolbarContainer) return;
                if (this.draggableController) return;
                this.draggableController = new EdgelessDraggableElementController(this, {
                    service: this.edgeless.service,
                    edgeless: this.edgeless,
                    scopeElement: this.toolbarContainer,
                    standardWidth: 100,
                    clickToDrag: true,
                    onOverlayCreated: (overlay, element)=>{
                        Object.assign(overlay.element.style, {
                            color: this.color,
                            stroke: this.stroke
                        });
                        const controller = this.edgeless.tools.currentController;
                        if (controller instanceof ShapeToolController) {
                            controller.clearOverlay();
                        }
                        overlay.element.style.filter = `drop-shadow(${this.shapeShadow})`;
                        this.readyToDrop = true;
                        this.draggingShape = element.data.name;
                    },
                    onDrop: (el, bound)=>{
                        const xywh = bound.serialize();
                        const shape = el.data;
                        const id = this.edgeless.service.addElement(CanvasElementType.SHAPE, {
                            shapeType: shape.name === 'roundedRect' ? ShapeType.Rect : shape.name,
                            xywh,
                            radius: shape.name === 'roundedRect' ? 0.1 : 0
                        });
                        this.edgeless.service.telemetryService?.track('CanvasElementAdded', {
                            control: 'toolbar:dnd',
                            page: 'whiteboard editor',
                            module: 'toolbar',
                            segment: 'toolbar',
                            type: 'shape',
                            other: {
                                shapeType: shape.name === 'roundedRect' ? ShapeType.Rect : shape.name
                            }
                        });
                        this._setShapeOverlayLock(false);
                        this.readyToDrop = false;
                        this.edgeless.service.tool.setEdgelessTool({
                            type: 'default'
                        }, {
                            elements: [
                                id
                            ],
                            editing: false
                        });
                    },
                    onCanceled: ()=>{
                        this._setShapeOverlayLock(false);
                        this.readyToDrop = false;
                    },
                    onElementClick: (el)=>{
                        this.onShapeClick?.(el.data);
                        this._setShapeOverlayLock(true);
                    },
                    onEnterOrLeaveScope: (overlay, isOutside)=>{
                        overlay.element.style.filter = isOutside ? 'none' : `drop-shadow(${this.shapeShadow})`;
                    }
                });
                this._disposables.add(this.edgeless.bindHotKey({
                    s: (ctx)=>{
                        if (ctx.get('keyboardState').raw.shiftKey) return;
                        const service = this.edgeless.service;
                        if (service.locked || service.selection.editing) return;
                        if (this.readyToDrop) {
                            const activeIndex = shapes.findIndex((s)=>s.name === this.draggingShape);
                            const nextIndex = (activeIndex + 1) % shapes.length;
                            const next = shapes[nextIndex];
                            this.draggingShape = next.name;
                            this.draggableController.cancelWithoutAnimation();
                        }
                        const el = this.shapeContainer.querySelector(`.shape.${this.draggingShape}`);
                        assertExists(el, 'Edgeless toolbar Shape element not found');
                        const { x, y } = service.tool.lastMousePos;
                        const { left, top } = this.edgeless.viewport;
                        const clientPos = {
                            x: x + left,
                            y: y + top
                        };
                        this.draggableController.clickToDrag(el, clientPos);
                    }
                }, {
                    global: true
                }));
            }
            render() {
                const { cancelled, dragOut, draggingElement } = this.draggableController?.states || {};
                const draggingShape = draggingElement?.data;
                return html`<div class="edgeless-shape-draggable">
      ${repeat(shapes, (s)=>s.name, (shape)=>{
                    const isBeingDragged = draggingShape?.name === shape.name;
                    const baseStyle = {
                        ...buildVariablesObject(shape.style),
                        filter: `drop-shadow(${this.shapeShadow})`
                    };
                    const currStyle = styleMap({
                        ...baseStyle,
                        opacity: isBeingDragged ? 0 : 1
                    });
                    const nextStyle = styleMap(baseStyle);
                    return html`${isBeingDragged ? html`<div
                  style=${nextStyle}
                  class=${classMap({
                        shape: true,
                        next: true,
                        coming: !!dragOut && !cancelled
                    })}
                >
                  ${shape.svg}
                </div>` : nothing}
            <div
              style=${currStyle}
              class=${classMap({
                        shape: true,
                        [shape.name]: true,
                        cancel: isBeingDragged && !dragOut
                    })}
              @mousedown=${(e)=>this.draggableController.onMouseDown(e, {
                            data: shape,
                            preview: shape.svg
                        })}
              @touchstart=${(e)=>this.draggableController.onTouchStart(e, {
                            data: shape,
                            preview: shape.svg
                        })}
              @click=${(e)=>e.stopPropagation()}
            >
              ${shape.svg}
            </div>`;
                })}
    </div>`;
            }
            updated(_changedProperties) {
                const controllerRequiredProps = [
                    'edgeless',
                    'toolbarContainer'
                ];
                if (controllerRequiredProps.some((p)=>_changedProperties.has(p)) && !this.draggableController) {
                    this.initDragController();
                }
            }
            get shapeShadow() {
                return this.theme === 'dark' ? '0 0 7px rgba(0, 0, 0, .22)' : '0 0 5px rgba(0, 0, 0, .2)';
            }
            #___private_color_1;
            get color() {
                return this.#___private_color_1;
            }
            set color(_v) {
                this.#___private_color_1 = _v;
            }
            #___private_onShapeClick_2;
            get onShapeClick() {
                return this.#___private_onShapeClick_2;
            }
            set onShapeClick(_v) {
                this.#___private_onShapeClick_2 = _v;
            }
            #___private_readyToDrop_3;
            get readyToDrop() {
                return this.#___private_readyToDrop_3;
            }
            set readyToDrop(_v) {
                this.#___private_readyToDrop_3 = _v;
            }
            #___private_shapeContainer_4;
            get shapeContainer() {
                return this.#___private_shapeContainer_4;
            }
            set shapeContainer(_v) {
                this.#___private_shapeContainer_4 = _v;
            }
            #___private_stroke_5;
            get stroke() {
                return this.#___private_stroke_5;
            }
            set stroke(_v) {
                this.#___private_stroke_5 = _v;
            }
            constructor(...args){
                super(...args);
                this.draggingShape = 'roundedRect';
                this.type = 'shape';
                this.#___private_color_1 = (_initProto(this), _init_color(this));
                this.#___private_onShapeClick_2 = _init_onShapeClick(this, ()=>{});
                this.#___private_readyToDrop_3 = _init_readyToDrop(this, false);
                this.#___private_shapeContainer_4 = _init_shapeContainer(this);
                this.#___private_stroke_5 = _init_stroke(this);
            }
        }
    }
}();
export { _EdgelessToolbarShapeDraggable as EdgelessToolbarShapeDraggable };
