function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _init_column, _init_editing, _init_isFocus, _init_rowId, _init_view, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { computed } from '@lit-labs/preact-signals';
import { css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { createRef } from 'lit/directives/ref.js';
import { html } from 'lit/static-html.js';
import { popMenu } from '../../../../_common/components/index.js';
import { renderUniLit } from '../../utils/uni-component/uni-component.js';
import { inputConfig, typeConfig } from '../column-menu.js';
import { DatabaseDuplicate, DatabaseMoveLeft, DatabaseMoveRight, DeleteIcon } from '../icons/index.js';
let _RecordField;
_dec = customElement('affine-data-view-record-field'), _dec1 = property({
    attribute: false
}), _dec2 = state(), _dec3 = state(), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_RecordField), _initClass();
    }
    static{
        class RecordField extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_column, _init_editing, _init_isFocus, _init_rowId, _init_view, _initProto], c: [_RecordField, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "column"
                    ],
                    [
                        _dec2,
                        1,
                        "editing"
                    ],
                    [
                        _dec3,
                        1,
                        "isFocus"
                    ],
                    [
                        _dec4,
                        1,
                        "rowId"
                    ],
                    [
                        _dec5,
                        1,
                        "view"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    affine-data-view-record-field {
      display: flex;
      gap: 12px;
    }

    .field-left {
      padding: 6px;
      display: flex;
      height: max-content;
      align-items: center;
      gap: 6px;
      font-size: var(--data-view-cell-text-size);
      line-height: var(--data-view-cell-text-line-height);
      color: var(--affine-text-secondary-color);
      width: 160px;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
    }

    .field-left:hover {
      background-color: var(--affine-hover-color);
    }

    affine-data-view-record-field .icon {
      display: flex;
      align-items: center;
      width: 16px;
      height: 16px;
    }

    affine-data-view-record-field .icon svg {
      width: 16px;
      height: 16px;
      fill: var(--affine-icon-color);
    }

    .filed-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .field-content {
      padding: 6px 8px;
      border-radius: 4px;
      flex: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
    }

    .field-content .affine-database-number {
      text-align: left;
      justify-content: start;
    }

    .field-content:hover {
      background-color: var(--affine-hover-color);
    }

    .field-content.is-editing {
      box-shadow: 0px 0px 0px 2px rgba(30, 150, 235, 0.3);
    }

    .field-content.is-focus {
      border: 1px solid var(--affine-primary-color);
    }
    .field-content.empty::before {
      content: 'Empty';
      color: var(--affine-text-disable-color);
      font-size: 14px;
      line-height: 22px;
    }
  `;
            }
            get readonly() {
                return this.view.readonly$.value;
            }
            render() {
                const column = this.column;
                const props = {
                    cell: this.cell$.value,
                    isEditing: this.editing,
                    selectCurrentCell: this.changeEditing
                };
                const renderer = this.column.renderer$.value;
                if (!renderer) {
                    return;
                }
                const { view, edit } = renderer;
                const contentClass = classMap({
                    'field-content': true,
                    empty: !this.editing && this.cell$.value.isEmpty$.value,
                    'is-editing': this.editing,
                    'is-focus': this.isFocus
                });
                return html`
      <div>
        <div class="field-left" @click="${this._clickLeft}">
          <div class="icon">
            <uni-lit .uni="${this.column.icon}"></uni-lit>
          </div>
          <div class="filed-name">${column.name}</div>
        </div>
      </div>
      <div @click="${this._click}" class="${contentClass}">
        ${renderUniLit(this.editing && edit ? edit : view, props, {
                    ref: this._cell,
                    class: 'kanban-cell'
                })}
      </div>
    `;
            }
            get cell() {
                return this._cell.value;
            }
            #___private_column_1;
            get column() {
                return this.#___private_column_1;
            }
            set column(_v) {
                this.#___private_column_1 = _v;
            }
            #___private_editing_2;
            get editing() {
                return this.#___private_editing_2;
            }
            set editing(_v) {
                this.#___private_editing_2 = _v;
            }
            #___private_isFocus_3;
            get isFocus() {
                return this.#___private_isFocus_3;
            }
            set isFocus(_v) {
                this.#___private_isFocus_3 = _v;
            }
            #___private_rowId_4;
            get rowId() {
                return this.#___private_rowId_4;
            }
            set rowId(_v) {
                this.#___private_rowId_4 = _v;
            }
            #___private_view_5;
            get view() {
                return this.#___private_view_5;
            }
            set view(_v) {
                this.#___private_view_5 = _v;
            }
            constructor(...args){
                super(...args);
                this._cell = createRef();
                this._click = (e)=>{
                    e.stopPropagation();
                    if (this.readonly) return;
                    this.changeEditing(true);
                };
                this._clickLeft = (e)=>{
                    if (this.readonly) return;
                    const ele = e.currentTarget;
                    const columns = this.view.detailColumns$.value;
                    popMenu(ele, {
                        options: {
                            input: inputConfig(this.column),
                            items: [
                                {
                                    type: 'group',
                                    name: 'Column Prop Group ',
                                    children: ()=>[
                                            typeConfig(this.column)
                                        ]
                                },
                                {
                                    type: 'action',
                                    name: 'Duplicate Column',
                                    icon: DatabaseDuplicate,
                                    hide: ()=>!this.column.duplicate || this.column.type === 'title',
                                    select: ()=>{
                                        this.column.duplicate?.();
                                    }
                                },
                                {
                                    type: 'action',
                                    name: 'Move Up',
                                    icon: html` <div
              style="transform: rotate(90deg);display:flex;align-items:center;"
            >
              ${DatabaseMoveLeft}
            </div>`,
                                    hide: ()=>columns.findIndex((v)=>v === this.column.id) === 0,
                                    select: ()=>{
                                        const index = columns.findIndex((v)=>v === this.column.id);
                                        const targetId = columns[index - 1];
                                        if (!targetId) {
                                            return;
                                        }
                                        this.view.columnMove(this.column.id, {
                                            id: targetId,
                                            before: true
                                        });
                                    }
                                },
                                {
                                    type: 'action',
                                    name: 'Move Down',
                                    icon: html` <div
              style="transform: rotate(90deg);display:flex;align-items:center;"
            >
              ${DatabaseMoveRight}
            </div>`,
                                    hide: ()=>columns.findIndex((v)=>v === this.column.id) === columns.length - 1,
                                    select: ()=>{
                                        const index = columns.findIndex((v)=>v === this.column.id);
                                        const targetId = columns[index + 1];
                                        if (!targetId) {
                                            return;
                                        }
                                        this.view.columnMove(this.column.id, {
                                            id: targetId,
                                            before: false
                                        });
                                    }
                                },
                                {
                                    type: 'group',
                                    name: 'operation',
                                    children: ()=>[
                                            {
                                                type: 'action',
                                                name: 'Delete Column',
                                                icon: DeleteIcon,
                                                hide: ()=>!this.column.delete || this.column.type === 'title',
                                                select: ()=>{
                                                    this.column.delete?.();
                                                },
                                                class: 'delete-item'
                                            }
                                        ]
                                }
                            ]
                        }
                    });
                };
                this.cell$ = computed(()=>{
                    return this.column.cellGet(this.rowId);
                });
                this.changeEditing = (editing)=>{
                    const selection = this.closest('affine-data-view-record-detail')?.selection;
                    if (selection) {
                        selection.selection = {
                            propertyId: this.column.id,
                            isEditing: editing
                        };
                    }
                };
                this.#___private_column_1 = (_initProto(this), _init_column(this));
                this.#___private_editing_2 = _init_editing(this, false);
                this.#___private_isFocus_3 = _init_isFocus(this, false);
                this.#___private_rowId_4 = _init_rowId(this);
                this.#___private_view_5 = _init_view(this);
            }
        }
    }
}();
export { _RecordField as RecordField };
