import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '@affine/i18n';
import { useContext } from 'react';
import { AffinePageReference } from '../../reference-link';
import { managerContext } from '../common';
import * as styles from './back-links-row.css';
export const BackLinksRow = ({ references, onClick })=>{
    const manager = useContext(managerContext);
    const t = useI18n();
    return _jsxs("div", {
        children: [
            _jsxs("div", {
                className: styles.title,
                children: [
                    t['com.affine.page-properties.backlinks'](),
                    " · ",
                    references.length
                ]
            }),
            references.map((link)=>_jsx(AffinePageReference, {
                    pageId: link.docId,
                    wrapper: (props)=>_jsx("div", {
                            className: styles.wrapper,
                            onClick: onClick,
                            ...props
                        }),
                    docCollection: manager.workspace.docCollection
                }, link.docId))
        ]
    });
};
