import { Service } from '../../../framework';
import { WorkspaceEngine } from '../entities/engine';
export class WorkspaceEngineService extends Service {
    get engine() {
        if (!this._engine) {
            this._engine = this.framework.createEntity(WorkspaceEngine, {
                engineProvider: this.workspaceScope.props.flavourProvider.getEngineProvider(this.workspaceScope.props.openOptions.metadata.id)
            });
        }
        return this._engine;
    }
    constructor(workspaceScope){
        super();
        this.workspaceScope = workspaceScope;
        this._engine = null;
    }
}
