import { backoffRetry, catchErrorInto, effect, Entity, fromPromise, LiveData, mapInto, onComplete, onStart } from '@toeverything/infra';
import { exhaustMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../error';
export class SubscriptionPrices extends Entity {
    constructor(serverConfigService, store){
        super();
        this.serverConfigService = serverConfigService;
        this.store = store;
        this.prices$ = new LiveData(null);
        this.isRevalidating$ = new LiveData(false);
        this.error$ = new LiveData(null);
        this.proPrice$ = this.prices$.map((prices)=>prices ? prices.find((price)=>price.plan === 'Pro') : null);
        this.aiPrice$ = this.prices$.map((prices)=>prices ? prices.find((price)=>price.plan === 'AI') : null);
        this.readableLifetimePrice$ = this.proPrice$.map((price)=>price?.lifetimeAmount ? `$${(price.lifetimeAmount / 100).toFixed(2).replace(/\.0+$/, '')}` : '');
        this.revalidate = effect(exhaustMap(()=>{
            return fromPromise(async (signal)=>{
                this.serverConfigService.serverConfig.revalidateIfNeeded();
                const serverConfig = await this.serverConfigService.serverConfig.features$.waitForNonNull(signal);
                if (!serverConfig.payment) {
                    return [];
                }
                return this.store.fetchSubscriptionPrices(signal);
            }).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), mapInto(this.prices$), catchErrorInto(this.error$), onStart(()=>this.isRevalidating$.next(true)), onComplete(()=>this.isRevalidating$.next(false)));
        }));
    }
}
