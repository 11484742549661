function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _CaptionedBlockComponent;
import { RangeManager } from '@blocksuite/block-std';
import { Slice } from '@blocksuite/store';
import { computed } from '@lit-labs/preact-signals';
import { css, html, nothing, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { CaptionedBlockComponent, DragIndicator, popMenu, toast } from '../_common/components/index.js';
import { NOTE_SELECTOR } from '../_common/edgeless/note/consts.js';
import { CopyIcon, DeleteIcon, MoreHorizontalIcon } from '../_common/icons/index.js';
import { Rect } from '../_common/utils/index.js';
import { AffineDragHandleWidget, EdgelessRootBlockComponent } from '../root-block/index.js';
import { captureEventTarget, getDropResult } from '../root-block/widgets/drag-handle/utils.js';
import { AFFINE_INNER_MODAL_WIDGET } from '../root-block/widgets/inner-modal/inner-modal.js';
import './components/title/index.js';
import { DatabaseBlockDataSource } from './data-source.js';
import { dataViewCommonStyle } from './data-view/common/css-variable.js';
import { DataView, DatabaseSelection, defineUniComponent, renderUniLit, widgetPresets } from './data-view/index.js';
import { DatabaseBlockSchema } from './database-model.js';
let _DatabaseBlockComponent;
_dec = customElement('affine-database');
new class extends _identity {
    constructor(){
        super(_DatabaseBlockComponent), _initClass();
    }
    static{
        class DatabaseBlockComponent extends (_CaptionedBlockComponent = CaptionedBlockComponent) {
            static{
                ({ c: [_DatabaseBlockComponent, _initClass] } = _apply_decs_2203_r(this, [], [
                    _dec
                ], _CaptionedBlockComponent));
            }
            static{
                this.styles = css`
    ${unsafeCSS(dataViewCommonStyle('affine-database'))}
    affine-database {
      display: block;
      border-radius: 8px;
      background-color: var(--affine-background-primary-color);
      padding: 8px;
      margin: 8px -8px -8px;
    }

    .database-block-selected {
      background-color: var(--affine-hover-color);
      border-radius: 4px;
    }

    .database-ops {
      margin-top: 4px;
      padding: 2px;
      border-radius: 4px;
      display: flex;
      cursor: pointer;
    }

    .database-ops svg {
      width: 16px;
      height: 16px;
      color: var(--affine-icon-color);
    }

    .database-ops:hover {
      background-color: var(--affine-hover-color);
    }
  `;
            }
            renderDatabaseOps() {
                if (this.doc.readonly) {
                    return nothing;
                }
                return html` <div class="database-ops" @click="${this._clickDatabaseOps}">
      ${MoreHorizontalIcon}
    </div>`;
            }
            connectedCallback() {
                super.connectedCallback();
                this.setAttribute(RangeManager.rangeSyncExcludeAttr, 'true');
                let canDrop = false;
                this.disposables.add(AffineDragHandleWidget.registerOption({
                    flavour: DatabaseBlockSchema.model.flavour,
                    onDragMove: (state)=>{
                        const target = captureEventTarget(state.raw.target);
                        const view = this.view;
                        if (view && target instanceof HTMLElement && this.contains(target)) {
                            canDrop = view.showIndicator?.(state.raw) ?? false;
                            return false;
                        }
                        if (canDrop) {
                            view?.hideIndicator?.();
                            canDrop = false;
                        }
                        return false;
                    },
                    onDragEnd: ({ state, draggingElements })=>{
                        const target = state.raw.target;
                        const view = this.view;
                        if (canDrop && view && view.moveTo && target instanceof HTMLElement && this.parentElement?.contains(target)) {
                            const blocks = draggingElements.map((v)=>v.model);
                            this.doc.moveBlocks(blocks, this.model);
                            blocks.forEach((model)=>{
                                view.moveTo?.(model.id, state.raw);
                            });
                            view.hideIndicator?.();
                            return false;
                        }
                        if (canDrop) {
                            view?.hideIndicator?.();
                            canDrop = false;
                        }
                        return false;
                    }
                }));
            }
            renderBlock() {
                const peekViewService = this.getRootService().peekViewService;
                return html`
      <div
        contenteditable="false"
        style="position: relative;background-color: var(--affine-background-primary-color);border-radius: 4px"
      >
        ${this.dataView.render({
                    bindHotkey: this._bindHotkey,
                    handleEvent: this._handleEvent,
                    selection$: this.viewSelection$,
                    setSelection: this.setSelection,
                    dataSource: this.dataSource,
                    headerWidget: this.headerWidget,
                    onDrag: this.onDrag,
                    std: this.std,
                    detailPanelConfig: {
                        openDetailPanel: peekViewService ? (target, template)=>peekViewService.peek(target, template) : undefined,
                        target: ()=>this.innerModalWidget.target
                    }
                })}
      </div>
    `;
            }
            get dataSource() {
                if (!this._dataSource) {
                    this._dataSource = new DatabaseBlockDataSource(this.host, {
                        pageId: this.host.doc.id,
                        blockId: this.model.id
                    });
                }
                return this._dataSource;
            }
            get innerModalWidget() {
                return this.rootComponent.widgetComponents[AFFINE_INNER_MODAL_WIDGET];
            }
            get topContenteditableElement() {
                if (this.rootComponent instanceof EdgelessRootBlockComponent) {
                    const note = this.closest(NOTE_SELECTOR);
                    return note;
                }
                return this.rootComponent;
            }
            get view() {
                return this.dataView.expose;
            }
            constructor(...args){
                super(...args);
                this._bindHotkey = (hotkeys)=>{
                    return {
                        dispose: this.host.event.bindHotkey(hotkeys, {
                            path: this.topContenteditableElement?.path ?? this.path
                        })
                    };
                };
                this._clickDatabaseOps = (e)=>{
                    popMenu(e.currentTarget, {
                        options: {
                            input: {
                                initValue: this.model.title.toString(),
                                placeholder: 'Untitled',
                                onComplete: (text)=>{
                                    this.model.title.replace(0, this.model.title.length, text);
                                }
                            },
                            items: [
                                {
                                    type: 'action',
                                    icon: CopyIcon,
                                    name: 'Copy',
                                    select: ()=>{
                                        const slice = Slice.fromModels(this.doc, [
                                            this.model
                                        ]);
                                        this.std.clipboard.copySlice(slice).then(()=>{
                                            toast(this.host, 'Copied to clipboard');
                                        }).catch(console.error);
                                    }
                                },
                                {
                                    type: 'group',
                                    name: '',
                                    children: ()=>[
                                            {
                                                type: 'action',
                                                icon: DeleteIcon,
                                                class: 'delete-item',
                                                name: 'Delete Database',
                                                select: ()=>{
                                                    this.model.children.slice().forEach((block)=>{
                                                        this.doc.deleteBlock(block);
                                                    });
                                                    this.doc.deleteBlock(this.model);
                                                }
                                            }
                                        ]
                                }
                            ]
                        }
                    });
                };
                this._handleEvent = (name, handler)=>{
                    return {
                        dispose: this.host.event.add(name, handler, {
                            path: this.path
                        })
                    };
                };
                this.dataView = new DataView();
                this.renderTitle = (dataViewMethod)=>{
                    const addRow = ()=>dataViewMethod.addRow?.('start');
                    return html` <affine-database-title
      style="overflow: hidden"
      .titleText="${this.model.title}"
      .readonly="${this.doc.readonly}"
      .onPressEnterKey="${addRow}"
    ></affine-database-title>`;
                };
                this.getRootService = ()=>{
                    return this.std.spec.getService('affine:page');
                };
                this.headerWidget = defineUniComponent((props)=>{
                    return html`
        <div style="margin-bottom: 16px;display:flex;flex-direction: column">
          <div style="display:flex;gap:8px;padding: 0 6px;margin-bottom: 8px;">
            ${this.renderTitle(props.viewMethods)} ${this.renderDatabaseOps()}
          </div>
          <div
            style="display:flex;align-items:center;justify-content: space-between;gap: 12px"
          >
            <div style="flex:1">
              ${renderUniLit(widgetPresets.viewBar, props)}
            </div>
            ${renderUniLit(this.toolsWidget, props)}
          </div>
          ${renderUniLit(widgetPresets.filterBar, props)}
        </div>
      `;
                });
                this.indicator = new DragIndicator();
                this.onDrag = (evt, id)=>{
                    const result = getDropResult(evt);
                    if (result && result.rect) {
                        document.body.append(this.indicator);
                        this.indicator.rect = Rect.fromLWTH(result.rect.left, result.rect.width, result.rect.top, result.rect.height);
                        return ()=>{
                            this.indicator.remove();
                            const model = this.doc.getBlock(id).model;
                            const target = this.doc.getBlock(result.dropBlockId).model;
                            let parent = this.doc.getParent(result.dropBlockId);
                            const shouldInsertIn = result.dropType === 'in';
                            if (shouldInsertIn) {
                                parent = target;
                            }
                            if (model && target && parent) {
                                if (shouldInsertIn) {
                                    this.doc.moveBlocks([
                                        model
                                    ], parent);
                                } else {
                                    this.doc.moveBlocks([
                                        model
                                    ], parent, target, result.dropType === 'before');
                                }
                            }
                        };
                    }
                    this.indicator.remove();
                    return ()=>{};
                };
                this.setSelection = (selection)=>{
                    this.selection.setGroup('note', selection ? [
                        new DatabaseSelection({
                            blockId: this.blockId,
                            viewSelection: selection
                        })
                    ] : []);
                };
                this.toolsWidget = widgetPresets.createTools({
                    table: [
                        widgetPresets.tools.filter,
                        widgetPresets.tools.expand,
                        widgetPresets.tools.search,
                        widgetPresets.tools.viewOptions,
                        widgetPresets.tools.tableAddRow
                    ],
                    kanban: [
                        widgetPresets.tools.filter,
                        widgetPresets.tools.expand,
                        widgetPresets.tools.search,
                        widgetPresets.tools.viewOptions
                    ]
                });
                this.viewSelection$ = computed(()=>{
                    const databaseSelection = this.selection.value.find((selection)=>{
                        if (selection.blockId !== this.blockId) {
                            return false;
                        }
                        return selection instanceof DatabaseSelection;
                    });
                    return databaseSelection?.viewSelection;
                });
            }
        }
    }
}();
export { _DatabaseBlockComponent as DatabaseBlockComponent };
