import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createReactComponentFromLit, useConfirmModal, useLitPortalFactory } from '@affine/component';
import { useJournalInfoHelper } from '@affine/core/hooks/use-journal';
import { PeekViewService } from '@affine/core/modules/peek-view';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { DocMetaTags, DocTitle, EdgelessEditor, PageEditor } from '@blocksuite/presets';
import { DocService, DocsService, useFramework, useLiveData, useService } from '@toeverything/infra';
import React, { forwardRef, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PagePropertiesTable } from '../../affine/page-properties';
import { AffinePageReference } from '../../affine/reference-link';
import { BiDirectionalLinkPanel } from './bi-directional-link-panel';
import { BlocksuiteEditorJournalDocTitle } from './journal-doc-title';
import { patchDocModeService, patchEdgelessClipboard, patchForSharedPage, patchNotificationService, patchPeekViewService, patchQuickSearchService, patchReferenceRenderer } from './specs/custom/spec-patchers';
import { createEdgelessModeSpecs } from './specs/edgeless';
import { createPageModeSpecs } from './specs/page';
import * as styles from './styles.css';
const adapted = {
    DocEditor: createReactComponentFromLit({
        react: React,
        elementClass: PageEditor
    }),
    DocTitle: createReactComponentFromLit({
        react: React,
        elementClass: DocTitle
    }),
    PageMetaTags: createReactComponentFromLit({
        react: React,
        elementClass: DocMetaTags
    }),
    EdgelessEditor: createReactComponentFromLit({
        react: React,
        elementClass: EdgelessEditor
    })
};
const usePatchSpecs = (page, shared, mode)=>{
    const [reactToLit, portals] = useLitPortalFactory();
    const peekViewService = useService(PeekViewService);
    const docService = useService(DocService);
    const docsService = useService(DocsService);
    const framework = useFramework();
    const referenceRenderer = useMemo(()=>{
        return function customReference(reference) {
            const pageId = reference.delta.attributes?.reference?.pageId;
            if (!pageId) return _jsx("span", {});
            return _jsx(AffinePageReference, {
                docCollection: page.collection,
                pageId: pageId
            });
        };
    }, [
        page.collection
    ]);
    const specs = useMemo(()=>{
        return mode === 'edgeless' ? createEdgelessModeSpecs(framework) : createPageModeSpecs(framework);
    }, [
        mode,
        framework
    ]);
    const confirmModal = useConfirmModal();
    const patchedSpecs = useMemo(()=>{
        let patched = patchReferenceRenderer(specs, reactToLit, referenceRenderer);
        patched = patchNotificationService(patchReferenceRenderer(patched, reactToLit, referenceRenderer), confirmModal);
        patched = patchPeekViewService(patched, peekViewService);
        patched = patchEdgelessClipboard(patched);
        if (!page.readonly) {
            patched = patchQuickSearchService(patched, framework);
        }
        if (shared) {
            patched = patchForSharedPage(patched);
        }
        patched = patchDocModeService(patched, docService, docsService);
        return patched;
    }, [
        confirmModal,
        docService,
        docsService,
        framework,
        page.readonly,
        peekViewService,
        reactToLit,
        referenceRenderer,
        shared,
        specs
    ]);
    return [
        patchedSpecs,
        useMemo(()=>_jsx(_Fragment, {
                children: portals.map((p)=>_jsx(Fragment, {
                        children: p.portal
                    }, p.id))
            }), [
            portals
        ])
    ];
};
export const BlocksuiteDocEditor = forwardRef(function BlocksuiteDocEditor({ page, shared }, ref) {
    const titleRef = useRef(null);
    const docRef = useRef(null);
    const [docPage, setDocPage] = useState();
    const { isJournal } = useJournalInfoHelper(page.collection, page.id);
    const workbench = useService(WorkbenchService).workbench;
    const activeView = useLiveData(workbench.activeView$);
    const hash = useLiveData(activeView.location$).hash;
    const onDocRef = useCallback((el)=>{
        docRef.current = el;
        if (ref) {
            if (typeof ref === 'function') {
                ref(el);
            } else {
                ref.current = el;
            }
        }
    }, [
        ref
    ]);
    useEffect(()=>{
        setTimeout(()=>{
            const docPage = docRef.current?.querySelector('affine-page-root');
            if (docPage) {
                setDocPage(docPage);
            }
            if (titleRef.current && !hash) {
                const richText = titleRef.current.querySelector('rich-text');
                richText?.inlineEditor?.focusEnd();
            } else {
                docPage?.focusFirstParagraph();
            }
        });
    }, []);
    const [specs, portals] = usePatchSpecs(page, !!shared, 'page');
    return _jsxs(_Fragment, {
        children: [
            _jsxs("div", {
                className: styles.affineDocViewport,
                style: {
                    height: '100%'
                },
                children: [
                    !isJournal ? _jsx(adapted.DocTitle, {
                        doc: page,
                        ref: titleRef
                    }) : _jsx(BlocksuiteEditorJournalDocTitle, {
                        page: page
                    }),
                    _jsx(PagePropertiesTable, {
                        docId: page.id,
                        page: page
                    }),
                    _jsx(adapted.DocEditor, {
                        className: styles.docContainer,
                        ref: onDocRef,
                        doc: page,
                        specs: specs,
                        hasViewport: false
                    }),
                    docPage && !page.readonly ? _jsx("div", {
                        className: styles.docEditorGap,
                        onClick: ()=>{
                            docPage.std.spec.getService('affine:page').appendParagraph();
                        }
                    }) : null,
                    !page.readonly ? _jsx(BiDirectionalLinkPanel, {}) : null
                ]
            }),
            portals
        ]
    });
});
export const BlocksuiteEdgelessEditor = forwardRef(function BlocksuiteEdgelessEditor({ page, shared }, ref) {
    const [specs, portals] = usePatchSpecs(page, !!shared, 'edgeless');
    const editorRef = useRef(null);
    const onDocRef = useCallback((el)=>{
        editorRef.current = el;
        if (ref) {
            if (typeof ref === 'function') {
                ref(el);
            } else {
                ref.current = el;
            }
        }
    }, [
        ref
    ]);
    useEffect(()=>{
        if (editorRef.current) {
            editorRef.current.updateComplete.then(()=>{
                editorRef.current?.querySelector('affine-edgeless-root')?.click();
            }).catch(console.error);
        }
    }, []);
    return _jsxs(_Fragment, {
        children: [
            _jsx(adapted.EdgelessEditor, {
                ref: onDocRef,
                doc: page,
                specs: specs
            }),
            portals
        ]
    });
});
