export function initEmptyPage(page, title) {
    page.load(()=>{
        const pageBlockId = page.addBlock('affine:page', {
            title: new page.Text(title ?? '')
        });
        page.addBlock('affine:surface', {}, pageBlockId);
        const noteBlockId = page.addBlock('affine:note', {}, pageBlockId);
        page.addBlock('affine:paragraph', {}, noteBlockId);
        page.history.clear();
    });
}
