import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import '@affine/component/theme/global.css';
import '@affine/component/theme/theme.css';
import { NotificationCenter } from '@affine/component';
import { AffineContext } from '@affine/component/context';
import { GlobalLoading } from '@affine/component/global-loading';
import { AppFallback } from '@affine/core/components/affine/app-container';
import { configureCommonModules } from '@affine/core/modules';
import { configureLocalStorageStateStorageImpls } from '@affine/core/modules/storage';
import { configureBrowserWorkbenchModule } from '@affine/core/modules/workbench';
import { configureBrowserWorkspaceFlavours, configureIndexedDBWorkspaceEngineStorageProvider } from '@affine/core/modules/workspace-engine';
import { router } from '@affine/core/router';
import { performanceLogger, performanceRenderLogger } from '@affine/core/shared';
import { Telemetry } from '@affine/core/telemetry';
import createEmotionCache from '@affine/core/utils/create-emotion-cache';
import { createI18n, setUpLanguage } from '@affine/i18n';
import { CacheProvider } from '@emotion/react';
import { Framework, FrameworkRoot, getCurrentStore, LifecycleService } from '@toeverything/infra';
import { lazy, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
if (!environment.isBrowser && environment.isDebug) {
    document.body.innerHTML = `<h1 style="color:red;font-size:5rem;text-align:center;">Don't run web entry in electron.</h1>`;
    throw new Error('Wrong distribution');
}
const performanceI18nLogger = performanceLogger.namespace('i18n');
const cache = createEmotionCache();
const DevTools = lazy(()=>import('jotai-devtools').then((m)=>({
            default: m.DevTools
        })));
const DebugProvider = ({ children })=>{
    return _jsxs(_Fragment, {
        children: [
            _jsx(Suspense, {
                children: process.env.DEBUG_JOTAI === 'true' && _jsx(DevTools, {})
            }),
            children
        ]
    });
};
const future = {
    v7_startTransition: true
};
async function loadLanguage() {
    performanceI18nLogger.info('start');
    const i18n = createI18n();
    document.documentElement.lang = i18n.language;
    performanceI18nLogger.info('set up');
    await setUpLanguage(i18n);
    performanceI18nLogger.info('done');
}
let languageLoadingPromise = null;
const framework = new Framework();
configureCommonModules(framework);
configureBrowserWorkbenchModule(framework);
configureLocalStorageStateStorageImpls(framework);
configureBrowserWorkspaceFlavours(framework);
configureIndexedDBWorkspaceEngineStorageProvider(framework);
const frameworkProvider = framework.provider();
window.addEventListener('focus', ()=>{
    frameworkProvider.get(LifecycleService).applicationFocus();
});
frameworkProvider.get(LifecycleService).applicationStart();
export function App() {
    performanceRenderLogger.debug('App');
    if (!languageLoadingPromise) {
        languageLoadingPromise = loadLanguage().catch(console.error);
    }
    return _jsx(Suspense, {
        children: _jsx(FrameworkRoot, {
            framework: frameworkProvider,
            children: _jsx(CacheProvider, {
                value: cache,
                children: _jsxs(AffineContext, {
                    store: getCurrentStore(),
                    children: [
                        _jsx(Telemetry, {}),
                        _jsxs(DebugProvider, {
                            children: [
                                _jsx(GlobalLoading, {}),
                                _jsx(NotificationCenter, {}),
                                _jsx(RouterProvider, {
                                    fallbackElement: _jsx(AppFallback, {}, "RouterFallback"),
                                    router: router,
                                    future: future
                                })
                            ]
                        })
                    ]
                })
            })
        })
    });
}
