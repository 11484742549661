import { jsx as _jsx } from "react/jsx-runtime";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useMenuItem } from './use-menu-item';
export const MenuItem = ({ children: propsChildren, type = 'default', className: propsClassName, preFix, endFix, checked, selected, block, ...otherProps })=>{
    const { className, children } = useMenuItem({
        children: propsChildren,
        className: propsClassName,
        type,
        preFix,
        endFix,
        checked,
        selected,
        block
    });
    return _jsx(DropdownMenu.Item, {
        className: className,
        ...otherProps,
        children: children
    });
};
