import { Service } from '../../../framework';
export class WorkspaceFactoryService extends Service {
    constructor(providers){
        super();
        this.providers = providers;
        this.create = async (flavour, initial = ()=>Promise.resolve())=>{
            const provider = this.providers.find((x)=>x.flavour === flavour);
            if (!provider) {
                throw new Error(`Unknown workspace flavour: ${flavour}`);
            }
            const metadata = await provider.createWorkspace(initial);
            return metadata;
        };
    }
}
