function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _init__menuContainer, _init__slideMenuContent, _init_height, _init_showNext, _init_showPrevious, _init_toolbarSlots, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { consume } from '@lit/context';
import { LitElement, css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ArrowRightSmallIcon } from '../../../../../_common/icons/index.js';
import { edgelessToolbarSlotsContext } from '../context.js';
let _EdgelessSlideMenu;
_dec = customElement('edgeless-slide-menu'), _dec1 = query('.menu-container'), _dec2 = query('.slide-menu-content'), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = consume({
    context: edgelessToolbarSlotsContext
});
new class extends _identity {
    constructor(){
        super(_EdgelessSlideMenu), _initClass();
    }
    static{
        class EdgelessSlideMenu extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init__menuContainer, _init__slideMenuContent, _init_height, _init_showNext, _init_showPrevious, _init_toolbarSlots, _initProto], c: [_EdgelessSlideMenu, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_menuContainer"
                    ],
                    [
                        _dec2,
                        1,
                        "_slideMenuContent"
                    ],
                    [
                        _dec3,
                        1,
                        "height"
                    ],
                    [
                        _dec4,
                        1,
                        "showNext"
                    ],
                    [
                        _dec5,
                        1,
                        "showPrevious"
                    ],
                    [
                        _dec6,
                        1,
                        "toolbarSlots"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    :host {
      max-width: 100%;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    .slide-menu-wrapper {
      position: relative;
    }
    .menu-container {
      background: var(--affine-background-overlay-panel-color);
      border-radius: 8px 8px 0 0;
      border: 1px solid var(--affine-border-color);
      border-bottom: none;
      display: flex;
      align-items: center;
      width: fit-content;
      max-width: 100%;
      overflow-x: auto;
      overscroll-behavior: none;
      scrollbar-width: none;
      position: relative;
      height: calc(var(--menu-height) + 1px);
      box-sizing: border-box;
      padding: 0 10px;
      scroll-snap-type: x mandatory;
    }
    .slide-menu-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      transition: left 0.5s ease-in-out;
    }
    .next-slide-button,
    .previous-slide-button {
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid var(--affine-border-color);
      background: var(--affine-background-overlay-panel-color);
      box-shadow: var(--affine-shadow-2);
      color: var(--affine-icon-color);
      transition:
        transform 0.3s ease-in-out,
        opacity 0.5s ease-in-out;
      z-index: 12;
    }
    .next-slide-button {
      opacity: 0;
      display: flex;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%) scale(0.5);
    }
    .next-slide-button:hover {
      cursor: pointer;
      transform: translate(50%, -50%) scale(1);
    }
    .previous-slide-button {
      opacity: 0;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }
    .previous-slide-button:hover {
      cursor: pointer;
      transform: translate(-50%, -50%) scale(1);
    }
    .previous-slide-button svg {
      transform: rotate(180deg);
    }
  `;
            }
            _handleSlideButtonClick(direction) {
                const totalWidth = this._slideMenuContent.clientWidth;
                const currentScrollLeft = this._menuContainer.scrollLeft;
                const menuWidth = this._menuContainer.clientWidth;
                const newLeft = currentScrollLeft + (direction === 'left' ? -menuWidth : menuWidth);
                this._menuContainer.scrollTo({
                    left: Math.max(0, Math.min(newLeft, totalWidth)),
                    behavior: 'smooth'
                });
            }
            _handleWheel(event) {
                event.stopPropagation();
            }
            _toggleSlideButton() {
                const scrollLeft = this._menuContainer.scrollLeft;
                const menuWidth = this._menuContainer.clientWidth;
                const leftMin = 0;
                const leftMax = this._slideMenuContent.clientWidth - menuWidth + 2;
                this.showPrevious = scrollLeft > leftMin;
                this.showNext = scrollLeft < leftMax;
            }
            firstUpdated() {
                setTimeout(this._toggleSlideButton.bind(this), 0);
                this._disposables.addFromEvent(this._menuContainer, 'scrollend', ()=>{
                    this._toggleSlideButton();
                });
                this._disposables.add(this.toolbarSlots.resize.on(()=>this._toggleSlideButton()));
            }
            render() {
                return html`
      <div class="slide-menu-wrapper">
        <div
          class="previous-slide-button"
          @click=${()=>this._handleSlideButtonClick('left')}
          style=${styleMap({
                    opacity: this.showPrevious ? '1' : '0'
                })}
        >
          ${ArrowRightSmallIcon}
        </div>
        <div
          class="menu-container"
          style=${styleMap({
                    '--menu-height': this.height
                })}
        >
          <div class="slide-menu-content" @wheel=${this._handleWheel}>
            <slot></slot>
          </div>
        </div>
        <div
          style=${styleMap({
                    opacity: this.showNext ? '1' : '0'
                })}
          class="next-slide-button"
          @click=${()=>this._handleSlideButtonClick('right')}
        >
          ${ArrowRightSmallIcon}
        </div>
      </div>
    `;
            }
            #___private__menuContainer_1;
            get _menuContainer() {
                return this.#___private__menuContainer_1;
            }
            set _menuContainer(_v) {
                this.#___private__menuContainer_1 = _v;
            }
            #___private__slideMenuContent_2;
            get _slideMenuContent() {
                return this.#___private__slideMenuContent_2;
            }
            set _slideMenuContent(_v) {
                this.#___private__slideMenuContent_2 = _v;
            }
            #___private_height_3;
            get height() {
                return this.#___private_height_3;
            }
            set height(_v) {
                this.#___private_height_3 = _v;
            }
            #___private_showNext_4;
            get showNext() {
                return this.#___private_showNext_4;
            }
            set showNext(_v) {
                this.#___private_showNext_4 = _v;
            }
            #___private_showPrevious_5;
            get showPrevious() {
                return this.#___private_showPrevious_5;
            }
            set showPrevious(_v) {
                this.#___private_showPrevious_5 = _v;
            }
            #___private_toolbarSlots_6;
            get toolbarSlots() {
                return this.#___private_toolbarSlots_6;
            }
            set toolbarSlots(_v) {
                this.#___private_toolbarSlots_6 = _v;
            }
            constructor(...args){
                super(...args);
                this.#___private__menuContainer_1 = (_initProto(this), _init__menuContainer(this));
                this.#___private__slideMenuContent_2 = _init__slideMenuContent(this);
                this.#___private_height_3 = _init_height(this, '40px');
                this.#___private_showNext_4 = _init_showNext(this, false);
                this.#___private_showPrevious_5 = _init_showPrevious(this, false);
                this.#___private_toolbarSlots_6 = _init_toolbarSlots(this);
            }
        }
    }
}();
export { _EdgelessSlideMenu as EdgelessSlideMenu };
