import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowDownSmallIcon } from '@blocksuite/icons/rc';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { MenuIcon } from './menu-icon';
import * as styles from './styles.css';
import { triggerWidthVar } from './styles.css';
export const MenuTrigger = forwardRef(({ disabled, noBorder = false, className, status = 'default', size = 'default', preFix, endFix, block = false, children, width, style = {}, ...otherProps }, ref)=>{
    return _jsxs("button", {
        ref: ref,
        style: {
            ...assignInlineVars({
                [triggerWidthVar]: width ? typeof width === 'number' ? `${width}px` : width : 'auto'
            }),
            ...style
        },
        className: clsx(styles.menuTrigger, className, {
            block,
            disabled: disabled,
            'no-border': noBorder,
            error: status === 'error',
            success: status === 'success',
            warning: status === 'warning',
            default: status === 'default',
            large: size === 'large',
            'extra-large': size === 'extraLarge'
        }),
        ...otherProps,
        children: [
            preFix,
            children,
            endFix,
            _jsx(MenuIcon, {
                position: "end",
                children: _jsx(ArrowDownSmallIcon, {})
            })
        ]
    });
});
MenuTrigger.displayName = 'MenuTrigger';
