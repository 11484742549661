import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ScrollableContainer } from '@affine/component';
import { Divider } from '@affine/component/ui/divider';
import { WorkspaceList } from '@affine/component/workspace-list';
import { useEnableCloud } from '@affine/core/hooks/affine/use-enable-cloud';
import { useWorkspaceInfo, useWorkspaceName } from '@affine/core/hooks/use-workspace-info';
import { AuthService } from '@affine/core/modules/cloud';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { useI18n } from '@affine/i18n';
import { CloudWorkspaceIcon, LocalWorkspaceIcon } from '@blocksuite/icons/rc';
import { GlobalContextService, useLiveData, useService, WorkspacesService } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { openCreateWorkspaceModalAtom, openSettingModalAtom } from '../../../../../atoms';
import { WorkspaceSubPath } from '../../../../../shared';
import { useNavigateHelper } from '../.././../../../hooks/use-navigate-helper';
import * as styles from './index.css';
function useIsWorkspaceOwner(meta) {
    const info = useWorkspaceInfo(meta);
    return info?.isOwner;
}
const CloudWorkSpaceList = ({ disabled, workspaces, onClickWorkspace, onClickWorkspaceSetting, currentWorkspaceId })=>{
    const t = useI18n();
    if (workspaces.length === 0) {
        return null;
    }
    return _jsxs("div", {
        className: styles.workspaceListWrapper,
        children: [
            _jsxs("div", {
                className: styles.workspaceType,
                children: [
                    _jsx(CloudWorkspaceIcon, {
                        width: 14,
                        height: 14,
                        className: styles.workspaceTypeIcon
                    }),
                    t['com.affine.workspaceList.workspaceListType.cloud']()
                ]
            }),
            _jsx(WorkspaceList, {
                disabled: disabled,
                items: workspaces,
                currentWorkspaceId: currentWorkspaceId,
                onClick: onClickWorkspace,
                onSettingClick: onClickWorkspaceSetting,
                useIsWorkspaceOwner: useIsWorkspaceOwner,
                useWorkspaceName: useWorkspaceName
            })
        ]
    });
};
const LocalWorkspaces = ({ disabled, workspaces, onClickWorkspace, onClickWorkspaceSetting, onClickEnableCloud, openingId, currentWorkspaceId })=>{
    const t = useI18n();
    if (workspaces.length === 0) {
        return null;
    }
    return _jsxs("div", {
        className: styles.workspaceListWrapper,
        children: [
            _jsxs("div", {
                className: styles.workspaceType,
                children: [
                    _jsx(LocalWorkspaceIcon, {
                        width: 14,
                        height: 14,
                        className: styles.workspaceTypeIcon
                    }),
                    t['com.affine.workspaceList.workspaceListType.local']()
                ]
            }),
            _jsx(WorkspaceList, {
                openingId: openingId,
                disabled: disabled,
                items: workspaces,
                currentWorkspaceId: currentWorkspaceId,
                onClick: onClickWorkspace,
                onSettingClick: onClickWorkspaceSetting,
                onEnableCloudClick: onClickEnableCloud,
                useIsWorkspaceOwner: useIsWorkspaceOwner,
                useWorkspaceName: useWorkspaceName
            })
        ]
    });
};
export const AFFiNEWorkspaceList = ({ onEventEnd })=>{
    const workspacesService = useService(WorkspacesService);
    const workspaces = useLiveData(workspacesService.list.workspaces$);
    const currentWorkspaceId = useLiveData(useService(GlobalContextService).globalContext.workspaceId.$);
    const setOpenCreateWorkspaceModal = useSetAtom(openCreateWorkspaceModalAtom);
    const confirmEnableCloud = useEnableCloud();
    const { jumpToSubPath } = useNavigateHelper();
    const setOpenSettingModalAtom = useSetAtom(openSettingModalAtom);
    const session = useService(AuthService).session;
    const status = useLiveData(session.status$);
    const isAuthenticated = status === 'authenticated';
    const cloudWorkspaces = useMemo(()=>workspaces.filter(({ flavour })=>flavour === WorkspaceFlavour.AFFINE_CLOUD), [
        workspaces
    ]);
    const localWorkspaces = useMemo(()=>workspaces.filter(({ flavour })=>flavour === WorkspaceFlavour.LOCAL), [
        workspaces
    ]);
    const onClickWorkspaceSetting = useCallback((workspaceMetadata)=>{
        setOpenSettingModalAtom({
            open: true,
            activeTab: 'workspace:preference',
            workspaceMetadata
        });
        onEventEnd?.();
    }, [
        onEventEnd,
        setOpenSettingModalAtom
    ]);
    const onClickEnableCloud = useCallback((meta)=>{
        const { workspace, dispose } = workspacesService.open({
            metadata: meta
        });
        confirmEnableCloud(workspace, {
            onFinished: ()=>{
                dispose();
            }
        });
    }, [
        confirmEnableCloud,
        workspacesService
    ]);
    const onClickWorkspace = useCallback((workspaceMetadata)=>{
        if (document.startViewTransition) {
            document.startViewTransition(()=>{
                jumpToSubPath(workspaceMetadata.id, WorkspaceSubPath.Home);
                onEventEnd?.();
                return new Promise((resolve)=>setTimeout(resolve, 150));
            });
        } else {
            jumpToSubPath(workspaceMetadata.id, WorkspaceSubPath.Home);
            onEventEnd?.();
        }
    }, [
        jumpToSubPath,
        onEventEnd
    ]);
    const onNewWorkspace = useCallback(()=>{
        setOpenCreateWorkspaceModal('new');
        onEventEnd?.();
    }, [
        onEventEnd,
        setOpenCreateWorkspaceModal
    ]);
    const onAddWorkspace = useCallback(()=>{
        setOpenCreateWorkspaceModal('add');
        onEventEnd?.();
    }, [
        onEventEnd,
        setOpenCreateWorkspaceModal
    ]);
    return _jsxs(ScrollableContainer, {
        className: styles.workspaceListsWrapper,
        scrollBarClassName: styles.scrollbar,
        children: [
            isAuthenticated ? _jsxs("div", {
                children: [
                    _jsx(CloudWorkSpaceList, {
                        workspaces: cloudWorkspaces,
                        onClickWorkspace: onClickWorkspace,
                        onClickWorkspaceSetting: onClickWorkspaceSetting,
                        onNewWorkspace: onNewWorkspace,
                        onAddWorkspace: onAddWorkspace,
                        currentWorkspaceId: currentWorkspaceId
                    }),
                    localWorkspaces.length > 0 && cloudWorkspaces.length > 0 ? _jsx(Divider, {
                        size: "thinner"
                    }) : null
                ]
            }) : null,
            _jsx(LocalWorkspaces, {
                workspaces: localWorkspaces,
                onClickWorkspace: onClickWorkspace,
                onClickWorkspaceSetting: onClickWorkspaceSetting,
                onClickEnableCloud: onClickEnableCloud,
                onNewWorkspace: onNewWorkspace,
                onAddWorkspace: onAddWorkspace,
                currentWorkspaceId: currentWorkspaceId
            })
        ]
    });
};
