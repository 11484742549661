import { Service } from '@toeverything/infra';
import { DocBacklinks } from '../entities/doc-backlinks';
import { DocLinks } from '../entities/doc-links';
export class DocLinksService extends Service {
    constructor(...args){
        super(...args);
        this.backlinks = this.framework.createEntity(DocBacklinks);
        this.links = this.framework.createEntity(DocLinks);
    }
}
