import { track } from '@affine/core/mixpanel';
import { WorkspacePropertiesAdapter } from '@affine/core/modules/properties';
import { I18n, i18nTime } from '@affine/i18n';
import { LinkedWidgetUtils } from '@blocksuite/blocks';
import { LinkedEdgelessIcon, LinkedPageIcon, TodayIcon } from '@blocksuite/icons/lit';
import { DocsService, WorkspaceService } from '@toeverything/infra';
export function createLinkedWidgetConfig(framework) {
    return {
        getMenus: (query, abort, editorHost, inlineEditor)=>{
            const currentWorkspace = framework.get(WorkspaceService).workspace;
            const rawMetas = currentWorkspace.docCollection.meta.docMetas;
            const adapter = framework.get(WorkspacePropertiesAdapter);
            const isJournal = (d)=>!!adapter.getJournalPageDateString(d.id);
            const docMetas = rawMetas.filter((meta)=>{
                if (isJournal(meta) && !meta.updatedDate) {
                    return false;
                }
                return !meta.trash;
            }).map((meta)=>{
                if (isJournal(meta)) {
                    const date = adapter.getJournalPageDateString(meta.id);
                    if (date) {
                        const title = i18nTime(date, {
                            absolute: {
                                accuracy: 'day'
                            }
                        });
                        return {
                            ...meta,
                            title
                        };
                    }
                }
                if (!meta.title) {
                    const title = I18n['Untitled']();
                    return {
                        ...meta,
                        title
                    };
                }
                return meta;
            }).filter(({ title })=>isFuzzyMatch(title, query));
            const MAX_DOCS = 6;
            const docsService = framework.get(DocsService);
            const isEdgeless = (d)=>docsService.list.getMode(d.id) === 'edgeless';
            return Promise.resolve([
                {
                    name: 'Link to Doc',
                    items: docMetas.map((doc)=>({
                            key: doc.id,
                            name: doc.title,
                            icon: isJournal(doc) ? TodayIcon() : isEdgeless(doc) ? LinkedEdgelessIcon() : LinkedPageIcon(),
                            action: ()=>{
                                abort();
                                LinkedWidgetUtils.insertLinkedNode({
                                    inlineEditor,
                                    docId: doc.id
                                });
                                track.doc.editor.atMenu.linkDoc();
                            }
                        })),
                    maxDisplay: MAX_DOCS,
                    overflowText: `${docMetas.length - MAX_DOCS} more docs`
                },
                LinkedWidgetUtils.createNewDocMenuGroup(query, abort, editorHost, inlineEditor)
            ]);
        }
    };
}
function isFuzzyMatch(name, query) {
    const pureName = name.trim().toLowerCase().split('').filter((char)=>char !== ' ').join('');
    const regex = new RegExp(query.split('').filter((char)=>char !== ' ').map((item)=>`${escapeRegExp(item)}.*`).join(''), 'i');
    return regex.test(pureName);
}
function escapeRegExp(input) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
