export * from './blocks/index.js';
export * from './editors/index.js';
export * from './fragments/index.js';
export * from './helpers/index.js';
const env = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : {};
const importIdentifier = '__ $BLOCKSUITE_EDITOR$ __';
if (env[importIdentifier] === true) {
    console.error('@blocksuite/presets was already imported. This breaks constructor checks and will lead to issues!');
}
if (typeof window === 'undefined') {
    throw new Error('Seems like you are importing @blocksuite/presets in SSR mode. Which is not supported for now.');
}
env[importIdentifier] = true;
