import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WorkspaceAvatar } from '@affine/component/workspace-avatar';
import { UNTITLED_WORKSPACE_NAME } from '@affine/env/constant';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { useI18n } from '@affine/i18n';
import { CollaborationIcon, SettingsIcon } from '@blocksuite/icons/rc';
import { useCallback } from 'react';
import { Button } from '../../../ui/button';
import { Skeleton } from '../../../ui/skeleton';
import * as styles from './styles.css';
export const WorkspaceCardSkeleton = ()=>{
    return _jsx("div", {
        children: _jsxs("div", {
            className: styles.card,
            "data-testid": "workspace-card",
            children: [
                _jsx(Skeleton, {
                    variant: "circular",
                    width: 28,
                    height: 28
                }),
                _jsx(Skeleton, {
                    variant: "rectangular",
                    height: 43,
                    width: 220,
                    style: {
                        marginLeft: '12px'
                    }
                })
            ]
        })
    });
};
export const WorkspaceCard = ({ onClick, onSettingClick, onEnableCloudClick, openingId, currentWorkspaceId, meta, isOwner = true, name })=>{
    const isLocal = meta.flavour === WorkspaceFlavour.LOCAL;
    const displayName = name ?? UNTITLED_WORKSPACE_NAME;
    const t = useI18n();
    const onEnableCloud = useCallback((e)=>{
        e.stopPropagation();
        onEnableCloudClick?.(meta);
    }, [
        meta,
        onEnableCloudClick
    ]);
    return _jsxs("div", {
        className: styles.card,
        "data-active": meta.id === currentWorkspaceId,
        "data-testid": "workspace-card",
        onClick: useCallback(()=>{
            onClick(meta);
        }, [
            onClick,
            meta
        ]),
        children: [
            _jsx(WorkspaceAvatar, {
                meta: meta,
                rounded: 3,
                size: 28,
                name: name,
                colorfulFallback: true
            }, meta.id),
            _jsxs("div", {
                className: styles.workspaceInfo,
                children: [
                    _jsx("div", {
                        className: styles.workspaceTitle,
                        children: displayName
                    }),
                    _jsxs("div", {
                        className: styles.actionButtons,
                        children: [
                            isLocal ? _jsx(Button, {
                                loading: !!openingId && openingId === meta.id,
                                disabled: !!openingId,
                                className: styles.showOnCardHover,
                                onClick: onEnableCloud,
                                children: t['ai.wemem.workspaceCard.enableCloud']()
                            }) : null,
                            isOwner ? null : _jsx(CollaborationIcon, {}),
                            _jsx("div", {
                                className: styles.settingButton,
                                onClick: (e)=>{
                                    e.stopPropagation();
                                    onSettingClick(meta);
                                },
                                children: _jsx(SettingsIcon, {
                                    width: 16,
                                    height: 16
                                })
                            })
                        ]
                    })
                ]
            })
        ]
    });
};
