import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSettingHelper } from '@affine/core/hooks/affine/use-app-setting-helper';
import { DocsService, GlobalContextService, useLiveData, useService } from '@toeverything/infra';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';
import { forwardRef } from 'react';
import { appSidebarOpenAtom } from '../app-sidebar';
import { appStyle, mainContainerStyle, toolStyle } from './index.css';
export const AppContainer = ({ resizing, useNoisyBackground, useBlurBackground, children, ...rest })=>{
    const noisyBackground = useNoisyBackground && environment.isDesktop;
    const blurBackground = environment.isDesktop && useBlurBackground;
    return _jsx("div", {
        ...rest,
        className: clsx(appStyle, {
            'noisy-background': noisyBackground,
            'blur-background': blurBackground
        }),
        "data-noise-background": noisyBackground,
        "data-is-resizing": resizing,
        "data-blur-background": blurBackground,
        children: children
    });
};
export const MainContainer = forwardRef(function MainContainer({ className, children, ...props }, ref) {
    const appSideBarOpen = useAtomValue(appSidebarOpenAtom);
    const { appSettings } = useAppSettingHelper();
    return _jsx("div", {
        ...props,
        className: clsx(mainContainerStyle, className),
        "data-is-desktop": environment.isDesktop,
        "data-transparent": false,
        "data-client-border": appSettings.clientBorder,
        "data-side-bar-open": appSideBarOpen,
        "data-testid": "main-container",
        ref: ref,
        children: _jsx("div", {
            className: mainContainerStyle,
            children: children
        })
    });
});
MainContainer.displayName = 'MainContainer';
export const ToolContainer = (props)=>{
    const docId = useLiveData(useService(GlobalContextService).globalContext.docId.$);
    const docRecordList = useService(DocsService).list;
    const doc = useLiveData(docId ? docRecordList.doc$(docId) : undefined);
    const inTrash = useLiveData(doc?.meta$)?.trash;
    return _jsx("div", {
        className: clsx(toolStyle, {
            trash: inTrash
        }, props.className),
        children: props.children
    });
};
