import { REQUEST_IDLE_CALLBACK_ENABLED } from '@blocksuite/global/env';
import { assertExists } from '@blocksuite/global/utils';
import * as Y from 'yjs';
import { isMaybeInlineRangeEqual } from '../utils/inline-range.js';
import { domRangeToInlineRange, inlineRangeToDomRange } from '../utils/range-conversion.js';
import { calculateTextLength, getTextNodesFromElement } from '../utils/text.js';
export class RangeService {
    constructor(editor){
        this.editor = editor;
        this._applyInlineRange = (inlineRange)=>{
            const selection = document.getSelection();
            if (!selection) {
                return;
            }
            try {
                const newRange = this.toDomRange(inlineRange);
                if (!newRange) {
                    return;
                }
                selection.removeAllRanges();
                selection.addRange(newRange);
                this.editor.slots.inlineRangeApply.emit(newRange);
            } catch (error) {
                console.error('failed to apply inline range');
                console.error(error);
            }
        };
        this._inlineRange = null;
        this._lastEndRelativePosition = null;
        this._lastStartRelativePosition = null;
        this.focusEnd = ()=>{
            this.setInlineRange({
                index: this.editor.yTextLength,
                length: 0
            });
        };
        this.focusIndex = (index)=>{
            this.setInlineRange({
                index,
                length: 0
            });
        };
        this.focusStart = ()=>{
            this.setInlineRange({
                index: 0,
                length: 0
            });
        };
        this.getInlineRange = ()=>{
            if (this.inlineRangeProvider) {
                return this.inlineRangeProvider.getInlineRange();
            }
            return this._inlineRange;
        };
        this.getInlineRangeFromElement = (element)=>{
            const range = document.createRange();
            const text = element.querySelector('[data-v-text]');
            if (!text) {
                return null;
            }
            const textNode = text.childNodes[1];
            assertExists(textNode instanceof Text);
            range.setStart(textNode, 0);
            range.setEnd(textNode, textNode.textContent?.length ?? 0);
            const inlineRange = this.toInlineRange(range);
            return inlineRange;
        };
        this.isFirstLine = (inlineRange)=>{
            if (!inlineRange || inlineRange.length > 0) return false;
            const range = this.toDomRange(inlineRange);
            if (!range) {
                console.error('failed to convert inline range to domRange');
                return false;
            }
            const beforeText = this.editor.yTextString.slice(0, inlineRange.index);
            if (beforeText.includes('\n')) {
                return false;
            }
            const container = range.commonAncestorContainer.parentElement;
            assertExists(container);
            const containerRect = container.getBoundingClientRect();
            const rangeRects = range.getClientRects();
            const rangeRect = rangeRects[rangeRects.length - 1];
            const tolerance = 1;
            return Math.abs(rangeRect.top - containerRect.top) < tolerance;
        };
        this.isLastLine = (inlineRange)=>{
            if (!inlineRange || inlineRange.length > 0) return false;
            const afterText = this.editor.yTextString.slice(inlineRange.index);
            if (afterText.includes('\n')) {
                return false;
            }
            const range = this.toDomRange(inlineRange);
            if (!range) {
                console.error('failed to convert inline range to domRange');
                return false;
            }
            const container = range.commonAncestorContainer.parentElement;
            assertExists(container);
            const containerRect = container.getBoundingClientRect();
            const rangeRects = range.getClientRects();
            const rangeRect = rangeRects[rangeRects.length - 1];
            const tolerance = 1;
            return Math.abs(rangeRect.bottom - containerRect.bottom) < tolerance;
        };
        this.isValidInlineRange = (inlineRange)=>{
            return !(inlineRange && (inlineRange.index < 0 || inlineRange.index + inlineRange.length > this.editor.yText.length));
        };
        this.onInlineRangeUpdated = async ([newInlineRange, sync])=>{
            const eq = isMaybeInlineRangeEqual(this._inlineRange, newInlineRange);
            if (eq) {
                return;
            }
            this._inlineRange = newInlineRange;
            if (newInlineRange) {
                this._lastStartRelativePosition = Y.createRelativePositionFromTypeIndex(this.yText, newInlineRange.index);
                this._lastEndRelativePosition = Y.createRelativePositionFromTypeIndex(this.yText, newInlineRange.index + newInlineRange.length);
            } else {
                this._lastStartRelativePosition = null;
                this._lastEndRelativePosition = null;
            }
            if (this.editor.mounted) {
                await this.editor.waitForUpdate();
                if (REQUEST_IDLE_CALLBACK_ENABLED) {
                    requestIdleCallback(()=>{
                        this.editor.requestUpdate(false);
                    });
                } else {
                    Promise.resolve().then(()=>{
                        this.editor.requestUpdate(false);
                    }).catch(console.error);
                }
            }
            if (!sync) {
                return;
            }
            if (this._inlineRange === null) {
                const selection = document.getSelection();
                if (selection && selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    if (range.intersectsNode(this.editor.rootElement)) {
                        selection.removeAllRanges();
                    }
                }
                return;
            }
            const fn = ()=>{
                this.syncInlineRange();
            };
            requestAnimationFrame(fn);
        };
        this.selectAll = ()=>{
            this.setInlineRange({
                index: 0,
                length: this.editor.yTextLength
            });
        };
        this.setInlineRange = (inlineRange, sync = true)=>{
            if (!this.isValidInlineRange(inlineRange)) {
                console.error('invalid inline range');
                return;
            }
            if (this.inlineRangeProvider) {
                this.inlineRangeProvider.setInlineRange(inlineRange, sync);
                return;
            }
            this.editor.slots.inlineRangeUpdate.emit([
                inlineRange,
                sync
            ]);
        };
        this.syncInlineRange = ()=>{
            const inlineRange = this.getInlineRange();
            if (inlineRange && this.editor.mounted) {
                this._applyInlineRange(inlineRange);
            }
        };
        this.toDomRange = (inlineRange)=>{
            const rootElement = this.editor.rootElement;
            return inlineRangeToDomRange(rootElement, inlineRange);
        };
        this.toInlineRange = (range)=>{
            const { rootElement, yText } = this.editor;
            return domRangeToInlineRange(range, rootElement, yText);
        };
    }
    getLine(rangeIndex) {
        const lineElements = Array.from(this.rootElement.querySelectorAll('v-line'));
        let beforeIndex = 0;
        for (const [lineIndex, lineElement] of lineElements.entries()){
            if (rangeIndex >= beforeIndex && rangeIndex < beforeIndex + lineElement.vTextLength + 1) {
                return {
                    line: lineElement,
                    lineIndex,
                    rangeIndexRelatedToLine: rangeIndex - beforeIndex
                };
            }
            beforeIndex += lineElement.vTextLength + 1;
        }
        console.error('failed to find line');
        return null;
    }
    getNativeRange() {
        const selection = this.getNativeSelection();
        if (!selection) return null;
        return selection.getRangeAt(0);
    }
    getNativeSelection() {
        const selection = document.getSelection();
        if (!selection) return null;
        if (selection.rangeCount === 0) return null;
        return selection;
    }
    getTextPoint(rangeIndex) {
        const vLines = Array.from(this.rootElement.querySelectorAll('v-line'));
        let index = 0;
        for (const vLine of vLines){
            const texts = getTextNodesFromElement(vLine);
            if (texts.length === 0) {
                return null;
            }
            for (const text of texts){
                if (!text.textContent) {
                    return null;
                }
                if (index + text.textContent.length >= rangeIndex) {
                    return [
                        text,
                        rangeIndex - index
                    ];
                }
                index += calculateTextLength(text);
            }
            index += 1;
        }
        return null;
    }
    get inlineRangeProvider() {
        return this.editor.inlineRangeProvider;
    }
    get lastEndRelativePosition() {
        return this._lastEndRelativePosition;
    }
    get lastStartRelativePosition() {
        return this._lastStartRelativePosition;
    }
    get rootElement() {
        return this.editor.rootElement;
    }
    get yText() {
        return this.editor.yText;
    }
}
