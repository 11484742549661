import { BlockModel } from '@blocksuite/store';
import { GfxBlockModel } from '../../../root-block/edgeless/block-model.js';
export function GfxCompatible(BlockModelSuperClass) {
    if (BlockModelSuperClass === BlockModel) {
        return GfxBlockModel;
    } else {
        let currentClass = BlockModelSuperClass;
        while(Object.getPrototypeOf(currentClass.prototype) !== BlockModel.prototype && Object.getPrototypeOf(currentClass.prototype) !== null){
            currentClass = Object.getPrototypeOf(currentClass.prototype).constructor;
        }
        if (Object.getPrototypeOf(currentClass.prototype) === null) {
            throw new Error('The SuperClass is not a subclass of BlockModel');
        }
        Object.setPrototypeOf(currentClass.prototype, GfxBlockModel.prototype);
    }
    return BlockModelSuperClass;
}
