function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _init__shapePanel, _init_borderStyleButton, _init_edgeless, _init_elements, _init_fillColorButton, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { cache } from 'lit/directives/cache.js';
import { choose } from 'lit/directives/choose.js';
import { join } from 'lit/directives/join.js';
import { styleMap } from 'lit/directives/style-map.js';
import { when } from 'lit/directives/when.js';
import '../../../_common/components/toolbar/icon-button.js';
import '../../../_common/components/toolbar/menu-button.js';
import { renderToolbarSeparator } from '../../../_common/components/toolbar/separator.js';
import { AddTextIcon, ChangeShapeIcon, GeneralStyleIcon, ScribbledStyleIcon, SmallArrowDownIcon } from '../../../_common/icons/index.js';
import { LineWidth } from '../../../_common/types.js';
import { countBy, maxBy } from '../../../_common/utils/iterable.js';
import { FontFamily } from '../../../surface-block/consts.js';
import { DEFAULT_SHAPE_FILL_COLOR, DEFAULT_SHAPE_STROKE_COLOR, FILL_COLORS, STROKE_COLORS, ShapeType } from '../../../surface-block/elements/shape/consts.js';
import { ShapeStyle, StrokeStyle } from '../../../surface-block/index.js';
import '../../edgeless/components/color-picker/index.js';
import { packColor, packColorsWithColorScheme } from '../../edgeless/components/color-picker/utils.js';
import '../../edgeless/components/panel/color-panel.js';
import { GET_DEFAULT_LINE_COLOR, isTransparent } from '../../edgeless/components/panel/color-panel.js';
import { LineStylesPanel } from '../../edgeless/components/panel/line-styles-panel.js';
import '../../edgeless/components/panel/shape-panel.js';
import '../../edgeless/components/panel/shape-style-panel.js';
import '../../edgeless/components/panel/stroke-style-panel.js';
import { SHAPE_FILL_COLOR_BLACK, SHAPE_TEXT_COLOR_PURE_BLACK, SHAPE_TEXT_COLOR_PURE_WHITE } from '../../edgeless/utils/consts.js';
import { mountShapeTextEditor } from '../../edgeless/utils/text.js';
import './change-text-menu.js';
const changeShapeButtonStyles = [
    css`
    .edgeless-component-line-size-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
    }

    .edgeless-component-line-size-button div {
      border-radius: 50%;
      background-color: var(--affine-icon-color);
    }

    .edgeless-component-line-size-button.size-s div {
      width: 4px;
      height: 4px;
    }
    .edgeless-component-line-size-button.size-l div {
      width: 10px;
      height: 10px;
    }
  `
];
function getMostCommonFillColor(elements, colorScheme) {
    const colors = countBy(elements, (ele)=>{
        if (ele.filled) {
            return typeof ele.fillColor === 'object' ? ele.fillColor[colorScheme] ?? ele.fillColor.normal ?? null : ele.fillColor;
        }
        return '--affine-palette-transparent';
    });
    const max = maxBy(Object.entries(colors), ([_k, count])=>count);
    return max ? max[0] : null;
}
function getMostCommonStrokeColor(elements, colorScheme) {
    const colors = countBy(elements, (ele)=>{
        return typeof ele.strokeColor === 'object' ? ele.strokeColor[colorScheme] ?? ele.strokeColor.normal ?? null : ele.strokeColor;
    });
    const max = maxBy(Object.entries(colors), ([_k, count])=>count);
    return max ? max[0] : null;
}
function getMostCommonShape(elements) {
    const shapeTypes = countBy(elements, (ele)=>{
        return ele.shapeType === 'rect' && ele.radius ? 'roundedRect' : ele.shapeType;
    });
    const max = maxBy(Object.entries(shapeTypes), ([_k, count])=>count);
    return max ? max[0] : null;
}
function getMostCommonLineSize(elements) {
    const sizes = countBy(elements, (ele)=>{
        return ele.strokeWidth;
    });
    const max = maxBy(Object.entries(sizes), ([_k, count])=>count);
    return max ? Number(max[0]) : LineWidth.Four;
}
function getMostCommonLineStyle(elements) {
    const sizes = countBy(elements, (ele)=>ele.strokeStyle);
    const max = maxBy(Object.entries(sizes), ([_k, count])=>count);
    return max ? max[0] : null;
}
function getMostCommonShapeStyle(elements) {
    const roughnesses = countBy(elements, (ele)=>{
        return ele.shapeStyle;
    });
    const max = maxBy(Object.entries(roughnesses), ([_k, count])=>count);
    return max ? max[0] : ShapeStyle.Scribbled;
}
let _EdgelessChangeShapeButton;
_dec = customElement('edgeless-change-shape-button'), _dec1 = query('edgeless-shape-panel'), _dec2 = query('edgeless-color-picker-button.border-style'), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = query('edgeless-color-picker-button.fill-color');
new class extends _identity {
    constructor(){
        super(_EdgelessChangeShapeButton), _initClass();
    }
    static{
        class EdgelessChangeShapeButton extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init__shapePanel, _init_borderStyleButton, _init_edgeless, _init_elements, _init_fillColorButton, _initProto], c: [_EdgelessChangeShapeButton, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_shapePanel"
                    ],
                    [
                        _dec2,
                        1,
                        "borderStyleButton"
                    ],
                    [
                        _dec3,
                        1,
                        "edgeless"
                    ],
                    [
                        _dec4,
                        1,
                        "elements"
                    ],
                    [
                        _dec5,
                        1,
                        "fillColorButton"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = [
                    changeShapeButtonStyles
                ];
            }
            #pickColor(key) {
                return (event)=>{
                    if (event.type === 'pick') {
                        this.elements.forEach((ele)=>{
                            const props = packColor(key, {
                                ...event.detail
                            });
                            if (key === 'fillColor' && !ele.filled) {
                                Object.assign(props, {
                                    filled: true
                                });
                            }
                            this.service.updateElement(ele.id, props);
                        });
                        return;
                    }
                    this.elements.forEach((ele)=>ele[event.type === 'start' ? 'stash' : 'pop'](key));
                };
            }
            _addText() {
                mountShapeTextEditor(this.elements[0], this.edgeless);
            }
            _getTextColor(fillColor) {
                const textColor = isTransparent(fillColor) ? GET_DEFAULT_LINE_COLOR() : fillColor === SHAPE_FILL_COLOR_BLACK ? SHAPE_TEXT_COLOR_PURE_WHITE : SHAPE_TEXT_COLOR_PURE_BLACK;
                return textColor;
            }
            _setShapeFillColor(fillColor) {
                const filled = !isTransparent(fillColor);
                const color = this._getTextColor(fillColor);
                this.elements.forEach((ele)=>this.service.updateElement(ele.id, {
                        filled,
                        fillColor,
                        color
                    }));
            }
            _setShapeStrokeColor(strokeColor) {
                this.elements.forEach((ele)=>this.service.updateElement(ele.id, {
                        strokeColor
                    }));
            }
            _setShapeStrokeStyle(strokeStyle) {
                this.elements.forEach((ele)=>this.service.updateElement(ele.id, {
                        strokeStyle
                    }));
            }
            _setShapeStrokeWidth(strokeWidth) {
                this.elements.forEach((ele)=>this.service.updateElement(ele.id, {
                        strokeWidth
                    }));
            }
            _setShapeStyle(shapeStyle) {
                const fontFamily = shapeStyle === ShapeStyle.General ? FontFamily.Inter : FontFamily.Kalam;
                this.elements.forEach((ele)=>{
                    this.service.updateElement(ele.id, {
                        shapeStyle,
                        fontFamily
                    });
                });
            }
            _setShapeStyles({ type, value }) {
                if (type === 'size') {
                    this._setShapeStrokeWidth(value);
                    return;
                }
                if (type === 'lineStyle') {
                    this._setShapeStrokeStyle(value);
                }
            }
            _showAddButtonOrTextMenu() {
                if (this.elements.length === 1 && !this.elements[0].text) {
                    return 'button';
                }
                if (!this.elements.some((e)=>!e.text)) {
                    return 'menu';
                }
                return 'nothing';
            }
            firstUpdated() {
                const _disposables = this._disposables;
                _disposables.add(this._shapePanel.slots.select.on((shapeType)=>{
                    const updatedProps = shapeType === 'roundedRect' ? {
                        shapeType: ShapeType.Rect,
                        radius: 0.1
                    } : {
                        shapeType,
                        radius: 0
                    };
                    this.edgeless.doc.captureSync();
                    this.elements.forEach((element)=>{
                        this.service.updateElement(element.id, updatedProps);
                    });
                }));
            }
            render() {
                const colorScheme = this.edgeless.surface.renderer.getColorScheme();
                const elements = this.elements;
                const selectedShape = getMostCommonShape(elements);
                const selectedFillColor = getMostCommonFillColor(elements, colorScheme) ?? DEFAULT_SHAPE_FILL_COLOR;
                const selectedStrokeColor = getMostCommonStrokeColor(elements, colorScheme) ?? DEFAULT_SHAPE_STROKE_COLOR;
                const selectedLineSize = getMostCommonLineSize(elements) ?? LineWidth.Four;
                const selectedLineStyle = getMostCommonLineStyle(elements) ?? StrokeStyle.Solid;
                const selectedShapeStyle = getMostCommonShapeStyle(elements) ?? ShapeStyle.Scribbled;
                return join([
                    html`
          <editor-menu-button
            .button=${html`
              <editor-icon-button
                aria-label="Switch type"
                .tooltip=${'Switch type'}
              >
                ${ChangeShapeIcon}${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <edgeless-shape-panel
              .selectedShape=${selectedShape}
              .shapeStyle=${selectedShapeStyle}
            >
            </edgeless-shape-panel>
          </editor-menu-button>
        `,
                    html`
          <editor-menu-button
            .button=${html`
              <editor-icon-button aria-label="Style" .tooltip=${'Style'}>
                ${cache(selectedShapeStyle === ShapeStyle.General ? GeneralStyleIcon : ScribbledStyleIcon)}
                ${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <edgeless-shape-style-panel
              .value=${selectedShapeStyle}
              .onSelect=${(value)=>this._setShapeStyle(value)}
            >
            </edgeless-shape-style-panel>
          </editor-menu-button>
        `,
                    when(this.edgeless.doc.awarenessStore.getFlag('enable_color_picker'), ()=>{
                        const { type, colors } = packColorsWithColorScheme(colorScheme, selectedFillColor, elements[0].fillColor);
                        return html`
              <edgeless-color-picker-button
                class="fill-color"
                .label=${'Fill color'}
                .pick=${this.#pickColor('fillColor')}
                .color=${selectedFillColor}
                .colors=${colors}
                .colorType=${type}
                .palettes=${FILL_COLORS}
              >
              </edgeless-color-picker-button>
            `;
                    }, ()=>html`
            <editor-menu-button
              .contentPadding=${'8px'}
              .button=${html`
                <editor-icon-button
                  aria-label="Fill color"
                  .tooltip=${'Fill color'}
                >
                  <edgeless-color-button
                    .color=${selectedFillColor}
                  ></edgeless-color-button>
                </editor-icon-button>
              `}
            >
              <edgeless-color-panel
                role="listbox"
                aria-label="Fill colors"
                .value=${selectedFillColor}
                .options=${FILL_COLORS}
                @select=${(e)=>this._setShapeFillColor(e.detail)}
              >
              </edgeless-color-panel>
            </editor-menu-button>
          `),
                    when(this.edgeless.doc.awarenessStore.getFlag('enable_color_picker'), ()=>{
                        const { type, colors } = packColorsWithColorScheme(colorScheme, selectedStrokeColor, elements[0].strokeColor);
                        return html`
              <edgeless-color-picker-button
                class="border-style"
                .label=${'Border style'}
                .pick=${this.#pickColor('strokeColor')}
                .color=${selectedStrokeColor}
                .colors=${colors}
                .colorType=${type}
                .palettes=${STROKE_COLORS}
                .hollowCircle=${true}
              >
                <div
                  slot="other"
                  class="line-styles"
                  style=${styleMap({
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            alignItems: 'center'
                        })}
                >
                  ${LineStylesPanel({
                            selectedLineSize: selectedLineSize,
                            selectedLineStyle: selectedLineStyle,
                            onClick: (e)=>this._setShapeStyles(e),
                            lineStyles: [
                                StrokeStyle.Solid,
                                StrokeStyle.Dash
                            ]
                        })}
                </div>
                <editor-toolbar-separator
                  slot="separator"
                  data-orientation="horizontal"
                ></editor-toolbar-separator>
              </edgeless-color-picker-button>
            `;
                    }, ()=>html`
            <editor-menu-button
              .contentPadding=${'8px'}
              .button=${html`
                <editor-icon-button
                  aria-label="Border style"
                  .tooltip=${'Border style'}
                >
                  <edgeless-color-button
                    .color=${selectedStrokeColor}
                    .hollowCircle=${true}
                  ></edgeless-color-button>
                </editor-icon-button>
              `}
            >
              <stroke-style-panel
                .hollowCircle=${true}
                .strokeWidth=${selectedLineSize}
                .strokeStyle=${selectedLineStyle}
                .strokeColor=${selectedStrokeColor}
                .setStrokeStyle=${(e)=>this._setShapeStyles(e)}
                .setStrokeColor=${(e)=>this._setShapeStrokeColor(e.detail)}
              >
              </stroke-style-panel>
            </editor-menu-button>
          `),
                    choose(this._showAddButtonOrTextMenu(), [
                        [
                            'button',
                            ()=>html`
                <editor-icon-button
                  aria-label="Add text"
                  .tooltip=${'Add text'}
                  @click=${this._addText}
                >
                  ${AddTextIcon}
                </editor-icon-button>
              `
                        ],
                        [
                            'menu',
                            ()=>html`
                <edgeless-change-text-menu
                  .elementType=${'shape'}
                  .elements=${elements}
                  .edgeless=${this.edgeless}
                ></edgeless-change-text-menu>
              `
                        ],
                        [
                            'nothing',
                            ()=>nothing
                        ]
                    ])
                ].filter((button)=>button !== nothing), renderToolbarSeparator);
            }
            get service() {
                return this.edgeless.service;
            }
            #___private__shapePanel_1;
            get _shapePanel() {
                return this.#___private__shapePanel_1;
            }
            set _shapePanel(_v) {
                this.#___private__shapePanel_1 = _v;
            }
            #___private_borderStyleButton_2;
            get borderStyleButton() {
                return this.#___private_borderStyleButton_2;
            }
            set borderStyleButton(_v) {
                this.#___private_borderStyleButton_2 = _v;
            }
            #___private_edgeless_3;
            get edgeless() {
                return this.#___private_edgeless_3;
            }
            set edgeless(_v) {
                this.#___private_edgeless_3 = _v;
            }
            #___private_elements_4;
            get elements() {
                return this.#___private_elements_4;
            }
            set elements(_v) {
                this.#___private_elements_4 = _v;
            }
            #___private_fillColorButton_5;
            get fillColorButton() {
                return this.#___private_fillColorButton_5;
            }
            set fillColorButton(_v) {
                this.#___private_fillColorButton_5 = _v;
            }
            constructor(...args){
                super(...args);
                this.#___private__shapePanel_1 = (_initProto(this), _init__shapePanel(this));
                this.#___private_borderStyleButton_2 = _init_borderStyleButton(this);
                this.#___private_edgeless_3 = _init_edgeless(this);
                this.#___private_elements_4 = _init_elements(this, []);
                this.#___private_fillColorButton_5 = _init_fillColorButton(this);
            }
        }
    }
}();
export function renderChangeShapeButton(edgeless, elements) {
    if (!elements?.length) return nothing;
    if (elements.some((e)=>edgeless.service.surface.isInMindmap(e.id))) return nothing;
    return html`
    <edgeless-change-shape-button .elements=${elements} .edgeless=${edgeless}>
    </edgeless-change-shape-button>
  `;
}
export { _EdgelessChangeShapeButton as EdgelessChangeShapeButton };
