import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton } from '@affine/component';
import { ResizePanel } from '@affine/component/resize-panel';
import { useServiceOptional, WorkspaceService } from '@toeverything/infra';
import { useAtom, useAtomValue } from 'jotai';
import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { WorkspaceSelector } from '../workspace-selector';
import { fallbackHeaderStyle, fallbackStyle } from './fallback.css';
import { floatingMaxWidth, navBodyStyle, navHeaderStyle, navStyle, navWrapperStyle, sidebarFloatMaskStyle } from './index.css';
import { APP_SIDEBAR_OPEN, appSidebarFloatingAtom, appSidebarOpenAtom, appSidebarResizingAtom, appSidebarWidthAtom } from './index.jotai';
import { SidebarHeader } from './sidebar-header';
const MAX_WIDTH = 480;
const MIN_WIDTH = 248;
export function AppSidebar({ children, clientBorder }) {
    const [open, setOpen] = useAtom(appSidebarOpenAtom);
    const [width, setWidth] = useAtom(appSidebarWidthAtom);
    const [floating, setFloating] = useAtom(appSidebarFloatingAtom);
    const [resizing, setResizing] = useAtom(appSidebarResizingAtom);
    useEffect(()=>{
        if (environment.isDesktop) {
            return;
        }
        function onResize() {
            const isFloatingMaxWidth = window.matchMedia(`(max-width: ${floatingMaxWidth}px)`).matches;
            const isOverflowWidth = window.matchMedia(`(max-width: ${width / 0.4}px)`).matches;
            const isFloating = isFloatingMaxWidth || isOverflowWidth;
            if (open === undefined && localStorage.getItem(APP_SIDEBAR_OPEN) === null) {
                setOpen(!isFloating);
            }
            setFloating(isFloating);
        }
        const dOnResize = debounce(onResize, 50);
        window.addEventListener('resize', dOnResize);
        return ()=>{
            window.removeEventListener('resize', dOnResize);
        };
    }, [
        open,
        setFloating,
        setOpen,
        width
    ]);
    const hasRightBorder = !environment.isDesktop && !clientBorder;
    const isMacosDesktop = environment.isDesktop && environment.isMacOs;
    return _jsxs(_Fragment, {
        children: [
            _jsx(ResizePanel, {
                floating: floating,
                open: open,
                resizing: resizing,
                maxWidth: MAX_WIDTH,
                minWidth: MIN_WIDTH,
                width: width,
                resizeHandlePos: "right",
                onOpen: setOpen,
                onResizing: setResizing,
                onWidthChange: setWidth,
                className: navWrapperStyle,
                resizeHandleOffset: clientBorder ? 8 : 0,
                resizeHandleVerticalPadding: clientBorder ? 16 : 0,
                "data-transparent": true,
                "data-open": open,
                "data-has-border": hasRightBorder,
                "data-testid": "app-sidebar-wrapper",
                "data-is-macos-electron": isMacosDesktop,
                children: _jsxs("nav", {
                    className: navStyle,
                    "data-testid": "app-sidebar",
                    children: [
                        !environment.isDesktop && _jsx(SidebarHeader, {}),
                        _jsx("div", {
                            className: navBodyStyle,
                            "data-testid": "sliderBar-inner",
                            children: children
                        })
                    ]
                })
            }),
            _jsx("div", {
                "data-testid": "app-sidebar-float-mask",
                "data-open": open,
                "data-is-floating": floating,
                className: sidebarFloatMaskStyle,
                onClick: ()=>setOpen(false)
            })
        ]
    });
}
export const AppSidebarFallback = ()=>{
    const width = useAtomValue(appSidebarWidthAtom);
    const currentWorkspace = useServiceOptional(WorkspaceService);
    return _jsx("div", {
        style: {
            width
        },
        className: navWrapperStyle,
        "data-has-border": true,
        "data-open": "true",
        children: _jsxs("nav", {
            className: navStyle,
            children: [
                _jsx("div", {
                    className: navHeaderStyle,
                    "data-open": "true"
                }),
                _jsx("div", {
                    className: navBodyStyle,
                    children: _jsx("div", {
                        className: fallbackStyle,
                        children: _jsx("div", {
                            className: fallbackHeaderStyle,
                            children: currentWorkspace ? _jsx(WorkspaceSelector, {}) : _jsxs(_Fragment, {
                                children: [
                                    _jsx(Skeleton, {
                                        variant: "rectangular",
                                        width: 181,
                                        height: 36
                                    }),
                                    _jsx(Skeleton, {
                                        variant: "circular",
                                        width: 34,
                                        height: 34
                                    })
                                ]
                            })
                        })
                    })
                })
            ]
        })
    });
};
export * from './add-page-button';
export * from './app-download-button';
export * from './app-updater-button';
export * from './category-divider';
export * from './index.css';
export * from './menu-item';
export * from './quick-search-input';
export * from './sidebar-containers';
export * from './sidebar-header';
export { appSidebarFloatingAtom, appSidebarOpenAtom, appSidebarResizingAtom };
