import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, DatePicker, Menu } from '@affine/component';
import { i18nTime, useI18n } from '@affine/i18n';
import { DocService, useService } from '@toeverything/infra';
import { noop } from 'lodash-es';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { managerContext } from './common';
import * as styles from './styles.css';
import { TagsInlineEditor } from './tags-inline-editor';
export const DateValue = ({ property })=>{
    const displayValue = property.value ? i18nTime(property.value, {
        absolute: {
            accuracy: 'day'
        }
    }) : undefined;
    const manager = useContext(managerContext);
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
    }, []);
    const handleChange = useCallback((e)=>{
        manager.updateCustomProperty(property.id, {
            value: e
        });
    }, [
        manager,
        property.id
    ]);
    const t = useI18n();
    return _jsx(Menu, {
        items: _jsx(DatePicker, {
            value: property.value,
            onChange: handleChange
        }),
        children: _jsx("div", {
            onClick: handleClick,
            className: styles.propertyRowValueCell,
            "data-empty": !property.value,
            children: displayValue ?? t['com.affine.page-properties.property-value-placeholder']()
        })
    });
};
export const CheckboxValue = ({ property })=>{
    const manager = useContext(managerContext);
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
        manager.updateCustomProperty(property.id, {
            value: !property.value
        });
    }, [
        manager,
        property.id,
        property.value
    ]);
    return _jsx("div", {
        onClick: handleClick,
        className: styles.propertyRowValueCell,
        "data-empty": !property.value,
        children: _jsx(Checkbox, {
            className: styles.checkboxProperty,
            checked: !!property.value,
            onChange: noop
        })
    });
};
export const TextValue = ({ property })=>{
    const manager = useContext(managerContext);
    const [value, setValue] = useState(property.value);
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
    }, []);
    const ref = useRef(null);
    const handleBlur = useCallback((e)=>{
        manager.updateCustomProperty(property.id, {
            value: e.currentTarget.value.trim()
        });
    }, [
        manager,
        property.id
    ]);
    useEffect(()=>{
        ref.current?.addEventListener('blur', handleBlur);
        return ()=>{
            ref.current?.removeEventListener('blur', handleBlur);
        };
    }, [
        handleBlur
    ]);
    const handleOnChange = useCallback((e)=>{
        setValue(e.target.value);
    }, []);
    const t = useI18n();
    useEffect(()=>{
        setValue(property.value);
    }, [
        property.value
    ]);
    return _jsxs("div", {
        onClick: handleClick,
        className: styles.propertyRowValueTextCell,
        children: [
            _jsx("textarea", {
                ref: ref,
                className: styles.propertyRowValueTextarea,
                value: value || '',
                onChange: handleOnChange,
                onClick: handleClick,
                "data-empty": !value,
                placeholder: t['com.affine.page-properties.property-value-placeholder']()
            }),
            _jsxs("div", {
                className: styles.propertyRowValueTextareaInvisible,
                children: [
                    value,
                    value?.endsWith('\n') || !value ? _jsx("br", {}) : null
                ]
            })
        ]
    });
};
export const NumberValue = ({ property })=>{
    const manager = useContext(managerContext);
    const [value, setValue] = useState(property.value);
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
    }, []);
    const handleBlur = useCallback((e)=>{
        manager.updateCustomProperty(property.id, {
            value: e.target.value.trim()
        });
    }, [
        manager,
        property.id
    ]);
    const handleOnChange = useCallback((e)=>{
        setValue(e.target.value);
    }, []);
    const t = useI18n();
    useEffect(()=>{
        setValue(property.value);
    }, [
        property.value
    ]);
    return _jsx("input", {
        className: styles.propertyRowValueNumberCell,
        type: 'number',
        value: value || '',
        onChange: handleOnChange,
        onClick: handleClick,
        onBlur: handleBlur,
        "data-empty": !value,
        placeholder: t['com.affine.page-properties.property-value-placeholder']()
    });
};
export const TagsValue = ()=>{
    const doc = useService(DocService).doc;
    const t = useI18n();
    return _jsx(TagsInlineEditor, {
        className: styles.propertyRowValueCell,
        placeholder: t['com.affine.page-properties.property-value-placeholder'](),
        pageId: doc.id,
        readonly: doc.blockSuiteDoc.readonly
    });
};
export const propertyValueRenderers = {
    date: DateValue,
    checkbox: CheckboxValue,
    text: TextValue,
    number: NumberValue,
    tags: TagsValue,
    progress: TextValue
};
