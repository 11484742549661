function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _init_groupContainer, _init_onBack, _init_view, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import Sortable from 'sortablejs';
import { createPopup } from '../../../_common/components/index.js';
import { ArrowLeftBigIcon } from '../../../_common/icons/index.js';
const show = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.0004 5.75C8.06451 5.75 4.70095 8.20857 3.36528 11.6764C3.28512 11.8845 3.28512 12.1155 3.36527 12.3236C4.70093 15.7914 8.06451 18.25 12.0005 18.25C15.9364 18.25 19.2999 15.7914 20.6356 12.3236C20.7158 12.1155 20.7158 11.8846 20.6356 11.6764C19.3 8.2086 15.9364 5.75 12.0004 5.75ZM1.96552 11.1372C3.51675 7.10978 7.42369 4.25 12.0004 4.25C16.5772 4.25 20.4842 7.10982 22.0354 11.1373C22.2492 11.6924 22.2492 12.3077 22.0354 12.8628C20.4841 16.8902 16.5772 19.75 12.0005 19.75C7.42369 19.75 3.51672 16.8902 1.96551 12.8627C1.75171 12.3076 1.75171 11.6923 1.96552 11.1372ZM12 9.75C10.7574 9.75 9.74999 10.7574 9.74999 12C9.74999 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75ZM8.24999 12C8.24999 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.24999 14.0711 8.24999 12Z"
  />
</svg> `;
const hidden = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M5 4L20 19" stroke="white" stroke-width="2" stroke-linecap="round" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.59938 7.54961C4.35061 7.21842 3.88046 7.1516 3.54927 7.40037C3.21808 7.64913 3.15126 8.11928 3.40002 8.45047C4.2023 9.51858 5.19943 10.4327 6.33872 11.1399L4.37596 14.084C4.1462 14.4287 4.23933 14.8943 4.58397 15.1241C4.92862 15.3538 5.39427 15.2607 5.62404 14.9161L7.62404 11.9161C7.6403 11.8917 7.65495 11.8667 7.66801 11.8412C8.77638 12.3299 9.98299 12.6369 11.25 12.7243V17.5C11.25 17.9143 11.5858 18.25 12 18.25C12.4142 18.25 12.75 17.9143 12.75 17.5V12.7243C14.0171 12.637 15.2237 12.3301 16.3322 11.8415C16.3452 11.8669 16.3598 11.8918 16.376 11.9161L18.376 14.9161C18.6057 15.2607 19.0714 15.3538 19.416 15.1241C19.7607 14.8943 19.8538 14.4287 19.624 14.084L17.6615 11.1403C18.8011 10.433 19.7985 9.51878 20.6009 8.45047C20.8497 8.11928 20.7828 7.64913 20.4516 7.40037C20.1205 7.1516 19.6503 7.21842 19.4015 7.54961C17.7127 9.79805 15.0263 11.2501 12.0005 11.2501C8.97467 11.2501 6.28823 9.79805 4.59938 7.54961Z"
  />
</svg> `;
let _DataViewPropertiesSettingView;
_dec = customElement('data-view-properties-setting'), _dec1 = query('.properties-group'), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_DataViewPropertiesSettingView), _initClass();
    }
    static{
        class DataViewPropertiesSettingView extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_groupContainer, _init_onBack, _init_view, _initProto], c: [_DataViewPropertiesSettingView, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "groupContainer"
                    ],
                    [
                        _dec2,
                        1,
                        "onBack"
                    ],
                    [
                        _dec3,
                        1,
                        "view"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    data-view-properties-setting {
      position: absolute;
      background-color: var(--affine-background-overlay-panel-color);
      border-radius: 8px;
      box-shadow: var(--affine-shadow-2);
      padding: 8px;
      min-width: 300px;
    }

    .properties-group-header {
      user-select: none;
      padding: 4px 12px 12px 12px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--affine-divider-color);
    }

    .properties-group-title {
      font-size: 12px;
      line-height: 20px;
      color: var(--affine-text-secondary-color);
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .properties-group-op {
      padding: 4px 8px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      border-radius: 4px;
      cursor: pointer;
    }

    .properties-group-op:hover {
      background-color: var(--affine-hover-color);
    }

    .properties-group {
      min-height: 40px;
    }

    .property-item {
      padding: 4px;
      display: flex;
      align-items: center;
      gap: 8px;
      user-select: none;
      cursor: pointer;
      border-radius: 4px;
    }

    .property-item:hover {
      background-color: var(--affine-hover-color);
    }

    .property-item-drag-bar {
      width: 4px;
      height: 12px;
      border-radius: 1px;
      background-color: #efeff0;
    }

    .property-item:hover .property-item-drag-bar {
      background-color: #c0bfc1;
    }

    .property-item-icon {
      display: flex;
      align-items: center;
    }

    .property-item-icon svg {
      color: var(--affine-icon-color);
      fill: var(--affine-icon-color);
      width: 20px;
      height: 20px;
    }

    .property-item-op-icon {
      display: flex;
      align-items: center;
      border-radius: 4px;
    }

    .property-item-op-icon:hover {
      background-color: var(--affine-hover-color);
    }
    .property-item-op-icon.disabled:hover {
      background-color: transparent;
    }

    .property-item-op-icon svg {
      fill: var(--affine-icon-color);
      color: var(--affine-icon-color);
      width: 20px;
      height: 20px;
    }

    .property-item-op-icon.disabled svg {
      fill: var(--affine-text-disable-color);
      color: var(--affine-text-disable-color);
    }

    .property-item-name {
      font-size: 14px;
      line-height: 22px;
      flex: 1;
    }
  `;
            }
            itemsGroup() {
                return this.view.columnsWithoutFilter$.value.map((id)=>this.view.columnGet(id));
            }
            connectedCallback() {
                super.connectedCallback();
                this._disposables.addFromEvent(this, 'pointerdown', (e)=>{
                    e.stopPropagation();
                });
            }
            firstUpdated() {
                const sortable = new Sortable(this.groupContainer, {
                    animation: 150,
                    group: `properties-sort-${this.view.id}`,
                    onEnd: (evt)=>{
                        const properties = [
                            ...this.view.columnsWithoutFilter$.value
                        ];
                        const index = evt.oldIndex ?? -1;
                        const from = properties[index];
                        properties.splice(index, 1);
                        const to = properties[evt.newIndex ?? -1];
                        this.view.columnMove(from, to ? {
                            before: true,
                            id: to
                        } : 'end');
                    }
                });
                this._disposables.add({
                    dispose: ()=>sortable.destroy()
                });
            }
            render() {
                const items = this.itemsGroup();
                const isAllShowed = items.every((v)=>!v.hide);
                const clickChangeAll = ()=>this.clickChangeAll(isAllShowed);
                return html`
      <div class="properties-group-header">
        <div class="properties-group-title dv-icon-20">
          <div
            @click=${this.onBack}
            style="display:flex;"
            class="dv-hover dv-round-4 dv-pd-2"
          >
            ${ArrowLeftBigIcon}
          </div>
          PROPERTIES
        </div>
        <div class="properties-group-op" @click="${clickChangeAll}">
          ${isAllShowed ? 'Hide All' : 'Show All'}
        </div>
      </div>
      <div class="properties-group">
        ${repeat(items, (v)=>v.id, this.renderColumn)}
      </div>
    `;
            }
            #___private_groupContainer_1;
            get groupContainer() {
                return this.#___private_groupContainer_1;
            }
            set groupContainer(_v) {
                this.#___private_groupContainer_1 = _v;
            }
            #___private_onBack_2;
            get onBack() {
                return this.#___private_onBack_2;
            }
            set onBack(_v) {
                this.#___private_onBack_2 = _v;
            }
            #___private_view_3;
            get view() {
                return this.#___private_view_3;
            }
            set view(_v) {
                this.#___private_view_3 = _v;
            }
            constructor(...args){
                super(...args);
                this.clickChangeAll = (allShow)=>{
                    this.view.columnsWithoutFilter$.value.forEach((id)=>{
                        if (this.view.columnGetType(id) !== 'title') {
                            this.view.columnUpdateHide(id, allShow);
                        }
                    });
                };
                this.renderColumn = (column)=>{
                    const isTitle = column.type === 'title';
                    const icon = column.hide ? hidden : show;
                    const changeVisible = ()=>{
                        if (column.type !== 'title') {
                            column.updateHide(!column.hide);
                        }
                    };
                    const classList = classMap({
                        'property-item-op-icon': true,
                        disabled: isTitle
                    });
                    return html` <div class="property-item">
      <div class="property-item-drag-bar"></div>
      <uni-lit class="property-item-icon" .uni="${column.icon}"></uni-lit>
      <div class="property-item-name">${column.name}</div>
      <div class="${classList}" @click="${changeVisible}">${icon}</div>
    </div>`;
                };
                this.#___private_groupContainer_1 = (_initProto(this), _init_groupContainer(this));
                this.#___private_onBack_2 = _init_onBack(this, undefined);
                this.#___private_view_3 = _init_view(this);
            }
        }
    }
}();
export const popPropertiesSetting = (target, props)=>{
    const view = new _DataViewPropertiesSettingView();
    view.view = props.view;
    view.onBack = ()=>{
        close();
        props.onBack?.();
    };
    const close = createPopup(target, view, {
        onClose: props.onClose
    });
};
export { _DataViewPropertiesSettingView as DataViewPropertiesSettingView };
