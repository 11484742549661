import { computed } from '@lit-labs/preact-signals';
export class ColumnBase {
    constructor(view, columnId){
        this.view = view;
        this.columnId = columnId;
        this.data$ = computed(()=>{
            return this.view.columnGetData(this.id);
        });
        this.detailRenderer$ = computed(()=>{
            return this.view.columnGetMeta(this.type)?.renderer.detailCellRenderer ?? this.renderer$.value;
        });
        this.readonly$ = computed(()=>{
            return this.view.readonly$.value || this.view.columnGetReadonly(this.id);
        });
        this.renderer$ = computed(()=>{
            return this.view.columnGetMeta(this.type)?.renderer.cellRenderer;
        });
    }
    cellGet(rowId) {
        return this.view.cellGet(rowId, this.id);
    }
    getStringValue(rowId) {
        return this.cellGet(rowId).stringValue$.value;
    }
    getValue(rowId) {
        return this.cellGet(rowId).value$.value;
    }
    setValue(rowId, value) {
        return this.cellGet(rowId).setValue(value);
    }
    setValueFromString(rowId, value) {
        const data = this.view.columnParseValueFromString(this.id, value);
        if (!data) {
            return;
        }
        if (data.data) {
            this.updateData(()=>data.data);
        }
        this.setValue(rowId, data.value);
    }
    updateData(updater) {
        const data = this.data$.value;
        this.view.columnUpdateData(this.id, {
            ...data,
            ...updater(data)
        });
    }
    updateHide(hide) {
        this.view.columnUpdateHide(this.id, hide);
    }
    updateName(name) {
        this.view.columnUpdateName(this.id, name);
    }
    get dataType() {
        return this.view.columnGetDataType(this.id);
    }
    get delete() {
        return ()=>this.view.columnDelete(this.id);
    }
    get duplicate() {
        return ()=>this.view.columnDuplicate(this.id);
    }
    get hide() {
        return this.view.columnGetHide(this.id);
    }
    get icon() {
        if (!this.type) return undefined;
        return this.view.getIcon(this.type);
    }
    get id() {
        return this.columnId;
    }
    get index() {
        return this.view.columnGetIndex(this.id);
    }
    get isFirst() {
        return this.view.columnGetIndex(this.id) === 0;
    }
    get isLast() {
        return this.view.columnGetIndex(this.id) === this.view.columnManagerList$.value.length - 1;
    }
    get name() {
        return this.view.columnGetName(this.id);
    }
    get type() {
        return this.view.columnGetType(this.id);
    }
    get updateType() {
        return (type)=>this.view.columnUpdateType(this.id, type);
    }
}
