function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _init_absolutePath, _init_frontEndpointStyle, _init_labelConstraints, _init_labelDisplay, _init_labelOffset, _init_labelStyle, _init_labelXYWH, _init_lableEditing, _init_mode, _init_path, _init_rearEndpointStyle, _init_rotate, _init_rough, _init_roughness, _init_source, _init_stroke, _init_strokeStyle, _init_strokeWidth, _init_target, _init_text, _init_xywh, _initProto;
import { GfxLocalElementModel, GfxPrimitiveElementModel, derive, local, yfield } from '@blocksuite/block-std/gfx';
import { Bound, PointLocation, Vec } from '@blocksuite/global/utils';
import { DocCollection } from '@blocksuite/store';
import { DEFAULT_ROUGHNESS, FontFamily, FontStyle, FontWeight, StrokeStyle, TextAlign } from '../consts.js';
import { getBezierNearestPoint, getBezierNearestTime, getBezierParameters, getBezierPoint, intersects } from '../utils/curve.js';
import { linePolylineIntersects, polyLineNearestPoint } from '../utils/math-utils.js';
import { Polyline } from '../utils/polyline.js';
export var ConnectorEndpoint;
(function(ConnectorEndpoint) {
    ConnectorEndpoint["Front"] = "Front";
    ConnectorEndpoint["Rear"] = "Rear";
})(ConnectorEndpoint || (ConnectorEndpoint = {}));
export const DEFAULT_FRONT_END_POINT_STYLE = 'None';
export const DEFAULT_REAR_END_POINT_STYLE = 'Arrow';
export const CONNECTOR_LABEL_MAX_WIDTH = 280;
export var ConnectorMode;
(function(ConnectorMode) {
    ConnectorMode[ConnectorMode["Straight"] = 0] = "Straight";
    ConnectorMode[ConnectorMode["Orthogonal"] = 1] = "Orthogonal";
    ConnectorMode[ConnectorMode["Curve"] = 2] = "Curve";
})(ConnectorMode || (ConnectorMode = {}));
export const getConnectorModeName = (mode)=>{
    return ({
        [0]: 'Straight',
        [1]: 'Orthogonal',
        [2]: 'Curve'
    })[mode];
};
export var ConnectorLabelOffsetAnchor;
(function(ConnectorLabelOffsetAnchor) {
    ConnectorLabelOffsetAnchor["Bottom"] = "bottom";
    ConnectorLabelOffsetAnchor["Center"] = "center";
    ConnectorLabelOffsetAnchor["Top"] = "top";
})(ConnectorLabelOffsetAnchor || (ConnectorLabelOffsetAnchor = {}));
_dec = local(), _dec1 = yfield('None'), _dec2 = yfield({
    hasMaxWidth: true,
    maxWidth: CONNECTOR_LABEL_MAX_WIDTH
}), _dec3 = yfield(true), _dec4 = yfield({
    distance: 0.5,
    anchor: "center"
}), _dec5 = yfield({
    color: '#000000',
    fontFamily: FontFamily.Inter,
    fontSize: 16,
    fontStyle: FontStyle.Normal,
    fontWeight: FontWeight.Regular,
    textAlign: TextAlign.Center
}), _dec6 = yfield(), _dec7 = local(), _dec8 = yfield(), _dec9 = derive((path, instance)=>{
    const { x, y } = instance;
    return {
        absolutePath: path.map((p)=>p.clone().setVec(Vec.add(p, [
                x,
                y
            ])))
    };
}), _dec10 = local(), _dec11 = yfield('Arrow'), _dec12 = local(), _dec13 = yfield(), _dec14 = yfield(), _dec15 = yfield(), _dec16 = yfield(), _dec17 = yfield(), _dec18 = yfield(), _dec19 = yfield(), _dec20 = yfield(), _dec21 = local();
export class ConnectorElementModel extends GfxPrimitiveElementModel {
    static{
        ({ e: [_init_absolutePath, _init_frontEndpointStyle, _init_labelConstraints, _init_labelDisplay, _init_labelOffset, _init_labelStyle, _init_labelXYWH, _init_lableEditing, _init_mode, _init_path, _init_rearEndpointStyle, _init_rotate, _init_rough, _init_roughness, _init_source, _init_stroke, _init_strokeStyle, _init_strokeWidth, _init_target, _init_text, _init_xywh, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "absolutePath"
            ],
            [
                _dec1,
                1,
                "frontEndpointStyle"
            ],
            [
                _dec2,
                1,
                "labelConstraints"
            ],
            [
                _dec3,
                1,
                "labelDisplay"
            ],
            [
                _dec4,
                1,
                "labelOffset"
            ],
            [
                _dec5,
                1,
                "labelStyle"
            ],
            [
                _dec6,
                1,
                "labelXYWH"
            ],
            [
                _dec7,
                1,
                "lableEditing"
            ],
            [
                _dec8,
                1,
                "mode"
            ],
            [
                [
                    _dec9,
                    _dec10
                ],
                1,
                "path"
            ],
            [
                _dec11,
                1,
                "rearEndpointStyle"
            ],
            [
                _dec12,
                1,
                "rotate"
            ],
            [
                _dec13,
                1,
                "rough"
            ],
            [
                _dec14,
                1,
                "roughness"
            ],
            [
                _dec15,
                1,
                "source"
            ],
            [
                _dec16,
                1,
                "stroke"
            ],
            [
                _dec17,
                1,
                "strokeStyle"
            ],
            [
                _dec18,
                1,
                "strokeWidth"
            ],
            [
                _dec19,
                1,
                "target"
            ],
            [
                _dec20,
                1,
                "text"
            ],
            [
                _dec21,
                1,
                "xywh"
            ]
        ], []));
    }
    static propsToY(props) {
        if (props.text && !(props.text instanceof DocCollection.Y.Text)) {
            props.text = new DocCollection.Y.Text(props.text);
        }
        return props;
    }
    containsBound(bounds) {
        return this.absolutePath.some((point)=>bounds.containsPoint(point)) || this.hasLabel() && Bound.fromXYWH(this.labelXYWH).points.some((p)=>bounds.containsPoint(p));
    }
    getLineIntersections(start, end) {
        const { mode, absolutePath: path } = this;
        let intersected = null;
        if (mode === 2 && path.length > 1) {
            intersected = intersects(path, [
                start,
                end
            ]);
        } else {
            intersected = linePolylineIntersects(start, end, path);
        }
        if (!intersected && this.hasLabel()) {
            intersected = linePolylineIntersects(start, end, Bound.fromXYWH(this.labelXYWH).points);
        }
        return intersected;
    }
    getNearestPoint(point) {
        const { mode, absolutePath: path } = this;
        if (mode === 0) {
            const first = path[0];
            const last = path[path.length - 1];
            return Vec.nearestPointOnLineSegment(first, last, point, true);
        }
        if (mode === 1) {
            const points = path.map((p)=>[
                    p[0],
                    p[1]
                ]);
            return Polyline.nearestPoint(points, point);
        }
        const b = getBezierParameters(path);
        const t = getBezierNearestTime(b, point);
        const p = getBezierPoint(b, t);
        if (p) return p;
        const { x, y } = this;
        return [
            x,
            y
        ];
    }
    getOffsetDistanceByPoint(point, bounds) {
        const { mode, absolutePath: path } = this;
        let { x, y, w, h } = this;
        if (bounds) {
            x = bounds.x;
            y = bounds.y;
            w = bounds.w;
            h = bounds.h;
        }
        point[0] = Vec.clamp(point[0], x, x + w);
        point[1] = Vec.clamp(point[1], y, y + h);
        if (mode === 0) {
            const s = path[0];
            const e = path[path.length - 1];
            const pl = Vec.dist(s, point);
            const fl = Vec.dist(s, e);
            return pl / fl;
        }
        if (mode === 1) {
            const points = path.map((p)=>[
                    p[0],
                    p[1]
                ]);
            const p = Polyline.nearestPoint(points, point);
            const pl = Polyline.lenAtPoint(points, p);
            const fl = Polyline.len(points);
            return pl / fl;
        }
        const b = getBezierParameters(path);
        return getBezierNearestTime(b, point);
    }
    getPointByOffsetDistance(offsetDistance = 0.5, bounds) {
        const { mode, absolutePath: path } = this;
        if (mode === 0) {
            const first = path[0];
            const last = path[path.length - 1];
            return Vec.lrp(first, last, offsetDistance);
        }
        let { x, y, w, h } = this;
        if (bounds) {
            x = bounds.x;
            y = bounds.y;
            w = bounds.w;
            h = bounds.h;
        }
        if (mode === 1) {
            const points = path.map((p)=>[
                    p[0],
                    p[1]
                ]);
            const point = Polyline.pointAt(points, offsetDistance);
            if (point) return point;
            return [
                x + w / 2,
                y + h / 2
            ];
        }
        const b = getBezierParameters(path);
        const point = getBezierPoint(b, offsetDistance);
        if (point) return point;
        return [
            x + w / 2,
            y + h / 2
        ];
    }
    getRelativePointLocation(point) {
        return new PointLocation(Bound.deserialize(this.xywh).getRelativePoint(point));
    }
    hasLabel() {
        return Boolean(!this.lableEditing && this.labelDisplay && this.labelXYWH);
    }
    includesPoint(x, y, options) {
        const currentPoint = [
            x,
            y
        ];
        if (this.labelIncludesPoint(currentPoint)) {
            return true;
        }
        const { mode, strokeWidth, absolutePath: path } = this;
        const point = mode === 2 ? getBezierNearestPoint(getBezierParameters(path), currentPoint) : polyLineNearestPoint(path, currentPoint);
        return Vec.dist(point, currentPoint) < (options?.expand ? strokeWidth / 2 : 0) + 8;
    }
    labelIncludesPoint(point) {
        return this.hasLabel() && Bound.fromXYWH(this.labelXYWH).isPointInBound(point);
    }
    moveTo(bound) {
        const oldBound = Bound.deserialize(this.xywh);
        const offset = Vec.sub([
            bound.x,
            bound.y
        ], [
            oldBound.x,
            oldBound.y
        ]);
        const { source, target } = this;
        if (!source.id && source.position) {
            this.source = {
                position: Vec.add(source.position, offset)
            };
        }
        if (!target.id && target.position) {
            this.target = {
                position: Vec.add(target.position, offset)
            };
        }
        if (this.hasLabel()) {
            const [x, y, w, h] = this.labelXYWH;
            this.labelXYWH = [
                x + offset[0],
                y + offset[1],
                w,
                h
            ];
        }
    }
    resize(bounds, originalPath, matrix) {
        this.updatingPath = false;
        const path = this.resizePath(originalPath, matrix);
        this.xywh = bounds.serialize();
        this.path = path.map((p)=>p.clone().setVec(Vec.sub(p, bounds.tl)));
        const props = {};
        if (this.hasLabel()) {
            const [cx, cy] = this.getPointByOffsetDistance(this.labelOffset.distance);
            const [, , w, h] = this.labelXYWH;
            props.labelXYWH = [
                cx - w / 2,
                cy - h / 2,
                w,
                h
            ];
        }
        if (!this.source.id) {
            props.source = {
                ...this.source,
                position: path[0].toVec()
            };
        }
        if (!this.target.id) {
            props.target = {
                ...this.target,
                position: path[path.length - 1].toVec()
            };
        }
        return props;
    }
    resizePath(originalPath, matrix) {
        if (this.mode === 2) {
            return originalPath.map((point)=>{
                const [p, t, absIn, absOut] = [
                    point,
                    point.tangent,
                    point.absIn,
                    point.absOut
                ].map((p)=>new DOMPoint(...p).matrixTransform(matrix)).map((p)=>[
                        p.x,
                        p.y
                    ]);
                const ip = Vec.sub(absIn, p);
                const op = Vec.sub(absOut, p);
                return new PointLocation(p, t, ip, op);
            });
        }
        return originalPath.map((point)=>{
            const { x, y } = new DOMPoint(...point).matrixTransform(matrix);
            const p = [
                x,
                y
            ];
            return PointLocation.fromVec(p);
        });
    }
    serialize() {
        const result = super.serialize();
        result.xywh = this.xywh;
        return result;
    }
    get connectable() {
        return false;
    }
    get connected() {
        return !!(this.source.id || this.target.id);
    }
    get elementBound() {
        let bounds = super.elementBound;
        if (this.hasLabel()) {
            bounds = bounds.unite(Bound.fromXYWH(this.labelXYWH));
        }
        return bounds;
    }
    get type() {
        return 'connector';
    }
    #___private_absolutePath_1;
    get absolutePath() {
        return this.#___private_absolutePath_1;
    }
    set absolutePath(_v) {
        this.#___private_absolutePath_1 = _v;
    }
    #___private_frontEndpointStyle_2;
    get frontEndpointStyle() {
        return this.#___private_frontEndpointStyle_2;
    }
    set frontEndpointStyle(_v) {
        this.#___private_frontEndpointStyle_2 = _v;
    }
    #___private_labelConstraints_3;
    get labelConstraints() {
        return this.#___private_labelConstraints_3;
    }
    set labelConstraints(_v) {
        this.#___private_labelConstraints_3 = _v;
    }
    #___private_labelDisplay_4;
    get labelDisplay() {
        return this.#___private_labelDisplay_4;
    }
    set labelDisplay(_v) {
        this.#___private_labelDisplay_4 = _v;
    }
    #___private_labelOffset_5;
    get labelOffset() {
        return this.#___private_labelOffset_5;
    }
    set labelOffset(_v) {
        this.#___private_labelOffset_5 = _v;
    }
    #___private_labelStyle_6;
    get labelStyle() {
        return this.#___private_labelStyle_6;
    }
    set labelStyle(_v) {
        this.#___private_labelStyle_6 = _v;
    }
    #___private_labelXYWH_7;
    get labelXYWH() {
        return this.#___private_labelXYWH_7;
    }
    set labelXYWH(_v) {
        this.#___private_labelXYWH_7 = _v;
    }
    #___private_lableEditing_8;
    get lableEditing() {
        return this.#___private_lableEditing_8;
    }
    set lableEditing(_v) {
        this.#___private_lableEditing_8 = _v;
    }
    #___private_mode_9;
    get mode() {
        return this.#___private_mode_9;
    }
    set mode(_v) {
        this.#___private_mode_9 = _v;
    }
    #___private_path_10;
    get path() {
        return this.#___private_path_10;
    }
    set path(_v) {
        this.#___private_path_10 = _v;
    }
    #___private_rearEndpointStyle_11;
    get rearEndpointStyle() {
        return this.#___private_rearEndpointStyle_11;
    }
    set rearEndpointStyle(_v) {
        this.#___private_rearEndpointStyle_11 = _v;
    }
    #___private_rotate_12;
    get rotate() {
        return this.#___private_rotate_12;
    }
    set rotate(_v) {
        this.#___private_rotate_12 = _v;
    }
    #___private_rough_13;
    get rough() {
        return this.#___private_rough_13;
    }
    set rough(_v) {
        this.#___private_rough_13 = _v;
    }
    #___private_roughness_14;
    get roughness() {
        return this.#___private_roughness_14;
    }
    set roughness(_v) {
        this.#___private_roughness_14 = _v;
    }
    #___private_source_15;
    get source() {
        return this.#___private_source_15;
    }
    set source(_v) {
        this.#___private_source_15 = _v;
    }
    #___private_stroke_16;
    get stroke() {
        return this.#___private_stroke_16;
    }
    set stroke(_v) {
        this.#___private_stroke_16 = _v;
    }
    #___private_strokeStyle_17;
    get strokeStyle() {
        return this.#___private_strokeStyle_17;
    }
    set strokeStyle(_v) {
        this.#___private_strokeStyle_17 = _v;
    }
    #___private_strokeWidth_18;
    get strokeWidth() {
        return this.#___private_strokeWidth_18;
    }
    set strokeWidth(_v) {
        this.#___private_strokeWidth_18 = _v;
    }
    #___private_target_19;
    get target() {
        return this.#___private_target_19;
    }
    set target(_v) {
        this.#___private_target_19 = _v;
    }
    #___private_text_20;
    get text() {
        return this.#___private_text_20;
    }
    set text(_v) {
        this.#___private_text_20 = _v;
    }
    #___private_xywh_21;
    get xywh() {
        return this.#___private_xywh_21;
    }
    set xywh(_v) {
        this.#___private_xywh_21 = _v;
    }
    constructor(...args){
        super(...args);
        this.updatingPath = false;
        this.#___private_absolutePath_1 = (_initProto(this), _init_absolutePath(this, []));
        this.#___private_frontEndpointStyle_2 = _init_frontEndpointStyle(this);
        this.#___private_labelConstraints_3 = _init_labelConstraints(this);
        this.#___private_labelDisplay_4 = _init_labelDisplay(this);
        this.#___private_labelOffset_5 = _init_labelOffset(this);
        this.#___private_labelStyle_6 = _init_labelStyle(this);
        this.#___private_labelXYWH_7 = _init_labelXYWH(this, undefined);
        this.#___private_lableEditing_8 = _init_lableEditing(this, false);
        this.#___private_mode_9 = _init_mode(this, 1);
        this.#___private_path_10 = _init_path(this, []);
        this.#___private_rearEndpointStyle_11 = _init_rearEndpointStyle(this);
        this.#___private_rotate_12 = _init_rotate(this, 0);
        this.#___private_rough_13 = _init_rough(this, undefined);
        this.#___private_roughness_14 = _init_roughness(this, DEFAULT_ROUGHNESS);
        this.#___private_source_15 = _init_source(this, {
            position: [
                0,
                0
            ]
        });
        this.#___private_stroke_16 = _init_stroke(this, '#000000');
        this.#___private_strokeStyle_17 = _init_strokeStyle(this, StrokeStyle.Solid);
        this.#___private_strokeWidth_18 = _init_strokeWidth(this, 4);
        this.#___private_target_19 = _init_target(this, {
            position: [
                0,
                0
            ]
        });
        this.#___private_text_20 = _init_text(this, undefined);
        this.#___private_xywh_21 = _init_xywh(this, '[0,0,0,0]');
    }
}
export class LocalConnectorElementModel extends GfxLocalElementModel {
    get path() {
        return this._path;
    }
    set path(value) {
        const { x, y } = this;
        this._path = value;
        this.absolutePath = value.map((p)=>p.clone().setVec([
                p[0] + x,
                p[1] + y
            ]));
    }
    get type() {
        return 'connector';
    }
    constructor(...args){
        super(...args);
        this._path = [];
        this.absolutePath = [];
        this.id = '';
        this.mode = 1;
        this.rotate = 0;
        this.roughness = DEFAULT_ROUGHNESS;
        this.seed = Math.random();
        this.source = {
            position: [
                0,
                0
            ]
        };
        this.stroke = '#000000';
        this.strokeStyle = StrokeStyle.Solid;
        this.strokeWidth = 4;
        this.target = {
            position: [
                0,
                0
            ]
        };
        this.updatingPath = false;
        this.xywh = '[0,0,0,0]';
    }
}
export function isConnectorWithLabel(model) {
    return model instanceof ConnectorElementModel && model.hasLabel();
}
