function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WidgetComponent;
import { BlockSelection, TextSelection } from '@blocksuite/block-std';
import { WidgetComponent } from '@blocksuite/block-std';
import { assertExists } from '@blocksuite/global/utils';
import { computed } from '@lit-labs/preact-signals';
import { css, html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { matchFlavours } from '../../../_common/utils/model.js';
import { RemoteColorManager } from '../../../root-block/remote-color-manager/remote-color-manager.js';
import { isRootComponent } from '../../../root-block/utils/guard.js';
import { cursorStyle, filterCoveringRects, selectionStyle } from './utils.js';
export const AFFINE_DOC_REMOTE_SELECTION_WIDGET = 'affine-doc-remote-selection-widget';
let _AffineDocRemoteSelectionWidget;
_dec = customElement(AFFINE_DOC_REMOTE_SELECTION_WIDGET);
new class extends _identity {
    constructor(){
        super(_AffineDocRemoteSelectionWidget), _initClass();
    }
    static{
        class AffineDocRemoteSelectionWidget extends (_WidgetComponent = WidgetComponent) {
            static{
                ({ c: [_AffineDocRemoteSelectionWidget, _initClass] } = _apply_decs_2203_r(this, [], [
                    _dec
                ], _WidgetComponent));
            }
            static{
                this.styles = css`
    :host {
      pointer-events: none;
    }
  `;
            }
            get _config() {
                const config = this.std.spec.getConfig('affine:page')?.docRemoteSelectionWidget ?? {};
                return {
                    blockSelectionBackgroundTransparent: (block)=>{
                        return matchFlavours(block, [
                            'affine:code',
                            'affine:database',
                            'affine:image'
                        ]) || !/affine:embed-*/.test(block.flavour);
                    },
                    ...config
                };
            }
            get _container() {
                return this.offsetParent;
            }
            get _containerRect() {
                return this.offsetParent?.getBoundingClientRect();
            }
            _getCursorRect(selections) {
                if (!isRootComponent(this.block)) {
                    console.error('remote selection widget must be used in page component');
                    return null;
                }
                const textSelection = selections.find((selection)=>selection instanceof TextSelection);
                const blockSelections = selections.filter((selection)=>selection instanceof BlockSelection);
                const container = this._container;
                const containerRect = this._containerRect;
                if (textSelection) {
                    const range = this.std.range.textSelectionToRange(this._selectionManager.create('text', {
                        from: {
                            blockId: textSelection.to ? textSelection.to.blockId : textSelection.from.blockId,
                            index: textSelection.to ? textSelection.to.index + textSelection.to.length : textSelection.from.index + textSelection.from.length,
                            length: 0
                        },
                        to: null
                    }));
                    if (!range) {
                        return null;
                    }
                    const container = this._container;
                    const containerRect = this._containerRect;
                    const rangeRects = Array.from(range.getClientRects());
                    if (rangeRects.length === 1) {
                        const rect = rangeRects[0];
                        return {
                            width: 2,
                            height: rect.height,
                            top: rect.top - (containerRect?.top ?? 0) + (container?.scrollTop ?? 0),
                            left: rect.left - (containerRect?.left ?? 0) + (container?.scrollLeft ?? 0)
                        };
                    }
                } else if (blockSelections.length > 0) {
                    const lastBlockSelection = blockSelections[blockSelections.length - 1];
                    const block = this.host.view.getBlock(lastBlockSelection.blockId);
                    if (block) {
                        const rect = block.getBoundingClientRect();
                        return {
                            width: 2,
                            height: rect.height,
                            top: rect.top - (containerRect?.top ?? 0) + (container?.scrollTop ?? 0),
                            left: rect.left + rect.width - (containerRect?.left ?? 0) + (container?.scrollLeft ?? 0)
                        };
                    }
                }
                return null;
            }
            _getSelectionRect(selections) {
                if (!isRootComponent(this.block)) {
                    console.error('remote selection widget must be used in page component');
                    return [];
                }
                const textSelection = selections.find((selection)=>selection instanceof TextSelection);
                const blockSelections = selections.filter((selection)=>selection instanceof BlockSelection);
                const container = this._container;
                const containerRect = this._containerRect;
                if (textSelection) {
                    const range = this.std.range.textSelectionToRange(textSelection);
                    if (range) {
                        const nativeRects = Array.from(range.getClientRects());
                        const rectsWithoutFiltered = nativeRects.map((rect)=>({
                                width: rect.right - rect.left,
                                height: rect.bottom - rect.top,
                                top: rect.top - (containerRect?.top ?? 0) + (container?.scrollTop ?? 0),
                                left: rect.left - (containerRect?.left ?? 0) + (container?.scrollLeft ?? 0)
                            })).filter((rect)=>rect.width > 0 && rect.height > 0);
                        return filterCoveringRects(rectsWithoutFiltered);
                    }
                } else if (blockSelections.length > 0) {
                    return blockSelections.flatMap((blockSelection)=>{
                        const block = this.host.view.getBlock(blockSelection.blockId);
                        if (block) {
                            const rect = block.getBoundingClientRect();
                            const transparent = this._config.blockSelectionBackgroundTransparent(block.model);
                            return {
                                width: rect.width,
                                height: rect.height,
                                top: rect.top - (containerRect?.top ?? 0) + (container?.scrollTop ?? 0),
                                left: rect.left - (containerRect?.left ?? 0) + (container?.scrollLeft ?? 0),
                                transparent
                            };
                        }
                        return [];
                    });
                }
                return [];
            }
            get _selectionManager() {
                return this.host.selection;
            }
            connectedCallback() {
                super.connectedCallback();
                this.handleEvent('wheel', ()=>{
                    this.requestUpdate();
                });
                this.disposables.addFromEvent(window, 'resize', ()=>{
                    this.requestUpdate();
                });
                this._remoteColorManager = new RemoteColorManager(this.host);
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this._resizeObserver.disconnect();
                this._abortController.abort();
            }
            render() {
                if (this._remoteSelections.value.length === 0) {
                    return nothing;
                }
                const remoteUsers = new Set();
                const selections = this._remoteSelections.value.flatMap(({ selections, id, user })=>{
                    if (remoteUsers.has(id)) {
                        return [];
                    } else {
                        remoteUsers.add(id);
                    }
                    return {
                        id,
                        selections,
                        rects: this._getSelectionRect(selections),
                        user
                    };
                });
                const remoteColorManager = this._remoteColorManager;
                assertExists(remoteColorManager);
                return html`<div>
      ${selections.flatMap((selection)=>{
                    const color = remoteColorManager.get(selection.id);
                    if (!color) return;
                    const cursorRect = this._getCursorRect(selection.selections);
                    return selection.rects.map((r)=>html`<div style="${selectionStyle(r, color)}"></div>`).concat([
                        html`
              <div
                style="${cursorRect ? cursorStyle(cursorRect, color) : styleMap({
                            display: 'none'
                        })}"
              >
                <div
                  style="${styleMap({
                            position: 'relative',
                            height: '100%'
                        })}"
                >
                  <div
                    style="${styleMap({
                            position: 'absolute',
                            left: '-4px',
                            bottom: `${cursorRect?.height ? cursorRect.height - 4 : 0}px`,
                            backgroundColor: color,
                            color: 'white',
                            maxWidth: '160px',
                            padding: '0 3px',
                            border: '1px solid var(--affine-pure-black-20)',
                            boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.16)',
                            borderRadius: '4px',
                            fontSize: '12px',
                            lineHeight: '18px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: selection.user ? 'block' : 'none'
                        })}"
                  >
                    ${selection.user?.name}
                  </div>
                </div>
              </div>
            `
                    ]);
                })}
    </div>`;
            }
            constructor(...args){
                super(...args);
                this._abortController = new AbortController();
                this._remoteColorManager = null;
                this._remoteSelections = computed(()=>{
                    const status = this.doc.awarenessStore.getStates();
                    return [
                        ...this.std.selection.remoteSelections.entries()
                    ].map(([id, selections])=>{
                        return {
                            id,
                            selections,
                            user: status.get(id)?.user
                        };
                    });
                });
                this._resizeObserver = new ResizeObserver(()=>{
                    this.requestUpdate();
                });
            }
        }
    }
}();
export { _AffineDocRemoteSelectionWidget as AffineDocRemoteSelectionWidget };
