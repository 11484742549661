import { wrapFontFamily } from '../../utils/font.js';
const RS_LTR_CHARS = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' + '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF';
const RS_RTL_CHARS = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';
const RE_RTL_CHECK = new RegExp(`^[^${RS_LTR_CHARS}]*[${RS_RTL_CHARS}]`);
export const isRTL = (text)=>RE_RTL_CHECK.test(text);
const getMeasureCtx = function initMeasureContext() {
    let ctx = null;
    let canvas = null;
    return ()=>{
        if (!canvas) {
            canvas = document.createElement('canvas');
            ctx = canvas.getContext('2d');
        }
        return ctx;
    };
}();
const cachedFontFamily = new Map();
export function getLineHeight(fontFamily, fontSize) {
    const ctx = getMeasureCtx();
    const wrappedFontFamily = wrapFontFamily(fontFamily);
    if (cachedFontFamily.has(wrappedFontFamily)) {
        const cache = cachedFontFamily.get(wrappedFontFamily);
        return fontSize / cache.fontSize * cache.lineHeight;
    }
    const font = `${fontSize}px ${wrapFontFamily(fontFamily)}`;
    ctx.font = `${fontSize}px ${wrapFontFamily(fontFamily)}`;
    const textMetrics = ctx.measureText('M');
    const lineHeight = textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent;
    if (font === ctx.font) {
        cachedFontFamily.set(wrappedFontFamily, {
            fontSize,
            lineHeight
        });
    }
    return lineHeight;
}
export function getFontString({ fontStyle, fontWeight, fontSize, fontFamily }) {
    const lineHeight = getLineHeight(fontFamily, fontSize);
    return `${fontStyle} ${fontWeight} ${fontSize}px/${lineHeight}px ${wrapFontFamily(fontFamily)}, sans-serif`.trim();
}
export function normalizeText(text) {
    return text.replace(/\t/g, '        ').replace(/\r?\n|\r/g, '\n');
}
export function splitIntoLines(text) {
    return normalizeText(text).split('\n');
}
export function getLineWidth(text, font) {
    const ctx = getMeasureCtx();
    if (font !== ctx.font) ctx.font = font;
    const width = ctx.measureText(text).width;
    return width;
}
export function getTextRect(text, fontFamily, fontSize) {
    text = text.split('\n').map((x)=>x || ' ').join('\n');
    const lineHeight = getLineHeight(fontFamily, fontSize);
    const font = `${fontSize}px "${fontFamily}"`;
    const w = getTextWidth(text, font);
    const h = getTextHeight(text, lineHeight);
    return {
        w,
        h
    };
}
export function getTextWidth(text, font) {
    const lines = splitIntoLines(text);
    let width = 0;
    lines.forEach((line)=>{
        width = Math.max(width, getLineWidth(line, font));
    });
    return width;
}
export const getTextHeight = (text, lineHeight)=>{
    const lineCount = splitIntoLines(text).length;
    return lineHeight * lineCount;
};
export function parseTokens(text) {
    const words = text.split('-');
    if (words.length > 1) {
        words.forEach((word, index)=>{
            if (index !== words.length - 1) {
                words[index] = word += '-';
            }
        });
    }
    return words.join(' ').split(' ');
}
export const charWidth = (()=>{
    const cachedCharWidth = {};
    const calculate = (char, font)=>{
        const ascii = char.charCodeAt(0);
        if (!cachedCharWidth[font]) {
            cachedCharWidth[font] = [];
        }
        if (!cachedCharWidth[font][ascii]) {
            const width = getLineWidth(char, font);
            cachedCharWidth[font][ascii] = width;
        }
        return cachedCharWidth[font][ascii];
    };
    const getCache = (font)=>{
        return cachedCharWidth[font];
    };
    return {
        calculate,
        getCache
    };
})();
export function wrapText(text, font, maxWidth) {
    if (!Number.isFinite(maxWidth) || maxWidth < 0) {
        return text;
    }
    const lines = [];
    const originalLines = text.split('\n');
    const spaceWidth = getLineWidth(' ', font);
    let currentLine = '';
    let currentLineWidthTillNow = 0;
    const push = (str)=>{
        if (str.trim()) {
            lines.push(str);
        }
    };
    const resetParams = ()=>{
        currentLine = '';
        currentLineWidthTillNow = 0;
    };
    originalLines.forEach((originalLine)=>{
        const currentLineWidth = getTextWidth(originalLine, font);
        if (currentLineWidth <= maxWidth) {
            lines.push(originalLine);
            return;
        }
        const words = parseTokens(originalLine);
        resetParams();
        let index = 0;
        while(index < words.length){
            const currentWordWidth = getLineWidth(words[index], font);
            if (currentWordWidth === maxWidth) {
                push(words[index]);
                index++;
            } else if (currentWordWidth > maxWidth) {
                push(currentLine);
                resetParams();
                while(words[index].length > 0){
                    const currentChar = String.fromCodePoint(words[index].codePointAt(0));
                    const width = charWidth.calculate(currentChar, font);
                    currentLineWidthTillNow += width;
                    words[index] = words[index].slice(currentChar.length);
                    if (currentLineWidthTillNow >= maxWidth) {
                        push(currentLine);
                        currentLine = currentChar;
                        currentLineWidthTillNow = width;
                    } else {
                        currentLine += currentChar;
                    }
                }
                if (currentLineWidthTillNow + spaceWidth >= maxWidth) {
                    push(currentLine);
                    resetParams();
                } else if (!currentLine.endsWith('-')) {
                    currentLine += ' ';
                    currentLineWidthTillNow += spaceWidth;
                }
                index++;
            } else {
                while(currentLineWidthTillNow < maxWidth && index < words.length){
                    const word = words[index];
                    currentLineWidthTillNow = getLineWidth(currentLine + word, font);
                    if (currentLineWidthTillNow > maxWidth) {
                        push(currentLine);
                        resetParams();
                        break;
                    }
                    index++;
                    const shouldAppendSpace = !word.endsWith('-');
                    currentLine += word;
                    if (shouldAppendSpace) {
                        currentLine += ' ';
                    }
                    if (currentLineWidthTillNow + spaceWidth >= maxWidth) {
                        if (shouldAppendSpace) {
                            lines.push(currentLine.slice(0, -1));
                        } else {
                            lines.push(currentLine);
                        }
                        resetParams();
                        break;
                    }
                }
            }
        }
        if (currentLine.slice(-1) === ' ') {
            currentLine = currentLine.slice(0, -1);
            push(currentLine);
        }
    });
    return lines.join('\n');
}
