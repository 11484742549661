import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuIcon, MenuItem, MenuSeparator } from '@affine/component';
import { isGhostTag } from '@affine/core/modules/tag/entities/internal-tag';
import { useI18n } from '@affine/i18n';
import dayjs from 'dayjs';
import { FilterTag } from './filter-tag-translation';
import * as styles from './index.css';
import { tBoolean, tDate, tDateRange, tTag } from './logical/custom-type';
import { Matcher } from './logical/matcher';
import { tArray, tFunction, tTypeRef, tTypeVar, typesystem } from './logical/typesystem';
import { variableDefineMap } from './shared-types';
export const vars = Object.entries(variableDefineMap).map(([key, value])=>({
        name: key,
        type: value.type,
        icon: value.icon
    }));
export const createDefaultFilter = (variable, propertiesMeta)=>{
    const data = filterMatcher.match(variable.type(propertiesMeta));
    if (!data) {
        throw new Error('No matching function found');
    }
    return {
        type: 'filter',
        left: {
            type: 'ref',
            name: variable.name
        },
        funcName: data.name,
        args: data.defaultArgs().map((value)=>({
                type: 'literal',
                value
            }))
    };
};
export const CreateFilterMenu = ({ value, onChange, propertiesMeta })=>{
    return _jsx(VariableSelect, {
        propertiesMeta: propertiesMeta,
        selected: value,
        onSelect: (filter)=>{
            onChange([
                ...value,
                filter
            ]);
        }
    });
};
export const VariableSelect = ({ onSelect, propertiesMeta })=>{
    const t = useI18n();
    return _jsxs("div", {
        "data-testid": "variable-select",
        children: [
            _jsx("div", {
                className: styles.variableSelectTitleStyle,
                children: t['com.affine.filter']()
            }),
            _jsx(MenuSeparator, {}),
            vars.map((v)=>_jsx(MenuItem, {
                    preFix: _jsx(MenuIcon, {
                        children: variableDefineMap[v.name].icon
                    }),
                    onClick: ()=>{
                        onSelect(createDefaultFilter(v, propertiesMeta));
                    },
                    className: styles.menuItemStyle,
                    children: _jsx("div", {
                        "data-testid": "variable-select-item",
                        className: styles.menuItemTextStyle,
                        children: _jsx(FilterTag, {
                            name: v.name
                        })
                    })
                }, v.name))
        ]
    });
};
export const filterMatcher = new Matcher((type, target)=>{
    const staticType = typesystem.subst(Object.fromEntries(type.typeVars?.map((v)=>[
            v.name,
            v.bound
        ]) ?? []), type);
    const firstArg = staticType.args[0];
    return firstArg && typesystem.isSubtype(firstArg, target);
});
filterMatcher.register(tFunction({
    args: [
        tBoolean.create(),
        tBoolean.create()
    ],
    rt: tBoolean.create()
}), {
    name: 'is',
    defaultArgs: ()=>[
            true
        ],
    impl: (value, target)=>{
        return value === target;
    }
});
filterMatcher.register(tFunction({
    args: [
        tDate.create(),
        tDate.create()
    ],
    rt: tBoolean.create()
}), {
    name: 'after',
    defaultArgs: ()=>{
        return [
            dayjs().subtract(1, 'day').endOf('day').valueOf()
        ];
    },
    impl: (date, target)=>{
        if (typeof date !== 'number' || typeof target !== 'number') {
            throw new Error('argument type error');
        }
        return dayjs(date).isAfter(dayjs(target).endOf('day'));
    }
});
filterMatcher.register(tFunction({
    args: [
        tDate.create(),
        tDateRange.create()
    ],
    rt: tBoolean.create()
}), {
    name: 'last',
    defaultArgs: ()=>[
            30
        ],
    impl: (date, n)=>{
        if (typeof date !== 'number' || typeof n !== 'number') {
            throw new Error('Argument type error: date and n must be numbers');
        }
        const startDate = dayjs().subtract(n, 'day').startOf('day').valueOf();
        return date > startDate;
    }
});
filterMatcher.register(tFunction({
    args: [
        tDate.create(),
        tDate.create()
    ],
    rt: tBoolean.create()
}), {
    name: 'before',
    defaultArgs: ()=>[
            dayjs().endOf('day').valueOf()
        ],
    impl: (date, target)=>{
        if (typeof date !== 'number' || typeof target !== 'number') {
            throw new Error('argument type error');
        }
        return dayjs(date).isBefore(dayjs(target).startOf('day'));
    }
});
const safeArray = (arr)=>{
    return Array.isArray(arr) ? arr : [];
};
filterMatcher.register(tFunction({
    args: [
        tArray(tTag.create())
    ],
    rt: tBoolean.create()
}), {
    name: 'is not empty',
    defaultArgs: ()=>[],
    impl: (tags)=>{
        const safeTags = safeArray(tags);
        return safeTags.some((t)=>!isGhostTag(t));
    }
});
filterMatcher.register(tFunction({
    args: [
        tArray(tTag.create())
    ],
    rt: tBoolean.create()
}), {
    name: 'is empty',
    defaultArgs: ()=>[],
    impl: (tags)=>{
        const safeTags = safeArray(tags);
        return safeTags.filter((t)=>!isGhostTag(t)).length === 0;
    }
});
filterMatcher.register(tFunction({
    typeVars: [
        tTypeVar('T', tTag.create())
    ],
    args: [
        tArray(tTypeRef('T')),
        tArray(tTypeRef('T'))
    ],
    rt: tBoolean.create()
}), {
    name: 'contains all',
    defaultArgs: ()=>[],
    impl: (tags, target)=>{
        if (!Array.isArray(target)) {
            return true;
        }
        const safeTags = safeArray(tags);
        return target.every((id)=>safeTags.includes(id));
    }
});
filterMatcher.register(tFunction({
    typeVars: [
        tTypeVar('T', tTag.create())
    ],
    args: [
        tArray(tTypeRef('T')),
        tArray(tTypeRef('T'))
    ],
    rt: tBoolean.create()
}), {
    name: 'contains one of',
    defaultArgs: ()=>[],
    impl: (tags, target)=>{
        if (!Array.isArray(target)) {
            return true;
        }
        const safeTags = safeArray(tags);
        return target.some((id)=>safeTags.includes(id));
    }
});
filterMatcher.register(tFunction({
    typeVars: [
        tTypeVar('T', tTag.create())
    ],
    args: [
        tArray(tTypeRef('T')),
        tArray(tTypeRef('T'))
    ],
    rt: tBoolean.create()
}), {
    name: 'does not contains all',
    defaultArgs: ()=>[],
    impl: (tags, target)=>{
        if (!Array.isArray(target)) {
            return true;
        }
        const safeTags = safeArray(tags);
        return !target.every((id)=>safeTags.includes(id));
    }
});
filterMatcher.register(tFunction({
    typeVars: [
        tTypeVar('T', tTag.create())
    ],
    args: [
        tArray(tTypeRef('T')),
        tArray(tTypeRef('T'))
    ],
    rt: tBoolean.create()
}), {
    name: 'does not contains one of',
    defaultArgs: ()=>[],
    impl: (tags, target)=>{
        if (!Array.isArray(target)) {
            return true;
        }
        const safeTags = safeArray(tags);
        return !target.some((id)=>safeTags.includes(id));
    }
});
