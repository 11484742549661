import { DebugLogger } from '@affine/debug';
import { backoffRetry, catchErrorInto, effect, Entity, fromPromise, LiveData, mapInto, onComplete, onStart } from '@toeverything/infra';
import { exhaustMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../../cloud';
const logger = new DebugLogger('affine:workspace-permission');
export class WorkspaceQuota extends Entity {
    constructor(workspaceService, store){
        super();
        this.workspaceService = workspaceService;
        this.store = store;
        this.quota$ = new LiveData(null);
        this.isLoading$ = new LiveData(false);
        this.error$ = new LiveData(null);
        this.revalidate = effect(exhaustMap(()=>{
            return fromPromise((signal)=>this.store.fetchWorkspaceQuota(this.workspaceService.workspace.id, signal)).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError,
                count: 3
            }), mapInto(this.quota$), catchErrorInto(this.error$, (error)=>{
                logger.error('Failed to fetch isOwner', error);
            }), onStart(()=>this.isLoading$.setValue(true)), onComplete(()=>this.isLoading$.setValue(false)));
        }));
    }
}
