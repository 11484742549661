import { jsx as _jsx } from "react/jsx-runtime";
import { AuthModal as AuthModalBase } from '@affine/component/auth-components';
import { useCallback, useMemo } from 'react';
import { AfterSignInSendEmail } from './after-sign-in-send-email';
import { AfterSignUpSendEmail } from './after-sign-up-send-email';
import { SendEmail } from './send-email';
import { SignIn } from './sign-in';
import { SignInWithPassword } from './sign-in-with-password';
const config = {
    signIn: SignIn,
    afterSignUpSendEmail: AfterSignUpSendEmail,
    afterSignInSendEmail: AfterSignInSendEmail,
    signInWithPassword: SignInWithPassword,
    sendEmail: SendEmail
};
export const AuthModal = ({ open, state, setOpen, email, setAuthEmail, setAuthState, setEmailType, emailType })=>{
    const onSignedIn = useCallback(()=>{
        setAuthState('signIn');
        setAuthEmail('');
        setOpen(false);
    }, [
        setAuthState,
        setAuthEmail,
        setOpen
    ]);
    return _jsx(AuthModalBase, {
        open: open,
        setOpen: setOpen,
        children: _jsx(AuthPanel, {
            state: state,
            email: email,
            setAuthEmail: setAuthEmail,
            setAuthState: setAuthState,
            setEmailType: setEmailType,
            emailType: emailType,
            onSignedIn: onSignedIn
        })
    });
};
export const AuthPanel = ({ state, email, setAuthEmail, setAuthState, setEmailType, emailType, onSignedIn })=>{
    const CurrentPanel = useMemo(()=>{
        return config[state];
    }, [
        state
    ]);
    return _jsx(CurrentPanel, {
        email: email,
        setAuthState: setAuthState,
        setAuthEmail: setAuthEmail,
        setEmailType: setEmailType,
        emailType: emailType,
        onSignedIn: onSignedIn
    });
};
