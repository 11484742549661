function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _init__backLinkShow, _init__show, _init_doc, _init_pageRoot, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { ReferenceNodeConfig, getAffineInlineSpecsWithReference } from '@blocksuite/blocks';
import { BlocksUtils, InlineManager, RichText } from '@blocksuite/blocks';
import { assertExists, noop } from '@blocksuite/global/utils';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ArrowJumpIcon, ArrowLeftIcon, SmallDeleteIcon, SmallLinkedDocIcon } from '../_common/icons.js';
import { DOC_BLOCK_CHILD_PADDING } from '../doc-meta-tags/utils.js';
noop(RichText);
const { matchFlavours } = BlocksUtils;
let _BiDirectionalLinkPanel;
_dec = customElement('bi-directional-link-panel'), _dec1 = state(), _dec2 = state(), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_BiDirectionalLinkPanel), _initClass();
    }
    static{
        class BiDirectionalLinkPanel extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init__backLinkShow, _init__show, _init_doc, _init_pageRoot, _initProto], c: [_BiDirectionalLinkPanel, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_backLinkShow"
                    ],
                    [
                        _dec2,
                        1,
                        "_show"
                    ],
                    [
                        _dec3,
                        1,
                        "doc"
                    ],
                    [
                        _dec4,
                        1,
                        "pageRoot"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    :host {
      width: 100%;
      max-width: var(--affine-editor-width);
      margin-left: auto;
      margin-right: auto;
      padding-left: var(
        --affine-editor-side-padding,
        ${DOC_BLOCK_CHILD_PADDING}
      );
      padding-right: var(
        --affine-editor-side-padding,
        ${DOC_BLOCK_CHILD_PADDING}
      );
      font-size: var(--affine-font-base);
    }

    /* Extra small devices (phones, 640px and down) */
    @container viewport (width <= 640px) {
      :host {
        padding-left: ${DOC_BLOCK_CHILD_PADDING}px;
        padding-right: ${DOC_BLOCK_CHILD_PADDING}px;
      }
    }

    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .back-links-title,
    .links-title {
      color: var(--affine-text-secondary-color);
      height: 32px;
      line-height: 32px;
    }

    .links,
    .back-links {
      margin-bottom: 16px;
    }

    .back-link-title {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .back-link-title div {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--affine-text-primary-color);
    }

    .back-links-container {
      height: auto;
      display: flex;
    }

    .back-links-container-left-divider {
      width: 20px;
      display: flex;
      justify-content: center;
    }
    .back-links-container-left-divider div {
      width: 1px;
      height: 100%;
      background-color: var(--affine-border-color);
    }

    .link {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      gap: 4px;
      white-space: nowrap;
      cursor: pointer;
    }

    .link svg {
      flex: none;
    }

    .link div {
      width: fit-content;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 32px;
      border-bottom: 0.5px solid var(--affine-divider-color);
    }

    .link.deleted {
      color: var(--affine-text-disable-color);
      text-decoration: line-through;
      fill: var(--affine-text-disable-color);
    }

    .arrow {
      cursor: pointer;
      transition: transform 0.2s;
    }

    .back-links-blocks-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 8px;
      position: relative;
    }

    .rich-text-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: start;
      cursor: pointer;
      border-radius: 4px;
      padding: 0px 8px;
      padding-top: 8px;
    }

    .rich-text {
      max-width: 96%;
      padding-bottom: 8px;
    }

    .arrow-link {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      display: none;
    }

    .rich-text-container:hover {
      background-color: var(--affine-hover-color);
    }

    .rich-text-container:hover .arrow-link {
      display: flex;
    }
    .arrow-link:hover {
      background-color: var(--affine-hover-color);
    }

    .quote {
      padding-left: 24px;
    }
    .quote::after {
      content: '';
      width: 2px;
      height: 24px;
      position: absolute;
      left: 16px;
      background: var(--affine-quote-color);
      border-radius: 18px;
    }

    .linked-doc-container {
      display: flex;
      align-items: center;
      padding-left: 2px;
      gap: 2px;
    }
    .linked-doc-container svg {
      scale: 1.2;
    }
  `;
            }
            get _backLinks() {
                const { doc } = this;
                const { collection } = doc;
                const backLinks = new Map();
                collection.indexer.backlink?.getBacklink(doc.id).reduce((map, link)=>{
                    const { pageId } = link;
                    if (map.has(pageId)) {
                        map.get(pageId).push(link.blockId);
                    } else {
                        map.set(pageId, [
                            link.blockId
                        ]);
                    }
                    return map;
                }, backLinks);
                return backLinks;
            }
            _backlink(model, docId, blockId) {
                if (!matchFlavours(model, [
                    'affine:paragraph',
                    'affine:list',
                    'affine:embed-linked-doc',
                    'affine:embed-synced-doc'
                ])) return nothing;
                let icon = null;
                if (matchFlavours(model, [
                    'affine:list'
                ])) {
                    const listService = this._host.spec.getService('affine:list');
                    icon = listService.styles.icon(model, false, ()=>{});
                }
                const type = matchFlavours(model, [
                    'affine:embed-linked-doc',
                    'affine:embed-synced-doc'
                ]) ? '' : model.type;
                return html` <div
      class=${`rich-text-container ${type}`}
      @click=${(e)=>{
                    this._handleLinkClick(e, docId, blockId);
                }}
    >
      <div class="rich-text">
        ${type ? html`${icon ?? nothing}
              <rich-text
                .yText=${model.text}
                .readonly=${true}
                .attributesSchema=${this._inlineManager.getSchema()}
                .attributeRenderer=${this._inlineManager.getRenderer()}
              ></rich-text>` : html`<div class="linked-doc-container">
              ${SmallLinkedDocIcon}
              ${this.doc.meta?.title ? this.doc.meta?.title : 'Untitled'}
            </div>`}
      </div>

      <div class="arrow-link">${ArrowJumpIcon}</div>
    </div>`;
            }
            _divider(visible) {
                return html`
      <style>
        .divider-container {
          height: 16px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .divider {
          background: var(--affine-border-color);
          height: 0.5px;
          width: 100%;
        }
      </style>
      <div
        style=${styleMap({
                    visibility: visible ? 'visible' : 'hidden'
                })}
        class="divider-container"
      >
        <div class="divider"></div>
      </div>
    `;
            }
            _handleLinkClick(e, docId, blockId) {
                if (e.shiftKey && this._rootService.peekViewService) {
                    this._rootService.peekViewService.peek({
                        docId,
                        blockId
                    }).catch(console.error);
                } else {
                    this.pageRoot.slots.docLinkClicked.emit({
                        docId,
                        blockId
                    });
                }
            }
            get _host() {
                return this.pageRoot.host;
            }
            get _links() {
                const { doc } = this;
                const ids = new Set();
                doc.getBlockByFlavour([
                    'affine:paragraph',
                    'affine:list',
                    'affine:embed-linked-doc',
                    'affine:embed-synced-doc'
                ]).forEach((model)=>{
                    if (model.text) {
                        const deltas = model.text.yText.toDelta();
                        deltas.forEach((delta)=>{
                            if (delta.attributes?.reference?.pageId) ids.add(delta.attributes.reference.pageId);
                        });
                    } else if (matchFlavours(model, [
                        'affine:embed-linked-doc',
                        'affine:embed-synced-doc'
                    ])) {
                        ids.add(model.pageId);
                    }
                });
                return Array.from(ids);
            }
            _renderBackLinks(backLinks) {
                const { doc } = this;
                const { collection } = doc;
                const length = backLinks.size;
                return html` <div class="back-links">
      <div class="back-links-title">${`Backlinks · ${length}`}</div>
      ${repeat(backLinks.keys(), (id)=>id, (docId, index)=>{
                    const doc = collection.getDoc(docId);
                    const blocks = backLinks.get(docId);
                    assertExists(doc);
                    const show = this._backLinkShow[index] ?? false;
                    const listService = this._host.spec.getService('affine:list');
                    return html`<style>
              .affine-list-block__prefix{
                display: flex;
                align-items: center;
              }

              .rich-text{
                display: flex;
                align-items: center;
              }

              ${listService.styles.prefix}
              ${listService.styles.toggle}
            </style>
            <div class="back-link">
              <div class="back-link-title">
                <div
                  class="arrow"
                  style=${styleMap({
                        transform: `rotate(${show ? 90 : 0}deg)`
                    })}
                  @click=${()=>{
                        this._backLinkShow[index] = !show;
                        this.requestUpdate();
                    }}
                >
                  ${ArrowLeftIcon}
                </div>
                <div>
                  ${SmallLinkedDocIcon}${doc.meta?.title ? doc.meta.title : 'Untitled'}
                </div>
              </div>
              <div class="back-links-container">
                <div class="back-links-container-left-divider">
                  <div></div>
                </div>
                <div class="back-links-blocks-container">
                  ${show ? repeat(blocks, (blockId)=>blockId, (blockId)=>{
                        const model = doc.getBlockById(blockId);
                        if (!model) return nothing;
                        return this._backlink(model, docId, blockId);
                    }) : nothing}
                </div>
              </div>
            </div>`;
                })}
    </div>`;
            }
            _renderLinks(ids) {
                const { collection } = this.doc;
                return html`<div class="links">
      <div class="links-title">Outgoing links · ${ids.length}</div>
      ${repeat(ids, (id)=>id, (id)=>{
                    const doc = collection.getDoc(id);
                    const isDeleted = !doc;
                    const title = isDeleted ? 'Deleted doc' : !doc.meta ? 'Untitled' : doc.meta.title;
                    const icon = isDeleted ? SmallDeleteIcon : SmallLinkedDocIcon;
                    return html`<div
            class=${`link ${isDeleted ? 'deleted' : ''}`}
            @click=${(e)=>{
                        this._handleLinkClick(e, id);
                    }}
          >
            ${icon}
            <div>${title}</div>
          </div>`;
                })}
    </div> `;
            }
            get _rootService() {
                return this._host.spec.getService('affine:page');
            }
            _toggleShow() {
                this._show = !this._show;
                this._rootService.editPropsStore.setStorage('showBidirectional', this._show);
            }
            connectedCallback() {
                super.connectedCallback();
                const config = new ReferenceNodeConfig();
                config.setInteractable(false);
                config.setDoc(this.doc);
                config.setCustomContent((reference)=>{
                    const title = reference.doc.meta?.title ? reference.doc.meta.title : 'Untitled';
                    return html`<style>
          .custom-reference-content svg {
            position: relative;
            top: 2px;
          }
        </style>
        <span class="custom-reference-content">
          ${SmallLinkedDocIcon} ${title}
        </span> `;
                });
                this._inlineManager.registerSpecs(getAffineInlineSpecsWithReference(config));
                if (this.doc.collection.indexer.backlink) {
                    const { _disposables } = this;
                    _disposables.add(this.doc.collection.indexer.backlink.slots.indexUpdated.on(()=>{
                        this.requestUpdate();
                    }));
                }
                this._show = !!this._rootService.editPropsStore.getStorage('showBidirectional');
            }
            render() {
                const links = this._links;
                const backLinks = this._backLinks;
                if (links.length + backLinks.size === 0) return nothing;
                return html`<style>
        .title {
          font-weight: 500;
          font-size: 15px;
          line-height: 24px;
          color: ${this._show ? 'var(--affine-text-primary-color)' : 'var(--affine-text-disable-color)'};
        }
        .show-button {
          width: 56px;
          height: 28px;
          border-radius: 8px;
          border: 1px solid var(--affine-border-color);
          background-color: var(--affine-white);
          text-align: center;
          font-size: 12px;
          line-height: 28px;
          font-weight: 500;
          color: var(--affine-text-primary-color);
          cursor: pointer;
        }
      </style>
      ${this._divider(!this._show)}
      <div class="title-line">
        <div class="title text">Bi-directional links</div>
        <div class="show-button" @click=${this._toggleShow}>
          ${this._show ? 'Hide' : 'Show'}
        </div>
      </div>
      ${this._divider(this._show)}
      ${this._show ? html`${this._renderBackLinks(backLinks)} ${this._renderLinks(links)} ` : nothing} `;
            }
            #___private__backLinkShow_1;
            get _backLinkShow() {
                return this.#___private__backLinkShow_1;
            }
            set _backLinkShow(_v) {
                this.#___private__backLinkShow_1 = _v;
            }
            #___private__show_2;
            get _show() {
                return this.#___private__show_2;
            }
            set _show(_v) {
                this.#___private__show_2 = _v;
            }
            #___private_doc_3;
            get doc() {
                return this.#___private_doc_3;
            }
            set doc(_v) {
                this.#___private_doc_3 = _v;
            }
            #___private_pageRoot_4;
            get pageRoot() {
                return this.#___private_pageRoot_4;
            }
            set pageRoot(_v) {
                this.#___private_pageRoot_4 = _v;
            }
            constructor(...args){
                super(...args);
                this._inlineManager = new InlineManager();
                this.#___private__backLinkShow_1 = (_initProto(this), _init__backLinkShow(this, []));
                this.#___private__show_2 = _init__show(this, false);
                this.#___private_doc_3 = _init_doc(this);
                this.#___private_pageRoot_4 = _init_pageRoot(this);
            }
        }
    }
}();
export { _BiDirectionalLinkPanel as BiDirectionalLinkPanel };
