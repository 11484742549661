function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _EdgelessToolbarToolMixin, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _init__currentFrameIndex, _init__navigatorMode, _init_containerWidth, _init_frameMenuShow, _init_setFrameMenuShow, _init_setSettingMenuShow, _init_settingMenuShow, _init_visible, _initProto;
import { Bound } from '@blocksuite/global/utils';
import { cssVar } from '@toeverything/theme';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { toast } from '../../../../_common/components/toast.js';
import { FrameNavigatorNextIcon, FrameNavigatorPrevIcon, NavigatorExitFullScreenIcon, NavigatorFullScreenIcon, StopAIIcon } from '../../../../_common/icons/edgeless.js';
import { clamp } from '../../../../surface-block/index.js';
import { isFrameBlock } from '../../utils/query.js';
import { launchIntoFullscreen } from '../utils.js';
import { EdgelessToolbarToolMixin } from './mixins/tool.mixin.js';
import './present/navigator-setting-button.js';
let _PresentationToolbar;
_dec = customElement('presentation-toolbar'), _dec1 = state({
    hasChanged () {
        return true;
    }
}), _dec2 = state(), _dec3 = property({
    attribute: false
}), _dec4 = property({
    type: Boolean
}), _dec5 = property(), _dec6 = property(), _dec7 = property({
    type: Boolean
}), _dec8 = property({
    attribute: true,
    type: Boolean
});
new class extends _identity {
    constructor(){
        super(_PresentationToolbar), _initClass();
    }
    static{
        class PresentationToolbar extends (_EdgelessToolbarToolMixin = EdgelessToolbarToolMixin(LitElement)) {
            static{
                ({ e: [_init__currentFrameIndex, _init__navigatorMode, _init_containerWidth, _init_frameMenuShow, _init_setFrameMenuShow, _init_setSettingMenuShow, _init_settingMenuShow, _init_visible, _initProto], c: [_PresentationToolbar, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_currentFrameIndex"
                    ],
                    [
                        _dec2,
                        1,
                        "_navigatorMode"
                    ],
                    [
                        _dec3,
                        1,
                        "containerWidth"
                    ],
                    [
                        _dec4,
                        1,
                        "frameMenuShow"
                    ],
                    [
                        _dec5,
                        1,
                        "setFrameMenuShow"
                    ],
                    [
                        _dec6,
                        1,
                        "setSettingMenuShow"
                    ],
                    [
                        _dec7,
                        1,
                        "settingMenuShow"
                    ],
                    [
                        _dec8,
                        1,
                        "visible"
                    ]
                ], [
                    _dec
                ], _EdgelessToolbarToolMixin));
            }
            static{
                this.styles = css`
    :host {
      align-items: inherit;
      width: 100%;
      height: 100%;
      gap: 8px;
      padding-right: 2px;
    }
    .full-divider {
      width: 8px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .full-divider::after {
      content: '';
      width: 1px;
      height: 100%;
      background: var(--affine-border-color);
      transform: scaleX(0.5);
    }
    .config-buttons {
      display: flex;
      gap: 10px;
    }
    .edgeless-frame-navigator {
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .edgeless-frame-navigator.dense {
      width: auto;
    }

    .edgeless-frame-navigator-title {
      display: inline-block;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 8px;
    }

    .edgeless-frame-navigator-count {
      color: var(--affine-text-secondary-color);
      white-space: nowrap;
    }
    .edgeless-frame-navigator-stop {
      border: none;
      cursor: pointer;
      padding: 4px;
      border-radius: 8px;
      position: relative;
      overflow: hidden;

      svg {
        display: block;
      }
    }
    .edgeless-frame-navigator-stop::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      border-radius: inherit;
    }
    .edgeless-frame-navigator-stop:hover::before {
      background: var(--affine-hover-color);
    }
  `;
            }
            constructor(edgeless){
                super();
                this._cachedIndex = -1;
                this.type = 'frameNavigator';
                this.#___private__currentFrameIndex_1 = (_initProto(this), _init__currentFrameIndex(this, 0));
                this.#___private__navigatorMode_2 = _init__navigatorMode(this, 'fit');
                this.#___private_containerWidth_3 = _init_containerWidth(this, 1920);
                this.#___private_frameMenuShow_4 = _init_frameMenuShow(this, false);
                this.#___private_setFrameMenuShow_5 = _init_setFrameMenuShow(this, ()=>{});
                this.#___private_setSettingMenuShow_6 = _init_setSettingMenuShow(this, ()=>{});
                this.#___private_settingMenuShow_7 = _init_settingMenuShow(this, false);
                this.#___private_visible_8 = _init_visible(this, true);
                this.edgeless = edgeless;
            }
            get _cachedPresentHideToolbar() {
                return !!this.edgeless.service.editPropsStore.getStorage('presentHideToolbar');
            }
            set _cachedPresentHideToolbar(value) {
                this.edgeless.service.editPropsStore.setStorage('presentHideToolbar', !!value);
            }
            get _frames() {
                return this.edgeless.service.frames;
            }
            _moveToCurrentFrame() {
                const current = this._currentFrameIndex;
                const viewport = this.edgeless.service.viewport;
                const frame = this._frames[current];
                if (frame) {
                    let bound = Bound.deserialize(frame.xywh);
                    if (this._navigatorMode === 'fill') {
                        const vb = viewport.viewportBounds;
                        const center = bound.center;
                        let w, h;
                        if (vb.w / vb.h > bound.w / bound.h) {
                            w = bound.w;
                            h = w * vb.h / vb.w;
                        } else {
                            h = bound.h;
                            w = h * vb.w / vb.h;
                        }
                        bound = Bound.fromCenter(center, w, h);
                    }
                    viewport.setViewportByBound(bound, [
                        0,
                        0,
                        0,
                        0
                    ], false);
                    this.edgeless.slots.navigatorFrameChanged.emit(this._frames[this._currentFrameIndex]);
                }
            }
            _nextFrame() {
                const frames = this._frames;
                const min = 0;
                const max = frames.length - 1;
                if (this._currentFrameIndex === frames.length - 1) {
                    toast(this.host, 'You have reached the last frame');
                } else {
                    this._currentFrameIndex = clamp(this._currentFrameIndex + 1, min, max);
                }
            }
            _previousFrame() {
                const frames = this._frames;
                const min = 0;
                const max = frames.length - 1;
                if (this._currentFrameIndex === 0) {
                    toast(this.host, 'You have reached the first frame');
                } else {
                    this._currentFrameIndex = clamp(this._currentFrameIndex - 1, min, max);
                }
            }
            _toggleFullScreen() {
                if (document.fullscreenElement) {
                    clearTimeout(this._timer);
                    document.exitFullscreen().catch(console.error);
                } else {
                    launchIntoFullscreen(this.edgeless.viewportElement);
                    this._timer = setTimeout(()=>{
                        this._currentFrameIndex = this._cachedIndex;
                    }, 400);
                }
                setTimeout(()=>this._moveToCurrentFrame(), 400);
                this.edgeless.slots.fullScreenToggled.emit();
            }
            firstUpdated() {
                const { _disposables, edgeless } = this;
                const { slots } = edgeless;
                _disposables.add(edgeless.bindHotKey({
                    ArrowLeft: ()=>{
                        const { type } = this.edgelessTool;
                        if (type !== 'frameNavigator') return;
                        this._previousFrame();
                    },
                    ArrowRight: ()=>{
                        const { type } = this.edgelessTool;
                        if (type !== 'frameNavigator') return;
                        this._nextFrame();
                    }
                }, {
                    global: true
                }));
                _disposables.add(slots.edgelessToolUpdated.on((tool)=>{
                    if (tool.type === 'frameNavigator') {
                        this._cachedIndex = this._currentFrameIndex;
                        this._navigatorMode = tool.mode ?? this._navigatorMode;
                        if (isFrameBlock(edgeless.service.selection.selectedElements[0])) {
                            this._cachedIndex = this._frames.findIndex((frame)=>frame.id === edgeless.service.selection.selectedElements[0].id);
                        }
                        if (this._frames.length === 0) toast(this.host, 'The presentation requires at least 1 frame. You can firstly create a frame.', 5000);
                        this._toggleFullScreen();
                    }
                    this.requestUpdate();
                }));
                _disposables.add(edgeless.slots.navigatorSettingUpdated.on(({ fillScreen })=>{
                    if (fillScreen !== undefined) {
                        this._navigatorMode = fillScreen ? 'fill' : 'fit';
                    }
                }));
                this._navigatorMode = this.edgeless.service.editPropsStore.getStorage('presentFillScreen') === true ? 'fill' : 'fit';
            }
            render() {
                const current = this._currentFrameIndex;
                const frames = this._frames;
                const frame = frames[current];
                const { doc } = this.edgeless;
                return html`
      <style>
        :host {
          display: ${this.visible ? 'flex' : 'none'};
        }
      </style>
      <edgeless-tool-icon-button
        .iconContainerPadding=${0}
        .tooltip=${'Previous'}
        @click=${()=>this._previousFrame()}
      >
        ${FrameNavigatorPrevIcon}
      </edgeless-tool-icon-button>

      <div class="edgeless-frame-navigator ${this.dense ? 'dense' : ''}">
        ${this.dense ? nothing : html`<span
              style="color: ${cssVar('textPrimaryColor')}"
              class="edgeless-frame-navigator-title"
              @click=${()=>this._moveToCurrentFrame()}
            >
              ${frame?.title ?? 'no frame'}
            </span>`}

        <span class="edgeless-frame-navigator-count">
          ${frames.length === 0 ? 0 : current + 1} / ${frames.length}
        </span>
      </div>

      <edgeless-tool-icon-button
        .tooltip=${'Next'}
        @click=${()=>this._nextFrame()}
        .iconContainerPadding=${0}
      >
        ${FrameNavigatorNextIcon}
      </edgeless-tool-icon-button>

      <div class="full-divider"></div>

      <div class="config-buttons">
        <edgeless-tool-icon-button
          .tooltip=${document.fullscreenElement ? 'Exit Full Screen' : 'Enter Full Screen'}
          @click=${()=>this._toggleFullScreen()}
          .iconContainerPadding=${0}
          .iconContainerWidth=${'24px'}
        >
          ${document.fullscreenElement ? NavigatorExitFullScreenIcon : NavigatorFullScreenIcon}
        </edgeless-tool-icon-button>

        ${this.dense ? nothing : html`<edgeless-frame-order-button
              .popperShow=${this.frameMenuShow}
              .setPopperShow=${this.setFrameMenuShow}
              .frames=${this._frames}
              .edgeless=${this.edgeless}
            >
            </edgeless-frame-order-button>`}

        <edgeless-navigator-setting-button
          .edgeless=${this.edgeless}
          .hideToolbar=${this._cachedPresentHideToolbar}
          .onHideToolbarChange=${(hideToolbar)=>{
                    this._cachedPresentHideToolbar = hideToolbar;
                }}
          .popperShow=${this.settingMenuShow}
          .setPopperShow=${this.setSettingMenuShow}
          .frames=${this._frames}
          .includeFrameOrder=${this.dense}
        >
        </edgeless-navigator-setting-button>
      </div>

      <div class="full-divider"></div>

      <button
        class="edgeless-frame-navigator-stop"
        @click=${()=>{
                    this.setEdgelessTool(doc.readonly ? {
                        type: 'pan',
                        panning: false
                    } : {
                        type: 'default'
                    });
                    document.fullscreenElement && this._toggleFullScreen();
                }}
        style="background: ${cssVar('warningColor')}"
      >
        ${StopAIIcon}
      </button>
    `;
            }
            updated(changedProperties) {
                if (changedProperties.has('_currentFrameIndex') && this.edgelessTool.type === 'frameNavigator') {
                    this._moveToCurrentFrame();
                }
            }
            get dense() {
                return this.containerWidth < 554;
            }
            get host() {
                return this.edgeless.host;
            }
            #___private__currentFrameIndex_1;
            get _currentFrameIndex() {
                return this.#___private__currentFrameIndex_1;
            }
            set _currentFrameIndex(_v) {
                this.#___private__currentFrameIndex_1 = _v;
            }
            #___private__navigatorMode_2;
            get _navigatorMode() {
                return this.#___private__navigatorMode_2;
            }
            set _navigatorMode(_v) {
                this.#___private__navigatorMode_2 = _v;
            }
            #___private_containerWidth_3;
            get containerWidth() {
                return this.#___private_containerWidth_3;
            }
            set containerWidth(_v) {
                this.#___private_containerWidth_3 = _v;
            }
            #___private_frameMenuShow_4;
            get frameMenuShow() {
                return this.#___private_frameMenuShow_4;
            }
            set frameMenuShow(_v) {
                this.#___private_frameMenuShow_4 = _v;
            }
            #___private_setFrameMenuShow_5;
            get setFrameMenuShow() {
                return this.#___private_setFrameMenuShow_5;
            }
            set setFrameMenuShow(_v) {
                this.#___private_setFrameMenuShow_5 = _v;
            }
            #___private_setSettingMenuShow_6;
            get setSettingMenuShow() {
                return this.#___private_setSettingMenuShow_6;
            }
            set setSettingMenuShow(_v) {
                this.#___private_setSettingMenuShow_6 = _v;
            }
            #___private_settingMenuShow_7;
            get settingMenuShow() {
                return this.#___private_settingMenuShow_7;
            }
            set settingMenuShow(_v) {
                this.#___private_settingMenuShow_7 = _v;
            }
            #___private_visible_8;
            get visible() {
                return this.#___private_visible_8;
            }
            set visible(_v) {
                this.#___private_visible_8 = _v;
            }
        }
    }
}();
export { _PresentationToolbar as PresentationToolbar };
