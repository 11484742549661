import { i18nTime } from '@affine/i18n';
import { EdgelessIcon, PageIcon, TodayIcon } from '@blocksuite/icons/rc';
import { Service } from '@toeverything/infra';
export class DocDisplayMetaService extends Service {
    constructor(propertiesAdapter){
        super();
        this.propertiesAdapter = propertiesAdapter;
    }
    getDocDisplayMeta(docRecord, originalTitle) {
        const journalDateString = this.propertiesAdapter.getJournalPageDateString(docRecord.id);
        const icon = journalDateString ? TodayIcon : docRecord.mode$.value === 'edgeless' ? EdgelessIcon : PageIcon;
        const title = journalDateString ? i18nTime(journalDateString, {
            absolute: {
                accuracy: 'day'
            }
        }) : originalTitle || docRecord.meta$.value.title || {
            key: 'Untitled'
        };
        return {
            title: title,
            icon: icon,
            updatedDate: docRecord.meta$.value.updatedDate
        };
    }
}
