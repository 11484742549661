import { EdgelessNoteBlockSpec, EdgelessSurfaceBlockSpec, EdgelessSurfaceRefBlockSpec, EdgelessTextBlockSpec, FrameBlockSpec } from '@blocksuite/blocks';
import { CommonBlockSpecs } from './common';
import { createEdgelessRootBlockSpec } from './custom/root-block';
export function createEdgelessModeSpecs(framework) {
    return [
        ...CommonBlockSpecs,
        EdgelessSurfaceBlockSpec,
        EdgelessSurfaceRefBlockSpec,
        FrameBlockSpec,
        EdgelessTextBlockSpec,
        EdgelessNoteBlockSpec,
        createEdgelessRootBlockSpec(framework)
    ];
}
