import { BlockModel } from '@blocksuite/store';
import { defineEmbedModel } from '../_common/embed-block-helper/embed-block-model.js';
export const EmbedLinkedDocStyles = [
    'vertical',
    'horizontal',
    'list',
    'cube',
    'horizontalThin'
];
export class EmbedLinkedDocModel extends defineEmbedModel(BlockModel) {
}
