export function clearMarksOnDiscontinuousInput(inlineEditor) {
    let inlineRange = inlineEditor.getInlineRange();
    const dispose = inlineEditor.slots.inlineRangeUpdate.on(([r, s])=>{
        if (inlineRange && r && (!s && r.index === inlineRange.index || s && r.index === inlineRange.index + 1)) {
            inlineRange = r;
        } else {
            inlineEditor.resetMarks();
            dispose.dispose();
        }
    });
}
