import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Scrollable } from '@affine/component';
import { PageDetailSkeleton } from '@affine/component/page-detail-skeleton';
import { AIProvider } from '@affine/core/blocksuite/presets/ai';
import { AffineErrorBoundary } from '@affine/core/components/affine/affine-error-boundary';
import { BlockSuiteEditor } from '@affine/core/components/blocksuite/block-suite-editor';
import { EditorOutlineViewer } from '@affine/core/components/blocksuite/outline-viewer';
import { useNavigateHelper } from '@affine/core/hooks/use-navigate-helper';
import { PageNotFound } from '@affine/core/pages/404';
import { DebugLogger } from '@affine/debug';
import { Bound, DisposableGroup } from '@blocksuite/global/utils';
import { DocsService, FrameworkScope, useService } from '@toeverything/infra';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { WorkbenchService } from '../../../workbench';
import { PeekViewService } from '../../services/peek-view';
import { useDoc } from '../utils';
import * as styles from './doc-peek-view.css';
const logger = new DebugLogger('doc-peek-view');
function fitViewport(editor, xywh) {
    try {
        if (!editor.host) {
            throw new Error('editor host is not ready');
        }
        const rootService = editor.host.std.spec.getService('affine:page');
        rootService.viewport.onResize();
        if (xywh) {
            const viewport = {
                xywh: xywh,
                padding: [
                    60,
                    20,
                    20,
                    20
                ]
            };
            rootService.viewport.setViewportByBound(Bound.deserialize(viewport.xywh), viewport.padding, false);
        } else {
            const data = rootService.getFitToScreenData();
            rootService.viewport.setViewport(data.zoom, [
                data.centerX,
                data.centerY
            ], false);
        }
    } catch (e) {
        logger.warn('failed to fitViewPort', e);
    }
}
export function DocPeekPreview({ docId, blockId, mode, xywh }) {
    const { doc, workspace, loading } = useDoc(docId);
    const { jumpToTag } = useNavigateHelper();
    const workbench = useService(WorkbenchService).workbench;
    const peekView = useService(PeekViewService).peekView;
    const [editor, setEditor] = useState(null);
    const onRef = (editor)=>{
        setEditor(editor);
    };
    const docs = useService(DocsService);
    const [resolvedMode, setResolvedMode] = useState(mode);
    useEffect(()=>{
        editor?.updateComplete.then(()=>{
            if (resolvedMode === 'edgeless') {
                fitViewport(editor, xywh);
            }
        }).catch(console.error);
        return;
    }, [
        editor,
        resolvedMode,
        xywh
    ]);
    useEffect(()=>{
        if (!mode || !resolvedMode) {
            setResolvedMode(docs.list.doc$(docId).value?.mode$.value || 'page');
        }
    }, [
        docId,
        docs.list,
        resolvedMode,
        mode
    ]);
    useEffect(()=>{
        const disposable = AIProvider.slots.requestOpenWithChat.on(()=>{
            if (doc) {
                workbench.openDoc(doc.id);
                peekView.close();
            }
        });
        return ()=>{
            disposable.dispose();
        };
    }, [
        doc,
        peekView,
        workbench,
        workspace.id
    ]);
    useEffect(()=>{
        const disposableGroup = new DisposableGroup();
        if (editor) {
            editor.updateComplete.then(()=>{
                if (!editor.host) {
                    return;
                }
                const rootService = editor.host.std.spec.getService('affine:page');
                disposableGroup.add(rootService.slots.docLinkClicked.on(({ docId, blockId })=>{
                    peekView.open({
                        docId,
                        blockId
                    }).catch(console.error);
                }));
                disposableGroup.add(rootService.slots.tagClicked.on(({ tagId })=>{
                    jumpToTag(workspace.id, tagId);
                    peekView.close();
                }));
            }).catch(console.error);
        }
        return ()=>{
            disposableGroup.dispose();
        };
    }, [
        editor,
        jumpToTag,
        peekView,
        workspace.id
    ]);
    const openOutlinePanel = useCallback(()=>{
        workbench.openDoc(docId);
        workbench.openSidebar();
        workbench.activeView$.value.activeSidebarTab('outline');
        peekView.close();
    }, [
        docId,
        peekView,
        workbench
    ]);
    if (!doc || !resolvedMode) {
        return loading || !resolvedMode ? _jsx(PageDetailSkeleton, {}, "current-page-is-null") : _jsx(PageNotFound, {
            noPermission: true
        });
    }
    return _jsx(AffineErrorBoundary, {
        children: _jsxs(Scrollable.Root, {
            children: [
                _jsxs(Scrollable.Viewport, {
                    className: clsx('affine-page-viewport', styles.affineDocViewport),
                    children: [
                        _jsx(FrameworkScope, {
                            scope: doc.scope,
                            children: _jsx(BlockSuiteEditor, {
                                ref: onRef,
                                className: styles.editor,
                                mode: resolvedMode,
                                defaultSelectedBlockId: blockId,
                                page: doc.blockSuiteDoc
                            })
                        }),
                        _jsx(EditorOutlineViewer, {
                            editor: editor,
                            show: resolvedMode === 'page',
                            openOutlinePanel: openOutlinePanel
                        })
                    ]
                }),
                _jsx(Scrollable.Scrollbar, {})
            ]
        })
    });
}
