import { IS_FIREFOX } from '@blocksuite/global/env';
import { assertExists } from '@blocksuite/global/utils';
import { matchFlavours } from './model.js';
import { asyncGetRichText, buildPath, getDocTitleInlineEditor, getPageRootByElement } from './query.js';
import { Rect } from './rect.js';
export async function asyncSetInlineRange(editorHost, model, inlineRange) {
    const richText = await asyncGetRichText(editorHost, model.id);
    if (!richText) {
        return;
    }
    await richText.updateComplete;
    const inlineEditor = richText.inlineEditor;
    if (!inlineEditor) {
        return;
    }
    inlineEditor.setInlineRange(inlineRange);
}
export async function asyncFocusRichText(editorHost, id, offset = 0) {
    const selection = editorHost.std.selection;
    selection.setGroup('note', [
        selection.create('text', {
            from: {
                blockId: id,
                index: offset,
                length: 0
            },
            to: null
        })
    ]);
    await editorHost.updateComplete;
}
export function caretRangeFromPoint(clientX, clientY) {
    if (IS_FIREFOX) {
        const caret = document.caretPositionFromPoint(clientX, clientY);
        const range = document.createRange();
        range.setStart(caret.offsetNode, caret.offset);
        return range;
    }
    const range = document.caretRangeFromPoint(clientX, clientY);
    if (!range) {
        return null;
    }
    const rangeRects = range?.getClientRects();
    if (rangeRects && rangeRects.length === 2 && range.startOffset === range.endOffset && clientY < rangeRects[0].y + rangeRects[0].height) {
        const deltaX = (rangeRects[0].x | 0) - (rangeRects[1].x | 0);
        if (deltaX > 0) {
            range.setStart(range.startContainer, range.startOffset - 1);
            range.setEnd(range.endContainer, range.endOffset - 1);
        }
    }
    return range;
}
function setStartRange(editableContainer) {
    const newRange = document.createRange();
    let firstNode = editableContainer.firstChild;
    while(firstNode?.firstChild){
        firstNode = firstNode.firstChild;
    }
    if (firstNode) {
        newRange.setStart(firstNode, 0);
        newRange.setEnd(firstNode, 0);
    }
    return newRange;
}
function setEndRange(editableContainer) {
    const newRange = document.createRange();
    let lastNode = editableContainer.lastChild;
    while(lastNode?.lastChild){
        lastNode = lastNode.lastChild;
    }
    if (lastNode) {
        newRange.setStart(lastNode, lastNode.textContent?.length || 0);
        newRange.setEnd(lastNode, lastNode.textContent?.length || 0);
    }
    return newRange;
}
function setNewTop(y, editableContainer, zoom = 1) {
    const SCROLL_THRESHOLD = 100;
    const scrollContainer = editableContainer.closest('.affine-page-viewport');
    const { top, bottom } = Rect.fromDOM(editableContainer);
    const { clientHeight } = document.documentElement;
    const lineHeight = (Number(window.getComputedStyle(editableContainer).lineHeight.replace(/\D+$/, '')) || 16) * zoom;
    const compare = bottom < y;
    switch(compare){
        case true:
            {
                let finalBottom = bottom;
                if (bottom < SCROLL_THRESHOLD && scrollContainer) {
                    scrollContainer.scrollTop = scrollContainer.scrollTop - SCROLL_THRESHOLD + bottom;
                    requestAnimationFrame(()=>{
                        finalBottom = editableContainer.getBoundingClientRect().bottom;
                    });
                }
                return finalBottom - lineHeight / 2;
            }
        case false:
            {
                let finalTop = top;
                if (scrollContainer && top > clientHeight - SCROLL_THRESHOLD) {
                    scrollContainer.scrollTop = scrollContainer.scrollTop + (top + SCROLL_THRESHOLD - clientHeight);
                    requestAnimationFrame(()=>{
                        finalTop = editableContainer.getBoundingClientRect().top;
                    });
                }
                return finalTop + lineHeight / 2;
            }
    }
}
export function focusTitle(editorHost, index = Infinity, len = 0) {
    const titleInlineEditor = getDocTitleInlineEditor(editorHost);
    if (!titleInlineEditor) {
        return;
    }
    if (index > titleInlineEditor.yText.length) {
        index = titleInlineEditor.yText.length;
    }
    titleInlineEditor.setInlineRange({
        index,
        length: len
    });
}
function focusRichText(editableContainer, position = 'end', zoom = 1) {
    const isPageRoot = !!getPageRootByElement(editableContainer);
    if (isPageRoot) {
        editableContainer.querySelector('v-line')?.scrollIntoView({
            block: 'nearest'
        });
    }
    const { left, right } = Rect.fromDOM(editableContainer);
    let range = null;
    switch(position){
        case 'start':
            range = setStartRange(editableContainer);
            break;
        case 'end':
            range = setEndRange(editableContainer);
            break;
        default:
            {
                const { x, y } = position;
                let newLeft = x;
                const newTop = setNewTop(y, editableContainer, zoom);
                if (x <= left) {
                    newLeft = left + 1;
                }
                if (x >= right) {
                    newLeft = right - 1;
                }
                range = caretRangeFromPoint(newLeft, newTop);
                break;
            }
    }
    resetNativeSelection(range);
}
export function focusBlockByModel(editorHost, model, position = 'end', zoom = 1) {
    if (matchFlavours(model, [
        'affine:note',
        'affine:page'
    ])) {
        console.error("Can't focus note or doc!");
        return;
    }
    const element = editorHost.view.viewFromPath('block', buildPath(model));
    const editableContainer = element?.querySelector('[contenteditable]');
    if (editableContainer) {
        focusRichText(editableContainer, position, zoom);
    }
}
export function resetNativeSelection(range) {
    const selection = window.getSelection();
    assertExists(selection);
    selection.removeAllRanges();
    range && selection.addRange(range);
}
export function getCurrentNativeRange(selection = window.getSelection()) {
    if (!selection) {
        console.error('Failed to get current range, selection is null');
        return null;
    }
    if (selection.rangeCount === 0) {
        return null;
    }
    if (selection.rangeCount > 1) {
        console.warn('getCurrentNativeRange may be wrong, rangeCount > 1');
    }
    return selection.getRangeAt(0);
}
export function handleNativeRangeAtPoint(x, y) {
    const range = caretRangeFromPoint(x, y);
    const startContainer = range?.startContainer;
    if (startContainer instanceof Node) {
        resetNativeSelection(range);
    }
}
