import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
const FileTypes = [
    {
        description: 'Images',
        accept: {
            'image/*': [
                '.avif',
                '.gif',
                '.jpeg',
                '.jpg',
                '.png',
                '.tif',
                '.tiff',
                '.webp'
            ]
        }
    },
    {
        description: 'Videos',
        accept: {
            'video/*': [
                '.avi',
                '.mp4',
                '.mpeg',
                '.ogg',
                '.webm',
                '.3gp',
                '.3g2'
            ]
        }
    },
    {
        description: 'Audios',
        accept: {
            'audio/*': [
                '.aac',
                '.mid',
                '.midi',
                '.mp3',
                '.oga',
                '.opus',
                '.wav',
                '.weba',
                '.3gp',
                '.3g2'
            ]
        }
    },
    {
        description: 'Markdown',
        accept: {
            'text/markdown': [
                '.md',
                '.markdown'
            ]
        }
    },
    {
        description: 'Html',
        accept: {
            'text/html': [
                '.html',
                '.htm'
            ]
        }
    },
    {
        description: 'Zip',
        accept: {
            'application/zip': [
                '.zip'
            ]
        }
    }
];
export async function openFileOrFiles({ acceptType = 'Any', multiple = false } = {}) {
    const supportsFileSystemAccess = 'showOpenFilePicker' in window && (()=>{
        try {
            return window.self === window.top;
        } catch  {
            return false;
        }
    })();
    if (supportsFileSystemAccess && window.showOpenFilePicker) {
        try {
            const fileType = FileTypes.find((i)=>i.description === acceptType);
            if (acceptType !== 'Any' && !fileType) throw new BlockSuiteError(ErrorCode.DefaultRuntimeError, `Unexpected acceptType "${acceptType}"`);
            const pickerOpts = {
                types: fileType ? [
                    fileType
                ] : undefined,
                multiple
            };
            const handles = await window.showOpenFilePicker(pickerOpts);
            if (!multiple) {
                const file = await handles[0].getFile();
                return file;
            } else {
                const files = await Promise.all(handles.map(async (handle)=>{
                    const file = await handle.getFile();
                    return file;
                }));
                return files;
            }
        } catch (err) {
            console.error('Error opening file');
            console.error(err);
            return null;
        }
    }
    return new Promise((resolve)=>{
        const input = document.createElement('input');
        input.classList.add('affine-upload-input');
        input.style.display = 'none';
        input.type = 'file';
        if (multiple) {
            input.multiple = true;
        }
        if (acceptType !== 'Any') {
            input.accept = Object.keys(FileTypes.find((i)=>i.description === acceptType)?.accept ?? '').join(',');
        }
        document.body.append(input);
        input.addEventListener('change', ()=>{
            input.remove();
            if (!input.files) {
                resolve(null);
                return;
            }
            if (multiple) {
                resolve(Array.from(input.files));
                return;
            }
            resolve(input.files[0]);
        });
        input.addEventListener('cancel', ()=>{
            resolve(null);
        });
        if ('showPicker' in HTMLInputElement.prototype) {
            input.showPicker();
        } else {
            input.click();
        }
    });
}
export async function getImageFilesFromLocal() {
    const imageFiles = await openFileOrFiles({
        acceptType: 'Images',
        multiple: true
    });
    if (!imageFiles) return [];
    return imageFiles;
}
export function downloadBlob(blob, name) {
    const dataURL = URL.createObjectURL(blob);
    const tmpLink = document.createElement('a');
    const event = new MouseEvent('click');
    tmpLink.download = name;
    tmpLink.href = dataURL;
    tmpLink.dispatchEvent(event);
    tmpLink.remove();
    URL.revokeObjectURL(dataURL);
}
const MAX_TEMP_DATA_SIZE = 100;
const tempAttachmentMap = new Map();
const tempImageMap = new Map();
export function withTempBlobData() {
    const saveAttachmentData = (sourceId, data)=>{
        if (tempAttachmentMap.size > MAX_TEMP_DATA_SIZE) {
            console.warn('Clear the temp attachment data. It may cause filename loss when converting between image and attachment.');
            tempAttachmentMap.clear();
        }
        tempAttachmentMap.set(sourceId, data);
    };
    const getAttachmentData = (blockId)=>{
        const data = tempAttachmentMap.get(blockId);
        tempAttachmentMap.delete(blockId);
        return data;
    };
    const saveImageData = (sourceId, data)=>{
        if (tempImageMap.size > MAX_TEMP_DATA_SIZE) {
            console.warn('Clear temp image data. It may cause image width and height loss when converting between image and attachment.');
            tempImageMap.clear();
        }
        tempImageMap.set(sourceId, data);
    };
    const getImageData = (blockId)=>{
        const data = tempImageMap.get(blockId);
        tempImageMap.delete(blockId);
        return data;
    };
    return {
        saveAttachmentData,
        getAttachmentData,
        saveImageData,
        getImageData
    };
}
