export class EdgelessToolController {
    constructor(service){
        this._draggingArea = null;
        this.enableHover = false;
        this._service = service;
    }
    get _blocks() {
        return this._edgeless.service.blocks;
    }
    get _doc() {
        return this._edgeless.doc;
    }
    get _surface() {
        return this._edgeless.surface;
    }
    mount(edgeless) {
        this._edgeless = edgeless;
    }
    get draggingArea() {
        return this._draggingArea;
    }
}
