function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _init_addPropertyButton, _init_rowId, _init_view, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { computed } from '@lit-labs/preact-signals';
import { css, nothing, unsafeCSS } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { keyed } from 'lit/directives/keyed.js';
import { repeat } from 'lit/directives/repeat.js';
import { html } from 'lit/static-html.js';
import { popFilterableSimpleMenu } from '../../../../_common/components/index.js';
import { renderUniLit } from '../../utils/uni-component/uni-component.js';
import { dataViewCommonStyle } from '../css-variable.js';
import { PlusIcon } from '../icons/index.js';
import './field.js';
import { DetailSelection } from './selection.js';
const arrowDown = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.5073 13.0032C17.2022 12.723 16.7278 12.7432 16.4476 13.0483L12.75 17.0745L12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5L11.25 17.0745L7.55239 13.0483C7.27222 12.7432 6.79777 12.723 6.49269 13.0032C6.18761 13.2833 6.16742 13.7578 6.4476 14.0629L11.4476 19.5073C11.5896 19.662 11.79 19.75 12 19.75C12.21 19.75 12.4104 19.662 12.5524 19.5073L17.5524 14.0629C17.8326 13.7578 17.8124 13.2833 17.5073 13.0032Z"
    fill="#77757D"
  />
</svg> `;
const arrowUp = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.5073 10.9968C17.2022 11.277 16.7278 11.2568 16.4476 10.9517L12.75 6.92547L12.75 19C12.75 19.4142 12.4142 19.75 12 19.75C11.5858 19.75 11.25 19.4142 11.25 19L11.25 6.92547L7.55239 10.9517C7.27222 11.2568 6.79777 11.277 6.49269 10.9968C6.18761 10.7167 6.16742 10.2422 6.4476 9.93714L11.4476 4.4927C11.5896 4.33803 11.79 4.25 12 4.25C12.21 4.25 12.4104 4.33803 12.5524 4.4927L17.5524 9.93714C17.8326 10.2422 17.8124 10.7167 17.5073 10.9968Z"
    fill="#77757D"
  />
</svg> `;
const styles = css`
  ${unsafeCSS(dataViewCommonStyle('affine-data-view-record-detail'))}
  affine-data-view-record-detail {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px;
    gap: 12px;
    background-color: var(--affine-background-primary-color);
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }

  .add-property {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: var(--data-view-cell-text-size);
    font-style: normal;
    font-weight: 400;
    line-height: var(--data-view-cell-text-line-height);
    color: var(--affine-text-disable-color);
    border-radius: 4px;
    padding: 6px 8px 6px 4px;
    cursor: pointer;
    margin-top: 8px;
    width: max-content;
  }

  .add-property:hover {
    background-color: var(--affine-hover-color);
  }

  .add-property .icon {
    display: flex;
    align-items: center;
  }

  .add-property .icon svg {
    fill: var(--affine-icon-color);
    width: 20px;
    height: 20px;
  }
  .switch-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 4px;
    cursor: pointer;
  }
  .switch-row:hover {
    background-color: var(--affine-hover-color);
  }
  .switch-row.disable {
    cursor: default;
    background: none;
    opacity: 0.5;
  }
`;
let _RecordDetail;
_dec = customElement('affine-data-view-record-detail'), _dec1 = query('.add-property'), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_RecordDetail), _initClass();
    }
    static{
        class RecordDetail extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_addPropertyButton, _init_rowId, _init_view, _initProto], c: [_RecordDetail, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "addPropertyButton"
                    ],
                    [
                        _dec2,
                        1,
                        "rowId"
                    ],
                    [
                        _dec3,
                        1,
                        "view"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = styles;
            }
            get readonly() {
                return this.view.readonly$.value;
            }
            renderHeader() {
                const header = this.detailSlots?.header;
                if (header) {
                    const props = {
                        view: this.view,
                        rowId: this.rowId
                    };
                    return renderUniLit(header, props);
                }
                return undefined;
            }
            renderNote() {
                const note = this.detailSlots?.note;
                if (note) {
                    const props = {
                        view: this.view,
                        rowId: this.rowId
                    };
                    return renderUniLit(note, props);
                }
                return undefined;
            }
            connectedCallback() {
                super.connectedCallback();
                this.disposables.addFromEvent(this, 'click', (e)=>{
                    e.stopPropagation();
                    this.selection.selection = undefined;
                });
                this.dataset.widgetId = 'affine-detail-widget';
                this.detailSlots = this.view.detailSlots;
            }
            hasNext() {
                return this.view.rowGetNext(this.rowId) != null;
            }
            hasPrev() {
                return this.view.rowGetPrev(this.rowId) != null;
            }
            nextRow() {
                const rowId = this.view.rowGetNext(this.rowId);
                if (rowId == null) {
                    return;
                }
                this.rowId = rowId;
                this.requestUpdate();
            }
            prevRow() {
                const rowId = this.view.rowGetPrev(this.rowId);
                if (rowId == null) {
                    return;
                }
                this.rowId = rowId;
                this.requestUpdate();
            }
            render() {
                const columns = this.columns$.value;
                const upClass = classMap({
                    'switch-row': true,
                    disable: !this.hasPrev()
                });
                const downClass = classMap({
                    'switch-row': true,
                    disable: !this.hasNext()
                });
                return html`
      <div
        style="position: absolute;left: 20px;top:20px;display: flex;align-items:center;gap:4px;"
      >
        <div @click="${this.prevRow}" class="${upClass}">${arrowUp}</div>
        <div @click="${this.nextRow}" class="${downClass}">${arrowDown}</div>
      </div>
      <div
        style="max-width: var(--affine-editor-width);display: flex;flex-direction: column;margin: 0 auto"
      >
        ${keyed(this.rowId, this.renderHeader())}
        ${repeat(columns, (v)=>v.id, (column)=>{
                    return keyed(this.rowId, html` <affine-data-view-record-field
                .view="${this.view}"
                .column="${column}"
                .rowId="${this.rowId}"
                data-column-id="${column.id}"
              ></affine-data-view-record-field>`);
                })}
        ${!this.readonly ? html`<div class="add-property" @click="${this._clickAddProperty}">
              <div class="icon">${PlusIcon}</div>
              Add Property
            </div>` : nothing}
        <div style="width: var(--affine-editor-width)"></div>
      </div>
      ${keyed(this.rowId, this.renderNote())}
    `;
            }
            #___private_addPropertyButton_1;
            get addPropertyButton() {
                return this.#___private_addPropertyButton_1;
            }
            set addPropertyButton(_v) {
                this.#___private_addPropertyButton_1 = _v;
            }
            #___private_rowId_2;
            get rowId() {
                return this.#___private_rowId_2;
            }
            set rowId(_v) {
                this.#___private_rowId_2 = _v;
            }
            #___private_view_3;
            get view() {
                return this.#___private_view_3;
            }
            set view(_v) {
                this.#___private_view_3 = _v;
            }
            constructor(...args){
                super(...args);
                this._clickAddProperty = ()=>{
                    popFilterableSimpleMenu(this.addPropertyButton, this.view.allColumnConfig.map((config)=>{
                        return {
                            type: 'action',
                            name: config.name,
                            icon: renderUniLit(this.view.getIcon(config.type)),
                            select: ()=>{
                                this.view.columnAdd('end', config.type);
                            }
                        };
                    }));
                };
                this.columns$ = computed(()=>{
                    return this.view.detailColumns$.value.map((id)=>this.view.columnGet(id));
                });
                this.selection = new DetailSelection(this);
                this.#___private_addPropertyButton_1 = (_initProto(this), _init_addPropertyButton(this));
                this.#___private_rowId_2 = _init_rowId(this);
                this.#___private_view_3 = _init_view(this);
            }
        }
    }
}();
export { _RecordDetail as RecordDetail };
