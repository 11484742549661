import { assertExists } from '@blocksuite/global/utils';
import { getAssetName } from '@blocksuite/store';
import JSZip from 'jszip';
export function createAssetsArchive(assetsMap, assetsIds) {
    const zip = new JSZip();
    const assets = zip.folder('assets');
    assertExists(assetsMap);
    assetsMap.forEach((blob, id)=>{
        if (!assetsIds.includes(id)) return;
        assets?.file(getAssetName(assetsMap, id), blob);
    });
    return zip;
}
export function download(blob, name) {
    const element = document.createElement('a');
    element.setAttribute('download', name);
    const fileURL = URL.createObjectURL(blob);
    element.setAttribute('href', fileURL);
    element.style.display = 'none';
    document.body.append(element);
    element.click();
    element.remove();
    URL.revokeObjectURL(fileURL);
}
