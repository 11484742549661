function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _initClass, _CaptionedBlockComponent, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _init__edgelessImage, _init__imageCard, _init__pageImage, _init_blob, _init_blobUrl, _init_downloading, _init_error, _init_lastSourceId, _init_lastSourceUrl, _init_loading, _init_retryCount, _initProto;
import { Bound } from '@blocksuite/global/utils';
import { html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { CaptionedBlockComponent } from '../_common/components/captioned-block-component.js';
import { Peekable } from '../_common/components/index.js';
import './components/edgeless-image-block.js';
import './components/image-card.js';
import './components/page-image-block.js';
import { copyImageBlob, downloadImageBlob, fetchImageBlob, resetImageSize, turnImageIntoCardView } from './utils.js';
let _ImageBlockComponent;
_dec = customElement('affine-image'), _dec1 = Peekable(), _dec2 = query('affine-edgeless-image'), _dec3 = query('affine-image-block-card'), _dec4 = query('affine-page-image'), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = state(), _dec10 = state(), _dec11 = property({
    attribute: false
}), _dec12 = property({
    attribute: false
});
class ImageBlockComponent extends (_CaptionedBlockComponent = CaptionedBlockComponent) {
    static{
        ({ e: [_init__edgelessImage, _init__imageCard, _init__pageImage, _init_blob, _init_blobUrl, _init_downloading, _init_error, _init_lastSourceId, _init_lastSourceUrl, _init_loading, _init_retryCount, _initProto], c: [_ImageBlockComponent, _initClass] } = _apply_decs_2203_r(this, [
            [
                _dec2,
                1,
                "_edgelessImage"
            ],
            [
                _dec3,
                1,
                "_imageCard"
            ],
            [
                _dec4,
                1,
                "_pageImage"
            ],
            [
                _dec5,
                1,
                "blob"
            ],
            [
                _dec6,
                1,
                "blobUrl"
            ],
            [
                _dec7,
                1,
                "downloading"
            ],
            [
                _dec8,
                1,
                "error"
            ],
            [
                _dec9,
                1,
                "lastSourceId"
            ],
            [
                _dec10,
                1,
                "lastSourceUrl"
            ],
            [
                _dec11,
                1,
                "loading"
            ],
            [
                _dec12,
                1,
                "retryCount"
            ]
        ], [
            _dec,
            _dec1
        ], _CaptionedBlockComponent));
    }
    _handleClick(event) {
        if (event.shiftKey) return;
        event.stopPropagation();
        if (!this.isInSurface) {
            this._selectBlock();
        }
    }
    get _imageElement() {
        const imageElement = this.isInSurface ? this._edgelessImage : this._pageImage;
        return imageElement;
    }
    _selectBlock() {
        const selectionManager = this.host.selection;
        const blockSelection = selectionManager.create('block', {
            blockId: this.blockId
        });
        selectionManager.setGroup('note', [
            blockSelection
        ]);
    }
    connectedCallback() {
        super.connectedCallback();
        this.refreshData();
        this.contentEditable = 'false';
        const parent = this.host.doc.getParent(this.model);
        this._isInSurface = parent?.flavour === 'affine:surface';
        this.blockContainerStyles = this._isInSurface ? undefined : {
            margin: '18px 0'
        };
        this.model.propsUpdated.on(({ key })=>{
            if (key === 'sourceId' || key === 'sourceUrl') {
                this.refreshData();
            }
        });
        if (this._isInSurface) {
            this.style.position = 'absolute';
            this.style.transformOrigin = 'center';
        }
    }
    disconnectedCallback() {
        if (this.blobUrl) {
            URL.revokeObjectURL(this.blobUrl);
        }
        super.disconnectedCallback();
    }
    renderBlock() {
        const containerStyleMap = styleMap({
            position: 'relative',
            width: '100%'
        });
        if (this.isInSurface) {
            const { xywh, rotate } = this.model;
            const bound = Bound.deserialize(xywh);
            this.style.left = `${bound.x}px`;
            this.style.top = `${bound.y}px`;
            this.style.width = `${bound.w}px`;
            this.style.height = `${bound.h}px`;
            this.style.transform = `rotate(${rotate}deg)`;
            this.style.zIndex = `${this.toZIndex()}`;
        }
        return html`
      <div
        class="affine-image-container"
        style=${containerStyleMap}
        @click=${this._handleClick}
      >
        ${this.loading || this.error ? html`<affine-image-block-card
              .block=${this}
            ></affine-image-block-card>` : this.isInSurface ? html`<affine-edgeless-image
                .url=${this.blobUrl}
                @error=${(_)=>{
            this.error = true;
        }}
              ></affine-edgeless-image>` : html`<affine-page-image .block=${this}></affine-page-image>`}
      </div>

      ${this.isInSurface ? nothing : Object.values(this.widgets)}
    `;
    }
    toZIndex() {
        return this.rootService?.layer.getZIndex(this.model) ?? 1;
    }
    updateZIndex() {
        this.style.zIndex = `${this.toZIndex()}`;
    }
    updated() {
        this._imageCard?.requestUpdate();
    }
    get imageCard() {
        return this._imageCard;
    }
    get isInSurface() {
        return this._isInSurface;
    }
    get resizeImg() {
        return this._imageElement?.resizeImg;
    }
    get rootService() {
        const service = this.std.spec.getService('affine:page');
        if (!('surface' in service)) {
            return null;
        }
        return service;
    }
    #___private__edgelessImage_1;
    get _edgelessImage() {
        return this.#___private__edgelessImage_1;
    }
    set _edgelessImage(_v) {
        this.#___private__edgelessImage_1 = _v;
    }
    #___private__imageCard_2;
    get _imageCard() {
        return this.#___private__imageCard_2;
    }
    set _imageCard(_v) {
        this.#___private__imageCard_2 = _v;
    }
    #___private__pageImage_3;
    get _pageImage() {
        return this.#___private__pageImage_3;
    }
    set _pageImage(_v) {
        this.#___private__pageImage_3 = _v;
    }
    #___private_blob_4;
    get blob() {
        return this.#___private_blob_4;
    }
    set blob(_v) {
        this.#___private_blob_4 = _v;
    }
    #___private_blobUrl_5;
    get blobUrl() {
        return this.#___private_blobUrl_5;
    }
    set blobUrl(_v) {
        this.#___private_blobUrl_5 = _v;
    }
    #___private_downloading_6;
    get downloading() {
        return this.#___private_downloading_6;
    }
    set downloading(_v) {
        this.#___private_downloading_6 = _v;
    }
    #___private_error_7;
    get error() {
        return this.#___private_error_7;
    }
    set error(_v) {
        this.#___private_error_7 = _v;
    }
    #___private_lastSourceId_8;
    get lastSourceId() {
        return this.#___private_lastSourceId_8;
    }
    set lastSourceId(_v) {
        this.#___private_lastSourceId_8 = _v;
    }
    #___private_lastSourceUrl_9;
    get lastSourceUrl() {
        return this.#___private_lastSourceUrl_9;
    }
    set lastSourceUrl(_v) {
        this.#___private_lastSourceUrl_9 = _v;
    }
    #___private_loading_10;
    get loading() {
        return this.#___private_loading_10;
    }
    set loading(_v) {
        this.#___private_loading_10 = _v;
    }
    #___private_retryCount_11;
    get retryCount() {
        return this.#___private_retryCount_11;
    }
    set retryCount(_v) {
        this.#___private_retryCount_11 = _v;
    }
    #___private_useCaptionEditor_12;
    get useCaptionEditor() {
        return this.#___private_useCaptionEditor_12;
    }
    set useCaptionEditor(_v) {
        this.#___private_useCaptionEditor_12 = _v;
    }
    static{
        _initClass();
    }
    constructor(...args){
        super(...args);
        this._isInSurface = false;
        this.convertToCardView = ()=>{
            turnImageIntoCardView(this).catch(console.error);
        };
        this.copy = ()=>{
            copyImageBlob(this).catch(console.error);
        };
        this.download = ()=>{
            downloadImageBlob(this).catch(console.error);
        };
        this.refreshData = ()=>{
            this.retryCount = 0;
            fetchImageBlob(this).then(()=>{
                const { width, height } = this.model;
                if (this.isInSurface && !width && !height) {
                    this.resetImageSize();
                }
            }).catch(console.error);
        };
        this.resetImageSize = ()=>{
            resetImageSize(this).catch(console.error);
        };
        this.#___private__edgelessImage_1 = (_initProto(this), _init__edgelessImage(this, null));
        this.#___private__imageCard_2 = _init__imageCard(this, null);
        this.#___private__pageImage_3 = _init__pageImage(this, null);
        this.#___private_blob_4 = _init_blob(this, undefined);
        this.#___private_blobUrl_5 = _init_blobUrl(this, undefined);
        this.#___private_downloading_6 = _init_downloading(this, false);
        this.#___private_error_7 = _init_error(this, false);
        this.#___private_lastSourceId_8 = _init_lastSourceId(this);
        this.#___private_lastSourceUrl_9 = _init_lastSourceUrl(this);
        this.#___private_loading_10 = _init_loading(this, false);
        this.#___private_retryCount_11 = _init_retryCount(this, 0);
        this.#___private_useCaptionEditor_12 = true;
    }
}
export { _ImageBlockComponent as ImageBlockComponent };
