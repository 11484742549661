import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowRightSmallIcon } from '@blocksuite/icons/rc';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { useMemo } from 'react';
import { MenuIcon } from './menu-icon';
import * as styles from './styles.css';
import { useMenuItem } from './use-menu-item';
export const MenuSub = ({ children: propsChildren, items, portalOptions, subOptions, triggerOptions: { className: propsClassName, preFix, endFix, type, ...otherTriggerOptions } = {}, subContentOptions: { className: subContentClassName = '', ...otherSubContentOptions } = {} })=>{
    const { className, children } = useMenuItem({
        children: propsChildren,
        className: propsClassName,
        type,
        preFix,
        endFix
    });
    return _jsxs(DropdownMenu.Sub, {
        ...subOptions,
        children: [
            _jsxs(DropdownMenu.SubTrigger, {
                className: className,
                ...otherTriggerOptions,
                children: [
                    children,
                    _jsx(MenuIcon, {
                        position: "end",
                        children: _jsx(ArrowRightSmallIcon, {})
                    })
                ]
            }),
            _jsx(DropdownMenu.Portal, {
                ...portalOptions,
                children: _jsx(DropdownMenu.SubContent, {
                    sideOffset: 10,
                    className: useMemo(()=>clsx(styles.menuContent, subContentClassName), [
                        subContentClassName
                    ]),
                    ...otherSubContentOptions,
                    children: items
                })
            })
        ]
    });
};
