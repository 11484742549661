import { assertExists } from '@blocksuite/global/utils';
import { useMemo } from 'react';
import { useAllBlockSuiteDocMeta } from './use-all-block-suite-page-meta';
import { useJournalHelper } from './use-journal';
export function useBlockSuiteDocMeta(docCollection) {
    const pageMetas = useAllBlockSuiteDocMeta(docCollection);
    const { isPageJournal } = useJournalHelper(docCollection);
    return useMemo(()=>pageMetas.filter((pageMeta)=>!isPageJournal(pageMeta.id) || !!pageMeta.updatedDate), [
        isPageJournal,
        pageMetas
    ]);
}
export function useDocMetaHelper(docCollection) {
    return useMemo(()=>({
            setDocTitle: (docId, newTitle)=>{
                const page = docCollection.getDoc(docId);
                assertExists(page);
                const pageBlock = page.getBlockByFlavour('affine:page').at(0);
                assertExists(pageBlock);
                page.transact(()=>{
                    pageBlock.title.delete(0, pageBlock.title.length);
                    pageBlock.title.insert(newTitle, 0);
                });
                docCollection.meta.setDocMeta(docId, {
                    title: newTitle
                });
            },
            setDocReadonly: (docId, readonly)=>{
                const page = docCollection.getDoc(docId);
                assertExists(page);
                page.awarenessStore.setReadonly(page.blockCollection, readonly);
            },
            setDocMeta: (docId, docMeta)=>{
                docCollection.meta.setDocMeta(docId, docMeta);
            },
            getDocMeta: (docId)=>{
                return docCollection.meta.getDocMeta(docId);
            }
        }), [
        docCollection
    ]);
}
