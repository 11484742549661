function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _init__selectedColor, _init__selectedSize, _init_colorButton, _init_edgeless, _init_elements, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import '../../../_common/components/toolbar/icon-button.js';
import '../../../_common/components/toolbar/menu-button.js';
import '../../../_common/components/toolbar/separator.js';
import { LineWidth } from '../../../_common/types.js';
import { countBy, maxBy } from '../../../_common/utils/iterable.js';
import { packColor, packColorsWithColorScheme } from '../../edgeless/components/color-picker/utils.js';
import '../../edgeless/components/panel/color-panel.js';
import { GET_DEFAULT_LINE_COLOR, LINE_COLORS } from '../../edgeless/components/panel/color-panel.js';
import '../../edgeless/components/panel/line-width-panel.js';
function getMostCommonColor(elements, colorScheme) {
    const colors = countBy(elements, (ele)=>{
        return typeof ele.color === 'object' ? ele.color[colorScheme] ?? ele.color.normal ?? null : ele.color;
    });
    const max = maxBy(Object.entries(colors), ([_k, count])=>count);
    return max ? max[0] : GET_DEFAULT_LINE_COLOR();
}
function getMostCommonSize(elements) {
    const sizes = countBy(elements, (ele)=>ele.lineWidth);
    const max = maxBy(Object.entries(sizes), ([_k, count])=>count);
    return max ? Number(max[0]) : LineWidth.Four;
}
function notEqual(key, value) {
    return (element)=>element[key] !== value;
}
let _EdgelessChangeBrushButton;
_dec = customElement('edgeless-change-brush-button'), _dec1 = state(), _dec2 = state(), _dec3 = query('edgeless-color-picker-button.color'), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
});
class EdgelessChangeBrushButton extends (_WithDisposable = WithDisposable(LitElement)) {
    static{
        ({ e: [_init__selectedColor, _init__selectedSize, _init_colorButton, _init_edgeless, _init_elements, _initProto], c: [_EdgelessChangeBrushButton, _initClass] } = _apply_decs_2203_r(this, [
            [
                _dec1,
                1,
                "_selectedColor"
            ],
            [
                _dec2,
                1,
                "_selectedSize"
            ],
            [
                _dec3,
                1,
                "colorButton"
            ],
            [
                _dec4,
                1,
                "edgeless"
            ],
            [
                _dec5,
                1,
                "elements"
            ]
        ], [
            _dec
        ], _WithDisposable));
    }
    _setBrushProp(key, value) {
        this.doc.captureSync();
        this.elements.filter(notEqual(key, value)).forEach((element)=>this.service.updateElement(element.id, {
                [key]: value
            }));
    }
    render() {
        const colorScheme = this.edgeless.surface.renderer.getColorScheme();
        const elements = this.elements;
        const { selectedSize, selectedColor } = this;
        return html`
      <edgeless-line-width-panel
        .selectedSize=${selectedSize}
        @select=${this._setLineWidth}
      >
      </edgeless-line-width-panel>

      <editor-toolbar-separator></editor-toolbar-separator>

      ${when(this.edgeless.doc.awarenessStore.getFlag('enable_color_picker'), ()=>{
            const { type, colors } = packColorsWithColorScheme(colorScheme, selectedColor, elements[0].color);
            return html`
            <edgeless-color-picker-button
              class="color"
              .label=${'Color'}
              .pick=${this.pickColor}
              .color=${selectedColor}
              .colors=${colors}
              .colorType=${type}
              .palettes=${LINE_COLORS}
            >
            </edgeless-color-picker-button>
          `;
        }, ()=>html`
          <editor-menu-button
            .contentPadding=${'8px'}
            .button=${html`
              <editor-icon-button aria-label="Color" .tooltip=${'Color'}>
                <edgeless-color-button
                  .color=${selectedColor}
                ></edgeless-color-button>
              </editor-icon-button>
            `}
          >
            <edgeless-color-panel
              .value=${selectedColor}
              @select=${this._setBrushColor}
            >
            </edgeless-color-panel>
          </editor-menu-button>
        `)}
    `;
    }
    get doc() {
        return this.edgeless.doc;
    }
    get selectedColor() {
        const colorScheme = this.edgeless.surface.renderer.getColorScheme();
        return this._selectedColor ?? getMostCommonColor(this.elements, colorScheme);
    }
    get selectedSize() {
        return this._selectedSize ?? getMostCommonSize(this.elements);
    }
    get service() {
        return this.surface.edgeless.service;
    }
    get surface() {
        return this.edgeless.surface;
    }
    #___private__selectedColor_1;
    get _selectedColor() {
        return this.#___private__selectedColor_1;
    }
    set _selectedColor(_v) {
        this.#___private__selectedColor_1 = _v;
    }
    #___private__selectedSize_2;
    get _selectedSize() {
        return this.#___private__selectedSize_2;
    }
    set _selectedSize(_v) {
        this.#___private__selectedSize_2 = _v;
    }
    #___private_colorButton_3;
    get colorButton() {
        return this.#___private_colorButton_3;
    }
    set colorButton(_v) {
        this.#___private_colorButton_3 = _v;
    }
    #___private_edgeless_4;
    get edgeless() {
        return this.#___private_edgeless_4;
    }
    set edgeless(_v) {
        this.#___private_edgeless_4 = _v;
    }
    #___private_elements_5;
    get elements() {
        return this.#___private_elements_5;
    }
    set elements(_v) {
        this.#___private_elements_5 = _v;
    }
    static{
        _initClass();
    }
    constructor(...args){
        super(...args);
        this._setBrushColor = ({ detail: color })=>{
            this._setBrushProp('color', color);
            this._selectedColor = color;
        };
        this._setLineWidth = ({ detail: lineWidth })=>{
            this._setBrushProp('lineWidth', lineWidth);
            this._selectedSize = lineWidth;
        };
        this.pickColor = (event)=>{
            if (event.type === 'pick') {
                this.elements.forEach((ele)=>this.service.updateElement(ele.id, packColor('color', {
                        ...event.detail
                    })));
                return;
            }
            this.elements.forEach((ele)=>ele[event.type === 'start' ? 'stash' : 'pop']('color'));
        };
        this.#___private__selectedColor_1 = (_initProto(this), _init__selectedColor(this, null));
        this.#___private__selectedSize_2 = _init__selectedSize(this, null);
        this.#___private_colorButton_3 = _init_colorButton(this);
        this.#___private_edgeless_4 = _init_edgeless(this);
        this.#___private_elements_5 = _init_elements(this, []);
    }
}
export function renderChangeBrushButton(edgeless, elements) {
    if (!elements?.length) return nothing;
    return html`
    <edgeless-change-brush-button .elements=${elements} .edgeless=${edgeless}>
    </edgeless-change-brush-button>
  `;
}
export { _EdgelessChangeBrushButton as EdgelessChangeBrushButton };
