class BaseAIError extends Error {
}
export var AIErrorType;
(function(AIErrorType) {
    AIErrorType["GeneralNetworkError"] = "GeneralNetworkError";
    AIErrorType["PaymentRequired"] = "PaymentRequired";
    AIErrorType["Unauthorized"] = "Unauthorized";
})(AIErrorType || (AIErrorType = {}));
export class UnauthorizedError extends BaseAIError {
    constructor(){
        super('Unauthorized');
        this.type = "Unauthorized";
    }
}
export class PaymentRequiredError extends BaseAIError {
    constructor(){
        super('Payment required');
        this.type = "PaymentRequired";
    }
}
export class GeneralNetworkError extends BaseAIError {
    constructor(message = 'Network error'){
        super(message);
        this.type = "GeneralNetworkError";
    }
}
