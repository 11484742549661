function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _LitElement, _dec1, _dec2, _dec3, _init_color, _init_hollowCircle, _init_letter, _initProto, _dec4, _initClass1, _LitElement1, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _init_hasTransparent, _init_hollowCircle1, _init_openColorPicker, _init_options, _init_showLetterMark, _init_value, _initProto1, _dec11, _initClass2, _LitElement2, _dec12, _init_color1, _initProto2;
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { TransparentIcon } from '../../../../_common/icons/index.js';
import { ThemeObserver } from '../../../../_common/theme/theme-observer.js';
import { createZodUnion } from '../../../../_common/utils/index.js';
export class ColorEvent extends Event {
    constructor(type, { detail, composed, bubbles }){
        super(type, {
            bubbles,
            composed
        });
        this.detail = detail;
    }
}
export const LINE_COLORS = [
    '--affine-palette-line-yellow',
    '--affine-palette-line-orange',
    '--affine-palette-line-red',
    '--affine-palette-line-magenta',
    '--affine-palette-line-purple',
    '--affine-palette-line-blue',
    '--affine-palette-line-teal',
    '--affine-palette-line-green',
    '--affine-palette-line-black',
    '--affine-palette-line-grey',
    '--affine-palette-line-white'
];
export const LineColorsSchema = createZodUnion(LINE_COLORS);
export const GET_DEFAULT_LINE_COLOR = ()=>ThemeObserver.mode === 'dark' ? LINE_COLORS[10] : LINE_COLORS[8];
export const GET_DEFAULT_TEXT_COLOR = ()=>LINE_COLORS[5];
export const DEFAULT_BRUSH_COLOR = LINE_COLORS[5];
export const DEFAULT_CONNECTOR_COLOR = LINE_COLORS[9];
export function isTransparent(color) {
    return color.toLowerCase().endsWith('transparent');
}
function isSameColorWithBackground(color) {
    return [
        '--affine-note-background-black',
        '--affine-note-background-white',
        '--affine-palette-line-black',
        '--affine-palette-line-white',
        '--affine-palette-shape-black',
        '--affine-palette-shape-white'
    ].includes(color.toLowerCase());
}
function TransparentColor(hollowCircle = false) {
    const containerStyle = {
        position: 'relative',
        width: '16px',
        height: '16px',
        stroke: 'none'
    };
    const maskStyle = {
        position: 'absolute',
        width: '10px',
        height: '10px',
        left: '3px',
        top: '3.5px',
        borderRadius: '50%',
        background: 'var(--affine-background-overlay-panel-color)'
    };
    const mask = hollowCircle ? html`<div style=${styleMap(maskStyle)}></div>` : nothing;
    return html`
    <div style=${styleMap(containerStyle)}>${TransparentIcon} ${mask}</div>
  `;
}
function BorderedHollowCircle(color) {
    const valid = color.startsWith('--');
    const strokeWidth = valid && isSameColorWithBackground(color) ? 1 : 0;
    const style = {
        fill: valid ? `var(${color})` : color,
        stroke: 'var(--affine-border-color)'
    };
    return html`
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3125 8C12.3125 10.3817 10.3817 12.3125 8 12.3125C5.61827 12.3125 3.6875 10.3817 3.6875 8C3.6875 5.61827 5.61827 3.6875 8 3.6875C10.3817 3.6875 12.3125 5.61827 12.3125 8ZM8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z"
        stroke-width="${strokeWidth}"
        style=${styleMap(style)}
      />
    </svg>
  `;
}
function AdditionIcon(color, hollowCircle) {
    if (isTransparent(color)) {
        return TransparentColor(hollowCircle);
    }
    if (hollowCircle) {
        return BorderedHollowCircle(color);
    }
    return nothing;
}
export function ColorUnit(color, { hollowCircle, letter } = {}) {
    const additionIcon = AdditionIcon(color, !!hollowCircle);
    const colorStyle = !hollowCircle && !isTransparent(color) ? {
        background: `var(${color})`
    } : {};
    const borderStyle = isSameColorWithBackground(color) && !hollowCircle ? {
        border: '0.5px solid var(--affine-border-color)'
    } : {};
    const style = {
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        boxSizing: 'border-box',
        overflow: 'hidden',
        ...borderStyle,
        ...colorStyle
    };
    return html`
    <div
      class="color-unit"
      style=${styleMap(style)}
      aria-label=${color.toLowerCase()}
      data-letter=${letter ? 'A' : ''}
    >
      ${additionIcon}
    </div>
  `;
}
let _EdgelessColorButton;
_dec = customElement('edgeless-color-button'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EdgelessColorButton), _initClass();
    }
    static{
        class EdgelessColorButton extends (_LitElement = LitElement) {
            static{
                ({ e: [_init_color, _init_hollowCircle, _init_letter, _initProto], c: [_EdgelessColorButton, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "color"
                    ],
                    [
                        _dec2,
                        1,
                        "hollowCircle"
                    ],
                    [
                        _dec3,
                        1,
                        "letter"
                    ]
                ], [
                    _dec
                ], _LitElement));
            }
            static{
                this.styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
    }

    .color-unit {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      box-sizing: border-box;
      overflow: hidden;
    }
  `;
            }
            render() {
                const { color, hollowCircle, letter } = this;
                const additionIcon = AdditionIcon(color, !!hollowCircle);
                const style = {};
                if (!hollowCircle) {
                    style.background = this.preprocessColor;
                    if (isSameColorWithBackground(color)) {
                        style.border = '0.5px solid var(--affine-border-color)';
                    }
                }
                return html`<div
      class="color-unit"
      aria-label=${color.toLowerCase()}
      data-letter=${letter ? 'A' : nothing}
      style=${styleMap(style)}
    >
      ${additionIcon}
    </div>`;
            }
            get preprocessColor() {
                const color = this.color;
                return color.startsWith('--') ? `var(${color})` : color;
            }
            #___private_color_1;
            get color() {
                return this.#___private_color_1;
            }
            set color(_v) {
                this.#___private_color_1 = _v;
            }
            #___private_hollowCircle_2;
            get hollowCircle() {
                return this.#___private_hollowCircle_2;
            }
            set hollowCircle(_v) {
                this.#___private_hollowCircle_2 = _v;
            }
            #___private_letter_3;
            get letter() {
                return this.#___private_letter_3;
            }
            set letter(_v) {
                this.#___private_letter_3 = _v;
            }
            constructor(...args){
                super(...args);
                this.#___private_color_1 = (_initProto(this), _init_color(this));
                this.#___private_hollowCircle_2 = _init_hollowCircle(this, undefined);
                this.#___private_letter_3 = _init_letter(this, undefined);
            }
        }
    }
}();
export const colorContainerStyles = css`
  .color-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    padding: 2px;
  }

  .color-unit::before {
    content: attr(data-letter);
    display: block;
    font-size: 12px;
  }

  .color-container[active]:after {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 0.5px solid var(--affine-primary-color);
    border-radius: 50%;
    box-sizing: border-box;
    content: attr(data-letter);
  }
`;
let _EdgelessColorPanel;
_dec4 = customElement('edgeless-color-panel'), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property(), _dec8 = property({
    type: Array
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EdgelessColorPanel), _initClass1();
    }
    static{
        class EdgelessColorPanel extends (_LitElement1 = LitElement) {
            static{
                ({ e: [_init_hasTransparent, _init_hollowCircle1, _init_openColorPicker, _init_options, _init_showLetterMark, _init_value, _initProto1], c: [_EdgelessColorPanel, _initClass1] } = _apply_decs_2203_r(this, [
                    [
                        _dec5,
                        1,
                        "hasTransparent"
                    ],
                    [
                        _dec6,
                        1,
                        "hollowCircle"
                    ],
                    [
                        _dec7,
                        1,
                        "openColorPicker"
                    ],
                    [
                        _dec8,
                        1,
                        "options"
                    ],
                    [
                        _dec9,
                        1,
                        "showLetterMark"
                    ],
                    [
                        _dec10,
                        1,
                        "value"
                    ]
                ], [
                    _dec4
                ], _LitElement1));
            }
            static{
                this.styles = css`
    :host {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 184px;
      gap: 8px;
    }

    ${colorContainerStyles}
  `;
            }
            onSelect(value) {
                this.dispatchEvent(new ColorEvent('select', {
                    detail: value,
                    composed: true,
                    bubbles: true
                }));
                this.value = value;
            }
            render() {
                return html`
      ${repeat(this.palettes, (color)=>color, (color)=>{
                    const unit = ColorUnit(color, {
                        hollowCircle: this.hollowCircle,
                        letter: this.showLetterMark
                    });
                    return html`
            <div
              class="color-container"
              ?active=${color === this.value}
              @click=${()=>this.onSelect(color)}
            >
              ${unit}
            </div>
          `;
                })}
      </div>
      <slot name="custom"></slot>
    `;
            }
            get palettes() {
                return this.hasTransparent ? [
                    '--affine-palette-transparent',
                    ...this.options
                ] : this.options;
            }
            #___private_hasTransparent_1;
            get hasTransparent() {
                return this.#___private_hasTransparent_1;
            }
            set hasTransparent(_v) {
                this.#___private_hasTransparent_1 = _v;
            }
            #___private_hollowCircle_2;
            get hollowCircle() {
                return this.#___private_hollowCircle_2;
            }
            set hollowCircle(_v) {
                this.#___private_hollowCircle_2 = _v;
            }
            #___private_openColorPicker_3;
            get openColorPicker() {
                return this.#___private_openColorPicker_3;
            }
            set openColorPicker(_v) {
                this.#___private_openColorPicker_3 = _v;
            }
            #___private_options_4;
            get options() {
                return this.#___private_options_4;
            }
            set options(_v) {
                this.#___private_options_4 = _v;
            }
            #___private_showLetterMark_5;
            get showLetterMark() {
                return this.#___private_showLetterMark_5;
            }
            set showLetterMark(_v) {
                this.#___private_showLetterMark_5 = _v;
            }
            #___private_value_6;
            get value() {
                return this.#___private_value_6;
            }
            set value(_v) {
                this.#___private_value_6 = _v;
            }
            constructor(...args){
                super(...args);
                this.#___private_hasTransparent_1 = (_initProto1(this), _init_hasTransparent(this, true));
                this.#___private_hollowCircle_2 = _init_hollowCircle1(this, false);
                this.#___private_openColorPicker_3 = _init_openColorPicker(this);
                this.#___private_options_4 = _init_options(this, LINE_COLORS);
                this.#___private_showLetterMark_5 = _init_showLetterMark(this, false);
                this.#___private_value_6 = _init_value(this, null);
            }
        }
    }
}();
let _EdgelessTextColorIcon;
_dec11 = customElement('edgeless-text-color-icon'), _dec12 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EdgelessTextColorIcon), _initClass2();
    }
    static{
        class EdgelessTextColorIcon extends (_LitElement2 = LitElement) {
            static{
                ({ e: [_init_color1, _initProto2], c: [_EdgelessTextColorIcon, _initClass2] } = _apply_decs_2203_r(this, [
                    [
                        _dec12,
                        1,
                        "color"
                    ]
                ], [
                    _dec11
                ], _LitElement2));
            }
            static{
                this.styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
    }
  `;
            }
            render() {
                return html`
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="currentColor"
          d="M8.71093 3.85123C8.91241 3.31395 9.42603 2.95801 9.99984 2.95801C10.5737 2.95801 11.0873 3.31395 11.2888 3.85123L14.7517 13.0858C14.8729 13.409 14.7092 13.7692 14.386 13.8904C14.0628 14.0116 13.7025 13.8479 13.5813 13.5247L12.5648 10.8141H7.43487L6.41838 13.5247C6.29718 13.8479 5.93693 14.0116 5.61373 13.8904C5.29052 13.7692 5.12677 13.409 5.24797 13.0858L8.71093 3.85123ZM7.90362 9.56405H12.0961L10.1183 4.29013C10.0998 4.24073 10.0526 4.20801 9.99984 4.20801C9.94709 4.20801 9.89986 4.24073 9.88134 4.29013L7.90362 9.56405Z"
        />
        <rect
          x="3.3335"
          y="15"
          width="13.3333"
          height="2.08333"
          rx="1"
          fill=${this.preprocessColor}
        />
      </svg>
    `;
            }
            get preprocessColor() {
                const color = this.color;
                return color.startsWith('--') ? `var(${color})` : color;
            }
            #___private_color_1;
            get color() {
                return this.#___private_color_1;
            }
            set color(_v) {
                this.#___private_color_1 = _v;
            }
            constructor(...args){
                super(...args);
                this.#___private_color_1 = (_initProto2(this), _init_color1(this));
            }
        }
    }
}();
export { _EdgelessColorButton as EdgelessColorButton, _EdgelessColorPanel as EdgelessColorPanel, _EdgelessTextColorIcon as EdgelessTextColorIcon };
