import { BlockModel } from '@blocksuite/store';
import { css, unsafeCSS } from 'lit';
import { isControlledKeyboardEvent } from '../../_common/utils/event.js';
import { getInlineEditorByModel } from '../../_common/utils/query.js';
import { getCurrentNativeRange } from '../../_common/utils/selection.js';
export function getQuery(inlineEditor, startRange) {
    const nativeRange = getCurrentNativeRange();
    if (!nativeRange) {
        return null;
    }
    if (nativeRange.startContainer !== nativeRange.endContainer) {
        console.warn('Failed to parse query! Current range is not collapsed.', nativeRange);
        return null;
    }
    const textNode = nativeRange.startContainer;
    if (textNode.nodeType !== Node.TEXT_NODE) {
        console.warn('Failed to parse query! Current range is not a text node.', nativeRange);
        return null;
    }
    const curRange = inlineEditor.getInlineRange();
    if (!startRange || !curRange) {
        return null;
    }
    if (curRange.index < startRange.index) {
        return null;
    }
    const text = inlineEditor.yText.toString();
    return text.slice(startRange.index, curRange.index);
}
export const createKeydownObserver = ({ target, signal, inlineEditor, onInput, onDelete, onMove, onConfirm, onAbort, onPaste, interceptor = (_, next)=>next() })=>{
    const waitForInlineEditorUpdated = (fn)=>{
        inlineEditor.slots.inlineRangeUpdate.once(fn);
    };
    const keyDownListener = (e)=>{
        if (e.defaultPrevented) return;
        if (isControlledKeyboardEvent(e)) {
            const isOnlyCmd = (e.ctrlKey || e.metaKey) && !e.altKey && !e.shiftKey;
            if (isOnlyCmd && e.key.length === 1) {
                switch(e.key){
                    case 'p':
                        {
                            onMove?.(-1);
                            e.stopPropagation();
                            e.preventDefault();
                            return;
                        }
                    case 'n':
                        {
                            onMove?.(1);
                            e.stopPropagation();
                            e.preventDefault();
                            return;
                        }
                    case 'v':
                        {
                            onPaste?.();
                            return;
                        }
                }
            }
            if (e.key === 'Control' || e.key === 'Meta' || e.key === 'Alt') {
                e.stopPropagation();
                return;
            }
            onAbort?.();
            return;
        }
        e.stopPropagation();
        if (!isControlledKeyboardEvent(e) && e.key.length === 1 || e.isComposing) {
            waitForInlineEditorUpdated(()=>onInput?.());
            return;
        }
        switch(e.key){
            case 'Escape':
                {
                    onAbort?.();
                    return;
                }
            case 'Backspace':
                {
                    waitForInlineEditorUpdated(()=>onDelete?.());
                    return;
                }
            case 'Enter':
                {
                    if (e.shiftKey) {
                        onAbort?.();
                        return;
                    }
                    onConfirm?.();
                    e.preventDefault();
                    return;
                }
            case 'Tab':
                {
                    if (e.shiftKey) {
                        onMove?.(-1);
                    } else {
                        onMove?.(1);
                    }
                    e.preventDefault();
                    return;
                }
            case 'ArrowUp':
                {
                    if (e.shiftKey) {
                        onAbort?.();
                        return;
                    }
                    onMove?.(-1);
                    e.preventDefault();
                    return;
                }
            case 'ArrowDown':
                {
                    if (e.shiftKey) {
                        onAbort?.();
                        return;
                    }
                    onMove?.(1);
                    e.preventDefault();
                    return;
                }
            case 'ArrowLeft':
            case 'ArrowRight':
                {
                    onAbort?.();
                    return;
                }
            default:
                return;
        }
    };
    target.addEventListener('keydown', (e)=>interceptor(e, ()=>keyDownListener(e)), {
        capture: true,
        signal
    });
    target.addEventListener('paste', ()=>waitForInlineEditorUpdated(()=>onInput?.()), {
        signal
    });
    target.addEventListener('input', ()=>waitForInlineEditorUpdated(()=>onInput?.()), {
        signal
    });
};
export function cleanSpecifiedTail(editorHost, inlineEditorOrModel, str) {
    if (!str) {
        console.warn('Failed to clean text! Unexpected empty string');
        return;
    }
    const inlineEditor = inlineEditorOrModel instanceof BlockModel ? getInlineEditorByModel(editorHost, inlineEditorOrModel) : inlineEditorOrModel;
    if (!inlineEditor) {
        return;
    }
    const inlineRange = inlineEditor.getInlineRange();
    if (!inlineRange) {
        return;
    }
    const idx = inlineRange.index - str.length;
    const textStr = inlineEditor.yText.toString().slice(idx, idx + str.length);
    if (textStr !== str) {
        console.warn(`Failed to clean text! Text mismatch expected: ${str} but actual: ${textStr}`);
        return;
    }
    inlineEditor.deleteText({
        index: idx,
        length: str.length
    });
    inlineEditor.setInlineRange({
        index: idx,
        length: 0
    });
}
export const scrollbarStyle = (container)=>{
    if (!container) {
        console.error('To prevent style pollution of the whole doc, you must add a container before the scrollbar style.');
        return css``;
    }
    if (container.includes('{') || container.includes('}')) {
        console.error('Invalid container name! Please use a valid CSS selector.');
        return css``;
    }
    return css`
    ${unsafeCSS(container)} {
      scrollbar-gutter: stable;
    }
    ${unsafeCSS(container)}::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
    }
    ${unsafeCSS(container)}::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #b1b1b1;
    }
    ${unsafeCSS(container)}::-webkit-scrollbar-corner {
      display: none;
    }
  `;
};
