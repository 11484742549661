import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { apis } from '@affine/electron-api';
import { ThemeProvider as NextThemeProvider, useTheme } from 'next-themes';
import { memo, useRef } from 'react';
const themes = [
    'dark',
    'light'
];
const DesktopThemeSync = memo(function DesktopThemeSync() {
    const { theme } = useTheme();
    const lastThemeRef = useRef(theme);
    const onceRef = useRef(false);
    if (lastThemeRef.current !== theme || !onceRef.current) {
        if (environment.isDesktop && theme) {
            apis?.ui.handleThemeChange(theme).catch((err)=>{
                console.error(err);
            });
        }
        lastThemeRef.current = theme;
        onceRef.current = true;
    }
    return null;
});
export const ThemeProvider = ({ children })=>{
    return _jsxs(NextThemeProvider, {
        themes: themes,
        enableSystem: true,
        children: [
            children,
            _jsx(DesktopThemeSync, {})
        ]
    });
};
