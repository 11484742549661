function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _init__dragging, _init__selected, _init_doc, _init_domHost, _init_edgeless, _init_editorHost, _init_fitPadding, _init_frameListContainer, _init_insertIndex, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { generateKeyBetween } from '@blocksuite/blocks';
import { Bound, DisposableGroup } from '@blocksuite/global/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import '../card/frame-card.js';
import { startDragging } from '../utils/drag.js';
const styles = css`
  .frame-list-container {
    display: flex;
    align-items: start;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    position: relative;
    margin: 0 8px;
  }

  .no-frame-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 300px;
  }

  .no-frame-placeholder {
    margin-top: 240px;
    align-self: center;
    width: 230px;
    height: 48px;
    color: var(--affine-text-secondary-color, #8e8d91);
    text-align: center;

    /* light/base */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .insert-indicator {
    height: 2px;
    border-radius: 1px;
    background-color: var(--affine-blue-600);
    position: absolute;
    contain: layout size;
    width: 284px;
    left: 0;
  }
`;
export const AFFINE_FRAME_PANEL_BODY = 'affine-frame-panel-body';
let _FramePanelBody;
_dec = customElement(AFFINE_FRAME_PANEL_BODY), _dec1 = state(), _dec2 = state(), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = query('.frame-list-container'), _dec9 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_FramePanelBody), _initClass();
    }
    static{
        class FramePanelBody extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init__dragging, _init__selected, _init_doc, _init_domHost, _init_edgeless, _init_editorHost, _init_fitPadding, _init_frameListContainer, _init_insertIndex, _initProto], c: [_FramePanelBody, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_dragging"
                    ],
                    [
                        _dec2,
                        1,
                        "_selected"
                    ],
                    [
                        _dec3,
                        1,
                        "doc"
                    ],
                    [
                        _dec4,
                        1,
                        "domHost"
                    ],
                    [
                        _dec5,
                        1,
                        "edgeless"
                    ],
                    [
                        _dec6,
                        1,
                        "editorHost"
                    ],
                    [
                        _dec7,
                        1,
                        "fitPadding"
                    ],
                    [
                        _dec8,
                        1,
                        "frameListContainer"
                    ],
                    [
                        _dec9,
                        1,
                        "insertIndex"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = styles;
            }
            _drag(e) {
                if (!this._selected.length) return;
                this._dragging = true;
                const framesMap = this._frameItems.reduce((map, frame)=>{
                    map.set(frame.frame.id, {
                        ...frame
                    });
                    return map;
                }, new Map());
                const selected = this._selected.slice();
                const draggedFramesInfo = selected.map((id)=>{
                    const frame = framesMap.get(id);
                    return {
                        frame: frame.frame,
                        element: this.renderRoot.querySelector(`[data-frame-id="${frame.frame.id}"]`),
                        cardIndex: frame.cardIndex,
                        frameIndex: frame.frameIndex
                    };
                });
                const width = draggedFramesInfo[0].element.clientWidth;
                this._frameElementHeight = draggedFramesInfo[0].element.offsetHeight;
                startDragging(draggedFramesInfo, {
                    width,
                    container: this,
                    document: this.ownerDocument,
                    domHost: this.domHost ?? this.ownerDocument,
                    start: {
                        x: e.detail.clientX,
                        y: e.detail.clientY
                    },
                    framePanelBody: this,
                    frameListContainer: this.frameListContainer,
                    frameElementHeight: this._frameElementHeight,
                    edgeless: this.edgeless,
                    doc: this.doc,
                    editorHost: this.editorHost,
                    onDragEnd: (insertIdx)=>{
                        this._dragging = false;
                        this.insertIndex = undefined;
                        if (insertIdx === undefined) return;
                        this._reorderFrames(selected, framesMap, insertIdx);
                    },
                    onDragMove: (idx, indicatorTranslateY)=>{
                        this.insertIndex = idx;
                        this._indicatorTranslateY = indicatorTranslateY ?? 0;
                    }
                });
            }
            _fitToElement(e) {
                const { block } = e.detail;
                const bound = Bound.deserialize(block.xywh);
                if (!this.edgeless) {
                    const viewport = {
                        xywh: block.xywh,
                        referenceId: block.id,
                        padding: this.viewportPadding
                    };
                    const rootService = this.editorHost.spec.getService('affine:page');
                    rootService.editPropsStore.setStorage('viewport', viewport);
                    rootService.docModeService.setMode('edgeless');
                } else {
                    this.edgeless.service.viewport.setViewportByBound(bound, this.viewportPadding, true);
                }
            }
            _renderEmptyContent() {
                const emptyContent = html` <div class="no-frame-container">
      <div class="no-frame-placeholder">
        Add frames to organize and present your Edgeless
      </div>
    </div>`;
                return emptyContent;
            }
            _renderFrameList() {
                const selectedFrames = new Set(this._selected);
                const frameCards = html`${repeat(this._frameItems, (frameItem)=>[
                        frameItem.frame.id,
                        frameItem.cardIndex
                    ].join('-'), (frameItem)=>{
                    const { frame, frameIndex, cardIndex } = frameItem;
                    return html`<affine-frame-card
          data-frame-id=${frame.id}
          .edgeless=${this.edgeless}
          .doc=${this.doc}
          .host=${this.editorHost}
          .frame=${frame}
          .cardIndex=${cardIndex}
          .frameIndex=${frameIndex}
          .status=${selectedFrames.has(frame.id) ? this._dragging ? 'placeholder' : 'selected' : 'none'}
          @select=${this._selectFrame}
          @fitview=${this._fitToElement}
          @drag=${this._drag}
        ></affine-frame-card>`;
                })}`;
                const frameList = html` <div class="frame-list-container">
      ${this.insertIndex !== undefined ? html`<div
            class="insert-indicator"
            style=${`transform: translateY(${this._indicatorTranslateY}px)`}
          ></div>` : nothing}
      ${frameCards}
    </div>`;
                return frameList;
            }
            _reorderFrames(selected, framesMap, insertIndex) {
                if (insertIndex >= 0 && insertIndex <= this._frameItems.length) {
                    const frames = Array.from(framesMap.values()).map((frameItem)=>frameItem.frame);
                    const selectedFrames = selected.map((id)=>framesMap.get(id)).map((frameItem)=>frameItem.frame).sort(this.compare);
                    let before = frames[insertIndex - 1]?.index || null;
                    const after = frames[insertIndex]?.index || null;
                    selectedFrames.forEach((frame)=>{
                        const newIndex = generateKeyBetween(before, after);
                        frame.doc.updateBlock(frame, {
                            index: newIndex
                        });
                        before = newIndex;
                    });
                    this.doc.captureSync();
                    this._updateFrames();
                }
            }
            _setDocDisposables(doc) {
                this._clearDocDisposables();
                this._docDisposables = new DisposableGroup();
                this._docDisposables.add(doc.slots.blockUpdated.on(({ flavour })=>{
                    if (flavour === 'affine:frame') {
                        requestAnimationFrame(()=>{
                            this._updateFrames();
                        });
                    }
                }));
            }
            _updateFrames() {
                if (this._dragging) return;
                if (!this.frames.length) {
                    this._selected = [];
                    this._frameItems = [];
                    return;
                }
                const frameItems = [];
                const oldSelectedSet = new Set(this._selected);
                const newSelected = [];
                const frames = this.frames.sort(this.compare);
                frames.forEach((frame, idx)=>{
                    const frameItem = {
                        frame,
                        frameIndex: frame.index,
                        cardIndex: idx
                    };
                    frameItems.push(frameItem);
                    if (oldSelectedSet.has(frame.id)) {
                        newSelected.push(frame.id);
                    }
                });
                this._frameItems = frameItems;
                this._selected = newSelected;
                this.requestUpdate();
            }
            compare(a, b) {
                if (a.index < b.index) return -1;
                else if (a.index > b.index) return 1;
                return 0;
            }
            connectedCallback() {
                super.connectedCallback();
                this._updateFrameItems();
                if (this.edgeless) {
                    this._lastEdgelessRootId = this.edgeless.model.id;
                }
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this._clearDocDisposables();
            }
            firstUpdated() {
                const disposables = this.disposables;
                disposables.addFromEvent(this, 'click', this._clickBlank);
            }
            render() {
                this._updateFrameItems();
                return html` ${this._frameItems.length ? this._renderFrameList() : this._renderEmptyContent()}`;
            }
            updated(_changedProperties) {
                if (_changedProperties.has('doc') || _changedProperties.has('edgeless')) {
                    this._setDocDisposables(this.doc);
                }
                if (_changedProperties.has('edgeless') && this.edgeless) {
                    if (this.edgeless.model.id === this._lastEdgelessRootId) {
                        this.edgeless.service.selection.set({
                            elements: this._selected,
                            editing: false
                        });
                    } else {
                        this._selected = [];
                    }
                    this._lastEdgelessRootId = this.edgeless.model.id;
                }
            }
            get frames() {
                const frames = this.doc.getBlockByFlavour('affine:frame');
                return frames.sort(this.compare);
            }
            get viewportPadding() {
                return this.fitPadding ? [
                    0,
                    0,
                    0,
                    0
                ].map((val, idx)=>Number.isFinite(this.fitPadding[idx]) ? this.fitPadding[idx] : val) : [
                    0,
                    0,
                    0,
                    0
                ];
            }
            #___private__dragging_1;
            get _dragging() {
                return this.#___private__dragging_1;
            }
            set _dragging(_v) {
                this.#___private__dragging_1 = _v;
            }
            #___private__selected_2;
            get _selected() {
                return this.#___private__selected_2;
            }
            set _selected(_v) {
                this.#___private__selected_2 = _v;
            }
            #___private_doc_3;
            get doc() {
                return this.#___private_doc_3;
            }
            set doc(_v) {
                this.#___private_doc_3 = _v;
            }
            #___private_domHost_4;
            get domHost() {
                return this.#___private_domHost_4;
            }
            set domHost(_v) {
                this.#___private_domHost_4 = _v;
            }
            #___private_edgeless_5;
            get edgeless() {
                return this.#___private_edgeless_5;
            }
            set edgeless(_v) {
                this.#___private_edgeless_5 = _v;
            }
            #___private_editorHost_6;
            get editorHost() {
                return this.#___private_editorHost_6;
            }
            set editorHost(_v) {
                this.#___private_editorHost_6 = _v;
            }
            #___private_fitPadding_7;
            get fitPadding() {
                return this.#___private_fitPadding_7;
            }
            set fitPadding(_v) {
                this.#___private_fitPadding_7 = _v;
            }
            #___private_frameListContainer_8;
            get frameListContainer() {
                return this.#___private_frameListContainer_8;
            }
            set frameListContainer(_v) {
                this.#___private_frameListContainer_8 = _v;
            }
            #___private_insertIndex_9;
            get insertIndex() {
                return this.#___private_insertIndex_9;
            }
            set insertIndex(_v) {
                this.#___private_insertIndex_9 = _v;
            }
            constructor(...args){
                super(...args);
                this._clearDocDisposables = ()=>{
                    this._docDisposables?.dispose();
                    this._docDisposables = null;
                };
                this._clickBlank = (e)=>{
                    e.stopPropagation();
                    if (e.target.closest('frame-card') || this._selected.length === 0) {
                        return;
                    }
                    this._selected = [];
                    this.edgeless?.service.selection.set({
                        elements: this._selected,
                        editing: false
                    });
                };
                this._docDisposables = null;
                this._frameElementHeight = 0;
                this._frameItems = [];
                this._indicatorTranslateY = 0;
                this._lastEdgelessRootId = '';
                this._selectFrame = (e)=>{
                    const { selected, id, multiselect } = e.detail;
                    if (!selected) {
                        this._selected = this._selected.filter((frameId)=>frameId !== id);
                    } else if (multiselect) {
                        this._selected = [
                            ...this._selected,
                            id
                        ];
                    } else {
                        this._selected = [
                            id
                        ];
                    }
                    this.edgeless?.service.selection.set({
                        elements: this._selected,
                        editing: false
                    });
                };
                this._updateFrameItems = ()=>{
                    this._frameItems = this.frames.map((frame, idx)=>({
                            frame,
                            frameIndex: frame.index,
                            cardIndex: idx
                        }));
                };
                this.#___private__dragging_1 = (_initProto(this), _init__dragging(this, false));
                this.#___private__selected_2 = _init__selected(this, []);
                this.#___private_doc_3 = _init_doc(this);
                this.#___private_domHost_4 = _init_domHost(this);
                this.#___private_edgeless_5 = _init_edgeless(this, null);
                this.#___private_editorHost_6 = _init_editorHost(this);
                this.#___private_fitPadding_7 = _init_fitPadding(this);
                this.#___private_frameListContainer_8 = _init_frameListContainer(this);
                this.#___private_insertIndex_9 = _init_insertIndex(this, undefined);
            }
        }
    }
}();
export { _FramePanelBody as FramePanelBody };
