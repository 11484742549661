import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSettingHelper } from '../../hooks/affine/use-app-setting-helper';
import { AppSidebarFallback } from '../app-sidebar';
import { AppContainer as AppContainerWithoutSettings, MainContainer } from '../workspace';
export const AppContainer = (props)=>{
    const { appSettings } = useAppSettingHelper();
    return _jsx(AppContainerWithoutSettings, {
        useNoisyBackground: appSettings.enableNoisyBackground,
        useBlurBackground: appSettings.enableBlurBackground && environment.isDesktop && environment.isMacOs,
        ...props
    });
};
export const AppFallback = ()=>{
    return _jsxs(AppContainer, {
        children: [
            _jsx(AppSidebarFallback, {}),
            _jsx(MainContainer, {})
        ]
    });
};
