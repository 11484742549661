import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getCurrentStore } from '@toeverything/infra';
import { Provider } from 'jotai/react';
import { useMemo } from 'react';
import * as styles from './affine-error-fallback.css';
import { ERROR_REFLECT_KEY } from './error-basic/fallback-creator';
import { DumpInfo } from './error-basic/info-logger';
import { AnyErrorFallback } from './error-fallbacks/any-error-fallback';
import { NoPageRootFallback } from './error-fallbacks/no-page-root-fallback';
import { PageNotFoundDetail } from './error-fallbacks/page-not-found-fallback';
import { RecoverableErrorFallback } from './error-fallbacks/recoverable-error-fallback';
const fallbacks = new Set([
    PageNotFoundDetail,
    RecoverableErrorFallback,
    NoPageRootFallback
]);
function getErrorFallbackComponent(error) {
    for (const Component of fallbacks){
        const ErrorConstructor = Reflect.get(Component, ERROR_REFLECT_KEY);
        if (ErrorConstructor && error instanceof ErrorConstructor) {
            return Component;
        }
    }
    return AnyErrorFallback;
}
export const AffineErrorFallback = (props)=>{
    const { error, resetError, height } = props;
    const Component = useMemo(()=>getErrorFallbackComponent(error), [
        error
    ]);
    return _jsxs("div", {
        className: styles.viewport,
        style: {
            height
        },
        children: [
            _jsx(Component, {
                error: error,
                resetError: resetError
            }),
            _jsx(Provider, {
                store: getCurrentStore(),
                children: _jsx(DumpInfo, {
                    error: error
                })
            }, "JotaiProvider")
        ]
    });
};
