import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useJournalInfoHelper } from '@affine/core/hooks/use-journal';
import { useI18n } from '@affine/i18n';
import { assertExists } from '@blocksuite/global/utils';
import { EdgelessIcon, PageIcon, TodayIcon, ToggleCollapseIcon, ViewLayersIcon } from '@blocksuite/icons/rc';
import * as Collapsible from '@radix-ui/react-collapsible';
import { DocsService, useLiveData, useService } from '@toeverything/infra';
import clsx from 'clsx';
import { selectAtom } from 'jotai/utils';
import { useCallback, useMemo, useState } from 'react';
import { CollectionListItem } from './collections/collection-list-item';
import { PageListItem } from './docs/page-list-item';
import { PagePreview } from './page-content-preview';
import * as styles from './page-group.css';
import { groupCollapseStateAtom, groupsAtom, listPropsAtom, selectionStateAtom, useAtom, useAtomValue } from './scoped-atoms';
import { TagListItem } from './tags/tag-list-item';
import { shallowEqual } from './utils';
export const ItemGroupHeader = ({ id, items, label })=>{
    const [collapseState, setCollapseState] = useAtom(groupCollapseStateAtom);
    const collapsed = collapseState[id];
    const onExpandedClicked = useCallback((e)=>{
        e.stopPropagation();
        e.preventDefault();
        setCollapseState((v)=>({
                ...v,
                [id]: !v[id]
            }));
    }, [
        id,
        setCollapseState
    ]);
    const [selectionState, setSelectionActive] = useAtom(selectionStateAtom);
    const selectedItems = useMemo(()=>{
        const selectedIds = selectionState.selectedIds ?? [];
        return items.filter((item)=>selectedIds.includes(item.id));
    }, [
        items,
        selectionState.selectedIds
    ]);
    const allSelected = selectedItems.length === items.length;
    const onSelectAll = useCallback(()=>{
        setSelectionActive(true);
        const nonCurrentGroupIds = selectionState.selectedIds?.filter((id)=>!items.map((item)=>item.id).includes(id)) ?? [];
        const newSelectedPageIds = allSelected ? nonCurrentGroupIds : [
            ...nonCurrentGroupIds,
            ...items.map((item)=>item.id)
        ];
        selectionState.onSelectedIdsChange?.(newSelectedPageIds);
    }, [
        setSelectionActive,
        selectionState,
        allSelected,
        items
    ]);
    const t = useI18n();
    return label ? _jsxs("div", {
        "data-testid": "page-list-group-header",
        className: styles.header,
        "data-group-id": id,
        "data-group-items-count": items.length,
        "data-group-selected-items-count": selectedItems.length,
        children: [
            _jsx("div", {
                role: "button",
                onClick: onExpandedClicked,
                "data-testid": "page-list-group-header-collapsed-button",
                className: styles.collapsedIconContainer,
                children: _jsx(ToggleCollapseIcon, {
                    className: styles.collapsedIcon,
                    "data-collapsed": !!collapsed
                })
            }),
            _jsx("div", {
                className: styles.headerLabel,
                children: label
            }),
            selectionState.selectionActive ? _jsxs("div", {
                className: styles.headerCount,
                children: [
                    selectedItems.length,
                    "/",
                    items.length
                ]
            }) : null,
            _jsx("div", {
                className: styles.spacer
            }),
            _jsx("button", {
                className: styles.selectAllButton,
                onClick: onSelectAll,
                children: t[allSelected ? 'com.affine.page.group-header.clear' : 'com.affine.page.group-header.select-all']()
            })
        ]
    }) : null;
};
export const ItemGroup = ({ id, items, label })=>{
    const [collapsed, setCollapsed] = useState(false);
    const onExpandedClicked = useCallback((e)=>{
        e.stopPropagation();
        e.preventDefault();
        setCollapsed((v)=>!v);
    }, []);
    const selectionState = useAtomValue(selectionStateAtom);
    const selectedItems = useMemo(()=>{
        const selectedIds = selectionState.selectedIds ?? [];
        return items.filter((item)=>selectedIds.includes(item.id));
    }, [
        items,
        selectionState.selectedIds
    ]);
    const onSelectAll = useCallback(()=>{
        const nonCurrentGroupIds = selectionState.selectedIds?.filter((id)=>!items.map((item)=>item.id).includes(id)) ?? [];
        selectionState.onSelectedIdsChange?.([
            ...nonCurrentGroupIds,
            ...items.map((item)=>item.id)
        ]);
    }, [
        items,
        selectionState
    ]);
    const t = useI18n();
    return _jsxs(Collapsible.Root, {
        "data-testid": "page-list-group",
        "data-group-id": id,
        open: !collapsed,
        className: clsx(styles.root),
        children: [
            label ? _jsxs("div", {
                "data-testid": "page-list-group-header",
                className: styles.header,
                children: [
                    _jsx(Collapsible.Trigger, {
                        role: "button",
                        onClick: onExpandedClicked,
                        "data-testid": "page-list-group-header-collapsed-button",
                        className: styles.collapsedIconContainer,
                        children: _jsx(ToggleCollapseIcon, {
                            className: styles.collapsedIcon,
                            "data-collapsed": collapsed !== false
                        })
                    }),
                    _jsx("div", {
                        className: styles.headerLabel,
                        children: label
                    }),
                    selectionState.selectionActive ? _jsxs("div", {
                        className: styles.headerCount,
                        children: [
                            selectedItems.length,
                            "/",
                            items.length
                        ]
                    }) : null,
                    _jsx("div", {
                        className: styles.spacer
                    }),
                    selectionState.selectionActive ? _jsx("button", {
                        className: styles.selectAllButton,
                        onClick: onSelectAll,
                        children: t['com.affine.page.group-header.select-all']()
                    }) : null
                ]
            }) : null,
            _jsx(Collapsible.Content, {
                className: styles.collapsibleContent,
                "data-state": !collapsed ? 'open' : 'closed',
                children: _jsx("div", {
                    className: styles.collapsibleContentInner,
                    children: items.map((item)=>_jsx(PageListItemRenderer, {
                            ...item
                        }, item.id))
                })
            })
        ]
    });
};
const requiredPropNames = [
    'docCollection',
    'rowAsLink',
    'isPreferredEdgeless',
    'operationsRenderer',
    'selectedIds',
    'onSelectedIdsChange',
    'draggable'
];
export const listsPropsAtom = selectAtom(listPropsAtom, (props)=>{
    return Object.fromEntries(requiredPropNames.map((name)=>[
            name,
            props?.[name]
        ]));
}, shallowEqual);
export const PageListItemRenderer = (item)=>{
    const props = useAtomValue(listsPropsAtom);
    const { selectionActive } = useAtomValue(selectionStateAtom);
    const groups = useAtomValue(groupsAtom);
    const pageItems = groups.flatMap((group)=>group.items).map((item)=>item.id);
    const page = item;
    return _jsx(PageListItem, {
        ...pageMetaToListItemProp(page, {
            ...props,
            selectable: !!selectionActive
        }, pageItems)
    });
};
export const CollectionListItemRenderer = (item)=>{
    const props = useAtomValue(listsPropsAtom);
    const { selectionActive } = useAtomValue(selectionStateAtom);
    const collection = item;
    return _jsx(CollectionListItem, {
        ...collectionMetaToListItemProp(collection, {
            ...props,
            selectable: !!selectionActive
        })
    });
};
export const TagListItemRenderer = (item)=>{
    const props = useAtomValue(listsPropsAtom);
    const { selectionActive } = useAtomValue(selectionStateAtom);
    const tag = item;
    return _jsx(TagListItem, {
        ...tagMetaToListItemProp(tag, {
            ...props,
            selectable: !!selectionActive
        })
    });
};
function tagIdToTagOption(tagId, docCollection) {
    return docCollection.meta.properties.tags?.options.find((opt)=>opt.id === tagId);
}
const PageTitle = ({ id })=>{
    const doc = useLiveData(useService(DocsService).list.doc$(id));
    const title = useLiveData(doc?.title$);
    const t = useI18n();
    return title || t['Untitled']();
};
const UnifiedPageIcon = ({ id, docCollection, isPreferredEdgeless })=>{
    const isEdgeless = isPreferredEdgeless ? isPreferredEdgeless(id) : false;
    const { isJournal } = useJournalInfoHelper(docCollection, id);
    if (isJournal) {
        return _jsx(TodayIcon, {});
    }
    return isEdgeless ? _jsx(EdgelessIcon, {}) : _jsx(PageIcon, {});
};
function pageMetaToListItemProp(item, props, pageIds) {
    const toggleSelection = props.onSelectedIdsChange ? ()=>{
        assertExists(props.selectedIds);
        const prevSelected = props.selectedIds.includes(item.id);
        const shouldAdd = !prevSelected;
        const shouldRemove = prevSelected;
        if (shouldAdd) {
            props.onSelectedIdsChange?.([
                ...props.selectedIds,
                item.id
            ]);
        } else if (shouldRemove) {
            props.onSelectedIdsChange?.(props.selectedIds.filter((id)=>id !== item.id));
        }
    } : undefined;
    const itemProps = {
        pageId: item.id,
        pageIds,
        title: _jsx(PageTitle, {
            id: item.id
        }),
        preview: _jsx(PagePreview, {
            docCollection: props.docCollection,
            pageId: item.id
        }),
        createDate: new Date(item.createDate),
        updatedDate: item.updatedDate ? new Date(item.updatedDate) : undefined,
        to: props.rowAsLink && !props.selectable ? `/${item.id}` : undefined,
        onClick: toggleSelection,
        icon: _jsx(UnifiedPageIcon, {
            id: item.id,
            docCollection: props.docCollection,
            isPreferredEdgeless: props.isPreferredEdgeless
        }),
        tags: item.tags?.map((id)=>tagIdToTagOption(id, props.docCollection)).filter((v)=>v != null) ?? [],
        operations: props.operationsRenderer?.(item),
        selectable: props.selectable,
        selected: props.selectedIds?.includes(item.id),
        onSelectedChange: toggleSelection,
        draggable: props.draggable,
        isPublicPage: !!item.isPublic
    };
    return itemProps;
}
function collectionMetaToListItemProp(item, props) {
    const toggleSelection = props.onSelectedIdsChange ? ()=>{
        assertExists(props.selectedIds);
        const prevSelected = props.selectedIds.includes(item.id);
        const shouldAdd = !prevSelected;
        const shouldRemove = prevSelected;
        if (shouldAdd) {
            props.onSelectedIdsChange?.([
                ...props.selectedIds,
                item.id
            ]);
        } else if (shouldRemove) {
            props.onSelectedIdsChange?.(props.selectedIds.filter((id)=>id !== item.id));
        }
    } : undefined;
    const itemProps = {
        collectionId: item.id,
        title: item.title,
        to: props.rowAsLink && !props.selectable ? `/collection/${item.id}` : undefined,
        onClick: toggleSelection,
        icon: _jsx(ViewLayersIcon, {}),
        operations: props.operationsRenderer?.(item),
        selectable: props.selectable,
        selected: props.selectedIds?.includes(item.id),
        onSelectedChange: toggleSelection,
        draggable: props.draggable
    };
    return itemProps;
}
function tagMetaToListItemProp(item, props) {
    const toggleSelection = props.onSelectedIdsChange ? ()=>{
        assertExists(props.selectedIds);
        const prevSelected = props.selectedIds.includes(item.id);
        const shouldAdd = !prevSelected;
        const shouldRemove = prevSelected;
        if (shouldAdd) {
            props.onSelectedIdsChange?.([
                ...props.selectedIds,
                item.id
            ]);
        } else if (shouldRemove) {
            props.onSelectedIdsChange?.(props.selectedIds.filter((id)=>id !== item.id));
        }
    } : undefined;
    const itemProps = {
        tagId: item.id,
        title: item.title,
        to: props.rowAsLink && !props.selectable ? `/tag/${item.id}` : undefined,
        onClick: toggleSelection,
        color: item.color,
        pageCount: item.pageCount,
        operations: props.operationsRenderer?.(item),
        selectable: props.selectable,
        selected: props.selectedIds?.includes(item.id),
        onSelectedChange: toggleSelection,
        draggable: props.draggable
    };
    return itemProps;
}
