import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading, Logo1IconBorder } from '@affine/component';
import { Divider } from '@affine/component/ui/divider';
import { MenuItem } from '@affine/component/ui/menu';
import { track } from '@affine/core/mixpanel';
import { AuthService } from '@affine/core/modules/cloud';
import { useI18n } from '@affine/i18n';
import { useLiveData, useService, WorkspacesService } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { Suspense, useCallback } from 'react';
import { authAtom, openCreateWorkspaceModalAtom } from '../../../../atoms';
import { AddWorkspace } from './add-workspace';
import * as styles from './index.css';
import { UserAccountItem } from './user-account';
import { AFFiNEWorkspaceList } from './workspace-list';
export const SignInItem = ()=>{
    const setOpen = useSetAtom(authAtom);
    const t = useI18n();
    const onClickSignIn = useCallback(()=>{
        track.$.navigationPanel.workspaceList.signIn();
        setOpen((state)=>({
                ...state,
                openModal: true
            }));
    }, [
        setOpen
    ]);
    return _jsx(MenuItem, {
        className: styles.menuItem,
        onClick: onClickSignIn,
        "data-testid": "cloud-signin-button",
        children: _jsxs("div", {
            className: styles.signInWrapper,
            children: [
                _jsx("div", {
                    className: styles.iconContainer,
                    children: _jsx(Logo1IconBorder, {})
                }),
                _jsxs("div", {
                    className: styles.signInTextContainer,
                    children: [
                        _jsx("div", {
                            className: styles.signInTextPrimary,
                            children: t['com.affine.workspace.cloud.auth']()
                        }),
                        _jsx("div", {
                            className: styles.signInTextSecondary,
                            children: t['com.affine.workspace.cloud.description']()
                        })
                    ]
                })
            ]
        })
    });
};
const UserWithWorkspaceListLoading = ()=>{
    return _jsx("div", {
        className: styles.loadingWrapper,
        children: _jsx(Loading, {
            size: 24
        })
    });
};
const UserWithWorkspaceListInner = ({ onEventEnd })=>{
    const session = useLiveData(useService(AuthService).session.session$);
    const isAuthenticated = session.status === 'authenticated';
    const setOpenCreateWorkspaceModal = useSetAtom(openCreateWorkspaceModalAtom);
    const setOpenSignIn = useSetAtom(authAtom);
    const openSignInModal = useCallback(()=>{
        setOpenSignIn((state)=>({
                ...state,
                openModal: true
            }));
    }, [
        setOpenSignIn
    ]);
    const onNewWorkspace = useCallback(()=>{
        if (!isAuthenticated && !runtimeConfig.allowLocalWorkspace) {
            return openSignInModal();
        }
        track.$.navigationPanel.workspaceList.createWorkspace();
        setOpenCreateWorkspaceModal('new');
        onEventEnd?.();
    }, [
        isAuthenticated,
        onEventEnd,
        openSignInModal,
        setOpenCreateWorkspaceModal
    ]);
    track.$.navigationPanel.workspaceList.createWorkspace();
    const onAddWorkspace = useCallback(()=>{
        track.$.navigationPanel.workspaceList.createWorkspace({
            control: 'import'
        });
        setOpenCreateWorkspaceModal('add');
        onEventEnd?.();
    }, [
        onEventEnd,
        setOpenCreateWorkspaceModal
    ]);
    const workspaceManager = useService(WorkspacesService);
    const workspaces = useLiveData(workspaceManager.list.workspaces$);
    return _jsxs("div", {
        className: styles.workspaceListWrapper,
        children: [
            isAuthenticated ? _jsx(UserAccountItem, {
                email: session.session.account.email ?? 'Unknown User',
                onEventEnd: onEventEnd
            }) : _jsx(SignInItem, {}),
            _jsx(Divider, {
                size: "thinner"
            }),
            _jsx(AFFiNEWorkspaceList, {
                onEventEnd: onEventEnd
            }),
            workspaces.length > 0 ? _jsx(Divider, {
                size: "thinner"
            }) : null,
            _jsx(AddWorkspace, {
                onAddWorkspace: onAddWorkspace,
                onNewWorkspace: onNewWorkspace
            })
        ]
    });
};
export const UserWithWorkspaceList = (props)=>{
    return _jsx(Suspense, {
        fallback: _jsx(UserWithWorkspaceListLoading, {}),
        children: _jsx(UserWithWorkspaceListInner, {
            ...props
        })
    });
};
