import { Bound } from '@blocksuite/global/utils';
import { BlockModel, defineBlockSchema } from '@blocksuite/store';
import { NOTE_WIDTH } from '../_common/consts.js';
import { GfxCompatible } from '../_common/edgeless/mixin/gfx-compatible.js';
import { DEFAULT_NOTE_BACKGROUND_COLOR, DEFAULT_NOTE_SHADOW } from '../_common/edgeless/note/consts.js';
import { NoteDisplayMode } from '../_common/types.js';
import { StrokeStyle } from '../surface-block/consts.js';
export const NoteBlockSchema = defineBlockSchema({
    flavour: 'affine:note',
    props: ()=>({
            xywh: `[0,0,${NOTE_WIDTH},95]`,
            background: DEFAULT_NOTE_BACKGROUND_COLOR,
            index: 'a0',
            hidden: false,
            displayMode: NoteDisplayMode.DocAndEdgeless,
            edgeless: {
                style: {
                    borderRadius: 0,
                    borderSize: 4,
                    borderStyle: StrokeStyle.None,
                    shadowType: DEFAULT_NOTE_SHADOW
                }
            }
        }),
    metadata: {
        version: 1,
        role: 'hub',
        parent: [
            'affine:page'
        ],
        children: [
            'affine:paragraph',
            'affine:list',
            'affine:code',
            'affine:divider',
            'affine:database',
            'affine:data-view',
            'affine:image',
            'affine:bookmark',
            'affine:attachment',
            'affine:surface-ref',
            'affine:embed-*'
        ]
    },
    toModel: ()=>{
        return new NoteBlockModel();
    }
});
export class NoteBlockModel extends GfxCompatible(BlockModel) {
    _isSelectable() {
        return this.displayMode !== NoteDisplayMode.DocOnly;
    }
    containsBound(bounds) {
        if (!this._isSelectable()) return false;
        return super.containsBound(bounds);
    }
    includesPoint(x, y) {
        if (!this._isSelectable()) return false;
        const bound = Bound.deserialize(this.xywh);
        return bound.isPointInBound([
            x,
            y
        ], 0);
    }
    intersectsBound(bound) {
        if (!this._isSelectable()) return false;
        return super.intersectsBound(bound);
    }
}
