import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DoneIcon } from '@blocksuite/icons/rc';
import clsx from 'clsx';
import { useMemo } from 'react';
import { MenuIcon } from './menu-icon';
import * as styles from './styles.css';
export const useMenuItem = ({ children: propsChildren, type = 'default', className: propsClassName, preFix, endFix, checked, selected, block })=>{
    const className = useMemo(()=>clsx(styles.menuItem, {
            danger: type === 'danger',
            warning: type === 'warning',
            checked,
            selected,
            block
        }, propsClassName), [
        block,
        checked,
        propsClassName,
        selected,
        type
    ]);
    const children = useMemo(()=>_jsxs(_Fragment, {
            children: [
                preFix,
                _jsx("span", {
                    className: styles.menuSpan,
                    children: propsChildren
                }),
                endFix,
                checked || selected ? _jsx(MenuIcon, {
                    position: "end",
                    className: clsx({
                        selected,
                        checked
                    }),
                    children: _jsx(DoneIcon, {})
                }) : null
            ]
        }), [
        checked,
        endFix,
        preFix,
        propsChildren,
        selected
    ]);
    return {
        children,
        className
    };
};
