import { z } from 'zod';
function createManyUnion(literals) {
    return z.union(literals.map((value)=>z.literal(value)));
}
export function createZodUnion(values) {
    if (values.length > 1) {
        return createManyUnion(values);
    }
    if (values.length === 1) {
        return z.literal(values[0]);
    }
    return z.never();
}
