import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, IconButton, Menu, MenuIcon, MenuItem, Tooltip } from '@affine/component';
import { useCurrentWorkspacePropertiesAdapter } from '@affine/core/hooks/use-affine-adapter';
import { track } from '@affine/core/mixpanel';
import { DocLinksService } from '@affine/core/modules/doc-link';
import { FeedTag } from '@affine/core/modules/tag/entities/internal-tag';
import { i18nTime, useI18n } from '@affine/i18n';
import { assertExists } from '@blocksuite/global/utils';
import { ArrowDownSmallIcon, DeleteIcon, InvisibleIcon, MoreHorizontalIcon, PlusIcon, TagsIcon, ToggleExpandIcon, ViewIcon } from '@blocksuite/icons/rc';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import * as Collapsible from '@radix-ui/react-collapsible';
import { DocService, useLiveData, useServices, WorkspaceService } from '@toeverything/infra';
import clsx from 'clsx';
import { use } from 'foxact/use';
import { useDebouncedValue } from 'foxact/use-debounced-value';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AffinePageReference } from '../reference-link';
import { managerContext } from './common';
import { ConfirmDeletePropertyModal } from './confirm-delete-property-modal';
import { getDefaultIconName, nameToIcon } from './icons-mapping';
import { isInternalProperty, usePropertyI18n } from './internal-properties';
import { EditPropertyNameMenuItem, PropertyTypeMenuItem, renderMenuItemOptions } from './menu-items';
import { newPropertyTypes, PagePropertiesManager } from './page-properties-manager';
import { propertyValueRenderers, TagsValue } from './property-row-value-renderer';
import * as styles from './styles.css';
const Divider = ()=>_jsx("div", {
        className: styles.tableHeaderDivider
    });
const editingPropertyAtom = atom(null);
const modifiers = [
    restrictToParentElement,
    restrictToVerticalAxis
];
export const SortableProperties = ({ children })=>{
    const manager = useContext(managerContext);
    const properties = useMemo(()=>manager.sorter.getOrderedItems(), [
        manager
    ]);
    const editingItem = useAtomValue(editingPropertyAtom);
    const draggable = !manager.readonly && !editingItem;
    const sensors = useSensors(useSensor(PointerSensor, {
        activationConstraint: {
            distance: 8
        }
    }));
    const [localProperties, setLocalProperties] = useState(properties);
    useEffect(()=>{
        setLocalProperties(properties);
    }, [
        properties
    ]);
    const onDragEnd = useCallback((event)=>{
        if (!draggable) {
            return;
        }
        const { active, over } = event;
        if (over) {
            manager.sorter.move(active.id, over.id);
        }
        setLocalProperties(manager.sorter.getOrderedItems());
    }, [
        manager,
        draggable
    ]);
    const filteredProperties = useMemo(()=>localProperties.filter((p)=>manager.getCustomPropertyMeta(p.id)), [
        localProperties,
        manager
    ]);
    return _jsx(DndContext, {
        sensors: sensors,
        onDragEnd: onDragEnd,
        modifiers: modifiers,
        children: _jsx(SortableContext, {
            disabled: !draggable,
            items: properties,
            children: children(filteredProperties)
        })
    });
};
const SortablePropertyRow = ({ property, className, children, ...props })=>{
    const manager = useContext(managerContext);
    const { setNodeRef, attributes, listeners, transform, transition, active, isDragging, isSorting } = useSortable({
        id: property.id
    });
    const style = useMemo(()=>({
            transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
            transition: isSorting ? transition : undefined,
            pointerEvents: manager.readonly ? 'none' : undefined
        }), [
        isSorting,
        manager.readonly,
        transform,
        transition
    ]);
    return _jsx("div", {
        style: style,
        ref: setNodeRef,
        className: clsx(styles.propertyRow, className),
        "data-property": property.id,
        "data-draggable": !manager.readonly,
        "data-dragging": isDragging,
        "data-other-dragging": active ? active.id !== property.id : false,
        ...props,
        ...attributes,
        ...listeners,
        children: typeof children === 'function' ? children({
            attributes,
            listeners
        }) : children
    });
};
const visibilities = [
    'visible',
    'hide',
    'hide-if-empty'
];
const rotateVisibility = (visibility)=>{
    const index = visibilities.indexOf(visibility);
    return visibilities[(index + 1) % visibilities.length];
};
const visibilityMenuText = (visibility = 'visible')=>{
    switch(visibility){
        case 'hide':
            return 'com.affine.page-properties.property.hide-in-view';
        case 'hide-if-empty':
            return 'com.affine.page-properties.property.hide-in-view-when-empty';
        case 'visible':
            return 'com.affine.page-properties.property.show-in-view';
        default:
            throw new Error(`unknown visibility: ${visibility}`);
    }
};
const visibilitySelectorText = (visibility = 'visible')=>{
    switch(visibility){
        case 'hide':
            return 'com.affine.page-properties.property.always-hide';
        case 'hide-if-empty':
            return 'com.affine.page-properties.property.hide-when-empty';
        case 'visible':
            return 'com.affine.page-properties.property.always-show';
        default:
            throw new Error(`unknown visibility: ${visibility}`);
    }
};
const VisibilityModeSelector = ({ property })=>{
    const manager = useContext(managerContext);
    const t = useI18n();
    const meta = manager.getCustomPropertyMeta(property.id);
    const visibility = property.visibility || 'visible';
    const menuItems = useMemo(()=>{
        const options = [];
        options.push(visibilities.map((v)=>{
            const text = visibilityMenuText(v);
            return {
                text: t[text](),
                selected: visibility === v,
                onClick: ()=>{
                    manager.updateCustomProperty(property.id, {
                        visibility: v
                    });
                }
            };
        }));
        return renderMenuItemOptions(options);
    }, [
        manager,
        property.id,
        t,
        visibility
    ]);
    if (!meta) {
        return null;
    }
    const required = meta.required;
    return _jsx(Menu, {
        items: menuItems,
        rootOptions: {
            open: required ? false : undefined
        },
        contentOptions: {
            onClick (e) {
                e.stopPropagation();
            }
        },
        children: _jsx("div", {
            role: "button",
            "data-required": required,
            className: styles.selectorButton,
            children: required ? t['com.affine.page-properties.property.required']() : _jsxs(_Fragment, {
                children: [
                    t[visibilitySelectorText(visibility)](),
                    _jsx(ArrowDownSmallIcon, {
                        width: 16,
                        height: 16
                    })
                ]
            })
        })
    });
};
export const PagePropertiesSettingsPopup = ({ children })=>{
    const manager = useContext(managerContext);
    const t = useI18n();
    const pt = usePropertyI18n();
    const menuItems = useMemo(()=>{
        const options = [];
        options.push(_jsx("div", {
            role: "heading",
            className: styles.menuHeader,
            style: {
                minWidth: 320
            },
            children: t['com.affine.page-properties.settings.title']()
        }));
        options.push('-');
        options.push([
            _jsx(SortableProperties, {
                children: (properties)=>properties.map((property)=>{
                        const meta = manager.getCustomPropertyMeta(property.id);
                        assertExists(meta, 'meta should exist for property');
                        const Icon = nameToIcon(meta.icon, meta.type);
                        const name = meta.name;
                        return _jsxs(SortablePropertyRow, {
                            property: property,
                            className: styles.propertySettingRow,
                            "data-testid": "page-properties-settings-menu-item",
                            children: [
                                _jsx(MenuIcon, {
                                    children: _jsx(Icon, {})
                                }),
                                _jsx("div", {
                                    "data-testid": "page-property-setting-row-name",
                                    className: styles.propertyRowName,
                                    children: pt(name)
                                }),
                                _jsx(VisibilityModeSelector, {
                                    property: property
                                })
                            ]
                        }, meta.id);
                    })
            }, "sortable-settings")
        ]);
        return renderMenuItemOptions(options);
    }, [
        manager,
        t
    ]);
    return _jsx(Menu, {
        contentOptions: {
            onClick (e) {
                e.stopPropagation();
            }
        },
        items: menuItems,
        children: children
    });
};
export const PageBacklinksPopup = ({ backlinks, children })=>{
    const manager = useContext(managerContext);
    return _jsx(Menu, {
        contentOptions: {
            onClick (e) {
                e.stopPropagation();
            }
        },
        items: _jsx("div", {
            className: styles.backlinksList,
            children: backlinks.map((link)=>_jsx(AffinePageReference, {
                    wrapper: MenuItem,
                    pageId: link.docId,
                    docCollection: manager.workspace.docCollection
                }, link.docId + ':' + link.blockId))
        }),
        children: children
    });
};
export const PagePropertyRowNameMenu = ({ editing, meta, property, onFinishEditing, children })=>{
    const manager = useContext(managerContext);
    const [localPropertyMeta, setLocalPropertyMeta] = useState(()=>({
            ...meta
        }));
    const [localProperty, setLocalProperty] = useState(()=>({
            ...property
        }));
    const nextVisibility = rotateVisibility(localProperty.visibility);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    useEffect(()=>{
        setLocalPropertyMeta(meta);
    }, [
        meta
    ]);
    useEffect(()=>{
        setLocalProperty(property);
    }, [
        property
    ]);
    const handleFinishEditing = useCallback(()=>{
        onFinishEditing();
        manager.updateCustomPropertyMeta(meta.id, localPropertyMeta);
        manager.updateCustomProperty(property.id, localProperty);
    }, [
        localProperty,
        localPropertyMeta,
        manager,
        meta.id,
        onFinishEditing,
        property.id
    ]);
    const t = useI18n();
    const handleNameBlur = useCallback((v)=>{
        manager.updateCustomPropertyMeta(meta.id, {
            name: v
        });
    }, [
        manager,
        meta.id
    ]);
    const handleNameChange = useCallback((name)=>{
        setLocalPropertyMeta((prev)=>({
                ...prev,
                name: name
            }));
    }, []);
    const toggleHide = useCallback((e)=>{
        e.stopPropagation();
        e.preventDefault();
        setLocalProperty((prev)=>({
                ...prev,
                visibility: nextVisibility
            }));
    }, [
        nextVisibility
    ]);
    const handleDelete = useCallback(()=>{
        manager.removeCustomProperty(property.id);
    }, [
        manager,
        property.id
    ]);
    const handleIconChange = useCallback((icon)=>{
        setLocalPropertyMeta((prev)=>({
                ...prev,
                icon
            }));
        manager.updateCustomPropertyMeta(meta.id, {
            icon: icon
        });
    }, [
        manager,
        meta.id
    ]);
    const menuItems = useMemo(()=>{
        const options = [];
        options.push(_jsx(EditPropertyNameMenuItem, {
            property: localPropertyMeta,
            onIconChange: handleIconChange,
            onNameBlur: handleNameBlur,
            onNameChange: handleNameChange
        }));
        options.push(_jsx(PropertyTypeMenuItem, {
            property: localPropertyMeta
        }));
        if (!localPropertyMeta.required) {
            options.push('-');
            options.push({
                icon: nextVisibility === 'hide' || nextVisibility === 'hide-if-empty' ? _jsx(InvisibleIcon, {}) : _jsx(ViewIcon, {}),
                text: t[visibilityMenuText(rotateVisibility(localProperty.visibility))](),
                onClick: toggleHide
            });
            options.push({
                type: 'danger',
                icon: _jsx(DeleteIcon, {}),
                text: t['com.affine.page-properties.property.remove-property'](),
                onClick: ()=>setShowDeleteModal(true)
            });
        }
        return renderMenuItemOptions(options);
    }, [
        handleIconChange,
        handleNameBlur,
        handleNameChange,
        localProperty.visibility,
        localPropertyMeta,
        nextVisibility,
        t,
        toggleHide
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(Menu, {
                rootOptions: {
                    open: editing
                },
                contentOptions: {
                    onInteractOutside: handleFinishEditing,
                    onClick (e) {
                        e.stopPropagation();
                    },
                    onKeyDown (e) {
                        if (e.key === 'Escape') {
                            handleFinishEditing();
                        }
                    }
                },
                items: menuItems,
                children: children
            }),
            _jsx(ConfirmDeletePropertyModal, {
                onConfirm: ()=>{
                    setShowDeleteModal(false);
                    handleDelete();
                },
                onCancel: ()=>setShowDeleteModal(false),
                show: showDeleteModal,
                property: meta
            })
        ]
    });
};
export const PagePropertiesTableHeader = ({ className, style, open, onOpenChange })=>{
    const manager = useContext(managerContext);
    const t = useI18n();
    const { docLinksServices } = useServices({
        DocLinksServices: DocLinksService
    });
    const docBacklinks = docLinksServices.backlinks;
    const backlinks = useLiveData(docBacklinks.backlinks$);
    const { docService, workspaceService } = useServices({
        DocService,
        WorkspaceService
    });
    const { syncing, retrying, serverClock } = useLiveData(workspaceService.workspace.engine.doc.docState$(docService.doc.id));
    const timestampElement = useMemo(()=>{
        const localizedCreateTime = manager.createDate ? i18nTime(manager.createDate) : null;
        const createTimeElement = _jsxs("div", {
            className: styles.tableHeaderTimestamp,
            children: [
                t['Created'](),
                " ",
                localizedCreateTime
            ]
        });
        return serverClock ? _jsx(Tooltip, {
            side: "right",
            content: _jsxs(_Fragment, {
                children: [
                    _jsxs("div", {
                        className: styles.tableHeaderTimestamp,
                        children: [
                            t['Updated'](),
                            " ",
                            i18nTime(serverClock)
                        ]
                    }),
                    manager.createDate && _jsxs("div", {
                        className: styles.tableHeaderTimestamp,
                        children: [
                            t['Created'](),
                            " ",
                            i18nTime(manager.createDate)
                        ]
                    })
                ]
            }),
            children: _jsx("div", {
                className: styles.tableHeaderTimestamp,
                children: !syncing && !retrying ? _jsxs(_Fragment, {
                    children: [
                        t['Updated'](),
                        ' ',
                        i18nTime(serverClock, {
                            relative: {
                                max: [
                                    1,
                                    'day'
                                ],
                                accuracy: 'minute'
                            },
                            absolute: {
                                accuracy: 'day'
                            }
                        })
                    ]
                }) : _jsx(_Fragment, {
                    children: t['com.affine.syncing']()
                })
            })
        }) : manager.updatedDate ? _jsx(Tooltip, {
            side: "right",
            content: createTimeElement,
            children: _jsxs("div", {
                className: styles.tableHeaderTimestamp,
                children: [
                    t['Updated'](),
                    " ",
                    i18nTime(manager.updatedDate)
                ]
            })
        }) : createTimeElement;
    }, [
        manager.createDate,
        manager.updatedDate,
        retrying,
        serverClock,
        syncing,
        t
    ]);
    const dTimestampElement = useDebouncedValue(timestampElement, 500);
    const handleCollapse = useCallback(()=>{
        track.doc.inlineDocInfo.$.toggle();
        onOpenChange(!open);
    }, [
        onOpenChange,
        open
    ]);
    const properties = manager.sorter.getOrderedItems();
    return _jsxs("div", {
        className: clsx(styles.tableHeader, className),
        style: style,
        children: [
            _jsxs("div", {
                className: styles.tableHeaderInfoRow,
                children: [
                    backlinks.length > 0 ? _jsx(PageBacklinksPopup, {
                        backlinks: backlinks,
                        children: _jsxs("div", {
                            className: styles.tableHeaderBacklinksHint,
                            children: [
                                t['com.affine.page-properties.backlinks'](),
                                " · ",
                                backlinks.length
                            ]
                        })
                    }) : null,
                    dTimestampElement
                ]
            }),
            _jsx(Divider, {}),
            _jsxs("div", {
                className: styles.tableHeaderSecondaryRow,
                children: [
                    _jsx("div", {
                        className: clsx(!open ? styles.pageInfoDimmed : null),
                        children: t['com.affine.page-properties.page-info']()
                    }),
                    properties.length === 0 || manager.readonly ? null : _jsx(PagePropertiesSettingsPopup, {
                        children: _jsx(IconButton, {
                            "data-testid": "page-info-show-more",
                            size: "20",
                            children: _jsx(MoreHorizontalIcon, {})
                        })
                    }),
                    _jsx(Collapsible.Trigger, {
                        asChild: true,
                        role: "button",
                        onClick: handleCollapse,
                        children: _jsx("div", {
                            className: styles.tableHeaderCollapseButtonWrapper,
                            "data-testid": "page-info-collapse",
                            children: _jsx(IconButton, {
                                size: "20",
                                children: _jsx(ToggleExpandIcon, {
                                    className: styles.collapsedIcon,
                                    "data-collapsed": !open
                                })
                            })
                        })
                    })
                ]
            })
        ]
    });
};
export const PagePropertyRow = ({ property, rowNameClassName })=>{
    const manager = useContext(managerContext);
    const meta = manager.getCustomPropertyMeta(property.id);
    assertExists(meta, 'meta should exist for property');
    const pt = usePropertyI18n();
    const Icon = nameToIcon(meta.icon, meta.type);
    const name = meta.name;
    const ValueRenderer = propertyValueRenderers[meta.type];
    const [editingMeta, setEditingMeta] = useState(false);
    const setEditingItem = useSetAtom(editingPropertyAtom);
    const handleEditMeta = useCallback(()=>{
        if (isInternalProperty(name)) {
            return;
        }
        if (!manager.readonly) {
            setEditingMeta(true);
        }
        setEditingItem(property.id);
    }, [
        name,
        manager.readonly,
        setEditingItem,
        property.id
    ]);
    const handleFinishEditingMeta = useCallback(()=>{
        setEditingMeta(false);
        setEditingItem(null);
    }, [
        setEditingItem
    ]);
    return _jsx(SortablePropertyRow, {
        property: property,
        "data-testid": "page-property-row",
        children: ({ attributes, listeners })=>_jsxs(_Fragment, {
                children: [
                    _jsx(PagePropertyRowNameMenu, {
                        editing: editingMeta,
                        meta: meta,
                        property: property,
                        onFinishEditing: handleFinishEditingMeta,
                        children: _jsx("div", {
                            ...attributes,
                            ...listeners,
                            "data-testid": "page-property-row-name",
                            className: clsx(styles.sortablePropertyRowNameCell, rowNameClassName),
                            onClick: handleEditMeta,
                            children: _jsxs("div", {
                                className: styles.propertyRowNameContainer,
                                children: [
                                    _jsx("div", {
                                        className: styles.propertyRowIconContainer,
                                        children: _jsx(Icon, {})
                                    }),
                                    _jsx("div", {
                                        className: styles.propertyRowName,
                                        children: pt(name)
                                    })
                                ]
                            })
                        })
                    }),
                    _jsx(ValueRenderer, {
                        meta: meta,
                        property: property
                    })
                ]
            })
    });
};
export const PageTagsRow = ({ rowNameClassName })=>{
    const t = useI18n();
    return _jsxs("div", {
        className: styles.tagsPropertyRow,
        "data-testid": "page-property-row",
        "data-property": "tags",
        children: [
            _jsx("div", {
                className: clsx(styles.propertyRowNameCell, rowNameClassName),
                "data-testid": "page-property-row-name",
                children: _jsxs("div", {
                    className: styles.propertyRowNameContainer,
                    children: [
                        _jsx("div", {
                            className: styles.propertyRowIconContainer,
                            children: _jsx(TagsIcon, {})
                        }),
                        _jsx("div", {
                            className: styles.propertyRowName,
                            children: t['Tags']()
                        })
                    ]
                })
            }),
            _jsx(TagsValue, {})
        ]
    });
};
export const PagePropertiesTableBody = ({ className, style })=>{
    const manager = useContext(managerContext);
    return _jsxs(Collapsible.Content, {
        className: clsx(styles.tableBodyRoot, className),
        style: style,
        children: [
            _jsx(PageTagsRow, {}),
            _jsx(SortableProperties, {
                children: (properties)=>properties.length ? _jsx("div", {
                        className: styles.tableBodySortable,
                        children: properties.filter((property)=>manager.isPropertyRequired(property.id) || property.visibility !== 'hide' && !(property.visibility === 'hide-if-empty' && !property.value)).map((property)=>_jsx(PagePropertyRow, {
                                property: property
                            }, property.id))
                    }) : null
            }),
            manager.readonly ? null : _jsx(PagePropertiesAddProperty, {}),
            _jsx(Divider, {})
        ]
    });
};
const findNextDefaultName = (name, allNames)=>{
    const nameExists = allNames.includes(name);
    if (nameExists) {
        const match = name.match(/(\d+)$/);
        if (match) {
            const num = parseInt(match[1], 10);
            const nextName = name.replace(/(\d+)$/, `${num + 1}`);
            return findNextDefaultName(nextName, allNames);
        } else {
            return findNextDefaultName(`${name} 2`, allNames);
        }
    } else {
        return name;
    }
};
export const PagePropertiesCreatePropertyMenuItems = ({ onCreated, metaManager })=>{
    const t = useI18n();
    const onAddProperty = useCallback((e, option)=>{
        const schemaList = metaManager.getOrderedPropertiesSchema();
        const nameExists = schemaList.some((meta)=>meta.name === option.name);
        const allNames = schemaList.map((meta)=>meta.name);
        const name = nameExists ? findNextDefaultName(option.name, allNames) : option.name;
        const { id } = metaManager.addPropertyMeta({
            name,
            icon: option.icon,
            type: option.type
        });
        onCreated?.(e, id);
    }, [
        metaManager,
        onCreated
    ]);
    return useMemo(()=>{
        const options = [];
        options.push(_jsx("div", {
            role: "heading",
            className: styles.menuHeader,
            children: t['com.affine.page-properties.create-property.menu.header']()
        }));
        options.push('-');
        options.push(newPropertyTypes.map((type)=>{
            const iconName = getDefaultIconName(type);
            const Icon = nameToIcon(iconName, type);
            const name = t[`com.affine.page-properties.property.${type}`]();
            return {
                icon: _jsx(Icon, {}),
                text: name,
                onClick: (e)=>{
                    onAddProperty(e, {
                        icon: iconName,
                        name: name,
                        type: type
                    });
                }
            };
        }));
        return renderMenuItemOptions(options);
    }, [
        onAddProperty,
        t
    ]);
};
const PagePropertiesAddPropertyMenuItems = ({ onCreateClicked })=>{
    const manager = useContext(managerContext);
    const t = useI18n();
    const pt = usePropertyI18n();
    const metaList = manager.metaManager.getOrderedPropertiesSchema();
    const nonRequiredMetaList = metaList.filter((meta)=>!meta.required);
    const isChecked = useCallback((m)=>{
        return manager.hasCustomProperty(m);
    }, [
        manager
    ]);
    const onClickProperty = useCallback((e, id)=>{
        e.stopPropagation();
        e.preventDefault();
        if (isChecked(id)) {
            manager.removeCustomProperty(id);
        } else {
            manager.addCustomProperty(id);
        }
    }, [
        isChecked,
        manager
    ]);
    const menuItems = useMemo(()=>{
        const options = [];
        options.push(_jsx("div", {
            role: "heading",
            className: styles.menuHeader,
            children: t['com.affine.page-properties.add-property.menu.header']()
        }));
        if (nonRequiredMetaList.length > 0) {
            options.push('-');
            const nonRequiredMetaOptions = nonRequiredMetaList.map((meta)=>{
                const Icon = nameToIcon(meta.icon, meta.type);
                const name = pt(meta.name);
                return {
                    icon: _jsx(Icon, {}),
                    text: name,
                    selected: isChecked(meta.id),
                    onClick: (e)=>onClickProperty(e, meta.id)
                };
            });
            options.push(nonRequiredMetaOptions);
        }
        options.push('-');
        options.push({
            icon: _jsx(PlusIcon, {}),
            text: t['com.affine.page-properties.add-property.menu.create'](),
            onClick: onCreateClicked
        });
        return renderMenuItemOptions(options);
    }, [
        isChecked,
        nonRequiredMetaList,
        onClickProperty,
        onCreateClicked,
        pt,
        t
    ]);
    return menuItems;
};
export const PagePropertiesAddProperty = ()=>{
    const t = useI18n();
    const [adding, setAdding] = useState(true);
    const manager = useContext(managerContext);
    const toggleAdding = useCallback((e)=>{
        e.stopPropagation();
        e.preventDefault();
        setAdding((prev)=>!prev);
    }, []);
    const menuOptions = useMemo(()=>{
        const handleCreated = (e, id)=>{
            toggleAdding(e);
            manager.addCustomProperty(id);
        };
        const items = adding ? _jsx(PagePropertiesAddPropertyMenuItems, {
            onCreateClicked: toggleAdding
        }) : _jsx(PagePropertiesCreatePropertyMenuItems, {
            metaManager: manager.metaManager,
            onCreated: handleCreated
        });
        return {
            contentOptions: {
                onClick (e) {
                    e.stopPropagation();
                }
            },
            rootOptions: {
                onOpenChange: ()=>setAdding(true)
            },
            items
        };
    }, [
        adding,
        manager,
        toggleAdding
    ]);
    return _jsx(Menu, {
        ...menuOptions,
        children: _jsx(Button, {
            variant: "plain",
            prefix: _jsx(PlusIcon, {}),
            className: styles.addPropertyButton,
            children: t['com.affine.page-properties.add-property']()
        })
    });
};
const PagePropertiesTableInner = ()=>{
    const manager = useContext(managerContext);
    const [expanded, setExpanded] = useState(false);
    use(manager.workspace.docCollection.doc.whenSynced);
    return _jsx("div", {
        className: styles.root,
        children: _jsxs(Collapsible.Root, {
            open: expanded,
            onOpenChange: setExpanded,
            className: styles.rootCentered,
            children: [
                _jsx(PagePropertiesTableHeader, {
                    open: expanded,
                    onOpenChange: setExpanded
                }),
                _jsx(PagePropertiesTableBody, {})
            ]
        })
    });
};
export const usePagePropertiesManager = (docId)=>{
    const adapter = useCurrentWorkspacePropertiesAdapter();
    const manager = useMemo(()=>{
        return new PagePropertiesManager(adapter, docId);
    }, [
        adapter,
        docId
    ]);
    return manager;
};
export const PagePropertiesTable = ({ docId, page })=>{
    const manager = usePagePropertiesManager(docId);
    const hasSubscriptionTag = page.meta?.tags.some((tag)=>tag === FeedTag.id);
    if (!manager.page || !hasSubscriptionTag && manager.readonly) {
        return null;
    }
    return _jsx(managerContext.Provider, {
        value: manager,
        children: _jsx(Suspense, {
            children: _jsx(PagePropertiesTableInner, {})
        })
    });
};
