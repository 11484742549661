import { Action, createPath, parsePath } from 'history';
export function createNavigableHistory(options = {}) {
    const { initialEntries = [
        '/'
    ], initialIndex } = options;
    const entries = initialEntries.map((entry)=>{
        const location = Object.freeze({
            pathname: '/',
            search: '',
            hash: '',
            state: null,
            key: createKey(),
            ...typeof entry === 'string' ? parsePath(entry) : entry
        });
        warning(location.pathname.charAt(0) === '/', `Relative pathnames are not supported in createMemoryHistory({ initialEntries }) (invalid entry: ${JSON.stringify(entry)})`);
        return location;
    });
    let index = clamp(initialIndex == null ? entries.length - 1 : initialIndex, 0, entries.length - 1);
    let action = Action.Pop;
    let location = entries[index];
    const listeners = createEvents();
    const blockers = createEvents();
    function createHref(to) {
        return typeof to === 'string' ? to : createPath(to);
    }
    function getNextLocation(to, state = null) {
        return Object.freeze({
            pathname: location.pathname,
            search: '',
            hash: '',
            ...typeof to === 'string' ? parsePath(to) : to,
            state,
            key: createKey()
        });
    }
    function allowTx(action, location, retry) {
        return !blockers.length || (blockers.call({
            action,
            location,
            retry
        }), false);
    }
    function applyTx(nextAction, nextLocation) {
        action = nextAction;
        location = nextLocation;
        listeners.call({
            action,
            location
        });
    }
    function push(to, state) {
        const nextAction = Action.Push;
        const nextLocation = getNextLocation(to, state);
        function retry() {
            push(to, state);
        }
        warning(location.pathname.charAt(0) === '/', `Relative pathnames are not supported in memory history.push(${JSON.stringify(to)})`);
        if (allowTx(nextAction, nextLocation, retry)) {
            index += 1;
            entries.splice(index, entries.length, nextLocation);
            applyTx(nextAction, nextLocation);
        }
    }
    function replace(to, state) {
        const nextAction = Action.Replace;
        const nextLocation = getNextLocation(to, state);
        function retry() {
            replace(to, state);
        }
        warning(location.pathname.charAt(0) === '/', `Relative pathnames are not supported in memory history.replace(${JSON.stringify(to)})`);
        if (allowTx(nextAction, nextLocation, retry)) {
            entries[index] = nextLocation;
            applyTx(nextAction, nextLocation);
        }
    }
    function go(delta) {
        const nextIndex = clamp(index + delta, 0, entries.length - 1);
        const nextAction = Action.Pop;
        const nextLocation = entries[nextIndex];
        function retry() {
            go(delta);
        }
        if (allowTx(nextAction, nextLocation, retry)) {
            index = nextIndex;
            applyTx(nextAction, nextLocation);
        }
    }
    const history = {
        get index () {
            return index;
        },
        get action () {
            return action;
        },
        get location () {
            return location;
        },
        get entries () {
            return entries;
        },
        createHref,
        push,
        replace,
        go,
        back () {
            go(-1);
        },
        forward () {
            go(1);
        },
        listen (listener) {
            return listeners.push(listener);
        },
        block (blocker) {
            return blockers.push(blocker);
        }
    };
    return history;
}
function createKey() {
    return Math.random().toString(36).substr(2, 8);
}
function warning(cond, message) {
    if (!cond) {
        if (typeof console !== 'undefined') console.warn(message);
        try {
            throw new Error(message);
        } catch (e) {}
    }
}
function clamp(n, lowerBound, upperBound) {
    return Math.min(Math.max(n, lowerBound), upperBound);
}
function createEvents() {
    let handlers = [];
    return {
        get length () {
            return handlers.length;
        },
        push (fn) {
            handlers.push(fn);
            return function() {
                handlers = handlers.filter((handler)=>handler !== fn);
            };
        },
        call (arg) {
            handlers.forEach((fn)=>fn && fn(arg));
        }
    };
}
