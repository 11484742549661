import { Bound } from '@blocksuite/global/utils';
import { assertExists } from '@blocksuite/global/utils';
import { blockComponentGetter } from '../../../_common/utils/query.js';
import { isTopLevelBlock } from '../../../root-block/edgeless/utils/query.js';
export const edgelessToBlob = async (host, options)=>{
    const { edgelessElement, surfaceRefBlock } = options;
    const rootService = host.spec.getService('affine:page');
    const exportManager = rootService.exportManager;
    const bound = Bound.deserialize(edgelessElement.xywh);
    const isBlock = isTopLevelBlock(edgelessElement);
    return exportManager.edgelessToCanvas(options.surfaceRenderer, bound, (model)=>blockComponentGetter(model, surfaceRefBlock.host.view), undefined, isBlock ? [
        edgelessElement
    ] : undefined, isBlock ? undefined : [
        edgelessElement
    ], {
        zoom: options.surfaceRenderer.viewport.zoom
    }).then((canvas)=>{
        assertExists(canvas);
        return new Promise((resolve, reject)=>{
            canvas.toBlob((blob)=>blob ? resolve(blob) : reject(null), 'image/png');
        });
    });
};
export const writeImageBlobToClipboard = async (blob)=>{
    if (window.apis?.clipboard?.copyAsImageFromString) {
        await window.apis.clipboard?.copyAsImageFromString(blob);
    } else {
        await navigator.clipboard.write([
            new ClipboardItem({
                [blob.type]: blob
            })
        ]);
    }
};
