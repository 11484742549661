function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _dec1, _initClass, _WithDisposable, _dec2, _dec3, _dec4, _dec5, _init_config, _init_delta, _init_refMeta, _init_selected, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { assertExists } from '@blocksuite/global/utils';
import { INLINE_ROOT_ATTR, ZERO_WIDTH_NON_JOINER, ZERO_WIDTH_SPACE } from '@blocksuite/inline';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ref } from 'lit/directives/ref.js';
import { HoverController } from '../../../../components/hover/controller.js';
import { Peekable } from '../../../../components/peekable.js';
import { BLOCK_ID_ATTR } from '../../../../consts.js';
import { FontDocIcon, FontLinkedDocIcon } from '../../../../icons/text.js';
import { getModelByElement, getRootByElement } from '../../../../utils/query.js';
import { affineTextStyles } from '../affine-text.js';
import { DEFAULT_DOC_NAME, REFERENCE_NODE } from '../consts.js';
import { toggleReferencePopup } from './reference-popup.js';
let _AffineReference;
_dec = customElement('affine-reference'), _dec1 = Peekable({
    action: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    type: Object
}), _dec4 = state(), _dec5 = property({
    type: Boolean
});
new class extends _identity {
    constructor(){
        super(_AffineReference), _initClass();
    }
    static{
        class AffineReference extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_config, _init_delta, _init_refMeta, _init_selected, _initProto], c: [_AffineReference, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec2,
                        1,
                        "config"
                    ],
                    [
                        _dec3,
                        1,
                        "delta"
                    ],
                    [
                        _dec4,
                        1,
                        "refMeta"
                    ],
                    [
                        _dec5,
                        1,
                        "selected"
                    ]
                ], [
                    _dec,
                    _dec1
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    .affine-reference {
      white-space: normal;
      word-break: break-word;
      color: var(--affine-text-primary-color);
      fill: var(--affine-icon-color);
      border-radius: 4px;
      text-decoration: none;
      cursor: pointer;
      user-select: none;
      padding: 1px 2px 1px 0;
    }
    .affine-reference:hover {
      background: var(--affine-hover-color);
    }

    .affine-reference[data-selected='true'] {
      background: var(--affine-hover-color);
    }

    .affine-reference-title {
      margin-left: 4px;
      border-bottom: 0.5px solid var(--affine-divider-color);
      transition: border 0.2s ease-out;
    }
    .affine-reference-title:hover {
      border-bottom: 0.5px solid var(--affine-icon-color);
    }
  `;
            }
            _onClick() {
                if (!this.config.interactable) return;
                const refMeta = this.refMeta;
                const model = getModelByElement(this);
                if (!refMeta) {
                    console.warn('The doc is deleted', this._refAttribute.pageId);
                    return;
                }
                if (refMeta.id === model.doc.id) {
                    return;
                }
                const targetDocId = refMeta.id;
                const rootModel = model.doc.root;
                assertExists(rootModel);
                const rootComponent = getRootByElement(this);
                assertExists(rootComponent);
                rootComponent.slots.docLinkClicked.emit({
                    docId: targetDocId
                });
            }
            connectedCallback() {
                super.connectedCallback();
                assertExists(this.config, '`reference-node` need `ReferenceNodeConfig`.');
                if (this.delta.insert !== REFERENCE_NODE) {
                    console.error(`Reference node must be initialized with '${REFERENCE_NODE}', but got '${this.delta.insert}'`);
                }
                const doc = this.doc;
                this._disposables.add(doc.collection.slots.docUpdated.on(()=>this._updateRefMeta(doc)));
                this.updateComplete.then(()=>{
                    this.disposables.add(this.inlineEditor.slots.textChange.on(()=>this._updateRefMeta(doc)));
                }).catch(console.error);
            }
            render() {
                const refMeta = this.refMeta;
                const isDeleted = !refMeta;
                const attributes = this.delta.attributes;
                assertExists(attributes, 'Failed to get attributes!');
                const type = attributes.reference?.type;
                assertExists(type, 'Unable to get reference type!');
                const title = this.customTitle ? this.customTitle(this) : isDeleted ? 'Deleted doc' : refMeta.title.length > 0 ? refMeta.title : DEFAULT_DOC_NAME;
                const icon = this.customIcon ? this.customIcon(this) : type === 'LinkedPage' ? FontLinkedDocIcon : FontDocIcon;
                const style = affineTextStyles(attributes, isDeleted ? {
                    color: 'var(--affine-text-disable-color)',
                    textDecoration: 'line-through',
                    fill: 'var(--affine-text-disable-color)'
                } : {});
                const content = this.customContent ? this.customContent(this) : html`${icon}<span data-title=${title} class="affine-reference-title"
            >${title}</span
          >`;
                return html`<span
      ${this.config.interactable ? ref(this._whenHover.setReference) : ''}
      data-selected=${this.selected}
      class="affine-reference"
      style=${style}
      @click=${this._onClick}
      >${content}<v-text .str=${ZERO_WIDTH_NON_JOINER}></v-text
    ></span>`;
            }
            willUpdate(_changedProperties) {
                super.willUpdate(_changedProperties);
                const doc = this.doc;
                this._updateRefMeta(doc);
            }
            get block() {
                const block = this.inlineEditor.rootElement.closest(`[${BLOCK_ID_ATTR}]`);
                assertExists(block);
                return block;
            }
            get customContent() {
                return this.config.customContent;
            }
            get customIcon() {
                return this.config.customIcon;
            }
            get customTitle() {
                return this.config.customTitle;
            }
            get doc() {
                const doc = this.config.doc;
                assertExists(doc, '`reference-node` need `Doc`.');
                return doc;
            }
            get inlineEditor() {
                const inlineRoot = this.closest(`[${INLINE_ROOT_ATTR}]`);
                assertExists(inlineRoot);
                return inlineRoot.inlineEditor;
            }
            get selfInlineRange() {
                const selfInlineRange = this.inlineEditor.getInlineRangeFromElement(this);
                assertExists(selfInlineRange);
                return selfInlineRange;
            }
            get std() {
                const std = this.block.std;
                assertExists(std);
                return std;
            }
            #___private_config_1;
            get config() {
                return this.#___private_config_1;
            }
            set config(_v) {
                this.#___private_config_1 = _v;
            }
            #___private_delta_2;
            get delta() {
                return this.#___private_delta_2;
            }
            set delta(_v) {
                this.#___private_delta_2 = _v;
            }
            #___private_refMeta_3;
            get refMeta() {
                return this.#___private_refMeta_3;
            }
            set refMeta(_v) {
                this.#___private_refMeta_3 = _v;
            }
            #___private_selected_4;
            get selected() {
                return this.#___private_selected_4;
            }
            set selected(_v) {
                this.#___private_selected_4 = _v;
            }
            constructor(...args){
                super(...args);
                this._refAttribute = {
                    type: 'LinkedPage',
                    pageId: '0'
                };
                this._updateRefMeta = (doc)=>{
                    const refAttribute = this.delta.attributes?.reference;
                    assertExists(refAttribute, 'Failed to get reference attribute!');
                    this._refAttribute = refAttribute;
                    const refMeta = doc.collection.meta.docMetas.find((doc)=>doc.id === refAttribute.pageId);
                    this.refMeta = refMeta ? {
                        ...refMeta
                    } : undefined;
                };
                this._whenHover = new HoverController(this, ({ abortController })=>{
                    if (this.doc.readonly || this.closest('.prevent-reference-popup')) {
                        return null;
                    }
                    const selection = this.std.selection;
                    const textSelection = selection.find('text');
                    if (!!textSelection && (!!textSelection.to || !!textSelection.from.length)) {
                        return null;
                    }
                    const blockSelections = selection.filter('block');
                    if (blockSelections.length) {
                        return null;
                    }
                    return {
                        template: toggleReferencePopup(this, this.inlineEditor, this.selfInlineRange, this.refMeta?.title ?? DEFAULT_DOC_NAME, abortController)
                    };
                }, {
                    enterDelay: 500
                });
                this.#___private_config_1 = (_initProto(this), _init_config(this));
                this.#___private_delta_2 = _init_delta(this, {
                    insert: ZERO_WIDTH_SPACE,
                    attributes: {}
                });
                this.#___private_refMeta_3 = _init_refMeta(this, undefined);
                this.#___private_selected_4 = _init_selected(this, false);
            }
        }
    }
}();
export { _AffineReference as AffineReference };
