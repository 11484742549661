import { AttachmentBlockService, AttachmentBlockSpec } from '@blocksuite/blocks';
import bytes from 'bytes';
class CustomAttachmentBlockService extends AttachmentBlockService {
    mounted() {
        this.maxFileSize = bytes.parse('2GB');
        super.mounted();
    }
}
export const CustomAttachmentBlockSpec = {
    ...AttachmentBlockSpec,
    service: CustomAttachmentBlockService
};
