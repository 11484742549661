function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _init__surfaceModel, _init_blocksPortal, _init_container, _init_doc, _init_edgeless, _init_fillScreen, _init_frame, _init_host, _init_surfaceHeight, _init_surfaceWidth, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { deserializeXYWH } from '@blocksuite/global/utils';
import { Bound } from '@blocksuite/global/utils';
import { DisposableGroup, debounce } from '@blocksuite/global/utils';
import { BlockViewType, nanoid } from '@blocksuite/store';
import { css, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { SpecProvider } from '../../../../specs/index.js';
import '../../../../surface-ref-block/surface-ref-portal.js';
import { isTopLevelBlock } from '../../utils/query.js';
const DEFAULT_PREVIEW_CONTAINER_WIDTH = 280;
const DEFAULT_PREVIEW_CONTAINER_HEIGHT = 166;
const styles = css`
  .frame-preview-container {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }

  .frame-preview-surface-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
  }

  .frame-preview-surface-viewport {
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    pointer-events: none;
    user-select: none;
  }

  .frame-preview-surface-canvas-container {
    height: 100%;
    width: 100%;
    position: relative;
  }
`;
let _FramePreview;
_dec = customElement('frame-preview'), _dec1 = state(), _dec2 = query('.frame-preview-surface-container surface-ref-portal'), _dec3 = query('.frame-preview-surface-canvas-container'), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = state(), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_FramePreview), _initClass();
    }
    static{
        class FramePreview extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init__surfaceModel, _init_blocksPortal, _init_container, _init_doc, _init_edgeless, _init_fillScreen, _init_frame, _init_host, _init_surfaceHeight, _init_surfaceWidth, _initProto], c: [_FramePreview, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_surfaceModel"
                    ],
                    [
                        _dec2,
                        1,
                        "blocksPortal"
                    ],
                    [
                        _dec3,
                        1,
                        "container"
                    ],
                    [
                        _dec4,
                        1,
                        "doc"
                    ],
                    [
                        _dec5,
                        1,
                        "edgeless"
                    ],
                    [
                        _dec6,
                        1,
                        "fillScreen"
                    ],
                    [
                        _dec7,
                        1,
                        "frame"
                    ],
                    [
                        _dec8,
                        1,
                        "host"
                    ],
                    [
                        _dec9,
                        1,
                        "surfaceHeight"
                    ],
                    [
                        _dec10,
                        1,
                        "surfaceWidth"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = styles;
            }
            _attachRenderer() {
                if (this._surfaceRefRenderer?.surfaceRenderer.canvas.isConnected || !this.container || !this.blocksPortal) return;
                this.surfaceRenderer.viewport.setContainer(this.container);
                this.surfaceRenderer.attach(this.container);
                if (this.blocksPortal.isUpdatePending) {
                    this.blocksPortal.updateComplete.then(()=>{
                        this.blocksPortal.setStackingCanvas(this._surfaceRefRenderer.surfaceRenderer.stackingCanvas);
                    }).catch(console.error);
                } else {
                    this.blocksPortal.setStackingCanvas(this._surfaceRefRenderer.surfaceRenderer.stackingCanvas);
                }
            }
            _cleanupSurfaceRefRenderer() {
                const surfaceRefService = this._surfaceRefService;
                if (!surfaceRefService) return;
                surfaceRefService.removeRenderer(this._surfaceRefRendererId);
            }
            _refreshViewport() {
                if (!this.frame || !this._surfaceService) {
                    return;
                }
                const referencedModel = this.frame;
                this.updateComplete.then(()=>{
                    this.surfaceRenderer.viewport.onResize();
                    this.surfaceRenderer.viewport.setViewportByBound(Bound.fromXYWH(deserializeXYWH(referencedModel.xywh)));
                    this.blocksPortal?.setViewport(this.surfaceRenderer.viewport);
                }).catch(console.error);
            }
            _renderSurfaceContent(referencedModel) {
                const { width, height } = this._getViewportWH(referencedModel);
                const backgroundColor = this.surfaceRenderer.generateColorProperty(referencedModel.background, '--affine-platte-transparent');
                return html`<div
      class="frame-preview-surface-container"
      style=${styleMap({
                    width: `${this.surfaceWidth}px`,
                    height: `${this.surfaceHeight}px`
                })}
    >
      <div
        style=${styleMap({
                    backgroundColor,
                    borderRadius: '4px'
                })}
      >
        <div
          class="frame-preview-surface-viewport"
          style=${styleMap({
                    width: `${width}px`,
                    height: `${height}px`,
                    aspectRatio: `${width} / ${height}`
                })}
        >
          <surface-ref-portal
            .doc=${this.doc}
            .host=${this.host}
            .refModel=${referencedModel}
            .renderModel=${this._renderModel}
          ></surface-ref-portal>
          <div class="frame-preview-surface-canvas-container">
            <!-- attach canvas here -->
          </div>
        </div>
      </div>
    </div>`;
            }
            _setDocDisposables(doc) {
                this._clearDocDisposables();
                this._docDisposables = new DisposableGroup();
                this._docDisposables.add(doc.slots.blockUpdated.on((event)=>{
                    const { type } = event;
                    if (type === 'update') return;
                    const model = doc.getBlockById(event.id);
                    if (!model || !isTopLevelBlock(model) || !model.xywh) return;
                    const frameBound = Bound.deserialize(this.frame.xywh);
                    const modelBound = Bound.deserialize(model.xywh);
                    if (frameBound.containsPoint([
                        modelBound.x,
                        modelBound.y
                    ])) {
                        this._refreshViewport();
                    }
                }));
            }
            _setEdgelessDisposables(edgeless) {
                this._clearEdgelessDisposables();
                if (!edgeless) return;
                this._edgelessDisposables = new DisposableGroup();
                this._edgelessDisposables.add(edgeless.slots.navigatorSettingUpdated.on(({ fillScreen })=>{
                    if (fillScreen !== undefined) {
                        this.fillScreen = fillScreen;
                        this._refreshViewport();
                    }
                }));
                this._edgelessDisposables.add(edgeless.service.surface.elementAdded.on(({ id })=>{
                    if (this._overlapWithFrame(id)) {
                        this._refreshViewport();
                    }
                }));
                this._edgelessDisposables.add(edgeless.service.surface.elementUpdated.on(this._debounceHandleElementUpdated));
                this._edgelessDisposables.add(edgeless.service.surface.elementRemoved.on(()=>this._refreshViewport()));
            }
            _setFrameDisposables(frame) {
                this._clearFrameDisposables();
                this._frameDisposables = new DisposableGroup();
                this._frameDisposables.add(frame.propsUpdated.on(()=>{
                    this.requestUpdate();
                    this._refreshViewport();
                }));
            }
            _setupSurfaceRefRenderer() {
                const surfaceRefService = this._surfaceRefService;
                if (!surfaceRefService) return;
                const renderer = surfaceRefService.getRenderer(this._surfaceRefRendererId, this.doc, true);
                this._surfaceRefRenderer = renderer;
                this._disposables.add(renderer.slots.surfaceModelChanged.on((model)=>{
                    this._surfaceModel = model;
                }));
                this._disposables.add(renderer.slots.surfaceRendererRefresh.on(()=>{
                    this.requestUpdate();
                }));
                this._disposables.add(this._surfaceRefRenderer.surfaceService.layer.slots.layerUpdated.on(()=>{
                    this.blocksPortal.setStackingCanvas(this._surfaceRefRenderer.surfaceRenderer.stackingCanvas);
                }));
                renderer.mount();
            }
            get _surfaceRefService() {
                return this.host.spec.getService('affine:surface-ref');
            }
            get _surfaceService() {
                return this.host?.std.spec.getService('affine:surface');
            }
            _tryLoadFillScreen() {
                if (!this.edgeless) return;
                this.fillScreen = this.edgeless.service.editPropsStore.getStorage('presentFillScreen') ?? false;
            }
            connectedCallback() {
                super.connectedCallback();
                this._tryLoadFillScreen();
                this._setupSurfaceRefRenderer();
                this._setDocDisposables(this.doc);
                this._setEdgelessDisposables(this.edgeless);
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this._cleanupSurfaceRefRenderer();
                this._clearEdgelessDisposables();
                this._clearDocDisposables();
                this._clearFrameDisposables();
            }
            firstUpdated() {
                this._refreshViewport();
                this._setFrameDisposables(this.frame);
            }
            render() {
                const { _surfaceModel, frame, host, _surfaceService } = this;
                const noContent = !_surfaceModel || !frame || !frame.xywh || !host || !_surfaceService;
                return html`<div class="frame-preview-container">
      ${noContent ? nothing : this._renderSurfaceContent(frame)}
    </div>`;
            }
            updated(_changedProperties) {
                if (_changedProperties.has('edgeless')) {
                    if (this.edgeless) {
                        this._setEdgelessDisposables(this.edgeless);
                    } else {
                        this._clearEdgelessDisposables();
                    }
                    setTimeout(()=>{
                        this._refreshViewport();
                    });
                }
                if (_changedProperties.has('doc')) {
                    if (this.doc) {
                        this._setDocDisposables(this.doc);
                    }
                }
                this._attachRenderer();
            }
            get surfaceRenderer() {
                return this._surfaceRefRenderer.surfaceRenderer;
            }
            #___private__surfaceModel_1;
            get _surfaceModel() {
                return this.#___private__surfaceModel_1;
            }
            set _surfaceModel(_v) {
                this.#___private__surfaceModel_1 = _v;
            }
            #___private_blocksPortal_2;
            get blocksPortal() {
                return this.#___private_blocksPortal_2;
            }
            set blocksPortal(_v) {
                this.#___private_blocksPortal_2 = _v;
            }
            #___private_container_3;
            get container() {
                return this.#___private_container_3;
            }
            set container(_v) {
                this.#___private_container_3 = _v;
            }
            #___private_doc_4;
            get doc() {
                return this.#___private_doc_4;
            }
            set doc(_v) {
                this.#___private_doc_4 = _v;
            }
            #___private_edgeless_5;
            get edgeless() {
                return this.#___private_edgeless_5;
            }
            set edgeless(_v) {
                this.#___private_edgeless_5 = _v;
            }
            #___private_fillScreen_6;
            get fillScreen() {
                return this.#___private_fillScreen_6;
            }
            set fillScreen(_v) {
                this.#___private_fillScreen_6 = _v;
            }
            #___private_frame_7;
            get frame() {
                return this.#___private_frame_7;
            }
            set frame(_v) {
                this.#___private_frame_7 = _v;
            }
            #___private_host_8;
            get host() {
                return this.#___private_host_8;
            }
            set host(_v) {
                this.#___private_host_8 = _v;
            }
            #___private_surfaceHeight_9;
            get surfaceHeight() {
                return this.#___private_surfaceHeight_9;
            }
            set surfaceHeight(_v) {
                this.#___private_surfaceHeight_9 = _v;
            }
            #___private_surfaceWidth_10;
            get surfaceWidth() {
                return this.#___private_surfaceWidth_10;
            }
            set surfaceWidth(_v) {
                this.#___private_surfaceWidth_10 = _v;
            }
            constructor(...args){
                super(...args);
                this._clearDocDisposables = ()=>{
                    this._docDisposables?.dispose();
                    this._docDisposables = null;
                };
                this._clearEdgelessDisposables = ()=>{
                    this._edgelessDisposables?.dispose();
                    this._edgelessDisposables = null;
                };
                this._clearFrameDisposables = ()=>{
                    this._frameDisposables?.dispose();
                    this._frameDisposables = null;
                };
                this._debounceHandleElementUpdated = debounce((data)=>{
                    const { id, oldValues, props } = data;
                    if (!props.xywh) return;
                    if (this._overlapWithFrame(id)) {
                        this._refreshViewport();
                    } else if (oldValues.xywh) {
                        const oldBound = Bound.deserialize(oldValues.xywh);
                        const frameBound = Bound.deserialize(this.frame.xywh);
                        if (oldBound.isOverlapWithBound(frameBound)) {
                            this._refreshViewport();
                        }
                    }
                }, 1000 / 30);
                this._docDisposables = null;
                this._edgelessDisposables = null;
                this._frameDisposables = null;
                this._getViewportWH = (referencedModel)=>{
                    const [, , w, h] = deserializeXYWH(referencedModel.xywh);
                    let scale = 1;
                    if (this.fillScreen) {
                        scale = Math.max(this.surfaceWidth / w, this.surfaceHeight / h);
                    } else {
                        scale = Math.min(this.surfaceWidth / w, this.surfaceHeight / h);
                    }
                    return {
                        width: w * scale,
                        height: h * scale
                    };
                };
                this._overlapWithFrame = (id)=>{
                    const ele = this.edgeless?.service.getElementById(id);
                    if (!ele || !ele.xywh) return false;
                    const frameBound = Bound.deserialize(this.frame.xywh);
                    const eleBound = Bound.deserialize(ele.xywh);
                    return frameBound.isOverlapWithBound(eleBound);
                };
                this._renderModel = (model)=>{
                    const query = {
                        mode: 'include',
                        match: [
                            {
                                id: model.id,
                                viewType: BlockViewType.Display
                            }
                        ]
                    };
                    this._disposables.add(()=>{
                        doc.blockCollection.clearQuery(query);
                    });
                    const doc = model.doc.blockCollection.getDoc({
                        query
                    });
                    const previewSpec = SpecProvider.getInstance().getSpec('page:preview');
                    return this.host.renderSpecPortal(doc, previewSpec.value);
                };
                this._surfaceRefRendererId = nanoid();
                this.#___private__surfaceModel_1 = (_initProto(this), _init__surfaceModel(this, null));
                this.#___private_blocksPortal_2 = _init_blocksPortal(this);
                this.#___private_container_3 = _init_container(this);
                this.#___private_doc_4 = _init_doc(this);
                this.#___private_edgeless_5 = _init_edgeless(this, null);
                this.#___private_fillScreen_6 = _init_fillScreen(this, false);
                this.#___private_frame_7 = _init_frame(this);
                this.#___private_host_8 = _init_host(this);
                this.#___private_surfaceHeight_9 = _init_surfaceHeight(this, DEFAULT_PREVIEW_CONTAINER_HEIGHT);
                this.#___private_surfaceWidth_10 = _init_surfaceWidth(this, DEFAULT_PREVIEW_CONTAINER_WIDTH);
            }
        }
    }
}();
export { _FramePreview as FramePreview };
