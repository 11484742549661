import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from '@affine/component';
import { useNavigateHelper } from '@affine/core/hooks/use-navigate-helper';
import { useI18n } from '@affine/i18n';
import { CloseIcon, DualLinkIcon, ExpandFullIcon, SplitViewIcon } from '@blocksuite/icons/rc';
import { useService } from '@toeverything/infra';
import { clsx } from 'clsx';
import { useCallback, useMemo } from 'react';
import { WorkbenchService } from '../../workbench';
import { PeekViewService } from '../services/peek-view';
import * as styles from './peek-view-controls.css';
import { useDoc } from './utils';
export const ControlButton = ({ icon, nameKey, name, onClick })=>{
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
        e.preventDefault();
        onClick();
    }, [
        onClick
    ]);
    return _jsx(IconButton, {
        variant: "solid",
        tooltip: name,
        "data-testid": "peek-view-control",
        "data-action-name": nameKey,
        size: "20",
        onClick: handleClick,
        icon: icon,
        className: styles.button
    });
};
export const DefaultPeekViewControls = ({ className, ...rest })=>{
    const peekView = useService(PeekViewService).peekView;
    const t = useI18n();
    const controls = useMemo(()=>{
        return [
            {
                icon: _jsx(CloseIcon, {}),
                nameKey: 'close',
                name: t['com.affine.peek-view-controls.close'](),
                onClick: ()=>peekView.close()
            }
        ].filter((opt)=>Boolean(opt));
    }, [
        peekView,
        t
    ]);
    return _jsx("div", {
        ...rest,
        className: clsx(styles.root, className),
        children: controls.map((option)=>_jsx(ControlButton, {
                ...option
            }, option.nameKey))
    });
};
export const DocPeekViewControls = ({ docId, blockId, mode, className, ...rest })=>{
    const peekView = useService(PeekViewService).peekView;
    const workbench = useService(WorkbenchService).workbench;
    const { jumpToPageBlock } = useNavigateHelper();
    const t = useI18n();
    const { doc, workspace } = useDoc(docId);
    const controls = useMemo(()=>{
        return [
            {
                icon: _jsx(CloseIcon, {}),
                nameKey: 'close',
                name: t['com.affine.peek-view-controls.close'](),
                onClick: ()=>peekView.close()
            },
            {
                icon: _jsx(ExpandFullIcon, {}),
                name: t['com.affine.peek-view-controls.open-doc'](),
                nameKey: 'open',
                onClick: ()=>{
                    blockId ? jumpToPageBlock(workspace.id, docId, blockId) : workbench.openDoc(docId);
                    if (mode) {
                        doc?.setMode(mode);
                    }
                    peekView.close('none');
                }
            },
            environment.isDesktop && {
                icon: _jsx(SplitViewIcon, {}),
                nameKey: 'split-view',
                name: t['com.affine.peek-view-controls.open-doc-in-split-view'](),
                onClick: ()=>{
                    workbench.openDoc(docId, {
                        at: 'beside'
                    });
                    peekView.close('none');
                }
            },
            !environment.isDesktop && {
                icon: _jsx(DualLinkIcon, {}),
                nameKey: 'new-tab',
                name: t['com.affine.peek-view-controls.open-doc-in-new-tab'](),
                onClick: ()=>{
                    window.open(`/workspace/${workspace.id}/${docId}#${blockId ?? ''}`, '_blank');
                    peekView.close('none');
                }
            }
        ].filter((opt)=>Boolean(opt));
    }, [
        blockId,
        doc,
        docId,
        jumpToPageBlock,
        mode,
        peekView,
        t,
        workbench,
        workspace.id
    ]);
    return _jsx("div", {
        ...rest,
        className: clsx(styles.root, className),
        children: controls.map((option)=>_jsx(ControlButton, {
                ...option
            }, option.nameKey))
    });
};
