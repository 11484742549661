import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DocLinksService } from '@affine/core/modules/doc-link';
import { useI18n } from '@affine/i18n';
import { useLiveData, useServices, WorkspaceService } from '@toeverything/infra';
import { useCallback, useState } from 'react';
import { AffinePageReference } from '../../affine/reference-link';
import * as styles from './bi-directional-link-panel.css';
export const BiDirectionalLinkPanel = ()=>{
    const t = useI18n();
    const [show, setShow] = useState(false);
    const { docLinksService, workspaceService } = useServices({
        DocLinksService,
        WorkspaceService
    });
    const links = useLiveData(docLinksService.links.links$);
    const backlinks = useLiveData(docLinksService.backlinks.backlinks$);
    const handleClickShow = useCallback(()=>{
        setShow(!show);
    }, [
        show
    ]);
    return _jsxs("div", {
        className: styles.container,
        children: [
            !show && _jsx("div", {
                className: styles.dividerContainer,
                children: _jsx("div", {
                    className: styles.divider
                })
            }),
            _jsxs("div", {
                className: styles.titleLine,
                children: [
                    _jsx("div", {
                        className: styles.title,
                        children: t['ai.wemem.editor.bi-directional-link.title']()
                    }),
                    _jsx("div", {
                        className: styles.showButton,
                        onClick: handleClickShow,
                        children: show ? t['ai.wemem.editor.bi-directional-link.hide']() : t['ai.wemem.editor.bi-directional-link.show']()
                    })
                ]
            }),
            show && _jsxs(_Fragment, {
                children: [
                    _jsx("div", {
                        className: styles.dividerContainer,
                        children: _jsx("div", {
                            className: styles.divider
                        })
                    }),
                    _jsxs("div", {
                        className: styles.linksContainer,
                        children: [
                            _jsxs("div", {
                                className: styles.linksTitles,
                                children: [
                                    t['ai.wemem.editor.bi-directional-link.backlinks'](),
                                    " ·",
                                    ' ',
                                    backlinks.length
                                ]
                            }),
                            backlinks.map((link)=>_jsx("div", {
                                    className: styles.link,
                                    children: _jsx(AffinePageReference, {
                                        pageId: link.docId,
                                        docCollection: workspaceService.workspace.docCollection
                                    }, link.docId)
                                }, link.docId))
                        ]
                    }),
                    _jsxs("div", {
                        className: styles.linksContainer,
                        children: [
                            _jsxs("div", {
                                className: styles.linksTitles,
                                children: [
                                    t['ai.wemem.editor.bi-directional-link.outgoing-links'](),
                                    " ·",
                                    ' ',
                                    links.length
                                ]
                            }),
                            links.map((link)=>_jsx("div", {
                                    className: styles.link,
                                    children: _jsx(AffinePageReference, {
                                        pageId: link.docId,
                                        docCollection: workspaceService.workspace.docCollection
                                    })
                                }, link.docId))
                        ]
                    })
                ]
            })
        ]
    });
};
