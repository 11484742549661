import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { CreateCollectionModal } from './create-collection';
import { EditCollectionModal } from './edit-collection/edit-collection';
export const useEditCollection = ()=>{
    const [data, setData] = useState();
    const close = useCallback((open)=>{
        if (!open) {
            setData(undefined);
        }
    }, []);
    return {
        node: _jsx(EditCollectionModal, {
            init: data?.collection,
            open: !!data,
            mode: data?.mode,
            onOpenChange: close,
            onConfirm: data?.onConfirm ?? (()=>{})
        }),
        open: (collection, mode)=>new Promise((res)=>{
                setData({
                    collection,
                    mode,
                    onConfirm: (collection)=>{
                        res(collection);
                    }
                });
            })
    };
};
export const useEditCollectionName = ({ title, showTips })=>{
    const [data, setData] = useState();
    const close = useCallback((open)=>{
        if (!open) {
            setData(undefined);
        }
    }, []);
    return {
        node: _jsx(CreateCollectionModal, {
            showTips: showTips,
            title: title,
            init: data?.name ?? '',
            open: !!data,
            onOpenChange: close,
            onConfirm: data?.onConfirm ?? (()=>{})
        }),
        open: (name)=>new Promise((res)=>{
                setData({
                    name,
                    onConfirm: (collection)=>{
                        res(collection);
                    }
                });
            })
    };
};
