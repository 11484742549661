import { assertExists } from '@blocksuite/global/utils';
import { html } from 'lit';
import '../../../_common/components/toolbar/icon-button.js';
import '../../../_common/components/toolbar/menu-button.js';
import '../../../_common/components/toolbar/separator.js';
import '../../../_common/components/tooltip/tooltip.js';
import { getBlockProps } from '../../../_common/utils/block-props.js';
import { isInsidePageEditor } from '../../../_common/utils/query.js';
export function ConfigRenderer(block, abortController, config, onClick) {
    return config.filter((item)=>{
        return item.showWhen(block);
    }).map((item)=>{
        let template = null;
        switch(item.type){
            case 'common':
                {
                    const defaultItem = item;
                    const buttonClass = `image-toolbar-button ${defaultItem.name.toLocaleLowerCase()}`;
                    template = html`
            <editor-icon-button
              class=${buttonClass}
              .tooltip=${defaultItem.tooltip}
              .tooltipOffset=${4}
              @click=${()=>defaultItem.action(block, abortController)}
            >
              ${defaultItem.icon}
            </editor-icon-button>
          `;
                    break;
                }
            case 'custom':
                {
                    const customItem = item;
                    template = customItem.render(block, onClick);
                    break;
                }
            default:
                template = null;
        }
        return [
            template
        ];
    }).filter(([template])=>template !== null && template !== undefined).map(([template])=>template);
}
export function MoreMenuRenderer(block, abortController, config) {
    return config.filter((item)=>{
        return item.showWhen(block);
    }).map((item)=>{
        let template = null;
        switch(item.type){
            case 'more':
                {
                    const moreItem = item;
                    const buttonClass = `menu-item ${moreItem.name.toLocaleLowerCase()}`;
                    template = html`
            <editor-menu-action
              class=${buttonClass}
              @click=${(e)=>{
                        e.stopPropagation();
                        moreItem.action(block, abortController);
                    }}
            >
              ${moreItem.icon} ${moreItem.name}
            </editor-menu-action>
          `;
                    break;
                }
            case 'divider':
                {
                    template = html`
            <editor-toolbar-separator
              data-orientation="horizontal"
            ></editor-toolbar-separator>
          `;
                    break;
                }
            default:
                template = null;
        }
        return template;
    }).filter((template)=>template !== null).map((template)=>template);
}
export function duplicate(block, abortController) {
    const model = block.model;
    const blockProps = getBlockProps(model);
    const { width, height, xywh, rotate, zIndex, ...duplicateProps } = blockProps;
    const { doc } = model;
    const parent = doc.getParent(model);
    assertExists(parent, 'Parent not found');
    const index = parent?.children.indexOf(model);
    const duplicateId = doc.addBlock(model.flavour, duplicateProps, parent, index + 1);
    abortController?.abort();
    const editorHost = block.host;
    editorHost.updateComplete.then(()=>{
        const { selection } = editorHost;
        selection.setGroup('note', [
            selection.create('block', {
                blockId: duplicateId
            })
        ]);
        if (isInsidePageEditor(editorHost)) {
            const duplicateElement = editorHost.view.getBlock(duplicateId);
            if (duplicateElement) {
                duplicateElement.scrollIntoView(true);
            }
        }
    }).catch(console.error);
}
