export class RecoverableError extends Error {
    canRetry() {
        return this.ttl > 0;
    }
    constructor(...args){
        super(...args);
        this.ttl = 3;
    }
}
export class SessionFetchErrorRightAfterLoginOrSignUp extends RecoverableError {
    constructor(message, onRetry){
        super(message);
        this.onRetry = onRetry;
    }
    retry() {
        if (this.ttl <= 0) {
            return;
        }
        try {
            this.onRetry();
        } finally{
            this.ttl--;
        }
    }
}
