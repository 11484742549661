import { Entity } from '../../../framework';
import { Table } from './table';
export class DB extends Entity {
    constructor(){
        super();
        this.db = this.props.db;
        Object.entries(this.props.schema).forEach(([tableName])=>{
            const table = this.framework.createEntity(Table, {
                table: this.db[tableName],
                storageDocId: this.props.storageDocId(tableName)
            });
            Object.defineProperty(this, tableName, {
                get: ()=>table
            });
        });
    }
}
