import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from '@affine/component/ui/tooltip';
import { useCatchEventCallback } from '@affine/core/hooks/use-catch-event-hook';
import { SubscriptionPlan } from '@affine/graphql';
import { useI18n } from '@affine/i18n';
import { useLiveData, useServices } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { openSettingModalAtom } from '../../../atoms';
import { ServerConfigService, SubscriptionService } from '../../../modules/cloud';
import * as styles from './style.css';
export const UserPlanButton = ()=>{
    const { serverConfigService, subscriptionService } = useServices({
        ServerConfigService,
        SubscriptionService
    });
    const hasPayment = useLiveData(serverConfigService.serverConfig.features$.map((r)=>r?.payment));
    const plan = useLiveData(subscriptionService.subscription.pro$.map((subscription)=>subscription !== null ? subscription?.plan : null));
    const isBeliever = useLiveData(subscriptionService.subscription.isBeliever$);
    const isLoading = plan === null;
    useEffect(()=>{
        subscriptionService.subscription.revalidate();
    }, [
        subscriptionService
    ]);
    const setSettingModalAtom = useSetAtom(openSettingModalAtom);
    const handleClick = useCatchEventCallback(()=>{
        setSettingModalAtom({
            open: true,
            activeTab: 'plans',
            scrollAnchor: 'cloudPricingPlan'
        });
    }, [
        setSettingModalAtom
    ]);
    const t = useI18n();
    if (!hasPayment) {
        return;
    }
    if (isLoading) {
        return;
    }
    const planLabel = isBeliever ? 'Believer' : plan ?? SubscriptionPlan.Free;
    return _jsx(Tooltip, {
        content: t['com.affine.payment.tag-tooltips'](),
        side: "top",
        children: _jsx("div", {
            "data-is-believer": isBeliever ? 'true' : undefined,
            className: styles.userPlanButton,
            onClick: handleClick,
            "data-event-props": "$.settingsPanel.profileAndBadge.viewPlans",
            children: planLabel
        })
    });
};
