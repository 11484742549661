function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _init__selectedIndex, _init__text, _init_options, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { checkboxChecked, checkboxUnchecked } from '../../../list-block/utils/icons.js';
import { ArrowRightSmallIcon, DoneIcon } from '../../icons/index.js';
import { rangeWrap } from '../../utils/math.js';
const isSelectableItem = (item)=>{
    return item.type === 'select';
};
let _MenuComponent;
_dec = customElement('affine-menu'), _dec1 = state(), _dec2 = state(), _dec3 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_MenuComponent), _initClass();
    }
    static{
        class MenuComponent extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init__selectedIndex, _init__text, _init_options, _initProto], c: [_MenuComponent, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_selectedIndex"
                    ],
                    [
                        _dec2,
                        1,
                        "_text"
                    ],
                    [
                        _dec3,
                        1,
                        "options"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    affine-menu {
      font-family: var(--affine-font-family);
      display: flex;
      flex-direction: column;
      user-select: none;
      min-width: 276px;
      box-shadow: var(--affine-shadow-2);
      border-radius: 8px;
      background-color: var(--affine-background-overlay-panel-color);
      padding: 8px;
      position: absolute;
      z-index: 999;
    }

    affine-menu * {
      box-sizing: border-box;
    }

    .affine-menu-body {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .affine-menu-header {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
    }

    /* .affine-menu-header .icon {
    } */
    .affine-menu-header input {
      flex: 1;
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      line-height: 22px;
      padding: 5px 12px;
      border: 1px solid var(--affine-border-color);
    }

    .affine-menu-header input::placeholder {
      color: var(--affine-placeholder-color);
    }

    .affine-menu-header input:focus {
      border: 1px solid var(--affine-primary-color);
    }

    .affine-menu-action {
      padding: 4px 12px;
      cursor: pointer;
      display: flex;
      gap: 4px;
      border-radius: 4px;
    }

    .affine-menu-action svg {
      width: 20px;
      height: 20px;
    }

    .affine-menu-action .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--affine-icon-color);
      fill: var(--affine-icon-color);
    }

    .affine-menu-action .content {
      border-radius: 4px;
      cursor: pointer;
      white-space: nowrap;
      justify-content: space-between;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      flex: 1;
      gap: 8px;
    }

    .affine-menu-action.selected {
      background-color: var(--affine-hover-color);
    }

    .affine-menu-action.selected.delete-item {
      background-color: var(--affine-background-error-color);
      color: var(--affine-error-color);
    }

    .affine-menu-action.selected.delete-item .icon > svg {
      color: var(--affine-error-color);
    }

    .affine-menu-action.selected-item {
      color: var(--affine-text-emphasis-color);
    }

    .affine-menu-action.selected-item .icon {
      color: var(--affine-text-emphasis-color);
      fill: currentColor;
    }

    .database-menu-component-action-button:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .no-results {
      font-size: 12px;
      line-height: 20px;
      color: var(--affine-text-secondary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }

    .affine-menu-action-text {
      flex: 1;
      padding: 0 4px;
    }
  `;
            }
            _isConsciousChoice() {
                return Date.now() < this.initTime + 100;
            }
            clearSubMenu() {
                this.subMenu?.remove();
                this.subMenu = undefined;
                this.focusInput();
            }
            close() {
                this.options.onClose?.();
            }
            focusInput() {
                this.inputRef.value?.focus();
            }
            getChecked(name) {
                return this._checked[name];
            }
            get isSearchMode() {
                return this.options.input?.search;
            }
            get minIndex() {
                return this.isSearchMode ? 0 : -1;
            }
            process(menu) {
                if (this.show(menu)) {
                    return this.processMap[menu.type](menu);
                } else {
                    return [];
                }
            }
            get selectedIndex() {
                return this._selectedIndex;
            }
            set selectedIndex(index) {
                const old = this._selectedIndex != null ? this.selectableItems[this._selectedIndex] : undefined;
                old?.onHover?.(false);
                if (index == null) {
                    this._selectedIndex = index;
                    return;
                }
                const newIndex = rangeWrap(index ?? this.minIndex, this.minIndex, this.selectableItems.length);
                this._selectedIndex = newIndex;
                this.selectableItems[newIndex]?.onHover?.(true);
            }
            get selectedItem() {
                return this.selectedIndex != null ? this.selectableItems[this.selectedIndex] : undefined;
            }
            setChecked(name, checked) {
                this._checked[name] = checked;
                this.requestUpdate();
            }
            show(item) {
                if (this.isSearchMode) {
                    if (item.type === 'group') {
                        return !item.hide?.();
                    }
                    if (item.type === 'custom') {
                        return this.text.length === 0;
                    }
                    if (!item.name.toLowerCase().includes(this.text.toLowerCase())) {
                        return false;
                    }
                }
                return !item.hide?.();
            }
            showHeader() {
                return !this.isSearchMode || !!this.text;
            }
            get text() {
                return this._text ?? this.options.input?.initValue ?? '';
            }
            set text(value) {
                this._text = value;
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this.selectedItem?.onHover?.(false);
            }
            firstUpdated() {
                this.initTime = Date.now();
                const input = this.inputRef.value;
                if (input) {
                    requestAnimationFrame(()=>{
                        this.focusInput();
                    });
                    const length = input.value.length;
                    input.setSelectionRange(length, length);
                    this._disposables.addFromEvent(input, 'keydown', (e)=>{
                        e.stopPropagation();
                        if (e.key === 'Escape') {
                            this.close();
                            return;
                        }
                        if (e.key === 'Enter' && !e.isComposing) {
                            const selectedItem = this.selectedItem;
                            if (selectedItem) {
                                selectedItem.select();
                            } else {
                                this.options.input?.onComplete?.(this.text);
                                this._complete();
                            }
                            return;
                        }
                        if (e.key === 'ArrowUp') {
                            e.preventDefault();
                            this.selectedIndex = this.selectedIndex != null ? this.selectedIndex - 1 : this.minIndex;
                            return;
                        }
                        if (e.key === 'ArrowDown') {
                            e.preventDefault();
                            this.selectedIndex = this.selectedIndex != null ? this.selectedIndex + 1 : this.minIndex;
                            return;
                        }
                    });
                    this._disposables.addFromEvent(input, 'copy', (e)=>{
                        e.stopPropagation();
                    });
                    this._disposables.addFromEvent(input, 'cut', (e)=>{
                        e.stopPropagation();
                    });
                }
            }
            processItems() {
                this.allItems = [];
                this.selectableItems = [];
                this.options.items.flatMap((item)=>this.process(item)).forEach((item)=>{
                    const isSelectable = isSelectableItem(item);
                    this.allItems.push({
                        ...item,
                        index: isSelectable ? this.selectableItems.length : undefined
                    });
                    if (isSelectable) {
                        this.selectableItems.push(item);
                    }
                });
            }
            render() {
                this.processItems();
                this.selectedIndex = this._selectedIndex;
                const showHeader = this.showHeader();
                const headerStyle = styleMap({
                    opacity: showHeader ? '1' : '0',
                    height: showHeader ? undefined : '0',
                    overflow: showHeader ? undefined : 'hidden',
                    position: showHeader ? undefined : 'absolute'
                });
                const showHeaderDivider = this.selectableItems.length > 0 && showHeader && this.options.input?.divider !== false;
                return html`
      <div
        class="affine-menu"
        style=${ifDefined(this.options.style)}
        @click="${this._clickContainer}"
      >
        ${this.options.input ? html` <div
                class="affine-menu-header"
                style=${headerStyle}
                @mouseenter="${this.mouseEnterHeader}"
              >
                ${this.options.input.icon ? html`<div class="icon">${this.options.input.icon}</div>` : nothing}
                <input
                  autocomplete="off"
                  data-1p-ignore
                  ${ref(this.inputRef)}
                  type="text"
                  placeholder="${this.options.input?.placeholder ?? ''}"
                  value="${this.text ?? this.options.input?.initValue ?? ''}"
                  @input="${this._inputText}"
                />
              </div>
              ${showHeaderDivider ? html`<menu-divider style="width: 100%"></menu-divider>` : null}` : null}
        <div class="affine-menu-body">
          ${this.selectableItems.length === 0 && this.isSearchMode ? html` <div class="no-results">No Results</div>` : ''}
          ${repeat(this.allItems, (menu, index)=>{
                    const i = menu.index;
                    const hideDividerWhenHeaderDividerIsShow = i === 0;
                    const divider = menu.upDivider || this.allItems[index - 1]?.downDivider;
                    const mouseEnter = ()=>{
                        this._mouseEnter(i);
                    };
                    if (menu.type === 'ui') {
                        return html`
                ${divider ? html`<menu-divider style="width: 100%"></menu-divider>` : null}
                <div @mouseenter=${mouseEnter}>${menu.render()}</div>
              `;
                    }
                    const itemClass = menu.class || 'affine-menu-item';
                    const classes = classMap({
                        'affine-menu-action': true,
                        selected: menu.type === 'select' && this._selectedIndex === i,
                        [itemClass]: true
                    });
                    const select = ()=>{
                        if (!isSelectableItem(menu)) return;
                        menu.select();
                    };
                    return html`
              ${divider && !hideDividerWhenHeaderDividerIsShow ? html`<menu-divider style="width: 100%"></menu-divider>` : null}
              <div
                class="${classes}"
                @click=${select}
                @mouseenter=${mouseEnter}
              >
                <div class="content">${menu.label()}</div>
              </div>
            `;
                })}
        </div>
      </div>
    `;
            }
            #___private__selectedIndex_1;
            get _selectedIndex() {
                return this.#___private__selectedIndex_1;
            }
            set _selectedIndex(_v) {
                this.#___private__selectedIndex_1 = _v;
            }
            #___private__text_2;
            get _text() {
                return this.#___private__text_2;
            }
            set _text(_v) {
                this.#___private__text_2 = _v;
            }
            #___private_options_3;
            get options() {
                return this.#___private_options_3;
            }
            set options(_v) {
                this.#___private_options_3 = _v;
            }
            constructor(...args){
                super(...args);
                this._checked = {};
                this._clickContainer = (e)=>{
                    e.stopPropagation();
                    this.focusInput();
                };
                this._complete = ()=>{
                    this.options.onComplete?.();
                    this.close();
                };
                this._inputText = (e)=>{
                    const target = e.target;
                    this.text = target.value;
                };
                this._mouseEnter = (index)=>{
                    if (this._isConsciousChoice()) {
                        return;
                    }
                    if (index !== this.selectedIndex) {
                        this.selectedIndex = index;
                        this.clearSubMenu();
                        this.selectedItem?.mouseEnter?.();
                    }
                };
                this.allItems = [];
                this.initTime = 0;
                this.inputRef = createRef();
                this.processMap = {
                    action: (menu)=>{
                        const icon = menu.icon ? html` <div class="icon">${menu.icon}</div>` : nothing;
                        const postfixIcon = menu.postfix ?? (menu.isSelected ? DoneIcon : undefined);
                        const postfix = postfixIcon ? html` <div class="icon">${postfixIcon}</div>` : nothing;
                        return [
                            {
                                type: 'select',
                                label: ()=>html`
            ${icon}
            <div class="affine-menu-action-text">
              ${menu.label?.() ?? menu.name}
            </div>
            ${postfix}
          `,
                                onHover: menu.onHover,
                                select: ()=>{
                                    menu.select();
                                    this._complete();
                                },
                                class: menu.class ?? (menu.isSelected ? 'selected-item' : '')
                            }
                        ];
                    },
                    checkbox: (menu)=>{
                        const postfix = menu.postfix ? html` <div class="icon">${menu.postfix}</div>` : nothing;
                        const checked = this.getChecked(menu.name) ?? menu.checked;
                        return [
                            {
                                type: 'select',
                                label: ()=>html`
            <div class="icon">
              ${checked ? checkboxChecked() : checkboxUnchecked()}
            </div>
            <div class="affine-menu-action-text">
              ${menu.label?.() ?? menu.name}
            </div>
            ${postfix}
          `,
                                select: ()=>{
                                    this.setChecked(menu.name, menu.select(checked));
                                },
                                class: menu.class ?? ''
                            }
                        ];
                    },
                    'toggle-switch': (menu)=>{
                        const postfix = menu.postfix ? html` <div class="icon">${menu.postfix}</div>` : nothing;
                        const onChange = (on)=>{
                            menu.onChange(on);
                        };
                        return [
                            {
                                type: 'normal',
                                label: ()=>html`
            <div class="affine-menu-action-text">
              ${menu.label?.() ?? menu.name}
            </div>

            <toggle-switch .on=${menu.on} .onChange=${onChange}></toggle-switch>
            ${postfix}
          `,
                                class: menu.class ?? ''
                            }
                        ];
                    },
                    group: (menu)=>{
                        const items = menu.children().flatMap((menu)=>this.process(menu));
                        if (items[0]) {
                            items[0].upDivider = true;
                        }
                        if (items[items.length - 1]) {
                            items[items.length - 1].downDivider = true;
                        }
                        return items;
                    },
                    'sub-menu': (menu)=>{
                        const openSubMenu = ()=>{
                            this.subMenu?.remove();
                            setTimeout(()=>{
                                const subMenu = new _MenuComponent();
                                const options = menu.options;
                                subMenu.options = {
                                    ...options,
                                    onClose: ()=>{
                                        options.onClose?.();
                                        this.clearSubMenu();
                                    },
                                    onComplete: ()=>{
                                        this._complete();
                                    }
                                };
                                this.append(subMenu);
                                computePosition(this, subMenu, {
                                    placement: 'right-start',
                                    middleware: [
                                        flip({
                                            fallbackPlacements: [
                                                'left-start',
                                                'right-end',
                                                'left-end'
                                            ]
                                        }),
                                        offset(4)
                                    ]
                                }).then(({ x, y })=>{
                                    Object.assign(subMenu.style, {
                                        left: `${x}px`,
                                        top: `${y}px`
                                    });
                                }).catch(console.error);
                                this.subMenu = subMenu;
                            });
                        };
                        const select = ()=>{
                            menu.select?.();
                            menu.select ? this._complete() : openSubMenu();
                        };
                        const postfix = html` <div class="icon">
        ${menu.postfix ?? ArrowRightSmallIcon}
      </div>`;
                        return [
                            {
                                type: 'select',
                                label: ()=>html`${menu.icon ? html` <div class="icon">${menu.icon}</div>` : nothing}
              <div class="affine-menu-action-text">
                ${menu.label?.() ?? menu.name}
              </div>
              ${postfix}`,
                                mouseEnter: openSubMenu,
                                select,
                                class: menu.isSelected ? 'selected-item' : ''
                            }
                        ];
                    },
                    custom: (menu)=>{
                        return [
                            {
                                type: 'ui',
                                render: menu.render
                            }
                        ];
                    }
                };
                this.mouseEnterHeader = ()=>{
                    if (this.isSearchMode) {
                        return;
                    }
                    this._mouseEnter(-1);
                };
                this.#___private__selectedIndex_1 = (_initProto(this), _init__selectedIndex(this, undefined));
                this.#___private__text_2 = _init__text(this, undefined);
                this.#___private_options_3 = _init_options(this);
            }
        }
    }
}();
export const getDefaultModalRoot = (ele)=>{
    const host = ele.closest('editor-host') ?? ele.closest('.data-view-popup-container');
    if (host) {
        return host;
    }
    return document.body;
};
export const createModal = (container = document.body)=>{
    const div = document.createElement('div');
    div.style.pointerEvents = 'auto';
    div.style.position = 'absolute';
    div.style.left = '0';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    div.style.zIndex = '1001';
    div.style.fontFamily = 'var(--affine-font-family)';
    container.append(div);
    return div;
};
export const positionToVRect = (x, y)=>{
    return {
        getBoundingClientRect () {
            return {
                x: x,
                y: y,
                width: 0,
                height: 0,
                top: y,
                bottom: y,
                left: x,
                right: x
            };
        }
    };
};
export const createPopup = (target, content, options)=>{
    const modal = createModal(options?.container ?? getDefaultModalRoot(target));
    autoUpdate(target, content, ()=>{
        computePosition(target, content, {
            placement: options?.placement,
            middleware: options?.middleware ?? [
                shift({
                    crossAxis: true
                })
            ]
        }).then(({ x, y })=>{
            Object.assign(content.style, {
                left: `${x}px`,
                top: `${y}px`
            });
        }).catch(console.error);
    });
    modal.append(content);
    modal.onmousedown = (ev)=>{
        if (ev.target === modal) {
            modal.remove();
            options?.onClose?.();
        }
    };
    modal.oncontextmenu = (ev)=>{
        ev.preventDefault();
        if (ev.target === modal) {
            modal.remove();
            options?.onClose?.();
        }
    };
    return ()=>modal.remove();
};
export const popMenu = (target, props)=>{
    const menu = new _MenuComponent();
    menu.options = {
        ...props.options,
        onClose: ()=>{
            props.options.onClose?.();
            close();
        }
    };
    const close = createPopup(target, menu, {
        onClose: props.options.onClose,
        middleware: props.middleware,
        container: props.container,
        placement: props.placement
    });
    return {
        close
    };
};
export const popFilterableSimpleMenu = (target, options, onClose)=>{
    popMenu(target, {
        options: {
            input: {
                placeholder: 'Search',
                search: true
            },
            items: options,
            onClose
        }
    });
};
export { _MenuComponent as MenuComponent };
