import { columnType } from '../../data-view/column/column-config.js';
import { tRichText } from '../../data-view/logical/data-type.js';
export const titleColumnType = columnType('title');
export const titlePureColumnConfig = titleColumnType.modelConfig({
    name: 'Title',
    type: ()=>tRichText.create(),
    defaultData: ()=>({}),
    cellToString: (data)=>data?.toString() ?? '',
    cellFromString: (data)=>{
        return {
            value: data
        };
    },
    cellToJson: (data)=>data?.toString() ?? null,
    onUpdate: (value, _data, callback)=>{
        value.yText.observe(callback);
        callback();
        return {
            dispose: ()=>{
                value.yText.unobserve(callback);
            }
        };
    },
    valueUpdate: (value, _data, newValue)=>{
        const v = newValue;
        if (typeof v === 'string') {
            value.replace(0, value.length, v);
            return value;
        }
        if (v == null) {
            value.replace(0, value.length, '');
            return value;
        }
        return newValue;
    },
    isEmpty: (data)=>data == null || data.length === 0
});
