import { signal } from '@lit-labs/preact-signals';
export var ColorScheme;
(function(ColorScheme) {
    ColorScheme["Dark"] = "dark";
    ColorScheme["Light"] = "light";
})(ColorScheme || (ColorScheme = {}));
const TRANSPARENT = 'transparent';
export class ThemeObserver {
    static #computedStyle;
    static #instance;
    #observer;
    static get computedStyle() {
        let computedStyle = ThemeObserver.#computedStyle;
        if (!computedStyle) {
            computedStyle = window.getComputedStyle(document.documentElement);
            ThemeObserver.#computedStyle = computedStyle;
        }
        return computedStyle;
    }
    static generateColorProperty(color, fallback = 'transparent') {
        fallback = fallback.startsWith('--') ? fallback.endsWith(TRANSPARENT) ? TRANSPARENT : `var(${fallback})` : fallback;
        if (typeof color === 'string') {
            return (color.startsWith('--') ? color.endsWith(TRANSPARENT) ? TRANSPARENT : `var(${color})` : color) ?? fallback;
        }
        if (!color) {
            return fallback;
        }
        if (color.light && color.dark) {
            return `light-dark(${color.light}, ${color.dark})`;
        }
        return color.normal ?? fallback;
    }
    static getColorValue(color, fallback = TRANSPARENT, real) {
        if (typeof color === 'object') {
            color = color[ThemeObserver.mode] ?? color.normal ?? fallback;
        }
        if (!color) {
            color = fallback ?? TRANSPARENT;
        }
        if (real && color.startsWith('--')) {
            color = color.endsWith(TRANSPARENT) ? TRANSPARENT : ThemeObserver.getPropertyValue(color);
            if (!color) {
                color = fallback.startsWith('--') ? ThemeObserver.getPropertyValue(fallback) : fallback;
            }
        }
        return color;
    }
    static getPropertyValue(property) {
        if (property.startsWith('--')) {
            if (property.endsWith(TRANSPARENT)) {
                return TRANSPARENT;
            }
            return ThemeObserver.computedStyle.getPropertyValue(property).trim() || property;
        }
        return property;
    }
    static get instance() {
        if (!ThemeObserver.#instance) {
            const instance = new ThemeObserver();
            instance.observe(document.documentElement);
            ThemeObserver.#instance = instance;
        }
        return ThemeObserver.#instance;
    }
    static get mode() {
        return ThemeObserver.instance.mode$.peek();
    }
    static subscribe(callback) {
        return ThemeObserver.instance.mode$.subscribe(callback);
    }
    disconnect() {
        this.#observer?.disconnect();
    }
    observe(element) {
        const callback = ()=>{
            const mode = element.dataset.theme;
            if (mode && this.mode$.peek() !== mode) {
                this.mode$.value = mode;
            }
        };
        this.#observer?.disconnect();
        this.#observer = new MutationObserver(callback);
        this.#observer.observe(element, {
            attributes: true,
            attributeFilter: [
                'data-theme'
            ]
        });
        callback();
    }
    constructor(){
        this.mode$ = signal("light");
    }
}
