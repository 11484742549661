import '../../_common/components/ask-ai-button';
import { html } from 'lit';
import { buildAIImageItemGroups } from '../../_common/config';
const AIImageItemGroups = buildAIImageItemGroups();
const buttonOptions = {
    size: 'small',
    backgroundColor: 'var(--affine-white)',
    panelWidth: 300
};
export function setupImageToolbarEntry(imageToolbar) {
    const onAskAIClick = ()=>{
        const { host } = imageToolbar;
        const { selection } = host;
        const imageBlock = imageToolbar.block;
        selection.setGroup('note', [
            selection.create('image', {
                blockId: imageBlock.blockId
            })
        ]);
    };
    imageToolbar.buildDefaultConfig();
    return;
    imageToolbar.addConfigItems([
        {
            type: 'custom',
            render (imageBlock, onClick) {
                return html`<ask-ai-button
            class="image-toolbar-button ask-ai"
            .host=${imageBlock.host}
            .actionGroups=${AIImageItemGroups}
            .toggleType=${'click'}
            .options=${buttonOptions}
            @click=${(e)=>{
                    e.stopPropagation();
                    onAskAIClick();
                    onClick?.();
                }}
          ></ask-ai-button>`;
            },
            showWhen: (imageBlockComponent)=>!imageBlockComponent.doc.readonly
        }
    ], 0);
}
