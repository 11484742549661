import { jsx as _jsx } from "react/jsx-runtime";
import { MenuIcon, MenuItem, MenuSub } from '@affine/component';
import { track } from '@affine/core/mixpanel';
import { useI18n } from '@affine/i18n';
import { ExportIcon, ExportToHtmlIcon, ExportToMarkdownIcon, ExportToPdfIcon, ExportToPngIcon } from '@blocksuite/icons/rc';
import { useCallback, useMemo } from 'react';
import { transitionStyle } from './index.css';
export function ExportMenuItem({ onSelect, className, type, icon, label }) {
    return _jsx(MenuItem, {
        className: className,
        "data-testid": `export-to-${type}`,
        onSelect: onSelect,
        block: true,
        preFix: _jsx(MenuIcon, {
            children: icon
        }),
        children: label
    });
}
export const ExportMenuItems = ({ exportHandler, className = transitionStyle, pageMode = 'page' })=>{
    const t = useI18n();
    const itemMap = useMemo(()=>[
            {
                component: ExportMenuItem,
                props: {
                    onSelect: ()=>exportHandler('pdf'),
                    className: className,
                    type: 'pdf',
                    icon: _jsx(ExportToPdfIcon, {}),
                    label: t['Export to PDF']()
                }
            },
            {
                component: ExportMenuItem,
                props: {
                    onSelect: ()=>exportHandler('html'),
                    className: className,
                    type: 'html',
                    icon: _jsx(ExportToHtmlIcon, {}),
                    label: t['Export to HTML']()
                }
            },
            {
                component: ExportMenuItem,
                props: {
                    onSelect: ()=>exportHandler('png'),
                    className: className,
                    type: 'png',
                    icon: _jsx(ExportToPngIcon, {}),
                    label: t['Export to PNG']()
                }
            },
            {
                component: ExportMenuItem,
                props: {
                    onSelect: ()=>exportHandler('markdown'),
                    className: className,
                    type: 'markdown',
                    icon: _jsx(ExportToMarkdownIcon, {}),
                    label: t['Export to Markdown']()
                }
            }
        ], [
        className,
        exportHandler,
        t
    ]);
    const items = itemMap.map(({ component: Component, props })=>pageMode === 'edgeless' && (props.type === 'pdf' || props.type === 'png') ? null : _jsx(Component, {
            ...props
        }, props.label));
    return items;
};
export const Export = ({ exportHandler, className, pageMode })=>{
    const t = useI18n();
    const items = _jsx(ExportMenuItems, {
        exportHandler: exportHandler,
        className: className,
        pageMode: pageMode
    });
    const handleExportMenuOpenChange = useCallback((open)=>{
        if (open) {
            track.$.header.docOptions.export();
        }
    }, []);
    return _jsx(MenuSub, {
        items: items,
        triggerOptions: {
            className: transitionStyle,
            preFix: _jsx(MenuIcon, {
                children: _jsx(ExportIcon, {})
            }),
            ['data-testid']: 'export-menu'
        },
        subOptions: {
            onOpenChange: handleExportMenuOpenChange
        },
        children: t.Export()
    });
};
