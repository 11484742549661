import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export function useBindWorkbenchToDesktopRouter(workbench, basename) {
    const browserLocation = useLocation();
    useEffect(()=>{
        const newLocation = browserLocationToViewLocation(browserLocation, basename);
        if (newLocation === null) {
            return;
        }
        if (workbench.location$.value.pathname === newLocation.pathname && workbench.location$.value.search === newLocation.search && workbench.location$.value.hash === newLocation.hash) {
            return;
        }
        workbench.open(newLocation);
    }, [
        basename,
        browserLocation,
        workbench
    ]);
}
function browserLocationToViewLocation(location, basename) {
    if (!location.pathname.startsWith(basename)) {
        return null;
    }
    return {
        ...location,
        pathname: location.pathname.slice(basename.length)
    };
}
