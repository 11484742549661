import { getWorkspacePublicPagesQuery } from '@affine/graphql';
import { Store } from '@toeverything/infra';
export class ShareDocsStore extends Store {
    constructor(graphqlService){
        super();
        this.graphqlService = graphqlService;
    }
    async getWorkspacesShareDocs(workspaceId, signal) {
        const data = await this.graphqlService.gql({
            query: getWorkspacePublicPagesQuery,
            variables: {
                workspaceId: workspaceId
            },
            context: {
                signal
            }
        });
        return data.workspace.publicPages;
    }
}
