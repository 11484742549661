function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _dec1, _initClass, _BlockComponent, _dec2, _dec3, _dec4, _dec5, _init__focused, _init__surfaceModel, _init_captionElement, _init_previewEditor, _initProto;
import { BlockComponent } from '@blocksuite/block-std';
import { GfxBlockElementModel } from '@blocksuite/block-std/gfx';
import { Bound, deserializeXYWH } from '@blocksuite/global/utils';
import { assertExists, noop } from '@blocksuite/global/utils';
import { css, html, nothing } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Peekable } from '../_common/components/peekable.js';
import { bindContainerHotkey } from '../_common/components/rich-text/keymap/container.js';
import { EdgelessModeIcon, FrameIcon, MoreDeleteIcon } from '../_common/icons/index.js';
import { requestConnectedFrame } from '../_common/utils/event.js';
import { SpecProvider } from '../specs/index.js';
import { SurfaceElementModel } from '../surface-block/index.js';
import './surface-ref-portal.js';
import { SurfaceRefPortal } from './surface-ref-portal.js';
import { noContentPlaceholder } from './utils.js';
noop(SurfaceRefPortal);
const REF_LABEL_ICON = {
    'affine:frame': FrameIcon,
    DEFAULT_NOTE_HEIGHT: EdgelessModeIcon
};
const NO_CONTENT_TITLE = {
    'affine:frame': 'Frame',
    group: 'Group',
    DEFAULT: 'Content'
};
const NO_CONTENT_REASON = {
    group: 'This content was ungrouped or deleted on edgeless mode',
    DEFAULT: 'This content was deleted on edgeless mode'
};
let _SurfaceRefBlockComponent;
_dec = customElement('affine-surface-ref'), _dec1 = Peekable(), _dec2 = state(), _dec3 = state(), _dec4 = query('affine-surface-ref > block-caption-editor'), _dec5 = query('editor-host');
new class extends _identity {
    constructor(){
        super(_SurfaceRefBlockComponent), _initClass();
    }
    static{
        class SurfaceRefBlockComponent extends (_BlockComponent = BlockComponent) {
            static{
                ({ e: [_init__focused, _init__surfaceModel, _init_captionElement, _init_previewEditor, _initProto], c: [_SurfaceRefBlockComponent, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec2,
                        1,
                        "_focused"
                    ],
                    [
                        _dec3,
                        1,
                        "_surfaceModel"
                    ],
                    [
                        _dec4,
                        1,
                        "captionElement"
                    ],
                    [
                        _dec5,
                        1,
                        "previewEditor"
                    ]
                ], [
                    _dec,
                    _dec1
                ], _BlockComponent));
            }
            static{
                this.styles = css`
    .affine-surface-ref {
      position: relative;
      user-select: none;
      margin: 10px 0;
    }

    .ref-placeholder {
      padding: 26px 0px 0px;
    }

    .placeholder-image {
      margin: 0 auto;
      text-align: center;
    }

    .placeholder-text {
      margin: 12px auto 0;
      text-align: center;
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;
      font-family: var(--affine-font-family);
    }

    .placeholder-action {
      margin: 32px auto 0;
      text-align: center;
    }

    .delete-button {
      width: 204px;
      padding: 4px 18px;

      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      border-radius: 8px;
      border: 1px solid var(--affine-border-color);

      font-family: var(--affine-font-family);
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;

      background-color: transparent;
      cursor: pointer;
    }

    .delete-button > .icon > svg {
      color: var(--affine-icon-color);
      width: 16px;
      height: 16px;
      display: block;
    }

    .placeholder-reason {
      margin: 72px auto 0;
      padding: 10px;

      text-align: center;
      font-size: 12px;
      font-family: var(--affine-font-family);
      line-height: 20px;

      color: var(--affine-warning-color);
      background-color: var(--affine-background-error-color);
    }

    .ref-content {
      position: relative;
      padding: 20px;
      background-color: var(--affine-background-primary-color);
      background: radial-gradient(
        var(--affine-edgeless-grid-color) 1px,
        var(--affine-background-primary-color) 1px
      );
    }

    .ref-viewport {
      max-width: 100%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      pointer-events: none;
      user-select: none;
    }

    .ref-viewport.frame {
      border-radius: 2px;
      border: 1px solid var(--affine-black-30);
    }

    .surface-ref-mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .surface-ref-mask:hover {
      background-color: rgba(211, 211, 211, 0.1);
    }

    .surface-ref-mask:hover .ref-label {
      display: block;
    }

    .ref-label {
      display: none;
      user-select: none;
    }

    .ref-label {
      position: absolute;
      left: 0;
      bottom: 0;

      width: 100%;
      padding: 8px 16px;
      border: 1px solid var(--affine-border-color);
      gap: 14px;

      background: var(--affine-background-primary-color);

      font-size: 12px;

      user-select: none;
    }

    .ref-label .title {
      display: inline-block;
      font-weight: 600;
      font-family: var(--affine-font-family);
      line-height: 20px;

      color: var(--affine-text-secondary-color);
    }

    .ref-label .title > svg {
      color: var(--affine-icon-secondary);
      display: inline-block;
      vertical-align: baseline;
      width: 20px;
      height: 20px;
      vertical-align: bottom;
    }

    .ref-label .suffix {
      display: inline-block;
      font-weight: 400;
      color: var(--affine-text-disable-color);
      line-height: 20px;
    }
  `;
            }
            _deleteThis() {
                this.doc.deleteBlock(this.model);
            }
            _focusBlock() {
                this.selection.update(()=>{
                    return [
                        this.selection.create('block', {
                            blockId: this.blockId
                        })
                    ];
                });
            }
            _initHotkey() {
                const selection = this.host.selection;
                const addParagraph = ()=>{
                    if (!this.doc.getParent(this.model)) return;
                    const [paragraphId] = this.doc.addSiblingBlocks(this.model, [
                        {
                            flavour: 'affine:paragraph'
                        }
                    ]);
                    const model = this.doc.getBlockById(paragraphId);
                    assertExists(model, `Failed to add paragraph block.`);
                    requestConnectedFrame(()=>{
                        selection.update((selList)=>{
                            return selList.filter((sel)=>!sel.is('block')).concat(selection.create('text', {
                                from: {
                                    blockId: model.id,
                                    index: 0,
                                    length: 0
                                },
                                to: null
                            }));
                        });
                    }, this);
                };
                this.bindHotKey({
                    Enter: ()=>{
                        if (!this._focused) return;
                        addParagraph();
                        return true;
                    }
                });
            }
            _initReferencedModel() {
                const surfaceModel = this.doc.getBlocksByFlavour('affine:surface')[0]?.model ?? null;
                this._surfaceModel = surfaceModel;
                const findReferencedModel = ()=>{
                    if (!this.model.reference) return [
                        null,
                        this.doc.id
                    ];
                    if (this.doc.getBlock(this.model.reference)) {
                        return [
                            this.doc.getBlock(this.model.reference).model,
                            this.doc.id
                        ];
                    }
                    if (this._surfaceModel?.getElementById(this.model.reference)) {
                        return [
                            this._surfaceModel.getElementById(this.model.reference),
                            this.doc.id
                        ];
                    }
                    const doc = [
                        ...this.std.collection.docs.values()
                    ].map((doc)=>doc.getDoc()).find((doc)=>doc.getBlock(this.model.reference) || doc.getBlocksByFlavour('affine:surface')[0].model.getElementById(this.model.reference));
                    if (doc) {
                        this._surfaceModel = doc.getBlocksByFlavour('affine:surface')[0].model;
                    }
                    if (doc && doc.getBlock(this.model.reference)) {
                        return [
                            doc.getBlock(this.model.reference).model,
                            doc.id
                        ];
                    }
                    if (doc && doc.getBlocksByFlavour('affine:surface')[0]) {
                        return [
                            doc.getBlocksByFlavour('affine:surface')[0].model.getElementById(this.model.reference),
                            doc.id
                        ];
                    }
                    return [
                        null,
                        this.doc.id
                    ];
                };
                const init = ()=>{
                    const [referencedModel, docId] = findReferencedModel();
                    this._referencedModel = referencedModel && referencedModel.xywh ? referencedModel : null;
                    this._previewDoc = this.doc.collection.getDoc(docId, {
                        readonly: true
                    });
                    this._referenceXYWH = this._referencedModel?.xywh ?? null;
                };
                init();
                this._disposables.add(this.model.propsUpdated.on((payload)=>{
                    if (payload.key === 'reference' && this.model.reference !== this._referencedModel?.id) {
                        init();
                    }
                }));
                if (surfaceModel && this._referencedModel instanceof SurfaceElementModel) {
                    this._disposables.add(surfaceModel.elementRemoved.on(({ id })=>{
                        if (this.model.reference === id) {
                            init();
                        }
                    }));
                }
                if (this._referencedModel instanceof GfxBlockElementModel) {
                    this._disposables.add(this.doc.slots.blockUpdated.on(({ type, id })=>{
                        if (type === 'delete' && id === this.model.reference) {
                            init();
                        }
                    }));
                }
            }
            _initSelection() {
                const selection = this.host.selection;
                this._disposables.add(selection.slots.changed.on((selList)=>{
                    this._focused = selList.some((sel)=>sel.blockId === this.blockId && sel.is('block'));
                }));
            }
            _initSpec() {
                this._previewSpec.setup('affine:page', ({ viewConnected })=>{
                    viewConnected.once(({ component })=>{
                        const edgelessBlock = component;
                        edgelessBlock.editorViewportSelector = 'ref-viewport';
                        edgelessBlock.service.viewport.sizeUpdated.once(()=>{
                            this._refreshViewport();
                        });
                    });
                });
                this._previewSpec.setup('affine:frame', ({ viewConnected })=>{
                    viewConnected.once(({ component })=>{
                        const frameBlock = component;
                        frameBlock.showBorder = false;
                    });
                });
            }
            _refreshViewport() {
                if (!this._referenceXYWH) return;
                const previewEditorHost = this.previewEditor;
                if (!previewEditorHost) return;
                const edgelessService = previewEditorHost.spec.getService('affine:page');
                edgelessService.viewport.setViewportByBound(Bound.deserialize(this._referenceXYWH));
            }
            _renderMask(referencedModel, flavourOrType) {
                const title = 'title' in referencedModel ? referencedModel.title : '';
                return html`
      <div class="surface-ref-mask">
        <div class="ref-label">
          <div class="title">
            ${REF_LABEL_ICON[flavourOrType ?? 'DEFAULT'] ?? REF_LABEL_ICON.DEFAULT}
            <span>${title}</span>
          </div>
          <div class="suffix">from edgeless mode</div>
        </div>
      </div>
    `;
            }
            _renderRefContent(referencedModel) {
                const [, , w, h] = deserializeXYWH(referencedModel.xywh);
                const flavourOrType = 'flavour' in referencedModel ? referencedModel.flavour : referencedModel.type;
                const _previewSpec = this._previewSpec.value;
                return html`<div class="ref-content">
      <div
        class="ref-viewport ${flavourOrType === 'affine:frame' ? 'frame' : ''}"
        style=${styleMap({
                    width: `${w}px`,
                    aspectRatio: `${w} / ${h}`
                })}
      >
        ${this.host.renderSpecPortal(this._previewDoc, _previewSpec)}
      </div>
      ${this._renderMask(referencedModel, flavourOrType)}
    </div>`;
            }
            _renderRefPlaceholder(model) {
                return html`<div class="ref-placeholder">
      <div class="placeholder-image">${noContentPlaceholder}</div>
      <div class="placeholder-text">
        No Such
        ${NO_CONTENT_TITLE[model.refFlavour ?? 'DEFAULT'] ?? NO_CONTENT_TITLE.DEFAULT}
      </div>
      <div class="placeholder-action">
        <button class="delete-button" type="button" @click=${this._deleteThis}>
          <span class="icon">${MoreDeleteIcon}</span
          ><span>Delete this block</span>
        </button>
      </div>
      <div class="placeholder-reason">
        ${NO_CONTENT_REASON[model.refFlavour ?? 'DEFAULT'] ?? NO_CONTENT_REASON.DEFAULT}
      </div>
    </div>`;
            }
            get _shouldRender() {
                return this.isConnected && !this.parentBlock.closest('affine-surface-ref');
            }
            connectedCallback() {
                super.connectedCallback();
                bindContainerHotkey(this);
                this.contentEditable = 'false';
                if (!this._shouldRender) return;
                const service = this.service;
                assertExists(service, `Surface ref block must run with its service.`);
                this._initHotkey();
                this._initSpec();
                this._initReferencedModel();
                this._initSelection();
            }
            render() {
                if (!this._shouldRender) return nothing;
                const { _surfaceModel, _referencedModel, model } = this;
                const isEmpty = !_surfaceModel || !_referencedModel || !_referencedModel.xywh;
                const content = isEmpty ? this._renderRefPlaceholder(model) : this._renderRefContent(_referencedModel);
                return html`
      <div
        class="affine-surface-ref"
        @click=${this._focusBlock}
        style=${styleMap({
                    outline: this._focused ? '2px solid var(--affine-primary-color)' : undefined
                })}
      >
        ${content}
      </div>

      <block-caption-editor .block=${this}></block-caption-editor>

      ${Object.values(this.widgets)}
    `;
            }
            viewInEdgeless() {
                if (!this._referenceXYWH) return;
                const viewport = {
                    xywh: this._referenceXYWH,
                    padding: [
                        60,
                        20,
                        20,
                        20
                    ]
                };
                const pageService = this.std.spec.getService('affine:page');
                pageService.editPropsStore.setStorage('viewport', viewport);
                pageService.docModeService.setMode('edgeless');
            }
            willUpdate(_changedProperties) {
                if (_changedProperties.has('_referencedModel')) {
                    this._refreshViewport();
                }
            }
            get referenceModel() {
                return this._referencedModel;
            }
            #___private__focused_1;
            get _focused() {
                return this.#___private__focused_1;
            }
            set _focused(_v) {
                this.#___private__focused_1 = _v;
            }
            #___private__surfaceModel_2;
            get _surfaceModel() {
                return this.#___private__surfaceModel_2;
            }
            set _surfaceModel(_v) {
                this.#___private__surfaceModel_2 = _v;
            }
            #___private_captionElement_3;
            get captionElement() {
                return this.#___private_captionElement_3;
            }
            set captionElement(_v) {
                this.#___private_captionElement_3 = _v;
            }
            #___private_previewEditor_4;
            get previewEditor() {
                return this.#___private_previewEditor_4;
            }
            set previewEditor(_v) {
                this.#___private_previewEditor_4 = _v;
            }
            constructor(...args){
                super(...args);
                this._previewDoc = null;
                this._previewSpec = SpecProvider.getInstance().getSpec('edgeless:preview');
                this._referenceXYWH = null;
                this._referencedModel = null;
                this.#___private__focused_1 = (_initProto(this), _init__focused(this, false));
                this.#___private__surfaceModel_2 = _init__surfaceModel(this, null);
                this.#___private_captionElement_3 = _init_captionElement(this);
                this.#___private_previewEditor_4 = _init_previewEditor(this);
            }
        }
    }
}();
export { _SurfaceRefBlockComponent as SurfaceRefBlockComponent };
