import { Entity, LiveData } from '@toeverything/infra';
import { map } from 'rxjs';
const DEFAULT_COLLAPSABLE_STATE = {
    favorites: false,
    organize: false,
    collections: true,
    tags: true,
    favoritesOld: true,
    migrationFavorites: true
};
export class ExplorerSection extends Entity {
    constructor(globalCache){
        super();
        this.globalCache = globalCache;
        this.name = this.props.name;
        this.key = `explorer.section.${this.name}`;
        this.defaultValue = DEFAULT_COLLAPSABLE_STATE[this.name];
        this.collapsed$ = LiveData.from(this.globalCache.watch(this.key).pipe(map((v)=>v ?? this.defaultValue)), this.defaultValue);
    }
    setCollapsed(collapsed) {
        this.globalCache.set(this.key, collapsed);
    }
}
