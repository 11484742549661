function escapeRegExp(input) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
export function isFuzzyMatch(name, query) {
    const pureName = name.trim().toLowerCase().split('').filter((char)=>char !== ' ').join('');
    const regex = new RegExp(query.split('').filter((char)=>char !== ' ').map((item)=>`${escapeRegExp(item)}.*`).join(''), 'i');
    return regex.test(pureName);
}
export function substringMatchScore(name, query) {
    if (query.length === 0) return 0;
    if (name.length === 0) return 0;
    if (query.length > name.length) return 0;
    query = query.toLowerCase();
    name = name.toLocaleLowerCase();
    let score;
    if (name.includes(query)) {
        score = 1 + query.length / name.length;
    } else {
        let maxMatchLength = 0;
        for(let i = 0; i < query.length; i++){
            for(let j = 0; j < name.length; j++){
                let matchLength = 0;
                while(i + matchLength < query.length && j + matchLength < name.length && query[i + matchLength] === name[j + matchLength]){
                    matchLength++;
                }
                maxMatchLength = Math.max(maxMatchLength, matchLength);
            }
        }
        score = maxMatchLength / name.length;
    }
    return 0.5 * score;
}
