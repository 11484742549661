import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, MenuIcon, MenuItem, MenuSeparator, Scrollable } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { cloneElement, isValidElement, useCallback } from 'react';
import { getDefaultIconName, getSafeIconName, nameToIcon } from './icons-mapping';
import { IconsSelectorButton } from './icons-selector';
import * as styles from './styles.css';
const isElementOption = (e)=>{
    return isValidElement(e);
};
export const renderMenuItemOptions = (options)=>{
    return options.map((option, index)=>{
        if (option === '-') {
            return _jsx(MenuSeparator, {}, index);
        } else if (isElementOption(option)) {
            return cloneElement(option, {
                key: index
            });
        } else if (Array.isArray(option)) {
            return _jsx(Scrollable.Root, {
                className: styles.menuItemListScrollable,
                children: _jsxs(Scrollable.Viewport, {
                    className: styles.menuItemList,
                    children: [
                        renderMenuItemOptions(option),
                        _jsx(Scrollable.Scrollbar, {
                            className: styles.menuItemListScrollbar
                        })
                    ]
                })
            }, index);
        } else {
            const { text, icon, onClick, type, key, checked, selected } = option;
            return _jsx(MenuItem, {
                type: type,
                selected: selected,
                checked: checked,
                preFix: icon ? _jsx(MenuIcon, {
                    children: icon
                }) : null,
                onClick: onClick,
                children: text
            }, key ?? index);
        }
    });
};
export const EditPropertyNameMenuItem = ({ property, onNameBlur: onBlur, onNameChange, onIconChange })=>{
    const iconName = getSafeIconName(property.icon, property.type);
    const onKeyDown = useCallback((e)=>{
        if (e.key !== 'Escape') {
            e.stopPropagation();
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            onBlur(e.currentTarget.value);
        }
    }, [
        onBlur
    ]);
    const handleBlur = useCallback((e)=>{
        onBlur(e.currentTarget.value);
    }, [
        onBlur
    ]);
    const t = useI18n();
    return _jsxs("div", {
        className: styles.propertyRowNamePopupRow,
        children: [
            _jsx(IconsSelectorButton, {
                selected: iconName,
                onSelectedChange: onIconChange
            }),
            _jsx(Input, {
                defaultValue: property.name,
                onBlur: handleBlur,
                onChange: onNameChange,
                placeholder: t['unnamed'](),
                onKeyDown: onKeyDown
            })
        ]
    });
};
export const PropertyTypeMenuItem = ({ property })=>{
    const Icon = nameToIcon(getDefaultIconName(property.type), property.type);
    const t = useI18n();
    return _jsxs("div", {
        className: styles.propertyRowTypeItem,
        children: [
            t['com.affine.page-properties.create-property.menu.header'](),
            _jsxs("div", {
                className: styles.propertyTypeName,
                children: [
                    _jsx(Icon, {}),
                    t[`com.affine.page-properties.property.${property.type}`]()
                ]
            })
        ]
    });
};
