function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _dec1, _initClass, _EmbedBlockComponent, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _init__cycle, _init__deleted, _init__docUpdatedAt, _init__error, _init__isEmptySyncedDoc, _init__loading, _init__syncedDocMode, _init_depth, _init_syncedDocCard, _init_syncedDocEditorHost, _initProto;
import { Bound, assertExists } from '@blocksuite/global/utils';
import { BlockViewType, DocCollection } from '@blocksuite/store';
import { html, nothing } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { classMap } from 'lit/directives/class-map.js';
import { guard } from 'lit/directives/guard.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Peekable } from '../_common/components/peekable.js';
import { EMBED_CARD_HEIGHT, EMBED_CARD_WIDTH } from '../_common/consts.js';
import { EmbedBlockComponent } from '../_common/embed-block-helper/embed-block-element.js';
import { EmbedEdgelessIcon, EmbedPageIcon } from '../_common/icons/text.js';
import { REFERENCE_NODE } from '../_common/inline/presets/nodes/consts.js';
import { ThemeObserver } from '../_common/theme/theme-observer.js';
import { NoteDisplayMode } from '../_common/types.js';
import { isEmptyDoc } from '../_common/utils/render-linked-doc.js';
import { SpecProvider } from '../specs/utils/spec-provider.js';
import './components/embed-synced-doc-card.js';
import { blockStyles } from './styles.js';
let _EmbedSyncedDocBlockComponent;
_dec = customElement('affine-embed-synced-doc-block'), _dec1 = Peekable({
    enableOn: ({ doc })=>!doc.readonly
}), _dec2 = state(), _dec3 = state(), _dec4 = state(), _dec5 = state(), _dec6 = state(), _dec7 = state(), _dec8 = state(), _dec9 = state(), _dec10 = query(':scope > .affine-block-component > .embed-block-container > affine-embed-synced-doc-card'), _dec11 = query(':scope > .affine-block-component > .embed-block-container > .affine-embed-synced-doc-container > .affine-embed-synced-doc-editor > div > editor-host');
new class extends _identity {
    constructor(){
        super(_EmbedSyncedDocBlockComponent), _initClass();
    }
    static{
        class EmbedSyncedDocBlockComponent extends (_EmbedBlockComponent = EmbedBlockComponent) {
            static{
                ({ e: [_init__cycle, _init__deleted, _init__docUpdatedAt, _init__error, _init__isEmptySyncedDoc, _init__loading, _init__syncedDocMode, _init_depth, _init_syncedDocCard, _init_syncedDocEditorHost, _initProto], c: [_EmbedSyncedDocBlockComponent, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec2,
                        1,
                        "_cycle"
                    ],
                    [
                        _dec3,
                        1,
                        "_deleted"
                    ],
                    [
                        _dec4,
                        1,
                        "_docUpdatedAt"
                    ],
                    [
                        _dec5,
                        1,
                        "_error"
                    ],
                    [
                        _dec6,
                        1,
                        "_isEmptySyncedDoc"
                    ],
                    [
                        _dec7,
                        1,
                        "_loading"
                    ],
                    [
                        _dec8,
                        1,
                        "_syncedDocMode"
                    ],
                    [
                        _dec9,
                        1,
                        "depth"
                    ],
                    [
                        _dec10,
                        1,
                        "syncedDocCard"
                    ],
                    [
                        _dec11,
                        1,
                        "syncedDocEditorHost"
                    ]
                ], [
                    _dec,
                    _dec1
                ], _EmbedBlockComponent));
            }
            static{
                this.styles = blockStyles;
            }
            _checkCycle() {
                let editorHost = this.host;
                while(editorHost && !this._cycle){
                    this._cycle = !!editorHost && editorHost.doc.id === this.model.pageId;
                    editorHost = editorHost.parentElement?.closest('editor-host') ?? null;
                }
            }
            _handleClick(_event) {
                if (this.isInSurface) return;
                this._selectBlock();
            }
            _isClickAtBorder(event, element, tolerance = 8) {
                const { x, y } = event;
                const rect = element.getBoundingClientRect();
                if (!rect) {
                    return false;
                }
                return Math.abs(x - rect.left) < tolerance || Math.abs(x - rect.right) < tolerance || Math.abs(y - rect.top) < tolerance || Math.abs(y - rect.bottom) < tolerance;
            }
            async _load() {
                this._loading = true;
                this._error = false;
                this._deleted = false;
                this._cycle = false;
                const syncedDoc = this.syncedDoc;
                if (!syncedDoc) {
                    this._deleted = true;
                    this._loading = false;
                    return;
                }
                this._checkCycle();
                if (!syncedDoc.loaded) {
                    try {
                        syncedDoc.load();
                    } catch (e) {
                        console.error(e);
                        this._error = true;
                    }
                }
                if (!this._error && !syncedDoc.root) {
                    await new Promise((resolve)=>{
                        syncedDoc.slots.rootAdded.once(()=>resolve());
                    });
                }
                this._loading = false;
            }
            get _rootService() {
                return this.std.spec.getService('affine:page');
            }
            _selectBlock() {
                const selectionManager = this.host.selection;
                const blockSelection = selectionManager.create('block', {
                    blockId: this.blockId
                });
                selectionManager.setGroup('note', [
                    blockSelection
                ]);
            }
            _setDocUpdatedAt() {
                const meta = this.doc.collection.meta.getDocMeta(this.model.pageId);
                if (meta) {
                    const date = meta.updatedDate || meta.createDate;
                    this._docUpdatedAt = new Date(date);
                }
            }
            get isPageMode() {
                return this._syncedDocMode === 'page';
            }
            connectedCallback() {
                super.connectedCallback();
                this.style.display = 'block';
                this._load().catch((e)=>{
                    console.error(e);
                    this._error = true;
                });
                this.contentEditable = 'false';
                this.model.propsUpdated.on(({ key })=>{
                    if (key === 'pageId' || key === 'style') {
                        this._load().catch((e)=>{
                            console.error(e);
                            this._error = true;
                        });
                    }
                });
                if (this.isInSurface) {
                    this.disposables.add(this.model.propsUpdated.on(()=>{
                        this.requestUpdate();
                    }));
                }
                this._setDocUpdatedAt();
                this.disposables.add(this.doc.collection.meta.docMetaUpdated.on(()=>{
                    this._setDocUpdatedAt();
                }));
                this._syncedDocMode = this._rootService.docModeService.getMode(this.model.pageId);
                this._isEmptySyncedDoc = isEmptyDoc(this.syncedDoc, this._syncedDocMode);
                this.disposables.add(this._rootService.docModeService.onModeChange((mode)=>{
                    this._syncedDocMode = mode;
                    this._isEmptySyncedDoc = isEmptyDoc(this.syncedDoc, mode);
                }, this.model.pageId));
                this.syncedDoc && this.disposables.add(this.syncedDoc.slots.blockUpdated.on(()=>{
                    this._isEmptySyncedDoc = isEmptyDoc(this.syncedDoc, this._syncedDocMode);
                }));
            }
            firstUpdated() {
                this.disposables.addFromEvent(this, 'click', (e)=>{
                    e.stopPropagation();
                    if (this._isClickAtBorder(e, this)) {
                        e.preventDefault();
                        this._selectBlock();
                    }
                });
                const syncedDocRootService = this.syncedDocEditorHost?.std.spec.getService('affine:page');
                if (syncedDocRootService) {
                    this.disposables.add(syncedDocRootService.slots.docLinkClicked.on(({ docId })=>{
                        this._rootService.slots.docLinkClicked.emit({
                            docId
                        });
                    }));
                }
                this._initEdgelessFitEffect();
            }
            renderBlock() {
                delete this.dataset.nestedEditor;
                const { style, xywh } = this.model;
                this._cardStyle = style;
                const bound = Bound.deserialize(xywh);
                this._width = this.isInSurface ? bound.w : EMBED_CARD_WIDTH[style];
                this._height = this.isInSurface ? bound.h : EMBED_CARD_HEIGHT[style];
                const syncedDoc = this.syncedDoc;
                const { isLoading, isError, isDeleted, isCycle } = this.blockState;
                const isInSurface = this.isInSurface;
                const isCardOnly = this.depth >= 1;
                if (isLoading || isError || isDeleted || isCardOnly || isCycle || !syncedDoc) {
                    let cardStyleMap = styleMap({
                        position: 'relative',
                        display: 'block',
                        width: '100%'
                    });
                    if (isInSurface) {
                        const bound = Bound.deserialize(this.model.xywh);
                        const scaleX = bound.w / EMBED_CARD_WIDTH[style];
                        const scaleY = bound.h / EMBED_CARD_HEIGHT[style];
                        cardStyleMap = styleMap({
                            display: 'block',
                            width: `${EMBED_CARD_WIDTH[style]}px`,
                            height: `${EMBED_CARD_HEIGHT[style]}px`,
                            transform: `scale(${scaleX}, ${scaleY})`,
                            transformOrigin: '0 0'
                        });
                    }
                    return this.renderEmbed(()=>html`
          <affine-embed-synced-doc-card
            style=${cardStyleMap}
            .block=${this}
          ></affine-embed-synced-doc-card>
        `);
                }
                return this._renderSyncedView();
            }
            updated(changedProperties) {
                super.updated(changedProperties);
                this.syncedDocCard?.requestUpdate();
            }
            get blockState() {
                return {
                    isLoading: this._loading,
                    isError: this._error,
                    isDeleted: this._deleted,
                    isCycle: this._cycle
                };
            }
            get docTitle() {
                return this.syncedDoc?.meta?.title.length ? this.syncedDoc.meta.title : 'Untitled';
            }
            get docUpdatedAt() {
                return this._docUpdatedAt;
            }
            get editorMode() {
                return this._syncedDocMode;
            }
            get syncedDoc() {
                return this._syncedDocMode === 'page' ? this.std.collection.getDoc(this.model.pageId, {
                    readonly: true,
                    query: this._pageFilter
                }) : this.std.collection.getDoc(this.model.pageId, {
                    readonly: true
                });
            }
            #___private__cycle_1;
            get _cycle() {
                return this.#___private__cycle_1;
            }
            set _cycle(_v) {
                this.#___private__cycle_1 = _v;
            }
            #___private__deleted_2;
            get _deleted() {
                return this.#___private__deleted_2;
            }
            set _deleted(_v) {
                this.#___private__deleted_2 = _v;
            }
            #___private__docUpdatedAt_3;
            get _docUpdatedAt() {
                return this.#___private__docUpdatedAt_3;
            }
            set _docUpdatedAt(_v) {
                this.#___private__docUpdatedAt_3 = _v;
            }
            #___private__error_4;
            get _error() {
                return this.#___private__error_4;
            }
            set _error(_v) {
                this.#___private__error_4 = _v;
            }
            #___private__isEmptySyncedDoc_5;
            get _isEmptySyncedDoc() {
                return this.#___private__isEmptySyncedDoc_5;
            }
            set _isEmptySyncedDoc(_v) {
                this.#___private__isEmptySyncedDoc_5 = _v;
            }
            #___private__loading_6;
            get _loading() {
                return this.#___private__loading_6;
            }
            set _loading(_v) {
                this.#___private__loading_6 = _v;
            }
            #___private__syncedDocMode_7;
            get _syncedDocMode() {
                return this.#___private__syncedDocMode_7;
            }
            set _syncedDocMode(_v) {
                this.#___private__syncedDocMode_7 = _v;
            }
            #___private_depth_8;
            get depth() {
                return this.#___private_depth_8;
            }
            set depth(_v) {
                this.#___private_depth_8 = _v;
            }
            #___private_syncedDocCard_9;
            get syncedDocCard() {
                return this.#___private_syncedDocCard_9;
            }
            set syncedDocCard(_v) {
                this.#___private_syncedDocCard_9 = _v;
            }
            #___private_syncedDocEditorHost_10;
            get syncedDocEditorHost() {
                return this.#___private_syncedDocEditorHost_10;
            }
            set syncedDocEditorHost(_v) {
                this.#___private_syncedDocEditorHost_10 = _v;
            }
            #___private_useCaptionEditor_11;
            get useCaptionEditor() {
                return this.#___private_useCaptionEditor_11;
            }
            set useCaptionEditor(_v) {
                this.#___private_useCaptionEditor_11 = _v;
            }
            constructor(...args){
                super(...args);
                this._buildPreviewSpec = (name)=>{
                    const nextDepth = this.depth + 1;
                    const previewSpecBuilder = SpecProvider.getInstance().getSpec(name);
                    const currentDisposables = this.disposables;
                    previewSpecBuilder.setup('affine:embed-synced-doc', (slots, disposableGroup)=>{
                        disposableGroup.add(slots.viewConnected.on(({ component })=>{
                            const nextComponent = component;
                            nextComponent.depth = nextDepth;
                            currentDisposables.add(()=>{
                                nextComponent.depth = 0;
                            });
                        }));
                        disposableGroup.add(slots.viewDisconnected.on(({ component })=>{
                            const nextComponent = component;
                            nextComponent.depth = 0;
                        }));
                    });
                    return previewSpecBuilder.value;
                };
                this._initEdgelessFitEffect = ()=>{
                    const fitToContent = ()=>{
                        const { _syncedDocMode } = this;
                        if (_syncedDocMode !== 'edgeless') return;
                        const service = this.syncedDocEditorHost?.std.spec.getService('affine:page');
                        if (!service) return;
                        service.viewport.onResize();
                        service.zoomToFit();
                    };
                    const observer = new ResizeObserver(fitToContent);
                    const block = this.embedBlock;
                    observer.observe(block);
                    this._disposables.add(()=>{
                        observer.disconnect();
                    });
                    this.syncedDocEditorHost?.updateComplete.then(()=>{
                        fitToContent();
                    }).catch(()=>{});
                };
                this._pageFilter = {
                    mode: 'loose',
                    match: [
                        {
                            flavour: 'affine:note',
                            props: {
                                displayMode: NoteDisplayMode.EdgelessOnly
                            },
                            viewType: BlockViewType.Hidden
                        }
                    ]
                };
                this._renderSyncedView = ()=>{
                    const syncedDoc = this.syncedDoc;
                    const isInSurface = this.isInSurface;
                    const editorMode = this._syncedDocMode;
                    assertExists(syncedDoc);
                    if (this.isPageMode && !this.isInSurface) {
                        this.style.width = 'calc(100% + 48px)';
                        this.style.marginLeft = '-24px';
                        this.style.marginRight = '-24px';
                    }
                    let containerStyleMap = styleMap({
                        position: 'relative',
                        width: '100%'
                    });
                    if (isInSurface) {
                        const scale = this.model.scale ?? 1;
                        const bound = Bound.deserialize((this.rootService?.getElementById(this.model.id) ?? this.model).xywh);
                        const width = bound.w / scale;
                        const height = bound.h / scale;
                        containerStyleMap = styleMap({
                            width: `${width}px`,
                            height: `${height}px`,
                            minHeight: `${height}px`,
                            transform: `scale(${scale})`,
                            transformOrigin: '0 0'
                        });
                    }
                    const theme = ThemeObserver.mode;
                    const isSelected = !!this.selected?.is('block');
                    const scale = isInSurface ? this.model.scale ?? 1 : undefined;
                    this.dataset.nestedEditor = '';
                    const renderEditor = ()=>{
                        return choose(editorMode, [
                            [
                                'page',
                                ()=>html`
            <div class="affine-page-viewport">
              ${this.host.renderSpecPortal(syncedDoc, this._buildPreviewSpec('page:preview'))}
            </div>
          `
                            ],
                            [
                                'edgeless',
                                ()=>html`
            <div class="affine-edgeless-viewport">
              ${this.host.renderSpecPortal(syncedDoc, this._buildPreviewSpec('edgeless:preview'))}
            </div>
          `
                            ]
                        ]);
                    };
                    const icon = this.isPageMode ? EmbedPageIcon : EmbedEdgelessIcon;
                    return this.renderEmbed(()=>html`
        <div
          class=${classMap({
                            'affine-embed-synced-doc-container': true,
                            [editorMode]: true,
                            [theme]: true,
                            selected: isSelected,
                            surface: isInSurface
                        })}
          @click=${this._handleClick}
          style=${containerStyleMap}
          ?data-scale=${scale}
        >
          <div class="affine-embed-synced-doc-editor">
            ${this.isPageMode && this._isEmptySyncedDoc ? html`
                  <div class="affine-embed-synced-doc-editor-empty">
                    <span>
                      This is a linked doc, you can add content here.
                    </span>
                  </div>
                ` : guard([
                            editorMode,
                            syncedDoc
                        ], renderEditor)}
          </div>
          ${isInSurface ? nothing : html`
                <div
                  class=${classMap({
                            'affine-embed-synced-doc-header-wrapper': true,
                            selected: isSelected
                        })}
                >
                  <div class="affine-embed-synced-doc-header">
                    ${icon}
                    <span class="affine-embed-synced-doc-title">
                      ${this.docTitle}
                    </span>
                  </div>
                </div>
              `}
          ${isInSurface ? html` <div class="affine-embed-synced-doc-editor-overlay"></div> ` : nothing}
        </div>
      `);
                };
                this.convertToCard = ()=>{
                    const { id, doc, pageId, caption, xywh } = this.model;
                    if (this.isInSurface) {
                        const style = 'vertical';
                        const bound = Bound.deserialize(xywh);
                        bound.w = EMBED_CARD_WIDTH[style];
                        bound.h = EMBED_CARD_HEIGHT[style];
                        const edgelessService = this.rootService;
                        if (!edgelessService) {
                            return;
                        }
                        const newId = edgelessService.addBlock('affine:embed-linked-doc', {
                            pageId,
                            xywh: bound.serialize(),
                            style,
                            caption
                        }, edgelessService.surface);
                        this.std.command.exec('reassociateConnectors', {
                            oldId: id,
                            newId
                        });
                        edgelessService.selection.set({
                            editing: false,
                            elements: [
                                newId
                            ]
                        });
                    } else {
                        const parent = doc.getParent(this.model);
                        assertExists(parent);
                        const index = parent.children.indexOf(this.model);
                        doc.addBlock('affine:embed-linked-doc', {
                            pageId,
                            caption
                        }, parent, index);
                        this.std.selection.setGroup('note', []);
                    }
                    doc.deleteBlock(this.model);
                };
                this.covertToInline = ()=>{
                    const { doc, pageId } = this.model;
                    const parent = doc.getParent(this.model);
                    assertExists(parent);
                    const index = parent.children.indexOf(this.model);
                    const yText = new DocCollection.Y.Text();
                    yText.insert(0, REFERENCE_NODE);
                    yText.format(0, REFERENCE_NODE.length, {
                        reference: {
                            type: 'LinkedPage',
                            pageId
                        }
                    });
                    const text = new doc.Text(yText);
                    doc.addBlock('affine:paragraph', {
                        text
                    }, parent, index);
                    doc.deleteBlock(this.model);
                };
                this.open = ()=>{
                    const syncedDocId = this.model.pageId;
                    if (syncedDocId === this.doc.id) return;
                    const rootComponent = this.std.view.viewFromPath('block', [
                        this.doc.root?.id ?? ''
                    ]);
                    assertExists(rootComponent);
                    rootComponent.slots.docLinkClicked.emit({
                        docId: syncedDocId
                    });
                };
                this.refreshData = ()=>{
                    this._load().catch((e)=>{
                        console.error(e);
                        this._error = true;
                    });
                };
                this.#___private__cycle_1 = (_initProto(this), _init__cycle(this, false));
                this.#___private__deleted_2 = _init__deleted(this, false);
                this.#___private__docUpdatedAt_3 = _init__docUpdatedAt(this, new Date());
                this.#___private__error_4 = _init__error(this, false);
                this.#___private__isEmptySyncedDoc_5 = _init__isEmptySyncedDoc(this, true);
                this.#___private__loading_6 = _init__loading(this, false);
                this.#___private__syncedDocMode_7 = _init__syncedDocMode(this, 'page');
                this.#___private_depth_8 = _init_depth(this, 0);
                this.#___private_syncedDocCard_9 = _init_syncedDocCard(this, null);
                this.#___private_syncedDocEditorHost_10 = _init_syncedDocEditorHost(this, null);
                this.#___private_useCaptionEditor_11 = false;
            }
        }
    }
}();
export { _EmbedSyncedDocBlockComponent as EmbedSyncedDocBlockComponent };
