import { searchSubscriptionsQuery } from '@affine/graphql';
import { Entity, LiveData } from '@toeverything/infra';
export class SubscribeFeed extends Entity {
    constructor(graphQLService){
        super();
        this.graphQLService = graphQLService;
        this.state$ = new LiveData(null);
        this.show$ = this.state$.map((s)=>!!s);
        this.show = (mode = 'commands', opts = {})=>{
            if (this.state$.value?.callback) {
                this.state$.value.callback(null);
            }
            if (mode === null) {
                this.state$.next(null);
            } else {
                this.state$.next({
                    mode,
                    query: opts.query ?? '',
                    callback: opts.callback
                });
            }
        };
        this.mode$ = this.state$.map((s)=>s?.mode);
        this.query$ = this.state$.map((s)=>s?.query || '');
        this.setQuery = (query)=>{
            if (!this.state$.value) return;
            this.state$.next({
                ...this.state$.value,
                query
            });
        };
    }
    hide() {
        return this.show(null);
    }
    toggle() {
        return this.show$.value ? this.hide() : this.show();
    }
    search(query) {
        const { promise, resolve } = Promise.withResolvers();
        this.show('docs', {
            callback: resolve,
            query
        });
        return promise;
    }
    setSearchCallbackResult(result) {
        if (this.state$.value?.callback) {
            this.state$.value.callback(result);
        }
    }
    async searchFeeds(query) {
        if (!query) {
            return [];
        }
        const res = await this.graphQLService.exec({
            query: searchSubscriptionsQuery,
            variables: {
                keyword: query
            }
        });
        return res.searchSubscriptions || [];
    }
}
