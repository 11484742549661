import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from 'react';
import { WorkspaceCard, WorkspaceCardSkeleton } from '../../components/card/workspace-card';
import { workspaceItemStyle } from './index.css';
const SortableWorkspaceItem = ({ item, openingId, useIsWorkspaceOwner, useWorkspaceName, currentWorkspaceId, onClick, onSettingClick, onEnableCloudClick })=>{
    const isOwner = useIsWorkspaceOwner?.(item);
    const name = useWorkspaceName?.(item);
    return _jsx("div", {
        className: workspaceItemStyle,
        "data-testid": "draggable-item",
        children: _jsx(WorkspaceCard, {
            currentWorkspaceId: currentWorkspaceId,
            meta: item,
            onClick: onClick,
            onSettingClick: onSettingClick,
            onEnableCloudClick: onEnableCloudClick,
            openingId: openingId,
            isOwner: isOwner,
            name: name
        })
    });
};
export const WorkspaceList = (props)=>{
    const workspaceList = props.items;
    return workspaceList.map((item)=>_jsx(Suspense, {
            fallback: _jsx(WorkspaceCardSkeleton, {}),
            children: _jsx(SortableWorkspaceItem, {
                ...props,
                item: item
            }, item.id)
        }, item.id));
};
