import { DebugLogger } from '@affine/debug';
import { Entity, IndexedDBIndexStorage, IndexedDBJobQueue, JobRunner, LiveData, WorkspaceDBService } from '@toeverything/infra';
import { map } from 'rxjs';
import { blockIndexSchema, docIndexSchema } from '../schema';
import { createWorker } from '../worker/out-worker';
export function isEmptyUpdate(binary) {
    return binary.byteLength === 0 || binary.byteLength === 2 && binary[0] === 0 && binary[1] === 0;
}
const logger = new DebugLogger('crawler');
export class DocsIndexer extends Entity {
    constructor(workspaceService){
        super();
        this.workspaceService = workspaceService;
        this.jobQueue = new IndexedDBJobQueue('jq:' + this.workspaceService.workspace.id);
        this.runner = new JobRunner(this.jobQueue, (jobs, signal)=>this.execJob(jobs, signal), ()=>new Promise((resolve)=>requestIdleCallback(()=>resolve(), {
                    timeout: 200
                })));
        this.indexStorage = new IndexedDBIndexStorage('idx:' + this.workspaceService.workspace.id);
        this.docIndex = this.indexStorage.getIndex('doc', docIndexSchema);
        this.blockIndex = this.indexStorage.getIndex('block', blockIndexSchema);
        this.workspaceEngine = this.workspaceService.workspace.engine;
        this.workspaceId = this.workspaceService.workspace.id;
        this.worker = null;
        this.status$ = LiveData.from(this.jobQueue.status$.pipe(map((status)=>({
                remaining: status.remaining
            }))), {});
    }
    setupListener() {
        this.workspaceEngine.doc.storage.eventBus.on((event)=>{
            if (WorkspaceDBService.isDBDocId(event.docId)) {
                return;
            }
            if (event.clientId === this.workspaceEngine.doc.clientId) {
                this.jobQueue.enqueue([
                    {
                        batchKey: event.docId,
                        payload: {
                            storageDocId: event.docId
                        }
                    }
                ]).catch((err)=>{
                    console.error('Error enqueueing job', err);
                });
            }
        });
    }
    async execJob(jobs, signal) {
        if (jobs.length === 0) {
            return;
        }
        const storageDocId = jobs[0].payload.storageDocId;
        const worker = await this.ensureWorker(signal);
        const startTime = performance.now();
        logger.debug('Start crawling job for storageDocId:', storageDocId);
        let workerOutput;
        if (storageDocId === this.workspaceId) {
            const rootDocBuffer = await this.workspaceEngine.doc.storage.loadDocFromLocal(this.workspaceId);
            if (!rootDocBuffer) {
                return;
            }
            const allIndexedDocs = (await this.docIndex.search({
                type: 'all'
            }, {
                pagination: {
                    limit: Number.MAX_SAFE_INTEGER,
                    skip: 0
                }
            })).nodes.map((n)=>n.id);
            workerOutput = await worker.run({
                type: 'rootDoc',
                allIndexedDocs,
                rootDocBuffer
            });
        } else {
            const rootDocBuffer = await this.workspaceEngine.doc.storage.loadDocFromLocal(this.workspaceId);
            const docBuffer = await this.workspaceEngine.doc.storage.loadDocFromLocal(storageDocId) ?? new Uint8Array(0);
            if (!rootDocBuffer) {
                return;
            }
            workerOutput = await worker.run({
                type: 'doc',
                docBuffer,
                storageDocId,
                rootDocBuffer
            });
        }
        if (workerOutput.deletedDoc || workerOutput.addedDoc) {
            if (workerOutput.deletedDoc) {
                const docIndexWriter = await this.docIndex.write();
                for (const docId of workerOutput.deletedDoc){
                    docIndexWriter.delete(docId);
                }
                await docIndexWriter.commit();
                const blockIndexWriter = await this.blockIndex.write();
                for (const docId of workerOutput.deletedDoc){
                    const oldBlocks = await blockIndexWriter.search({
                        type: 'match',
                        field: 'docId',
                        match: docId
                    }, {
                        pagination: {
                            limit: Number.MAX_SAFE_INTEGER
                        }
                    });
                    for (const block of oldBlocks.nodes){
                        blockIndexWriter.delete(block.id);
                    }
                }
                await blockIndexWriter.commit();
            }
            if (workerOutput.addedDoc) {
                const docIndexWriter = await this.docIndex.write();
                for (const { doc } of workerOutput.addedDoc){
                    docIndexWriter.put(doc);
                }
                await docIndexWriter.commit();
                const blockIndexWriter = await this.blockIndex.write();
                for (const { id, blocks } of workerOutput.addedDoc){
                    const oldBlocks = await blockIndexWriter.search({
                        type: 'match',
                        field: 'docId',
                        match: id
                    }, {
                        pagination: {
                            limit: Number.MAX_SAFE_INTEGER
                        }
                    });
                    for (const block of oldBlocks.nodes){
                        blockIndexWriter.delete(block.id);
                    }
                    for (const block of blocks){
                        blockIndexWriter.insert(block);
                    }
                }
                await blockIndexWriter.commit();
            }
        }
        if (workerOutput.reindexDoc) {
            await this.jobQueue.enqueue(workerOutput.reindexDoc.map(({ storageDocId })=>({
                    batchKey: storageDocId,
                    payload: {
                        storageDocId
                    }
                })));
        }
        const duration = performance.now() - startTime;
        logger.debug('Finish crawling job for storageDocId:' + storageDocId + ' in ' + duration + 'ms ');
    }
    startCrawling() {
        this.runner.start();
        this.jobQueue.enqueue([
            {
                batchKey: this.workspaceId,
                payload: {
                    storageDocId: this.workspaceId
                }
            }
        ]).catch((err)=>{
            console.error('Error enqueueing job', err);
        });
    }
    async ensureWorker(signal) {
        if (!this.worker) {
            this.worker = await createWorker(signal);
        }
        return this.worker;
    }
    dispose() {
        this.runner.stop();
    }
}
