import { PointLocation } from './point-location.js';
import { Vec } from './vec.js';
export const EPSILON = 1e-12;
export const MACHINE_EPSILON = 1.12e-16;
export function randomSeed() {
    return Math.floor(Math.random() * 2 ** 31);
}
export function getBoundsFromPoints(points, rotation = 0) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    if (points.length < 1) {
        minX = 0;
        minY = 0;
        maxX = 1;
        maxY = 1;
    } else {
        for (const [x, y] of points){
            minX = Math.min(x, minX);
            minY = Math.min(y, minY);
            maxX = Math.max(x, maxX);
            maxY = Math.max(y, maxY);
        }
    }
    if (rotation !== 0) {
        return getBoundsFromPoints(points.map((pt)=>Vec.rotWith(pt, [
                (minX + maxX) / 2,
                (minY + maxY) / 2
            ], rotation)));
    }
    return {
        minX,
        minY,
        maxX,
        maxY,
        width: Math.max(1, maxX - minX),
        height: Math.max(1, maxY - minY)
    };
}
export function getPointsFromBoundsWithRotation(bounds, getPoints = ({ x, y, w, h })=>[
        [
            x,
            y
        ],
        [
            x + w,
            y
        ],
        [
            x + w,
            y + h
        ],
        [
            x,
            y + h
        ]
    ]) {
    const { rotate } = bounds;
    let points = getPoints(bounds);
    if (rotate) {
        const { x, y, w, h } = bounds;
        const cx = x + w / 2;
        const cy = y + h / 2;
        const m = new DOMMatrix().translateSelf(cx, cy).rotateSelf(rotate).translateSelf(-cx, -cy);
        points = points.map((point)=>{
            const { x, y } = new DOMPoint(...point).matrixTransform(m);
            return [
                x,
                y
            ];
        });
    }
    return points;
}
export function getQuadBoundsWithRotation(bounds) {
    const { x, y, w, h, rotate } = bounds;
    const rect = new DOMRect(x, y, w, h);
    if (!rotate) return rect;
    return new DOMQuad(...getPointsFromBoundsWithRotation(bounds).map((point)=>new DOMPoint(...point))).getBounds();
}
export function getBoundsWithRotation(bounds) {
    const { x, y, width: w, height: h } = getQuadBoundsWithRotation(bounds);
    return {
        x,
        y,
        w,
        h
    };
}
export function lineIntersects(sp, ep, sp2, ep2, infinite = false) {
    const v1 = Vec.sub(ep, sp);
    const v2 = Vec.sub(ep2, sp2);
    const cross = Vec.cpr(v1, v2);
    if (almostEqual(cross, 0, MACHINE_EPSILON)) return null;
    const d = Vec.sub(sp, sp2);
    let u1 = Vec.cpr(v2, d) / cross;
    const u2 = Vec.cpr(v1, d) / cross, epsilon = EPSILON, uMin = -epsilon, uMax = 1 + epsilon;
    if (infinite || uMin < u1 && u1 < uMax && uMin < u2 && u2 < uMax) {
        if (!infinite) {
            u1 = clamp(u1, 0, 1);
        }
        return Vec.lrp(sp, ep, u1);
    }
    return null;
}
export function polygonNearestPoint(points, point) {
    const len = points.length;
    let rst;
    let dis = Infinity;
    for(let i = 0; i < len; i++){
        const p = points[i];
        const p2 = points[(i + 1) % len];
        const temp = Vec.nearestPointOnLineSegment(p, p2, point, true);
        const curDis = Vec.dist(temp, point);
        if (curDis < dis) {
            dis = curDis;
            rst = temp;
        }
    }
    return rst;
}
export function polygonPointDistance(points, point) {
    const nearest = polygonNearestPoint(points, point);
    return Vec.dist(nearest, point);
}
export function almostEqual(a, b, epsilon = 0.0001) {
    return Math.abs(a - b) < epsilon;
}
export function clamp(n, min, max) {
    return Math.max(min, max !== undefined ? Math.min(n, max) : n);
}
export function rotatePoints(points, center, rotate) {
    const rad = toRadian(rotate);
    return points.map((p)=>Vec.rotWith(p, center, rad));
}
export function rotatePoint(point, center, rotate) {
    const rad = toRadian(rotate);
    return Vec.add(center, Vec.rot(Vec.sub(point, center), rad));
}
export function toRadian(angle) {
    return angle * Math.PI / 180;
}
export function isPointOnLineSegment(point, line) {
    const [sp, ep] = line;
    const v1 = Vec.sub(point, sp);
    const v2 = Vec.sub(point, ep);
    return almostEqual(Vec.cpr(v1, v2), 0, 0.01) && Vec.dpr(v1, v2) <= 0;
}
export function polygonGetPointTangent(points, point) {
    const len = points.length;
    for(let i = 0; i < len; i++){
        const p = points[i];
        const p2 = points[(i + 1) % len];
        if (isPointOnLineSegment(point, [
            p,
            p2
        ])) {
            return Vec.normalize(Vec.sub(p2, p));
        }
    }
    return [
        0,
        0
    ];
}
export function linePolygonIntersects(sp, ep, points) {
    const result = [];
    const len = points.length;
    for(let i = 0; i < len; i++){
        const p = points[i];
        const p2 = points[(i + 1) % len];
        const rst = lineIntersects(sp, ep, p, p2);
        if (rst) {
            const v = new PointLocation(rst);
            v.tangent = Vec.normalize(Vec.sub(p2, p));
            result.push(v);
        }
    }
    return result.length ? result : null;
}
