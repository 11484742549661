function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WidgetComponent, _dec1, _dec2, _dec3, _dec4, _dec5, _init__dragging, _init__registeredEntries, _init_enableNoteSlicer, _init_selectedIds, _init_toolbarVisible, _initProto;
import { WidgetComponent } from '@blocksuite/block-std';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { join } from 'lit/directives/join.js';
import '../../../_common/components/toolbar/icon-button.js';
import '../../../_common/components/toolbar/menu-button.js';
import { renderToolbarSeparator } from '../../../_common/components/toolbar/separator.js';
import '../../../_common/components/toolbar/toolbar.js';
import { ConnectorCWithArrowIcon } from '../../../_common/icons/edgeless.js';
import { ThemeObserver } from '../../../_common/theme/theme-observer.js';
import { atLeastNMatches, groupBy, pickValues } from '../../../_common/utils/iterable.js';
import { ConnectorMode, GroupElementModel } from '../../../surface-block/index.js';
import { ShapeElementModel, clamp } from '../../../surface-block/index.js';
import { edgelessElementsBound } from '../../edgeless/utils/bound-utils.js';
import { isAttachmentBlock, isBookmarkBlock, isEdgelessTextBlock, isEmbeddedBlock, isFrameBlock, isImageBlock, isNoteBlock } from '../../edgeless/utils/query.js';
import { renderAddFrameButton } from './add-frame-button.js';
import { renderAddGroupButton } from './add-group-button.js';
import { renderAlignButton } from './align-button.js';
import { renderAttachmentButton } from './change-attachment-button.js';
import { renderChangeBrushButton } from './change-brush-button.js';
import { renderConnectorButton } from './change-connector-button.js';
import { renderChangeEdgelessTextButton } from './change-edgeless-text-button.js';
import { renderEmbedButton } from './change-embed-card-button.js';
import { renderFrameButton } from './change-frame-button.js';
import { renderGroupButton } from './change-group-button.js';
import { renderChangeImageButton } from './change-image-button.js';
import { renderMindmapButton } from './change-mindmap-button.js';
import { renderNoteButton } from './change-note-button.js';
import { renderChangeShapeButton } from './change-shape-button.js';
import { renderChangeTextButton } from './change-text-button.js';
import './more-button.js';
import { renderReleaseFromGroupButton } from './release-from-group-button.js';
export const EDGELESS_ELEMENT_TOOLBAR_WIDGET = 'edgeless-element-toolbar-widget';
let _EdgelessElementToolbarWidget;
_dec = customElement(EDGELESS_ELEMENT_TOOLBAR_WIDGET), _dec1 = state(), _dec2 = state(), _dec3 = property({
    attribute: false
}), _dec4 = state({
    hasChanged: (value, oldValue)=>{
        if (value.length !== oldValue?.length) {
            return true;
        }
        return value.some((id, index)=>id !== oldValue[index]);
    }
}), _dec5 = state();
new class extends _identity {
    constructor(){
        super(_EdgelessElementToolbarWidget), _initClass();
    }
    static{
        class EdgelessElementToolbarWidget extends (_WidgetComponent = WidgetComponent) {
            static{
                ({ e: [_init__dragging, _init__registeredEntries, _init_enableNoteSlicer, _init_selectedIds, _init_toolbarVisible, _initProto], c: [_EdgelessElementToolbarWidget, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_dragging"
                    ],
                    [
                        _dec2,
                        1,
                        "_registeredEntries"
                    ],
                    [
                        _dec3,
                        1,
                        "enableNoteSlicer"
                    ],
                    [
                        _dec4,
                        1,
                        "selectedIds"
                    ],
                    [
                        _dec5,
                        1,
                        "toolbarVisible"
                    ]
                ], [
                    _dec
                ], _WidgetComponent));
            }
            static{
                this.styles = css`
    :host {
      position: absolute;
      z-index: 3;
      transform: translateZ(0);
      will-change: transform;
      -webkit-user-select: none;
      user-select: none;
    }
  `;
            }
            _groupSelected() {
                const result = groupBy(this.selection.selectedElements, (model)=>{
                    if (isNoteBlock(model)) {
                        return 'note';
                    } else if (isFrameBlock(model)) {
                        return 'frame';
                    } else if (isImageBlock(model)) {
                        return 'image';
                    } else if (isAttachmentBlock(model)) {
                        return 'attachment';
                    } else if (isBookmarkBlock(model) || isEmbeddedBlock(model)) {
                        return 'embedCard';
                    } else if (isEdgelessTextBlock(model)) {
                        return 'edgelessText';
                    }
                    return model.type;
                });
                return result;
            }
            _recalculatePosition() {
                const { selection, viewport } = this.edgeless.service;
                const elements = selection.selectedElements;
                if (elements.length === 0) {
                    this.style.transform = 'translate3d(0, 0, 0)';
                    return;
                }
                const bound = edgelessElementsBound(elements);
                const { width, height } = viewport;
                const [x, y] = viewport.toViewCoord(bound.x, bound.y);
                const [right, bottom] = viewport.toViewCoord(bound.maxX, bound.maxY);
                let left, top;
                if (x >= width || right <= 0 || y >= height || bottom <= 0) {
                    left = x;
                    top = y;
                    this.style.transform = `translate3d(${left}px, ${top}px, 0)`;
                    return;
                }
                let offset = 37 + 12;
                let hasFrame = false;
                let hasGroup = false;
                if ((hasFrame = elements.some((ele)=>isFrameBlock(ele))) || (hasGroup = elements.some((ele)=>ele instanceof GroupElementModel))) {
                    offset += 16 + 4;
                    if (hasFrame) {
                        offset += 8;
                    }
                } else if (elements.length === 1 && elements[0] instanceof ShapeElementModel) {
                    offset += 22 + 4;
                }
                top = y - offset;
                if (top < 0) {
                    top = y + bound.h * viewport.zoom + offset - 37;
                    if (hasFrame || hasGroup) {
                        top -= 16 + 4;
                        if (hasFrame) {
                            top -= 8;
                        }
                    }
                }
                left = clamp(x, 10, width - 10);
                top = clamp(top, 10, height - 150);
                this.style.transform = `translate3d(${left}px, ${top}px, 0)`;
                this.selectedIds = selection.selectedIds;
            }
            _renderQuickConnectButton() {
                return [
                    html`
        <editor-icon-button
          aria-label="Draw connector"
          .tooltip=${'Draw connector'}
          .activeMode=${'background'}
          @click=${this._quickConnect}
        >
          ${ConnectorCWithArrowIcon}
        </editor-icon-button>
      `
                ];
            }
            firstUpdated() {
                const { _disposables, edgeless } = this;
                _disposables.add(edgeless.service.viewport.viewportUpdated.on(()=>{
                    this._recalculatePosition();
                }));
                _disposables.add(this.selection.slots.updated.on(()=>{
                    if (this.selection.selectedIds.length === 0 || this.selection.editing || this.selection.inoperable) {
                        this.toolbarVisible = false;
                    } else {
                        this.toolbarVisible = true;
                        this._recalculatePosition();
                    }
                }));
                pickValues(this.edgeless.service.surface, [
                    'elementAdded',
                    'elementUpdated'
                ]).forEach((slot)=>_disposables.add(slot.on(this._updateOnSelectedChange)));
                _disposables.add(this.doc.slots.blockUpdated.on(this._updateOnSelectedChange));
                _disposables.add(edgeless.dispatcher.add('dragStart', ()=>{
                    this._dragging = true;
                }));
                _disposables.add(edgeless.dispatcher.add('dragEnd', ()=>{
                    this._dragging = false;
                    this._recalculatePosition();
                }));
                _disposables.add(edgeless.slots.elementResizeStart.on(()=>{
                    this._dragging = true;
                }));
                _disposables.add(edgeless.slots.elementResizeEnd.on(()=>{
                    this._dragging = false;
                    this._recalculatePosition();
                }));
                _disposables.add(edgeless.slots.readonlyUpdated.on(()=>this.requestUpdate()));
                this.updateComplete.then(()=>{
                    _disposables.add(ThemeObserver.subscribe(()=>this.requestUpdate()));
                }).catch(console.error);
            }
            registerEntry(entry) {
                this._registeredEntries.push(entry);
            }
            render() {
                if (this.doc.readonly || this._dragging || !this.toolbarVisible) {
                    return nothing;
                }
                const groupedSelected = this._groupSelected();
                const { edgeless, selection } = this;
                const { shape, brush, connector, note, text, frame, group, embedCard, attachment, image, edgelessText, mindmap: mindmaps } = groupedSelected;
                const { selectedElements } = this.selection;
                const selectedAtLeastTwoTypes = atLeastNMatches(Object.values(groupedSelected), (e)=>!!e.length, 2);
                const quickConnectButton = selectedElements.length === 1 && !connector?.length ? this._renderQuickConnectButton() : undefined;
                const generalButtons = selectedElements.length !== connector?.length ? [
                    renderAddFrameButton(edgeless, selectedElements),
                    renderAddGroupButton(edgeless, selectedElements),
                    renderAlignButton(edgeless, selectedElements)
                ] : [];
                const buttons = selectedAtLeastTwoTypes ? generalButtons : [
                    ...generalButtons,
                    renderMindmapButton(edgeless, mindmaps),
                    renderMindmapButton(edgeless, shape),
                    renderChangeShapeButton(edgeless, shape),
                    renderChangeBrushButton(edgeless, brush),
                    renderConnectorButton(edgeless, connector),
                    renderNoteButton(edgeless, note, quickConnectButton),
                    renderChangeTextButton(edgeless, text),
                    renderChangeEdgelessTextButton(edgeless, edgelessText),
                    renderFrameButton(edgeless, frame),
                    renderGroupButton(edgeless, group),
                    renderEmbedButton(edgeless, embedCard, quickConnectButton),
                    renderAttachmentButton(edgeless, attachment),
                    renderChangeImageButton(edgeless, image)
                ];
                if (selectedElements.length === 1) {
                    if (selection.firstElement.group instanceof GroupElementModel) {
                        buttons.unshift(renderReleaseFromGroupButton(this.edgeless));
                    }
                    if (!connector?.length) {
                        buttons.push(quickConnectButton?.pop() ?? nothing);
                    }
                }
                this._registeredEntries.filter((entry)=>entry.when(selectedElements)).map((entry)=>entry.render(this.edgeless)).forEach((entry)=>entry && buttons.unshift(entry));
                buttons.push(html`
      <edgeless-more-button
        .elements=${selectedElements}
        .edgeless=${edgeless}
        .vertical=${true}
      ></edgeless-more-button>
    `);
                return html`
      <editor-toolbar>
        ${join(buttons.filter((b)=>b !== nothing), renderToolbarSeparator)}
      </editor-toolbar>
    `;
            }
            get edgeless() {
                return this.block;
            }
            get selection() {
                return this.edgeless.service.selection;
            }
            get slots() {
                return this.edgeless.slots;
            }
            get surface() {
                return this.edgeless.surface;
            }
            #___private__dragging_1;
            get _dragging() {
                return this.#___private__dragging_1;
            }
            set _dragging(_v) {
                this.#___private__dragging_1 = _v;
            }
            #___private__registeredEntries_2;
            get _registeredEntries() {
                return this.#___private__registeredEntries_2;
            }
            set _registeredEntries(_v) {
                this.#___private__registeredEntries_2 = _v;
            }
            #___private_enableNoteSlicer_3;
            get enableNoteSlicer() {
                return this.#___private_enableNoteSlicer_3;
            }
            set enableNoteSlicer(_v) {
                this.#___private_enableNoteSlicer_3 = _v;
            }
            #___private_selectedIds_4;
            get selectedIds() {
                return this.#___private_selectedIds_4;
            }
            set selectedIds(_v) {
                this.#___private_selectedIds_4 = _v;
            }
            #___private_toolbarVisible_5;
            get toolbarVisible() {
                return this.#___private_toolbarVisible_5;
            }
            set toolbarVisible(_v) {
                this.#___private_toolbarVisible_5 = _v;
            }
            constructor(...args){
                super(...args);
                this._quickConnect = ({ x, y })=>{
                    const element = this.selection.selectedElements[0];
                    const point = this.edgeless.service.viewport.toViewCoordFromClientCoord([
                        x,
                        y
                    ]);
                    this.edgeless.doc.captureSync();
                    this.edgeless.tools.setEdgelessTool({
                        type: 'connector',
                        mode: ConnectorMode.Curve
                    });
                    const ctc = this.edgeless.tools.controllers['connector'];
                    ctc.quickConnect(point, element);
                };
                this._updateOnSelectedChange = (element)=>{
                    const id = typeof element === 'string' ? element : element.id;
                    if (this.isConnected && !this._dragging && this.selection.has(id)) {
                        this._recalculatePosition();
                        this.requestUpdate();
                    }
                };
                this.#___private__dragging_1 = (_initProto(this), _init__dragging(this, false));
                this.#___private__registeredEntries_2 = _init__registeredEntries(this, []);
                this.#___private_enableNoteSlicer_3 = _init_enableNoteSlicer(this);
                this.#___private_selectedIds_4 = _init_selectedIds(this, []);
                this.#___private_toolbarVisible_5 = _init_toolbarVisible(this, false);
            }
        }
    }
}();
export { _EdgelessElementToolbarWidget as EdgelessElementToolbarWidget };
