import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { notify } from '@affine/component';
import { AuthInput, ModalHeader } from '@affine/component/auth-components';
import { Button } from '@affine/component/ui/button';
import { authAtom } from '@affine/core/atoms';
import { useAsyncCallback } from '@affine/core/hooks/affine-async-hooks';
import { track } from '@affine/core/mixpanel';
import { Trans, useI18n } from '@affine/i18n';
import { ArrowRightBigIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthService } from '../../../modules/cloud';
import { emailRegex } from '../../../utils/email-regex';
import { OAuth } from './oauth';
import * as style from './style.css';
import { Captcha, useCaptcha } from './use-captcha';
function validateEmail(email) {
    return emailRegex.test(email);
}
export const SignIn = ({ setAuthState, setAuthEmail, email, onSignedIn })=>{
    const t = useI18n();
    const authService = useService(AuthService);
    const [searchParams] = useSearchParams();
    const [isMutating, setIsMutating] = useState(false);
    const [verifyToken, challenge] = useCaptcha();
    const [isValidEmail, setIsValidEmail] = useState(true);
    const { openModal } = useAtomValue(authAtom);
    useEffect(()=>{
        const timeout = setInterval(()=>{
            authService.session.revalidate();
        }, 3000);
        return ()=>{
            clearInterval(timeout);
        };
    }, [
        authService
    ]);
    const loginStatus = useLiveData(authService.session.status$);
    if (loginStatus === 'authenticated' && openModal) {
        onSignedIn?.();
    }
    const onContinue = useAsyncCallback(async ()=>{
        if (!validateEmail(email)) {
            setIsValidEmail(false);
            return;
        }
        setIsValidEmail(true);
        setIsMutating(true);
        setAuthEmail(email);
        try {
            const { hasPassword, isExist: isUserExist } = await authService.checkUserByEmail(email);
            if (verifyToken) {
                if (isUserExist) {
                    if (hasPassword) {
                        setAuthState('signInWithPassword');
                    } else {
                        track.$.$.auth.signIn();
                        await authService.sendEmailMagicLink(email, verifyToken, challenge, searchParams.get('redirect_uri'));
                        setAuthState('afterSignInSendEmail');
                    }
                } else {
                    await authService.sendEmailMagicLink(email, verifyToken, challenge, searchParams.get('redirect_uri'));
                    track.$.$.auth.signUp();
                    setAuthState('afterSignUpSendEmail');
                }
            }
        } catch (err) {
            console.error(err);
            notify.error({
                title: 'Failed to send email. Please try again.'
            });
        }
        setIsMutating(false);
    }, [
        authService,
        challenge,
        email,
        searchParams,
        setAuthEmail,
        setAuthState,
        verifyToken
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(ModalHeader, {
                title: t['com.affine.auth.sign.in'](),
                subTitle: t['com.affine.brand.affineCloud']()
            }),
            _jsx(OAuth, {
                redirectUri: searchParams.get('redirect_uri')
            }),
            _jsxs("div", {
                className: style.authModalContent,
                children: [
                    _jsx(AuthInput, {
                        label: t['com.affine.settings.email'](),
                        placeholder: t['com.affine.auth.sign.email.placeholder'](),
                        value: email,
                        onChange: useCallback((value)=>{
                            setAuthEmail(value);
                        }, [
                            setAuthEmail
                        ]),
                        error: !isValidEmail,
                        errorHint: isValidEmail ? '' : t['com.affine.auth.sign.email.error'](),
                        onEnter: onContinue
                    }),
                    verifyToken ? null : _jsx(Captcha, {}),
                    verifyToken ? _jsx(Button, {
                        style: {
                            width: '100%'
                        },
                        size: "extraLarge",
                        "data-testid": "continue-login-button",
                        block: true,
                        loading: isMutating,
                        suffix: _jsx(ArrowRightBigIcon, {}),
                        suffixStyle: {
                            width: 20,
                            height: 20,
                            color: cssVar('blue')
                        },
                        onClick: onContinue,
                        children: t['com.affine.auth.sign.email.continue']()
                    }) : null,
                    _jsx("div", {
                        className: style.authMessage,
                        children: _jsxs(Trans, {
                            i18nKey: "com.affine.auth.sign.message",
                            children: [
                                'By clicking "Continue with Google/Email" above, you acknowledge that you agree to AFFiNE\'s ',
                                _jsx("a", {
                                    href: "https://wemem.ai/terms",
                                    target: "_blank",
                                    rel: "noreferrer",
                                    children: "Terms of Conditions"
                                }),
                                " and ",
                                _jsx("a", {
                                    href: "https://wemem.ai/privacy",
                                    target: "_blank",
                                    rel: "noreferrer",
                                    children: "Privacy Policy"
                                }),
                                "."
                            ]
                        })
                    })
                ]
            })
        ]
    });
};
