import { DebugLogger } from '@affine/debug';
import { setupGlobal } from '@affine/env/global';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { atomEffect } from 'jotai-effect';
import { getCurrentStore } from './root-store';
setupGlobal();
const logger = new DebugLogger('affine:settings');
export const windowFrameStyleOptions = [
    'frameless',
    'NativeTitleBar'
];
export const dateFormatOptions = [
    'MM/dd/YYYY',
    'dd/MM/YYYY',
    'YYYY-MM-dd',
    'YYYY.MM.dd',
    'YYYY/MM/dd',
    'dd-MMM-YYYY',
    'dd MMMM YYYY'
];
export const fontStyleOptions = [
    {
        key: 'Sans',
        value: 'var(--affine-font-sans-family)'
    },
    {
        key: 'Serif',
        value: 'var(--affine-font-serif-family)'
    },
    {
        key: 'Mono',
        value: 'var(--affine-font-mono-family)'
    }
];
const appSettingBaseAtom = atomWithStorage('affine-settings', {
    clientBorder: environment.isDesktop && !environment.isWindows,
    fullWidthLayout: false,
    windowFrameStyle: 'frameless',
    fontStyle: 'Sans',
    dateFormat: dateFormatOptions[0],
    startWeekOnMonday: false,
    enableBlurBackground: true,
    enableNoisyBackground: true,
    autoCheckUpdate: true,
    autoDownloadUpdate: true,
    enableTelemetry: true,
    enableMultiView: false,
    editorFlags: {}
});
export function setupEditorFlags(docCollection) {
    const store = getCurrentStore();
    const syncEditorFlags = ()=>{
        try {
            const editorFlags = getCurrentStore().get(appSettingBaseAtom).editorFlags;
            Object.entries(editorFlags ?? {}).forEach(([key, value])=>{
                docCollection.awarenessStore.setFlag(key, value);
            });
            docCollection.awarenessStore.setFlag('enable_synced_doc_block', true);
            docCollection.awarenessStore.setFlag('enable_edgeless_text', true);
            docCollection.awarenessStore.setFlag('enable_color_picker', true);
            docCollection.awarenessStore.setFlag('enable_ai_chat_block', true);
            docCollection.awarenessStore.setFlag('enable_ai_onboarding', true);
        } catch (err) {
            logger.error('syncEditorFlags', err);
        }
    };
    store.sub(appSettingBaseAtom, syncEditorFlags);
    syncEditorFlags();
}
const appSettingEffect = atomEffect((get)=>{
    const settings = get(appSettingBaseAtom);
    if (environment.isDesktop) {
        logger.debug('sync settings to electron', settings);
        window.apis?.updater.setConfig({
            autoCheckUpdate: settings.autoCheckUpdate,
            autoDownloadUpdate: settings.autoDownloadUpdate
        }).catch((err)=>{
            console.error(err);
        });
    }
});
export const appSettingAtom = atom((get)=>{
    get(appSettingEffect);
    return get(appSettingBaseAtom);
}, (_get, set, apply)=>{
    set(appSettingBaseAtom, (prev)=>{
        const next = typeof apply === 'function' ? apply(prev) : apply;
        return {
            ...prev,
            ...next
        };
    });
});
