var _computedKey;
import { CONSTRUCTOR_CONTEXT } from '../constructor-context';
_computedKey = Symbol.dispose;
export class Component {
    get eventBus() {
        return this.framework.eventBus;
    }
    constructor(){
        if (!CONSTRUCTOR_CONTEXT.current.provider) {
            throw new Error('Component must be created in the context of a provider');
        }
        this.framework = CONSTRUCTOR_CONTEXT.current.provider;
        this.props = CONSTRUCTOR_CONTEXT.current.props;
        CONSTRUCTOR_CONTEXT.current = {};
    }
    dispose() {}
    [_computedKey]() {
        this.dispose();
    }
}
