import { createYProxy } from '@blocksuite/store';
import { LiveData, Service } from '@toeverything/infra';
import { defaultsDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { PagePropertyType, PageSystemPropertyId } from './schema';
const AFFINE_PROPERTIES_ID = 'affine:workspace-properties';
export class WorkspacePropertiesAdapter extends Service {
    get workspace() {
        return this.workspaceService.workspace;
    }
    constructor(workspaceService){
        super();
        this.workspaceService = workspaceService;
        this.ensuredRoot = false;
        this.ensuredPages = {};
        this.transact = this.workspaceService.workspace.docCollection.doc.transact.bind(this.workspaceService.workspace.docCollection.doc);
        const rootDoc = workspaceService.workspace.docCollection.doc;
        this.properties = rootDoc.getMap(AFFINE_PROPERTIES_ID);
        this.proxy = createYProxy(this.properties);
        this.properties$ = LiveData.from(new Observable((observer)=>{
            const update = ()=>{
                requestAnimationFrame(()=>{
                    observer.next(new Proxy(this.proxy, {}));
                });
            };
            update();
            this.properties.observeDeep(update);
            return ()=>{
                this.properties.unobserveDeep(update);
            };
        }), this.proxy);
    }
    ensureRootProperties() {
        if (this.ensuredRoot) {
            return;
        }
        this.ensuredRoot = true;
        defaultsDeep(this.proxy, {
            schema: {
                pageProperties: {
                    custom: {},
                    system: {
                        journal: {
                            id: PageSystemPropertyId.Journal,
                            name: 'Journal',
                            source: 'system',
                            type: PagePropertyType.Date
                        },
                        tags: {
                            id: PageSystemPropertyId.Tags,
                            name: 'Tags',
                            source: 'system',
                            type: PagePropertyType.Tags,
                            options: this.workspaceService.workspace.docCollection.meta.properties.tags?.options ?? []
                        }
                    }
                }
            },
            pageProperties: {}
        });
    }
    ensurePageProperties(pageId) {
        this.ensureRootProperties();
        if (this.ensuredPages[pageId]) {
            return;
        }
        this.ensuredPages[pageId] = true;
        defaultsDeep(this.proxy.pageProperties, {
            [pageId]: {
                custom: {},
                system: {
                    [PageSystemPropertyId.Journal]: {
                        id: PageSystemPropertyId.Journal,
                        value: false
                    },
                    [PageSystemPropertyId.Tags]: {
                        id: PageSystemPropertyId.Tags,
                        value: []
                    }
                }
            }
        });
    }
    get schema() {
        return this.proxy.schema;
    }
    get favorites() {
        return this.proxy.favorites;
    }
    get pageProperties() {
        return this.proxy.pageProperties;
    }
    getPageProperties(pageId) {
        return this.pageProperties?.[pageId] ?? null;
    }
    getJournalPageDateString(id) {
        return this.pageProperties?.[id]?.system[PageSystemPropertyId.Journal]?.value;
    }
    setJournalPageDateString(id, date) {
        this.ensurePageProperties(id);
        const pageProperties = this.pageProperties?.[id];
        pageProperties.system[PageSystemPropertyId.Journal].value = date;
    }
    markFavoritesMigrated() {
        this.proxy.favoritesMigrated = true;
    }
}
export class MigrationFavoriteItemsAdapter extends Service {
    constructor(adapter){
        super();
        this.adapter = adapter;
        this.favorites$ = this.adapter.properties$.map(()=>this.getItems().filter((i)=>i.value));
        this.migrated$ = this.adapter.properties$.map((props)=>props.favoritesMigrated ?? false);
    }
    getItems() {
        return Object.entries(this.adapter.favorites ?? {}).filter(([k])=>k.includes(':')).map(([, v])=>v);
    }
    markFavoritesMigrated() {
        this.adapter.markFavoritesMigrated();
    }
}
export class CompatibleFavoriteItemsAdapter extends Service {
    constructor(favoriteService){
        super();
        this.favoriteService = favoriteService;
    }
    toggle(id, type) {
        this.favoriteService.favoriteList.toggle(type, id);
    }
    isFavorite$(id, type) {
        return this.favoriteService.favoriteList.isFavorite$(type, id);
    }
    isFavorite(id, type) {
        return this.favoriteService.favoriteList.isFavorite$(type, id).value;
    }
    get favorites$() {
        return this.favoriteService.favoriteList.list$.map((v)=>v.filter((i)=>i.type === 'doc' || i.type === 'collection').map((i)=>({
                    id: i.id,
                    order: '',
                    type: i.type,
                    value: true
                })));
    }
}
