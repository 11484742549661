import { matchFlavours } from '../../_common/utils/model.js';
import { correctNumberedListsOrderToPrev } from './utils.js';
export const unindentListCommand = (ctx, next)=>{
    const { blockId, inlineIndex, std } = ctx;
    const host = std.host;
    const doc = host.doc;
    const model = doc.getBlock(blockId).model;
    if (!matchFlavours(model, [
        'affine:list'
    ])) {
        console.error(`block ${blockId} is not a list block`);
        return;
    }
    const parent = doc.getParent(model);
    if (!parent) {
        console.error(`block ${blockId} has no parent`);
        return;
    }
    if (parent.role !== 'content') {
        return;
    }
    const grandParent = doc.getParent(parent);
    if (!grandParent) {
        console.error(`block ${blockId} has no grand parent`);
        return;
    }
    const modelIndex = parent.children.indexOf(model);
    if (modelIndex === -1) {
        console.error(`block ${blockId} is not a child of its parent`);
        return;
    }
    doc.captureSync();
    const nextSiblings = doc.getNexts(model);
    doc.moveBlocks(nextSiblings, model);
    const nextSibling = nextSiblings.at(0);
    if (nextSibling) correctNumberedListsOrderToPrev(doc, nextSibling);
    doc.moveBlocks([
        model
    ], grandParent, parent, false);
    correctNumberedListsOrderToPrev(doc, model);
    host.updateComplete.then(()=>{
        host.selection.setGroup('note', [
            host.selection.create('text', {
                from: {
                    blockId,
                    index: inlineIndex,
                    length: 0
                },
                to: null
            })
        ]);
    }).catch(console.error);
    next();
};
