import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import { DisposableGroup, Slot } from '@blocksuite/global/utils';
import { computed, signal } from '@lit-labs/preact-signals';
import { BlockSelection, CursorSelection, SurfaceSelection, TextSelection } from './variants/index.js';
export class SelectionManager {
    constructor(std){
        this.std = std;
        this._itemAdded = (event)=>{
            event.stackItem.meta.set('selection-state', this.value);
        };
        this._itemPopped = (event)=>{
            const selection = event.stackItem.meta.get('selection-state');
            if (selection) {
                this.set(selection);
            }
        };
        this._jsonToSelection = (json)=>{
            const ctor = this._selectionConstructors[json.type];
            if (!ctor) {
                throw new BlockSuiteError(ErrorCode.SelectionError, `Unknown selection type: ${json.type}`);
            }
            return ctor.fromJSON(json);
        };
        this._remoteSelections = signal(new Map());
        this._selectionConstructors = {};
        this._selections = signal([]);
        this.disposables = new DisposableGroup();
        this.slots = {
            changed: new Slot(),
            remoteChanged: new Slot()
        };
        this._setupDefaultSelections();
        this._store.awareness.on('change', (change)=>{
            const all = change.updated.concat(change.added).concat(change.removed);
            const localClientID = this._store.awareness.clientID;
            const exceptLocal = all.filter((id)=>id !== localClientID);
            const hasLocal = all.includes(localClientID);
            if (hasLocal) {
                const localSelection = this._store.getLocalSelection(this.std.doc.blockCollection).map((json)=>{
                    return this._jsonToSelection(json);
                });
                this._selections.value = localSelection;
            }
            if (exceptLocal.length > 0) {
                const map = new Map();
                this._store.getStates().forEach((state, id)=>{
                    if (id === this._store.awareness.clientID) return;
                    const selection = Object.entries(state.selectionV2).filter(([key])=>key === this.std.doc.id).flatMap(([_, selection])=>selection);
                    const selections = selection.map((json)=>{
                        try {
                            return this._jsonToSelection(json);
                        } catch (error) {
                            console.error('Parse remote selection failed:', id, json, error);
                            return null;
                        }
                    }).filter((sel)=>!!sel);
                    map.set(id, selections);
                });
                this._remoteSelections.value = map;
            }
        });
    }
    _setupDefaultSelections() {
        this.register([
            TextSelection,
            BlockSelection,
            SurfaceSelection,
            CursorSelection
        ]);
    }
    get _store() {
        return this.std.collection.awarenessStore;
    }
    clear(types) {
        if (types) {
            const values = this.value.filter((selection)=>!types.includes(selection.type));
            this.set(values);
        } else {
            this.set([]);
        }
    }
    create(type, ...args) {
        const ctor = this._selectionConstructors[type];
        if (!ctor) {
            throw new BlockSuiteError(ErrorCode.SelectionError, `Unknown selection type: ${type}`);
        }
        return new ctor(...args);
    }
    dispose() {
        Object.values(this.slots).forEach((slot)=>slot.dispose());
        this.disposables.dispose();
    }
    filter(type) {
        return this.filter$(type).value;
    }
    filter$(type) {
        return computed(()=>this.value.filter((sel)=>sel.is(type)));
    }
    find(type) {
        return this.find$(type).value;
    }
    find$(type) {
        return computed(()=>this.value.find((sel)=>sel.is(type)));
    }
    fromJSON(json) {
        const selections = json.map((json)=>{
            return this._jsonToSelection(json);
        });
        return this.set(selections);
    }
    getGroup(group) {
        return this.value.filter((s)=>s.group === group);
    }
    mount() {
        if (this.disposables.disposed) {
            this.disposables = new DisposableGroup();
        }
        this.std.doc.history.on('stack-item-added', this._itemAdded);
        this.std.doc.history.on('stack-item-popped', this._itemPopped);
        this.disposables.add(this._store.slots.update.on(({ id })=>{
            if (id === this._store.awareness.clientID) return;
            this.slots.remoteChanged.emit(this.remoteSelections);
        }));
    }
    register(ctor) {
        [
            ctor
        ].flat().forEach((ctor)=>{
            this._selectionConstructors[ctor.type] = ctor;
        });
        return this;
    }
    set(selections) {
        this._store.setLocalSelection(this.std.doc.blockCollection, selections.map((s)=>s.toJSON()));
        this.slots.changed.emit(selections);
    }
    setGroup(group, selections) {
        const current = this.value.filter((s)=>s.group !== group);
        this.set([
            ...current,
            ...selections
        ]);
    }
    unmount() {
        this.std.doc.history.off('stack-item-added', this._itemAdded);
        this.std.doc.history.off('stack-item-popped', this._itemPopped);
        this.slots.changed.dispose();
        this.disposables.dispose();
        this.clear();
    }
    update(fn) {
        const selections = fn(this.value);
        this.set(selections);
    }
    get remoteSelections() {
        return this._remoteSelections.value;
    }
    get value() {
        return this._selections.value;
    }
}
