function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _SignalWatcher, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _init__activeHeadingId, _init__activeIndicator, _init__root, _init__showViewer, _init_editor, _init_toggleOutlinePanel, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { NoteDisplayMode, scrollbarStyle } from '@blocksuite/blocks';
import { SignalWatcher, effect, signal } from '@lit-labs/preact-signals';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { TocIcon } from '../_common/icons.js';
import { getHeadingBlocksFromDoc } from './utils/query.js';
import { observeActiveHeading, scrollToBlockWithHighlight } from './utils/scroll.js';
export const AFFINE_OUTLINE_VIEWER = 'affine-outline-viewer';
let _OutlineViewer;
_dec = customElement(AFFINE_OUTLINE_VIEWER), _dec1 = state(), _dec2 = query('.outline-viewer-indicator.active'), _dec3 = query('.outline-viewer-root'), _dec4 = state(), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_OutlineViewer), _initClass();
    }
    static{
        class OutlineViewer extends (_SignalWatcher = SignalWatcher(WithDisposable(LitElement))) {
            static{
                ({ e: [_init__activeHeadingId, _init__activeIndicator, _init__root, _init__showViewer, _init_editor, _init_toggleOutlinePanel, _initProto], c: [_OutlineViewer, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_activeHeadingId"
                    ],
                    [
                        _dec2,
                        1,
                        "_activeIndicator"
                    ],
                    [
                        _dec3,
                        1,
                        "_root"
                    ],
                    [
                        _dec4,
                        1,
                        "_showViewer"
                    ],
                    [
                        _dec5,
                        1,
                        "editor"
                    ],
                    [
                        _dec6,
                        1,
                        "toggleOutlinePanel"
                    ]
                ], [
                    _dec
                ], _SignalWatcher));
            }
            static{
                this.animationDuration = 400;
            }
            static{
                this.styles = css`
    :host {
      display: flex;
    }

    .outline-viewer-root {
      --timing: cubic-bezier(0.2, 1.2, 0.41, 1);
      --duration: ${_OutlineViewer.animationDuration}ms;

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 16px;
      max-height: 100%;
      box-sizing: border-box;
      border: 1px solid transparent;
      overflow-y: hidden;

      transition: all var(--duration) var(--timing);
    }

    .outline-viewer-indicator {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 2px;
      border-radius: 1px;
      overflow: hidden;
      background: var(--affine-black-10, rgba(0, 0, 0, 0.1));
      transition:
        all var(--duration) var(--timing),
        background 0.8s var(--timing);
    }

    .outline-viewer-content {
      display: flex;
      transition: all var(--duration) var(--timing);
      width: 184px;
      opacity: 0;
    }

    .outline-viewer-indicator.header {
      background-color: transparent;
    }

    .outline-viewer-indicator.header {
      .outline-viewer-content {
        gap: 4px;
        padding: 6px 8px;

        span {
          flex: 1;
          overflow: hidden;
          color: var(--affine-text-secondary-color, #8e8d91);
          text-overflow: ellipsis;
          text-wrap: nowrap;

          font-family: var(--affine-font-family);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .outline-viewer-indicator.active {
      width: 24px;
      background: var(--affine-text-primary-color);
    }

    .outline-viewer-root:hover {
      gap: 0px;
      padding: 8px 4px 8px 8px;
      background: var(--affine-background-overlay-panel-color);
      border-radius: 8px;
      border-color: var(--affine-border-color);
      box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.14);
      overflow-y: auto;

      transition:
        all var(--duration) var(--timing),
        max-height 0.1s ease;

      .outline-viewer-indicator {
        background: transparent;
        width: 180px;
        height: 30px;
        transition:
          all var(--duration) var(--timing),
          background 0.2s var(--timing);
      }

      .outline-viewer-content {
        opacity: 1;
      }
    }

    ${scrollbarStyle('.outline-viewer-root:hover')}
  `;
            }
            async _scrollToBlock(blockId) {
                {
                    this._lockIndicatorsScroll = true;
                    if (this._unlockIndicatorsScrollTimeout) {
                        clearTimeout(this._unlockIndicatorsScrollTimeout);
                    }
                    this._unlockIndicatorsScrollTimeout = setTimeout(()=>{
                        this._lockIndicatorsScroll = false;
                    }, 1000);
                }
                this._clearHighlightMask = await scrollToBlockWithHighlight(this.editor, blockId);
            }
            _toggleOutlinePanel() {
                if (this.toggleOutlinePanel) {
                    this._showViewer = false;
                    this.toggleOutlinePanel();
                }
            }
            connectedCallback() {
                super.connectedCallback();
                this.disposables.add(observeActiveHeading(()=>this.editor, this._activeHeadingId$));
                this.disposables.add(effect(()=>{
                    this._activeHeadingId = this._activeHeadingId$.value;
                }));
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this._clearHighlightMask();
                if (this._unlockIndicatorsScrollTimeout) {
                    clearTimeout(this._unlockIndicatorsScrollTimeout);
                }
            }
            render() {
                if (!this.editor || !this.editor.doc.root || this.editor.mode === 'edgeless') return nothing;
                const headingBlocks = getHeadingBlocksFromDoc(this.editor.doc, [
                    NoteDisplayMode.DocAndEdgeless,
                    NoteDisplayMode.DocOnly
                ], true);
                if (headingBlocks.length === 0) return nothing;
                const items = [
                    ...this.editor.doc.meta?.title !== '' ? [
                        this.editor.doc.root
                    ] : [],
                    ...headingBlocks
                ];
                const delayScrollIndicators = ()=>{
                    setTimeout(this._scrollIndicator, OutlineViewer.animationDuration);
                };
                return html`<div
      class="outline-viewer-root"
      @mouseenter=${delayScrollIndicators}
      @mouseleave=${delayScrollIndicators}
    >
      <div class="outline-viewer-indicator header">
        <div class="outline-viewer-content">
          <span>Table of Contents</span>
          <edgeless-tool-icon-button
            .tooltip=${'Open in sidebar'}
            .tipPosition=${'top-end'}
            .activeMode=${'background'}
            @click=${this._toggleOutlinePanel}
          >
            ${TocIcon}
          </edgeless-tool-icon-button>
        </div>
      </div>
      ${repeat(items, (block)=>block.id, (block)=>{
                    const active = this._activeHeadingId === block.id;
                    return html`<div
            class=${classMap({
                        'outline-viewer-indicator': true,
                        active
                    })}
          >
            <div class="outline-viewer-content">
              <affine-outline-block-preview
                class=${classMap({
                        active
                    })}
                .block=${block}
                @click=${()=>{
                        this._scrollToBlock(block.id).catch(console.error);
                    }}
              >
              </affine-outline-block-preview>
            </div>
          </div>`;
                })}
    </div>`;
            }
            updated(changedProperties) {
                if (changedProperties.has('_activeHeadingId')) {
                    this._scrollIndicator();
                }
            }
            #___private__activeHeadingId_1;
            get _activeHeadingId() {
                return this.#___private__activeHeadingId_1;
            }
            set _activeHeadingId(_v) {
                this.#___private__activeHeadingId_1 = _v;
            }
            #___private__activeIndicator_2;
            get _activeIndicator() {
                return this.#___private__activeIndicator_2;
            }
            set _activeIndicator(_v) {
                this.#___private__activeIndicator_2 = _v;
            }
            #___private__root_3;
            get _root() {
                return this.#___private__root_3;
            }
            set _root(_v) {
                this.#___private__root_3 = _v;
            }
            #___private__showViewer_4;
            get _showViewer() {
                return this.#___private__showViewer_4;
            }
            set _showViewer(_v) {
                this.#___private__showViewer_4 = _v;
            }
            #___private_editor_5;
            get editor() {
                return this.#___private_editor_5;
            }
            set editor(_v) {
                this.#___private_editor_5 = _v;
            }
            #___private_toggleOutlinePanel_6;
            get toggleOutlinePanel() {
                return this.#___private_toggleOutlinePanel_6;
            }
            set toggleOutlinePanel(_v) {
                this.#___private_toggleOutlinePanel_6 = _v;
            }
            constructor(...args){
                super(...args);
                this._activeHeadingId$ = signal(null);
                this._clearHighlightMask = ()=>{};
                this._lockIndicatorsScroll = false;
                this._scrollIndicator = ()=>{
                    if (this._lockIndicatorsScroll) return;
                    if (!this._root) return;
                    if (!this._activeIndicator) {
                        this._root.scrollTop = 0;
                        return;
                    }
                    const { top, bottom } = this._activeIndicator.getBoundingClientRect();
                    const { top: rootTop, bottom: rootBottom } = this._root.getBoundingClientRect();
                    if (top < rootTop) {
                        this._root.scrollTop -= rootTop - top + 5;
                    } else if (bottom > rootBottom) {
                        this._root.scrollTop += bottom - rootBottom + 5;
                    }
                };
                this._unlockIndicatorsScrollTimeout = null;
                this.#___private__activeHeadingId_1 = (_initProto(this), _init__activeHeadingId(this, null));
                this.#___private__activeIndicator_2 = _init__activeIndicator(this, null);
                this.#___private__root_3 = _init__root(this, null);
                this.#___private__showViewer_4 = _init__showViewer(this, false);
                this.#___private_editor_5 = _init_editor(this);
                this.#___private_toggleOutlinePanel_6 = _init_toggleOutlinePanel(this, null);
            }
        }
    }
}();
export { _OutlineViewer as OutlineViewer };
