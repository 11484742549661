function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _SignalWatcher, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _init__dragging, _init__edgelessOnlyNotes, _init__pageVisibleNotes, _init__selected, _init_OutlinePanelContainer, _init_activeHeadingId, _init_doc, _init_domHost, _init_edgeless, _init_editor, _init_enableNotesSorting, _init_fitPadding, _init_insertIndex, _init_noticeVisible, _init_panelListElement, _init_renderEdgelessOnlyNotes, _init_setNoticeVisibility, _init_showPreviewIcon, _init_toggleNotesSorting, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { BlocksUtils, NoteDisplayMode } from '@blocksuite/blocks';
import { Bound, DisposableGroup } from '@blocksuite/global/utils';
import { SignalWatcher, effect } from '@lit-labs/preact-signals';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import '../card/outline-card.js';
import { startDragging } from '../utils/drag.js';
import { getHeadingBlocksFromDoc, getNotesFromDoc, isHeadingBlock } from '../utils/query.js';
import { scrollToBlock, scrollToBlockWithHighlight } from '../utils/scroll.js';
import './outline-notice.js';
const styles = css`
  .outline-panel-body-container {
    position: relative;
    display: flex;
    align-items: start;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 8px;
  }

  .panel-list {
    position: relative;
    width: 100%;
  }

  .panel-list .hidden-title {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: var(--affine-text-secondary-color);
    padding-left: 8px;
    height: 40px;
    box-sizing: border-box;
    padding: 6px 8px;
    margin-top: 8px;
  }

  .insert-indicator {
    height: 2px;
    border-radius: 1px;
    background-color: var(--affine-brand-color);
    border-radius: 1px;
    position: absolute;
    contain: layout size;
    width: 100%;
  }

  .no-note-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .note-placeholder {
    margin-top: 240px;
    align-self: center;
    width: 190px;
    height: 48px;
    color: var(--affine-text-secondary-color, #8e8d91);
    text-align: center;
    /* light/base */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;
export const AFFINE_OUTLINE_PANEL_BODY = 'affine-outline-panel-body';
let _OutlinePanelBody;
_dec = customElement(AFFINE_OUTLINE_PANEL_BODY), _dec1 = state(), _dec2 = state(), _dec3 = state(), _dec4 = state(), _dec5 = query('.outline-panel-body-container'), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    attribute: false
}), _dec11 = property({
    attribute: false
}), _dec12 = property({
    attribute: false
}), _dec13 = property({
    attribute: false
}), _dec14 = property({
    attribute: false
}), _dec15 = query('.panel-list'), _dec16 = property({
    attribute: false
}), _dec17 = property({
    attribute: false
}), _dec18 = property({
    attribute: false
}), _dec19 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_OutlinePanelBody), _initClass();
    }
    static{
        class OutlinePanelBody extends (_SignalWatcher = SignalWatcher(WithDisposable(LitElement))) {
            static{
                ({ e: [_init__dragging, _init__edgelessOnlyNotes, _init__pageVisibleNotes, _init__selected, _init_OutlinePanelContainer, _init_activeHeadingId, _init_doc, _init_domHost, _init_edgeless, _init_editor, _init_enableNotesSorting, _init_fitPadding, _init_insertIndex, _init_noticeVisible, _init_panelListElement, _init_renderEdgelessOnlyNotes, _init_setNoticeVisibility, _init_showPreviewIcon, _init_toggleNotesSorting, _initProto], c: [_OutlinePanelBody, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_dragging"
                    ],
                    [
                        _dec2,
                        1,
                        "_edgelessOnlyNotes"
                    ],
                    [
                        _dec3,
                        1,
                        "_pageVisibleNotes"
                    ],
                    [
                        _dec4,
                        1,
                        "_selected"
                    ],
                    [
                        _dec5,
                        1,
                        "OutlinePanelContainer"
                    ],
                    [
                        _dec6,
                        1,
                        "activeHeadingId"
                    ],
                    [
                        _dec7,
                        1,
                        "doc"
                    ],
                    [
                        _dec8,
                        1,
                        "domHost"
                    ],
                    [
                        _dec9,
                        1,
                        "edgeless"
                    ],
                    [
                        _dec10,
                        1,
                        "editor"
                    ],
                    [
                        _dec11,
                        1,
                        "enableNotesSorting"
                    ],
                    [
                        _dec12,
                        1,
                        "fitPadding"
                    ],
                    [
                        _dec13,
                        1,
                        "insertIndex"
                    ],
                    [
                        _dec14,
                        1,
                        "noticeVisible"
                    ],
                    [
                        _dec15,
                        1,
                        "panelListElement"
                    ],
                    [
                        _dec16,
                        1,
                        "renderEdgelessOnlyNotes"
                    ],
                    [
                        _dec17,
                        1,
                        "setNoticeVisibility"
                    ],
                    [
                        _dec18,
                        1,
                        "showPreviewIcon"
                    ],
                    [
                        _dec19,
                        1,
                        "toggleNotesSorting"
                    ]
                ], [
                    _dec
                ], _SignalWatcher));
            }
            static{
                this.styles = styles;
            }
            _EmptyPanel() {
                return html`<div class="no-note-container">
      <div class="note-placeholder">
        Use headings to create a table of contents.
      </div>
    </div>`;
            }
            _PanelList(withEdgelessOnlyNotes) {
                const selectedNotesSet = new Set(this._selected);
                return html`<div class="panel-list">
      ${this.insertIndex !== undefined ? html`<div
            class="insert-indicator"
            style=${`transform: translateY(${this._indicatorTranslateY}px)`}
          ></div>` : nothing}
      ${this._renderDocTitle()}
      ${this._pageVisibleNotes.length ? repeat(this._pageVisibleNotes, (note)=>note.note.id, (note, idx)=>html`
              <affine-outline-note-card
                data-note-id=${note.note.id}
                .note=${note.note}
                .number=${idx + 1}
                .index=${note.index}
                .doc=${this.doc}
                .editorMode=${this.editor.mode}
                .activeHeadingId=${this.activeHeadingId.value}
                .status=${selectedNotesSet.has(note.note.id) ? this._dragging ? 'placeholder' : 'selected' : undefined}
                .showPreviewIcon=${this.showPreviewIcon}
                .enableNotesSorting=${this.enableNotesSorting}
                @select=${this._selectNote}
                @drag=${this._drag}
                @fitview=${this._fitToElement}
                @clickblock=${this._scrollToBlock}
                @displaymodechange=${this._handleDisplayModeChange}
              ></affine-outline-note-card>
            `) : html`${nothing}`}
      ${withEdgelessOnlyNotes ? html`<div class="hidden-title">Hidden Contents</div>
            ${repeat(this._edgelessOnlyNotes, (note)=>note.note.id, (note, idx)=>html`<affine-outline-note-card
                  data-note-id=${note.note.id}
                  .note=${note.note}
                  .number=${idx + 1}
                  .index=${note.index}
                  .doc=${this.doc}
                  .activeHeadingId=${this.activeHeadingId.value}
                  .invisible=${true}
                  .showPreviewIcon=${this.showPreviewIcon}
                  .enableNotesSorting=${this.enableNotesSorting}
                  @fitview=${this._fitToElement}
                  @displaymodechange=${this._handleDisplayModeChange}
                ></affine-outline-note-card>`)} ` : nothing}
    </div>`;
            }
            _clearDocDisposables() {
                this._docDisposables?.dispose();
                this._docDisposables = null;
            }
            _clickHandler(e) {
                e.stopPropagation();
                if (e.target.closest('outline-note-card') || this._selected.length === 0) {
                    return;
                }
                this._selected = [];
                this.edgeless?.service.selection.set({
                    elements: this._selected,
                    editing: false
                });
            }
            _deSelectNoteInEdgelessMode(note) {
                if (!this._isEdgelessMode() || !this.edgeless) return;
                const { selection } = this.edgeless.service;
                if (!selection.has(note.id)) return;
                const selectedIds = selection.selectedIds.filter((id)=>id !== note.id);
                selection.set({
                    elements: selectedIds,
                    editing: false
                });
            }
            _doubleClickHandler(e) {
                e.stopPropagation();
                if (e.target.closest('outline-note-card') || !this.enableNotesSorting) {
                    return;
                }
                this.toggleNotesSorting();
            }
            _drag() {
                if (!this._selected.length || !this._pageVisibleNotes.length || !this.doc.root) return;
                this._dragging = true;
                const children = this.doc.root.children.slice();
                const notes = this._pageVisibleNotes;
                const notesMap = this._pageVisibleNotes.reduce((map, note, index)=>{
                    map.set(note.note.id, {
                        ...note,
                        number: index + 1
                    });
                    return map;
                }, new Map());
                const selected = this._selected.slice();
                startDragging({
                    container: this,
                    document: this.ownerDocument,
                    host: this.domHost ?? this.ownerDocument,
                    doc: this.doc,
                    outlineListContainer: this.panelListElement,
                    onDragEnd: (insertIdx)=>{
                        this._dragging = false;
                        this.insertIndex = undefined;
                        if (insertIdx === undefined) return;
                        this._moveNotes(insertIdx, selected, notesMap, notes, children);
                    },
                    onDragMove: (idx, indicatorTranslateY)=>{
                        this.insertIndex = idx;
                        this._indicatorTranslateY = indicatorTranslateY ?? 0;
                    }
                });
            }
            _fitToElement(e) {
                const edgeless = this.edgeless;
                if (!edgeless) return;
                const { block } = e.detail;
                const bound = Bound.deserialize(block.xywh);
                edgeless.service.viewport.setViewportByBound(bound, this.viewportPadding, true);
            }
            _handleDisplayModeChange(e) {
                const { note, newMode } = e.detail;
                const { displayMode: currentMode } = note;
                if (newMode === currentMode) {
                    return;
                }
                this.doc.updateBlock(note, {
                    displayMode: newMode
                });
                const noteParent = this.doc.getParent(note);
                if (noteParent === null) {
                    console.error(`Failed to get parent of note(id:${note.id})`);
                    return;
                }
                const noteParentChildNotes = noteParent.children.filter((block)=>BlocksUtils.matchFlavours(block, [
                        'affine:note'
                    ]));
                const noteParentLastNote = noteParentChildNotes[noteParentChildNotes.length - 1];
                if (currentMode === NoteDisplayMode.EdgelessOnly && note !== noteParentLastNote) {
                    this.doc.moveBlocks([
                        note
                    ], noteParent, noteParentLastNote, false);
                }
                if (newMode === NoteDisplayMode.DocOnly) {
                    this._deSelectNoteInEdgelessMode(note);
                }
            }
            _isEdgelessMode() {
                return this.editor.mode === 'edgeless';
            }
            _moveNotes(index, selected, notesMap, notes, children) {
                if (!this._isEdgelessMode() || !children.length || !this.doc.root) return;
                const blocks = selected.map((id)=>notesMap.get(id).note);
                const draggingBlocks = new Set(blocks);
                const targetIndex = index === notes.length ? notes[index - 1].index + 1 : notes[index].index;
                const leftPart = children.slice(0, targetIndex).filter((block)=>!draggingBlocks.has(block));
                const rightPart = children.slice(targetIndex).filter((block)=>!draggingBlocks.has(block));
                const newChildren = [
                    ...leftPart,
                    ...blocks,
                    ...rightPart
                ];
                this._changedFlag = true;
                this.doc.updateBlock(this.doc.root, {
                    children: newChildren
                });
            }
            _renderDocTitle() {
                if (!this.doc.root) return nothing;
                const hasNotEmptyHeadings = getHeadingBlocksFromDoc(this.doc, [
                    NoteDisplayMode.DocOnly,
                    NoteDisplayMode.DocAndEdgeless
                ], true).length > 0;
                if (!hasNotEmptyHeadings) return nothing;
                return html`<affine-outline-block-preview
      class=${classMap({
                    active: this.doc.root.id === this.activeHeadingId.value
                })}
      .block=${this.doc.root}
      .className=${this.doc.root?.id === this.activeHeadingId.value ? 'active' : ''}
      .cardNumber=${1}
      .enableNotesSorting=${false}
      .showPreviewIcon=${this.showPreviewIcon}
      @click=${()=>{
                    if (!this.doc.root) return;
                    scrollToBlock(this.editor, this.doc.root.id);
                    this.activeHeadingId.value = this.doc.root.id;
                }}
    ></affine-outline-block-preview>`;
            }
            async _scrollToBlock(e) {
                this._clearHighlightMask = await scrollToBlockWithHighlight(this.editor, e.detail.blockId);
                this.activeHeadingId.value = e.detail.blockId;
            }
            _selectNote(e) {
                if (!this._isEdgelessMode()) return;
                const { selected, id, multiselect } = e.detail;
                if (!selected) {
                    this._selected = this._selected.filter((noteId)=>noteId !== id);
                } else if (multiselect) {
                    this._selected = [
                        ...this._selected,
                        id
                    ];
                } else {
                    this._selected = [
                        id
                    ];
                }
                const selectedIds = this._pageVisibleNotes.reduce((ids, item)=>{
                    const note = item.note;
                    if (this._selected.includes(note.id) && (!note.displayMode || note.displayMode === NoteDisplayMode.DocAndEdgeless)) {
                        ids.push(note.id);
                    }
                    return ids;
                }, []);
                this.edgeless?.service.selection.set({
                    elements: selectedIds,
                    editing: false
                });
            }
            _setDocDisposables() {
                this._clearDocDisposables();
                this._docDisposables = new DisposableGroup();
                this._docDisposables.add(effect(()=>{
                    this._updateNotes();
                    this._updateNoticeVisibility();
                }));
            }
            _shouldRenderNoteList(noteItems) {
                if (!noteItems.length) return false;
                let hasHeadings = false;
                let hasChildrenBlocks = false;
                for (const noteItem of noteItems){
                    for (const block of noteItem.note.children){
                        hasChildrenBlocks = true;
                        if (isHeadingBlock(block)) {
                            hasHeadings = true;
                            break;
                        }
                    }
                    if (hasHeadings) {
                        break;
                    }
                }
                return hasHeadings || this.enableNotesSorting && hasChildrenBlocks;
            }
            _updateNotes() {
                const rootModel = this.doc.root;
                if (this._dragging) return;
                if (!rootModel) {
                    this._pageVisibleNotes = [];
                    return;
                }
                const oldSelectedSet = this._selected.reduce((pre, id)=>{
                    pre.add(id);
                    return pre;
                }, new Set());
                const newSelected = [];
                rootModel.children.forEach((block)=>{
                    if (![
                        'affine:note'
                    ].includes(block.flavour)) return;
                    const blockModel = block;
                    if (blockModel.displayMode !== NoteDisplayMode.EdgelessOnly) {
                        if (oldSelectedSet.has(block.id)) {
                            newSelected.push(block.id);
                        }
                    }
                });
                this._pageVisibleNotes = getNotesFromDoc(this.doc, [
                    NoteDisplayMode.DocAndEdgeless,
                    NoteDisplayMode.DocOnly
                ]);
                this._edgelessOnlyNotes = getNotesFromDoc(this.doc, [
                    NoteDisplayMode.EdgelessOnly
                ]);
                this._selected = newSelected;
            }
            _updateNoticeVisibility() {
                if (this.enableNotesSorting) {
                    if (this.noticeVisible) {
                        this.setNoticeVisibility(false);
                    }
                    return;
                }
                const shouldShowNotice = this._pageVisibleNotes.some((note)=>note.note.displayMode === NoteDisplayMode.DocOnly);
                if (shouldShowNotice && !this.noticeVisible) {
                    this.setNoticeVisibility(true);
                }
            }
            _zoomToFit() {
                const edgeless = this.edgeless;
                if (!edgeless) return;
                const bound = edgeless.getElementsBound();
                if (!bound) return;
                this._oldViewport = {
                    zoom: edgeless.service.viewport.zoom,
                    center: {
                        x: edgeless.service.viewport.center.x,
                        y: edgeless.service.viewport.center.y
                    }
                };
                edgeless.service.viewport.setViewportByBound(new Bound(bound.x, bound.y, bound.w, bound.h), this.viewportPadding, true);
            }
            connectedCallback() {
                super.connectedCallback();
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                if (!this._changedFlag && this._oldViewport) {
                    const edgeless = this.edgeless;
                    if (!edgeless) return;
                    edgeless.service.viewport.setViewport(this._oldViewport.zoom, [
                        this._oldViewport.center.x,
                        this._oldViewport.center.y
                    ], true);
                }
                this._clearDocDisposables();
                this._clearHighlightMask();
            }
            firstUpdated() {
                this.disposables.addFromEvent(this, 'click', this._clickHandler);
                this.disposables.addFromEvent(this, 'dblclick', this._doubleClickHandler);
            }
            render() {
                const shouldRenderPageVisibleNotes = this._shouldRenderNoteList(this._pageVisibleNotes);
                const shouldRenderEdgelessOnlyNotes = this.renderEdgelessOnlyNotes && this._shouldRenderNoteList(this._edgelessOnlyNotes);
                const shouldRenderEmptyPanel = !shouldRenderPageVisibleNotes && !shouldRenderEdgelessOnlyNotes;
                return html`
      <div class="outline-panel-body-container">
        ${shouldRenderEmptyPanel ? this._EmptyPanel() : this._PanelList(shouldRenderEdgelessOnlyNotes)}
      </div>
    `;
            }
            willUpdate(_changedProperties) {
                if (_changedProperties.has('doc') || _changedProperties.has('edgeless')) {
                    this._setDocDisposables();
                }
                if (_changedProperties.has('mode') && this.edgeless && this._isEdgelessMode()) {
                    this._clearHighlightMask();
                    if (_changedProperties.get('mode') === undefined) return;
                    requestAnimationFrame(()=>this._zoomToFit());
                }
            }
            get viewportPadding() {
                return this.fitPadding ? [
                    0,
                    0,
                    0,
                    0
                ].map((val, idx)=>Number.isFinite(this.fitPadding[idx]) ? this.fitPadding[idx] : val) : [
                    0,
                    0,
                    0,
                    0
                ];
            }
            #___private__dragging_1;
            get _dragging() {
                return this.#___private__dragging_1;
            }
            set _dragging(_v) {
                this.#___private__dragging_1 = _v;
            }
            #___private__edgelessOnlyNotes_2;
            get _edgelessOnlyNotes() {
                return this.#___private__edgelessOnlyNotes_2;
            }
            set _edgelessOnlyNotes(_v) {
                this.#___private__edgelessOnlyNotes_2 = _v;
            }
            #___private__pageVisibleNotes_3;
            get _pageVisibleNotes() {
                return this.#___private__pageVisibleNotes_3;
            }
            set _pageVisibleNotes(_v) {
                this.#___private__pageVisibleNotes_3 = _v;
            }
            #___private__selected_4;
            get _selected() {
                return this.#___private__selected_4;
            }
            set _selected(_v) {
                this.#___private__selected_4 = _v;
            }
            #___private_OutlinePanelContainer_5;
            get OutlinePanelContainer() {
                return this.#___private_OutlinePanelContainer_5;
            }
            set OutlinePanelContainer(_v) {
                this.#___private_OutlinePanelContainer_5 = _v;
            }
            #___private_activeHeadingId_6;
            get activeHeadingId() {
                return this.#___private_activeHeadingId_6;
            }
            set activeHeadingId(_v) {
                this.#___private_activeHeadingId_6 = _v;
            }
            #___private_doc_7;
            get doc() {
                return this.#___private_doc_7;
            }
            set doc(_v) {
                this.#___private_doc_7 = _v;
            }
            #___private_domHost_8;
            get domHost() {
                return this.#___private_domHost_8;
            }
            set domHost(_v) {
                this.#___private_domHost_8 = _v;
            }
            #___private_edgeless_9;
            get edgeless() {
                return this.#___private_edgeless_9;
            }
            set edgeless(_v) {
                this.#___private_edgeless_9 = _v;
            }
            #___private_editor_10;
            get editor() {
                return this.#___private_editor_10;
            }
            set editor(_v) {
                this.#___private_editor_10 = _v;
            }
            #___private_enableNotesSorting_11;
            get enableNotesSorting() {
                return this.#___private_enableNotesSorting_11;
            }
            set enableNotesSorting(_v) {
                this.#___private_enableNotesSorting_11 = _v;
            }
            #___private_fitPadding_12;
            get fitPadding() {
                return this.#___private_fitPadding_12;
            }
            set fitPadding(_v) {
                this.#___private_fitPadding_12 = _v;
            }
            #___private_insertIndex_13;
            get insertIndex() {
                return this.#___private_insertIndex_13;
            }
            set insertIndex(_v) {
                this.#___private_insertIndex_13 = _v;
            }
            #___private_noticeVisible_14;
            get noticeVisible() {
                return this.#___private_noticeVisible_14;
            }
            set noticeVisible(_v) {
                this.#___private_noticeVisible_14 = _v;
            }
            #___private_panelListElement_15;
            get panelListElement() {
                return this.#___private_panelListElement_15;
            }
            set panelListElement(_v) {
                this.#___private_panelListElement_15 = _v;
            }
            #___private_renderEdgelessOnlyNotes_16;
            get renderEdgelessOnlyNotes() {
                return this.#___private_renderEdgelessOnlyNotes_16;
            }
            set renderEdgelessOnlyNotes(_v) {
                this.#___private_renderEdgelessOnlyNotes_16 = _v;
            }
            #___private_setNoticeVisibility_17;
            get setNoticeVisibility() {
                return this.#___private_setNoticeVisibility_17;
            }
            set setNoticeVisibility(_v) {
                this.#___private_setNoticeVisibility_17 = _v;
            }
            #___private_showPreviewIcon_18;
            get showPreviewIcon() {
                return this.#___private_showPreviewIcon_18;
            }
            set showPreviewIcon(_v) {
                this.#___private_showPreviewIcon_18 = _v;
            }
            #___private_toggleNotesSorting_19;
            get toggleNotesSorting() {
                return this.#___private_toggleNotesSorting_19;
            }
            set toggleNotesSorting(_v) {
                this.#___private_toggleNotesSorting_19 = _v;
            }
            constructor(...args){
                super(...args);
                this._changedFlag = false;
                this._clearHighlightMask = ()=>{};
                this._docDisposables = null;
                this._indicatorTranslateY = 0;
                this.#___private__dragging_1 = (_initProto(this), _init__dragging(this, false));
                this.#___private__edgelessOnlyNotes_2 = _init__edgelessOnlyNotes(this, []);
                this.#___private__pageVisibleNotes_3 = _init__pageVisibleNotes(this, []);
                this.#___private__selected_4 = _init__selected(this, []);
                this.#___private_OutlinePanelContainer_5 = _init_OutlinePanelContainer(this);
                this.#___private_activeHeadingId_6 = _init_activeHeadingId(this);
                this.#___private_doc_7 = _init_doc(this);
                this.#___private_domHost_8 = _init_domHost(this);
                this.#___private_edgeless_9 = _init_edgeless(this);
                this.#___private_editor_10 = _init_editor(this);
                this.#___private_enableNotesSorting_11 = _init_enableNotesSorting(this);
                this.#___private_fitPadding_12 = _init_fitPadding(this);
                this.#___private_insertIndex_13 = _init_insertIndex(this, undefined);
                this.#___private_noticeVisible_14 = _init_noticeVisible(this);
                this.#___private_panelListElement_15 = _init_panelListElement(this);
                this.#___private_renderEdgelessOnlyNotes_16 = _init_renderEdgelessOnlyNotes(this, true);
                this.#___private_setNoticeVisibility_17 = _init_setNoticeVisibility(this);
                this.#___private_showPreviewIcon_18 = _init_showPreviewIcon(this);
                this.#___private_toggleNotesSorting_19 = _init_toggleNotesSorting(this);
            }
        }
    }
}();
export { _OutlinePanelBody as OutlinePanelBody };
