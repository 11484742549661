import { DebugLogger } from '@affine/debug';
import { backoffRetry, catchErrorInto, effect, Entity, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { EMPTY, mergeMap, switchMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../../cloud';
export const logger = new DebugLogger('affine:share-doc-list');
export class ShareDocsList extends Entity {
    constructor(workspaceService, store, cache){
        super();
        this.workspaceService = workspaceService;
        this.store = store;
        this.cache = cache;
        this.list$ = LiveData.from(this.cache.watch('share-docs'), []);
        this.isLoading$ = new LiveData(false);
        this.error$ = new LiveData(null);
        this.revalidate = effect(switchMap(()=>fromPromise((signal)=>{
                return this.store.getWorkspacesShareDocs(this.workspaceService.workspace.id, signal);
            }).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), mergeMap((list)=>{
                this.cache.set('share-docs', list);
                return EMPTY;
            }), catchErrorInto(this.error$, (err)=>logger.error('revalidate share docs error', err)), onStart(()=>{
                this.isLoading$.next(true);
            }), onComplete(()=>{
                this.isLoading$.next(false);
            }))));
    }
}
