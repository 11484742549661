import { AIEdgelessRootBlockSpec, AIPageRootBlockSpec } from '@affine/core/blocksuite/presets/ai';
import { mixpanel } from '@affine/core/mixpanel';
import { AffineCanvasTextFonts, EdgelessRootService, PageRootService } from '@blocksuite/blocks';
import { createLinkedWidgetConfig } from './linked-widget';
function customLoadFonts(service) {
    if (runtimeConfig.isSelfHosted) {
        const fonts = AffineCanvasTextFonts.map((font)=>({
                ...font,
                url: '/assets/' + new URL(font.url).pathname.split('/').pop()
            }));
        service.fontLoader.load(fonts);
    } else {
        service.fontLoader.load(AffineCanvasTextFonts);
    }
}
function withAffineRootService(Service) {
    return class extends Service {
        loadFonts() {
            customLoadFonts(this);
        }
        constructor(...args){
            super(...args);
            this.telemetryService = {
                track: (eventName, props)=>{
                    mixpanel.track(eventName, props);
                }
            };
        }
    };
}
export function createPageRootBlockSpec(framework) {
    return {
        ...AIPageRootBlockSpec,
        service: withAffineRootService(PageRootService),
        config: {
            linkedWidget: createLinkedWidgetConfig(framework)
        }
    };
}
export function createEdgelessRootBlockSpec(framework) {
    return {
        ...AIEdgelessRootBlockSpec,
        service: withAffineRootService(EdgelessRootService),
        config: {
            linkedWidget: createLinkedWidgetConfig(framework)
        }
    };
}
