import { backoffRetry, effect, Entity, fromPromise, LiveData } from '@toeverything/infra';
import { EMPTY, exhaustMap, mergeMap } from 'rxjs';
export class ServerConfig extends Entity {
    constructor(store){
        super();
        this.store = store;
        this.config$ = new LiveData(null);
        this.features$ = this.config$.map((config)=>{
            return config ? Array.from(new Set(config.features)).reduce((acc, cur)=>{
                acc[cur.toLowerCase()] = true;
                return acc;
            }, {}) : null;
        });
        this.credentialsRequirement$ = this.config$.map((config)=>{
            return config ? config.credentialsRequirement : null;
        });
        this.revalidate = effect(exhaustMap(()=>{
            return fromPromise((signal)=>this.store.fetchServerConfig(signal)).pipe(backoffRetry({
                count: Infinity
            }), mergeMap((config)=>{
                this.config$.next(config);
                return EMPTY;
            }));
        }));
        this.revalidateIfNeeded = ()=>{
            if (!this.config$.value) {
                this.revalidate();
            }
        };
    }
    dispose() {
        this.revalidate.unsubscribe();
    }
}
