import "../core/src/components/page-list/filter/index.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/filter/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71Vy3ajMAzd8xVaThfOgaR5jPM1BgtQx7E5tgnp9PTfe2zyIAlpki665CJLV1fy9WT75pYzkcJHAiDJNUq8cygV7tYJQOvQMocKC89BG40BFIoqzcjjxnEoUHu0ATZbtKUyHYeapES9Tj6TSZ89i9lLoz1z9B85bIX9w5goS9LIIr5zL4MD03hgI2xFmsOy2UG2aCKjGNwhVbXnME/TgKmQpd5j03QQ+G01gMIoYy/+e9x55rAwWgr7zmLIkNpsQI1503CY9gX3UG68N5tLVGHpOWTnoO0Zr3rw0EDWf+ai+FdZ02p5QTA3VqK9Jvb6rMjz8Zn3+SMPcEaR/Lb8IZ5ZIal1HF7v0O9q8hgPNkJK0lWU6iDC9RIBvLXOUxlGoT1qz8E1okCWo+/wbM0W4w3d2NertKdfRWtdWIzG0AE6m2Ps8Vh2+aDwFx3vhfJWaEeejOYglIJ0ks0doHDISDPT+mElXgeBYr0Rjjc1j6fujOxYZPVwO09cnx/3/vg8w28OM8hAtN7EmZFmHUlfc5iuznv8DSH/3hfSbV6eafHHGp4bzsFKo2AZpHvBTsR/Qx1xX51cuN4pRukfxQhGlT4j49jeUmH0tafmQ7Mf3P2Ld2jRv0M3BS1imjFvi27IoqGFB7azoolDDbfoFI9KUePIrZPPL0eD7wSwBwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var argStyle = 'vjs73ab';
export var ellipsisTextStyle = 'vjs73ac';
export var filterContainerStyle = 'vjs73a0';
export var filterItemCloseStyle = 'vjs73a6';
export var filterItemStyle = 'vjs73a5';
export var filterTypeIconStyle = 'vjs73aa';
export var filterTypeStyle = 'vjs73a9';
export var inputStyle = 'vjs73a7';
export var menuItemStyle = 'vjs73a1';
export var menuItemTextStyle = 'vjs73a4';
export var switchStyle = 'vjs73a8';
export var variableSelectDividerStyle = 'vjs73a3';
export var variableSelectTitleStyle = 'vjs73a2';