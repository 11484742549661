import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton, MenuIcon, MenuItem, MenuSeparator, useConfirmModal } from '@affine/component';
import { useAppSettingHelper } from '@affine/core/hooks/affine/use-app-setting-helper';
import { useDeleteCollectionInfo } from '@affine/core/hooks/affine/use-delete-collection-info';
import { track } from '@affine/core/mixpanel';
import { CollectionService } from '@affine/core/modules/collection';
import { CompatibleFavoriteItemsAdapter } from '@affine/core/modules/properties';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { useI18n } from '@affine/i18n';
import { DeleteIcon, FavoritedIcon, FavoriteIcon, FilterIcon, OpenInNewIcon, PlusIcon, SplitViewIcon } from '@blocksuite/icons/rc';
import { DocsService, useLiveData, useServices } from '@toeverything/infra';
import { useCallback, useMemo } from 'react';
export const useExplorerCollectionNodeOperations = (collectionId, onOpenCollapsed, onOpenEdit)=>{
    const t = useI18n();
    const { appSettings } = useAppSettingHelper();
    const { workbenchService, docsService, collectionService, compatibleFavoriteItemsAdapter } = useServices({
        DocsService,
        WorkbenchService,
        CollectionService,
        CompatibleFavoriteItemsAdapter
    });
    const deleteInfo = useDeleteCollectionInfo();
    const favorite = useLiveData(useMemo(()=>compatibleFavoriteItemsAdapter.isFavorite$(collectionId, 'collection'), [
        collectionId,
        compatibleFavoriteItemsAdapter
    ]));
    const { openConfirmModal } = useConfirmModal();
    const createAndAddDocument = useCallback(()=>{
        const newDoc = docsService.createDoc();
        collectionService.addPageToCollection(collectionId, newDoc.id);
        track.$.navigationPanel.collections.createDoc();
        track.$.navigationPanel.collections.addDocToCollection({
            control: 'button'
        });
        workbenchService.workbench.openDoc(newDoc.id);
        onOpenCollapsed();
    }, [
        collectionId,
        collectionService,
        docsService,
        onOpenCollapsed,
        workbenchService.workbench
    ]);
    const handleToggleFavoriteCollection = useCallback(()=>{
        compatibleFavoriteItemsAdapter.toggle(collectionId, 'collection');
        track.$.navigationPanel.organize.toggleFavorite({
            type: 'collection'
        });
    }, [
        compatibleFavoriteItemsAdapter,
        collectionId
    ]);
    const handleAddDocToCollection = useCallback(()=>{
        openConfirmModal({
            title: t['com.affine.collection.add-doc.confirm.title'](),
            description: t['com.affine.collection.add-doc.confirm.description'](),
            cancelText: t['Cancel'](),
            confirmText: t['Confirm'](),
            confirmButtonOptions: {
                variant: 'primary'
            },
            onConfirm: createAndAddDocument
        });
    }, [
        createAndAddDocument,
        openConfirmModal,
        t
    ]);
    const handleOpenInSplitView = useCallback(()=>{
        workbenchService.workbench.openCollection(collectionId, {
            at: 'beside'
        });
        track.$.navigationPanel.organize.openInSplitView({
            type: 'collection'
        });
    }, [
        collectionId,
        workbenchService.workbench
    ]);
    const handleOpenInNewTab = useCallback(()=>{
        workbenchService.workbench.openCollection(collectionId, {
            at: 'new-tab'
        });
        track.$.navigationPanel.organize.openInNewTab({
            type: 'collection'
        });
    }, [
        collectionId,
        workbenchService.workbench
    ]);
    const handleDeleteCollection = useCallback(()=>{
        collectionService.deleteCollection(deleteInfo, collectionId);
        track.$.navigationPanel.organize.deleteOrganizeItem({
            type: 'collection'
        });
    }, [
        collectionId,
        collectionService,
        deleteInfo
    ]);
    const handleShowEdit = useCallback(()=>{
        onOpenEdit();
    }, [
        onOpenEdit
    ]);
    return useMemo(()=>[
            {
                index: 0,
                inline: true,
                view: _jsx(IconButton, {
                    size: "16",
                    onClick: handleAddDocToCollection,
                    tooltip: t['com.affine.rootAppSidebar.explorer.collection-add-tooltip'](),
                    children: _jsx(PlusIcon, {})
                })
            },
            {
                index: 99,
                view: _jsx(MenuItem, {
                    preFix: _jsx(MenuIcon, {
                        children: _jsx(FilterIcon, {})
                    }),
                    onClick: handleShowEdit,
                    children: t['com.affine.collection.menu.edit']()
                })
            },
            {
                index: 99,
                view: _jsx(MenuItem, {
                    preFix: _jsx(MenuIcon, {
                        children: _jsx(PlusIcon, {})
                    }),
                    onClick: handleAddDocToCollection,
                    children: t['New Page']()
                })
            },
            {
                index: 99,
                view: _jsx(MenuItem, {
                    preFix: _jsx(MenuIcon, {
                        children: favorite ? _jsx(FavoritedIcon, {
                            style: {
                                color: 'var(--affine-primary-color)'
                            }
                        }) : _jsx(FavoriteIcon, {})
                    }),
                    onClick: handleToggleFavoriteCollection,
                    children: favorite ? t['com.affine.favoritePageOperation.remove']() : t['com.affine.favoritePageOperation.add']()
                })
            },
            {
                index: 99,
                view: _jsx(MenuItem, {
                    preFix: _jsx(MenuIcon, {
                        children: _jsx(OpenInNewIcon, {})
                    }),
                    onClick: handleOpenInNewTab,
                    children: t['com.affine.workbench.tab.page-menu-open']()
                })
            },
            ...appSettings.enableMultiView ? [
                {
                    index: 99,
                    view: _jsx(MenuItem, {
                        preFix: _jsx(MenuIcon, {
                            children: _jsx(SplitViewIcon, {})
                        }),
                        onClick: handleOpenInSplitView,
                        children: t['com.affine.workbench.split-view.page-menu-open']()
                    })
                }
            ] : [],
            {
                index: 9999,
                view: _jsx(MenuSeparator, {}, "menu-separator")
            },
            {
                index: 10000,
                view: _jsx(MenuItem, {
                    type: 'danger',
                    preFix: _jsx(MenuIcon, {
                        children: _jsx(DeleteIcon, {})
                    }),
                    onClick: handleDeleteCollection,
                    children: t['Delete']()
                })
            }
        ], [
        appSettings.enableMultiView,
        favorite,
        handleAddDocToCollection,
        handleDeleteCollection,
        handleOpenInNewTab,
        handleOpenInSplitView,
        handleShowEdit,
        handleToggleFavoriteCollection,
        t
    ]);
};
