import { jsx as _jsx } from "react/jsx-runtime";
import { InlineEdit } from '@affine/component';
import { useBlockSuiteDocMeta, useDocMetaHelper } from '@affine/core/hooks/use-block-suite-page-meta';
import clsx from 'clsx';
import { useCallback } from 'react';
import * as styles from './style.css';
const inputAttrs = {
    'data-testid': 'title-content'
};
export const BlocksuiteHeaderTitle = (props)=>{
    const { docCollection, pageId, isPublic, inputHandleRef, onEditSave } = props;
    const currentPage = docCollection.getDoc(pageId);
    const pageMeta = useBlockSuiteDocMeta(docCollection).find((meta)=>meta.id === currentPage?.id);
    const title = pageMeta?.title;
    const { setDocTitle } = useDocMetaHelper(docCollection);
    const onChange = useCallback((v)=>{
        onEditSave?.();
        setDocTitle(currentPage?.id || '', v);
    }, [
        currentPage?.id,
        onEditSave,
        setDocTitle
    ]);
    return _jsx(InlineEdit, {
        className: clsx(styles.title, props.className),
        autoSelect: true,
        value: title,
        onChange: onChange,
        editable: !isPublic,
        exitible: true,
        placeholder: "Untitled",
        "data-testid": "title-edit-button",
        handleRef: inputHandleRef,
        inputAttrs: inputAttrs
    });
};
