import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
export class WorkspaceList extends Entity {
    constructor(providers){
        super();
        this.providers = providers;
        this.workspaces$ = new LiveData(this.providers.map((p)=>p.workspaces$)).map((v)=>{
            return v;
        }).flat().map((workspaces)=>{
            return workspaces.flat();
        });
        this.isLoading$ = new LiveData(this.providers.map((p)=>p.isLoading$ ?? new LiveData(false))).flat().map((isLoadings)=>isLoadings.some((isLoading)=>isLoading));
    }
    revalidate() {
        this.providers.forEach((provider)=>provider.revalidate?.());
    }
}
