import { bundledLanguagesInfo, isPlainLang } from 'shiki';
import { PLAIN_TEXT_LANG_INFO } from './consts.js';
const PopularLanguages = [
    'python',
    'c',
    'java',
    'cpp',
    'csharp',
    'vb',
    'javascript',
    'php',
    'sql',
    'asm',
    'pascal',
    'go',
    'swift',
    'matlab',
    'r',
    'rust',
    'ruby',
    'sas',
    'ada',
    'perl',
    'objective-c',
    'cobol',
    'lisp',
    'dart',
    'lua',
    'julia',
    'd',
    'kotlin',
    'logo',
    'scala',
    'haskell',
    'fsharp',
    'scheme',
    'typescript',
    'groovy',
    'abap',
    'prolog',
    'plsql',
    'bash',
    'apex',
    'markdown',
    'json',
    'html',
    'css',
    'diff',
    'jsx',
    'tsx',
    'vue'
];
const POPULAR_LANGUAGES_MAP = PopularLanguages.reduce((acc, lang, i)=>{
    return {
        [lang]: i,
        ...acc
    };
}, {});
export function getLanguagePriority(lang) {
    return POPULAR_LANGUAGES_MAP[lang] ?? Infinity;
}
export function isPlaintext(lang) {
    return isPlainLang(lang) || PLAIN_TEXT_LANG_INFO.id === lang;
}
export const getStandardLanguage = (languageName, strict = false)=>{
    if (!languageName) return null;
    if (isPlaintext(languageName)) {
        return null;
    }
    const language = bundledLanguagesInfo.find((codeLanguage)=>codeLanguage.id.toLowerCase() === languageName.toLowerCase());
    if (language) return language;
    if (strict) return null;
    const aliasLanguage = bundledLanguagesInfo.find((codeLanguage)=>codeLanguage.aliases?.includes(languageName.toLowerCase()));
    return aliasLanguage ?? null;
};
