import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, IconButton, Menu } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { CloseIcon, PlusIcon } from '@blocksuite/icons/rc';
import { Condition } from './condition';
import * as styles from './index.css';
import { CreateFilterMenu } from './vars';
export const FilterList = ({ value, onChange, propertiesMeta })=>{
    const t = useI18n();
    return _jsxs("div", {
        style: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
            alignItems: 'center'
        },
        children: [
            value.map((filter, i)=>{
                return _jsxs("div", {
                    className: styles.filterItemStyle,
                    children: [
                        _jsx(Condition, {
                            propertiesMeta: propertiesMeta,
                            value: filter,
                            onChange: (filter)=>{
                                onChange(value.map((old, oldIndex)=>oldIndex === i ? filter : old));
                            }
                        }),
                        _jsx("div", {
                            className: styles.filterItemCloseStyle,
                            onClick: ()=>{
                                onChange(value.filter((_, index)=>i !== index));
                            },
                            children: _jsx(CloseIcon, {})
                        })
                    ]
                }, i);
            }),
            _jsx(Menu, {
                items: _jsx(CreateFilterMenu, {
                    value: value,
                    onChange: onChange,
                    propertiesMeta: propertiesMeta
                }),
                children: value.length === 0 ? _jsx(Button, {
                    suffix: _jsx(PlusIcon, {}),
                    children: t['com.affine.filterList.button.add']()
                }) : _jsx(IconButton, {
                    size: "16",
                    children: _jsx(PlusIcon, {})
                })
            })
        ]
    });
};
