import { PageSurfaceBlockSpec, PageSurfaceRefBlockSpec } from '@blocksuite/blocks';
import { CommonBlockSpecs } from './common';
import { createPageRootBlockSpec } from './custom/root-block';
export function createPageModeSpecs(framework) {
    return [
        ...CommonBlockSpecs,
        PageSurfaceBlockSpec,
        PageSurfaceRefBlockSpec,
        createPageRootBlockSpec(framework)
    ];
}
