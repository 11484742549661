import { matchFlavours } from '../../_common/utils/model.js';
export function getNextContinuousNumberedLists(doc, modelOrId) {
    const model = typeof modelOrId === 'string' ? doc.getBlock(modelOrId).model : modelOrId;
    const parent = doc.getParent(model);
    if (!parent) return [];
    const modelIndex = parent.children.indexOf(model);
    if (modelIndex === -1) return [];
    const firstNotNumberedListIndex = parent.children.findIndex((model, i)=>i > modelIndex && (!matchFlavours(model, [
            'affine:list'
        ]) || model.type !== 'numbered'));
    const newContinuousLists = parent.children.slice(modelIndex + 1, firstNotNumberedListIndex === -1 ? undefined : firstNotNumberedListIndex);
    if (!newContinuousLists.every((model)=>matchFlavours(model, [
            'affine:list'
        ]) && model.type === 'numbered')) return [];
    return newContinuousLists;
}
export function correctNumberedListsOrderToPrev(doc, modelOrId, transact = true) {
    const model = typeof modelOrId === 'string' ? doc.getBlock(modelOrId).model : modelOrId;
    if (!matchFlavours(model, [
        'affine:list'
    ]) || model.type$.value !== 'numbered') {
        return;
    }
    const fn = ()=>{
        const previousSibling = doc.getPrev(model);
        if (previousSibling && matchFlavours(previousSibling, [
            'affine:list'
        ]) && previousSibling.type === 'numbered') {
            if (!previousSibling.order) previousSibling.order = 1;
            model.order = previousSibling.order + 1;
        } else {
            model.order = 1;
        }
        let base = model.order + 1;
        const continuousNumberedLists = getNextContinuousNumberedLists(doc, model);
        continuousNumberedLists.forEach((list)=>{
            list.order = base;
            base++;
        });
    };
    if (transact) {
        doc.transact(fn);
    } else {
        fn();
    }
}
export function correctListOrder(doc, model) {
    if (model.type === 'numbered' && !Number.isInteger(model.order)) {
        correctNumberedListsOrderToPrev(doc, model, false);
    }
    if (model.type !== 'numbered') {
        model.order = null;
    }
}
