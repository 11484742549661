function maybeAffineOrigin(origin) {
    return origin.startsWith('file://') || origin.startsWith('affine://') || origin.endsWith('affine.pro') || origin.endsWith('affine.fail') || origin.includes('localhost');
}
export const resolveRouteLinkMeta = (href)=>{
    try {
        const url = new URL(href, location.origin);
        if (!maybeAffineOrigin(url.origin)) {
            return null;
        }
        const [_, workspaceId, moduleName, subModuleName] = url.pathname.match(/\/workspace\/([^/]+)\/([^/]+)(?:\/([^/]+))?/) || [];
        if (workspaceId) {
            const basename = `/workspace/${workspaceId}`;
            const pathname = url.pathname.replace(basename, '');
            const search = url.search;
            const hash = url.hash;
            const location1 = {
                pathname,
                search,
                hash
            };
            if (isRouteModulePath(moduleName)) {
                return {
                    location: location1,
                    basename,
                    workspaceId,
                    moduleName,
                    subModuleName
                };
            } else if (moduleName) {
                return {
                    location: location1,
                    basename,
                    workspaceId,
                    moduleName: 'doc',
                    docId: moduleName,
                    blockId: hash.slice(1)
                };
            }
        }
        return null;
    } catch  {
        return null;
    }
};
export const routeModulePaths = [
    'all',
    'collection',
    'tag',
    'trash'
];
const isRouteModulePath = (path)=>routeModulePaths.includes(path);
export const resolveLinkToDoc = (href)=>{
    const meta = resolveRouteLinkMeta(href);
    if (!meta || meta.moduleName !== 'doc') return null;
    return {
        workspaceId: meta.workspaceId,
        docId: meta.docId,
        blockId: meta.blockId
    };
};
