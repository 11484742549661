import { assertExists } from '@blocksuite/global/utils';
import { baseTheme } from '@toeverything/theme';
import { html } from 'lit';
import { getRootByEditorHost } from '../utils/query.js';
let ToastContainer = null;
const htmlToElement = (html)=>{
    const template = document.createElement('template');
    if (typeof html === 'string') {
        html = html.trim();
        template.innerHTML = html;
    } else {
        const htmlString = String.raw(html.strings, ...html.values);
        template.innerHTML = htmlString;
    }
    return template.content.firstChild;
};
const createToastContainer = (editorHost)=>{
    const styles = `
    position: fixed;
    z-index: 9999;
    top: 16px;
    left: 16px;
    right: 16px;
    bottom: 78px;
    pointer-events: none;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  `;
    const template = html`<div class="toast-container" style="${styles}"></div>`;
    const element = htmlToElement(template);
    const rootComponent = getRootByEditorHost(editorHost);
    assertExists(rootComponent);
    const viewportElement = rootComponent.viewportElement;
    viewportElement?.append(element);
    return element;
};
export const toast = (editorHost, message, duration = 2500)=>{
    if (!ToastContainer) {
        ToastContainer = createToastContainer(editorHost);
    }
    const styles = `
    max-width: 480px;
    text-align: center;
    font-family: ${baseTheme.fontSansFamily};
    font-size: var(--affine-font-sm);
    padding: 6px 12px;
    margin: 10px 0 0 0;
    color: var(--affine-white);
    background: var(--affine-tooltip);
    box-shadow: var(--affine-float-button-shadow);
    border-radius: 10px;
    transition: all 230ms cubic-bezier(0.21, 1.02, 0.73, 1);
    opacity: 0;
  `;
    const template = html`<div style="${styles}"></div>`;
    const element = htmlToElement(template);
    element.textContent = message;
    ToastContainer.append(element);
    const fadeIn = [
        {
            opacity: 0
        },
        {
            opacity: 1
        }
    ];
    const options = {
        duration: 230,
        easing: 'cubic-bezier(0.21, 1.02, 0.73, 1)',
        fill: 'forwards'
    };
    element.animate(fadeIn, options);
    setTimeout(()=>{
        const fadeOut = fadeIn.reverse();
        const animation = element.animate(fadeOut, options);
        animation.finished.then(()=>{
            element.style.maxHeight = '0';
            element.style.margin = '0';
            element.style.padding = '0';
            element.addEventListener('transitionend', ()=>{
                element.remove();
            }, {
                once: true
            });
        }).catch(console.error);
    }, duration);
    return element;
};
