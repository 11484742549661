import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, IconButton, Tooltip } from '@affine/component';
import { CompatibleFavoriteItemsAdapter } from '@affine/core/modules/properties';
import { Trans, useI18n } from '@affine/i18n';
import { CloseIcon, EdgelessIcon, PageIcon, ToggleCollapseIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { FilterList } from '../../filter';
import { List, ListScrollContainer } from '../../list';
import { filterPageByRules } from '../../use-collection-manager';
import { AffineShapeIcon } from '../affine-shape';
import * as styles from './edit-collection.css';
export const RulesMode = ({ collection, updateCollection, reset, buttons, switchMode, allPageListConfig })=>{
    const t = useI18n();
    const [showPreview, setShowPreview] = useState(true);
    const allowListPages = [];
    const rulesPages = [];
    const favAdapter = useService(CompatibleFavoriteItemsAdapter);
    const favorites = useLiveData(favAdapter.favorites$);
    allPageListConfig.allPages.forEach((meta)=>{
        if (meta.trash) {
            return;
        }
        const pageData = {
            meta,
            publicMode: allPageListConfig.getPublicMode(meta.id),
            favorite: favorites.some((f)=>f.id === meta.id)
        };
        if (collection.filterList.length && filterPageByRules(collection.filterList, [], pageData)) {
            rulesPages.push(meta);
        }
        if (collection.allowList.includes(meta.id)) {
            allowListPages.push(meta);
        }
    });
    const [expandInclude, setExpandInclude] = useState(collection.allowList.length > 0);
    const operationsRenderer = useCallback((item)=>{
        const page = item;
        return allPageListConfig.favoriteRender(page);
    }, [
        allPageListConfig
    ]);
    const tips = useMemo(()=>_jsx(Trans, {
            i18nKey: "com.affine.editCollection.rules.tips",
            values: {
                highlight: t['com.affine.editCollection.rules.tips.highlight']()
            },
            components: {
                2: _jsx("span", {
                    className: styles.rulesTitleHighlight
                })
            }
        }), [
        t
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx("input", {
                type: "text",
                style: {
                    width: 0,
                    height: 0
                },
                onFocus: (e)=>requestAnimationFrame(()=>e.target.blur())
            }),
            _jsx(Tooltip, {
                content: tips,
                children: _jsx("div", {
                    className: clsx(styles.rulesTitle, styles.ellipsis),
                    children: tips
                })
            }),
            _jsxs("div", {
                className: styles.rulesContainer,
                children: [
                    _jsxs("div", {
                        className: styles.rulesContainerLeft,
                        children: [
                            _jsx("div", {
                                className: styles.rulesContainerLeftTab,
                                children: switchMode
                            }),
                            _jsx("div", {
                                className: styles.rulesContainerLeftContent,
                                children: _jsxs("div", {
                                    style: {
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 8,
                                        overflowY: 'auto'
                                    },
                                    children: [
                                        _jsx(FilterList, {
                                            propertiesMeta: allPageListConfig.docCollection.meta.properties,
                                            value: collection.filterList,
                                            onChange: useCallback((filterList)=>updateCollection({
                                                    ...collection,
                                                    filterList
                                                }), [
                                                collection,
                                                updateCollection
                                            ])
                                        }),
                                        _jsxs("div", {
                                            className: styles.rulesContainerLeftContentInclude,
                                            children: [
                                                collection.allowList.length > 0 ? _jsxs("div", {
                                                    className: styles.includeTitle,
                                                    children: [
                                                        _jsx(IconButton, {
                                                            onClick: ()=>setExpandInclude(!expandInclude),
                                                            iconStyle: {
                                                                transform: expandInclude ? 'rotate(90deg)' : undefined
                                                            },
                                                            icon: _jsx(ToggleCollapseIcon, {})
                                                        }),
                                                        _jsx("div", {
                                                            style: {
                                                                color: cssVar('textSecondaryColor')
                                                            },
                                                            children: t['com.affine.editCollection.rules.include.title']()
                                                        })
                                                    ]
                                                }) : null,
                                                _jsx("div", {
                                                    style: {
                                                        display: expandInclude ? 'flex' : 'none',
                                                        flexWrap: 'wrap',
                                                        gap: '8px 16px'
                                                    },
                                                    children: collection.allowList.map((id)=>{
                                                        const page = allPageListConfig.allPages.find((v)=>v.id === id);
                                                        return _jsxs("div", {
                                                            className: styles.includeItem,
                                                            children: [
                                                                _jsxs("div", {
                                                                    className: styles.includeItemContent,
                                                                    children: [
                                                                        _jsxs("div", {
                                                                            style: {
                                                                                display: 'flex',
                                                                                gap: 6,
                                                                                alignItems: 'center'
                                                                            },
                                                                            children: [
                                                                                allPageListConfig.isEdgeless(id) ? _jsx(EdgelessIcon, {
                                                                                    style: {
                                                                                        width: 16,
                                                                                        height: 16
                                                                                    }
                                                                                }) : _jsx(PageIcon, {
                                                                                    style: {
                                                                                        width: 16,
                                                                                        height: 16
                                                                                    }
                                                                                }),
                                                                                t['com.affine.editCollection.rules.include.page']()
                                                                            ]
                                                                        }),
                                                                        _jsx("div", {
                                                                            className: styles.includeItemContentIs,
                                                                            children: t['com.affine.editCollection.rules.include.is']()
                                                                        }),
                                                                        _jsx("div", {
                                                                            className: clsx(styles.includeItemTitle, styles.ellipsis),
                                                                            children: page?.title || t['Untitled']()
                                                                        })
                                                                    ]
                                                                }),
                                                                _jsx(IconButton, {
                                                                    size: "14",
                                                                    icon: _jsx(CloseIcon, {}),
                                                                    onClick: ()=>{
                                                                        updateCollection({
                                                                            ...collection,
                                                                            allowList: collection.allowList.filter((v)=>v !== id)
                                                                        });
                                                                    }
                                                                })
                                                            ]
                                                        }, id);
                                                    })
                                                })
                                            ]
                                        })
                                    ]
                                })
                            })
                        ]
                    }),
                    _jsxs(ListScrollContainer, {
                        className: styles.rulesContainerRight,
                        style: {
                            display: showPreview ? 'flex' : 'none'
                        },
                        children: [
                            rulesPages.length > 0 ? _jsx(List, {
                                hideHeader: true,
                                className: styles.resultPages,
                                items: rulesPages,
                                docCollection: allPageListConfig.docCollection,
                                isPreferredEdgeless: allPageListConfig.isEdgeless,
                                operationsRenderer: operationsRenderer
                            }) : _jsx(RulesEmpty, {
                                noRules: collection.filterList.length === 0,
                                fullHeight: allowListPages.length === 0
                            }),
                            allowListPages.length > 0 ? _jsxs("div", {
                                children: [
                                    _jsx("div", {
                                        className: styles.includeListTitle,
                                        children: t['com.affine.editCollection.rules.include.title']()
                                    }),
                                    _jsx(List, {
                                        hideHeader: true,
                                        className: styles.resultPages,
                                        items: allowListPages,
                                        docCollection: allPageListConfig.docCollection,
                                        isPreferredEdgeless: allPageListConfig.isEdgeless,
                                        operationsRenderer: operationsRenderer
                                    })
                                ]
                            }) : null
                        ]
                    })
                ]
            }),
            _jsxs("div", {
                className: styles.rulesBottom,
                children: [
                    _jsxs("div", {
                        className: styles.bottomLeft,
                        children: [
                            _jsx(Button, {
                                onClick: ()=>{
                                    setShowPreview(!showPreview);
                                },
                                children: t['com.affine.editCollection.rules.preview']()
                            }),
                            _jsx(Button, {
                                variant: "plain",
                                onClick: reset,
                                children: t['com.affine.editCollection.rules.reset']()
                            }),
                            _jsx("div", {
                                className: styles.previewCountTips,
                                children: _jsxs(Trans, {
                                    i18nKey: "com.affine.editCollection.rules.countTips",
                                    values: {
                                        selectedCount: allowListPages.length,
                                        filteredCount: rulesPages.length
                                    },
                                    children: [
                                        "Selected",
                                        _jsx("span", {
                                            className: styles.previewCountTipsHighlight,
                                            children: "count"
                                        }),
                                        ", filtered",
                                        _jsx("span", {
                                            className: styles.previewCountTipsHighlight,
                                            children: "count"
                                        })
                                    ]
                                })
                            })
                        ]
                    }),
                    _jsx("div", {
                        style: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 20
                        },
                        children: buttons
                    })
                ]
            })
        ]
    });
};
const RulesEmpty = ({ noRules, fullHeight })=>{
    const t = useI18n();
    return _jsxs("div", {
        style: {
            height: fullHeight ? '100%' : '70%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 18,
            padding: '48px 0'
        },
        children: [
            _jsx(AffineShapeIcon, {}),
            _jsx("strong", {
                style: {
                    fontSize: 20,
                    lineHeight: '28px'
                },
                children: noRules ? t['com.affine.editCollection.rules.empty.noRules']() : t['com.affine.editCollection.rules.empty.noResults']()
            }),
            _jsx("div", {
                style: {
                    width: '389px',
                    textAlign: 'center',
                    fontSize: 15,
                    lineHeight: '24px'
                },
                children: noRules ? _jsxs(Trans, {
                    i18nKey: "com.affine.editCollection.rules.empty.noRules.tips",
                    children: [
                        "Please ",
                        _jsx("strong", {
                            children: "add rules"
                        }),
                        " to save this collection or switch to ",
                        _jsx("strong", {
                            children: "Pages"
                        }),
                        ", use manual selection mode"
                    ]
                }) : t['com.affine.editCollection.rules.empty.noResults.tips']()
            })
        ]
    });
};
