function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _BlockComponent, _dec1, _dec2, _dec3, _dec4, _init_background, _init_editorViewportSelector, _init_layer, _init_surface, _initProto;
import { BlockComponent } from '@blocksuite/block-std';
import { assertExists } from '@blocksuite/global/utils';
import { css, html } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { requestThrottledConnectedFrame } from '../../_common/utils/index.js';
import '../../surface-block/surface-block.js';
import './components/note-slicer/index.js';
import './components/presentation/edgeless-navigator-black-background.js';
import './components/rects/edgeless-dragging-area-rect.js';
import './components/rects/edgeless-selected-rect.js';
import './components/toolbar/edgeless-toolbar.js';
import { edgelessElementsBound } from './utils/bound-utils.js';
import { getBackgroundGrid, isCanvasElement } from './utils/query.js';
let _EdgelessRootPreviewBlockComponent;
_dec = customElement('affine-edgeless-root-preview'), _dec1 = query('.edgeless-background'), _dec2 = state(), _dec3 = query('.edgeless-layer'), _dec4 = query('affine-surface');
new class extends _identity {
    constructor(){
        super(_EdgelessRootPreviewBlockComponent), _initClass();
    }
    static{
        class EdgelessRootPreviewBlockComponent extends (_BlockComponent = BlockComponent) {
            static{
                ({ e: [_init_background, _init_editorViewportSelector, _init_layer, _init_surface, _initProto], c: [_EdgelessRootPreviewBlockComponent, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "background"
                    ],
                    [
                        _dec2,
                        1,
                        "editorViewportSelector"
                    ],
                    [
                        _dec3,
                        1,
                        "layer"
                    ],
                    [
                        _dec4,
                        1,
                        "surface"
                    ]
                ], [
                    _dec
                ], _BlockComponent));
            }
            static{
                this.styles = css`
    affine-edgeless-root {
      -webkit-user-select: none;
      user-select: none;
      display: block;
      height: 100%;
    }

    .widgets-container {
      position: absolute;
      left: 0;
      top: 0;
      contain: size layout;
      z-index: 1;
      height: 100%;
    }

    .edgeless-background {
      height: 100%;
      background-color: var(--affine-background-primary-color);
      background-image: radial-gradient(
        var(--affine-edgeless-grid-color) 1px,
        var(--affine-background-primary-color) 1px
      );
    }

    .edgeless-layer {
      position: absolute;
      top: 0;
      left: 0;
      contain: size layout style;
    }

    @media print {
      .selected {
        background-color: transparent !important;
      }
    }
  `;
            }
            _getLayerViewport(negative = false) {
                const { translateX, translateY, zoom } = this.service.viewport;
                if (negative) {
                    return `scale(${1 / zoom}) translate(${-translateX}px, ${-translateY}px)`;
                }
                return `translate(${translateX}px, ${translateY}px) scale(${zoom})`;
            }
            _initFontLoader() {
                const fontLoader = this.service?.fontLoader;
                assertExists(fontLoader);
                fontLoader.ready.then(()=>{
                    this.surface.refresh();
                }).catch(console.error);
            }
            _initPixelRatioChangeEffect() {
                let media;
                const onPixelRatioChange = ()=>{
                    if (media) {
                        this.service.viewport.onResize();
                        media.removeEventListener('change', onPixelRatioChange);
                    }
                    media = matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
                    media.addEventListener('change', onPixelRatioChange);
                };
                onPixelRatioChange();
                this._disposables.add(()=>{
                    media?.removeEventListener('change', onPixelRatioChange);
                });
            }
            _initResizeEffect() {
                if (!this._viewportElement) {
                    return;
                }
                const resizeObserver = new ResizeObserver((_)=>{
                    if (!this.service || !this.service.selection || !this.service.viewport) {
                        console.error('Service not ready');
                        return;
                    }
                    this.service.selection.set(this.service.selection.surfaceSelections);
                    this.service.viewport.onResize();
                });
                resizeObserver.observe(this.viewportElement);
                this._resizeObserver?.disconnect();
                this._resizeObserver = resizeObserver;
            }
            _initSlotEffects() {
                const { disposables } = this;
                this.disposables.add(this.service.themeObserver.mode$.subscribe(()=>this.surface.refresh()));
                disposables.add(this.service.selection);
            }
            _initViewport() {
                this.service.viewport.setContainer(this);
            }
            connectedCallback() {
                super.connectedCallback();
                this.handleEvent('selectionChange', ()=>{
                    const surface = this.host.selection.value.find((sel)=>sel.is('surface'));
                    if (!surface) return;
                    const el = this.service.getElementById(surface.elements[0]);
                    if (isCanvasElement(el)) {
                        return true;
                    }
                    return;
                });
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                if (this._resizeObserver) {
                    this._resizeObserver.disconnect();
                    this._resizeObserver = null;
                }
            }
            firstUpdated() {
                this._initSlotEffects();
                this._initResizeEffect();
                this._initPixelRatioChangeEffect();
                this._initFontLoader();
                this._initViewport();
                this._disposables.add(this.service.viewport.viewportUpdated.on(()=>{
                    this._refreshLayerViewport();
                }));
                this._refreshLayerViewport();
            }
            getElementsBound() {
                const { service } = this;
                return edgelessElementsBound([
                    ...service.elements,
                    ...service.blocks
                ]);
            }
            renderBlock() {
                return html`
      <div class="edgeless-background edgeless-container">
        <div class="edgeless-layer">
          ${this.renderChildren(this.model)}${this.renderChildren(this.surfaceBlockModel)}
        </div>
      </div>
    `;
            }
            willUpdate(_changedProperties) {
                if (_changedProperties.has('editorViewportSelector')) {
                    this._initResizeEffect();
                }
            }
            get dispatcher() {
                return this.service?.uiEventDispatcher;
            }
            get surfaceBlockModel() {
                return this.model.children.find((child)=>child.flavour === 'affine:surface');
            }
            get viewportElement() {
                if (this._viewportElement) return this._viewportElement;
                this._viewportElement = this.host.closest(this.editorViewportSelector);
                assertExists(this._viewportElement);
                return this._viewportElement;
            }
            #___private_background_1;
            get background() {
                return this.#___private_background_1;
            }
            set background(_v) {
                this.#___private_background_1 = _v;
            }
            #___private_editorViewportSelector_2;
            get editorViewportSelector() {
                return this.#___private_editorViewportSelector_2;
            }
            set editorViewportSelector(_v) {
                this.#___private_editorViewportSelector_2 = _v;
            }
            #___private_layer_3;
            get layer() {
                return this.#___private_layer_3;
            }
            set layer(_v) {
                this.#___private_layer_3 = _v;
            }
            #___private_surface_4;
            get surface() {
                return this.#___private_surface_4;
            }
            set surface(_v) {
                this.#___private_surface_4 = _v;
            }
            constructor(...args){
                super(...args);
                this._refreshLayerViewport = requestThrottledConnectedFrame(()=>{
                    const { zoom, translateX, translateY } = this.service.viewport;
                    const { gap } = getBackgroundGrid(zoom, true);
                    this.background.style.setProperty('background-position', `${translateX}px ${translateY}px`);
                    this.background.style.setProperty('background-size', `${gap}px ${gap}px`);
                    this.layer.style.setProperty('transform', this._getLayerViewport());
                    this.layer.dataset.scale = zoom.toString();
                }, this);
                this._resizeObserver = null;
                this._viewportElement = null;
                this.#___private_background_1 = (_initProto(this), _init_background(this));
                this.#___private_editorViewportSelector_2 = _init_editorViewportSelector(this, '.affine-edgeless-viewport');
                this.#___private_layer_3 = _init_layer(this);
                this.#___private_surface_4 = _init_surface(this);
            }
        }
    }
}();
export { _EdgelessRootPreviewBlockComponent as EdgelessRootPreviewBlockComponent };
