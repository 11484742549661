let _resizeObserver = null;
const elementsMap = new WeakMap();
window._resizeObserverElementsMap = elementsMap;
const getResizeObserver = ()=>_resizeObserver ??= new ResizeObserver((entries)=>{
        entries.forEach((entry)=>{
            const listeners = elementsMap.get(entry.target) ?? [];
            listeners.forEach(({ callback })=>callback(entry));
        });
    });
const removeListener = (element, listener)=>{
    if (!element) return;
    const listeners = elementsMap.get(element) ?? [];
    const observer = getResizeObserver();
    if (listeners.includes(listener)) {
        elementsMap.set(element, listeners.filter((l)=>l !== listener));
    }
    if (elementsMap.get(element)?.length === 0) {
        observer.unobserve(element);
        elementsMap.delete(element);
    }
};
export const observeResize = (element, callback)=>{
    const observer = getResizeObserver();
    if (!elementsMap.has(element)) {
        observer.observe(element);
    }
    const prevListeners = elementsMap.get(element) ?? [];
    const listener = {
        callback,
        dispose: ()=>{}
    };
    listener.dispose = ()=>removeListener(element, listener);
    elementsMap.set(element, [
        ...prevListeners,
        listener
    ]);
    return listener.dispose;
};
