import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { toast } from '@affine/component';
import { FeedPageListHeader } from '@affine/core/components/page-list/docs/page-list-header-feed';
import { useTrashModalHelper } from '@affine/core/hooks/affine/use-trash-modal-helper';
import { useBlockSuiteDocMeta } from '@affine/core/hooks/use-block-suite-page-meta';
import { CollectionService } from '@affine/core/modules/collection';
import { Trans, useI18n } from '@affine/i18n';
import { useService, WorkspaceService } from '@toeverything/infra';
import { useCallback, useMemo, useRef, useState } from 'react';
import { usePageHelper } from '../../blocksuite/block-suite-page-list/utils';
import { ListFloatingToolbar } from '../components/list-floating-toolbar';
import { usePageItemGroupDefinitions } from '../group-definitions';
import { usePageHeaderColsDef } from '../header-col-def';
import { PageOperationCell } from '../operation-cell';
import { PageListItemRenderer } from '../page-group';
import { ListTableHeader } from '../page-header';
import { useFilteredPageMetas } from '../use-filtered-page-metas';
import { VirtualizedList } from '../virtualized-list';
import { CollectionPageListHeader, PageListHeader, TagPageListHeader } from './page-list-header';
const usePageOperationsRenderer = ()=>{
    const t = useI18n();
    const collectionService = useService(CollectionService);
    const removeFromAllowList = useCallback((id)=>{
        collectionService.deletePagesFromCollections([
            id
        ]);
        toast(t['com.affine.collection.removePage.success']());
    }, [
        collectionService,
        t
    ]);
    const pageOperationsRenderer = useCallback((page, isInAllowList)=>{
        return _jsx(PageOperationCell, {
            page: page,
            isInAllowList: isInAllowList,
            onRemoveFromAllowList: ()=>removeFromAllowList(page.id)
        });
    }, [
        removeFromAllowList
    ]);
    return pageOperationsRenderer;
};
export const VirtualizedPageList = ({ tag, collection, feed, feedDocs, filters, listItem, setHideHeaderCreateNewPage, currentFilters, onChangeCurrentFilters, wrapTo })=>{
    const listRef = useRef(null);
    const [showFloatingToolbar, setShowFloatingToolbar] = useState(false);
    const [selectedPageIds, setSelectedPageIds] = useState([]);
    const currentWorkspace = useService(WorkspaceService).workspace;
    const pageMetas = useBlockSuiteDocMeta(currentWorkspace.docCollection);
    const pageOperations = usePageOperationsRenderer();
    const { isPreferredEdgeless } = usePageHelper(currentWorkspace.docCollection);
    const pageHeaderColsDef = usePageHeaderColsDef();
    const filteredPageMetas = useFilteredPageMetas(pageMetas, {
        filters,
        collection
    });
    const pageMetasToRender = useMemo(()=>{
        if (listItem) {
            return listItem;
        }
        return filteredPageMetas;
    }, [
        filteredPageMetas,
        listItem
    ]);
    const filteredSelectedPageIds = useMemo(()=>{
        const ids = pageMetasToRender.map((page)=>page.id);
        return selectedPageIds.filter((id)=>ids.includes(id));
    }, [
        pageMetasToRender,
        selectedPageIds
    ]);
    const hideFloatingToolbar = useCallback(()=>{
        listRef.current?.toggleSelectable();
    }, []);
    const pageOperationRenderer = useCallback((item)=>{
        const page = item;
        const isInAllowList = collection?.allowList?.includes(page.id);
        return pageOperations(page, isInAllowList);
    }, [
        collection,
        pageOperations
    ]);
    const pageHeaderRenderer = useCallback(()=>{
        return _jsx(ListTableHeader, {
            headerCols: pageHeaderColsDef
        });
    }, [
        pageHeaderColsDef
    ]);
    const pageItemRenderer = useCallback((item)=>{
        return _jsx(PageListItemRenderer, {
            ...item
        });
    }, []);
    const heading = useMemo(()=>{
        if (tag) {
            return _jsx(TagPageListHeader, {
                workspaceId: currentWorkspace.id,
                tag: tag
            });
        }
        if (collection) {
            return _jsx(CollectionPageListHeader, {
                workspaceId: currentWorkspace.id,
                collection: collection
            });
        }
        if (feed) {
            return _jsx(FeedPageListHeader, {
                workspaceId: currentWorkspace.id,
                collection: feed,
                propertiesMeta: currentWorkspace.docCollection.meta.properties,
                currentFilters: currentFilters,
                onChangeCurrentFilters: onChangeCurrentFilters
            });
        }
        return _jsx(PageListHeader, {
            currentFilters: currentFilters,
            onChangeCurrentFilters: onChangeCurrentFilters
        });
    }, [
        collection,
        currentFilters,
        currentWorkspace.docCollection,
        currentWorkspace.id,
        feed,
        onChangeCurrentFilters,
        tag
    ]);
    const { setTrashModal } = useTrashModalHelper(currentWorkspace.docCollection);
    const handleMultiDelete = useCallback(()=>{
        if (filteredSelectedPageIds.length === 0) {
            return;
        }
        const pageNameMapping = Object.fromEntries(pageMetas.map((meta)=>[
                meta.id,
                meta.title
            ]));
        const pageNames = filteredSelectedPageIds.map((id)=>pageNameMapping[id] ?? '');
        setTrashModal({
            open: true,
            pageIds: filteredSelectedPageIds,
            pageTitles: pageNames
        });
        hideFloatingToolbar();
    }, [
        filteredSelectedPageIds,
        hideFloatingToolbar,
        pageMetas,
        setTrashModal
    ]);
    const group = usePageItemGroupDefinitions();
    return _jsxs(_Fragment, {
        children: [
            _jsx(VirtualizedList, {
                ref: listRef,
                selectable: "toggle",
                draggable: true,
                atTopThreshold: 80,
                atTopStateChange: setHideHeaderCreateNewPage,
                onSelectionActiveChange: setShowFloatingToolbar,
                heading: heading,
                groupBy: group,
                selectedIds: filteredSelectedPageIds,
                onSelectedIdsChange: setSelectedPageIds,
                items: pageMetasToRender,
                rowAsLink: true,
                wrapTo: wrapTo,
                isPreferredEdgeless: isPreferredEdgeless,
                docCollection: currentWorkspace.docCollection,
                operationsRenderer: pageOperationRenderer,
                itemRenderer: pageItemRenderer,
                headerRenderer: pageHeaderRenderer
            }),
            _jsx(ListFloatingToolbar, {
                open: showFloatingToolbar,
                onDelete: handleMultiDelete,
                onClose: hideFloatingToolbar,
                content: _jsxs(Trans, {
                    i18nKey: "com.affine.page.toolbar.selected",
                    count: filteredSelectedPageIds.length,
                    children: [
                        _jsx("div", {
                            style: {
                                color: 'var(--affine-text-secondary-color)'
                            },
                            children: {
                                count: filteredSelectedPageIds.length
                            }
                        }),
                        "selected"
                    ]
                })
            })
        ]
    });
};
