import { SurfaceElementModel } from './base.js';
import { BrushElementModel } from './brush.js';
import { ConnectorElementModel } from './connector.js';
import { GroupElementModel } from './group.js';
import { MindmapElementModel } from './mindmap.js';
import { ShapeElementModel } from './shape.js';
import { TextElementModel } from './text.js';
export const elementsCtorMap = {
    group: GroupElementModel,
    connector: ConnectorElementModel,
    shape: ShapeElementModel,
    brush: BrushElementModel,
    text: TextElementModel,
    mindmap: MindmapElementModel
};
export { BrushElementModel, ConnectorElementModel, GroupElementModel, MindmapElementModel, ShapeElementModel, SurfaceElementModel, TextElementModel };
export var CanvasElementType;
(function(CanvasElementType) {
    CanvasElementType["BRUSH"] = "brush";
    CanvasElementType["CONNECTOR"] = "connector";
    CanvasElementType["GROUP"] = "group";
    CanvasElementType["MINDMAP"] = "mindmap";
    CanvasElementType["SHAPE"] = "shape";
    CanvasElementType["TEXT"] = "text";
})(CanvasElementType || (CanvasElementType = {}));
export function isCanvasElementType(type) {
    return type.toLocaleUpperCase() in CanvasElementType;
}
