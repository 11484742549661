import { useCallback, useContext, useMemo } from 'react';
import { NavigateContext, router } from '../router';
export var RouteLogic;
(function(RouteLogic) {
    RouteLogic["REPLACE"] = "replace";
    RouteLogic["PUSH"] = "push";
})(RouteLogic || (RouteLogic = {}));
function defaultNavigate(to, option) {
    setTimeout(()=>{
        router?.navigate(to, option).catch((err)=>{
            console.error('Failed to navigate', err);
        });
    }, 100);
}
export function useNavigateHelper() {
    const navigate = useContext(NavigateContext) ?? defaultNavigate;
    const jumpToPage = useCallback((workspaceId, pageId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/${pageId}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToPageBlock = useCallback((workspaceId, pageId, blockId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/${pageId}#${blockId}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToCollections = useCallback((workspaceId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/collection`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToTags = useCallback((workspaceId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/tag`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToTag = useCallback((workspaceId, tagId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/tag/${tagId}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToCollection = useCallback((workspaceId, collectionId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/collection/${collectionId}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToManageFeeds = useCallback((workspaceId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/feed/manage`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToFeedsDocs = useCallback((workspaceId, status = 'unseen', feedId, logic = "push")=>{
        if (feedId) {
            return navigate(`/workspace/${workspaceId}/feed/${feedId}/${status}`, {
                replace: logic === "replace"
            });
        }
        return navigate(`/workspace/${workspaceId}/feed/${status}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToFeed = useCallback((workspaceId, feedId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/feed/${feedId}/unseen`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToSubPath = useCallback((workspaceId, subPath, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/${subPath}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const openPage = useCallback((workspaceId, pageId)=>{
        return jumpToPage(workspaceId, pageId);
    }, [
        jumpToPage
    ]);
    const jumpToIndex = useCallback((logic = "push", opt)=>{
        return navigate({
            pathname: '/',
            search: opt?.search
        }, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpTo404 = useCallback((logic = "push")=>{
        return navigate('/404', {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToExpired = useCallback((logic = "push")=>{
        return navigate('/expired', {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToSignIn = useCallback((redirectUri, logic = "push", otherOptions, params)=>{
        const searchParams = new URLSearchParams();
        if (redirectUri) {
            searchParams.set('redirect_uri', encodeURIComponent(redirectUri));
        }
        if (params) {
            for(const key in params)searchParams.set(key, params[key]);
        }
        return navigate('/signIn' + (searchParams.toString() ? '?' + searchParams.toString() : ''), {
            replace: logic === "replace",
            ...otherOptions
        });
    }, [
        navigate
    ]);
    const openInApp = useCallback((schema, path)=>{
        const encodedUrl = encodeURIComponent(`${schema}://${path}`);
        return navigate(`/open-app/url?schema=${schema}&url=${encodedUrl}`);
    }, [
        navigate
    ]);
    return useMemo(()=>({
            jumpToPage,
            jumpToPageBlock,
            jumpToSubPath,
            jumpToIndex,
            jumpTo404,
            openPage,
            jumpToExpired,
            jumpToSignIn,
            jumpToCollection,
            jumpToFeed,
            jumpToFeedsDocs,
            jumpToManageFeeds,
            jumpToCollections,
            jumpToTags,
            jumpToTag,
            openInApp
        }), [
        jumpToPage,
        jumpToPageBlock,
        jumpToSubPath,
        jumpToIndex,
        jumpTo404,
        openPage,
        jumpToExpired,
        jumpToSignIn,
        jumpToCollection,
        jumpToFeed,
        jumpToFeedsDocs,
        jumpToManageFeeds,
        jumpToCollections,
        jumpToTags,
        jumpToTag,
        openInApp
    ]);
}
