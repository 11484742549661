import { jsx as _jsx } from "react/jsx-runtime";
import { EditorLoading } from '@affine/component/page-detail-skeleton';
import { assertExists } from '@blocksuite/global/utils';
import { use } from 'foxact/use';
import { forwardRef, memo, Suspense, useCallback, useEffect, useRef } from 'react';
import { BlocksuiteEditorContainer } from './blocksuite-editor-container';
import { NoPageRootError } from './no-page-error';
function usePageRoot(page) {
    if (!page.ready) {
        page.load();
    }
    if (!page.root) {
        use(new Promise((resolve, reject)=>{
            const disposable = page.slots.rootAdded.once(()=>{
                resolve();
            });
            window.setTimeout(()=>{
                disposable.dispose();
                reject(new NoPageRootError(page));
            }, 20 * 1000);
        }));
    }
    return page.root;
}
const BlockSuiteEditorImpl = forwardRef(function BlockSuiteEditorImpl({ mode, page, className, defaultSelectedBlockId, onLoadEditor, shared, style }, ref) {
    usePageRoot(page);
    assertExists(page, 'page should not be null');
    const editorDisposeRef = useRef(()=>{});
    const editorRef = useRef(null);
    const onRefChange = useCallback((editor)=>{
        editorRef.current = editor;
        if (ref) {
            if (typeof ref === 'function') {
                ref(editor);
            } else {
                ref.current = editor;
            }
        }
        if (editor && onLoadEditor) {
            editorDisposeRef.current = onLoadEditor(editor);
        }
    }, [
        onLoadEditor,
        ref
    ]);
    useEffect(()=>{
        const disposable = page.slots.blockUpdated.once(()=>{
            page.collection.setDocMeta(page.id, {
                updatedDate: Date.now()
            });
        });
        return ()=>{
            disposable.dispose();
        };
    }, [
        page
    ]);
    useEffect(()=>{
        return ()=>{
            editorDisposeRef.current();
        };
    }, []);
    return _jsx(BlocksuiteEditorContainer, {
        mode: mode,
        page: page,
        shared: shared,
        ref: onRefChange,
        className: className,
        style: style,
        defaultSelectedBlockId: defaultSelectedBlockId
    });
});
export const BlockSuiteEditor = memo(forwardRef(function BlockSuiteEditor(props, ref) {
    return _jsx(Suspense, {
        fallback: _jsx(EditorLoading, {}),
        children: _jsx(BlockSuiteEditorImpl, {
            ref: ref,
            ...props
        }, props.page.id)
    });
}));
BlockSuiteEditor.displayName = 'BlockSuiteEditor';
