import { isStrictUrl } from '../../utils/url.js';
const EDGE_IGNORED_ATTRIBUTES = [
    'code',
    'link'
];
const GLOBAL_IGNORED_ATTRIBUTES = [];
const autoIdentifyLink = (ctx)=>{
    if (ctx.data !== ' ') {
        return;
    }
    if (ctx.attributes?.link) {
        if (ctx.inlineRange.index === ctx.inlineEditor.yText.length) {
            delete ctx.attributes['link'];
        }
        return;
    }
    const lineInfo = ctx.inlineEditor.getLine(ctx.inlineRange.index);
    if (!lineInfo) {
        return;
    }
    const { line, lineIndex, rangeIndexRelatedToLine } = lineInfo;
    if (lineIndex !== 0) {
        return;
    }
    const verifyData = line.vTextContent.slice(0, rangeIndexRelatedToLine).split(' ');
    const verifyStr = verifyData[verifyData.length - 1];
    const isUrl = isStrictUrl(verifyStr);
    if (!isUrl) {
        return;
    }
    const startIndex = ctx.inlineRange.index - verifyStr.length;
    ctx.inlineEditor.formatText({
        index: startIndex,
        length: verifyStr.length
    }, {
        link: verifyStr
    });
};
function handleExtendedAttributes(ctx) {
    const { data, inlineEditor, inlineRange } = ctx;
    const deltas = inlineEditor.getDeltasByInlineRange(inlineRange);
    if (data && data.length > 0 && data !== '\n') {
        if ((deltas.length > 1 || deltas.length === 1 && inlineRange.index !== 0) && !inlineEditor.isEmbed(deltas[0][0])) {
            const { attributes } = deltas[0][0];
            if (deltas.length !== 1 || inlineRange.index === inlineEditor.yText.length) {
                EDGE_IGNORED_ATTRIBUTES.forEach((attr)=>{
                    delete attributes?.[attr];
                });
            }
            GLOBAL_IGNORED_ATTRIBUTES.forEach((attr)=>{
                delete attributes?.[attr];
            });
            ctx.attributes = attributes ?? {};
        }
    }
    return ctx;
}
export const onVBeforeinput = (ctx)=>{
    handleExtendedAttributes(ctx);
    autoIdentifyLink(ctx);
};
export const onVCompositionEnd = (ctx)=>{
    handleExtendedAttributes(ctx);
};
