import { jsx as _jsx } from "react/jsx-runtime";
import { SidebarIcon } from '@blocksuite/icons/rc';
import { appSidebarOpenAtom } from '../components/app-sidebar';
import { track } from '../mixpanel';
import { registerAffineCommand } from './registry';
export function registerAffineLayoutCommands({ t, store }) {
    const unsubs = [];
    unsubs.push(registerAffineCommand({
        id: 'affine:toggle-left-sidebar',
        category: 'affine:layout',
        icon: _jsx(SidebarIcon, {}),
        label: ()=>store.get(appSidebarOpenAtom) ? t['com.affine.cmdk.affine.left-sidebar.collapse']() : t['com.affine.cmdk.affine.left-sidebar.expand'](),
        keyBinding: {
            binding: '$mod+/'
        },
        run () {
            track.$.navigationPanel.$.toggle();
            store.set(appSidebarOpenAtom, (v)=>!v);
        }
    }));
    return ()=>{
        unsubs.forEach((unsub)=>unsub());
    };
}
