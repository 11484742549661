import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@affine/component';
import { RightSidebarIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { useAtomValue } from 'jotai';
import { Suspense, useCallback } from 'react';
import { AffineErrorBoundary } from '../../../components/affine/affine-error-boundary';
import { appSidebarOpenAtom } from '../../../components/app-sidebar/index.jotai';
import { SidebarSwitch } from '../../../components/app-sidebar/sidebar-header/sidebar-switch';
import { ViewService } from '../services/view';
import { WorkbenchService } from '../services/workbench';
import * as styles from './route-container.css';
import { useViewPosition } from './use-view-position';
import { ViewBodyTarget, ViewHeaderTarget } from './view-islands';
export const ToggleButton = ({ onToggle, className, show })=>{
    return _jsx(IconButton, {
        size: "24",
        onClick: onToggle,
        className: className,
        "data-show": show,
        children: _jsx(RightSidebarIcon, {})
    });
};
export const RouteContainer = ({ route })=>{
    const viewPosition = useViewPosition();
    const leftSidebarOpen = useAtomValue(appSidebarOpenAtom);
    const workbench = useService(WorkbenchService).workbench;
    const view = useService(ViewService).view;
    const sidebarOpen = useLiveData(workbench.sidebarOpen$);
    const handleToggleSidebar = useCallback(()=>{
        workbench.toggleSidebar();
    }, [
        workbench
    ]);
    const currentPath = useLiveData(useService(WorkbenchService).workbench.location$.map((location)=>location.pathname));
    const isFeed = currentPath.includes('/feed/');
    return _jsxs("div", {
        className: styles.root,
        children: [
            _jsxs("div", {
                className: styles.header,
                style: isFeed ? {
                    display: 'none'
                } : {},
                children: [
                    viewPosition.isFirst && !environment.isDesktop && _jsx(SidebarSwitch, {
                        show: !leftSidebarOpen,
                        className: styles.leftSidebarButton
                    }),
                    _jsx(ViewHeaderTarget, {
                        viewId: view.id,
                        className: styles.viewHeaderContainer
                    }),
                    viewPosition.isLast && !environment.isDesktop && _jsx(ToggleButton, {
                        show: !sidebarOpen,
                        className: styles.rightSidebarButton,
                        onToggle: handleToggleSidebar
                    })
                ]
            }),
            _jsx(AffineErrorBoundary, {
                children: _jsx(Suspense, {
                    children: _jsx(route.Component, {})
                })
            }),
            _jsx(ViewBodyTarget, {
                viewId: view.id,
                className: styles.viewBodyContainer
            })
        ]
    });
};
