export class NetworkError extends Error {
    constructor(originError){
        super(`Network error: ${originError.message}`);
        this.originError = originError;
        this.stack = originError.stack;
    }
}
export function isNetworkError(error) {
    return error instanceof NetworkError;
}
export class BackendError extends Error {
    constructor(originError){
        super(`Server error: ${originError.message}`);
        this.originError = originError;
        this.stack = originError.stack;
    }
}
export function isBackendError(error) {
    return error instanceof BackendError;
}
