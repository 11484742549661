import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { withUnit } from '../../utils/with-unit';
import { loading, speedVar } from './styles.css';
export const Loading = ({ size, speed = 1.2, progress = 0.2 })=>{
    const sizeWithUnit = size ? withUnit(size, 'px') : '16px';
    return _jsxs("svg", {
        className: loading,
        width: sizeWithUnit,
        height: sizeWithUnit,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        style: {
            ...assignInlineVars({
                [speedVar]: `${speed}s`
            })
        },
        children: [
            _jsx("circle", {
                cx: 12,
                cy: 12,
                r: 8,
                strokeWidth: 4,
                stroke: "var(--affine-black-10)"
            }),
            _jsx("circle", {
                cx: 12,
                cy: 12,
                r: 8,
                strokeWidth: 4,
                stroke: "var(--affine-primary-color)",
                strokeDasharray: `${2 * Math.PI * 8 * progress} ${2 * Math.PI * 8 * (1 - progress)}`
            })
        ]
    });
};
