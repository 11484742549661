export const ZOOM_MAX = 6.0;
export const ZOOM_MIN = 0.1;
export const ZOOM_STEP = 0.25;
export const ZOOM_INITIAL = 1.0;
export const ZOOM_WHEEL_STEP = 0.1;
export const GRID_SIZE = 3000;
export const GRID_GAP_MIN = 10;
export const GRID_GAP_MAX = 50;
export const DEFAULT_ROUGHNESS = 1.4;
export const DEFAULT_CENTRAL_AREA_RATIO = 0.3;
export var ShapeStyle;
(function(ShapeStyle) {
    ShapeStyle["General"] = "General";
    ShapeStyle["Scribbled"] = "Scribbled";
})(ShapeStyle || (ShapeStyle = {}));
export var StrokeStyle;
(function(StrokeStyle) {
    StrokeStyle["Dash"] = "dash";
    StrokeStyle["None"] = "none";
    StrokeStyle["Solid"] = "solid";
})(StrokeStyle || (StrokeStyle = {}));
export var TextAlign;
(function(TextAlign) {
    TextAlign["Center"] = "center";
    TextAlign["Left"] = "left";
    TextAlign["Right"] = "right";
})(TextAlign || (TextAlign = {}));
export var TextVerticalAlign;
(function(TextVerticalAlign) {
    TextVerticalAlign["Bottom"] = "bottom";
    TextVerticalAlign["Center"] = "center";
    TextVerticalAlign["Top"] = "top";
})(TextVerticalAlign || (TextVerticalAlign = {}));
export var TextResizing;
(function(TextResizing) {
    TextResizing[TextResizing["AUTO_WIDTH"] = 0] = "AUTO_WIDTH";
    TextResizing[TextResizing["AUTO_HEIGHT"] = 1] = "AUTO_HEIGHT";
})(TextResizing || (TextResizing = {}));
export var FontWeight;
(function(FontWeight) {
    FontWeight["Bold"] = "700";
    FontWeight["Light"] = "300";
    FontWeight["Medium"] = "500";
    FontWeight["Regular"] = "400";
    FontWeight["SemiBold"] = "600";
})(FontWeight || (FontWeight = {}));
export var FontStyle;
(function(FontStyle) {
    FontStyle["Italic"] = "italic";
    FontStyle["Normal"] = "normal";
})(FontStyle || (FontStyle = {}));
export var FontFamily;
(function(FontFamily) {
    FontFamily["BebasNeue"] = "blocksuite:surface:BebasNeue";
    FontFamily["Inter"] = "blocksuite:surface:Inter";
    FontFamily["Kalam"] = "blocksuite:surface:Kalam";
    FontFamily["Lora"] = "blocksuite:surface:Lora";
    FontFamily["OrelegaOne"] = "blocksuite:surface:OrelegaOne";
    FontFamily["Poppins"] = "blocksuite:surface:Poppins";
    FontFamily["Satoshi"] = "blocksuite:surface:Satoshi";
})(FontFamily || (FontFamily = {}));
export const FontFamilyMap = {
    ["blocksuite:surface:Inter"]: 'Inter',
    ["blocksuite:surface:Kalam"]: 'Kalam',
    ["blocksuite:surface:Satoshi"]: 'Satoshi',
    ["blocksuite:surface:Poppins"]: 'Poppins',
    ["blocksuite:surface:Lora"]: 'Lora',
    ["blocksuite:surface:BebasNeue"]: 'Bebas Neue',
    ["blocksuite:surface:OrelegaOne"]: 'Orelega One'
};
export const FontFamilyList = Object.entries(FontFamilyMap);
export const AffineCanvasTextFonts = [
    {
        font: "blocksuite:surface:Inter",
        url: 'https://cdn.wemem.ai/fonts/Inter-Light-BETA.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://cdn.wemem.ai/fonts/Inter-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://cdn.wemem.ai/fonts/Inter-SemiBold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://cdn.wemem.ai/fonts/Inter-LightItalic-BETA.woff',
        weight: "300",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://cdn.wemem.ai/fonts/Inter-Italic.woff',
        weight: "400",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://cdn.wemem.ai/fonts/Inter-SemiBoldItalic.woff',
        weight: "600",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Kalam",
        url: 'https://cdn.wemem.ai/fonts/Kalam-Light.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Kalam",
        url: 'https://cdn.wemem.ai/fonts/Kalam-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Kalam",
        url: 'https://cdn.wemem.ai/fonts/Kalam-Bold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://cdn.wemem.ai/fonts/Satoshi-Light.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://cdn.wemem.ai/fonts/Satoshi-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://cdn.wemem.ai/fonts/Satoshi-Bold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://cdn.wemem.ai/fonts/Satoshi-LightItalic.woff',
        weight: "300",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://cdn.wemem.ai/fonts/Satoshi-Italic.woff',
        weight: "400",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://cdn.wemem.ai/fonts/Satoshi-BoldItalic.woff',
        weight: "600",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://cdn.wemem.ai/fonts/Poppins-Light.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://cdn.wemem.ai/fonts/Poppins-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://cdn.wemem.ai/fonts/Poppins-Medium.woff',
        weight: "500",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://cdn.wemem.ai/fonts/Poppins-SemiBold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://cdn.wemem.ai/fonts/Poppins-LightItalic.woff',
        weight: "300",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://cdn.wemem.ai/fonts/Poppins-Italic.woff',
        weight: "400",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://cdn.wemem.ai/fonts/Poppins-SemiBoldItalic.woff',
        weight: "600",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Lora",
        url: 'https://cdn.wemem.ai/fonts/Lora-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Lora",
        url: 'https://cdn.wemem.ai/fonts/Lora-Bold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Lora",
        url: 'https://cdn.wemem.ai/fonts/Lora-Italic.woff',
        weight: "400",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Lora",
        url: 'https://cdn.wemem.ai/fonts/Lora-BoldItalic.woff',
        weight: "600",
        style: "italic"
    },
    {
        font: "blocksuite:surface:BebasNeue",
        url: 'https://cdn.wemem.ai/fonts/BebasNeue-Light.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:BebasNeue",
        url: 'https://cdn.wemem.ai/fonts/BebasNeue-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:OrelegaOne",
        url: 'https://cdn.wemem.ai/fonts/OrelegaOne-Regular.woff',
        weight: "400",
        style: "normal"
    }
];
export const CommunityCanvasTextFonts = [
    {
        font: "blocksuite:surface:Inter",
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-Light-BETA.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-SemiBold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-LightItalic-BETA.woff',
        weight: "300",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-Italic.woff',
        weight: "400",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Inter",
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-SemiBoldItalic.woff',
        weight: "600",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Kalam",
        url: 'https://fonts.cdnfonts.com/s/13130/Kalam-Light.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Kalam",
        url: 'https://fonts.cdnfonts.com/s/13130/Kalam-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Kalam",
        url: 'https://fonts.cdnfonts.com/s/13130/Kalam-Bold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-Light.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-Bold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-LightItalic.woff',
        weight: "300",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-Italic.woff',
        weight: "400",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Satoshi",
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-BoldItalic.woff',
        weight: "600",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-Light.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-Medium.woff',
        weight: "500",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-SemiBold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-LightItalic.woff',
        weight: "300",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-Italic.woff',
        weight: "400",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Poppins",
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-SemiBoldItalic.woff',
        weight: "600",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Lora",
        url: 'https://fonts.cdnfonts.com/s/29883/Lora-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Lora",
        url: 'https://fonts.cdnfonts.com/s/29883/Lora-Bold.woff',
        weight: "600",
        style: "normal"
    },
    {
        font: "blocksuite:surface:Lora",
        url: 'https://fonts.cdnfonts.com/s/29883/Lora-Italic.woff',
        weight: "400",
        style: "italic"
    },
    {
        font: "blocksuite:surface:Lora",
        url: 'https://fonts.cdnfonts.com/s/29883/Lora-BoldItalic.woff',
        weight: "600",
        style: "italic"
    },
    {
        font: "blocksuite:surface:BebasNeue",
        url: 'https://fonts.cdnfonts.com/s/14902/BebasNeue%20Light.woff',
        weight: "300",
        style: "normal"
    },
    {
        font: "blocksuite:surface:BebasNeue",
        url: 'https://fonts.cdnfonts.com/s/14902/BebasNeue-Regular.woff',
        weight: "400",
        style: "normal"
    },
    {
        font: "blocksuite:surface:OrelegaOne",
        url: 'https://fonts.cdnfonts.com/s/93179/OrelegaOne-Regular.woff',
        weight: "400",
        style: "normal"
    }
];
