function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _init__isHover, _init__isMoving, _init_current, _init_edgeless, _init_selectedRect, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { Vec } from '@blocksuite/global/utils';
import { DisposableGroup, assertExists } from '@blocksuite/global/utils';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { AutoCompleteArrowIcon, MindMapChildIcon, MindMapSiblingIcon, NoteAutoCompleteIcon } from '../../../../_common/icons/index.js';
import { handleNativeRangeAtPoint } from '../../../../_common/utils/index.js';
import { ConnectorMode } from '../../../../surface-block/element-model/connector.js';
import { MindmapElementModel } from '../../../../surface-block/element-model/mindmap.js';
import { shapeMethods } from '../../../../surface-block/element-model/shape.js';
import { LayoutType } from '../../../../surface-block/element-model/utils/mindmap/layout.js';
import { DEFAULT_SHAPE_STROKE_COLOR } from '../../../../surface-block/elements/shape/consts.js';
import { ShapeElementModel } from '../../../../surface-block/index.js';
import { CanvasElementType, Overlay } from '../../../../surface-block/index.js';
import { ConnectorPathGenerator } from '../../../../surface-block/managers/connector-manager.js';
import { NOTE_INIT_HEIGHT } from '../../utils/consts.js';
import { isNoteBlock } from '../../utils/query.js';
import { mountShapeTextEditor } from '../../utils/text.js';
import { DEFAULT_CONNECTOR_COLOR } from '../panel/color-panel.js';
import './auto-complete-panel.js';
import { EdgelessAutoCompletePanel } from './auto-complete-panel.js';
import { Direction, MAIN_GAP, createEdgelessElement, getPosition, isShape, nextBound } from './utils.js';
class AutoCompleteOverlay extends Overlay {
    render(ctx, _rc) {
        if (this.linePoints.length && this.renderShape) {
            ctx.setLineDash([
                2,
                2
            ]);
            ctx.strokeStyle = this.stroke;
            ctx.beginPath();
            this.linePoints.forEach((p, index)=>{
                if (index === 0) ctx.moveTo(p[0], p[1]);
                else ctx.lineTo(p[0], p[1]);
            });
            ctx.stroke();
            this.renderShape(ctx);
            ctx.stroke();
        }
    }
    constructor(...args){
        super(...args);
        this.linePoints = [];
        this.renderShape = null;
        this.stroke = '';
    }
}
let _EdgelessAutoComplete;
_dec = customElement('edgeless-auto-complete'), _dec1 = state(), _dec2 = state(), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EdgelessAutoComplete), _initClass();
    }
    static{
        class EdgelessAutoComplete extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init__isHover, _init__isMoving, _init_current, _init_edgeless, _init_selectedRect, _initProto], c: [_EdgelessAutoComplete, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_isHover"
                    ],
                    [
                        _dec2,
                        1,
                        "_isMoving"
                    ],
                    [
                        _dec3,
                        1,
                        "current"
                    ],
                    [
                        _dec4,
                        1,
                        "edgeless"
                    ],
                    [
                        _dec5,
                        1,
                        "selectedRect"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    .edgeless-auto-complete-container {
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }
    .edgeless-auto-complete-arrow-wrapper {
      width: 72px;
      height: 44px;
      position: absolute;
      z-index: 1;
      pointer-events: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .edgeless-auto-complete-arrow-wrapper.hidden {
      display: none;
    }
    .edgeless-auto-complete-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 19px;
      cursor: pointer;
      pointer-events: auto;
      transition:
        background 0.3s linear,
        box-shadow 0.2s linear;
    }

    .edgeless-auto-complete-arrow-wrapper:hover
      > .edgeless-auto-complete-arrow {
      border: 1px solid var(--affine-border-color);
      box-shadow: var(--affine-shadow-1);
      background: var(--affine-white);
    }

    .edgeless-auto-complete-arrow-wrapper
      > .edgeless-auto-complete-arrow:hover {
      border: 1px solid var(--affine-white-10);
      box-shadow: var(--affine-shadow-1);
      background: var(--affine-primary-color);
    }

    .edgeless-auto-complete-arrow-wrapper.mindmap
      > .edgeless-auto-complete-arrow {
      border: 1px solid var(--affine-border-color);
      box-shadow: var(--affine-shadow-1);
      background: var(--affine-white);

      transition:
        background 0.3s linear,
        color 0.2s linear;
    }

    .edgeless-auto-complete-arrow-wrapper.mindmap
      > .edgeless-auto-complete-arrow:hover {
      border: 1px solid var(--affine-white-10);
      box-shadow: var(--affine-shadow-1);
      background: var(--affine-primary-color);
    }

    .edgeless-auto-complete-arrow svg {
      fill: #77757d;
      color: #77757d;
    }
    .edgeless-auto-complete-arrow:hover svg {
      fill: #ffffff;
      color: #ffffff;
    }
  `;
            }
            _addConnector(source, target) {
                const { current, edgeless } = this;
                let stroke = DEFAULT_CONNECTOR_COLOR;
                if (isShape(current)) {
                    if (typeof current.strokeColor === 'object') {
                        stroke = {
                            ...current.strokeColor
                        };
                    } else {
                        stroke = current.strokeColor;
                    }
                } else {
                    if (typeof current.background === 'object') {
                        stroke = {
                            ...current.background
                        };
                    } else {
                        let tag = current.background.split('-').pop();
                        if (!tag || tag === 'gray') tag = 'grey';
                        stroke = `--affine-palette-line-${tag}`;
                    }
                }
                const id = edgeless.service.addElement(CanvasElementType.CONNECTOR, {
                    mode: ConnectorMode.Orthogonal,
                    strokeWidth: 2,
                    stroke,
                    source,
                    target
                });
                return edgeless.service.getElementById(id);
            }
            _addMindmapNode(direction, target) {
                const mindmap = this.current.group;
                if (!(mindmap instanceof MindmapElementModel)) return;
                const parentNode = target === 'sibling' ? mindmap.getParentNode(this.current.id) ?? this.current : this.current;
                const newNode = mindmap.addNode(parentNode.id, target === 'sibling' ? this.current.id : undefined, undefined, undefined, direction);
                requestAnimationFrame(()=>{
                    mountShapeTextEditor(this.edgeless.service.getElementById(newNode), this.edgeless);
                });
            }
            _computeLine(type, curShape, nextBound) {
                const startBound = this.current.elementBound;
                const { startPosition, endPosition } = getPosition(type);
                const nextShape = {
                    xywh: nextBound.serialize(),
                    rotate: curShape.rotate,
                    shapeType: curShape.shapeType
                };
                const startPoint = curShape.getRelativePointLocation(startPosition);
                const endPoint = curShape.getRelativePointLocation.call(nextShape, endPosition);
                return this._pathGenerator.generateOrthogonalConnectorPath({
                    startBound,
                    endBound: nextBound,
                    startPoint,
                    endPoint
                });
            }
            _computeNextBound(type) {
                if (isShape(this.current)) {
                    const connectedShapes = this._getConnectedElements(this.current).filter((e)=>e instanceof ShapeElementModel);
                    return nextBound(type, this.current, connectedShapes);
                } else {
                    const bound = this.current.elementBound;
                    switch(type){
                        case Direction.Right:
                            {
                                bound.x += bound.w + MAIN_GAP;
                                break;
                            }
                        case Direction.Bottom:
                            {
                                bound.y += bound.h + MAIN_GAP;
                                break;
                            }
                        case Direction.Left:
                            {
                                bound.x -= bound.w + MAIN_GAP;
                                break;
                            }
                        case Direction.Top:
                            {
                                bound.y -= bound.h + MAIN_GAP;
                                break;
                            }
                    }
                    return bound;
                }
            }
            _createAutoCompletePanel(e, connector) {
                if (!this.canShowAutoComplete) return;
                const position = this.edgeless.service.viewport.toModelCoord(e.clientX, e.clientY);
                const autoCompletePanel = new EdgelessAutoCompletePanel(position, this.edgeless, this.current, connector);
                this.edgeless.append(autoCompletePanel);
            }
            _generateElementOnClick(type) {
                const { doc, service } = this.edgeless;
                const bound = this._computeNextBound(type);
                const id = createEdgelessElement(this.edgeless, this.current, bound);
                if (isShape(this.current)) {
                    const { startPosition, endPosition } = getPosition(type);
                    this._addConnector({
                        id: this.current.id,
                        position: startPosition
                    }, {
                        id,
                        position: endPosition
                    });
                    mountShapeTextEditor(service.getElementById(id), this.edgeless);
                } else {
                    const model = doc.getBlockById(id);
                    assertExists(model);
                    const [x, y] = service.viewport.toViewCoord(bound.center[0], bound.y + NOTE_INIT_HEIGHT / 2);
                    requestAnimationFrame(()=>{
                        handleNativeRangeAtPoint(x, y);
                    });
                }
                this.edgeless.service.selection.set({
                    elements: [
                        id
                    ],
                    editing: true
                });
                this.removeOverlay();
            }
            _getConnectedElements(element) {
                const service = this.edgeless.service;
                return service.getConnectors(element.id).reduce((prev, current)=>{
                    if (current.target.id === element.id && current.source.id) {
                        prev.push(service.getElementById(current.source.id));
                    }
                    if (current.source.id === element.id && current.target.id) {
                        prev.push(service.getElementById(current.target.id));
                    }
                    return prev;
                }, []);
            }
            _getMindmapButtons() {
                const mindmap = this.current.group;
                const mindmapDirection = this.current instanceof ShapeElementModel && mindmap instanceof MindmapElementModel ? mindmap.getLayoutDir(this.current.id) : null;
                const isRoot = mindmap?.tree.id === this.current.id;
                let result = null;
                switch(mindmapDirection){
                    case LayoutType.LEFT:
                        result = [
                            [
                                Direction.Left,
                                'child',
                                LayoutType.LEFT
                            ]
                        ];
                        if (!isRoot) {
                            result.push([
                                Direction.Bottom,
                                'sibling',
                                mindmapDirection
                            ]);
                        }
                        return result;
                    case LayoutType.RIGHT:
                        result = [
                            [
                                Direction.Right,
                                'child',
                                LayoutType.RIGHT
                            ]
                        ];
                        if (!isRoot) {
                            result.push([
                                Direction.Bottom,
                                'sibling',
                                mindmapDirection
                            ]);
                        }
                        return result;
                    case LayoutType.BALANCE:
                        result = [
                            [
                                Direction.Right,
                                'child',
                                LayoutType.RIGHT
                            ],
                            [
                                Direction.Left,
                                'child',
                                LayoutType.LEFT
                            ]
                        ];
                        return result;
                    default:
                        result = null;
                }
                return result;
            }
            _renderArrow() {
                const isShape = this.current instanceof ShapeElementModel;
                const { selectedRect } = this;
                const { zoom } = this.edgeless.service.viewport;
                const width = 72;
                const height = 44;
                const arrowDirections = isShape ? [
                    Direction.Right,
                    Direction.Bottom,
                    Direction.Left,
                    Direction.Top
                ] : [
                    Direction.Right,
                    Direction.Left
                ];
                const arrowMargin = isShape ? height / 2 : height * (2 / 3);
                const Arrows = arrowDirections.map((type)=>{
                    let transform = '';
                    const icon = isShape ? AutoCompleteArrowIcon : NoteAutoCompleteIcon;
                    switch(type){
                        case Direction.Top:
                            transform += `translate(${selectedRect.width / 2}px, ${-arrowMargin}px)`;
                            break;
                        case Direction.Right:
                            transform += `translate(${selectedRect.width + arrowMargin}px, ${selectedRect.height / 2}px)`;
                            isShape && (transform += `rotate(90deg)`);
                            break;
                        case Direction.Bottom:
                            transform += `translate(${selectedRect.width / 2}px, ${selectedRect.height + arrowMargin}px)`;
                            isShape && (transform += `rotate(180deg)`);
                            break;
                        case Direction.Left:
                            transform += `translate(${-arrowMargin}px, ${selectedRect.height / 2}px)`;
                            isShape && (transform += `rotate(-90deg)`);
                            break;
                    }
                    transform += `translate(${-width / 2}px, ${-height / 2}px)`;
                    const arrowWrapperClasses = classMap({
                        'edgeless-auto-complete-arrow-wrapper': true,
                        hidden: !isShape && type === Direction.Left && zoom >= 1.5
                    });
                    return html`<div
        class=${arrowWrapperClasses}
        style=${styleMap({
                        transform,
                        transformOrigin: 'left top'
                    })}
      >
        <div
          class="edgeless-auto-complete-arrow"
          @mouseenter=${()=>{
                        this._timer = setTimeout(()=>{
                            if (this.current instanceof ShapeElementModel) {
                                const bound = this._computeNextBound(type);
                                const path = this._computeLine(type, this.current, bound);
                                this._showNextShape(this.current, bound, path, this.current.shapeType);
                            }
                        }, 300);
                    }}
          @mouseleave=${()=>{
                        this.removeOverlay();
                    }}
          @pointerdown=${(e)=>{
                        this._onPointerDown(e, type);
                    }}
        >
          ${icon}
        </div>
      </div>`;
                });
                return Arrows;
            }
            _renderMindMapButtons() {
                const mindmapButtons = this._getMindmapButtons();
                if (!mindmapButtons) {
                    return;
                }
                const { selectedRect } = this;
                const { zoom } = this.edgeless.service.viewport;
                const width = 72;
                const height = 44;
                const buttonMargin = height / 2;
                return mindmapButtons.map((type)=>{
                    let transform = '';
                    const [position, target, layout] = type;
                    const isLeftLayout = layout === LayoutType.LEFT;
                    const icon = target === 'child' ? MindMapChildIcon : MindMapSiblingIcon;
                    switch(position){
                        case Direction.Bottom:
                            transform += `translate(${selectedRect.width / 2}px, ${selectedRect.height + buttonMargin}px)`;
                            isLeftLayout && (transform += `scale(-1)`);
                            break;
                        case Direction.Right:
                            transform += `translate(${selectedRect.width + buttonMargin}px, ${selectedRect.height / 2}px)`;
                            break;
                        case Direction.Left:
                            transform += `translate(${-buttonMargin}px, ${selectedRect.height / 2}px)`;
                            transform += `scale(-1)`;
                            break;
                    }
                    transform += `translate(${-width / 2}px, ${-height / 2}px)`;
                    const arrowWrapperClasses = classMap({
                        'edgeless-auto-complete-arrow-wrapper': true,
                        hidden: position === Direction.Left && zoom >= 1.5,
                        mindmap: true
                    });
                    return html`<div
        class=${arrowWrapperClasses}
        style=${styleMap({
                        transform,
                        transformOrigin: 'left top'
                    })}
      >
        <div
          class="edgeless-auto-complete-arrow"
          @pointerdown=${()=>{
                        this._addMindmapNode(layout, target);
                    }}
        >
          ${icon}
        </div>
      </div>`;
                });
            }
            _showNextShape(current, bound, path, targetType) {
                const { surface } = this.edgeless;
                surface.renderer.addOverlay(this._autoCompleteOverlay);
                this._autoCompleteOverlay.stroke = surface.renderer.getColorValue(current.strokeColor, DEFAULT_SHAPE_STROKE_COLOR, true);
                this._autoCompleteOverlay.linePoints = path;
                this._autoCompleteOverlay.renderShape = (ctx)=>{
                    shapeMethods[targetType].draw(ctx, {
                        ...bound,
                        rotate: current.rotate
                    });
                };
                surface.refresh();
            }
            get _surface() {
                return this.edgeless.surface;
            }
            connectedCallback() {
                super.connectedCallback();
                this._pathGenerator = new ConnectorPathGenerator({
                    getElementById: (id)=>this.edgeless.service.getElementById(id)
                });
            }
            firstUpdated() {
                const { _disposables, edgeless } = this;
                _disposables.add(this.edgeless.service.selection.slots.updated.on(()=>{
                    this._autoCompleteOverlay.linePoints = [];
                    this._autoCompleteOverlay.renderShape = null;
                }));
                _disposables.add(()=>this.removeOverlay());
                _disposables.add(edgeless.host.event.add('pointerMove', ()=>{
                    const state = edgeless.tools.getHoverState();
                    if (!state) {
                        this._isHover = false;
                        return;
                    }
                    this._isHover = state.content === this.current ? true : false;
                }));
                this.edgeless.handleEvent('dragStart', ()=>{
                    this._isMoving = true;
                });
                this.edgeless.handleEvent('dragEnd', ()=>{
                    this._isMoving = false;
                });
            }
            removeOverlay() {
                this._timer && clearTimeout(this._timer);
                this.edgeless.surface.renderer.removeOverlay(this._autoCompleteOverlay);
            }
            render() {
                const isShape = this.current instanceof ShapeElementModel;
                const isMindMap = this.current.group instanceof MindmapElementModel;
                if (this._isMoving || this._isHover && !isShape) {
                    this.removeOverlay();
                    return nothing;
                }
                const { selectedRect } = this;
                return html`<div
      class="edgeless-auto-complete-container"
      style=${styleMap({
                    top: selectedRect.top + 'px',
                    left: selectedRect.left + 'px',
                    width: selectedRect.width + 'px',
                    height: selectedRect.height + 'px',
                    transform: `rotate(${selectedRect.rotate}deg)`
                })}
    >
      ${isMindMap ? this._renderMindMapButtons() : this._renderArrow()}
    </div>`;
            }
            get canShowAutoComplete() {
                const { current } = this;
                return isShape(current) || isNoteBlock(current);
            }
            #___private__isHover_1;
            get _isHover() {
                return this.#___private__isHover_1;
            }
            set _isHover(_v) {
                this.#___private__isHover_1 = _v;
            }
            #___private__isMoving_2;
            get _isMoving() {
                return this.#___private__isMoving_2;
            }
            set _isMoving(_v) {
                this.#___private__isMoving_2 = _v;
            }
            #___private_current_3;
            get current() {
                return this.#___private_current_3;
            }
            set current(_v) {
                this.#___private_current_3 = _v;
            }
            #___private_edgeless_4;
            get edgeless() {
                return this.#___private_edgeless_4;
            }
            set edgeless(_v) {
                this.#___private_edgeless_4 = _v;
            }
            #___private_selectedRect_5;
            get selectedRect() {
                return this.#___private_selectedRect_5;
            }
            set selectedRect(_v) {
                this.#___private_selectedRect_5 = _v;
            }
            constructor(...args){
                super(...args);
                this._autoCompleteOverlay = new AutoCompleteOverlay();
                this._onPointerDown = (e, type)=>{
                    const { service } = this.edgeless;
                    const viewportRect = service.viewport.boundingClientRect;
                    const start = service.viewport.toModelCoord(e.clientX - viewportRect.left, e.clientY - viewportRect.top);
                    if (!this.edgeless.dispatcher) return;
                    let connector;
                    this._disposables.addFromEvent(document, 'pointermove', (e)=>{
                        const point = service.viewport.toModelCoord(e.clientX - viewportRect.left, e.clientY - viewportRect.top);
                        if (Vec.dist(start, point) > 8 && !this._isMoving) {
                            if (!this.canShowAutoComplete) return;
                            this._isMoving = true;
                            const { startPosition } = getPosition(type);
                            connector = this._addConnector({
                                id: this.current.id,
                                position: startPosition
                            }, {
                                position: point
                            });
                        }
                        if (this._isMoving) {
                            assertExists(connector);
                            const otherSideId = connector.source.id;
                            connector.target = this._surface.overlays.connector.renderConnector(point, otherSideId ? [
                                otherSideId
                            ] : []);
                        }
                    });
                    this._disposables.addFromEvent(document, 'pointerup', (e)=>{
                        if (!this._isMoving) {
                            this._generateElementOnClick(type);
                        } else if (connector && !connector.target.id) {
                            this.edgeless.service.selection.clear();
                            this._createAutoCompletePanel(e, connector);
                        }
                        this._isMoving = false;
                        this._surface.overlays.connector.clear();
                        this._disposables.dispose();
                        this._disposables = new DisposableGroup();
                    });
                };
                this._timer = null;
                this.#___private__isHover_1 = (_initProto(this), _init__isHover(this, true));
                this.#___private__isMoving_2 = _init__isMoving(this, false);
                this.#___private_current_3 = _init_current(this);
                this.#___private_edgeless_4 = _init_edgeless(this);
                this.#___private_selectedRect_5 = _init_selectedRect(this);
            }
        }
    }
}();
export { _EdgelessAutoComplete as EdgelessAutoComplete };
