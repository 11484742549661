import { matchFlavours } from '../../_common/utils/model.js';
import { correctNumberedListsOrderToPrev } from './utils.js';
export const indentListCommand = (ctx, next)=>{
    const { blockId, inlineIndex, std } = ctx;
    const host = std.host;
    const doc = host.doc;
    const model = doc.getBlock(blockId).model;
    if (!matchFlavours(model, [
        'affine:list'
    ])) {
        console.error(`block ${blockId} is not a list block`);
        return;
    }
    const previousSibling = doc.getPrev(model);
    if (!previousSibling) {
        return;
    }
    const nextSibling = doc.getNext(model);
    doc.captureSync();
    doc.moveBlocks([
        model
    ], previousSibling);
    correctNumberedListsOrderToPrev(doc, model);
    if (nextSibling) correctNumberedListsOrderToPrev(doc, nextSibling);
    host.updateComplete.then(()=>{
        host.selection.setGroup('note', [
            host.selection.create('text', {
                from: {
                    blockId,
                    index: inlineIndex,
                    length: 0
                },
                to: null
            })
        ]);
    }).catch(console.error);
    next();
};
