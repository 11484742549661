import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
export class DocRecord extends Entity {
    constructor(docsStore){
        super();
        this.docsStore = docsStore;
        this.id = this.props.id;
        this.meta$ = LiveData.from(this.docsStore.watchDocMeta(this.id), {});
        this.mode$ = LiveData.from(this.docsStore.watchDocModeSetting(this.id), 'page').map((mode)=>mode === 'edgeless' ? 'edgeless' : 'page');
        this.title$ = this.meta$.map((meta)=>meta.title ?? '');
        this.trash$ = this.meta$.map((meta)=>meta.trash ?? false);
    }
    setMeta(meta) {
        this.docsStore.setDocMeta(this.id, meta);
    }
    setMode(mode) {
        return this.docsStore.setDocModeSetting(this.id, mode);
    }
    getMode() {
        return this.docsStore.getDocModeSetting(this.id);
    }
    toggleMode() {
        const mode = this.getMode() === 'edgeless' ? 'page' : 'edgeless';
        this.setMode(mode);
        return this.getMode();
    }
    observeMode() {
        return this.docsStore.watchDocModeSetting(this.id);
    }
    moveToTrash() {
        return this.setMeta({
            trash: true
        });
    }
    restoreFromTrash() {
        return this.setMeta({
            trash: false
        });
    }
}
