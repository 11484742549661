import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@affine/component';
import { PageDisplayMenu, useEditFeed } from '@affine/core/components/page-list';
import { FeedPageListOperationsMenu } from '@affine/core/components/page-list/docs/page-list-header-feed-operations-menu';
import { FeedAvatar } from '@affine/core/components/page-list/feed/avatar';
import { useNavigateHelper } from '@affine/core/hooks/use-navigate-helper';
import { useI18n } from '@affine/i18n';
import { useCallback } from 'react';
import * as styles from './page-list-header.css';
import * as feedStyles from './page-list-header-feed.css';
export const FeedPageListHeader = ({ collection, workspaceId, propertiesMeta, currentFilters, onChangeCurrentFilters })=>{
    const t = useI18n();
    const { jumpToManageFeeds } = useNavigateHelper();
    const handleJumpToFeeds = useCallback(()=>{
        jumpToManageFeeds(workspaceId);
    }, [
        jumpToManageFeeds,
        workspaceId
    ]);
    const { node, handleEditFeed } = useEditFeed(collection);
    return _jsxs(_Fragment, {
        children: [
            node,
            _jsxs("div", {
                className: styles.docListHeader,
                children: [
                    _jsxs("div", {
                        className: styles.docListHeaderTitle,
                        children: [
                            _jsxs("div", {
                                style: {
                                    cursor: 'pointer'
                                },
                                onClick: handleJumpToFeeds,
                                children: [
                                    t['ai.wemem.feeds.header'](),
                                    " /"
                                ]
                            }),
                            _jsx("div", {
                                className: styles.titleIcon,
                                children: _jsx(FeedAvatar, {
                                    image: collection.feed?.icon
                                })
                            }),
                            _jsx("div", {
                                className: styles.titleCollectionName,
                                children: collection.name
                            }),
                            _jsx("div", {
                                className: feedStyles.listRightButton,
                                children: _jsx(FeedPageListOperationsMenu, {
                                    filterList: currentFilters,
                                    onChangeFilterList: onChangeCurrentFilters,
                                    propertiesMeta: propertiesMeta
                                })
                            })
                        ]
                    }),
                    _jsxs("div", {
                        className: styles.rightButtonGroup,
                        children: [
                            _jsx(Button, {
                                onClick: handleEditFeed,
                                children: t['Edit']()
                            }),
                            _jsx(PageDisplayMenu, {})
                        ]
                    })
                ]
            })
        ]
    });
};
