import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Modal, Scrollable } from '@affine/component';
import { DocsSearchService } from '@affine/core/modules/docs-search';
import { FeedTag } from '@affine/core/modules/tag/entities/internal-tag';
import { LiveData, useLiveData, useServices, WorkspaceService } from '@toeverything/infra';
import { Suspense, useCallback, useContext, useMemo, useRef } from 'react';
import { BlocksuiteHeaderTitle } from '../../../blocksuite/block-suite-header/title';
import { managerContext } from '../common';
import { PagePropertiesAddProperty, PagePropertyRow, SortableProperties, usePagePropertiesManager } from '../table';
import { BackLinksRow } from './back-links-row';
import * as styles from './info-modal.css';
import { TagsRow } from './tags-row';
import { TimeRow } from './time-row';
export const InfoModal = ({ open, onOpenChange, docId })=>{
    const { docsSearchService, workspaceService } = useServices({
        DocsSearchService,
        WorkspaceService
    });
    const page = workspaceService.workspace.docCollection.getDoc(docId);
    const titleInputHandleRef = useRef(null);
    const manager = usePagePropertiesManager(docId);
    const handleClose = useCallback(()=>{
        onOpenChange(false);
    }, [
        onOpenChange
    ]);
    const references = useLiveData(useMemo(()=>LiveData.from(docsSearchService.watchRefsFrom(docId), null), [
        docId,
        docsSearchService
    ]));
    const hasSubscriptionTag = page?.meta?.tags.some((tag)=>tag === FeedTag.id);
    if (!manager.page || !hasSubscriptionTag && manager.readonly) {
        return null;
    }
    return _jsx(Modal, {
        contentOptions: {
            className: styles.container
        },
        open: open,
        onOpenChange: onOpenChange,
        withoutCloseButton: true,
        children: _jsxs(Scrollable.Root, {
            children: [
                _jsxs(Scrollable.Viewport, {
                    className: styles.viewport,
                    "data-testid": "info-modal",
                    children: [
                        _jsx("div", {
                            className: styles.titleContainer,
                            "data-testid": "info-modal-title",
                            children: _jsx(BlocksuiteHeaderTitle, {
                                className: styles.titleStyle,
                                inputHandleRef: titleInputHandleRef,
                                pageId: docId,
                                docCollection: workspaceService.workspace.docCollection
                            })
                        }),
                        _jsx(managerContext.Provider, {
                            value: manager,
                            children: _jsx(Suspense, {
                                children: _jsx(InfoTable, {
                                    docId: docId,
                                    onClose: handleClose,
                                    references: references,
                                    readonly: manager.readonly
                                })
                            })
                        })
                    ]
                }),
                _jsx(Scrollable.Scrollbar, {
                    className: styles.scrollBar
                })
            ]
        })
    });
};
const InfoTable = ({ onClose, references, docId, readonly })=>{
    const manager = useContext(managerContext);
    return _jsxs("div", {
        children: [
            _jsx(TimeRow, {
                docId: docId
            }),
            _jsx(Divider, {
                size: "thinner"
            }),
            references && references.length > 0 ? _jsxs(_Fragment, {
                children: [
                    _jsx(BackLinksRow, {
                        references: references,
                        onClick: onClose
                    }),
                    _jsx(Divider, {
                        size: "thinner"
                    })
                ]
            }) : null,
            _jsx(TagsRow, {
                docId: docId,
                readonly: readonly
            }),
            _jsx(SortableProperties, {
                children: (properties)=>properties.length ? _jsx("div", {
                        children: properties.filter((property)=>manager.isPropertyRequired(property.id) || property.visibility !== 'hide' && !(property.visibility === 'hide-if-empty' && !property.value)).map((property)=>_jsx(PagePropertyRow, {
                                property: property,
                                rowNameClassName: styles.rowNameContainer
                            }, property.id))
                    }) : null
            }),
            manager.readonly ? null : _jsx(PagePropertiesAddProperty, {})
        ]
    });
};
