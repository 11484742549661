function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _dec1, _initClass, _EmbedBlockComponent, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _init__docUpdatedAt, _init__linkedDocMode, _init__loading, _init_bannerContainer, _init_isBannerEmpty, _init_isError, _init_isNoteContentEmpty, _init_noteContainer, _init_surfaceRefRenderer, _init_surfaceRefService, _initProto;
import { Bound } from '@blocksuite/global/utils';
import { assertExists } from '@blocksuite/global/utils';
import { DocCollection } from '@blocksuite/store';
import { html, nothing } from 'lit';
import { customElement, property, queryAsync, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { Peekable, isPeekable } from '../_common/components/peekable.js';
import { EMBED_CARD_HEIGHT, EMBED_CARD_WIDTH } from '../_common/consts.js';
import { EmbedBlockComponent } from '../_common/embed-block-helper/index.js';
import { REFERENCE_NODE } from '../_common/inline/presets/nodes/consts.js';
import { renderLinkedDocInCard } from '../_common/utils/render-linked-doc.js';
import { SyncedDocErrorIcon } from '../embed-synced-doc-block/styles.js';
import { styles } from './styles.js';
import { getEmbedLinkedDocIcons } from './utils.js';
let _EmbedLinkedDocBlockComponent;
_dec = customElement('affine-embed-linked-doc-block'), _dec1 = Peekable({
    enableOn: ({ doc })=>!doc.readonly
}), _dec2 = state(), _dec3 = state(), _dec4 = state(), _dec5 = queryAsync('.affine-embed-linked-doc-banner.render'), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = queryAsync('.affine-embed-linked-doc-content-note.render'), _dec10 = property({
    attribute: false
}), _dec11 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EmbedLinkedDocBlockComponent), _initClass();
    }
    static{
        class EmbedLinkedDocBlockComponent extends (_EmbedBlockComponent = EmbedBlockComponent) {
            static{
                ({ e: [_init__docUpdatedAt, _init__linkedDocMode, _init__loading, _init_bannerContainer, _init_isBannerEmpty, _init_isError, _init_isNoteContentEmpty, _init_noteContainer, _init_surfaceRefRenderer, _init_surfaceRefService, _initProto], c: [_EmbedLinkedDocBlockComponent, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec2,
                        1,
                        "_docUpdatedAt"
                    ],
                    [
                        _dec3,
                        1,
                        "_linkedDocMode"
                    ],
                    [
                        _dec4,
                        1,
                        "_loading"
                    ],
                    [
                        _dec5,
                        1,
                        "bannerContainer"
                    ],
                    [
                        _dec6,
                        1,
                        "isBannerEmpty"
                    ],
                    [
                        _dec7,
                        1,
                        "isError"
                    ],
                    [
                        _dec8,
                        1,
                        "isNoteContentEmpty"
                    ],
                    [
                        _dec9,
                        1,
                        "noteContainer"
                    ],
                    [
                        _dec10,
                        1,
                        "surfaceRefRenderer"
                    ],
                    [
                        _dec11,
                        1,
                        "surfaceRefService"
                    ]
                ], [
                    _dec,
                    _dec1
                ], _EmbedBlockComponent));
            }
            static{
                this.styles = styles;
            }
            _handleClick(event) {
                if (this.config.handleClick) {
                    this.config.handleClick(event, this.host);
                    return;
                }
                if (this.isInSurface) return;
                this._selectBlock();
            }
            _handleDoubleClick(event) {
                if (this.config.handleDoubleClick) {
                    this.config.handleDoubleClick(event, this.host);
                    return;
                }
                if (isPeekable(this)) {
                    return;
                }
                event.stopPropagation();
                this.open();
            }
            _isDocEmpty() {
                const linkedDoc = this.linkedDoc;
                if (!linkedDoc) {
                    return false;
                }
                return !!linkedDoc && this.isNoteContentEmpty && this.isBannerEmpty;
            }
            get _rootService() {
                return this.std.spec.getService('affine:page');
            }
            connectedCallback() {
                super.connectedCallback();
                this._load().catch((e)=>{
                    console.error(e);
                    this.isError = true;
                });
                const linkedDoc = this.linkedDoc;
                if (linkedDoc) {
                    this.disposables.add(linkedDoc.collection.meta.docMetaUpdated.on(()=>{
                        this._load().catch((e)=>{
                            console.error(e);
                            this.isError = true;
                        });
                    }));
                    this.disposables.add(linkedDoc.slots.blockUpdated.on((payload)=>{
                        if (payload.type === 'update' && [
                            '',
                            'caption',
                            'xywh'
                        ].includes(payload.props.key)) {
                            return;
                        }
                        if (payload.type === 'add' && payload.init) {
                            return;
                        }
                        this._load().catch((e)=>{
                            console.error(e);
                            this.isError = true;
                        });
                    }));
                    this._setDocUpdatedAt();
                    this.disposables.add(this.doc.collection.meta.docMetaUpdated.on(()=>{
                        this._setDocUpdatedAt();
                    }));
                    this._linkedDocMode = this._rootService.docModeService.getMode(this.model.pageId);
                    this.disposables.add(this._rootService.docModeService.onModeChange((mode)=>{
                        this._linkedDocMode = mode;
                    }, this.model.pageId));
                }
                this.model.propsUpdated.on(({ key })=>{
                    if (key === 'pageId' || key === 'style') {
                        this._load().catch((e)=>{
                            console.error(e);
                            this.isError = true;
                        });
                    }
                });
                if (this.isInSurface) {
                    this.disposables.add(this.model.propsUpdated.on(()=>{
                        this.requestUpdate();
                    }));
                }
            }
            disconnectedCallback() {
                this.cleanUpSurfaceRefRenderer();
                super.disconnectedCallback();
            }
            renderBlock() {
                this._cardStyle = this.model.style;
                this._width = EMBED_CARD_WIDTH[this._cardStyle];
                this._height = EMBED_CARD_HEIGHT[this._cardStyle];
                const linkedDoc = this.linkedDoc;
                const isDeleted = !linkedDoc;
                const isLoading = this._loading;
                const isError = this.isError;
                const isEmpty = this._isDocEmpty() && this.isBannerEmpty;
                const cardClassMap = classMap({
                    loading: isLoading,
                    error: isError,
                    deleted: isDeleted,
                    empty: isEmpty,
                    'banner-empty': this.isBannerEmpty,
                    'note-empty': this.isNoteContentEmpty,
                    [this._cardStyle]: true
                });
                const { LoadingIcon, ReloadIcon, LinkedDocIcon, LinkedDocDeletedIcon, LinkedDocDeletedBanner, LinkedDocEmptyBanner, SyncedDocErrorBanner } = getEmbedLinkedDocIcons(this._linkedDocMode, this._cardStyle);
                const titleIcon = isError ? SyncedDocErrorIcon : isLoading ? LoadingIcon : isDeleted ? LinkedDocDeletedIcon : LinkedDocIcon;
                const titleText = isError ? linkedDoc?.meta?.title || 'Untitled' : isLoading ? 'Loading...' : isDeleted ? `Deleted doc` : linkedDoc?.meta?.title || 'Untitled';
                const showDefaultNoteContent = isError || isLoading || isDeleted || isEmpty;
                const defaultNoteContent = isError ? 'This linked doc failed to load.' : isLoading ? '' : isDeleted ? 'This linked doc is deleted.' : isEmpty ? 'Preview of the doc will be displayed here.' : '';
                const dateText = this._cardStyle === 'cube' ? this._docUpdatedAt.toLocaleTimeString() : this._docUpdatedAt.toLocaleString();
                const showDefaultBanner = isError || isLoading || isDeleted || isEmpty;
                const defaultBanner = isError ? SyncedDocErrorBanner : isLoading ? LinkedDocEmptyBanner : isDeleted ? LinkedDocDeletedBanner : LinkedDocEmptyBanner;
                return this.renderEmbed(()=>html`
        <div>
          <div
            class="affine-embed-linked-doc-block ${cardClassMap}"
            @click=${this._handleClick}
            @dblclick=${this._handleDoubleClick}
          >
            <div class="affine-embed-linked-doc-content">
              <div class="affine-embed-linked-doc-content-title">
                <div class="affine-embed-linked-doc-content-title-icon">
                  ${titleIcon}
                </div>

                <div class="affine-embed-linked-doc-content-title-text">
                  ${titleText}
                </div>
              </div>

              <div class="affine-embed-linked-doc-content-note render"></div>
              ${showDefaultNoteContent ? html`<div
                    class="affine-embed-linked-doc-content-note default"
                  >
                    ${defaultNoteContent}
                  </div>` : nothing}
              ${isError ? html`
                    <div class="affine-embed-linked-doc-card-content-reload">
                      <div
                        class="affine-embed-linked-doc-card-content-reload-button"
                        @click=${this.refreshData}
                      >
                        ${ReloadIcon} <span>Reload</span>
                      </div>
                    </div>
                  ` : html`
                    <div class="affine-embed-linked-doc-content-date">
                      <span>Updated</span>

                      <span>${dateText}</span>
                    </div>
                  `}
            </div>

            <div class="affine-embed-linked-doc-banner render"></div>

            ${showDefaultBanner ? html`
                  <div class="affine-embed-linked-doc-banner default">
                    ${defaultBanner}
                  </div>
                ` : nothing}
            <div class="affine-embed-linked-doc-block-overlay"></div>
          </div>
        </div>
      `);
            }
            updated() {
                const linkedDoc = this.linkedDoc;
                const { xywh, style } = this.model;
                const bound = Bound.deserialize(xywh);
                if (linkedDoc && style === 'horizontalThin') {
                    bound.w = EMBED_CARD_WIDTH.horizontal;
                    bound.h = EMBED_CARD_HEIGHT.horizontal;
                    this.doc.withoutTransact(()=>{
                        this.doc.updateBlock(this.model, {
                            xywh: bound.serialize(),
                            style: 'horizontal'
                        });
                    });
                } else if (!linkedDoc && style === 'horizontal') {
                    bound.w = EMBED_CARD_WIDTH.horizontalThin;
                    bound.h = EMBED_CARD_HEIGHT.horizontalThin;
                    this.doc.withoutTransact(()=>{
                        this.doc.updateBlock(this.model, {
                            xywh: bound.serialize(),
                            style: 'horizontalThin'
                        });
                    });
                }
            }
            get config() {
                return this.std.spec.getConfig('affine:embed-linked-doc') || {};
            }
            get docTitle() {
                return this.linkedDoc?.meta?.title.length ? this.linkedDoc.meta.title : 'Untitled';
            }
            get editorMode() {
                return this._linkedDocMode;
            }
            get linkedDoc() {
                const doc = this.std.collection.getDoc(this.model.pageId);
                return doc;
            }
            #___private__docUpdatedAt_1;
            get _docUpdatedAt() {
                return this.#___private__docUpdatedAt_1;
            }
            set _docUpdatedAt(_v) {
                this.#___private__docUpdatedAt_1 = _v;
            }
            #___private__linkedDocMode_2;
            get _linkedDocMode() {
                return this.#___private__linkedDocMode_2;
            }
            set _linkedDocMode(_v) {
                this.#___private__linkedDocMode_2 = _v;
            }
            #___private__loading_3;
            get _loading() {
                return this.#___private__loading_3;
            }
            set _loading(_v) {
                this.#___private__loading_3 = _v;
            }
            #___private_bannerContainer_4;
            get bannerContainer() {
                return this.#___private_bannerContainer_4;
            }
            set bannerContainer(_v) {
                this.#___private_bannerContainer_4 = _v;
            }
            #___private_isBannerEmpty_5;
            get isBannerEmpty() {
                return this.#___private_isBannerEmpty_5;
            }
            set isBannerEmpty(_v) {
                this.#___private_isBannerEmpty_5 = _v;
            }
            #___private_isError_6;
            get isError() {
                return this.#___private_isError_6;
            }
            set isError(_v) {
                this.#___private_isError_6 = _v;
            }
            #___private_isNoteContentEmpty_7;
            get isNoteContentEmpty() {
                return this.#___private_isNoteContentEmpty_7;
            }
            set isNoteContentEmpty(_v) {
                this.#___private_isNoteContentEmpty_7 = _v;
            }
            #___private_noteContainer_8;
            get noteContainer() {
                return this.#___private_noteContainer_8;
            }
            set noteContainer(_v) {
                this.#___private_noteContainer_8 = _v;
            }
            #___private_surfaceRefRenderer_9;
            get surfaceRefRenderer() {
                return this.#___private_surfaceRefRenderer_9;
            }
            set surfaceRefRenderer(_v) {
                this.#___private_surfaceRefRenderer_9 = _v;
            }
            #___private_surfaceRefService_10;
            get surfaceRefService() {
                return this.#___private_surfaceRefService_10;
            }
            set surfaceRefService(_v) {
                this.#___private_surfaceRefService_10 = _v;
            }
            constructor(...args){
                super(...args);
                this._cardStyle = 'horizontal';
                this._height = EMBED_CARD_HEIGHT.horizontal;
                this._load = async ()=>{
                    this._loading = true;
                    this.isError = false;
                    this.isNoteContentEmpty = true;
                    this.isBannerEmpty = true;
                    const linkedDoc = this.linkedDoc;
                    if (!linkedDoc) {
                        this._loading = false;
                        return;
                    }
                    if (!linkedDoc.loaded) {
                        try {
                            linkedDoc.load();
                        } catch (e) {
                            console.error(e);
                            this.isError = true;
                        }
                    }
                    if (!this.isError && !linkedDoc.root) {
                        await new Promise((resolve)=>{
                            linkedDoc.slots.rootAdded.once(()=>{
                                resolve();
                            });
                        });
                    }
                    this._loading = false;
                    if (!this.isError) {
                        const cardStyle = this.model.style;
                        if (cardStyle === 'horizontal' || cardStyle === 'vertical') {
                            renderLinkedDocInCard(this);
                        }
                    }
                };
                this._selectBlock = ()=>{
                    const selectionManager = this.host.selection;
                    const blockSelection = selectionManager.create('block', {
                        blockId: this.blockId
                    });
                    selectionManager.setGroup('note', [
                        blockSelection
                    ]);
                };
                this._setDocUpdatedAt = ()=>{
                    const meta = this.doc.collection.meta.getDocMeta(this.model.pageId);
                    if (meta) {
                        const date = meta.updatedDate || meta.createDate;
                        this._docUpdatedAt = new Date(date);
                    }
                };
                this._width = EMBED_CARD_WIDTH.horizontal;
                this.cleanUpSurfaceRefRenderer = ()=>{
                    if (this.surfaceRefRenderer) {
                        this.surfaceRefService.removeRenderer(this.surfaceRefRenderer.id);
                    }
                };
                this.convertToEmbed = ()=>{
                    const { id, doc, pageId, caption, xywh } = this.model;
                    const isSyncedDocEnabled = doc.awarenessStore.getFlag('enable_synced_doc_block');
                    if (!isSyncedDocEnabled) {
                        return;
                    }
                    if (this.isInSurface) {
                        const style = 'syncedDoc';
                        const bound = Bound.deserialize(xywh);
                        bound.w = EMBED_CARD_WIDTH[style];
                        bound.h = EMBED_CARD_HEIGHT[style];
                        const edgelessService = this.rootService;
                        if (!edgelessService) {
                            return;
                        }
                        const newId = edgelessService.addBlock('affine:embed-synced-doc', {
                            pageId,
                            xywh: bound.serialize(),
                            caption
                        }, edgelessService.surface);
                        this.std.command.exec('reassociateConnectors', {
                            oldId: id,
                            newId
                        });
                        edgelessService.selection.set({
                            editing: false,
                            elements: [
                                newId
                            ]
                        });
                    } else {
                        const parent = doc.getParent(this.model);
                        assertExists(parent);
                        const index = parent.children.indexOf(this.model);
                        doc.addBlock('affine:embed-synced-doc', {
                            pageId,
                            caption
                        }, parent, index);
                        this.std.selection.setGroup('note', []);
                    }
                    doc.deleteBlock(this.model);
                };
                this.covertToInline = ()=>{
                    const { doc, pageId } = this.model;
                    const parent = doc.getParent(this.model);
                    assertExists(parent);
                    const index = parent.children.indexOf(this.model);
                    const yText = new DocCollection.Y.Text();
                    yText.insert(0, REFERENCE_NODE);
                    yText.format(0, REFERENCE_NODE.length, {
                        reference: {
                            type: 'LinkedPage',
                            pageId
                        }
                    });
                    const text = new doc.Text(yText);
                    doc.addBlock('affine:paragraph', {
                        text
                    }, parent, index);
                    doc.deleteBlock(this.model);
                };
                this.open = ()=>{
                    const linkedDocId = this.model.pageId;
                    if (linkedDocId === this.doc.id) return;
                    const rootComponent = this.std.view.viewFromPath('block', [
                        this.doc.root?.id ?? ''
                    ]);
                    assertExists(rootComponent);
                    rootComponent.slots.docLinkClicked.emit({
                        docId: linkedDocId
                    });
                };
                this.refreshData = ()=>{
                    this._load().catch((e)=>{
                        console.error(e);
                        this.isError = true;
                    });
                };
                this.#___private__docUpdatedAt_1 = (_initProto(this), _init__docUpdatedAt(this, new Date()));
                this.#___private__linkedDocMode_2 = _init__linkedDocMode(this, 'page');
                this.#___private__loading_3 = _init__loading(this, false);
                this.#___private_bannerContainer_4 = _init_bannerContainer(this);
                this.#___private_isBannerEmpty_5 = _init_isBannerEmpty(this, false);
                this.#___private_isError_6 = _init_isError(this, false);
                this.#___private_isNoteContentEmpty_7 = _init_isNoteContentEmpty(this, false);
                this.#___private_noteContainer_8 = _init_noteContainer(this);
                this.#___private_surfaceRefRenderer_9 = _init_surfaceRefRenderer(this, undefined);
                this.#___private_surfaceRefService_10 = _init_surfaceRefService(this);
            }
        }
    }
}();
export { _EmbedLinkedDocBlockComponent as EmbedLinkedDocBlockComponent };
