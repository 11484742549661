import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useJournalInfoHelper } from '@affine/core/hooks/use-journal';
import { useI18n } from '@affine/i18n';
import * as styles from './styles.css';
export const BlocksuiteEditorJournalDocTitle = ({ page })=>{
    const { localizedJournalDate, isTodayJournal, journalDate } = useJournalInfoHelper(page.collection, page.id);
    const t = useI18n();
    const day = journalDate?.format('dddd') ?? null;
    return _jsxs("span", {
        className: "doc-title-container",
        children: [
            _jsx("span", {
                children: localizedJournalDate
            }),
            isTodayJournal ? _jsx("span", {
                className: styles.titleTodayTag,
                children: t['com.affine.today']()
            }) : _jsx("span", {
                className: styles.titleDayTag,
                children: day
            })
        ]
    });
};
