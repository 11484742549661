export function setObjectPropMeta(symbol, target, prop, val) {
    target[symbol] = target[symbol] ?? {};
    target[symbol][prop] = val;
}
export function getObjectPropMeta(target, symbol, prop) {
    if (prop) {
        return target[symbol]?.[prop] ?? null;
    }
    return target[symbol] ?? {};
}
export function getDecoratorState(surface) {
    return surface['_decoratorState'];
}
export function createDecoratorState() {
    return {
        creating: false,
        deriving: false,
        skipYfield: false
    };
}
