import { DebugLogger } from '@affine/debug';
import { applyAwarenessUpdate, encodeAwarenessUpdate, removeAwarenessStates } from 'y-protocols/awareness';
import { base64ToUint8Array, uint8ArrayToBase64 } from '../../utils/base64';
const logger = new DebugLogger('affine:awareness:socketio');
export class CloudAwarenessConnection {
    constructor(workspaceId, socket){
        this.workspaceId = workspaceId;
        this.socket = socket;
        this.awareness = null;
        this.awarenessBroadcast = ({ workspaceId: wsId, awarenessUpdate })=>{
            if (!this.awareness) {
                return;
            }
            if (wsId !== this.workspaceId) {
                return;
            }
            applyAwarenessUpdate(this.awareness, base64ToUint8Array(awarenessUpdate), 'remote');
        };
        this.awarenessUpdate = (changes, origin)=>{
            if (!this.awareness) {
                return;
            }
            if (origin === 'remote') {
                return;
            }
            const changedClients = Object.values(changes).reduce((res, cur)=>res.concat(cur));
            const update = encodeAwarenessUpdate(this.awareness, changedClients);
            uint8ArrayToBase64(update).then((encodedUpdate)=>{
                this.socket.emit('awareness-update', {
                    workspaceId: this.workspaceId,
                    awarenessUpdate: encodedUpdate
                });
            }).catch((err)=>logger.error(err));
        };
        this.newClientAwarenessInitHandler = ()=>{
            if (!this.awareness) {
                return;
            }
            const awarenessUpdate = encodeAwarenessUpdate(this.awareness, [
                this.awareness.clientID
            ]);
            uint8ArrayToBase64(awarenessUpdate).then((encodedAwarenessUpdate)=>{
                this.socket.emit('awareness-update', {
                    workspaceId: this.workspaceId,
                    awarenessUpdate: encodedAwarenessUpdate
                });
            }).catch((err)=>logger.error(err));
        };
        this.windowBeforeUnloadHandler = ()=>{
            if (!this.awareness) {
                return;
            }
            removeAwarenessStates(this.awareness, [
                this.awareness.clientID
            ], 'window unload');
        };
        this.handleConnect = ()=>{
            this.socket.emit('client-handshake-awareness', {
                workspaceId: this.workspaceId,
                version: runtimeConfig.appVersion
            }, (res)=>{
                logger.debug('awareness handshake finished', res);
                this.socket.emit('awareness-init', this.workspaceId, (res)=>{
                    logger.debug('awareness-init finished', res);
                });
            });
        };
        this.handleReject = ()=>{
            this.socket.off('server-version-rejected', this.handleReject);
            this.disconnect();
            this.socket.disconnect();
        };
    }
    connect(awareness) {
        this.socket.on('server-awareness-broadcast', this.awarenessBroadcast);
        this.socket.on('new-client-awareness-init', this.newClientAwarenessInitHandler);
        this.awareness = awareness;
        this.awareness.on('update', this.awarenessUpdate);
        window.addEventListener('beforeunload', this.windowBeforeUnloadHandler);
        this.socket.on('connect', this.handleConnect);
        this.socket.on('server-version-rejected', this.handleReject);
        if (this.socket.connected) {
            this.handleConnect();
        } else {
            this.socket.connect();
        }
    }
    disconnect() {
        if (this.awareness) {
            removeAwarenessStates(this.awareness, [
                this.awareness.clientID
            ], 'disconnect');
            this.awareness.off('update', this.awarenessUpdate);
        }
        this.awareness = null;
        this.socket.emit('client-leave-awareness', this.workspaceId);
        this.socket.off('server-awareness-broadcast', this.awarenessBroadcast);
        this.socket.off('new-client-awareness-init', this.newClientAwarenessInitHandler);
        this.socket.off('connect', this.handleConnect);
        this.socket.off('server-version-rejected', this.handleReject);
        window.removeEventListener('unload', this.windowBeforeUnloadHandler);
    }
}
