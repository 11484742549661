import { Entity, LiveData } from '@toeverything/infra';
import { tagColorMap } from './utils';
export class Tag extends Entity {
    constructor(store, docs){
        super();
        this.store = store;
        this.docs = docs;
        this.id = this.props.id;
        this.tagOption$ = LiveData.from(this.store.watchTagInfo(this.id), undefined).map((tagInfo)=>tagInfo);
        this.value$ = this.tagOption$.map((tag)=>tag?.value || '');
        this.color$ = this.tagOption$.map((tag)=>tagColorMap(tag?.color ?? '') || '');
        this.createDate$ = this.tagOption$.map((tag)=>tag?.createDate || Date.now());
        this.updateDate$ = this.tagOption$.map((tag)=>tag?.updateDate || Date.now());
        this.ghost$ = this.tagOption$.map((tag)=>tag?.ghost);
        this.pageIds$ = LiveData.computed((get)=>{
            const pages = get(this.docs.list.docs$);
            return pages.filter((page)=>get(page.meta$).tags?.includes(this.id)).map((page)=>page.id);
        });
    }
    rename(value) {
        this.store.updateTagInfo(this.id, {
            id: this.id,
            value,
            color: this.color$.value,
            createDate: this.createDate$.value,
            updateDate: Date.now()
        });
    }
    changeColor(color) {
        this.store.updateTagInfo(this.id, {
            color
        });
    }
    tag(pageId) {
        const pageRecord = this.docs.list.doc$(pageId).value;
        if (!pageRecord) {
            return;
        }
        pageRecord?.setMeta({
            tags: [
                ...pageRecord.meta$.value.tags ?? [],
                this.id
            ]
        });
    }
    untag(pageId) {
        const pageRecord = this.docs.list.doc$(pageId).value;
        if (!pageRecord) {
            return;
        }
        pageRecord?.setMeta({
            tags: pageRecord.meta$.value.tags?.filter((tagId)=>tagId !== this.id)
        });
    }
}
