import { jsx as _jsx } from "react/jsx-runtime";
import { Slot } from '@blocksuite/store';
import clsx from 'clsx';
import { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { BlocksuiteDocEditor, BlocksuiteEdgelessEditor } from './lit-adaper';
import * as styles from './styles.css';
function forwardSlot(from, to) {
    Object.entries(from).forEach(([key, slot])=>{
        const target = to[key];
        if (target) {
            slot.pipe(target);
        }
    });
}
function findBlockElementById(container, blockId) {
    const element = container.querySelector(`[data-block-id="${blockId}"]`);
    return element;
}
const useBlockElementById = (containerRef, blockId, timeout = 1000)=>{
    const [blockElement, setBlockElement] = useState(null);
    useEffect(()=>{
        if (!blockId) {
            return;
        }
        let canceled = false;
        const start = Date.now();
        function run() {
            if (canceled || !containerRef.current || !blockId) {
                return;
            }
            const element = findBlockElementById(containerRef.current, blockId);
            if (element) {
                setBlockElement(element);
            } else if (Date.now() - start < timeout) {
                setTimeout(run, 100);
            }
        }
        run();
        return ()=>{
            canceled = true;
        };
    }, [
        blockId,
        containerRef,
        timeout
    ]);
    return blockElement;
};
export const BlocksuiteEditorContainer = forwardRef(function AffineEditorContainer({ page, mode, className, style, defaultSelectedBlockId, shared }, ref) {
    const scrolledRef = useRef(false);
    const hashChangedRef = useRef(false);
    const rootRef = useRef(null);
    const docRef = useRef(null);
    const edgelessRef = useRef(null);
    const slots = useMemo(()=>{
        return {
            docLinkClicked: new Slot(),
            editorModeSwitched: new Slot(),
            docUpdated: new Slot(),
            tagClicked: new Slot()
        };
    }, []);
    useLayoutEffect(()=>{
        requestAnimationFrame(()=>{
            const docPage = rootRef.current?.querySelector('affine-page-root');
            const edgelessPage = rootRef.current?.querySelector('affine-edgeless-root');
            if (docPage) {
                forwardSlot(docPage.slots, slots);
            }
            if (edgelessPage) {
                forwardSlot(edgelessPage.slots, slots);
            }
        });
    }, [
        page,
        slots
    ]);
    useLayoutEffect(()=>{
        slots.docUpdated.emit({
            newDocId: page.id
        });
    }, [
        page,
        slots.docUpdated
    ]);
    useLayoutEffect(()=>{
        slots.editorModeSwitched.emit(mode);
    }, [
        mode,
        slots.editorModeSwitched
    ]);
    const affineEditorContainerProxy = useMemo(()=>{
        const api = {
            slots,
            get page () {
                return page;
            },
            get doc () {
                return page;
            },
            get host () {
                return mode === 'page' ? docRef.current?.host : edgelessRef.current?.host;
            },
            get model () {
                return page.root;
            },
            get updateComplete () {
                return mode === 'page' ? docRef.current?.updateComplete : edgelessRef.current?.updateComplete;
            },
            get mode () {
                return mode;
            }
        };
        const proxy = new Proxy(api, {
            has (_, prop) {
                return Reflect.has(api, prop) || (rootRef.current ? Reflect.has(rootRef.current, prop) : false);
            },
            get (_, prop) {
                if (Reflect.has(api, prop)) {
                    return api[prop];
                }
                if (rootRef.current && Reflect.has(rootRef.current, prop)) {
                    const maybeFn = Reflect.get(rootRef.current, prop);
                    if (typeof maybeFn === 'function') {
                        return maybeFn.bind(rootRef.current);
                    } else {
                        return maybeFn;
                    }
                }
                return undefined;
            }
        });
        return proxy;
    }, [
        mode,
        page,
        slots
    ]);
    useEffect(()=>{
        if (ref) {
            if (typeof ref === 'function') {
                ref(affineEditorContainerProxy);
            } else {
                ref.current = affineEditorContainerProxy;
            }
        }
    }, [
        affineEditorContainerProxy,
        ref
    ]);
    const blockElement = useBlockElementById(rootRef, defaultSelectedBlockId);
    useEffect(()=>{
        let canceled = false;
        const handleScrollToBlock = (blockElement)=>{
            if (!mode || !blockElement) {
                return;
            }
            blockElement.scrollIntoView({
                behavior: 'instant',
                block: 'center'
            });
            const selectManager = affineEditorContainerProxy.host?.selection;
            if (!blockElement.path.length || !selectManager) {
                return;
            }
            const newSelection = selectManager.create('block', {
                blockId: blockElement.blockId
            });
            selectManager.set([
                newSelection
            ]);
        };
        affineEditorContainerProxy.updateComplete.then(()=>{
            if (blockElement && !scrolledRef.current && !canceled && !hashChangedRef.current) {
                handleScrollToBlock(blockElement);
                scrolledRef.current = true;
            }
        }).catch(console.error);
        return ()=>{
            canceled = true;
        };
    }, [
        blockElement,
        affineEditorContainerProxy,
        mode
    ]);
    return _jsx("div", {
        "data-testid": `editor-${page.id}`,
        className: clsx(`editor-wrapper ${mode}-mode`, styles.docEditorRoot, className),
        "data-affine-editor-container": true,
        style: style,
        ref: rootRef,
        children: mode === 'page' ? _jsx(BlocksuiteDocEditor, {
            shared: shared,
            page: page,
            ref: docRef
        }) : _jsx(BlocksuiteEdgelessEditor, {
            shared: shared,
            page: page,
            ref: edgelessRef
        })
    });
});
