import { DebugLogger } from '@affine/debug';
import { UserFriendlyError } from '@affine/graphql';
import { fromPromise, Service } from '@toeverything/infra';
import { BackendError, NetworkError } from '../error';
export function getAffineCloudBaseUrl() {
    if (environment.isDesktop) {
        return runtimeConfig.serverUrlPrefix;
    }
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
}
const logger = new DebugLogger('affine:fetch');
export class FetchService extends Service {
    constructor(...args){
        super(...args);
        this.rxFetch = (input, init)=>{
            return fromPromise((signal)=>{
                return this.fetch(input, {
                    signal,
                    ...init
                });
            });
        };
        this.fetch = async (input, init)=>{
            logger.debug('fetch', input);
            const externalSignal = init?.signal;
            if (externalSignal?.aborted) {
                throw externalSignal.reason;
            }
            const abortController = new AbortController();
            externalSignal?.addEventListener('abort', (reason)=>{
                abortController.abort(reason);
            });
            const timeout = init?.timeout ?? 15000;
            const timeoutId = setTimeout(()=>{
                abortController.abort('timeout');
            }, timeout);
            const res = await fetch(new URL(input, getAffineCloudBaseUrl()), {
                ...init,
                signal: abortController.signal
            }).catch((err)=>{
                logger.debug('network error', err);
                throw new NetworkError(err);
            });
            clearTimeout(timeoutId);
            if (res.status === 504) {
                const error = new Error('Gateway Timeout');
                logger.debug('network error', error);
                throw new NetworkError(error);
            }
            if (!res.ok) {
                logger.warn('backend error', new Error(`${res.status} ${res.statusText}`));
                let reason = '';
                if (res.headers.get('Content-Type')?.includes('application/json')) {
                    try {
                        reason = await res.json();
                    } catch (err) {}
                }
                throw new BackendError(UserFriendlyError.fromAnyError(reason));
            }
            return res;
        };
    }
}
