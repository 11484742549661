import { SpecProvider } from '@blocksuite/blocks';
import { AIChatBlockSpec, EdgelessAIChatBlockSpec } from '@blocksuite/presets';
const CustomSpecs = [
    AIChatBlockSpec,
    EdgelessAIChatBlockSpec
];
function patchPreviewSpec(id, specs) {
    const specProvider = SpecProvider.getInstance();
    specProvider.extendSpec(id, specs);
}
patchPreviewSpec('edgeless:preview', CustomSpecs);
