import { Doc as YDoc } from 'yjs';
import { Service } from '../../../framework';
import { createORMClient, YjsDBAdapter } from '../../../orm';
import { ObjectPool } from '../../../utils';
import { DB } from '../entities/db';
import { AFFiNE_WORKSPACE_DB_SCHEMA } from '../schema';
import { AFFiNE_WORKSPACE_USERDATA_DB_SCHEMA } from '../schema/schema';
const WorkspaceDBClient = createORMClient(AFFiNE_WORKSPACE_DB_SCHEMA);
const WorkspaceUserdataDBClient = createORMClient(AFFiNE_WORKSPACE_USERDATA_DB_SCHEMA);
export class WorkspaceDBService extends Service {
    constructor(workspaceService){
        super();
        this.workspaceService = workspaceService;
        this.userdataDBPool = new ObjectPool({
            onDangling () {
                return false;
            }
        });
        this.db = this.framework.createEntity(DB, {
            db: new WorkspaceDBClient(new YjsDBAdapter(AFFiNE_WORKSPACE_DB_SCHEMA, {
                getDoc: (guid)=>{
                    const ydoc = new YDoc({
                        guid: `db$${this.workspaceService.workspace.id}$${guid}`
                    });
                    this.workspaceService.workspace.engine.doc.addDoc(ydoc, false);
                    this.workspaceService.workspace.engine.doc.setPriority(ydoc.guid, 50);
                    return ydoc;
                }
            })),
            schema: AFFiNE_WORKSPACE_DB_SCHEMA,
            storageDocId: (tableName)=>`db$${this.workspaceService.workspace.id}$${tableName}`
        });
    }
    userdataDB(userId) {
        const userdataDb = this.userdataDBPool.get(userId);
        if (userdataDb) {
            return userdataDb.obj;
        }
        const newDB = this.framework.createEntity(DB, {
            db: new WorkspaceUserdataDBClient(new YjsDBAdapter(AFFiNE_WORKSPACE_USERDATA_DB_SCHEMA, {
                getDoc: (guid)=>{
                    const ydoc = new YDoc({
                        guid: `userdata$${userId}$${this.workspaceService.workspace.id}$${guid}`
                    });
                    this.workspaceService.workspace.engine.doc.addDoc(ydoc, false);
                    this.workspaceService.workspace.engine.doc.setPriority(ydoc.guid, 50);
                    return ydoc;
                }
            })),
            schema: AFFiNE_WORKSPACE_USERDATA_DB_SCHEMA,
            storageDocId: (tableName)=>`userdata$${userId}$${this.workspaceService.workspace.id}$${tableName}`
        });
        this.userdataDBPool.put(userId, newDB);
        return newDB;
    }
    static isDBDocId(docId) {
        return docId.startsWith('db$') || docId.startsWith('userdata$');
    }
}
export async function transformWorkspaceDBLocalToCloud(localWorkspaceId, cloudWorkspaceId, localDocStorage, cloudDocStorage, accountId) {
    for (const tableName of Object.keys(AFFiNE_WORKSPACE_DB_SCHEMA)){
        const localDocName = `db$${localWorkspaceId}$${tableName}`;
        const localDoc = await localDocStorage.doc.get(localDocName);
        if (localDoc) {
            const cloudDocName = `db$${cloudWorkspaceId}$${tableName}`;
            await cloudDocStorage.doc.set(cloudDocName, localDoc);
        }
    }
    for (const tableName of Object.keys(AFFiNE_WORKSPACE_USERDATA_DB_SCHEMA)){
        const localDocName = `userdata$__local__$${localWorkspaceId}$${tableName}`;
        const localDoc = await localDocStorage.doc.get(localDocName);
        if (localDoc) {
            const cloudDocName = `userdata$${accountId}$${cloudWorkspaceId}$${tableName}`;
            await cloudDocStorage.doc.set(cloudDocName, localDoc);
        }
    }
}
