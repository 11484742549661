import { DebugLogger } from '@affine/debug';
import { Unreachable } from '@affine/env/constant';
import { Subject } from 'rxjs';
const logger = new DebugLogger('effect');
export function effect(...args) {
    const subject$ = new Subject();
    const effectLocation = environment.isDebug ? `(${new Error().stack?.split('\n')[2].trim()})` : '';
    class EffectError extends Unreachable {
        constructor(message, value){
            logger.error(`effect ${effectLocation} ${message}`, value);
            super(`effect ${effectLocation} ${message}` + ` ${value ? value instanceof Error ? value.stack ?? value.message : value + '' : ''}`);
        }
    }
    const subscription = subject$.pipe.apply(subject$, args).subscribe({
        next (value) {
            const error = new EffectError('should not emit value', value);
            setImmediate(()=>{
                throw error;
            });
        },
        complete () {
            const error = new EffectError('effect unexpected complete');
            setImmediate(()=>{
                throw error;
            });
        },
        error (error) {
            const effectError = new EffectError('effect uncaught error', error);
            setImmediate(()=>{
                throw effectError;
            });
        }
    });
    const fn = (value)=>{
        subject$.next(value);
    };
    fn.unsubscribe = ()=>subscription.unsubscribe();
    return fn;
}
