function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _init_host, _init_doc, _init_isLoading, _init_chatContextValue, _initProto;
import './chat-panel-input';
import './chat-panel-messages';
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { debounce } from '@blocksuite/global/utils';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { AIHelpIcon, SmallHintIcon } from '../_common/icons';
import { AIProvider } from '../provider';
import { getSelectedImagesAsBlobs, getSelectedTextContent } from '../utils/selection-utils';
let _ChatPanel;
_dec = customElement('chat-panel'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = state(), _dec4 = state();
new class extends _identity {
    constructor(){
        super(_ChatPanel), _initClass();
    }
    static{
        class ChatPanel extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_host, _init_doc, _init_isLoading, _init_chatContextValue, _initProto], c: [_ChatPanel, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "host"
                    ],
                    [
                        _dec2,
                        1,
                        "doc"
                    ],
                    [
                        _dec3,
                        1,
                        "isLoading"
                    ],
                    [
                        _dec4,
                        1,
                        "chatContextValue"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css`
    chat-panel {
      width: 100%;
    }

    .chat-panel-container {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      padding-top: 8px;
      height: 100%;
    }

    .chat-panel-title {
      background: var(--affine-background-primary-color);
      position: relative;
      padding: 8px 0px;
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      div:first-child {
        font-size: 14px;
        font-weight: 500;
        color: var(--affine-text-secondary-color);
      }

      div:last-child {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    chat-panel-messages {
      flex: 1;
      overflow-y: hidden;
    }

    .chat-panel-hints {
      margin: 0 4px;
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid var(--affine-border-color);
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }

    .chat-panel-hints :first-child {
      color: var(--affine-text-primary-color);
    }

    .chat-panel-hints :nth-child(2) {
      color: var(--affine-text-secondary-color);
    }

    .chat-panel-footer {
      margin: 8px 0px;
      height: 20px;
      display: flex;
      gap: 4px;
      align-items: center;
      color: var(--affine-text-secondary-color);
      font-size: 12px;
      user-select: none;
    }
  `;
            }
            #___private_host_1;
            get host() {
                return this.#___private_host_1;
            }
            set host(_v) {
                this.#___private_host_1 = _v;
            }
            #___private_doc_2;
            get doc() {
                return this.#___private_doc_2;
            }
            set doc(_v) {
                this.#___private_doc_2 = _v;
            }
            #___private_isLoading_3;
            get isLoading() {
                return this.#___private_isLoading_3;
            }
            set isLoading(_v) {
                this.#___private_isLoading_3 = _v;
            }
            #___private_chatContextValue_4;
            get chatContextValue() {
                return this.#___private_chatContextValue_4;
            }
            set chatContextValue(_v) {
                this.#___private_chatContextValue_4 = _v;
            }
            updated(_changedProperties) {
                if (_changedProperties.has('doc')) {
                    this.chatContextValue.chatSessionId = null;
                    this._resetItems();
                }
            }
            connectedCallback() {
                super.connectedCallback();
                if (!this.doc) throw new Error('doc is required');
                AIProvider.slots.actions.on(({ action, event })=>{
                    const { status } = this.chatContextValue;
                    if (action !== 'chat' && event === 'finished' && (status === 'idle' || status === 'success')) {
                        this._resetItems();
                    }
                    if (action === 'chat' && event === 'finished') {
                        AIProvider.slots.toggleChatCards.emit({
                            visible: true,
                            ok: status === 'success'
                        });
                    }
                });
                AIProvider.slots.userInfo.on((userInfo)=>{
                    if (userInfo) {
                        this._resetItems();
                    }
                });
            }
            scrollToDown() {
                requestAnimationFrame(()=>this._chatMessages.value?.scrollToDown());
            }
            render() {
                return html` <div class="chat-panel-container">
      <div class="chat-panel-title">
        <div>Wemem AI</div>
        <div
          @click=${()=>{
                    AIProvider.toggleGeneralAIOnboarding?.(true);
                }}
        >
          ${AIHelpIcon}
        </div>
      </div>
      <chat-panel-messages
        ${ref(this._chatMessages)}
        .chatContextValue=${this.chatContextValue}
        .updateContext=${this.updateContext}
        .host=${this.host}
        .isLoading=${this.isLoading}
      ></chat-panel-messages>
      <chat-panel-input
        .chatContextValue=${this.chatContextValue}
        .updateContext=${this.updateContext}
        .host=${this.host}
        .cleanupHistories=${this._cleanupHistories}
      ></chat-panel-input>
      <div class="chat-panel-footer">
        ${SmallHintIcon}
        <div>AI outputs can be misleading or wrong</div>
      </div>
    </div>`;
            }
            constructor(...args){
                super(...args);
                this._chatMessages = createRef();
                this._resettingCounter = 0;
                this._resetItems = debounce(()=>{
                    const counter = ++this._resettingCounter;
                    this.isLoading = true;
                    (async ()=>{
                        const { doc } = this;
                        const [histories, actions] = await Promise.all([
                            AIProvider.histories?.chats(doc.collection.id, doc.id, {
                                fork: false
                            }),
                            AIProvider.histories?.actions(doc.collection.id, doc.id)
                        ]);
                        if (counter !== this._resettingCounter) return;
                        const items = actions ? [
                            ...actions
                        ] : [];
                        if (histories?.at(-1)) {
                            const history = histories.at(-1);
                            if (!history) return;
                            this.chatContextValue.chatSessionId = history.sessionId;
                            items.push(...history.messages);
                            AIProvider.LAST_ROOT_SESSION_ID = history.sessionId;
                        }
                        this.chatContextValue = {
                            ...this.chatContextValue,
                            items: items.sort((a, b)=>{
                                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                            })
                        };
                        this.isLoading = false;
                        this.scrollToDown();
                    })().catch(console.error);
                }, 200);
                this.#___private_host_1 = (_initProto(this), _init_host(this));
                this.#___private_doc_2 = _init_doc(this);
                this.#___private_isLoading_3 = _init_isLoading(this, false);
                this.#___private_chatContextValue_4 = _init_chatContextValue(this, {
                    quote: '',
                    images: [],
                    abortController: null,
                    items: [],
                    status: 'idle',
                    error: null,
                    markdown: '',
                    chatSessionId: null
                });
                this._cleanupHistories = async ()=>{
                    const notification = this.host.std.spec.getService('affine:page').notificationService;
                    if (!notification) return;
                    if (await notification.confirm({
                        title: 'Clear History',
                        message: 'Are you sure you want to clear all history? This action will permanently delete all content, including all chat logs and data, and cannot be undone.',
                        confirmText: 'Confirm',
                        cancelText: 'Cancel'
                    })) {
                        await AIProvider.histories?.cleanup(this.doc.collection.id, this.doc.id, [
                            this.chatContextValue.chatSessionId ?? '',
                            ...this.chatContextValue.items.filter((item)=>'sessionId' in item).map((item)=>item.sessionId)
                        ]);
                        this.chatContextValue.chatSessionId = null;
                        notification.toast('History cleared');
                        this._resetItems();
                    }
                };
                this.updateContext = (context)=>{
                    this.chatContextValue = {
                        ...this.chatContextValue,
                        ...context
                    };
                };
                this.continueInChat = async ()=>{
                    const text = await getSelectedTextContent(this.host, 'plain-text');
                    const markdown = await getSelectedTextContent(this.host, 'markdown');
                    const images = await getSelectedImagesAsBlobs(this.host);
                    this.updateContext({
                        quote: text,
                        markdown,
                        images
                    });
                };
            }
        }
    }
}();
export { _ChatPanel as ChatPanel };
