function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _initClass, _WidgetComponent;
import { WidgetComponent } from '@blocksuite/block-std';
import { offset, shift } from '@floating-ui/dom';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { join } from 'lit/directives/join.js';
import { repeat } from 'lit/directives/repeat.js';
import { HoverController } from '../../../_common/components/hover/controller.js';
import { isPeekable, peek } from '../../../_common/components/peekable.js';
import { toast } from '../../../_common/components/toast.js';
import '../../../_common/components/toolbar/icon-button.js';
import { renderToolbarSeparator } from '../../../_common/components/toolbar/separator.js';
import '../../../_common/components/toolbar/toolbar.js';
import '../../../_common/components/toolbar/menu-button.js';
import { renderActions } from '../../../_common/components/toolbar/utils.js';
import { PAGE_HEADER_HEIGHT } from '../../../_common/consts.js';
import { EdgelessModeIcon, MoreVerticalIcon, SmallArrowDownIcon } from '../../../_common/icons/edgeless.js';
import { CaptionIcon, CenterPeekIcon, CopyIcon, DeleteIcon, DownloadIcon, OpenIcon } from '../../../_common/icons/text.js';
import { downloadBlob } from '../../../_common/utils/filesys.js';
import { edgelessToBlob, writeImageBlobToClipboard } from './utils.js';
export const AFFINE_SURFACE_REF_TOOLBAR = 'affine-surface-ref-toolbar';
let _AffineSurfaceRefToolbar;
_dec = customElement(AFFINE_SURFACE_REF_TOOLBAR);
class AffineSurfaceRefToolbar extends (_WidgetComponent = WidgetComponent) {
    static{
        ({ c: [_AffineSurfaceRefToolbar, _initClass] } = _apply_decs_2203_r(this, [], [
            _dec
        ], _WidgetComponent));
    }
    connectedCallback() {
        super.connectedCallback();
        this._hoverController.setReference(this.block);
    }
    static{
        _initClass();
    }
    constructor(...args){
        super(...args);
        this._hoverController = new HoverController(this, ({ abortController })=>{
            const surfaceRefBlock = this.block;
            const selection = this.host.selection;
            const textSelection = selection.find('text');
            if (!!textSelection && (!!textSelection.to || !!textSelection.from.length)) {
                return null;
            }
            const blockSelections = selection.filter('block');
            if (blockSelections.length > 1 || blockSelections.length === 1 && blockSelections[0].blockId !== surfaceRefBlock.blockId) {
                return null;
            }
            return {
                template: SurfaceRefToolbarOptions({
                    block: this.block,
                    model: this.block.model,
                    abortController
                }),
                computePosition: {
                    referenceElement: this.block,
                    placement: 'top-start',
                    middleware: [
                        offset({
                            mainAxis: 12,
                            crossAxis: 10
                        }),
                        shift({
                            crossAxis: true,
                            padding: {
                                top: PAGE_HEADER_HEIGHT + 12,
                                bottom: 12,
                                right: 12
                            }
                        })
                    ],
                    autoUpdate: true
                }
            };
        });
    }
}
function SurfaceRefToolbarOptions(options) {
    const { block, model, abortController } = options;
    const readonly = model.doc.readonly;
    const hasValidReference = !!block.referenceModel;
    const openMenuActions = [];
    if (hasValidReference) {
        openMenuActions.push({
            name: 'Open in edgeless',
            icon: EdgelessModeIcon,
            handler: ()=>block.viewInEdgeless(),
            disabled: readonly
        });
        if (isPeekable(block)) {
            openMenuActions.push({
                name: 'Open in center peek',
                icon: CenterPeekIcon,
                handler: ()=>peek(block)
            });
        }
    }
    const moreMenuActions = [
        hasValidReference ? [
            {
                type: 'copy',
                name: 'Copy',
                icon: CopyIcon,
                handler: ()=>{
                    if (!block.referenceModel || !block.doc.root) return;
                    const editor = block.previewEditor;
                    const edgelessRootElement = editor?.view.getBlock(block.doc.root.id);
                    const surfaceRenderer = edgelessRootElement?.surface?.renderer;
                    edgelessToBlob(block.host, {
                        surfaceRefBlock: block,
                        surfaceRenderer,
                        edgelessElement: block.referenceModel
                    }).then((blob)=>{
                        return writeImageBlobToClipboard(blob);
                    }).then(()=>{
                        toast(block.host, 'Copied image to clipboard');
                    }).catch((err)=>{
                        console.error(err);
                    });
                }
            },
            {
                type: 'download',
                name: 'Download',
                icon: DownloadIcon,
                handler: ()=>{
                    if (!block.referenceModel || !block.doc.root) return;
                    const referencedModel = block.referenceModel;
                    const editor = block.previewEditor;
                    const edgelessRootElement = editor?.view.getBlock(block.doc.root.id);
                    const surfaceRenderer = edgelessRootElement?.surface?.renderer;
                    edgelessToBlob(block.host, {
                        surfaceRefBlock: block,
                        surfaceRenderer,
                        edgelessElement: referencedModel
                    }).then((blob)=>{
                        const fileName = 'title' in referencedModel ? referencedModel.title?.toString() ?? 'Edgeless Content' : 'Edgeless Content';
                        downloadBlob(blob, fileName);
                    }).catch((err)=>{
                        console.error(err);
                    });
                }
            }
        ] : [],
        [
            {
                type: 'delete',
                name: 'Delete',
                icon: DeleteIcon,
                disabled: readonly,
                handler: ()=>{
                    model.doc.deleteBlock(model);
                    abortController.abort();
                }
            }
        ]
    ];
    const buttons = [
        openMenuActions.length ? html`
          <editor-menu-button
            .contentPadding=${'8px'}
            .button=${html`
              <editor-icon-button
                aria-label="Open doc"
                .justify=${'space-between'}
                .labelHeight=${'20px'}
              >
                ${OpenIcon}${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <div data-size="large" data-orientation="vertical">
              ${repeat(openMenuActions, (button)=>button.name, ({ name, icon, handler, disabled })=>html`
                  <editor-menu-action
                    aria-label=${name}
                    ?disabled=${disabled}
                    @click=${handler}
                  >
                    ${icon}<span class="label">${name}</span>
                  </editor-menu-action>
                `)}
            </div>
          </editor-menu-button>
        ` : nothing,
        readonly ? nothing : html`
          <editor-icon-button
            aria-label="Caption"
            @click=${()=>{
            abortController.abort();
            block.captionElement.show();
        }}
          >
            ${CaptionIcon}
          </editor-icon-button>
        `,
        html`
      <editor-menu-button
        .contentPadding=${'8px'}
        .button=${html`
          <editor-icon-button aria-label="More" .tooltip=${'More'}>
            ${MoreVerticalIcon}
          </editor-icon-button>
        `}
      >
        <div data-size="large" data-orientation="vertical">
          ${renderActions(moreMenuActions)}
        </div>
      </editor-menu-button>
    `
    ];
    return html`
    <editor-toolbar class="surface-ref-toolbar-container">
      ${join(buttons.filter((button)=>button !== nothing), renderToolbarSeparator)}
    </editor-toolbar>
  `;
}
export { _AffineSurfaceRefToolbar as AffineSurfaceRefToolbar };
