import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';
import { menuItemIcon } from './styles.css';
export const MenuIcon = forwardRef(({ children, icon, position = 'start', className, ...otherProps }, ref)=>{
    return _jsx("div", {
        ref: ref,
        className: useMemo(()=>clsx(menuItemIcon, {
                end: position === 'end',
                start: position === 'start'
            }, className), [
            className,
            position
        ]),
        ...otherProps,
        children: icon || children
    });
});
MenuIcon.displayName = 'MenuIcon';
