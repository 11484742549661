import { throttle } from '@blocksuite/global/utils';
import { BlockComponent } from '../view/element/block-component.js';
import { RangeManager } from './range-manager.js';
export class RangeBinding {
    constructor(manager){
        this.manager = manager;
        this._compositionStartCallback = null;
        this._onBeforeInput = (event)=>{
            const selection = this.selectionManager.find('text');
            if (!selection) return;
            if (event.isComposing) return;
            const { from, to } = selection;
            if (!to || from.blockId === to.blockId) return;
            const range = this.rangeManager?.value;
            if (!range) return;
            const blocks = this.rangeManager.getSelectedBlockComponentsByRange(range, {
                mode: 'flat'
            });
            const start = blocks.at(0);
            const end = blocks.at(-1);
            if (!start || !end) return;
            const startText = start.model.text;
            const endText = end.model.text;
            if (!startText || !endText) return;
            event.preventDefault();
            this.host.doc.transact(()=>{
                startText.delete(from.index, from.length);
                startText.insert(event.data ?? '', from.index);
                endText.delete(0, to.length);
                startText.join(endText);
                blocks.slice(1).reverse().forEach((block)=>{
                    const parent = this.host.doc.getParent(block.model);
                    if (!parent) return;
                    this.host.doc.deleteBlock(block.model, {
                        bringChildrenTo: parent
                    });
                });
            });
            const newSelection = this.selectionManager.create('text', {
                from: {
                    blockId: from.blockId,
                    index: from.index + (event.data?.length ?? 0),
                    length: 0
                },
                to: null
            });
            this.selectionManager.setGroup('note', [
                newSelection
            ]);
        };
        this._onCompositionEnd = (event)=>{
            if (this._compositionStartCallback) {
                event.preventDefault();
                this._compositionStartCallback(event).catch(console.error);
                this._compositionStartCallback = null;
            }
        };
        this._onCompositionStart = ()=>{
            const selection = this.selectionManager.find('text');
            if (!selection) return;
            const { from, to } = selection;
            if (!to) return;
            this.isComposing = true;
            const range = this.rangeManager?.value;
            if (!range) return;
            const blocks = this.rangeManager.getSelectedBlockComponentsByRange(range, {
                mode: 'flat'
            });
            const highestBlocks = this.rangeManager.getSelectedBlockComponentsByRange(range, {
                mode: 'highest',
                match: (block)=>block.model.role === 'content'
            });
            const start = blocks.at(0);
            const end = blocks.at(-1);
            if (!start || !end) return;
            const startText = start.model.text;
            const endText = end.model.text;
            if (!startText || !endText) return;
            this._compositionStartCallback = async (event)=>{
                this.isComposing = false;
                const parents = [];
                for (const highestBlock of highestBlocks){
                    const parentModel = this.host.doc.getParent(highestBlock.blockId);
                    if (!parentModel) continue;
                    const parent = this.host.view.getBlock(parentModel.id);
                    if (!(parent instanceof BlockComponent) || parents.includes(parent)) continue;
                    parent.dirty = true;
                    await parent.updateComplete;
                    await parent.updateComplete;
                    parents.push(parent);
                }
                this.host.doc.transact(()=>{
                    endText.delete(0, to.length);
                    startText.join(endText);
                    blocks.slice(1).reverse().forEach((block)=>{
                        const parent = this.host.doc.getParent(block.model);
                        if (!parent) return;
                        this.host.doc.deleteBlock(block.model, {
                            bringChildrenTo: parent
                        });
                    });
                });
                await this.host.updateComplete;
                const selection = this.selectionManager.create('text', {
                    from: {
                        blockId: from.blockId,
                        index: from.index + (event.data?.length ?? 0),
                        length: 0
                    },
                    to: null
                });
                this.host.selection.setGroup('note', [
                    selection
                ]);
                this.rangeManager?.syncTextSelectionToRange(selection);
            };
        };
        this._onNativeSelectionChanged = async ()=>{
            if (this.isComposing) return;
            await this.host.updateComplete;
            const selection = document.getSelection();
            if (!selection) {
                this.selectionManager.clear([
                    'text'
                ]);
                return;
            }
            const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
            const isRangeReversed = !!selection.anchorNode && !!selection.focusNode && (selection.anchorNode === selection.focusNode ? selection.anchorOffset > selection.focusOffset : selection.anchorNode.compareDocumentPosition(selection.focusNode) === Node.DOCUMENT_POSITION_PRECEDING);
            if (!range) {
                this._prevTextSelection = null;
                this.selectionManager.clear([
                    'text'
                ]);
                return;
            }
            const isRangeOutNotEditable = range.startContainer instanceof HTMLElement && range.startContainer.contentEditable === 'false' && range.endContainer instanceof HTMLElement && range.endContainer.contentEditable === 'false';
            if (isRangeOutNotEditable) {
                this._prevTextSelection = null;
                this.selectionManager.clear([
                    'text'
                ]);
                selection.removeRange(range);
                return;
            }
            const el = range.commonAncestorContainer instanceof Element ? range.commonAncestorContainer : range.commonAncestorContainer.parentElement;
            if (!el) return;
            const block = el.closest(`[${this.host.blockIdAttr}]`);
            if (block?.getAttribute(RangeManager.rangeSyncExcludeAttr) === 'true') return;
            const inlineEditor = this.rangeManager?.getClosestInlineEditor(range.commonAncestorContainer);
            if (inlineEditor?.isComposing) return;
            const textSelection = this.rangeManager?.rangeToTextSelection(range, isRangeReversed);
            if (!textSelection) {
                this._prevTextSelection = null;
                this.selectionManager.clear([
                    'text'
                ]);
                return;
            }
            const model = this.host.doc.getBlockById(textSelection.blockId);
            if (!model) return;
            const path = this.host.view.calculatePath(model);
            this._prevTextSelection = {
                selection: textSelection,
                path
            };
            this.rangeManager?.syncRangeToTextSelection(range, isRangeReversed);
        };
        this._onStdSelectionChanged = (selections)=>{
            const text = selections.find((selection)=>selection.is('text')) ?? null;
            if (text === this._prevTextSelection) {
                return;
            }
            this.host.updateComplete.then(()=>{
                const model = text && this.host.doc.getBlockById(text.blockId);
                const path = model && this.host.view.calculatePath(model);
                const eq = text && this._prevTextSelection && path ? text.equals(this._prevTextSelection.selection) && path.join('') === this._prevTextSelection.path.join('') : false;
                if (eq) return;
                this._prevTextSelection = text && path ? {
                    selection: text,
                    path: path
                } : null;
                if (text) {
                    this.rangeManager?.syncTextSelectionToRange(text);
                } else {
                    this.rangeManager?.clear();
                }
            }).catch(console.error);
        };
        this._prevTextSelection = null;
        this.isComposing = false;
        this.host.disposables.add(this.selectionManager.slots.changed.on(this._onStdSelectionChanged));
        this.host.disposables.addFromEvent(document, 'selectionchange', throttle(()=>{
            this._onNativeSelectionChanged().catch(console.error);
        }, 10));
        this.host.disposables.add(this.host.event.add('beforeInput', (ctx)=>{
            const event = ctx.get('defaultState').event;
            this._onBeforeInput(event);
        }));
        this.host.disposables.add(this.host.event.add('compositionStart', this._onCompositionStart));
        this.host.disposables.add(this.host.event.add('compositionEnd', (ctx)=>{
            const event = ctx.get('defaultState').event;
            this._onCompositionEnd(event);
        }));
    }
    get host() {
        return this.manager.host;
    }
    get rangeManager() {
        return this.host.range;
    }
    get selectionManager() {
        return this.host.selection;
    }
}
