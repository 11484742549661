import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDeleteCollectionInfo } from '@affine/core/hooks/affine/use-delete-collection-info';
import { Trans } from '@affine/i18n';
import { useService, WorkspaceService } from '@toeverything/infra';
import { useCallback, useMemo, useRef, useState } from 'react';
import { CollectionService } from '../../../modules/collection';
import { ListFloatingToolbar } from '../components/list-floating-toolbar';
import { collectionHeaderColsDef } from '../header-col-def';
import { CollectionOperationCell } from '../operation-cell';
import { CollectionListItemRenderer } from '../page-group';
import { ListTableHeader } from '../page-header';
import { VirtualizedList } from '../virtualized-list';
import { CollectionListHeader } from './collection-list-header';
const useCollectionOperationsRenderer = ({ info, service })=>{
    const collectionOperationsRenderer = useCallback((collection)=>{
        return _jsx(CollectionOperationCell, {
            info: info,
            collection: collection,
            service: service
        });
    }, [
        info,
        service
    ]);
    return collectionOperationsRenderer;
};
export const VirtualizedCollectionList = ({ collections, collectionMetas, setHideHeaderCreateNewCollection, node, handleCreateCollection })=>{
    const listRef = useRef(null);
    const [showFloatingToolbar, setShowFloatingToolbar] = useState(false);
    const [selectedCollectionIds, setSelectedCollectionIds] = useState([]);
    const collectionService = useService(CollectionService);
    const currentWorkspace = useService(WorkspaceService).workspace;
    const info = useDeleteCollectionInfo();
    const collectionOperations = useCollectionOperationsRenderer({
        info,
        service: collectionService
    });
    const filteredSelectedCollectionIds = useMemo(()=>{
        const ids = collections.map((collection)=>collection.id);
        return selectedCollectionIds.filter((id)=>ids.includes(id));
    }, [
        collections,
        selectedCollectionIds
    ]);
    const hideFloatingToolbar = useCallback(()=>{
        listRef.current?.toggleSelectable();
    }, []);
    const collectionOperationRenderer = useCallback((item)=>{
        const collection = item;
        return collectionOperations(collection);
    }, [
        collectionOperations
    ]);
    const collectionHeaderRenderer = useCallback(()=>{
        return _jsx(ListTableHeader, {
            headerCols: collectionHeaderColsDef
        });
    }, []);
    const collectionItemRenderer = useCallback((item)=>{
        return _jsx(CollectionListItemRenderer, {
            ...item
        });
    }, []);
    const handleDelete = useCallback(()=>{
        if (selectedCollectionIds.length === 0) {
            return;
        }
        collectionService.deleteCollection(info, ...selectedCollectionIds);
        hideFloatingToolbar();
    }, [
        collectionService,
        hideFloatingToolbar,
        info,
        selectedCollectionIds
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(VirtualizedList, {
                ref: listRef,
                selectable: "toggle",
                draggable: true,
                atTopThreshold: 80,
                atTopStateChange: setHideHeaderCreateNewCollection,
                onSelectionActiveChange: setShowFloatingToolbar,
                heading: _jsx(CollectionListHeader, {
                    node: node,
                    onCreate: handleCreateCollection
                }),
                selectedIds: filteredSelectedCollectionIds,
                onSelectedIdsChange: setSelectedCollectionIds,
                items: collectionMetas,
                itemRenderer: collectionItemRenderer,
                rowAsLink: true,
                docCollection: currentWorkspace.docCollection,
                operationsRenderer: collectionOperationRenderer,
                headerRenderer: collectionHeaderRenderer
            }),
            _jsx(ListFloatingToolbar, {
                open: showFloatingToolbar,
                content: _jsxs(Trans, {
                    i18nKey: "com.affine.collection.toolbar.selected",
                    count: selectedCollectionIds.length,
                    children: [
                        _jsx("div", {
                            style: {
                                color: 'var(--affine-text-secondary-color)'
                            },
                            children: {
                                count: selectedCollectionIds.length
                            }
                        }),
                        "selected"
                    ]
                }),
                onClose: hideFloatingToolbar,
                onDelete: handleDelete
            })
        ]
    });
};
