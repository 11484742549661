import { EdgelessIcon, PageIcon } from '@blocksuite/icons/rc';
import { Entity, LiveData } from '@toeverything/infra';
const group = {
    id: 'creation',
    label: {
        key: 'com.affine.quicksearch.group.creation'
    },
    score: 0
};
export class CreationQuickSearchSession extends Entity {
    query(query) {
        this.query$.next(query);
    }
    constructor(...args){
        super(...args);
        this.query$ = new LiveData('');
        this.items$ = LiveData.computed((get)=>{
            const query = get(this.query$);
            if (!query.trim()) {
                return [];
            }
            return [
                {
                    id: 'creation:create-page',
                    source: 'creation',
                    label: {
                        key: 'com.affine.cmdk.affine.create-new-page-as',
                        options: {
                            keyWord: query
                        }
                    },
                    group,
                    icon: PageIcon,
                    payload: {
                        mode: 'edgeless',
                        title: query
                    }
                },
                {
                    id: 'creation:create-edgeless',
                    source: 'creation',
                    label: {
                        key: 'com.affine.cmdk.affine.create-new-edgeless-as',
                        options: {
                            keyWord: query
                        }
                    },
                    group,
                    icon: EdgelessIcon,
                    payload: {
                        mode: 'edgeless',
                        title: query
                    }
                }
            ];
        });
    }
}
