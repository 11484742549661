import { jsx as _jsx } from "react/jsx-runtime";
import { MenuIcon, MenuItem } from '@affine/component';
import { PublicLinkDisableModal } from '@affine/component/disable-public-link';
import { useI18n } from '@affine/i18n';
import { ShareIcon } from '@blocksuite/icons/rc';
export const DisablePublicSharing = (props)=>{
    const t = useI18n();
    return _jsx(MenuItem, {
        type: "danger",
        preFix: _jsx(MenuIcon, {
            children: _jsx(ShareIcon, {})
        }),
        ...props,
        children: t['Disable Public Sharing']()
    });
};
DisablePublicSharing.DisablePublicSharingModal = PublicLinkDisableModal;
