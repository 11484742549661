import { jsx as _jsx } from "react/jsx-runtime";
import { useI18n } from '@affine/i18n';
import { ConfirmModal } from '../../ui/modal';
export const PublicLinkDisableModal = (props)=>{
    const t = useI18n();
    return _jsx(ConfirmModal, {
        title: t['com.affine.publicLinkDisableModal.title'](),
        description: t['com.affine.publicLinkDisableModal.description'](),
        cancelText: t['com.affine.publicLinkDisableModal.button.cancel'](),
        confirmText: t['com.affine.publicLinkDisableModal.button.disable'](),
        confirmButtonOptions: {
            variant: 'error',
            ['data-testid']: 'confirm-enable-affine-cloud-button'
        },
        ...props
    });
};
