import { assertExists } from '@blocksuite/global/utils';
import { BLOCK_ID_ATTR as ATTR, BLOCK_CHILDREN_CONTAINER_PADDING_LEFT as PADDING_LEFT } from '../consts.js';
import { clamp } from './math.js';
import { matchFlavours } from './model.js';
const ATTR_SELECTOR = `[${ATTR}]`;
const MAX_SPACE = 32;
const STEPS = MAX_SPACE / 2 / 2;
export function getNextBlock(editorHost, model, map = {}) {
    if (model.id in map) {
        console.error("Can't get next block! There's a loop in the block tree!");
        return null;
    }
    map[model.id] = true;
    const doc = model.doc;
    if (model.children.length) {
        return model.children[0];
    }
    let currentBlock = model;
    while(currentBlock){
        const nextSibling = doc.getNext(currentBlock);
        if (nextSibling) {
            if (matchFlavours(nextSibling, [
                'affine:note'
            ])) {
                if (isInsideEdgelessEditor(editorHost)) {
                    return null;
                }
                return getNextBlock(editorHost, nextSibling);
            }
            return nextSibling;
        }
        currentBlock = doc.getParent(currentBlock);
    }
    return null;
}
export function getPreviousBlock(editorHost, model) {
    const getPrev = (model)=>{
        const parent = model.doc.getParent(model);
        if (!parent) return null;
        const index = parent.children.indexOf(model);
        if (index > 0) {
            let prev = parent.children[index - 1];
            while(prev.children.length > 0){
                prev = prev.children[prev.children.length - 1];
            }
            return prev;
        }
        if (isInsideEdgelessEditor(editorHost) && matchFlavours(parent, [
            'affine:note'
        ])) {
            return null;
        }
        return parent;
    };
    const map = {};
    const iterate = (model)=>{
        if (model.id in map) {
            console.error("Can't get previous block! There's a loop in the block tree!");
            return null;
        }
        map[model.id] = true;
        const prev = getPrev(model);
        if (prev) {
            if (prev.role === 'content' && !matchFlavours(prev, [
                'affine:frame'
            ])) {
                return prev;
            } else {
                return iterate(prev);
            }
        } else {
            return null;
        }
    };
    return iterate(model);
}
export function buildPath(model) {
    const path = [];
    let current = model;
    while(current){
        path.unshift(current.id);
        current = current.doc.getParent(current);
    }
    return path;
}
export function blockComponentGetter(model, view) {
    if (matchFlavours(model, [
        'affine:image',
        'affine:frame'
    ])) {
        let current = model;
        let id = null;
        while(current){
            if (!matchFlavours(current, [
                'affine:surface'
            ])) {
                id = current.id;
                break;
            }
            current = current.doc.getParent(current);
        }
        return view.getBlock(id || model.id);
    } else {
        return view.getBlock(model.id);
    }
}
export function getRootByElement(element) {
    const pageRoot = getPageRootByElement(element);
    if (pageRoot) return pageRoot;
    const edgelessRoot = getEdgelessRootByElement(element);
    if (edgelessRoot) return edgelessRoot;
    return null;
}
export function getRootByEditorHost(editorHost) {
    return getPageRootByEditorHost(editorHost) ?? getEdgelessRootByEditorHost(editorHost);
}
export function getPageRootByElement(element) {
    return element.closest('affine-page-root');
}
export function getPageRootByEditorHost(editorHost) {
    return editorHost.querySelector('affine-page-root');
}
export function getEdgelessRootByElement(element) {
    return element.closest('affine-edgeless-root');
}
export function getEdgelessRootByEditorHost(editorHost) {
    return editorHost.querySelector('affine-edgeless-root');
}
export function isInsidePageEditor(host) {
    return Array.from(host.children).some((v)=>v.tagName.toLowerCase() === 'affine-page-root');
}
export function isInsideEdgelessEditor(host) {
    return Array.from(host.children).some((v)=>v.tagName.toLowerCase() === 'affine-edgeless-root');
}
export function getViewportElement(editorHost) {
    if (!isInsidePageEditor(editorHost)) return null;
    const doc = editorHost.doc;
    if (!doc.root) {
        console.error('Failed to get root doc');
        return null;
    }
    const rootComponent = editorHost.view.viewFromPath('block', [
        doc.root.id
    ]);
    if (!rootComponent || rootComponent.closest('affine-page-root') !== rootComponent) {
        console.error('Failed to get viewport element!');
        return null;
    }
    return rootComponent.viewportElement;
}
export function getBlockComponentByModel(editorHost, model) {
    if (!model) return null;
    return getBlockComponentByPath(editorHost, model.id);
}
export function getBlockComponentByPath(editorHost, blockId) {
    return editorHost.view.getBlock(blockId);
}
export async function asyncGetBlockComponent(editorHost, id) {
    const rootBlockId = editorHost.doc.root?.id;
    if (!rootBlockId) return null;
    const rootComponent = editorHost.view.getBlock(rootBlockId);
    if (!rootComponent) return null;
    await rootComponent.updateComplete;
    return editorHost.view.getBlock(id);
}
export function getRichTextByModel(editorHost, id) {
    const blockComponent = editorHost.view.getBlock(id);
    const richText = blockComponent?.querySelector('rich-text');
    if (!richText) return null;
    return richText;
}
export async function asyncGetRichText(editorHost, id) {
    const blockComponent = await asyncGetBlockComponent(editorHost, id);
    if (!blockComponent) return null;
    await blockComponent.updateComplete;
    const richText = blockComponent?.querySelector('rich-text');
    if (!richText) return null;
    return richText;
}
export function getInlineEditorByModel(editorHost, model) {
    if (matchFlavours(model, [
        'affine:database'
    ])) {
        return null;
    }
    const richText = getRichTextByModel(editorHost, model.id);
    if (!richText) return null;
    return richText.inlineEditor;
}
export function getModelByElement(element) {
    const closestBlock = element.closest(ATTR_SELECTOR);
    assertExists(closestBlock, 'Cannot find block element by element');
    return getModelByBlockComponent(closestBlock);
}
export function getDocTitleByEditorHost(editorHost) {
    const docViewport = editorHost.closest('.affine-page-viewport');
    if (!docViewport) return null;
    return docViewport.querySelector('doc-title');
}
export function getDocTitleInlineEditor(editorHost) {
    const docTitle = getDocTitleByEditorHost(editorHost);
    if (!docTitle) return null;
    const titleRichText = docTitle.querySelector('rich-text');
    assertExists(titleRichText);
    return titleRichText.inlineEditor;
}
function contains(parent, node) {
    return parent.compareDocumentPosition(node) & Node.DOCUMENT_POSITION_CONTAINED_BY;
}
function hasBlockId(element) {
    return element.hasAttribute(ATTR);
}
function isRootOrNoteOrSurface(element) {
    return matchFlavours(element.model, [
        'affine:page',
        'affine:note',
        'affine:surface'
    ]);
}
function isBlock(element) {
    return !isRootOrNoteOrSurface(element);
}
function isImage({ tagName }) {
    return tagName === 'AFFINE-IMAGE';
}
function isDatabase({ tagName }) {
    return tagName === 'AFFINE-DATABASE-TABLE' || tagName === 'AFFINE-DATABASE';
}
function isEdgelessChildNote({ classList }) {
    return classList.contains('note-background');
}
export function getClosestBlockComponentByPoint(point, state = null, scale = 1) {
    const { y } = point;
    let container;
    let element = null;
    let bounds = null;
    let childBounds = null;
    let diff = 0;
    let n = 1;
    if (state) {
        const { snapToEdge = {
            x: true,
            y: false
        } } = state;
        container = state.container;
        const rect = state.rect || container?.getBoundingClientRect();
        if (rect) {
            if (snapToEdge.x) {
                point.x = Math.min(Math.max(point.x, rect.left) + PADDING_LEFT * scale - 1, rect.right - PADDING_LEFT * scale - 1);
            }
            if (snapToEdge.y) {
                if (scale !== 1) {
                    console.warn('scale is not supported yet');
                }
                point.y = clamp(point.y, rect.top + 1, rect.bottom - 1);
            }
        }
    }
    element = findBlockComponent(document.elementsFromPoint(point.x, point.y), container);
    if (element) {
        if (isDatabase(element)) {
            bounds = element.getBoundingClientRect();
            const rows = getDatabaseBlockRowsElement(element);
            if (rows) {
                childBounds = rows.getBoundingClientRect();
                if (childBounds.height) {
                    if (point.y < childBounds.top || point.y > childBounds.bottom) {
                        return element;
                    }
                    childBounds = null;
                } else {
                    return element;
                }
            }
        } else {
            bounds = getRectByBlockComponent(element);
            childBounds = element.querySelector('.affine-block-children-container')?.firstElementChild?.getBoundingClientRect();
            if (childBounds && childBounds.height) {
                if (bounds.x < point.x && point.x <= childBounds.x) {
                    return element;
                }
                childBounds = null;
            } else {
                return element;
            }
        }
        bounds = null;
        element = null;
    }
    do {
        point.y = y - n * 2;
        if (n < 0) n--;
        n *= -1;
        element = findBlockComponent(document.elementsFromPoint(point.x, point.y), container);
        if (element) {
            bounds = getRectByBlockComponent(element);
            diff = bounds.bottom - point.y;
            if (diff >= 0 && diff <= STEPS * 2) {
                return element;
            }
            diff = point.y - bounds.top;
            if (diff >= 0 && diff <= STEPS * 2) {
                return element;
            }
            bounds = null;
            element = null;
        }
    }while (n <= STEPS);
    return element;
}
export function findClosestBlockComponent(container, point, selector) {
    const children = Array.from(container.querySelectorAll(selector)).filter((child)=>child.host === container.host).filter((child)=>child !== container);
    let lastDistance = Number.POSITIVE_INFINITY;
    let lastChild = null;
    if (!children.length) return null;
    for (const child of children){
        const rect = child.getBoundingClientRect();
        if (rect.height === 0 || point.y > rect.bottom || point.y < rect.top) continue;
        const distance = Math.pow(point.y - (rect.y + rect.height / 2), 2) + Math.pow(point.x - rect.x, 2);
        if (distance <= lastDistance) {
            lastDistance = distance;
            lastChild = child;
        } else {
            return lastChild;
        }
    }
    return lastChild;
}
export function getClosestBlockComponentByElement(element) {
    if (!element) return null;
    if (hasBlockId(element) && isBlock(element)) {
        return element;
    }
    const blockComponent = element.closest(ATTR_SELECTOR);
    if (blockComponent && isBlock(blockComponent)) {
        return blockComponent;
    }
    return null;
}
export function getModelByBlockComponent(component) {
    const containerBlock = component;
    if ('hostModel' in containerBlock) {
        const loader = containerBlock;
        assertExists(loader.hostModel);
        return loader.hostModel;
    }
    assertExists(containerBlock.model);
    return containerBlock.model;
}
export function getRectByBlockComponent(element) {
    if (isDatabase(element)) return element.getBoundingClientRect();
    return (element.firstElementChild ?? element).getBoundingClientRect();
}
export function getBlockComponentsExcludeSubtrees(elements) {
    if (elements.length <= 1) return elements;
    let parent = elements[0];
    return elements.filter((node, index)=>{
        if (index === 0) return true;
        if (contains(parent, node)) {
            return false;
        } else {
            parent = node;
            return true;
        }
    });
}
function findBlockComponent(elements, parent) {
    const len = elements.length;
    let element = null;
    let i = 0;
    while(i < len){
        element = elements[i];
        i++;
        if (parent && !contains(parent, element)) continue;
        if (hasBlockId(element) && isBlock(element)) return element;
        if (isImage(element)) {
            const element = elements[i];
            if (i < len && hasBlockId(element) && isBlock(element)) {
                return elements[i];
            }
            return getClosestBlockComponentByElement(element);
        }
    }
    return null;
}
export function getHoveringNote(point) {
    return document.elementsFromPoint(point.x, point.y).find(isEdgelessChildNote) || null;
}
function getDatabaseBlockTableElement(element) {
    return element.querySelector('.affine-database-block-table');
}
function getDatabaseBlockColumnHeaderElement(element) {
    return element.querySelector('.affine-database-column-header');
}
function getDatabaseBlockRowsElement(element) {
    return element.querySelector('.affine-database-block-rows');
}
export var DropFlags;
(function(DropFlags) {
    DropFlags[DropFlags["Normal"] = 0] = "Normal";
    DropFlags[DropFlags["Database"] = 1] = "Database";
    DropFlags[DropFlags["EmptyDatabase"] = 2] = "EmptyDatabase";
})(DropFlags || (DropFlags = {}));
export function getDropRectByPoint(point, model, element) {
    const result = {
        rect: getRectByBlockComponent(element),
        flag: 0
    };
    const isDatabase = matchFlavours(model, [
        'affine:database'
    ]);
    if (isDatabase) {
        const table = getDatabaseBlockTableElement(element);
        if (!table) {
            return result;
        }
        let bounds = table.getBoundingClientRect();
        if (model.isEmpty.value) {
            result.flag = 2;
            if (point.y < bounds.top) return result;
            const header = getDatabaseBlockColumnHeaderElement(element);
            assertExists(header);
            bounds = header.getBoundingClientRect();
            result.rect = new DOMRect(result.rect.left, bounds.bottom, result.rect.width, 1);
        } else {
            result.flag = 1;
            const rows = getDatabaseBlockRowsElement(element);
            assertExists(rows);
            const rowsBounds = rows.getBoundingClientRect();
            if (point.y < rowsBounds.top || point.y > rowsBounds.bottom) return result;
            const elements = document.elementsFromPoint(point.x, point.y);
            const len = elements.length;
            let e;
            let i = 0;
            for(; i < len; i++){
                e = elements[i];
                if (e.classList.contains('affine-database-block-row-cell-content')) {
                    result.rect = getCellRect(e, bounds);
                    return result;
                }
                if (e.classList.contains('affine-database-block-row')) {
                    e = e.querySelector(ATTR_SELECTOR);
                    assertExists(e);
                    result.rect = getCellRect(e, bounds);
                    return result;
                }
            }
        }
    } else {
        const parent = element.parentElement;
        if (parent?.classList.contains('affine-database-block-row-cell-content')) {
            result.flag = 1;
            result.rect = getCellRect(parent);
            return result;
        }
    }
    return result;
}
function getCellRect(element, bounds) {
    if (!bounds) {
        const table = element.closest('.affine-database-block-table');
        assertExists(table);
        bounds = table.getBoundingClientRect();
    }
    const col = element.parentElement;
    assertExists(col);
    const row = col.parentElement;
    assertExists(row);
    const colRect = col.getBoundingClientRect();
    return new DOMRect(bounds.left, colRect.top, colRect.right - bounds.left, colRect.height);
}
export function hasClassNameInList(element, classList) {
    return classList.some((className)=>element.classList.contains(className));
}
