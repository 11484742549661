function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _init__editorHost, _init_text, _init_host, _init_ctx, _initProto;
import { WithDisposable } from '@blocksuite/block-std';
import { EdgelessEditorBlockSpecs } from '@blocksuite/blocks';
import { AffineSchemas } from '@blocksuite/blocks/schemas';
import { DocCollection, Schema } from '@blocksuite/store';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { getAIPanel } from '../ai-panel';
import { PPTBuilder } from '../slides/index';
export const createSlidesRenderer = (host, ctx)=>{
    return (answer, state)=>{
        if (state === 'generating') {
            const panel = getAIPanel(host);
            panel.generatingElement?.updateLoadingProgress(2);
            return nothing;
        }
        if (state !== 'finished' && state !== 'error') {
            return nothing;
        }
        return html`<style>
        .slides-container {
          width: 100%;
          height: 300px;
        }
      </style>
      <div class="slides-container">
        <ai-slides-renderer
          .host=${host}
          .ctx=${ctx}
          .text=${answer}
        ></ai-slides-renderer>
      </div>`;
    };
};
let _AISlidesRenderer;
_dec = customElement('ai-slides-renderer'), _dec1 = query('editor-host'), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_AISlidesRenderer), _initClass();
    }
    static{
        class AISlidesRenderer extends (_WithDisposable = WithDisposable(LitElement)) {
            static{
                ({ e: [_init__editorHost, _init_text, _init_host, _init_ctx, _initProto], c: [_AISlidesRenderer, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "_editorHost"
                    ],
                    [
                        _dec2,
                        1,
                        "text"
                    ],
                    [
                        _dec3,
                        1,
                        "host"
                    ],
                    [
                        _dec4,
                        1,
                        "ctx"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = css``;
            }
            #___private__editorHost_1;
            get _editorHost() {
                return this.#___private__editorHost_1;
            }
            set _editorHost(_v) {
                this.#___private__editorHost_1 = _v;
            }
            #___private_text_2;
            get text() {
                return this.#___private_text_2;
            }
            set text(_v) {
                this.#___private_text_2 = _v;
            }
            #___private_host_3;
            get host() {
                return this.#___private_host_3;
            }
            set host(_v) {
                this.#___private_host_3 = _v;
            }
            #___private_ctx_4;
            get ctx() {
                return this.#___private_ctx_4;
            }
            set ctx(_v) {
                this.#___private_ctx_4 = _v;
            }
            firstUpdated() {
                requestAnimationFrame(()=>{
                    if (!this._editorHost) return;
                    PPTBuilder(this._editorHost).process(this.text).then((res)=>{
                        if (this.ctx) {
                            this.ctx.set({
                                contents: res.contents,
                                images: res.images
                            });
                        }
                    }).catch(console.error);
                });
            }
            render() {
                return html`<style>
        .slides-container {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .edgeless-container {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          border: 1px solid var(--affine-border-color);
        }

        .mask {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          background-color: transparent;
          width: 100%;
          height: 100%;
        }

        .edgeless-container affine-edgeless-zoom-toolbar-widget,
        edgeless-toolbar {
          display: none;
        }

        * {
          box-sizing: border-box;
        }

        .affine-edgeless-viewport {
          display: block;
          height: 100%;
          position: relative;
          overflow: hidden;
          container-name: viewport;
          container-type: inline-size;
        }

        .affine-edgeless-surface-block-container {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .affine-edgeless-surface-block-container canvas {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 1;
          pointer-events: none;
        }

        edgeless-block-portal-container {
          position: relative;
          box-sizing: border-box;
          overflow: hidden;
          display: block;
          height: 100%;
          font-family: var(--affine-font-family);
          font-size: var(--affine-font-base);
          line-height: var(--affine-line-height);
          color: var(--affine-text-primary-color);
          font-weight: 400;
        }

        .affine-block-children-container.edgeless {
          padding-left: 0;
          position: relative;
          overflow: hidden;
          height: 100%;
          touch-action: none;
          background-color: var(--affine-background-primary-color);
          background-image: radial-gradient(
            var(--affine-edgeless-grid-color) 1px,
            var(--affine-background-primary-color) 1px
          );
          z-index: 0;
        }

        .affine-edgeless-block-child {
          position: absolute;
          transform-origin: center;
          box-sizing: border-box;
          border: 2px solid var(--affine-white-10);
          border-radius: 8px;
          box-shadow: var(--affine-shadow-3);
          pointer-events: all;
        }

        affine-edgeless-image .resizable-img,
        affine-edgeless-image .resizable-img img {
          width: 100%;
          height: 100%;
        }

        .affine-edgeless-layer {
          position: absolute;
          top: 0;
          left: 0;
          contain: size layout style;
        }
      </style>
      <div class="slides-container">
        <div
          class="edgeless-container affine-edgeless-viewport"
          ${ref(this._editorContainer)}
        >
          ${this.host.renderSpecPortal(this._doc, EdgelessEditorBlockSpecs)}
        </div>
        <div class="mask"></div>
      </div>`;
            }
            connectedCallback() {
                super.connectedCallback();
                const schema = new Schema().register(AffineSchemas);
                const collection = new DocCollection({
                    schema,
                    id: 'SLIDES_PREVIEW',
                    disableBacklinkIndex: true,
                    disableSearchIndex: true
                });
                collection.meta.initialize();
                collection.start();
                const doc = collection.createDoc();
                doc.load(()=>{
                    const pageBlockId = doc.addBlock('affine:page', {});
                    doc.addBlock('affine:surface', {}, pageBlockId);
                });
                doc.resetHistory();
                this._doc = doc;
            }
            constructor(...args){
                super(...args);
                this._editorContainer = createRef();
                this.#___private__editorHost_1 = (_initProto(this), _init__editorHost(this));
                this.#___private_text_2 = _init_text(this);
                this.#___private_host_3 = _init_host(this);
                this.#___private_ctx_4 = _init_ctx(this, undefined);
            }
        }
    }
}();
export { _AISlidesRenderer as AISlidesRenderer };
