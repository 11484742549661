function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _BaseCellRenderer, _dec1, _init_docId, _initProto, _dec2, _initClass1, _BaseCellRenderer1, _dec3, _init__container, _initProto1;
import { assertExists } from '@blocksuite/global/utils';
import { baseTheme } from '@toeverything/theme';
import { css, unsafeCSS } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { PenIcon } from '../../../../../_common/icons/index.js';
import { isValidUrl, normalizeUrl } from '../../../../../_common/utils/url.js';
import { HostContextKey } from '../../../../context/host-context.js';
import { stopPropagation } from '../../../utils/event.js';
import { createIcon } from '../../../utils/uni-icon.js';
import { BaseCellRenderer } from '../../base-cell.js';
import { createFromBaseCellRenderer } from '../../renderer.js';
import './components/link-node.js';
import { linkColumnModelConfig } from './define.js';
let _LinkCell;
_dec = customElement('affine-database-link-cell'), _dec1 = state();
new class extends _identity {
    constructor(){
        super(_LinkCell), _initClass();
    }
    static{
        class LinkCell extends (_BaseCellRenderer = BaseCellRenderer) {
            static{
                ({ e: [_init_docId, _initProto], c: [_LinkCell, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "docId"
                    ]
                ], [
                    _dec
                ], _BaseCellRenderer));
            }
            static{
                this.styles = css`
    affine-database-link-cell {
      width: 100%;
      user-select: none;
    }

    affine-database-link-cell:hover .affine-database-link-icon {
      visibility: visible;
    }

    .affine-database-link {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      height: 100%;
      outline: none;
      overflow: hidden;
      font-size: var(--data-view-cell-text-size);
      line-height: var(--data-view-cell-text-line-height);
      word-break: break-all;
    }

    affine-database-link-node {
      flex: 1;
      word-break: break-all;
    }

    .affine-database-link-icon {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      visibility: hidden;
      cursor: pointer;
      background: var(--affine-background-primary-color);
      border-radius: 4px;
    }
    .affine-database-link-icon:hover {
      background: var(--affine-hover-color);
    }

    .affine-database-link-icon svg {
      width: 16px;
      height: 16px;
      fill: var(--affine-icon-color);
    }
    .data-view-link-column-linked-doc {
      text-decoration: underline;
      text-decoration-color: var(--affine-divider-color);
      transition: text-decoration-color 0.2s ease-out;
      cursor: pointer;
    }
    .data-view-link-column-linked-doc:hover {
      text-decoration-color: var(--affine-icon-color);
    }
  `;
            }
            render() {
                const linkText = this.value ?? '';
                const docName = this.docId && this.std?.collection.getDoc(this.docId)?.meta?.title;
                return html`
      <div class="affine-database-link" @click="${this._onClick}">
        ${docName ? html`<span
              class="data-view-link-column-linked-doc"
              @click="${this.openDoc}"
              >${docName}</span
            >` : html`<affine-database-link-node
              .link="${linkText}"
            ></affine-database-link-node>`}
        <div class="affine-database-link-icon" @click="${this._onEdit}">
          ${PenIcon}
        </div>
      </div>
    `;
            }
            updated() {
                if (this.value !== this.preValue) {
                    const std = this.std;
                    this.preValue = this.value;
                    if (!this.value || !isValidUrl(this.value)) {
                        this.docId = undefined;
                        return;
                    }
                    const result = std?.spec.getService('affine:page').quickSearchService?.searchDoc({
                        userInput: this.value,
                        skipSelection: true
                    });
                    result?.then((res)=>{
                        if (res && 'docId' in res) {
                            this.docId = res.docId;
                            return;
                        }
                        this.docId = undefined;
                    }).catch(()=>{
                        this.docId = undefined;
                    });
                }
            }
            get std() {
                const host = this.view.getContext(HostContextKey);
                return host?.std;
            }
            #___private_docId_1;
            get docId() {
                return this.#___private_docId_1;
            }
            set docId(_v) {
                this.#___private_docId_1 = _v;
            }
            constructor(...args){
                super(...args);
                this._onClick = (event)=>{
                    event.stopPropagation();
                    const value = this.value ?? '';
                    if (!value || !isValidUrl(value)) {
                        this.selectCurrentCell(true);
                        return;
                    }
                    if (isValidUrl(value)) {
                        const target = event.target;
                        const link = target.querySelector('.link-node');
                        if (link) {
                            event.preventDefault();
                            link.click();
                        }
                        return;
                    }
                };
                this._onEdit = (e)=>{
                    e.stopPropagation();
                    this.selectCurrentCell(true);
                };
                this.openDoc = (e)=>{
                    e.stopPropagation();
                    if (!this.docId) {
                        return;
                    }
                    const std = this.std;
                    const rootId = std?.doc.root?.id;
                    if (!rootId) {
                        return;
                    }
                    const rootComponent = std?.view.viewFromPath('block', [
                        rootId
                    ]);
                    assertExists(rootComponent);
                    rootComponent.slots.docLinkClicked.emit({
                        docId: this.docId
                    });
                };
                this.#___private_docId_1 = (_initProto(this), _init_docId(this, undefined));
            }
        }
    }
}();
let _LinkCellEditing;
_dec2 = customElement('affine-database-link-cell-editing'), _dec3 = query('.affine-database-link-editing');
new class extends _identity {
    constructor(){
        super(_LinkCellEditing), _initClass1();
    }
    static{
        class LinkCellEditing extends (_BaseCellRenderer1 = BaseCellRenderer) {
            static{
                ({ e: [_init__container, _initProto1], c: [_LinkCellEditing, _initClass1] } = _apply_decs_2203_r(this, [
                    [
                        _dec3,
                        1,
                        "_container"
                    ]
                ], [
                    _dec2
                ], _BaseCellRenderer1));
            }
            static{
                this.styles = css`
    affine-database-link-cell-editing {
      width: 100%;
      cursor: text;
    }

    .affine-database-link-editing {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      border: none;
      font-family: ${unsafeCSS(baseTheme.fontSansFamily)};
      color: var(--affine-text-primary-color);
      font-weight: 400;
      background-color: transparent;
      font-size: var(--data-view-cell-text-size);
      line-height: var(--data-view-cell-text-line-height);
      word-break: break-all;
    }

    .affine-database-link-editing:focus {
      outline: none;
    }
  `;
            }
            firstUpdated() {
                this._focusEnd();
            }
            onExitEditMode() {
                this._setValue();
            }
            render() {
                const linkText = this.value ?? '';
                return html`<input
      class="affine-database-link-editing link"
      .value=${linkText}
      @keydown=${this._onKeydown}
      @pointerdown=${stopPropagation}
    />`;
            }
            #___private__container_1;
            get _container() {
                return this.#___private__container_1;
            }
            set _container(_v) {
                this.#___private__container_1 = _v;
            }
            constructor(...args){
                super(...args);
                this._focusEnd = ()=>{
                    const end = this._container.value.length;
                    this._container.focus();
                    this._container.setSelectionRange(end, end);
                };
                this._onKeydown = (e)=>{
                    if (e.key === 'Enter' && !e.isComposing) {
                        this._setValue();
                        setTimeout(()=>{
                            this.selectCurrentCell(false);
                        });
                    }
                };
                this._setValue = (value = this._container.value)=>{
                    let url = value;
                    if (isValidUrl(value)) {
                        url = normalizeUrl(value);
                    }
                    this.onChange(url);
                    this._container.value = url;
                };
                this.#___private__container_1 = (_initProto1(this), _init__container(this));
            }
        }
    }
}();
export const linkColumnConfig = linkColumnModelConfig.renderConfig({
    icon: createIcon('LinkIcon'),
    cellRenderer: {
        view: createFromBaseCellRenderer(_LinkCell),
        edit: createFromBaseCellRenderer(_LinkCellEditing)
    }
});
export { _LinkCell as LinkCell, _LinkCellEditing as LinkCellEditing };
