import { literal } from 'lit/static-html.js';
import { AIChatBlockSchema } from './ai-chat-model.js';
export const AIChatBlockSpec = {
    schema: AIChatBlockSchema,
    view: {
        component: literal`affine-ai-chat`
    }
};
export const EdgelessAIChatBlockSpec = {
    schema: AIChatBlockSchema,
    view: {
        component: literal`affine-edgeless-ai-chat`
    }
};
