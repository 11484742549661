import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from 'jotai';
import { navHeaderStyle } from '../index.css';
import { appSidebarOpenAtom } from '../index.jotai';
import { SidebarSwitch } from './sidebar-switch';
export const SidebarHeader = ()=>{
    const open = useAtomValue(appSidebarOpenAtom);
    return _jsx("div", {
        className: navHeaderStyle,
        "data-open": open,
        children: _jsx(SidebarSwitch, {
            show: open
        })
    });
};
export * from './sidebar-switch';
