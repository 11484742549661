import { KEYBOARD_ALLOW_DEFAULT, baseTextAttributes, getDefaultAttributeRenderer } from '@blocksuite/inline';
import { z } from 'zod';
export class InlineManager {
    registerMarkdownMatches(markdownMatches) {
        this._markdownMatches = markdownMatches;
    }
    registerSpecs(specs) {
        if (!Array.isArray(specs)) {
            return;
        }
        this._specs = specs;
    }
    get markdownMatches() {
        return this._markdownMatches;
    }
    get specs() {
        return this._specs;
    }
    constructor(){
        this._markdownMatches = [];
        this._specs = [];
        this.embedChecker = (delta)=>{
            for (const spec of this._specs){
                if (spec.embed && spec.match(delta)) {
                    return true;
                }
            }
            return false;
        };
        this.getRenderer = ()=>{
            const defaultRenderer = getDefaultAttributeRenderer();
            const renderer = (delta, selected)=>{
                for (const spec of this._specs.toReversed()){
                    if (spec.match(delta)) {
                        return spec.renderer(delta, selected);
                    }
                }
                return defaultRenderer(delta, selected);
            };
            return renderer;
        };
        this.getSchema = ()=>{
            const defaultSchema = baseTextAttributes;
            const schema = this._specs.reduce((acc, cur)=>{
                const currentSchema = z.object({
                    [cur.name]: cur.schema
                });
                return acc.merge(currentSchema);
            }, defaultSchema);
            return schema;
        };
        this.markdownShortcutHandler = (context, undoManager)=>{
            const { inlineEditor, prefixText, inlineRange } = context;
            for (const match of this._markdownMatches){
                const matchedText = prefixText.match(match.pattern);
                if (matchedText) {
                    return match.action({
                        inlineEditor,
                        prefixText,
                        inlineRange,
                        pattern: match.pattern,
                        undoManager
                    });
                }
            }
            return KEYBOARD_ALLOW_DEFAULT;
        };
    }
}
