import { AuthService, GraphQLService, WebSocketService } from '@affine/core/modules/cloud';
import { GlobalState, WorkspaceFlavourProvider } from '@toeverything/infra';
import { CloudWorkspaceFlavourProviderService } from './impls/cloud';
import { IndexedDBBlobStorage } from './impls/engine/blob-indexeddb';
import { SqliteBlobStorage } from './impls/engine/blob-sqlite';
import { IndexedDBDocStorage } from './impls/engine/doc-indexeddb';
import { SqliteDocStorage } from './impls/engine/doc-sqlite';
import { LOCAL_WORKSPACE_LOCAL_STORAGE_KEY, LocalWorkspaceFlavourProvider } from './impls/local';
import { WorkspaceEngineStorageProvider } from './providers/engine';
export function configureBrowserWorkspaceFlavours(framework) {
    framework.impl(WorkspaceFlavourProvider('LOCAL'), LocalWorkspaceFlavourProvider, [
        WorkspaceEngineStorageProvider
    ]).service(CloudWorkspaceFlavourProviderService, [
        GlobalState,
        AuthService,
        WorkspaceEngineStorageProvider,
        GraphQLService,
        WebSocketService
    ]).impl(WorkspaceFlavourProvider('CLOUD'), (p)=>p.get(CloudWorkspaceFlavourProviderService));
}
export function configureIndexedDBWorkspaceEngineStorageProvider(framework) {
    framework.impl(WorkspaceEngineStorageProvider, {
        getDocStorage (workspaceId) {
            return new IndexedDBDocStorage(workspaceId);
        },
        getBlobStorage (workspaceId) {
            return new IndexedDBBlobStorage(workspaceId);
        }
    });
}
export function configureSqliteWorkspaceEngineStorageProvider(framework) {
    framework.impl(WorkspaceEngineStorageProvider, {
        getDocStorage (workspaceId) {
            return new SqliteDocStorage(workspaceId);
        },
        getBlobStorage (workspaceId) {
            return new SqliteBlobStorage(workspaceId);
        }
    });
}
export function _addLocalWorkspace(id) {
    const allWorkspaceIDs = JSON.parse(localStorage.getItem(LOCAL_WORKSPACE_LOCAL_STORAGE_KEY) ?? '[]');
    allWorkspaceIDs.push(id);
    localStorage.setItem(LOCAL_WORKSPACE_LOCAL_STORAGE_KEY, JSON.stringify(allWorkspaceIDs));
}
