function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
function _identity(x) {
    return x;
}
var _dec, _initClass, _WithDisposable, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _init_bannerContainer, _init_block, _init_isBannerEmpty, _init_isError, _init_isNoteContentEmpty, _init_noteContainer, _init_surfaceRefRenderer, _init_surfaceRefService, _initProto;
import { ShadowlessElement, WithDisposable } from '@blocksuite/block-std';
import { html, nothing } from 'lit';
import { customElement, property, queryAsync } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { renderLinkedDocInCard } from '../../_common/utils/render-linked-doc.js';
import { cardStyles } from '../styles.js';
import { getSyncedDocIcons } from '../utils.js';
let _EmbedSyncedDocCard;
_dec = customElement('affine-embed-synced-doc-card'), _dec1 = queryAsync('.affine-embed-synced-doc-card-banner.render'), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = queryAsync('.affine-embed-synced-doc-content-note.render'), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
});
new class extends _identity {
    constructor(){
        super(_EmbedSyncedDocCard), _initClass();
    }
    static{
        class EmbedSyncedDocCard extends (_WithDisposable = WithDisposable(ShadowlessElement)) {
            static{
                ({ e: [_init_bannerContainer, _init_block, _init_isBannerEmpty, _init_isError, _init_isNoteContentEmpty, _init_noteContainer, _init_surfaceRefRenderer, _init_surfaceRefService, _initProto], c: [_EmbedSyncedDocCard, _initClass] } = _apply_decs_2203_r(this, [
                    [
                        _dec1,
                        1,
                        "bannerContainer"
                    ],
                    [
                        _dec2,
                        1,
                        "block"
                    ],
                    [
                        _dec3,
                        1,
                        "isBannerEmpty"
                    ],
                    [
                        _dec4,
                        1,
                        "isError"
                    ],
                    [
                        _dec5,
                        1,
                        "isNoteContentEmpty"
                    ],
                    [
                        _dec6,
                        1,
                        "noteContainer"
                    ],
                    [
                        _dec7,
                        1,
                        "surfaceRefRenderer"
                    ],
                    [
                        _dec8,
                        1,
                        "surfaceRefService"
                    ]
                ], [
                    _dec
                ], _WithDisposable));
            }
            static{
                this.styles = cardStyles;
            }
            _handleClick(event) {
                event.stopPropagation();
                if (!this.block.isInSurface) {
                    this._selectBlock();
                }
            }
            _isDocEmpty() {
                const syncedDoc = this.block.syncedDoc;
                if (!syncedDoc) {
                    return false;
                }
                return !!syncedDoc && !syncedDoc.meta?.title.length && this.isNoteContentEmpty && this.isBannerEmpty;
            }
            _selectBlock() {
                const selectionManager = this.host.selection;
                const blockSelection = selectionManager.create('block', {
                    blockId: this.block.blockId
                });
                selectionManager.setGroup('note', [
                    blockSelection
                ]);
            }
            connectedCallback() {
                super.connectedCallback();
                this.block.handleEvent('dragStart', ()=>{
                    this._dragging = true;
                }, {
                    global: true
                });
                this.block.handleEvent('dragEnd', ()=>{
                    this._dragging = false;
                }, {
                    global: true
                });
                const { isCycle } = this.block.blockState;
                const syncedDoc = this.block.syncedDoc;
                if (isCycle && syncedDoc) {
                    if (syncedDoc.root) {
                        renderLinkedDocInCard(this);
                    } else {
                        syncedDoc.slots.rootAdded.once(()=>{
                            renderLinkedDocInCard(this);
                        });
                    }
                    this.disposables.add(syncedDoc.collection.meta.docMetaUpdated.on(()=>{
                        renderLinkedDocInCard(this);
                    }));
                    this.disposables.add(syncedDoc.slots.blockUpdated.on((payload)=>{
                        if (this._dragging) {
                            return;
                        }
                        if (payload.type === 'update' && [
                            '',
                            'caption',
                            'xywh'
                        ].includes(payload.props.key)) {
                            return;
                        }
                        renderLinkedDocInCard(this);
                    }));
                }
            }
            disconnectedCallback() {
                super.disconnectedCallback();
                this.cleanUpSurfaceRefRenderer();
            }
            render() {
                const { isLoading, isDeleted, isError, isCycle } = this.blockState;
                const error = this.isError || isError;
                const isEmpty = this._isDocEmpty() && this.isBannerEmpty;
                const cardClassMap = classMap({
                    loading: isLoading,
                    error,
                    deleted: isDeleted,
                    cycle: isCycle,
                    surface: this.block.isInSurface,
                    empty: isEmpty,
                    'banner-empty': this.isBannerEmpty,
                    'note-empty': this.isNoteContentEmpty
                });
                const { LoadingIcon, SyncedDocIcon, SyncedDocErrorIcon, SyncedDocDeletedIcon, ReloadIcon, SyncedDocEmptyBanner, SyncedDocErrorBanner, SyncedDocDeletedBanner } = getSyncedDocIcons(this.editorMode);
                const titleIcon = error ? SyncedDocErrorIcon : isLoading ? LoadingIcon : isDeleted ? SyncedDocDeletedIcon : SyncedDocIcon;
                const titleText = error ? this.block.docTitle : isLoading ? 'Loading...' : isDeleted ? `Deleted doc` : this.block.docTitle;
                const showDefaultNoteContent = isLoading || error || isDeleted || isEmpty;
                const defaultNoteContent = error ? 'This linked doc failed to load.' : isLoading ? '' : isDeleted ? 'This linked doc is deleted.' : isEmpty ? 'Preview of the page will be displayed here.' : '';
                const dateText = this.block.docUpdatedAt.toLocaleString();
                const showDefaultBanner = isLoading || error || isDeleted || isEmpty;
                const defaultBanner = isLoading ? SyncedDocEmptyBanner : error ? SyncedDocErrorBanner : isDeleted ? SyncedDocDeletedBanner : SyncedDocEmptyBanner;
                return html`
      <div
        class="affine-embed-synced-doc-card ${cardClassMap}"
        @click=${this._handleClick}
      >
        <div class="affine-embed-synced-doc-card-content">
          <div class="affine-embed-synced-doc-card-content-title">
            <div class="affine-embed-synced-doc-card-content-title-icon">
              ${titleIcon}
            </div>

            <div class="affine-embed-synced-doc-card-content-title-text">
              ${titleText}
            </div>
          </div>

          ${showDefaultNoteContent ? html`<div class="affine-embed-synced-doc-content-note default">
                ${defaultNoteContent}
              </div>` : nothing}
          <div class="affine-embed-synced-doc-content-note render"></div>

          ${error ? html`
                <div class="affine-embed-synced-doc-card-content-reload">
                  <div
                    class="affine-embed-synced-doc-card-content-reload-button"
                    @click=${()=>this.block.refreshData()}
                  >
                    ${ReloadIcon} <span>Reload</span>
                  </div>
                </div>
              ` : html`
                <div class="affine-embed-synced-doc-card-content-date">
                  <span>Updated</span>

                  <span>${dateText}</span>
                </div>
              `}
        </div>

        <div class="affine-embed-synced-doc-card-banner render"></div>

        ${showDefaultBanner ? html`
              <div class="affine-embed-synced-doc-card-banner default">
                ${defaultBanner}
              </div>
            ` : nothing}
      </div>
    `;
            }
            get blockState() {
                return this.block.blockState;
            }
            get editorMode() {
                return this.block.editorMode;
            }
            get host() {
                return this.block.host;
            }
            get linkedDoc() {
                return this.block.syncedDoc;
            }
            get model() {
                return this.block.model;
            }
            get path() {
                return this.block.path;
            }
            get std() {
                return this.block.std;
            }
            #___private_bannerContainer_1;
            get bannerContainer() {
                return this.#___private_bannerContainer_1;
            }
            set bannerContainer(_v) {
                this.#___private_bannerContainer_1 = _v;
            }
            #___private_block_2;
            get block() {
                return this.#___private_block_2;
            }
            set block(_v) {
                this.#___private_block_2 = _v;
            }
            #___private_isBannerEmpty_3;
            get isBannerEmpty() {
                return this.#___private_isBannerEmpty_3;
            }
            set isBannerEmpty(_v) {
                this.#___private_isBannerEmpty_3 = _v;
            }
            #___private_isError_4;
            get isError() {
                return this.#___private_isError_4;
            }
            set isError(_v) {
                this.#___private_isError_4 = _v;
            }
            #___private_isNoteContentEmpty_5;
            get isNoteContentEmpty() {
                return this.#___private_isNoteContentEmpty_5;
            }
            set isNoteContentEmpty(_v) {
                this.#___private_isNoteContentEmpty_5 = _v;
            }
            #___private_noteContainer_6;
            get noteContainer() {
                return this.#___private_noteContainer_6;
            }
            set noteContainer(_v) {
                this.#___private_noteContainer_6 = _v;
            }
            #___private_surfaceRefRenderer_7;
            get surfaceRefRenderer() {
                return this.#___private_surfaceRefRenderer_7;
            }
            set surfaceRefRenderer(_v) {
                this.#___private_surfaceRefRenderer_7 = _v;
            }
            #___private_surfaceRefService_8;
            get surfaceRefService() {
                return this.#___private_surfaceRefService_8;
            }
            set surfaceRefService(_v) {
                this.#___private_surfaceRefService_8 = _v;
            }
            constructor(...args){
                super(...args);
                this._dragging = false;
                this.cleanUpSurfaceRefRenderer = ()=>{
                    if (this.surfaceRefRenderer) {
                        this.surfaceRefService.removeRenderer(this.surfaceRefRenderer.id);
                    }
                };
                this.#___private_bannerContainer_1 = (_initProto(this), _init_bannerContainer(this));
                this.#___private_block_2 = _init_block(this);
                this.#___private_isBannerEmpty_3 = _init_isBannerEmpty(this, false);
                this.#___private_isError_4 = _init_isError(this, false);
                this.#___private_isNoteContentEmpty_5 = _init_isNoteContentEmpty(this, false);
                this.#___private_noteContainer_6 = _init_noteContainer(this);
                this.#___private_surfaceRefRenderer_7 = _init_surfaceRefRenderer(this, null);
                this.#___private_surfaceRefService_8 = _init_surfaceRefService(this);
            }
        }
    }
}();
export { _EmbedSyncedDocCard as EmbedSyncedDocCard };
