import { DisposableGroup, Slot } from '@blocksuite/global/utils';
import { ThemeObserver } from '../_common/theme/theme-observer.js';
import { Viewport } from '../root-block/edgeless/utils/viewport.js';
import { Renderer } from '../surface-block/index.js';
import { getSurfaceBlock } from './utils.js';
export class SurfaceRefRenderer {
    constructor(id, doc, std, options = {
        enableStackingCanvas: false
    }){
        this.id = id;
        this.doc = doc;
        this.std = std;
        this._disposables = new DisposableGroup();
        this._surfaceModel = null;
        this.slots = {
            surfaceRendererInit: new Slot(),
            surfaceRendererRefresh: new Slot(),
            surfaceModelChanged: new Slot(),
            mounted: new Slot(),
            unmounted: new Slot()
        };
        const viewport = new Viewport();
        const renderer = new Renderer({
            viewport,
            layerManager: this.surfaceService.layer,
            enableStackingCanvas: options.enableStackingCanvas,
            provider: {
                generateColorProperty: (color, fallback)=>ThemeObserver.generateColorProperty(color, fallback),
                getColorScheme: ()=>ThemeObserver.mode,
                getColorValue: (color, fallback, real)=>ThemeObserver.getColorValue(color, fallback, real),
                getPropertyValue: (property)=>ThemeObserver.getPropertyValue(property)
            }
        });
        this._surfaceRenderer = renderer;
        this._viewport = viewport;
    }
    _initSurfaceModel() {
        const init = ()=>{
            const model = getSurfaceBlock(this.doc);
            this._surfaceModel = model;
            if (!model) return;
            this.slots.surfaceModelChanged.emit(model);
        };
        init();
        if (!this._surfaceModel) {
            this._disposables.add(this.doc.slots.blockUpdated.on(({ type })=>{
                if (type === 'add' && !this._surfaceModel && getSurfaceBlock(this.doc)) {
                    init();
                }
            }));
        }
    }
    _initSurfaceRenderer() {
        this.slots.surfaceRendererInit.emit();
    }
    getModel(id) {
        return this.doc.getBlockById(id) ?? this._surfaceModel?.getElementById(id) ?? null;
    }
    mount() {
        if (this._disposables.disposed) {
            this._disposables = new DisposableGroup();
        }
        this._initSurfaceModel();
        this._initSurfaceRenderer();
        this.slots.mounted.emit();
    }
    unmount() {
        this._disposables.dispose();
        this.slots.unmounted.emit();
    }
    get surfaceModel() {
        return this._surfaceModel;
    }
    get surfaceRenderer() {
        return this._surfaceRenderer;
    }
    get surfaceService() {
        return this.std.spec.getService('affine:surface');
    }
    get viewport() {
        return this._viewport;
    }
}
