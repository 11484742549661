import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Input, Modal, RadioButton, RadioButtonGroup } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { useCallback, useMemo, useState } from 'react';
import * as styles from './create-feed.css';
import { feedTypeWrapper } from './create-feed.css';
var FeedType;
(function(FeedType) {
    FeedType["WeChat"] = "WeChat";
    FeedType["RSS"] = "RSS";
})(FeedType || (FeedType = {}));
export const CreateFeedModal = ({ init, onConfirm, open, showTips, onOpenChange, title })=>{
    const t = useI18n();
    const onConfirmTitle = useCallback((title)=>{
        onConfirm(title);
        onOpenChange(false);
    }, [
        onConfirm,
        onOpenChange
    ]);
    const onCancel = useCallback(()=>{
        onOpenChange(false);
    }, [
        onOpenChange
    ]);
    return _jsx(Modal, {
        open: open,
        title: title,
        onOpenChange: onOpenChange,
        width: 480,
        children: init != null ? _jsx(CreateFeed, {
            showTips: showTips,
            onConfirmText: t['com.affine.editCollection.save'](),
            init: init,
            onConfirm: onConfirmTitle,
            onCancel: onCancel
        }) : null
    });
};
export const CreateFeed = ({ onConfirmText, init, showTips, onCancel, onConfirm })=>{
    const t = useI18n();
    const [value, onChange] = useState(init);
    const isNameEmpty = useMemo(()=>value.trim().length === 0, [
        value
    ]);
    const save = useCallback(()=>{
        if (isNameEmpty) {
            return;
        }
        onConfirm(value);
    }, [
        onConfirm,
        value,
        isNameEmpty
    ]);
    const onKeyDown = useCallback((e)=>{
        if (e.key === 'Escape') {
            if (isNameEmpty) {
                return;
            } else {
                e.currentTarget.blur();
            }
        }
        e.stopPropagation();
    }, [
        isNameEmpty
    ]);
    const [feedType, setFeedType] = useState("WeChat");
    return _jsxs("div", {
        children: [
            _jsxs("div", {
                className: styles.content,
                children: [
                    _jsxs(RadioButtonGroup, {
                        width: 250,
                        className: feedTypeWrapper,
                        value: feedType,
                        onValueChange: useCallback((value)=>{
                            setFeedType(value);
                        }, [
                            setFeedType
                        ]),
                        children: [
                            _jsx(RadioButton, {
                                value: "WeChat",
                                "data-testid": "feed-type-wechat-trigger",
                                children: t['ai.wemem.edit-feed.feedType.wechat']()
                            }),
                            _jsx(RadioButton, {
                                value: "RSS",
                                "data-testid": "feed-type-rss-trigger",
                                children: t['ai.wemem.edit-feed.feedType.rss']()
                            })
                        ]
                    }),
                    _jsx(Input, {
                        autoFocus: true,
                        value: value,
                        "data-testid": "input-collection-title",
                        placeholder: t[`ai.wemem.edit-feed.feedType.${feedType}.placeholder`](),
                        onChange: useCallback((value)=>onChange(value), [
                            onChange
                        ]),
                        onEnter: save,
                        onKeyDown: onKeyDown
                    }),
                    showTips ? _jsx("div", {
                        className: styles.createTips,
                        children: t[`ai.wemem.edit-feed.feedType.${feedType}.createTips`]()
                    }) : null
                ]
            }),
            _jsxs("div", {
                className: styles.footer,
                children: [
                    _jsx(Button, {
                        size: "large",
                        onClick: onCancel,
                        children: t['ai.wemem.edit-feed.button.cancel']()
                    }),
                    _jsx(Button, {
                        size: "large",
                        "data-testid": "save-collection",
                        type: "primary",
                        disabled: isNameEmpty,
                        onClick: save,
                        children: onConfirmText ?? t['ai.wemem.edit-feed.button.create']()
                    })
                ]
            })
        ]
    });
};
